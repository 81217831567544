import { Injectable } from '@angular/core';
import _ from 'lodash-es';

import { KmProjectStub } from '../../lib-models/km-project-stub/km-project-stub';
import { KmProjectTask } from '../../lib-models/km-project-task/km-project-task';
import { SortUtilService } from '../sort-util/sort-util.service';
import { ProjectTaskOrder, ProjectTaskOrderMap } from '../../lib.types';

@Injectable({
  providedIn: 'root'
})
export class ProjectTaskUtilService {

  static groupProjectTasksByProject(
    project_tasks: KmProjectTask[]
  ): { project: KmProjectStub, project_tasks: KmProjectTask[] }[] {
    const grouped_project_task_map: Record<number, { project: KmProjectStub, project_tasks: KmProjectTask[] }> = {};

    for (const project_task of project_tasks) {
      const project_key = project_task.project.project_key;

      if (!grouped_project_task_map[project_key]) {
        grouped_project_task_map[project_key] = {
          project: project_task.project,
          project_tasks: []
        };
      }

      grouped_project_task_map[project_key].project_tasks.push(project_task);
    }

    const grouped_project_tasks: { project: KmProjectStub, project_tasks: KmProjectTask[] }[] = [];

    for (const project_key of Object.keys(grouped_project_task_map)) {
      grouped_project_tasks.push({
        project: grouped_project_task_map[project_key].project,
        project_tasks: grouped_project_task_map[project_key].project_tasks
      });

    }
    SortUtilService.sortList(grouped_project_tasks, { primary_sort_property: 'project.project_name' });

    return grouped_project_tasks;
  }

  // Uses an order map generated by the API to sort project tasks by suggested order
  // Project tasks with active or on hold clocks take precendence
  static orderProjectTasks(
    project_tasks: KmProjectTask[],
    project_task_order_map: ProjectTaskOrderMap,
    order_type: ProjectTaskOrder
  ): KmProjectTask[] {
    return project_tasks.sort((a, b) => {
      const a_ordering = project_task_order_map[a.project_task_key] || null;
      const b_ordering = project_task_order_map[b.project_task_key] || null;

      return (!!a_ordering ? a_ordering[order_type] : -1) - (!!b_ordering ? b_ordering[order_type] : -1);
    });
  }
}
