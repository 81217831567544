import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { GridsterModule } from 'angular-gridster2';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { YouTubePlayerModule } from '@angular/youtube-player';

import { LibPipesModule } from '../lib-pipes/lib-pipes.module';
import { LibDirectivesModule } from '../lib-directives/lib-directives.module';

import { CameraComponent } from './camera/camera.component';
import { BackdropComponent } from './backdrop/backdrop.component';
import { BannerComponent } from './banner/banner.component';
import { ListDropDownComponent } from './list-drop-down/list-drop-down.component';
import { TooltipPopoverComponent } from './tooltip-popover/tooltip-popover.component';
import { ItemBulletComponent } from './item-bullet/item-bullet.component';
import { AppCheckboxComponent } from './app-checkbox/app-checkbox.component';
import { AppToggleComponent } from './app-toggle/app-toggle.component';
import { AppListComponent } from './app-list/app-list.component';
import { AppListItemDirective } from './app-list/app-list-item/app-list-item.directive';
import { MegaSearchComponent } from './mega-search/mega-search.component';
import { AppSpinnerComponent } from './app-spinner/app-spinner.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { LabelPanelComponent } from './label-panel/label-panel.component';
import { LabelDropdownComponent } from './label-dropdown/label-dropdown.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { WeekSelectorComponent } from './week-selector/week-selector.component';
import { ColourPickerComponent } from './colour-picker/colour-picker.component';
import { WhatsThisComponent } from './whats-this/whats-this.component';
import { ReportChartComponent } from './report-chart/report-chart.component';
import { ReportTableComponent } from './report-table/report-table.component';
import { ReportTableResizerDirective } from './report-table/report-table-resizer/report-table-resizer.directive';
import { ReportFilterSettingComponent } from './report-filter-setting/report-filter-setting.component';
import { ReportChartSettingComponent } from './report-chart-setting/report-chart-setting.component';
import { ReportTableSettingComponent } from './report-table-setting/report-table-setting.component';
import { ReportTableColumnComponent } from './report-table/report-table-column/report-table-column.component';
import { AppRadioButtonComponent } from './app-radio-button/app-radio-button.component';
import { HalfDoughnutChartComponent } from './half-doughnut-chart/half-doughnut-chart.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { AddressInputComponent } from './address-input/address-input.component';
import { RadioBoxComponent } from './radio-box/radio-box.component';
import { AppFormItemComponent } from './app-form-item/app-form-item.component';
import { ArchivedIconComponent } from './archived-icon/archived-icon.component';
import { DaySelectorComponent } from './day-selector/day-selector.component';
import { DurationInputComponent } from './duration-input/duration-input.component';
import { DurationSplitInputComponent } from './duration-split-input/duration-split-input.component';
import { UnitInputComponent } from './unit-input/unit-input.component';
import { MonthSelectorComponent } from './month-selector/month-selector.component';
import { CalendarMonthGridComponent } from './calendar-month-grid/calendar-month-grid.component';
import { SegmentValueComponent } from './segment-value/segment-value.component';
import { AttachmentPopoverComponent } from './attachment-popover/attachment-popover.component';
import { MobilePopupMenuComponent } from './mobile-popup-menu/mobile-popup-menu.component';
import { MobileSlideoutButtonComponent } from './mobile-slideout-button/mobile-slideout-button.component';
import { UacDropdownComponent } from './uac-dropdown/uac-dropdown.component';
import { NotificationDropdownComponent } from './notification-dropdown/notification-dropdown.component';
import { NotificationDropdownContentComponent } from './notification-dropdown/notification-dropdown-content/notification-dropdown-content.component';
import { ClientIconComponent } from './client-icon/client-icon.component';
import { TaskIconComponent } from './task-icon/task-icon.component';
import { ProjectIconComponent } from './project-icon/project-icon.component';
import { ReportThumbnailComponent } from './report-thumbnail/report-thumbnail.component';
import { AppSearchComponent } from './app-search/app-search.component';
import { AppTabsComponent } from './app-tabs/app-tabs.component';
import { DayDropdownComponent } from './day-dropdown/day-dropdown.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { WeekDayTabsComponent } from './week-day-tabs/week-day-tabs.component';
import { WeekDaySegmentTotalsComponent } from './week-day-segment-totals/week-day-segment-totals.component';
import { ThumbnailGraphicComponent } from './thumbnail-graphic/thumbnail-graphic.component';
import { ReportNumericComponent } from './report-numeric/report-numeric.component';
import { ReportNumericSettingComponent } from './report-numeric-setting/report-numeric-setting.component';
import { InputAutocompleteComponent } from './input-autocomplete/input-autocomplete.component';
import { UserIconComponent } from './user-icon/user-icon.component';
import { AppVimeoPlayerComponent } from './app-vimeo-player/app-vimeo-player.component';
import { AppYoutubePlayerComponent } from './app-youtube-player/app-youtube-player.component';
import { InvoiceEditorComponent } from './invoice-editor/invoice-editor.component';
import { InvoiceEditorLineComponent } from './invoice-editor/invoice-editor-line/invoice-editor-line.component';
import { NotificationPopoverComponent } from './notification-popover/notification-popover.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { ClockDropdownComponent } from './clock-dropdown/clock-dropdown.component';
import { ProjectTaskClockComponent } from './project-task-clock/project-task-clock.component';
import { ActiveClockListComponent } from './active-clock-list/active-clock-list.component';
import { ReportDashComponent } from './report-dash/report-dash.component';
import { ReportDashBlockComponent } from './report-dash/report-dash-block/report-dash-block.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { MonthInputComponent } from './month-input/month-input.component';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { ProductIconComponent } from './product-icon/product-icon.component';
import { InvoxyIntegrationIconComponent } from './invoxy-integration-icon/invoxy-integration-icon.component';
import { LockedIconComponent } from './locked-icon/locked-icon.component';
import { ClockFooterComponent } from './clock-footer/clock-footer.component';
import { AppFormItemErrorComponent } from './app-form-item-error/app-form-item-error.component';
import { DateRangeDropdownComponent } from './date-range-dropdown/date-range-dropdown.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { KmConfettiComponent } from './km-confetti/km-confetti.component';
import { CompletedIconComponent } from './completed-icon/completed-icon.component';
import { ListDropDownItemComponent } from './list-drop-down/list-drop-down-item/list-drop-down-item.component';
import { AccountSelectDropdownComponent } from './account-select-dropdown/account-select-dropdown.component';

@NgModule({
  declarations: [
    CameraComponent,
    BackdropComponent,
    BannerComponent,
    ListDropDownComponent,
    TooltipPopoverComponent,
    ItemBulletComponent,
    AppCheckboxComponent,
    AppToggleComponent,
    AppListComponent,
    AppListItemDirective,
    MegaSearchComponent,
    AppSpinnerComponent,
    BackButtonComponent,
    LabelPanelComponent,
    LabelDropdownComponent,
    DatePickerComponent,
    WeekSelectorComponent,
    ColourPickerComponent,
    WhatsThisComponent,
    ReportChartComponent,
    ReportTableComponent,
    ReportTableResizerDirective,
    ReportFilterSettingComponent,
    ReportChartSettingComponent,
    ReportTableSettingComponent,
    ReportTableColumnComponent,
    AppRadioButtonComponent,
    HalfDoughnutChartComponent,
    TimePickerComponent,
    AddressInputComponent,
    RadioBoxComponent,
    AppFormItemComponent,
    ArchivedIconComponent,
    DaySelectorComponent,
    DurationInputComponent,
    DurationSplitInputComponent,
    UnitInputComponent,
    MonthSelectorComponent,
    CalendarMonthGridComponent,
    SegmentValueComponent,
    AttachmentPopoverComponent,
    MobilePopupMenuComponent,
    MobileSlideoutButtonComponent,
    UacDropdownComponent,
    NotificationDropdownComponent,
    NotificationDropdownContentComponent,
    ClientIconComponent,
    TaskIconComponent,
    ProjectIconComponent,
    ReportThumbnailComponent,
    AppSearchComponent,
    AppTabsComponent,
    DayDropdownComponent,
    FileUploaderComponent,
    WeekDayTabsComponent,
    WeekDaySegmentTotalsComponent,
    ThumbnailGraphicComponent,
    ReportNumericComponent,
    ReportNumericSettingComponent,
    InputAutocompleteComponent,
    UserIconComponent,
    AppVimeoPlayerComponent,
    AppYoutubePlayerComponent,
    InvoiceEditorComponent,
    InvoiceEditorLineComponent,
    NotificationPopoverComponent,
    FilePreviewComponent,
    ClockDropdownComponent,
    ProjectTaskClockComponent,
    ActiveClockListComponent,
    ReportDashComponent,
    ReportDashBlockComponent,
    MonthPickerComponent,
    MonthInputComponent,
    MenuButtonComponent,
    ProductIconComponent,
    InvoxyIntegrationIconComponent,
    LockedIconComponent,
    ClockFooterComponent,
    AppFormItemErrorComponent,
    DateRangeDropdownComponent,
    DateRangePickerComponent,
    KmConfettiComponent,
    CompletedIconComponent,
    ListDropDownItemComponent,
    AccountSelectDropdownComponent
  ],
  imports: [
    LibPipesModule,
    CommonModule,
    NgbModule,
    FormsModule,
    LibDirectivesModule,
    GridsterModule,
    InfiniteScrollModule,
    NgxMaskModule,
    GoogleMapsModule,
    RouterModule,
    ScrollingModule,
    DragDropModule,
    YouTubePlayerModule
  ],
  exports: [
    CameraComponent,
    BackdropComponent,
    BannerComponent,
    ListDropDownComponent,
    TooltipPopoverComponent,
    ItemBulletComponent,
    AppCheckboxComponent,
    AppToggleComponent,
    AppListComponent,
    AppListItemDirective,
    MegaSearchComponent,
    AppSpinnerComponent,
    BackButtonComponent,
    LabelPanelComponent,
    LabelPanelComponent,
    LabelDropdownComponent,
    DatePickerComponent,
    WeekSelectorComponent,
    ColourPickerComponent,
    WhatsThisComponent,
    ReportChartComponent,
    ReportTableComponent,
    ReportTableResizerDirective,
    ReportFilterSettingComponent,
    ReportChartSettingComponent,
    ReportTableSettingComponent,
    ReportTableColumnComponent,
    AppRadioButtonComponent,
    HalfDoughnutChartComponent,
    TimePickerComponent,
    AddressInputComponent,
    RadioBoxComponent,
    AppFormItemComponent,
    ArchivedIconComponent,
    DaySelectorComponent,
    DurationInputComponent,
    DurationSplitInputComponent,
    UnitInputComponent,
    MonthSelectorComponent,
    CalendarMonthGridComponent,
    SegmentValueComponent,
    AttachmentPopoverComponent,
    MobilePopupMenuComponent,
    MobileSlideoutButtonComponent,
    UacDropdownComponent,
    NotificationDropdownComponent,
    NotificationDropdownContentComponent,
    ClientIconComponent,
    TaskIconComponent,
    ProjectIconComponent,
    ReportThumbnailComponent,
    AppSearchComponent,
    AppTabsComponent,
    DayDropdownComponent,
    FileUploaderComponent,
    WeekDayTabsComponent,
    WeekDaySegmentTotalsComponent,
    ThumbnailGraphicComponent,
    ReportNumericComponent,
    ReportNumericSettingComponent,
    InputAutocompleteComponent,
    UserIconComponent,
    AppVimeoPlayerComponent,
    AppYoutubePlayerComponent,
    InvoiceEditorComponent,
    InvoiceEditorLineComponent,
    FilePreviewComponent,
    ClockDropdownComponent,
    ProjectTaskClockComponent,
    ActiveClockListComponent,
    ReportDashComponent,
    ReportDashBlockComponent,
    MonthPickerComponent,
    MonthInputComponent,
    MenuButtonComponent,
    ProductIconComponent,
    InvoxyIntegrationIconComponent,
    LockedIconComponent,
    ClockFooterComponent,
    AppFormItemErrorComponent,
    DateRangeDropdownComponent,
    DateRangePickerComponent,
    KmConfettiComponent,
    AccountSelectDropdownComponent
  ]
})
export class LibComponentsModule { }
