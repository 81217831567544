import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import _ from 'lodash-es';

import {
  KmInvoiceLine,
  KmInvoice,
  PostableObjectErrorMap,
  RedactorService,
  InvoiceUtilService,
  CoreUtilService
} from '../../../public-api';

import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';
import { KmProjectStub } from '../../lib-models/km-project-stub/km-project-stub';
import { ProjectServiceInterface } from '../../lib-interfaces/project-service.interface';

@Component({
  selector: 'lib-invoice-line-editor-modal',
  templateUrl: './invoice-line-editor-modal.component.html',
  styleUrls: ['./invoice-line-editor-modal.component.scss']
})
export class InvoiceLineEditorModalComponent implements OnInit, AfterViewInit, AppModalInterface {

  readonly project_label = CoreUtilService.project_label;

  @Output() close = new EventEmitter<{ line: KmInvoiceLine, deleted_flag: boolean }>();
  @Output() dismiss = new EventEmitter<void>();

  @Input() invoice: KmInvoice;
  @Input() line_index: number;

  readonly currency_symbol = CoreUtilService.currency_symbol;
  readonly currency_code = CoreUtilService.currency_code;

  project: KmProjectStub = null;

  show_quantity: boolean = true;
  show_rate: boolean = true;
  show_total: boolean = true;

  line: KmInvoiceLine = null;
  line_errors: PostableObjectErrorMap = {};
  line_description_editor: any = null;

  constructor(
    @Inject('projectService') public projectService: ProjectServiceInterface
  ) { }

  ngOnInit(): void {
    this._initLineConfig();
  }

  private _initLineConfig() {
    for (const line of this.invoice.lines) {
      if (line.line_index === this.line_index) {
        this.line = line;
      }
    }
    if (!this.line) {
      this.cancel();
    }
    else {
      if (!!this.line.project_key) {
        this.project = this.projectService.getProjectStub(this.line.project_key);
      }

      this.show_quantity = true;
      this.show_rate = true;
      this.show_total = true;

      switch (this.line.line_type) {
        case 'Text':
          this.show_quantity = false;
          this.show_rate = false;
          this.show_total = false;
          break;
        case 'Project':
          this.show_quantity = false;
          break;
      }
    }
  }

  ngAfterViewInit(): void {
    this._initEditorForLine();
  }

  recalculateLineAmounts() {
    InvoiceUtilService.recalculateLineAmounts(this.invoice);
  }

  _initEditorForLine() {
    this.line_description_editor = RedactorService.createEditor(
      '#line-editor-modal-' + this.line.line_index,
      'invoice-line',
      this.line.line_description,
      null,
      this.invoice.posted_flag,
      { blur: () => this.line.line_description = this.line_description_editor.editor.getContent() }
    );
  }

  cancel() {
    this.dismiss.emit();
  }

  validateForm() {
    this.line.line_description = this.line_description_editor.editor.getContent();

    this.line_errors = this.invoice.getLineErrors(this.line);

    if (Object.keys(this.line_errors).length === 0) {
      this._saveLine();
    }
  }

  private _saveLine() {
    this.close.emit({
      line: this.line,
      deleted_flag: false
    });
  }

  deleteLine() {
    this.close.emit({
      line: this.line,
      deleted_flag: true
    });
  }

}
