import { flatMap } from 'lodash-es';
import { KmProjectStub } from '../km-project-stub/km-project-stub';

export class KmClientStub {

  client_key: number;
  client_name: string;
  contact_phone: string;
  billing_email: string;
  locked_flag: boolean;

  constructor(
    client_key: number,
    client_name: string,
    contact_phone: string,
    billing_email: string,
    invoxy_locked_flag: boolean = false,
    coworker_locked_flag: boolean = false
  ) {
    this.client_key = client_key;
    this.client_name = client_name;
    this.contact_phone = contact_phone;
    this.billing_email = billing_email;

    this.locked_flag = invoxy_locked_flag || coworker_locked_flag;
  }

}
