<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>Add {{report_label.capitalised_plural}}</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>
  </div>

  <div class="app-modal-content -contentGrey">
    <div class="app-modal-contentInner">

      <div class="app-reportTile-container"
           *ngIf="visible_reports.length">
        <ng-container *ngFor="let report of visible_reports">
          <div class="app-reportTile -smallTile"
               *ngIf="reportIsVisible(report)"
               (click)="selectReport(report)">
            <report-thumbnail [report]="report"></report-thumbnail>
            <div class="app-reportTile-details">
              <div class="app-reportTile-details-title">
                {{report.report_title}}
              </div>
              <div class="app-reportTile-details-type">
                {{report.report_type_label}}
              </div>
            </div>
          </div>
        </ng-container>

        <div class="app-reportTile -smallTile -newReport">
          <list-drop-down [button_color_class]="'-color-success-clear'"
                          [button_type_class]="'-type-icon'"
                          [button_icon_class]="'ion-ios-add'"
                          [item_label_prop]="'label'"
                          [items]="new_report_dropdown"
                          (item_selected)="dropdownItemSelected($event.item)">
          </list-drop-down>
        </div>
      </div>

      <div class="app-list-empty"
           [hidden]="!!visible_reports.length">
        <div class="app-list-emptyHeader">
          No {{report_label.capitalised_plural}}
        </div>
        <div class="app-list-emptyDescription">
          No {{report_label.lowercase_plural}} available
        </div>
        <list-drop-down class="app-list-emptyButton"
                        [button_color_class]="'-color-success'"
                        [button_type_class]="'-type-icon'"
                        [button_icon_class]="'ion-ios-add'"
                        [item_label_prop]="'label'"
                        [items]="new_report_dropdown"
                        (item_selected)="dropdownItemSelected($event.item)">
        </list-drop-down>
      </div>

    </div>
  </div>

  <div class="app-modal-btnFooter -desktop-only">
    <div class="app-modal-btnFooterLeft">
    </div>
    <div class="app-modal-btnFooterRight">
      <button class="-color-white-outline"
              (click)="cancel()">
        Close
      </button>
    </div>
  </div>
</div>
