import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService, CoreUtilService } from 'flexitime-library';
import { StateAccessService } from '../../../services/state-access/state-access.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  readonly query_params = CoreUtilService.parseQueryParams(this.route.snapshot.queryParams);

  route_path: string = null;
  route_params: any = null;

  constructor(
    public route: ActivatedRoute,
    public authService: AuthService,
    public stateAccessService: StateAccessService
  ) { }

  ngOnInit() {
    this.initialiseAppServices();
  }

  initialiseAppServices() {
    this.route_path = this.query_params.route_path || null;
    this.route_params = CoreUtilService.parseJSON(this.query_params.route_params);

    if (this.authService.hasSession()) {
      this.stateAccessService.clearServiceData();

      this.stateAccessService.ensureServicesInitialised()
        .then(() => {
          this.stateAccessService.reloadStateAccess(this.route_path, this.route_params);
        })
        .catch((err) => {
          this.authService.logout();
        });
    }
    else {
      this.authService.logout();
    }
  }

}
