<button class="-type-icon -color-grey-clear nd-button"
        (click)="openDropdown()">
  <i class="ion ion-md-notifications nd-icon">
  </i>

  <div class="nd-unseenCount"
       [hidden]="unseen_count === 0">
    {{unseen_count}}
  </div>
</button>
