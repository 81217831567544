import { Directive, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[file-reader]'
})
export class FileReaderDirective {

  readonly MAX_MB = 4;

  @Input() file: any = null;
  @Output() file_changed = new EventEmitter<{ file: any }>();
  @Output() file_error = new EventEmitter<{ error: string }>();

  @HostListener('change', ['$event'])
  onClick(event: any) {
    setTimeout(() => {
      const file = event.target.files[0];

      if (!!file) {
        const file_size = file.size / 1024 / 1024;

        if (file_size < this.MAX_MB) {
          this.file_changed.emit({
            file: event.target.files[0]
          });
        }
        else {
          this.element.nativeElement.value = null;
          this.file_error.emit({
            error: 'File must be less than ' + this.MAX_MB + ' MB.'
          });
        }
      }
    });
  }

  constructor(
    public element: ElementRef
  ) { }

}
