import _ from 'lodash-es';

import { PostableObjectErrorMap } from '../../../lib-interfaces/postable-object.interface';
import { ReportConfigTable, Label, ReportChartType, ReportMeasurePermission, ReportConfigMeasure, ReportConfigDimension } from '../../../lib.types';
import { Report, ReportFilter, ReportQuery } from '../report';

export type ReportSeries = {
  dimension: ReportConfigDimension,
  show_legend: boolean,
  stack: boolean
}

export class ChartReport extends Report {

  static readonly chart_type_labels: Record<ReportChartType, string> = {
    bar: 'Bar',
    horizontal_bar: 'Horizontal Bar',
    line: 'Line',
    pie: 'Pie'
  };

  measure: ReportConfigMeasure;
  dimension: ReportConfigDimension;
  series: ReportSeries;
  graph_function: ReportMeasurePermission;
  report_type_label: string;

  private _graph_type: ReportChartType;

  constructor(
    table_config: ReportConfigTable,
    graph_function: ReportMeasurePermission,
    graph_type: ReportChartType,
    dimension: ReportConfigDimension,
    measure: ReportConfigMeasure,
    report_key: number = null,
    report_title: string = '',
    labels: Label[] = [],
    filters: ReportFilter[] = [],
    series: ReportSeries = null
  ) {
    super(
      'CHART',
      table_config,
      report_key,
      report_title,
      labels,
      filters
    );

    this.dimension = dimension;
    this.measure = measure;
    this.series = series;
    this.graph_function = graph_function;
    this.graph_type = graph_type;
  }

  get graph_type(): ReportChartType {
    return this._graph_type;
  }
  set graph_type(graph_type: ReportChartType) {
    this._graph_type = graph_type;
    this.report_type_label = ChartReport.chart_type_labels[graph_type];
  }

  generateReportQuery(): ReportQuery {
    if (
      this._validateMeasure() &&
      this._validateDimension()
    ) {
      const measures = [
        {
          field: this.measure.id,
          func: this.graph_function
        }
      ];
      const series = !!this.series ? [this.series.dimension.id] : [];
      const fields: string[] = [this.dimension.id].concat(series);

      return {
        report_is_table: false,
        table: this.table_config.id,
        fields,
        groups: fields,
        measures,
        filters: this.formatFiltersForPosting()
      };
    }
    else {
      throw new Error('Invalid measure or dimension');
    }
  }

  getErrors(report_title_required: boolean = true): PostableObjectErrorMap {
    const errors = super.getErrors(report_title_required);

    if (!this.dimension) {
      errors['dimension'] = 'A category must be selected';
    }
    if (!this.measure) {
      errors['measure'] = 'A measure must be selected';
    }
    if (!this.graph_function) {
      errors['graph_function'] = 'An aggregate must be selected';
    }

    return errors;
  }

  formatForPosting(to_delete: boolean = false): any {
    const data = super.formatForPosting(to_delete);

    const graph_type = this.graph_type === 'horizontal_bar' ? 'horizontalBar' : this.graph_type;

    let series = null;
    if (!!this.series) {
      series = JSON.stringify({
        id: this.series.dimension.id,
        showLegend: this.series.show_legend,
        stack: this.series.stack
      });
    }

    data.measures = JSON.stringify([this.measure.id]);
    data.dimensions = JSON.stringify([this.dimension.id]);
    data.series = series;
    data.graph_function = this.graph_function;
    data.graph_type = graph_type;

    return data;
  }

  private _validateMeasure(): boolean {
    return _.findIndex(this.table_config.measures, { id: this.measure.id }) !== -1;
  }

  private _validateDimension(): boolean {
    return _.findIndex(this.table_config.dimensions, { id: this.dimension.id }) !== -1;
  }

}