import { Injectable } from '@angular/core';

import { EditorPlaceholder, EditorType } from '../../lib.types';

type RedactorEditorEvent = ('blur' | 'focus');

declare const RedactorX: any;

@Injectable({
  providedIn: 'root'
})
export class RedactorService {

  static createEditor(
    id: string,
    type: EditorType,
    content: string,
    placeholders: EditorPlaceholder[],
    disabled: boolean = false,
    events: Partial<Record<RedactorEditorEvent, () => void>> = {},
    reload: boolean = false
  ) {

    const mobile_profile_view: boolean = window.innerWidth <= 600;

    try {
      const settings: any = {
        addbar: {
          hide: ['image', 'pre']
        },
        code: false,
        content: content,
        editor: { focus: reload },
        embed: false,
        format: ['p', 'ul', 'ol'],
        plugins: ['disable', 'floating-toolbar'],
        quote: false,
        source: false,
        subscribe: {},
        toolbar: {}
      };

      if (!!events) {
        for (const event_type of Object.keys(events)) {
          settings.subscribe['editor.' + event_type] = () => events[event_type]();
        }
      }

      if (!!placeholders) {
        settings.plugins.push('variable');
        settings.variable = {
          items: placeholders
        };
      }

      if (!mobile_profile_view) {
        settings.plugins.push('underline');
      }

      if (type === 'textarea' || type === 'textarea-no-tables') {
        settings.editor.minHeight = '150px';
      }
      if (type === 'invoice-body') {
        settings.editor.minHeight = '60px';
        if (mobile_profile_view) {
          settings.toolbar.hide = ['deleted', 'link'];
        }
      }

      if (type !== 'invoice-line') {
        settings.plugins.push('alignment');
        settings.alignment = {
          align: {
            'left': 'text-left',
            'center': 'text-center',
            'right': 'text-right',
            'justify': 'text-justify'
          }
        };
        settings.plugins.push('fontsize');
      }

      switch (type) {
        case 'textarea-no-tables':
          settings.addbar.hide.push('table');
          if (mobile_profile_view) {
            settings.toolbar.hide = ['deleted', 'link'];
            settings.format = false;
          }
          break;
        case 'invoice-line':
          settings.addbar = false;
          settings.format = false;
          settings.editor.minHeight = '34px';
          break;
      }

      if (disabled) settings.disable = {
        disabled: true
      };

      const app = RedactorX(id, settings);

      if (!!app && !!reload) {
        app.stop();
        app.start(settings);
      }

      const mainContainer = app.container.get('main').nodes[0];
      if (type === 'invoice-body') mainContainer.classList.add('rx-container-invoice-body');
      if (type === 'invoice-line') mainContainer.classList.add('rx-container-invoice-line');

      return app;
    }
    catch (err) {
      console.log(err);
      return null;
    }
  }
}
