import { Inject, Injectable } from '@angular/core';

import mixpanel from 'mixpanel-browser';
import { KmCompany } from '../../lib-models/km-company/km-company';
import { InvUac } from '../../lib-models/inv-uac/inv-uac';
import { TimeUtilService } from '../time-util/time-util.service';
import { ApiService } from '../api/api.service';
import { DpUac } from '../../lib-models/dp-uac/dp-uac';
import { DpCompany } from '../../lib-models/dp-company/dp-company';


type EventType = (
  'Client Updated' |
  'Project Updated' |
  'Task Updated' |
  'Time Updated' |
  'Invoice Updated' |
  'Resume Updated' |
  'Clock Updated' |
  'Template Updated' |
  'Integration' |
  'Login' |
  'Roster' |
  'Availability' |
  'Notifications' |
  'Coworker Updated' |
  'Cover Request'
)

type UserData = {
  name: string,
  email: string,
  sign_up_date: string,
  number_of_clients: number,
  number_of_projects: number,
  last_logged_in: string
}

type ActionType = (
  'Created' |
  'Updated' |
  'Removed' |
  'Archived' |
  'Sent' |
  'Paid' |
  'Completed' |
  'Started' |
  'Stopped' |
  'Connected' |
  'Disconnected' |
  'Viewed' |
  'Shared' |
  'Logged in' |
  'Invited' |
  'Responded'
)

type ClientEventProperties = { client_key: number, action: ActionType }
type ProjectEventProperties = { project_key: number, action: ActionType }
type TaskEventProperties = { project_task_key: number, action: ActionType }
type TimeEventProperties = { segment_key: number, action: ActionType, method_of_recording?: string }
type InvoiceEventProperties = { invoice_key: number, action: ActionType, recipient?: string }
type ResumeEventProperties = { action: ActionType }
type ClockEventProperties = { clock_key: number, action: ActionType, clock_in_time?: Date, clock_out_time?: Date }
type TemplateEventProperties = { template_key: number, action: ActionType, template_type: ('Message' | 'Invoice') }
type IntegrationEventProperties = { integration_type: string, action: ActionType, integration_date?: string, invoice_key?: number }
type LoginEventProperties = { action: ActionType }
type RosterEventProperties = { action: ActionType }
type AvailabilityEventProperties = { action: ActionType }
type NotificationsEventProperties = { action: ActionType }
type CoworkerEventProperties = { action: ActionType, coworker_user_key?: number, coworker_email_address?: string, status?: ('Accepted' | 'Declined' | 'Pending') }
type CoverRequestEventProperties = { action: ActionType, original_person_key?: number, new_person_key?: string, note?: string, accepted?: boolean}

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  company_key: number;
  company_product_key: number;
  owner_flag: boolean;
  admin_flag: boolean;
  team_manager_flag: boolean;
  sample_company: boolean;
  user: UserData;

  token: string;

  service_setup: boolean = false;


  constructor(
    @Inject('env') public env: any,
    public apiService: ApiService
  ) {
    this.token = env.mixpanel_project_token;
  }

  format_functions: Record<EventType, Function> = {
    'Client Updated': this._formatClientEventData,
    'Project Updated': this._formatProjectEventData,
    'Task Updated': this._formatTaskEventData,
    'Time Updated': this._formatTimeEventData,
    'Invoice Updated': this._formatInvoiceEventData,
    'Resume Updated': this._formatResumeEventData,
    'Clock Updated': this._formatClockEventData,
    'Template Updated': this._formatTemplateEventData,
    'Integration': this._formatIntegrationEventData,
    'Login': this._formatLoginEventData,
    'Roster': this._formatRosterEventData,
    'Availability': this._formatAvailabilityEventData,
    'Notifications': this._formatNotificationsEventData,
    'Coworker Updated': this._formatCoworkerEventData,
    'Cover Request': this._formatCoverRequestEventData,
  }

  initialiseService(
    force_reload = false,
    product: string = 'Karmly',
    user_company: DpUac = null,
    company: DpCompany = null,
    company_admin: any = null,
    person: any = null
  ) {
    return new Promise<void>((resolve, reject) => {
      if (!!this.service_setup && !force_reload) {
        resolve();
      }
      else {
        if (product === 'Karmly') {
          this._getTrackingUserData()
            .then((user) => {
              if (!!user) {
                this.initialiseTrackingKarmly(user);
                this.service_setup = true;
                resolve();
              }
            })
            .catch((err) => reject());
        }
        else if (product === 'Droppah') {
          this.initialiseTrackingDroppah(user_company, company, person, company_admin);
          this.trackEvent('Login', 'Droppah', { action: 'Logged in' });
          resolve();
        }
        else resolve();
      }
    });
  }

  initialiseTrackingKarmly(user: any) {
    const user_data = this._formatUserData(user);
    if (!this.token || !user_data) {
      return;
    }
    mixpanel.init(this.token, { debug: false });

    mixpanel.identify(user?.external_user_access_key);

    //fields we can override
    mixpanel.people.set({
      number_of_clients: user_data.number_of_clients,
      number_of_projects: user_data.number_of_projects,
      last_logged_in: user_data.last_logged_in
    });

    //fields we only want to set if null
    //The $ values are for mixpanel premade variables, so we don't create our own version of email and use the one that already exists
    mixpanel.people.set_once({
      $name: user_data.name,
      $email: user_data.email,
      sign_up_date: user_data.sign_up_date
    });

    this.company_key = user?.company_key;
    this.company_product_key = user?.company_product_key;
  }

  initialiseTrackingDroppah(user_company: DpUac, company: DpCompany, person: any, company_admin: any) {
    if (!this.token || !user_company || !company || !person) {
      return;
    }
    mixpanel.init(this.token, { debug: false });

    mixpanel.identify(user_company.user_access_company_key);

    //The $ values are for mixpanel premade variables, so we don't create our own version of email and use the one that already exists
    mixpanel.people.set({
      $name: person?.display_name || company_admin?.full_name,
      $email: person?.email || company_admin?.registered_email
    });

    this.company_key = company.company_key;
    this.company_product_key = company.company_product_key;
    this.owner_flag = user_company.owner_flag;
    this.admin_flag = user_company.admin_flag;
    this.team_manager_flag = user_company.team_manager_flag;
    this.sample_company = company.sample_company;

    this.service_setup = true;
  }

  trackEvent(event: EventType, product: String, event_data: any) {
    if (!this.token || !this.service_setup) {
      return;
    }
    // format the event data
    const formatted_event_data = event_data ? this.format_functions[event](event_data) : {};

    // add shared properties
    // formatted_event_data.status = status;
    formatted_event_data.product = product;
    formatted_event_data.product_company_reference = this.company_key;
    formatted_event_data.company_product_key = this.company_product_key;

    if (formatted_event_data.action === 'Created' || formatted_event_data.action === 'Removed' || formatted_event_data.action === 'Archived') {
      const increment_amount = formatted_event_data.account === 'Created' ? 1 : -1;
      if (event === 'Client Updated') {
        mixpanel.people.increment('number_of_clients', increment_amount);
      }
      else if (event === 'Project Updated') {
        mixpanel.people.increment('number_of_projects', increment_amount);
      }
    }

    // // add extra properties for error status
    // if (status === 'Error' && error_data) {
    //   formatted_event_data.error_data = MixpanelService._formatErrorData(error_data);
    // }

    // call mixpanel
    mixpanel.track(event, formatted_event_data);
  }

  determinClientAction(client_key: number, to_delete: boolean): string {
    if (!!to_delete && !!client_key) return 'Removed';
    if (!!client_key) return 'Updated';
    if (!client_key && !to_delete) return 'Created';
    return null;
  }

  determinClockAction(to_delete: boolean): string {
    return !!to_delete ? 'Removed' : 'Stopped';
  }

  determinInvoiceAction(invoice_key: number, to_delete: boolean, posted_flag: boolean, paid_flag: boolean): string {
    if (!!to_delete && !!invoice_key) return 'Removed';
    if (!!invoice_key) return 'Updated';
    if (!!posted_flag) return 'Sent';
    if (!!paid_flag) return 'Paid';
    return null;
  }

  determinProjectAction(project_key: number, to_delete: boolean, to_archive: boolean, to_complete: boolean = false): string {
    if (!!to_delete && !!project_key) return 'Removed';
    if (!!to_archive && !!project_key) return 'Archived';
    if (to_complete) return 'Completed';
    if (!!project_key) return 'Updated';
    if (!project_key && !to_delete && !to_archive) return 'Created';
    return null;
  }

  determinProjectTaskAction(project_task_key: number, to_delete: boolean, completed_date: any): string {
    if (!!to_delete && !!project_task_key) return 'Removed';
    if (!!completed_date) return 'Completed';
    if (!!project_task_key) return 'Updated';
    if (!project_task_key && !to_delete) return 'Created';
    return null;
  }

  determinTimeAction(segment_key: number, to_delete: boolean): string {
    if (!!to_delete && !!segment_key) return 'Removed';
    if (!!segment_key) return 'Updated';
    if (!segment_key && !to_delete) return 'Created';
    return null;
  }

  private _getTrackingUserData() {
    return new Promise((resolve, reject) => {
      this.apiService.get('INVOXY', 'karmly/company/trackinguser', null, { hide_error: true })
        .then((data) => {
          resolve({
            name: data.name,
            email: data.email,
            company_key: data.company_key,
            company_product_key: data.company_product_key,
            external_user_access_key: data.external_user_access_key,
            sign_up_date: data.sign_up_date,
            number_of_clients: data.number_of_clients,
            number_of_projects: data.number_of_projects,
            number_of_sessions: data.number_of_sessions
          });
        })
        .catch((err) => reject(err));
    });
  }

  private _formatClientEventData(event_data): ClientEventProperties {
    return {
      client_key: event_data?.client_key || null,
      action: event_data?.action || null
    };
  }
  private _formatProjectEventData(event_data): ProjectEventProperties {
    return {
      project_key: event_data?.project_key || null,
      action: event_data?.action || null
    };
  }
  private _formatTaskEventData(event_data): TaskEventProperties {
    return {
      project_task_key: event_data?.project_task_key || null,
      action: event_data?.action || null
    };
  }
  private _formatTimeEventData(event_data): TimeEventProperties {
    return {
      segment_key: event_data?.segment_key || null,
      action: event_data?.action || null,
      method_of_recording: event_data?.method_of_recording || null
    };
  }
  private _formatInvoiceEventData(event_data): InvoiceEventProperties {
    return {
      invoice_key: event_data?.invoice_key || null,
      action: event_data?.action || null,
      recipient: event_data?.recipient || null
    };
  }
  private _formatResumeEventData(event_data): ResumeEventProperties {
    return {
      action: event_data?.action || null
    };
  }
  private _formatClockEventData(event_data): ClockEventProperties {
    return {
      clock_key: event_data?.clock_key || null,
      action: event_data?.action || null,
      clock_in_time: event_data?.clock_in_time || null,
      clock_out_time: event_data?.clock_out_time || null
    };
  }
  private _formatTemplateEventData(event_data): TemplateEventProperties {
    return {
      template_key: event_data?.template_key || null,
      action: event_data?.action || null,
      template_type: event_data?.template_type || null
    };
  }
  private _formatIntegrationEventData(event_data): IntegrationEventProperties {
    return {
      integration_type: event_data?.integration_type || null,
      action: event_data?.action || null,
      integration_date: event_data?.integration_date || null,
      invoice_key: event_data?.invoice_key || null
    };
  }

  private _formatLoginEventData(event_data): LoginEventProperties {
    return {
      action: event_data?.action || null
    };
  }

  private _formatRosterEventData(event_data): RosterEventProperties {
    return {
      action: event_data?.action || null
    };
  }

  private _formatAvailabilityEventData(event_data): AvailabilityEventProperties {
    return {
      action: event_data?.action || null
    };
  }

  private _formatNotificationsEventData(event_data): NotificationsEventProperties {
    return {
      action: event_data?.action || null
    };
  }
  private _formatCoworkerEventData(event_data): CoworkerEventProperties {
    return {
      action: event_data?.action || null,
      coworker_user_key: event_data?.coworker_user_key || null,
      coworker_email_address: event_data?.coworker_email_address || null,
      status: event_data?.status || null
    };
  }


  private _formatCoverRequestEventData(event_data): CoverRequestEventProperties {
    return {
      action: event_data?.action || null,
      original_person_key: event_data?.original_person_key || null,
      new_person_key: event_data?.new_person_key || null,
      note: event_data?.note || null,
      accepted: event_data?.accepted || null
    };
  }


  private _formatUserData(user): UserData {
    return {
      name: user.name || null,
      email: user.email || null,
      sign_up_date: user.sign_up_date || null,
      number_of_clients: user.number_of_clients || null,
      number_of_projects: user.number_of_projects || null,
      last_logged_in: TimeUtilService.dateToDateString(new Date(Date.now()))
    };
  }
}
