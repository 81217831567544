<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>Archive {{project_label.capitalised}}</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <div class="app-warningPanel">
        <div class="app-warningPanel-row">
          If you archive <span class="-bold">{{project_name}}</span> it will no longer be available to use and time recorded will no longer be
          editable. If you need to use this again you can simply restore it
        </div>
      </div>

      <div class="app-edit-content">
        <div class="col-md-12 app-edit-col">
          <div class="app-edit-row apm-confirm">
            Are you sure you want to archive <span class="-bold">{{project_name}}</span>?
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterRight">
      <button class="-color-success"
              (click)="archive()">
        Archive
      </button>
      <button class="-color-white-outline -desktop-only"
              (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
