import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Label } from '../../lib.types';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';

@Component({
  selector: 'label-dropdown',
  templateUrl: './label-dropdown.component.html',
  styleUrls: ['./label-dropdown.component.scss']
})
export class LabelDropdownComponent implements OnInit {

  readonly colour_pallete = CoreUtilService.colour_palette;

  visible_labels: Label[] = [];
  search: string = '';
  colour: string = this.colour_pallete[0][0];

  valid_label: boolean = false;

  @Input() dropdown_disabled: boolean = false;

  private _labels: Label[] = [];
  @Input()
  get labels(): Label[] {
    return this._labels;
  }
  set labels(value: Label[]) {
    this._labels = SortUtilService.sortList(value, { primary_sort_property: 'title' });
    setTimeout(() => {
      this.reloadVisibleLabels();
    });
  }

  @Input() labelIsVisible = (label: Label) => true;

  @Output() add_label = new EventEmitter<Label>();

  constructor() { }

  ngOnInit(): void {
    this.reloadVisibleLabels();
  }

  reloadVisibleLabels() {
    this.valid_label = false;

    if (!!this.search) {
      const new_label: Label = {
        title: this.search,
        color: this.colour
      };

      this.valid_label = !CoreUtilService.labelAlreadyExists(new_label, this.labels);

      // Filter visible labels
      const visible = [];
      const search_upper = this.search.toUpperCase();

      for (const label of this.labels) {
        if (
          this.labelIsVisible(label) &&
          label.title.toUpperCase().indexOf(search_upper) === 0
        ) {
          visible.push(label);
        }
      }
      this.visible_labels = visible;
    }
    else {
      const visible = [];

      for (const label of this.labels) {
        if (this.labelIsVisible(label)) {
          visible.push(label);
        }
      }
      this.visible_labels = visible;
    }
  }

  clearSearch() {
    this.search = '';
    this.reloadVisibleLabels();
  }

  newLabel() {
    if (this.valid_label) {
      this.add_label.emit({
        title: this.search,
        color: this.colour
      });
      this.search = '';
      this.reloadVisibleLabels();
    }
  }

  addExistingLabel(label: Label) {
    this.add_label.emit(label);
    this.reloadVisibleLabels();
  }

}
