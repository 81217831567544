<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-center">
        <div class="app-animatedTick">
          <img src="assets/icons/invoxyTickAnimated.svg">
        </div>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <div class="app-modal-contentInfo">
        <div>
          {{message}}
        </div>
      </div>

    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterRight">
      <button class="-color-white-outline"
              (click)="cancel()">
        Ok
      </button>
    </div>
  </div>
</div>
