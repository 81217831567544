import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CoreUtilService } from '../../../public-api';
import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';

@Component({
  selector: 'archive-project-modal',
  templateUrl: './archive-project-modal.component.html',
  styleUrls: ['./archive-project-modal.component.scss']
})
export class ArchiveProjectModalComponent implements AppModalInterface {

  readonly project_label = CoreUtilService.project_label;

  @Input() project_name: string;

  @Output() close = new EventEmitter<string>();
  @Output() dismiss = new EventEmitter<void>();

  archive() {
    this.close.emit(this.project_name);
  }

  cancel() {
    this.dismiss.emit();
  }
}
