import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreUtilService } from '../../../public-api';

import { ComponentLoaderDirective } from '../../lib-directives/component-loader/component-loader.directive';
import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';

@Component({
  selector: 'lib-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss']
})
export class ModalWrapperComponent implements OnInit {

  modal_wrapper_id: string = CoreUtilService.generateUUID();

  @ViewChild(ComponentLoaderDirective, { static: true }) component_loader!: ComponentLoaderDirective;

  // Component must implement AppModalInterface
  @Input() component: any;
  @Input() component_properties: Record<string, any> = {};

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.loadComponent();
  }

  loadComponent() {
    const view_container_ref = this.component_loader.view_container_ref;
    view_container_ref.clear();

    const component_instance = view_container_ref.createComponent(this.component).instance as AppModalInterface;

    for (const key of Object.keys(this.component_properties)) {
      component_instance[key] = this.component_properties[key];
    }

    component_instance.close.subscribe((result) => {
      this.activeModal.close(result);
    });
    component_instance.dismiss.subscribe(() => {
      this.activeModal.dismiss();
    });
  }

}
