import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import confetti from 'canvas-confetti';

const count = 200;
const defaults = {
  origin: { y: 0.7 }
};

@Component({
  selector: 'km-confetti',
  templateUrl: './km-confetti.component.html',
  styleUrls: ['./km-confetti.component.scss']
})
export class KmConfettiComponent implements OnInit {

  @HostListener('click', ['$event'])
  handleClick() {
    this.close();
  }
  @ViewChild('canvas_confetti') canvas_confetti: ElementRef;

  @Output() confettiFinished = new EventEmitter();

  myConfetti = null;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.myConfetti = confetti.create(this.canvas_confetti.nativeElement, { resize: true });
      this.run();

      setTimeout(() => {
        this.close();
      }, 1500);

    });

  }


  fire(particleRatio, opts) {
    this.myConfetti(Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio)
    }));
  }

  run() {
    this.fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    this.fire(0.2, {
      spread: 60,
    });
    this.fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    this.fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    this.fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  close() {
    this.confettiFinished.emit();
  }
}
