<div class="app-formItem-container">
  <div class="app-formItem-column">

    <app-form-item [label]="'Table'">
      <list-drop-down [item_label_prop]="'label'"
                      [button_title]="report.table_config?.label"
                      [list_fill_width]="true"
                      [items]="config.facts"
                      (item_selected)="selectFact($event.item)">
      </list-drop-down>
    </app-form-item>

    <app-form-item class="-allColumns"
                   [label]="'All Columns'"
                   [label_right_content]="true"
                   [hide_border]="true">

      <app-search appFormItem-labelRight
                  [(search)]="search"
                  (searchChange)="reloadVisibleColumns()">
      </app-search>

      <div class="app-list rts-columnList-container">

        <ng-container *ngIf="!!report.table_config">
          <div class="app-list-item -itemSelectDisabled -header"
               *ngIf="!!visible_dimensions.length">
            <div class="app-list-itemCell">
              Categories
            </div>
          </div>

          <ng-container *ngFor="let dimension of visible_dimensions">
            <div class="app-list-item"
                 *ngIf="!dimension.system_only_flag"
                 (click)="addDimension(dimension)">
              <div class="app-list-itemCell">
                <span [title]="dimension.label">
                  {{dimension.label}}
                </span>
              </div>
              <div class="app-list-itemCell -rowAdd">
                <i class="ion ion-ios-add"></i>
              </div>
            </div>
          </ng-container>


          <div class="app-list-item -itemSelectDisabled -header"
               *ngIf="!!visible_measures.length">
            <div class="app-list-itemCell">
              Measures
            </div>
          </div>

          <ng-container *ngFor="let measure of visible_measures">
            <div class="app-list-item"
                 *ngIf="!measure.system_only_flag"
                 (click)="addMeasure(measure)">
              <div class="app-list-itemCell">
                <span [title]="measure.label">
                  {{measure.label}}
                </span>
              </div>
              <div class="app-list-itemCell -rowAdd">
                <i class="ion ion-ios-add"></i>
              </div>
            </div>
          </ng-container>

        </ng-container>

        <div class="app-list-empty"
             *ngIf="!visible_dimensions.length && !visible_measures.length">
          <div class="app-list-emptyDescription"
               *ngIf="!available_dimensions.length && !available_measures.length">
            No more columns available for the <b>{{report.table_config?.label}}</b> table
          </div>
          <div class="app-list-emptyDescription"
               *ngIf="!!available_dimensions.length || !!available_measures.length">
            No columns match your search
          </div>
        </div>

      </div>

    </app-form-item>

    <app-form-item class="-selectedColumns"
                   [label]="'Selected Columns'"
                   [hide_border]="true">

      <div class="app-list rts-columnList-container">

        <ng-container *ngIf="!!report.table_config">
          <ng-container *ngFor="let dimension of report.dimensions">
            <div class="app-list-item -itemSelectDisabled">
              <div class="app-list-itemCell">
                <span [title]="dimension.label">{{dimension.label}}</span>
              </div>
              <div class="app-list-itemCell -rowDelete"
                   (click)="removeDimension(dimension)">
                <i class="ion ion-md-close app-remove"></i>
              </div>
            </div>
          </ng-container>

          <ng-container *ngFor="let table_measure of report.measures">
            <div class="app-list-item -itemSelectDisabled">
              <div class="app-list-itemCell">
                <span [title]="table_measure.measure.label">{{table_measure.measure.label}}</span>
              </div>
              <div class="app-list-itemCell -functionSelect">
                <list-drop-down [button_title]="table_measure.func"
                                [button_color_class]="'-color-white'"
                                [button_type_class]="'-type-text'"
                                [button_icon_class]="'ion-md-arrow-dropdown'"
                                [disabled]="!available_measure_function_map[table_measure.measure.id].length"
                                [items]="available_measure_function_map[table_measure.measure.id]"
                                (item_selected)="selectMeasureFunction(table_measure, $event.item)">
                </list-drop-down>
              </div>
              <div class="app-list-itemCell -rowDelete"
                   (click)="removeMeasure(table_measure)">
                <i class="ion ion-md-close app-remove"></i>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <div class="app-list-empty"
             *ngIf="!report.dimensions.length && !report.measures.length">
          <div class="app-list-emptyDescription">
            Add columns to this report from the list above
          </div>
        </div>

      </div>

    </app-form-item>

  </div>
</div>
