import { Component, Input, HostListener, HostBinding } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'app-checkbox',
  templateUrl: './app-checkbox.component.html',
  styleUrls: ['./app-checkbox.component.scss'],
  providers: [MakeProvider(AppCheckboxComponent)]
})
export class AppCheckboxComponent extends AbstractValueAccessor {

  @HostBinding('class.-disabled') @Input() disabled: boolean = false;
  @HostBinding('class.-readonly') @Input() readonly: boolean = false;

  _value: boolean;

  get value(): any {
    if (this.invertModel) {
      return !this._value;
    }
    else {
      return this._value;
    }
  }
  set value(v: any) {
    if (this.invertModel) {
      super.value = !v;
    }
    else {
      super.value = v;
    }
  }

  @Input() disableInternalToggle: boolean = false;
  @Input() tooltip: string = null;
  @Input() label: string = null;
  @Input() labelLeft: boolean = false;
  @Input() btnSmall: boolean = true;
  @Input() invertModel: boolean = false;

  @HostListener('click', ['$event'])
  handleClick() {
    if (!this.disableInternalToggle && !this.disabled && !this.readonly) {
      this.value = !this.value;
    }
  }
}
