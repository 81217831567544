import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { PostableObject, PostableObjectErrorMap } from './../../lib-interfaces/postable-object.interface';

export class DpPersonAvailability implements PostableObject<DpPersonAvailability> {

  private readonly _weekday_full_names = {
    Mon: 'Monday', Tue: 'Tuesday', Wed: 'Wednesday',
    Thu: 'Thursday', Fri: 'Friday', Sat: 'Saturday', Sun: 'Sunday'
  };

  person_availability_key: number;
  weekday: string;
  weekday_full: string;
  private _available_flag: boolean;
  private _all_day: boolean;
  private _start_time: Date;
  private _end_time: Date;

  constructor(
    weekday: string, person_availability_key: number = null, available_flag: boolean = true,
    start_time: string | Date = null, end_time: string | Date = null
  ) {
    this.weekday = weekday;
    this.weekday_full = this._weekday_full_names[this.weekday];
    this.person_availability_key = person_availability_key;
    this._available_flag = available_flag;

    this._initAvailabilityTimes(start_time, end_time);
    this._initAllDay();
  }

  get available_flag(): boolean {
    return this._available_flag;
  }
  set available_flag(available_flag: boolean) {
    this._available_flag = available_flag;
  }

  get all_day(): boolean {
    return this._all_day;
  }
  set all_day(all_day: boolean) {
    this._all_day = all_day;
  }

  get start_time(): Date {
    return this._start_time;
  }
  set start_time(start_time: Date) {
    this._start_time = start_time;
    this._all_day = false;
  }

  get end_time(): Date {
    return this._end_time;
  }
  set end_time(end_time: Date) {
    this._end_time = end_time;
    this._all_day = false;
  }

  private _initAllDay() {
    if (this._start_time !== null && this._end_time !== null) {
      this._all_day = false;
    }
    else {
      this._all_day = true;
      this._start_time = null;
      this._end_time = null;
    }
  }

  private _initAvailabilityTimes(start_time: string | Date, end_time: string | Date) {
    if (this._available_flag) {
      this._start_time = typeof start_time === 'string' ? TimeUtilService.timeStringToDate(start_time) : start_time;
      this._end_time = typeof end_time === 'string' ? TimeUtilService.timeStringToDate(end_time) : end_time;
    }
    else {
      this._start_time = null;
      this._end_time = null;
    }
  }

  validate(): { isValid: boolean, invalidFields: any } {
    const result: { isValid: boolean, invalidFields: any } = {
      isValid: true,
      invalidFields: {}
    };

    if (this.available_flag && !this.all_day) {
      if (this.start_time === null || !TimeUtilService.dateIsValid(this.start_time)) {
        result.invalidFields.start_time = true;
      }
      if (this.end_time === null || !TimeUtilService.dateIsValid(this.end_time)) {
        result.invalidFields.end_time = true;
      }
    }

    if (Object.keys(result.invalidFields).length > 0) {
      result.isValid = false;
    }
    return result;
  }


  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }


  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    /*
    if (this.available_flag && !this.all_day) {
      if (this.start_time === null || !TimeUtilService.dateIsValid(this.start_time)) {
        errors.push({
          error_path: 'start_time',
          error_message: 'Please enter a valid start time for availability on ' + this.weekday_full
        });
      }
      if (this.end_time === null || !TimeUtilService.dateIsValid(this.end_time)) {
        errors.push({
          error_path: 'end_time',
          error_message: 'Please enter a valid end time for availability on ' + this.weekday_full
        });
      }
    }*/

    return errors;
  }

  formatForPosting(): any {
    if (this.hasErrors()) {
      return {
        weekday: this.weekday,
        person_availability_key: this.person_availability_key,
        available_flag: this.available_flag,
        start_time: !this.available_flag || this.all_day ? null : TimeUtilService.dateToTimeString(this.start_time),
        end_time: !this.available_flag || this.all_day ? null : TimeUtilService.dateToTimeString(this.end_time),
      };
    }
    return null;
  }
}
