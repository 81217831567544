<button class="-type-icon"
        [disabled]="disabled"
        [ngClass]="product === 'DROPPAH' ? '-color-white-clear' : '-color-default-clear'"
        (click)="backWeek()">
  <i *ngIf="show_prev_button"
     class="ion ion-md-arrow-dropleft"></i>
</button>

<div class="weekSelector-textContainer"
     ngbDropdown
     [container]="'body'"
     [display]="'static'"
     [dropdownClass]="'dropdown'"
     (openChange)="dropdownToggled($event)">
  <div ngbDropdownToggle
       *ngIf="week_relation_string === null">
    {{week_start | date : 'd'}} - {{week_end | date : 'd MMM, yyyy'}}
  </div>
  <div ngbDropdownToggle
       *ngIf="week_relation_string !== null">{{week_relation_string}}</div>
  <div class="app-datepicker-dropdown"
       ngbDropdownMenu>
  <ngb-datepicker [(ngModel)]="date_picked"></ngb-datepicker>
  <div class="app-week-relation-buttons"
         *ngIf="show_week_relation_buttons">
      <div class="app-week-relation-button"
           (click)="weekRelationButton(-1)">
        <p>Last Week</p>
      </div>
      <div class="app-week-relation-button"
           (click)="weekRelationButton(0)">
        <p>This Week</p>
      </div>
      <div class="app-week-relation-button"
           (click)="weekRelationButton(1)">
        <p>Next Week</p>
      </div>
    </div>
  </div>
</div>

<button class="-type-icon"
        [disabled]="disabled"
        [ngClass]="product === 'DROPPAH' ? '-color-white-clear' : '-color-default-clear'"
        (click)="forwardWeek()">
  <i *ngIf="show_next_button"
     class="ion ion-md-arrow-dropright"></i>
</button>
