import { GridsterItem } from 'angular-gridster2';
import _ from 'lodash-es';

import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { Segment } from '../../../lib-models/segment/segment';
import { DateTime, Duration } from 'luxon';
import { TimeUserCalendarEvent } from './time-user-calendar-event';

type Handle = ('NORTH' | 'SOUTH');

export class TimeUserCalendarSegment implements GridsterItem {

  private _segment: Segment = null;
  get segment(): Segment {
    return this._segment;
  }
  set segment(segment: Segment) {
    this._segment = segment;

    this.multi_day_segment = this._multi_day_segment();
    this._initCalendarSegmentExtension();
  }

  calendar_start_date: Date = null;
  calendar_end_date: Date = null;
  days_in_calendar: number = null;
  multi_day_segment: boolean = false;

  private _is_copied_segment: boolean = false;

  // Not null if this is the second part of a multiday segment
  // parent_calendar_segment: TimeEmployeeCalendarSegment = null;
  // Not null if this is the first part of a multiday segment
  extension: TimeUserCalendarSegmentExtension = null;

  // Number of minutes per row
  private _mins_per_row: number = null;
  private _rows_per_hour: number = null;

  is_locked: boolean = false;

  tu_calendar_events: TimeUserCalendarEvent[] = [];

  readonly cols: number = 1;
  rows: number = 0;
  x: number = -1;
  y: number = -1;
  layerIndex: number = 1003;
  resizeEnabled: boolean = true;
  dragEnabled: boolean = true;
  x_offset: number = 0;

  constructor(
    segment: Segment,
    calendar_start_date: Date,
    calendar_end_date: Date,
    is_copied_segment: boolean = false,
    mins_per_row: number = 15,
    tu_calendar_events: TimeUserCalendarEvent[] = []
  ) {
    this._mins_per_row = mins_per_row;
    this._rows_per_hour = 60 / this._mins_per_row;
    this.tu_calendar_events = tu_calendar_events;

    this.calendar_start_date = TimeUtilService.getCleanDate(calendar_start_date);
    this.calendar_end_date = TimeUtilService.getCleanDate(calendar_end_date);
    this._initDaysInCalendar();

    this.is_copied_segment = is_copied_segment;
    this.segment = segment;

    this._initDimensions();
  }

  private _getXOffset(){
    const overlapping_calendar_events = [];

    for (const ce of this.tu_calendar_events) {
      if (TimeUtilService.twoPeriodsOverlap(
        this.segment.start_time, this.segment.end_time,
        ce.calendar_event.start_time, ce.calendar_event.end_time,
      )) {
        overlapping_calendar_events.push(ce);
      }
    }

    let max_x_offset = 0;
    for (const ce of overlapping_calendar_events) {
      max_x_offset = Math.max(max_x_offset, ce.x_offset + 1);
    }
    return max_x_offset;
  }

  private _initDaysInCalendar() {
    const start = DateTime.fromJSDate(this.calendar_start_date);
    const end = DateTime.fromJSDate(this.calendar_end_date);
    this.days_in_calendar = end.diff(start, 'days').days + 1;
  }

  get is_copied_segment(): boolean {
    return this._is_copied_segment;
  }
  set is_copied_segment(val: boolean) {
    this._is_copied_segment = val;

    this.layerIndex = val ? 1002 : 1003;
    if (!!this.extension) {
      this.extension.layerIndex = val ? 1002 : 1003;
    }
  }

  updateSegmentOnDrag(): any {
    const segment_date = TimeUtilService.incrementDate(this.calendar_start_date, this.x);

    const midnight = TimeUtilService.getCleanDate(segment_date);
    const start_time = TimeUtilService.incrementMinutes(midnight, this._mins_per_row * this.y);

    const total_mins = Duration.fromObject({ hours: this.segment.duration + this.segment.break_duration }).as('minutes');
    const end_time = DateTime.fromJSDate(start_time).plus({ minutes: total_mins }).toJSDate();

    this.segment.segment_date = segment_date;
    this.segment.start_time = start_time;
    this.segment.end_time = end_time;

    this.multi_day_segment = this._multi_day_segment();

    if (!!this.multi_day_segment) {
      this._initCalendarSegmentExtension();
      this.x = this._getCol(this.segment.start_time);
      this.rows = this._getRows();
    }
    else {
      this.extension = null;
    }
  }

  updateSegmentOnResize(handle: Handle): boolean {
    let redraw = false;
    if (handle === 'NORTH') {
      const start_time = TimeUserCalendarSegment.getStartTimeFromRow(this.segment.start_time, this.y, this._mins_per_row);
      const new_total_mins = TimeUtilService.totalMinutesBetweenTwoDates(start_time, this.segment.end_time);

      if (new_total_mins > 1440) {
        this.updateSegmentDimensions();
        redraw = true;
      }
      else {
        this.segment.start_time = start_time;
      }
    }
    else if (handle === 'SOUTH' && !this.multi_day_segment) {
      const end_time = TimeUserCalendarSegment.getEndTimeFromRow(this.segment.end_time, this.y + this.rows, this._mins_per_row);
      const new_total_mins = TimeUtilService.totalMinutesBetweenTwoDates(this.segment.start_time, end_time);

      if (new_total_mins > 1440) {
        this.updateSegmentDimensions();
        redraw = true;
      }
      else {
        this.segment.end_time = end_time;
      }
    }
    return redraw;
  }

  updateSegmentExtensionOnResize(handle: Handle): boolean {
    let redraw = false;
    if (handle === 'SOUTH') {
      const end_time = TimeUserCalendarSegment.getEndTimeFromRow(this.segment.end_time, this.extension.rows, this._mins_per_row);
      const new_total_mins = TimeUtilService.totalMinutesBetweenTwoDates(this.segment.start_time, end_time);

      if (new_total_mins > 1440) {
        this.updateSegmentDimensions();
        redraw = true;
      }
      else {
        this.segment.end_time = end_time;
      }
    }
    return redraw;
  }

  updateSegmentDimensions() {
    this.x = this._getCol(this.segment.start_time);
    this.y = this._getRow(this.segment.start_time);
    this.rows = this._getRows();
    this.x_offset = this._getXOffset();
    this._checkIsLocked();

    if (!!this.extension) {
      this.extension.rows = this._getRow(this.segment.end_time);
      this.extension.x = this._getCol(this.segment.end_time);
    }
  }

  private _checkIsLocked() {
    this.is_locked = this.rows <= 1 || this.segment.is_locked;
    this.resizeEnabled = !this.is_locked;
    this.dragEnabled = !this.is_locked;
  }

  private _initDimensions() {
    if (this.segment.overlapsPeriodOfDays(this.calendar_start_date, this.calendar_end_date)) {
      this.updateSegmentDimensions();
    }
  }

  private _initCalendarSegmentExtension() {
    if (this.multi_day_segment) {
      const rows = this._getRow(this.segment.end_time);
      const x = this._getCol(this.segment.end_time);

      this.extension = new TimeUserCalendarSegmentExtension(
        rows, x, this.segment,
        (handle) => this.updateSegmentExtensionOnResize(handle)
      );
    }
    else {
      this.extension = null;
    }
  }

  private _getRows(): number {
    if (this.multi_day_segment) {
      return Math.max(1, (1440 / this._mins_per_row) - this.y);
    }
    else {
      return Math.max(1, this._getRow(this.segment.end_time) - this.y);
    }
  }

  private _getRow(time: Date): number {
    const hours = time.getHours();
    const totalMins = time.getMinutes();
    const roundedMins = totalMins - (totalMins % this._mins_per_row);
    return (hours * this._rows_per_hour) + (roundedMins / this._mins_per_row);
  }

  private _getCol(date: Date): number {
    const diff = TimeUtilService.totalDaysBetweenTwoDates(date, this.calendar_start_date, false);
    return (diff < 0 || diff >= this.days_in_calendar) ? -1 : diff;
  }

  private _multi_day_segment(): boolean {
    return !DateTime.fromJSDate(this.segment.start_time).hasSame(DateTime.fromJSDate(this.segment.end_time), 'day');
  }

  static getStartTimeFromRow(date: Date, row: number, mins_per_row: number) {
    return this.getEndTimeFromRow(date, row, mins_per_row);
  }

  static getEndTimeFromRow(date: Date, row: number, mins_per_row: number) {
    const rows_per_hour = 60 / mins_per_row;

    const hours = Math.floor(row / rows_per_hour);
    const mins = (row % rows_per_hour) * mins_per_row;

    return TimeUtilService.updateTime(date, hours, mins);
  }

}

export class TimeUserCalendarSegmentExtension implements GridsterItem {

  readonly cols: number = 1;
  readonly dragEnabled: boolean = false;
  readonly y: number = 0;
  resizeEnabled: boolean = true;
  rows: number = 0;
  x: number = -1;
  layerIndex: number = 1002;

  segment: Segment;

  updateSegmentOnResize: (handle: Handle) => boolean;

  constructor(
    rows: number,
    x: number,
    segment: Segment,
    updateSegmentExtensionOnResize: (handle: Handle) => boolean
  ) {
    this.rows = rows;
    this.x = x;
    this.segment = segment;
    this.updateSegmentOnResize = updateSegmentExtensionOnResize;
    this.resizeEnabled = this.x !== 0 && !this.segment.is_locked;
  }

}
