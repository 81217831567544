import { Injectable } from '@angular/core';
import _ from 'lodash-es';

import { CoreUtilService } from '../core-util/core-util.service';
import { SortUtilService } from '../sort-util/sort-util.service';
import { KmInvoice, KmInvoiceLine } from '../../lib-models/km-invoice/km-invoice';
import { InvoiceLineType, InvoiceLineTypeValue, ProductValue } from '../../lib.types';
import { KmInvoiceTemplate } from '../../../public-api';

@Injectable({
  providedIn: 'root'
})
export class InvoiceUtilService {

  static invoice_line_type_map: Record<InvoiceLineTypeValue, InvoiceLineType> = {
    Expense: {
      label: 'Expense',
      icon_class: 'ion-md-pricetag',
      value: 'Expense',
      hide_quantity_flag: true,
      hide_rate_flag: false
    },
    Fixed: {
      label: 'Fixed Charge',
      icon_class: 'bi-currency-dollar',
      value: 'Fixed',
      hide_quantity_flag: true,
      hide_rate_flag: false
    },
    Time: {
      label: 'Timesheet Line',
      icon_class: 'ion-md-time',
      value: 'Time',
      hide_quantity_flag: false,
      hide_rate_flag: false
    },
    Placement: {
      label: 'Placement Fee',
      icon_class: 'ion-ios-briefcase',
      value: 'Placement',
      hide_quantity_flag: true,
      hide_rate_flag: false
    },
    Project: {
      label: 'Fixed Fee Project',
      icon_class: 'ion-ios-briefcase',
      value: 'Project',
      hide_quantity_flag: true,
      hide_rate_flag: false
    },
    Project_Task: {
      label: 'Fixed Fee Task',
      icon_class: 'ion-ios-briefcase',
      value: 'Project_Task',
      hide_quantity_flag: true,
      hide_rate_flag: false
    },
    Percent: {
      label: 'Percentage Fee',
      icon_class: 'bi-percent',
      value: 'Percent',
      hide_quantity_flag: true,
      hide_rate_flag: false
    },
    Text: {
      label: 'Text Line',
      icon_class: 'ion-md-create',
      value: 'Text',
      hide_quantity_flag: true,
      hide_rate_flag: true
    },
    Subtotal: {
      label: 'Subtotal',
      icon_class: null,
      value: 'Subtotal',
      hide_quantity_flag: true,
      hide_rate_flag: true
    }
  };

  static getUserCreatableInvoiceLineTypes(product: ProductValue): InvoiceLineType[] {
    const types = this.invoice_line_type_map;

    switch (product) {
      case 'KARMLY':
        return [
          types.Time,
          types.Project,
          types.Expense,
          types.Fixed,
          types.Text,
          types.Project_Task
        ];
    }
    return [];
  }

  static getInvoiceLineType(value: InvoiceLineTypeValue): InvoiceLineType {
    return this.invoice_line_type_map[value] || null;
  }

  static getInvoiceLineIcon(line_type: InvoiceLineTypeValue): string {
    return this.invoice_line_type_map[line_type]?.icon_class || null;
  }

  static recalculateTemplateLineAmounts(invoice_template: KmInvoiceTemplate): void {
    for (const line of invoice_template.lines) {
      line.quantity = 1;
      line.rate = CoreUtilService.roundNumber(
        parseFloat(line.rate as any || 0), 5
      );
    }
  }

  // net_amount/tax_amount/gross_amount rounded to 2dp
  // quantity/rate rounded to 5dp
  static recalculateLineAmounts(invoice: KmInvoice): void {
    let net_amount = 0;
    let tax_amount = 0;

    for (const line of invoice.lines) {
      if (!line.deleted_flag) {

        this.recalculateLineValues(line, net_amount);
        tax_amount += line.tax_amount;
        net_amount += line.net_amount;
      }
    }

    invoice.net_amount = CoreUtilService.roundNumber(
      net_amount, 2
    );
    invoice.tax_amount = CoreUtilService.roundNumber(
      tax_amount, 2
    );
    invoice.gross_amount = CoreUtilService.roundNumber(
      net_amount + tax_amount, 2
    );
  }

  // net_amount/tax_amount/gross_amount rounded to 2dp
  // quantity/rate rounded to 5dp
  static recalculateLineValues(
    line: KmInvoiceLine,
    current_line_group_net_amount: number
  ) {
    line.rate = CoreUtilService.roundNumber(
      parseFloat(line.rate as any || 0), 5
    );
    line.quantity = CoreUtilService.roundNumber(
      parseFloat(line.quantity as any || 0), 5
    );

    line.net_amount = CoreUtilService.roundNumber(
      line.quantity * line.rate, 2
    );

    //round each line total to 2dp to prevent changes modal (matches db)
    line.tax_amount = CoreUtilService.roundNumber(
      line.net_amount * (line.tax_rate / 100), 2
    );
    line.gross_amount = CoreUtilService.roundNumber(
      line.net_amount + line.tax_amount, 2
    );
  }

  static recalculateLineIndices(invoice: KmInvoice): void {
    let index = 0;

    for (const line of invoice.lines) {
      if (!line.deleted_flag) {
        line.line_index = index;

        index++;
      }
    }
  }

  static recalculateTemplateLineNumbersAndIndices(invoice: KmInvoiceTemplate): void {
    let index = 0;

    for (const line of invoice.lines) {
      if (!line.deleted_flag) {
        line.line_index = index;

        index++;
      }
    }
  }

  static moveInvoiceLinesIntoSubtotalGroups(invoice_lines: any[]): void {
    for (let i = invoice_lines.length - 1; i >= 0; i--) {
      const line = invoice_lines[i];

      if (line.line_index.indexOf('.') !== -1) {
        const subtotal_line_index = parseInt(line.line_index) + '';

        const subtotal = _.find(invoice_lines, { line_index: subtotal_line_index });
        // Move line from main invoice line list to subtotal line list
        if (!!subtotal) {
          subtotal.lines.push(line);
          invoice_lines.splice(i, 1);
        }
      }
    }

    for (const line of invoice_lines) {
      if (line.lines.length) {
        SortUtilService.sortList(line.lines, { primary_sort_property: 'line_index' });
      }
    }
  }

}
