import { ElementRef, EventEmitter, QueryList, SimpleChanges } from '@angular/core';
import { GridsterComponent, GridsterConfig, GridsterItemComponent } from 'angular-gridster2';
import { Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import _ from 'lodash-es';

import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { TimeUserCalendarSegment, TimeUserCalendarSegmentExtension } from './time-user-calendar-segment';
import { Segment } from '../../../lib-models/segment/segment';
import { StateDataService } from '../../../lib-services/state-data/state-data.service';
import { SortUtilService, NavMenuServiceAbstract, CalendarEvent, TimeUserCalendarEvent } from '../../../../public-api';

type Handle = ('NORTH' | 'SOUTH');
export type CalendarSegment = (
  TimeUserCalendarSegment | TimeUserCalendarSegmentExtension
);
export type TimeUserCalendarNewCalendarSegment = {
  cols: number,
  rows: number,
  x: number,
  y: number,
  layerIndex: number,
  start_time: Date,
  end_time: Date
};
export type TimeUserCalendarNewSegmentEvent = {
  segment?: Segment,
  start_time?: Date,
  end_time?: Date,
  origin_component: string
};
export type TimeUserDailyCalendarEvent = {
  calendar_event: CalendarEvent,
  start_index: number,
  cols: number,
  y_offset: number
};

export abstract class TimeUserCalendar {

  private _component_visible: boolean = false;

  // ViewChildren
  abstract gridster_component: GridsterComponent;
  abstract grid_container_outer: ElementRef;
  abstract new_calendar_segment_component: GridsterItemComponent;
  abstract calendar_segment_components: QueryList<GridsterItemComponent>;
  abstract calendar_segment_extension_components: QueryList<GridsterItemComponent>;

  // Inputs
  abstract segments: Segment[];
  abstract calendar_events: CalendarEvent[];
  abstract show_calendar_events: boolean;
  abstract loading: boolean;

  // Outputs
  abstract new_segment: EventEmitter<TimeUserCalendarNewSegmentEvent>;
  abstract update_segment: EventEmitter<{ segment: Segment }>;
  abstract edit_segment: EventEmitter<{ segment: Segment }>;

  abstract copied_segment: TimeUserCalendarSegment;
  abstract calendar_segments: TimeUserCalendarSegment[];
  abstract tu_calendar_events: TimeUserCalendarEvent[];
  abstract tu_daily_calendar_events: TimeUserDailyCalendarEvent[];

  max_daily_calendar_event_y_offset: number = 0;

  selected_week = this.stateDataService.selected_week;

  new_calendar_segment: TimeUserCalendarNewCalendarSegment = null;
  drag_start_y: number = null;

  calendar_hours: Date[] = [];
  gridster_rows: number = 96;
  gridster_cols: number = 7;
  // Number of minutes per row
  gridster_mins_per_row: number = 15;
  // Row height in px
  gridster_row_height: number = 15;
  gridster_options: GridsterConfig;

  calendar_segment_backup: CalendarSegment = null;
  scroll_initialised = false;

  event_subscriptions: Subscription[] = [];

  constructor(
    public stateDataService: StateDataService,
    public navMenuService: NavMenuServiceAbstract,
    public elementRef: ElementRef
  ) { }

  abstract generateCopiedSegment(s: Segment): Segment;

  onInit(): void {
    this.initGridTimes();
    this.initGridsterOptions();
    this._initEventListeners();
  }

  onChanges(changes: SimpleChanges) {
    if (!!changes.segments || !!changes.calendar_events) {
      this.refreshComponent();
    }
  }

  onDestroy() {
    this._clearEventListeners();
  }

  afterViewChecked(): void {
    if (!this.scroll_initialised) {
      this.scroll_initialised = true;
      this.scrollToHour(9);
    }
    this._checkComponentVisibility();
  }

  // Trigger Gridster to check if it needs to re-render after the visiblilty of the component has changed
  private _checkComponentVisibility() {
    // offsetParent is null when component or a parent component is hidden
    const component_visible = !!this.elementRef.nativeElement.offsetParent;
    if (this._component_visible !== component_visible) {
      this._component_visible = component_visible;

      if (this._component_visible) {
        setTimeout(() => this.resizeCalendar());
      }
    }
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.navMenuService.subscribeToEvent(
        'NAV_MENU_TOGGLED',
        () => this.resizeCalendar()
      )
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  goToCalendarEvent(calendar_event: CalendarEvent) {
    if (!!calendar_event.event_html_link) {
      window.open(calendar_event.event_html_link, '_blank');
    }
  }

  resizeCalendar() {
    this.gridster_options.api.resize();
  }

  segmentVisibleInCalendar(segment: Segment): boolean {
    const fifteen_mins = 1 / 60 * 15;
    return segment.duration >= fifteen_mins &&
      segment.overlapsPeriodOfDays(
        this.stateDataService.selected_week_start,
        this.stateDataService.selected_week_end
      );
  }

  eventInSelectedWeek(event: CalendarEvent): boolean {
    return event.overlapsPeriodOfDays(
      this.stateDataService.selected_week_start,
      this.stateDataService.selected_week_end
    );
  }

  eventVisibleInCalendar(calendar_event: CalendarEvent): boolean {
    return !!calendar_event.start_time &&
      !!calendar_event.end_time &&
      !calendar_event.is_greater_than_24_hours &&
      this.eventInSelectedWeek(calendar_event);
  }

  refreshComponent() {
    this.initCalendarEvents();
    this.initCalendarSegments();
  }

  redrawSegment(segment_key: number): void {
    const cs_comps = this.calendar_segment_components.toArray();
    const csx_comps = this.calendar_segment_extension_components.toArray();

    for (const cs_comp of cs_comps) {
      if (cs_comp.item.segment.segment_key === segment_key) {
        TimeUserCalendar.redrawSegmentComponent(cs_comp);
      }
    }
    for (const csx_comp of csx_comps) {
      if (csx_comp.item.segment.segment_key === segment_key) {
        TimeUserCalendar.redrawSegmentComponent(csx_comp);
      }
    }
  }

  redrawNewSegment() {
    if (!!this.new_calendar_segment_component) {
      this.new_calendar_segment_component.$item.rows = this.new_calendar_segment_component.item.rows;
      this.new_calendar_segment_component.$item.x = this.new_calendar_segment_component.item.x;
      this.new_calendar_segment_component.$item.y = this.new_calendar_segment_component.item.y;
      this.new_calendar_segment_component.$item.layerIndex = this.new_calendar_segment_component.item.layerIndex;

      this.new_calendar_segment_component.setSize();
      this.new_calendar_segment_component.checkItemChanges(this.new_calendar_segment_component.$item, this.new_calendar_segment_component.item);
    }
  }

  static redrawSegmentComponent(cs_component: GridsterItemComponent) {
    cs_component.$item.rows = cs_component.item.rows;
    cs_component.$item.x = cs_component.item.x;
    cs_component.$item.y = cs_component.item.y;
    cs_component.$item.layerIndex = cs_component.item.layerIndex;
    cs_component.$item.resizeEnabled = cs_component.item.resizeEnabled;
    cs_component.$item.dragEnabled = cs_component.item.dragEnabled;

    cs_component.setSize();
    cs_component.checkItemChanges(cs_component.$item, cs_component.item);
  }

  initCalendarSegments() {
    this.selected_week = this.stateDataService.selected_week;
    this.calendar_segments = [];
    const visible_segments = [];

    for (const segment of this.segments) {
      if (
        !segment.unit_flag &&
        this.segmentVisibleInCalendar(segment)
      ) {
        visible_segments.push(segment);
      }
    }

    SortUtilService.sortList(
      visible_segments,
      {
        primary_sort_property: 'start_time',
        forward_order: false
      }
    );

    for (const segment of visible_segments) {
      this.calendar_segments.push(this.generateCalendarSegment(segment, false));
    }
  }

  private _getCalendarSegmentXOffset(segment: Segment) {
    const overlapping_calendar_events = [];

    for (const ce of this.tu_calendar_events) {
      if (TimeUtilService.twoPeriodsOverlap(
        segment.start_time, segment.end_time,
        ce.calendar_event.start_time, ce.calendar_event.end_time,
      )) {
        overlapping_calendar_events.push(ce);
      }
    }

    let max_x_offset = 0;
    for (const ce of overlapping_calendar_events) {
      max_x_offset = Math.max(max_x_offset, ce.x_offset + 1);
    }
    return max_x_offset;
  }

  generateCalendarSegment(
    segment: Segment,
    is_copied_segment: boolean = false,
  ): TimeUserCalendarSegment {
    const x_offset = this._getCalendarSegmentXOffset(segment);

    return new TimeUserCalendarSegment(
      segment,
      this.stateDataService.selected_week_start,
      this.stateDataService.selected_week_end,
      is_copied_segment,
      this.gridster_mins_per_row,
      this.tu_calendar_events
    );
  }

  initCalendarEvents() {
    this.selected_week = this.stateDataService.selected_week;
    const visible_calendar_events = [];

    for (const calendar_event of this.calendar_events) {
      if (this.eventInSelectedWeek(calendar_event)) {
        visible_calendar_events.push(calendar_event);
      }
    }

    SortUtilService.sortList(
      visible_calendar_events,
      {
        primary_sort_property: 'start_time',
        forward_order: false
      }
    );

    this.tu_calendar_events = [];
    this.tu_daily_calendar_events = [];

    for (const calendar_event of visible_calendar_events) {
      if (this.eventVisibleInCalendar(calendar_event)) {
        this.generateCalendarEvent(calendar_event);
      }
      // Event without an end time or event of longer than 24 hours
      else {
        this.generateDailyCalendarEvent(calendar_event);
      }
    }
    this._updateMaxDailyCalendarEventYOffset();
  }

  private _updateMaxDailyCalendarEventYOffset() {
    let max_y_offset = 0;
    for (const dce of this.tu_daily_calendar_events) {
      if (dce.y_offset > max_y_offset) {
        max_y_offset = dce.y_offset;
      }
    }
    this.max_daily_calendar_event_y_offset = max_y_offset;
  }

  private _getCalendarEventXOffset(calendar_event: CalendarEvent): number {
    let x_offset = 0;
    for (const ce of this.tu_calendar_events) {
      if (TimeUtilService.twoPeriodsOverlap(
        calendar_event.start_time, calendar_event.end_time,
        ce.calendar_event.start_time, ce.calendar_event.end_time
      )) {
        x_offset++;
      }
    }
    const previous_ce = this.tu_calendar_events[this.tu_calendar_events.length - 1];
    if (!!previous_ce && x_offset <= previous_ce.x_offset) {
      x_offset = Math.max(0, x_offset - 1);
    }

    return x_offset;
  }

  generateCalendarEvent(calendar_event: CalendarEvent): void {
    const x_offset = this._getCalendarEventXOffset(calendar_event);

    this.tu_calendar_events.push(
      new TimeUserCalendarEvent(
        calendar_event,
        this.stateDataService.selected_week_start,
        this.stateDataService.selected_week_end,
        this.gridster_mins_per_row,
        x_offset,
        this.tu_calendar_events.length
      )
    );
  }

  generateDailyCalendarEvent(calendar_event: CalendarEvent): void {
    const week_start = DateTime.fromJSDate(this.selected_week[0]);

    const event_start = DateTime.fromJSDate(calendar_event.start_time).startOf('day');
    const event_end = !!calendar_event.end_time ?
      DateTime.fromJSDate(calendar_event.end_time) : event_start;

    const start_index = Math.max(
      event_start.diff(week_start, 'days').as('days'),
      0
    );
    const cols = Math.min(
      Math.ceil(event_end.diff(event_start).as('days')),
      (7 - start_index)
    );
    const y_offset = this._getDailyCalendarEventYOffset(calendar_event);

    this.tu_daily_calendar_events.push({
      calendar_event,
      start_index,
      cols,
      y_offset
    });
  }

  private _getDailyCalendarEventYOffset(calendar_event: CalendarEvent): number {
    let y_offset = 0;
    for (const ce of this.tu_daily_calendar_events) {
      if (calendar_event.overlapsPeriodOfDays(
        ce.calendar_event.start_time,
        ce.calendar_event.end_time || ce.calendar_event.start_time
      )) {
        y_offset++;
      }
    }
    return y_offset;
  }

  initGridTimes() {
    this.calendar_hours = [];
    const selected_week_start = this.stateDataService.selected_week_start;
    const year = selected_week_start.getFullYear();
    const month = selected_week_start.getMonth();
    const date = selected_week_start.getDate();

    for (let i = 0; i < 24; i++) {
      this.calendar_hours.push(new Date(year, month, date, i));
    }
  }

  segmentCreated(segment: Segment, origin_component: string) {
    if (
      !segment.unit_flag &&
      this.segmentVisibleInCalendar(segment)
    ) {
      // Created via copying an existing segment
      if (origin_component === 'TimeUserCalendarComponent') {
        for (const cs of this.calendar_segments) {
          if (TimeUserCalendar.segmentMatchesCalendarSegment(segment, cs)) {
            cs.segment = segment;
            this.redrawSegment(cs.segment.segment_key);
          }
        }
      }
      else {
        this.calendar_segments.push(
          this.generateCalendarSegment(segment)
        );
      }
    }
  }

  segmentUpdated(segment: Segment) {
    if (
      !segment.unit_flag &&
      this.segmentVisibleInCalendar(segment)
    ) {
      for (const cs of this.calendar_segments) {
        if (cs.segment.segment_key === segment.segment_key) {
          cs.segment = segment;

          cs.updateSegmentDimensions();
          this.redrawSegment(cs.segment.segment_key);
        }
      }
    }
    else {
      this._removeCalendarSegment(segment.segment_key);
    }
  }

  segmentDeleted(segment_key: number) {
    this._removeCalendarSegment(segment_key);
  }

  initGridsterOptions() {
    this.gridster_options = {
      allowMultiLayer: true,
      minCols: this.gridster_cols,
      maxCols: this.gridster_cols,
      minRows: this.gridster_rows * 2,
      maxRows: this.gridster_rows * 2,
      margin: 0,
      maxItemRows: this.gridster_rows,
      minItemRows: 1,
      outerMargin: false,
      displayGrid: 'none',
      pushItems: false,
      swap: false,
      mobileBreakpoint: 0,
      resizable: {
        enabled: true,
        delayStart: 0,
        handles: { s: true, e: false, n: true, w: false, se: false, ne: false, sw: false, nw: false },
        start: (cs: CalendarSegment, cs_comp, event) => this.segmentResizeStart(cs, event),
        stop: (cs: CalendarSegment, cs_comp, event) => this.segmentResizeStop(cs, event)
      },
      draggable: {
        enabled: true,
        delayStart: 0,
        start: (cs: TimeUserCalendarSegment, cs_comp, event) => this.segmentDragStart(cs, event),
        stop: (cs: TimeUserCalendarSegment, cs_comp, event) => this.segmentDragStop(cs, event)
      }
    };
    this.gridsterUpdated();
  }

  gridsterUpdated() {
    this.gridster_options?.api?.optionsChanged();
  }

  scrollToHour(hour: number) {
    this.grid_container_outer.nativeElement.scroll(0, hour * 60);
  }

  segmentDragStart(cs: TimeUserCalendarSegment, event: MouseEvent) {
    this.calendar_segment_backup = _.cloneDeep(cs);
  }

  segmentDragStop(cs: TimeUserCalendarSegment, event: MouseEvent) {
    if (!!cs) {
      setTimeout(() => {
        if (!!this.copied_segment) {
          cs.updateSegmentOnDrag();

          if (TimeUtilService.segmentsOverlap(cs.segment, this.copied_segment.segment)) {
            this._removeCalendarSegment(null);
          }
          else {
            this.new_segment.emit({
              segment: cs.segment,
              origin_component: 'TimeUserCalendarComponent'
            });
          }

          this.copied_segment.is_copied_segment = false;
          this.redrawSegment(this.copied_segment.segment.segment_key);
          this.copied_segment = null;
        }
        else {
          if (TimeUserCalendar.calendarSegmentPositionOrSizeChanged(cs, this.calendar_segment_backup)) {
            cs.updateSegmentOnDrag();

            if (cs.multi_day_segment) {
              this.redrawSegment(cs.segment.segment_key);
            }
            this.update_segment.emit({ segment: cs.segment });
          }
          else {
            this.edit_segment.emit({ segment: cs.segment });
          }
        }
        this.calendar_segment_backup = null;
      });
    }
  }

  // Only way to edit a single row segment is via segment modal as dragging/resizing becomes fiddly
  editSingleRowSegmentOnMouseup(cs: TimeUserCalendarSegment) {
    if (cs.is_locked) {
      this.edit_segment.emit({ segment: cs.segment });
    }
  }

  segmentExtensionClicked(event: MouseEvent, cs: CalendarSegment) {
    const classes: string = (event.target as Element).className;
    if (!classes.includes('handle-s')) {
      this.edit_segment.emit({ segment: cs.segment });
    }
  }

  segmentResizeStart(cs: CalendarSegment, event: MouseEvent) {
    this.calendar_segment_backup = _.cloneDeep(cs);
  }

  segmentResizeStop(cs: CalendarSegment, event: MouseEvent) {
    setTimeout(() => {
      if (TimeUserCalendar.calendarSegmentPositionOrSizeChanged(cs, this.calendar_segment_backup)) {
        const redraw = cs.updateSegmentOnResize(TimeUserCalendar.getSegmentResizeHandle(event));
        if (redraw) {
          this.redrawSegment(cs.segment.segment_key);
        }

        this.update_segment.emit({ segment: cs.segment });
      }
      this.calendar_segment_backup = null;
    });
  }

  newSegmentStart(event: MouseEvent) {
    const container_width = (event.target as HTMLElement).clientWidth;
    const col_width = container_width / 7;

    const col = Math.floor(event.offsetX / col_width);
    const row = Math.floor(event.offsetY / this.gridster_row_height);

    const start_time = TimeUserCalendarSegment.getStartTimeFromRow(
      this.stateDataService.selected_week[col],
      row,
      this.gridster_mins_per_row
    );
    const end_time = TimeUserCalendarSegment.getEndTimeFromRow(
      this.stateDataService.selected_week[col],
      row,
      this.gridster_mins_per_row
    );

    this.drag_start_y = event.offsetY;

    this.new_calendar_segment = {
      cols: 1,
      rows: 1,
      x: col,
      y: row,
      layerIndex: 2,
      start_time,
      end_time
    };
  }

  newSegmentChange(event: MouseEvent) {
    if (this.new_calendar_segment !== null) {
      const drag_start_row = Math.floor(this.drag_start_y / this.gridster_row_height);

      // Dragging down
      if (event.offsetY >= this.drag_start_y) {
        const row = Math.ceil(event.offsetY / this.gridster_row_height);
        const rows = Math.max(1, Math.abs(row - this.new_calendar_segment.y));

        this.new_calendar_segment.y = drag_start_row;
        this.new_calendar_segment.rows = rows;
      }
      // Dragging up
      else if (event.offsetY < this.drag_start_y) {
        const row = Math.floor(event.offsetY / this.gridster_row_height);

        this.new_calendar_segment.y = row;
        this.new_calendar_segment.rows = drag_start_row - row;
      }

      const start_time = TimeUserCalendarSegment.getStartTimeFromRow(
        this.new_calendar_segment.start_time,
        this.new_calendar_segment.y,
        this.gridster_mins_per_row
      );
      const end_time = TimeUserCalendarSegment.getEndTimeFromRow(
        this.new_calendar_segment.start_time,
        this.new_calendar_segment.y + this.new_calendar_segment.rows,
        this.gridster_mins_per_row
      );

      this.new_calendar_segment.start_time = start_time;
      this.new_calendar_segment.end_time = end_time;

      this.redrawNewSegment();
    }
  }

  newSegmentStop() {
    if (this.new_calendar_segment !== null) {
      this.new_segment.emit({
        start_time: this.new_calendar_segment.start_time,
        end_time: this.new_calendar_segment.end_time,
        origin_component: 'TimeUserCalendarComponent'
      });

      this.new_calendar_segment = null;
      this.drag_start_y = null;
    }
  }

  startSegmentCopy(ev: MouseEvent, calendar_segemnt: TimeUserCalendarSegment) {
    if (ev.ctrlKey) {
      this._addCopiedSegment(calendar_segemnt);
    }
  }

  private _removeCalendarSegment(segment_key: number) {
    setTimeout(() => {
      for (let i = 0; i < this.calendar_segments.length; i++) {
        if (this.calendar_segments[i].segment.segment_key === segment_key) {
          this.calendar_segments.splice(i, 1);
        }
      }
    });
  }

  // private _getTimeFromRow(date: Date, row: number) {
  //   const rows_per_hour = 60 / this.gridster_row_mins;

  //   const hours = Math.floor(row / rows_per_hour);
  //   const mins = (row % rows_per_hour) * this.gridster_row_mins;

  //   return TimeUtilService.updateTime(date, hours, mins);
  // }

  private _addCopiedSegment(calendar_segemnt: TimeUserCalendarSegment) {
    this.copied_segment = this.generateCalendarSegment(
      calendar_segemnt.segment, true
    );
    this.calendar_segments.push(this.copied_segment);
    calendar_segemnt.segment = this.generateCopiedSegment(calendar_segemnt.segment);
  }

  static calendarSegmentPositionOrSizeChanged(cs: CalendarSegment, cs_backup: CalendarSegment): boolean {
    if (!!cs && !!cs_backup) {
      return cs.rows !== cs_backup.rows ||
        cs.cols !== cs_backup.cols ||
        cs.x !== cs_backup.x ||
        cs.y !== cs_backup.y;
    }
    return false;
  }

  static segmentMatchesCalendarSegment(segment: Segment, cs: TimeUserCalendarSegment): boolean {
    return TimeUtilService.twoPeriodsOverlapPerfectly(
      segment.start_time,
      segment.end_time,
      cs.segment.start_time,
      cs.segment.end_time
    );
  }

  static getSegmentResizeHandle(event: MouseEvent): Handle {
    const classList = (event.target as Element).className.split(' ');

    for (const c of classList) {
      if (c === 'handle-n') {
        return 'NORTH';
      }
      else if (c === 'handle-s') {
        return 'SOUTH';
      }
    }
    return null;
  }

}
