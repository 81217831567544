import { Component, Input } from '@angular/core';

import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'unit-input',
  templateUrl: './unit-input.component.html',
  styleUrls: ['./unit-input.component.scss'],
  providers: [
    MakeProvider(UnitInputComponent)
  ]
})
export class UnitInputComponent extends AbstractValueAccessor {

  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() bold_non_zeros: boolean = false;
  @Input() tooltip_content: string = null;
  @Input() input_prefix: string | number = null;
  @Input() input_suffix: string | number = null;

  _value: number = null;
  _previous_value: number = null;

  writeValue(value: any) {
    // Initialise previous value
    if (this._previous_value === null && !isNaN(value)) {
      this._previous_value = value;
    }
    super.writeValue(value);
  }

  parseModelFromView(view_value: any): void {
    const model_value = parseFloat(view_value);

    if (model_value !== null && !isNaN(model_value) && model_value >= 0) {
      this.value = model_value;
      this._previous_value = this.value;
    }
    else {
      this._revertToPreviousValue();
    }
  }

  private _revertToPreviousValue() {
    // Ensures input view is re-rendered
    this._value = null;
    setTimeout(() => this.value = this._previous_value);
  }

}
