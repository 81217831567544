<div class="input-autocomplete-container"
     *ngIf="!is_mobile"
     ngbDropdown
     #ngb_dropdown="ngbDropdown"
     [container]="'body'"
     [autoClose]="true"
     (openChange)="dropdownToggled($event)">

  <div ngbDropdownAnchor>
    <ng-container *ngTemplateOutlet="iac_inputContainer">
    </ng-container>
  </div>


  <div ngbDropdownMenu>
    <ng-container *ngTemplateOutlet="iac_content"></ng-container>
  </div>
</div>

<div class="input-autocomplete-container"
     *ngIf="is_mobile">
  <ng-container *ngTemplateOutlet="iac_inputContainer">
  </ng-container>
</div>

<ng-template #iac_inputContainer>
  <input type="text"
         class="form-control"
         autocomplete="off"
         #value_input
         [disabled]="disabled"
         [readonly]="readonly"
         [(ngModel)]="searchInput"
         (keydown)="keyPressed($event)"
         (click)="dropdownToggleClicked()">
</ng-template>

<ng-template #iac_content>
  <div class="ldd-listContainer">
    <div class="ldd-listContainerInner -iac"
         [ngStyle]="{
          'min-width': is_mobile ? '100vw' : list_width,
          'max-width': is_mobile ? '100vw' : list_width,
          'max-height': 'min(' + list_height + ', 50vh)' }">

      <app-form-item *ngIf="is_mobile"
                     [mock_focus]="true">
        <ng-container *ngTemplateOutlet="iac_inputContainer">
        </ng-container>
      </app-form-item>


      <div class="ldd-list">
        <div class="ldd-item"
             ngbDropdownItem
             *ngFor="let item of filteredItems, let i = index"
             [ngClass]="{ '-highlighted': i === highlightedFilteredSupplierIndex }"
             (click)="selectItem(item)">
          <div class="ldd-itemInner">
            <div class="-itemValue">
              {{item}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
