import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash-es';

import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';
import { DateTime } from 'luxon';

@Component({
  selector: 'lib-update-segment-rate-modal',
  templateUrl: './update-segment-rate-modal.component.html',
  styleUrls: ['./update-segment-rate-modal.component.scss']
})
export class UpdateSegmentRateModalComponent implements OnInit, AppModalInterface {

  @Input() project: any;
  @Input() task: any;
  @Input() resources: any[];

  @Output() close = new EventEmitter<{ from_date: Date }>();
  @Output() dismiss = new EventEmitter<void>();

  overtime: any[] = [];
  oncost: any[] = [];
  oncharge: any[] = [];

  from_date = new Date();
  today = new Date();

  ngOnInit() {
    if (!!this.task) {
      this.overtime = _.filter(this.task.overtime, (ot) => !ot.deleted_flag);
      this.oncost = _.filter(this.task.on_costs, (oc) => !oc.deleted_flag && !oc.on_charge_flag);
      this.oncharge = _.filter(this.task.on_costs, (oc) => !oc.deleted_flag && !!oc.on_charge_flag);
    }
  }

  fromDateChanged() {
    if (
      !TimeUtilService.dateIsValid(this.from_date) ||
      DateTime.fromJSDate(this.from_date).startOf('day') > DateTime.fromJSDate(this.today).startOf('day')
    ) {
      this.from_date = _.cloneDeep(this.today);
    }
  }

  confirm() {
    this.close.emit({
      from_date: this.from_date
    });
  }

  cancel() {
    this.dismiss.emit();
  }

}
