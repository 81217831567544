<div class="dayDropdown-container"
     *ngIf="!is_mobile"
     ngbDropdown
     #ngb_dropdown="ngbDropdown"
     [container]="'body'"
     [autoClose]="true"
     (openChange)="dropdownToggled($event)">

  <button class="-type-dropdown -color-white-outline dayDropdown-button"
          [ngClass]="{ '-readonly': readonly }"
          [disabled]="disabled || readonly"
          ngbDropdownAnchor
          (click)="dropdownToggleClicked()">
    <span>{{this.full_name ? selected_day.label_full : selected_day.label}}</span>
    <i class="ion ion-md-arrow-dropdown"></i>
  </button>

  <div ngbDropdownMenu>
    <ng-container *ngTemplateOutlet="dd_content"></ng-container>
  </div>
</div>

<div class="dayDropdown-container"
     *ngIf="is_mobile"
     (click)="dropdownToggleClicked()">
  <button class="-type-dropdown -color-white-outline dayDropdown-button"
          [ngClass]="{ '-readonly': readonly }"
          [disabled]="disabled || readonly">
    <span>{{this.full_name ? selected_day.label_full : selected_day.label}}</span>
    <i class="ion ion-md-arrow-dropdown"></i>
  </button>
</div>

<ng-template #dd_content>
  <div class="dd-list"
       [class.-fullWidth]="full_name">
    <li class="dayDropdown-li"
        *ngFor="let day of days"
        [ngClass]="{ '-selected': day.date_index === selected_day?.date_index }"
        (click)="daySelected(day)">
      {{full_name ? day.label_full : day.label}}
    </li>
  </div>
</ng-template>
