import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DashGridBlock } from '../../lib-classes/abstract/dash-grid/dash-grid-block';
import { AppTab, DashGridBlockThumbnail } from '../../lib.types';
import { ReportService } from '../../lib-services/report/report.service';
import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';

type TabValue = (
  'WIDGET' | 'REPORT'
);

@Component({
  selector: 'app-dash-grid-block-modal',
  templateUrl: './dash-grid-block-modal.component.html',
  styleUrls: ['./dash-grid-block-modal.component.scss']
})
export class DashGridBlockModalComponent implements OnInit, AppModalInterface {

  readonly widget_thumbnails: Record<string, { icon_class: string, icon_class_prefix: string }> = {
    NOTIFICATION: {
      icon_class: 'ion-md-notifications',
      icon_class_prefix: 'ion'
    },
    PROJECTS: {
      icon_class: 'ion-md-person',
      icon_class_prefix: 'ion'
    },
    MONTH_SUMMARY: {
      icon_class: 'ion-md-calendar',
      icon_class_prefix: 'ion'
    },
    TIME_SUMMARY: {
      icon_class: 'ion-md-time',
      icon_class_prefix: 'ion'
    },
    REPORT_CLIENT_REVENUE: {
      icon_class: 'bi-graph-up-arrow',
      icon_class_prefix: 'bi'
    },
    REPORT_CONSULTANT_PERFORMANCE: {
      icon_class: 'ion-md-stats',
      icon_class_prefix: 'ion'
    },
    APPROVALS: {
      icon_class: 'ion-ios-checkmark-circle',
      icon_class_prefix: 'ion'
    }
  };

  tab_map: Record<TabValue, AppTab> = {
    WIDGET: {
      value: 'WIDGET',
      label: 'Widgets',
      disabled: false
    },
    REPORT: {
      value: 'REPORT',
      label: 'My Reports',
      disabled: false
    }
  };
  tabs: AppTab[] = [
    this.tab_map.WIDGET,
    this.tab_map.REPORT
  ];
  selected_tab: AppTab = null;

  all_reports = this.reportService.getAllReports();
  reports_map = this.reportService.getReportsMap();

  @Input() active_blocks: DashGridBlock[] = [];
  @Input() available_widget_blocks: DashGridBlockThumbnail[] = [];

  available_blocks: Record<TabValue, DashGridBlockThumbnail[]> = {
    WIDGET: [],
    REPORT: []
  };

  @Output() close = new EventEmitter<{ block_type: string, report_key: number }>();
  @Output() dismiss = new EventEmitter<void>();

  // TODO: thumbail images

  constructor(
    public reportService: ReportService
  ) { }

  ngOnInit(): void {
    this._initAvailableBlocks();
    this._initSelectedTab();
  }

  selectTab(tab: AppTab) {
    if (!!this.available_blocks[tab.value].length) {
      this.selected_tab = tab;
    }
  }

  addBlock(block: DashGridBlockThumbnail) {
    this.close.emit({
      block_type: block.block_type,
      report_key: block.report_key || null
    });
  }

  cancel() {
    this.dismiss.emit();
  }

  private _initAvailableBlocks() {
    this.available_blocks = {
      WIDGET: this.available_widget_blocks,
      REPORT: []
    };

    const active_report_keys = new Set();

    for (const block of this.active_blocks) {
      if (!!block.report_key) {
        active_report_keys.add(block.report_key);
      }
    }

    for (const report of this.all_reports) {
      if (!active_report_keys.has(report.report_key)) {
        this.available_blocks.REPORT.push({
          block_type: 'REPORT',
          label: report.report_title,
          report_key: report.report_key
        });
      }
    }

    this.tab_map.WIDGET.disabled = this.available_blocks.WIDGET.length > 0;
    this.tab_map.REPORT.disabled = this.available_blocks.REPORT.length > 0;
  }

  private _initSelectedTab() {
    if (!!this.available_blocks.WIDGET.length) {
      this.selected_tab = this.tab_map.WIDGET;
    }
    else {
      this.selected_tab = this.tab_map.REPORT;
    }
  }

}
