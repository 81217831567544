<div class="app-nav">

  <div class="app-navHeader">

    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          Reporting
        </div>
      </div>
      <div class="app-navRow-right">
        <img class="app-navRow-logo"
             src="assets/product-imgs/flexitime/FlexiTime_WFM_Full.svg">
      </div>
    </div>
  </div>

  <ng-container>
    <div class="app-navToolbar">
      <div class="app-navRow">
        <div class="app-navRow-left">
          <app-tabs [tabs]="tabs"
                    [cache_id]="'report'"
                    [(selectedTab)]="selected_tab">
          </app-tabs>
        </div>

        <div class="app-navRow-right"
             *ngIf="!is_mobile">
          <ng-container *ngTemplateOutlet="mega_search"></ng-container>
          <ng-container *ngTemplateOutlet="account_dropdown_template"></ng-container>
          <ng-container *ngTemplateOutlet="new_report_dropdown_template"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="is_mobile">
    <div class="app-navToolbar"
         [class.-searchToolbar]="selected_tab?.value === 'REPORTS'">
      <ng-container *ngTemplateOutlet="mega_search"></ng-container>
      <ng-container *ngTemplateOutlet="account_dropdown_template"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="new_report_dropdown_template"></ng-container>
  </ng-container>

  <div class="app-navWindow">
    <div class="app-navContent">

      <report-dash *ngIf="initialised && selected_tab?.value === 'DASH'"
                   [block_config]="dashboard_config"
                   [selected_accounts]="selected_accounts"
                   [query_params]="query_params"
                   (update_project_dashboard_config)="updateDashboardConfig($event)">
      </report-dash>

      <div class="app-reportTile-container"
           *ngIf="initialised && selected_tab?.value === 'REPORTS' ">
        <ng-container *ngFor="let report of visible_reports">
          <div class="app-reportTile"
               (click)="editReport(report)">
            <report-thumbnail [report]="report"></report-thumbnail>
            <div class="app-reportTile-details">
              <div class="app-reportTile-details-title">
                {{report.report_title}}
              </div>
              <div class="app-reportTile-details-type">
                {{report.report_type_label}}
              </div>
            </div>

          </div>
        </ng-container>

        <div class="app-list-empty"
             *ngIf="visible_reports.length === 0">
          <div class="app-list-emptyHeader">
            No {{report_label.capitalised_plural}}
          </div>
          <div class="app-list-emptyDescription">
            No {{report_label.capitalised_plural}} to show
          </div>

        </div>
      </div>

    </div>
  </div>

</div>

<ng-template #mega_search>
  <mega-search *ngIf="selected_tab?.value === 'REPORTS'"
               [megaSearchId]="megaSearchId"
               [megaSearchConfigMap]="megaSearchConfigMap"
               [inputPlaceholder]="'Search...'"
               [items]="{ reports: reports }"
               (searchUpdated)="updateVisibleReports()">
  </mega-search>
</ng-template>

<ng-template #new_report_dropdown_template>
  <list-drop-down [float_on_mobile]="true"
                  [button_color_class]="'-color-success'"
                  [button_type_class]="'-type-icon'"
                  [button_icon_class]="'ion-ios-add'"
                  [item_config]="new_report_dropdown_config"
                  [items]="new_report_dropdown"
                  (item_selected)="dropdownItemSelected($event.item)">
  </list-drop-down>
</ng-template>

<ng-template #account_dropdown_template>
  <account-select-dropdown *ngIf="selected_tab?.value === 'DASH'"
                           (accounts_selected)="accountsSelected()">
  </account-select-dropdown>
</ng-template>
