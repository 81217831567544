<button class="-type-icon"
        [disabled]="disabled"
        [ngClass]="is_mobile ? '-color-default-clear' : '-color-white-outline'"
        (click)="backDay()">
  <i class="ion ion-ios-arrow-back"></i>
</button>

<div class="daySelector-textContainer"
     ngbDropdown
     [container]="'body'"
     [display]="'static'"
     [dropdownClass]="'dropdown'"
     (openChange)="dropdownToggled($event)">
  <div ngbDropdownToggle>
    {{selected_day | date : 'd MMM,'}}
    <span>{{selected_day | date : 'yyyy'}}</span>
  </div>
  <div class="app-datepicker-dropdown"
       ngbDropdownMenu>
    <ngb-datepicker [(ngModel)]="selected_day"></ngb-datepicker>
  </div>
</div>

<button class="-type-icon"
        [disabled]="disabled"
        [ngClass]="is_mobile ? '-color-default-clear' : '-color-white-outline'"
        (click)="forwardDay()">
  <i class="ion ion-ios-arrow-forward"></i>
</button>
