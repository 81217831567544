<ng-container *ngIf="!!file_url">
  <img class="fp-imagePreview"
       *ngIf="!!file_is_image"
       [src]="file_url"
       (error)="imageError()">

  <div class="fp-documentPreview"
       *ngIf="!file_is_image">
    <i class="ion ion-md-document"></i>
  </div>

  <i class="ion ion-md-close app-remove -white"
     *ngIf="!disabled"
     (click)="clearFile()">
  </i>
</ng-container>
