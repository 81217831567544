import _ from 'lodash-es';

import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

type SortPropertyDataType = (
  'STRING' | 'NUMBER' | 'BOOLEAN' | 'DATE' | 'DATETIME'
);
type SortPropertyPriorityType = (
  'PRIMARY' | 'SECONDARY' | 'END'
);

export type SortConfig = {
  primary_sort_property?: string,
  secondary_sort_property?: string,
  end_sort_property?: string
  forward_order?: boolean,
  // eg. 'project_key'
  list_primary_key?: string,
  supporting_datasets?: SortConfigSupportingDatasets
};

// eg. { summary_data: { *project_key*: { hours_this_month: 0, hours_last_month: 0 } } }
export type SortConfigSupportingDatasets = Record<string, Record<string, any>>;

@Injectable({
  providedIn: 'root'
})
export class SortUtilService {

  /**
     * Sorts the given list by the specified primary and secondary object properties.
     *
     * Sorts in ascending or descending order based on the forward_order param.
     *
     * If a end_sort_property is provided, any items with a truthy value
     * will be sorted to the end of the list, regardless of forward_order value.
     */
  static sortList(
    list: any[],
    config: SortConfig = {}
  ): any[] {
    try {
      list = list || [];

      config = {
        primary_sort_property: config?.primary_sort_property || null,
        secondary_sort_property: config?.secondary_sort_property || null,
        end_sort_property: config?.end_sort_property || null,
        forward_order: config?.forward_order === false ? false : true,
        list_primary_key: config?.list_primary_key || null,
        supporting_datasets: config?.supporting_datasets || {}
      };

      const primary_property_type = this._getSortPropertyDataType(list, config, 'PRIMARY');
      const secondary_property_type = this._getSortPropertyDataType(list, config, 'SECONDARY');

      if (
        !!primary_property_type ||
        !!secondary_property_type
      ) {
        return _.clone(list.sort((a, b) => {

          const end_values = this._getPropertyValues(a, b, config, config.end_sort_property);
          let result = this._compareListItemEndSortValues(
            end_values.av,
            end_values.bv,
            config.end_sort_property
          );

          if (result === 0) {
            const primary_values = this._getPropertyValues(a, b, config, config.primary_sort_property);
            result = this._compareListItemValues(
              primary_values.av,
              primary_values.bv,
              config,
              primary_property_type
            );
          }

          if (result === 0) {
            const secondary_values = this._getPropertyValues(a, b, config, config.secondary_sort_property);
            result = this._compareListItemValues(
              secondary_values.av,
              secondary_values.bv,
              config,
              secondary_property_type
            );
          }

          return result;
        }));
      }
      else {
        return this._sortPrimitiveList(list, config);
      }
    }
    catch (err) {
      console.log(err);
      return list;
    }
  }

  static _sortPrimitiveList(
    list: any[],
    config: SortConfig
  ): any[] {
    let item_type: SortPropertyDataType = null;

    for (const item of list) {
      if (!!item) {
        item_type = this._getTypeOfValue(item);
        break;
      }
    }

    if (!!item_type) {
      return _.clone(list.sort((a, b) => {
        return this._compareListItemValues(
          a,
          b,
          config,
          item_type
        );
      }));
    }
    return _.clone(list);
  }

  // Comparing items for sorting to the end of the list works differently in that we don't need to check data types
  // Instead we only compare whether or not that they are truthy values
  static _compareListItemEndSortValues(
    av: any,
    bv: any,
    end_sort_property: string
  ) {
    if (!!end_sort_property) {
      if (!av && !!bv) {
        return -1;
      }
      else if (!!av && !bv) {
        return 1;
      }
      return 0;
    }
    return 0;
  }

  static _compareListItemValues(
    av: any,
    bv: any,
    config: SortConfig,
    property_data_type: SortPropertyDataType
  ) {
    switch (property_data_type) {
      case 'STRING':
        return this._compareStrings(av, bv, config);
      case 'NUMBER':
        return this._compareNumbers(av, bv, config);
      case 'BOOLEAN':
        return this._compareBooleans(av, bv, config);
      case 'DATE':
        return this._compareDates(av, bv, config);
      case 'DATETIME':
        return this._compareDateTimes(av, bv, config);
    }
  }

  static _compareStrings(
    av: any,
    bv: any,
    config: SortConfig
  ) {
    av = (av || '').toUpperCase();
    bv = (bv || '').toUpperCase();

    if (!av) {
      return 1;
    }
    else if (!bv) {
      return -1;
    }

    return config.forward_order ? av.localeCompare(bv) : bv.localeCompare(av);
  }

  static _compareNumbers(
    av: any,
    bv: any,
    config: SortConfig
  ) {
    av = parseFloat(av);
    bv = parseFloat(bv);

    if (av === null) {
      return 1;
    }
    else if (bv === null) {
      return -1;
    }

    if (config.forward_order) {
      return bv < av ? -1 : bv > av ? 1 : 0;
    }
    else {
      return av < bv ? -1 : av > bv ? 1 : 0;
    }
  }

  static _compareBooleans(
    av: any,
    bv: any,
    config: SortConfig
  ) {
    av = (av === true || av === false) ? av : null;
    bv = (bv === true || bv === false) ? bv : null;

    if (av === null) {
      return 1;
    }
    else if (bv === null) {
      return -1;
    }

    if (config.forward_order) {
      return !av && bv ? -1 : av && !bv ? 1 : 0;
    }
    else {
      return !bv && av ? -1 : bv && !av ? 1 : 0;
    }
  }

  static _compareDateTimes(
    av: any,
    bv: any,
    config: SortConfig
  ) {
    av = !!av?.toJSDate ? av.toJSDate() : av;
    bv = !!bv?.toJSDate ? bv.toJSDate() : bv;

    return this._compareDates(
      av,
      bv,
      config
    );
  }

  static _compareDates(
    av: any,
    bv: any,
    config: SortConfig
  ) {
    av = this._dateIsValid(av) ? av.valueOf() : null;
    bv = this._dateIsValid(bv) ? bv.valueOf() : null;

    if (av === null) {
      return 1;
    }
    else if (bv === null) {
      return -1;
    }

    // Invert value of forward_order for dates
    if (!config.forward_order) {
      return av < bv ? -1 : av > bv ? 1 : 0;
    }
    else {
      return bv < av ? -1 : bv > av ? 1 : 0;
    }
  }

  static _getPropertyValues(
    a: any,
    b: any,
    config: SortConfig,
    property_path: string
  ): { av: any, bv: any } {
    let av: any, bv: any;

    if (!!property_path) {
      av = this._getPropertyValueForItem(a, config, property_path);
      bv = this._getPropertyValueForItem(b, config, property_path);
    }
    else {
      av = a || null;
      bv = b || null;
    }

    return { av, bv };
  }

  static _getPropertyPath(
    config: SortConfig,
    property_priority: SortPropertyPriorityType
  ): string {
    switch (property_priority) {
      case 'PRIMARY':
        return config?.primary_sort_property || null;
      case 'SECONDARY':
        return config?.secondary_sort_property || null;
      case 'END':
        return config?.end_sort_property || null;
    }
  }

  static _getSortPropertyDataType(
    list: any[],
    config: SortConfig,
    property_priority: SortPropertyPriorityType
  ): SortPropertyDataType {

    const property_path = this._getPropertyPath(config, property_priority);
    if (property_path === null) {
      return null;
    }

    for (const item of list) {
      if (item !== null) {
        const value = this._getPropertyValueForItem(item, config, property_path);

        const value_type = this._getTypeOfValue(value);
        if (!!value_type) {
          return value_type;
        }
      }
    }
    return null;
  }

  static _getTypeOfValue(value: any): SortPropertyDataType {
    if (typeof value === 'string') {
      return 'STRING';
    }
    else if (typeof value === 'number') {
      return 'NUMBER';
    }
    else if (value === true || value === false) {
      return 'BOOLEAN';
    }
    else if (value instanceof DateTime) {
      return 'DATETIME';
    }
    else if (value instanceof Date) {
      return 'DATE';
    }
    return;
  }

  static _getPropertyValueForItem(
    list_item: any,
    config: SortConfig,
    property_path: string
  ): any {
    try {
      // ":" acts as a separator for determining when the property we're checking belongs to a supporting_dataset instead of the main list
      // eg supporting_data = { summary_data: { *project_key*: { hours_this_month: 0, hours_last_month: 0 } } }
      //    property_name = 'summary_data:hours_this_month'
      const supporting_dataset_key_separator_index = !!property_path ? property_path.indexOf(':') : -1;
      let supporting_dataset_key: string = null;

      if (supporting_dataset_key_separator_index >= 0) {
        supporting_dataset_key = property_path.slice(0, supporting_dataset_key_separator_index);
        property_path = property_path.slice(supporting_dataset_key_separator_index + 1);
      }

      let value = list_item;

      if (!!property_path) {
        // Value is found in a supporting dataset
        if (supporting_dataset_key !== null) {
          const item_primary_key = list_item[config.list_primary_key];
          value = this._getNestedPropertyValue(config.supporting_datasets[supporting_dataset_key][item_primary_key], property_path);
        }
        // Value is found in main list
        else {
          value = this._getNestedPropertyValue(list_item, property_path);
        }
      }

      return value;
    }
    catch (err) {
      return null;
    }
  }

  static _getNestedPropertyValue(
    list_item: any,
    property_path: string
  ): any {
    try {
      const property_nodes = property_path.split('.');
      let property_value = list_item;

      for (const node of property_nodes) {
        property_value = property_value[node];
      }

      return property_value === undefined ? null : property_value;
    }
    catch (err) {
      return null;
    }
  }

  private static _dateIsValid(date: Date): boolean {
    return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime());
  }
}
