import { Injectable } from '@angular/core';
import { DateTime, DateTimeUnit, Duration, DurationLikeObject, Interval } from 'luxon';
import { DatePipe } from '@angular/common';

import _ from 'lodash-es';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SortUtilService } from '../sort-util/sort-util.service';
import { Segment } from '../../lib-models/segment/segment';
import { DpSegment } from '../../lib-models//segment/dp-segment/dp-segment';
import { DpRosterSegment } from '../../lib-models/dp-roster-segment/dp-roster-segment';
import { CoreUtilService } from '../core-util/core-util.service';
import {
  WeekDayShort,
  SegmentWeeklyTotals,
  WeekDayFull,
  DateRangeValue,
  DateRange,
  ProjectTaskRepeatFrequency
} from '../../lib.types';


@Injectable({
  providedIn: 'root'
})
export class TimeUtilService {

  static DEFAULT_DATE_KEY_FORMAT = 'yyyyMMdd';
  static readonly DEFAULT_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';
  static readonly DETAILED_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';

  static get week_days(): WeekDayFull[] {
    return [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];
  }

  static get week_day_shorts(): WeekDayShort[] {
    return [
      'mon',
      'tue',
      'wed',
      'thu',
      'fri',
      'sat',
      'sun'
    ];
  }

  static dateIsValid(date: any): boolean {
    return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date.getTime());
  }

  static numberIsValid(number: any): boolean {
    return !(number === null || number === undefined || number === '' || isNaN(Number(number)));
  }

  static getWeekDayShort(week_day: WeekDayFull): WeekDayShort {
    const index = this.week_days.indexOf(week_day);
    return index !== -1 ? this.week_day_shorts[index] : null;
  }

  static getWeekDayFull(week_day_short: WeekDayShort): WeekDayFull {
    const index = this.week_day_shorts.indexOf(week_day_short);
    return index !== -1 ? this.week_days[index] : null;
  }

  static get months() {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
  }

  static get months_shorts() {
    return [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec'
    ];
  }

  static generateYearOfMonths(year: number, month_index: number = 0): { label: string, value: Date }[] {
    const months: { label: string, value: Date }[] = [];
    const month_labels = (this.months_shorts.slice(month_index)).concat(this.months_shorts.slice(0, month_index));

    let month = new Date(year, month_index, 1);

    for (let i = 0; i < 12; i++) {
      months.push({
        label: CoreUtilService.capitaliseFirstLetter(month_labels[i]),
        value: month
      });
      month = this.incrementMonth(month, 1);
    }
    return months;
  }

  static generateMonthsInRange(start_date: Date, end_date: Date): { label: string, value: Date }[] {
    const num_months = Math.ceil(this._totalDiffBetweenTwoDates(start_date, end_date, true, 'month'));

    const months: { label: string, value: Date }[] = [];

    let month = start_date;
    month.setDate(1);

    for (let i = 0; i < num_months; i++) {
      months.push({
        label: this.dateToDateTimeString(month, 'MMM'),
        value: month
      });
      month = this.incrementMonth(month, 1);
    }

    return months;
  }

  static generateDatesInRange(start_date: Date, end_date: Date): Date[] {
    const dates: Date[] = [];
    let current_date: Date = start_date;
    while (current_date <= end_date) {
      dates.push(current_date);
      current_date = this.incrementDate(current_date, 1);
    }
    return dates;
  }

  static getNgbDateStruct(date: Date): NgbDateStruct {
    if (TimeUtilService.dateIsValid(date)) {
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }
    return null;
  }

  /**
   * Calculates a segment end_time based on it's current start_time, duration and break_duration
   *
   * @param {Object} segment
   */
  static calculateSegmentEndTime(segment: any): Date {
    const totalHoursMins = TimeUtilService.hoursDecimalAsHoursAndMinutes(segment.duration + segment.break_duration);
    const totalHours = totalHoursMins[0];
    const totalMins = totalHoursMins[1];

    let newEnd = _.cloneDeep(segment.start_time);
    newEnd = TimeUtilService.incrementHours(newEnd, totalHours);
    newEnd = TimeUtilService.incrementMinutes(newEnd, totalMins);

    return newEnd;
  }

  /**
  * Creates a copy of the provided date object, increments the
  * relevant portion of the date and returns the resulting value.
  *
  * Use these functions to ensure that Angular's change detection is
  * triggered properly because the standard setFullYear(), setMonth() etc
  * only modify the existing variable which means Angular won't detect a variable change
  *
  * @param {Date} date
  * @param {number} value
  */
  static incrementYear(date: Date, value: number): Date {
    const newDate = _.cloneDeep(date);
    newDate.setFullYear(newDate.getFullYear() + value);
    return newDate;
  }
  static incrementMonth(date: Date, value: number): Date {
    const newDate = _.cloneDeep(date);
    newDate.setMonth(newDate.getMonth() + value);
    return newDate;
  }
  static incrementDate(date: Date, value: number): Date {
    const newDate = _.cloneDeep(date);
    newDate.setDate(newDate.getDate() + value);
    return newDate;
  }
  static incrementHours(date: Date, value: number): Date {
    const newDate = _.cloneDeep(date);
    newDate.setHours(newDate.getHours() + value);
    return newDate;
  }
  static incrementMinutes(date: Date, value: number): Date {
    const newDate = _.cloneDeep(date);
    newDate.setMinutes(newDate.getMinutes() + value);
    return newDate;
  }

  /**
   * Creates a copy of the provided date object, updates the
   * relevant portion of the date and returns the resulting value.
   *
   * Use these functions to ensure that Angular's change detection is
   * triggered properly because the standard setFullYear(), setMonth() etc
   * only modify the existing variable which means Angular won't detect a variable change
   *
   * @param {Date} date
   * @param {number} value
   */
  static updateYear(date: Date, value: number): Date {
    const newDate = _.cloneDeep(date);
    newDate.setFullYear(value);
    return newDate;
  }
  static updateMonth(date: Date, value: number): Date {
    const newDate = _.cloneDeep(date);
    newDate.setMonth(value);
    return newDate;
  }
  static updateDate(date: Date, value: number): Date {
    const newDate = _.cloneDeep(date);
    newDate.setDate(value);
    return newDate;
  }
  static updateHours(date: Date, value: number): Date {
    const newDate = _.cloneDeep(date);
    newDate.setHours(value);
    return newDate;
  }
  static updateMinutes(date: Date, value: number): Date {
    const newDate = _.cloneDeep(date);
    newDate.setMinutes(value);
    return newDate;
  }

  static hasSame(a: Date, b: Date, unit: DateTimeUnit): boolean {
    if (!a || !b) return false;
    return DateTime.fromJSDate(a).hasSame(DateTime.fromJSDate(b), unit);
  }

  static updateDateTime(
    date_time: Date,
    year: number, month: number = null, date: number = null,
    hour: number = null, min: number = null
  ): Date {
    const d = _.cloneDeep(date_time);
    d.setFullYear(
      year, (month !== null ? month : d.getMonth()), (date !== null ? date : d.getDate())
    );
    d.setHours(
      (hour !== null ? hour : d.getHours()), (min !== null ? min : d.getMinutes())
    );
    return d;
  }
  static updateTime(
    time: Date,
    hour: number, min: number = null
  ): Date {
    const d = _.cloneDeep(time);
    d.setHours(
      hour, (min !== null ? min : d.getMinutes())
    );
    return d;
  }

  static toggleDateMeridian(date: Date, meridian: 'AM' | 'PM' = null): Date {
    const dt = DateTime.fromJSDate(date);

    switch (meridian) {
      case 'AM':
        return (dt.get('hour') < 12 ? dt : dt.minus({ hours: 12 })).toJSDate();
      case 'PM':
        return (dt.get('hour') >= 12 ? dt : dt.plus({ hours: 12 })).toJSDate();
      default:
        return (dt.get('hour') < 12 ? dt.plus({ hours: 12 }) : dt.minus({ hours: 12 })).toJSDate();
    }
  }

  static calculateDateRange(value: DateRangeValue, SOFY_month_index: number = null): DateRange {
    let end_date: DateTime = DateTime.now();
    let start_date: DateTime = null;

    switch (value) {
      case 'THIS_YEAR':
        end_date = end_date.set({ month: SOFY_month_index }).startOf('month');

        start_date = end_date.minus({ years: 1 });
        start_date = start_date.set({ month: SOFY_month_index + 1 }).startOf('month');
        break;
      case 'LAST_YEAR':
        end_date = end_date.set({ month: SOFY_month_index }).startOf('month');
        end_date = end_date.minus({ years: 1 });

        start_date = end_date.minus({ years: 1 });
        start_date = start_date.set({ month: SOFY_month_index + 1 }).startOf('month');
        break;
      case 'WEEK':
        start_date = end_date.startOf('week');
        end_date = start_date.endOf('week');
        break;
      case 'LAST_WEEK':
        start_date = end_date.minus({ weeks: 1 }).startOf('week');
        end_date = start_date.endOf('week');
        break;
      case 'MONTH':
        end_date = end_date.endOf('month');
        start_date = end_date.set({ day: 1 });
        break;
      case 'LAST_MONTH':
        start_date = end_date.minus({ months: 1 }).startOf('month');
        end_date = start_date.endOf('month');
        break;
      case 'QUARTER':
        start_date = end_date.startOf('quarter');
        end_date = start_date.endOf('quarter');
        break;
      case 'LAST_QUARTER':
        start_date = end_date.minus({ quarter: 1 }).startOf('quarter');
        end_date = start_date.endOf('quarter');
        break;
      case 'ALL_TIME':
        start_date = null;
        end_date = null;
        break;
    }

    return {
      start_date: start_date?.startOf('day').toJSDate() || null,
      end_date: end_date?.startOf('day').toJSDate() || null,
    };
  }

  /**
   * Updates the date (dd/mm/yyyy) portion of a date object (such as a segment start or end time), using the provided dateToCopy
   */
  static updateDatePortionOfDateTime(
    date_time_to_update: Date,
    date_to_copy: Date,
    overwrite_reference: boolean = false
  ): Date {
    if (overwrite_reference) {
      date_time_to_update = _.cloneDeep(date_time_to_update);
    }

    date_time_to_update.setFullYear(
      date_to_copy.getFullYear(),
      date_to_copy.getMonth(),
      date_to_copy.getDate()
    );

    return date_time_to_update;
  }

  /**
   * Updates the time (hh:mm) portion of a date object (such as a segment start or end time), using the provided timeToCopy
   */
  static updateTimePortionOfDateTime(
    date_time_to_update: Date,
    date_to_copy: Date,
    overwrite_reference: boolean = false
  ): Date {
    if (overwrite_reference) {
      date_time_to_update = _.cloneDeep(date_time_to_update);
    }
    date_time_to_update.setHours(
      date_to_copy.getHours(),
      date_to_copy.getMinutes()
    );

    return date_time_to_update;
  }

  /**
  * Converts an hours based duration in decimal format into an array with two numeric values.
  * The first value is the number of hours, the second being the number of minutes
  * eg. 1.75 -> [1, 45]
  */
  static hoursDecimalAsHoursAndMinutes(dec: number): number[] {
    const duration = Duration.fromObject({ hour: dec }).shiftTo('hours', 'minutes');
    return [
      duration.hours,
      Math.round(duration.minutes)
    ];
  }

  /**
   * Determines if the time portion of a end date is less than the time portion of an start date
   */
  static endTimeLessThanStartTime(start: Date, end: Date): boolean {
    const startMins = (start.getHours() * 60) + start.getMinutes();
    const endMins = (end.getHours() * 60) + end.getMinutes();

    return endMins < startMins;
  }

  static getNewestDurationSegmentInList(segments: any[]) {
    const duration_segments = _.filter(segments, { unit_flag: false });
    SortUtilService.sortList(duration_segments, { primary_sort_property: 'start_time' });

    return !!duration_segments.length ? duration_segments[0] : null;
  }

  static getOldestDurationSegmentInList(segments: any[]) {
    const duration_segments = _.filter(segments, { unit_flag: false });
    SortUtilService.sortList(duration_segments, { primary_sort_property: 'start_time' });

    return !!duration_segments.length ? duration_segments[duration_segments.length - 1] : null;
  }

  static getDurationSegmentsOnDay(segments: any[], day: Date): any[] {
    const l_day = DateTime.fromJSDate(day);
    const days_segments = [];

    for (const segment of segments) {
      if (
        !segment.unit_flag &&
        (
          l_day.hasSame(DateTime.fromJSDate(segment.start_time), 'day') ||
          l_day.hasSame(DateTime.fromJSDate(segment.end_time), 'day')
        )
      ) {
        days_segments.push(segment);
      }
    }
    return days_segments;
  }

  /**
   * Converts date object to date time string
   * If no stringFormat is provided, defaults to 'yyyy-MM-dd HH:mm'
   */
  static dateToDateTimeString(date: Date, format: string = null): string {
    if (!TimeUtilService.dateIsValid(date)) return null;

    format = format || 'yyyy-MM-dd HH:mm';
    return DateTime.fromJSDate(date).toFormat(format);
  }

  static dateToDateString(date: Date): string {
    return TimeUtilService.dateToDateTimeString(date, 'yyyy-MM-dd');
  }

  static dateToTimeString(date: Date): string {
    return TimeUtilService.dateToDateTimeString(date, 'HH:mm');
  }

  /**
   * Timezone information included in date_string is ignored
   */
  static utcDateTimeStringToDate(date_string: string): Date {
    if (!date_string) return null;

    date_string = this._parseDateTimeString(date_string, this.DEFAULT_DATETIME_FORMAT);
    if (!!date_string) {
      return DateTime.fromFormat(date_string, this.DEFAULT_DATETIME_FORMAT, { zone: 'utc' }).toLocal().toJSDate();
    }
    return null;
  }

  /**
   * Timezone information included in date_string is ignored
   */
  static utcDateTimeStringToDetailedDate(date_string: string): Date {
    if (!date_string) return null;

    date_string = this._parseDateTimeString(date_string, this.DETAILED_DATETIME_FORMAT);
    if (!!date_string) {
      return DateTime.fromFormat(date_string, this.DETAILED_DATETIME_FORMAT, { zone: 'utc' }).toLocal().toJSDate();
    }
    return null;
  }

  /**
   * Timezone information included in date_string is ignored
   */
  static dateTimeStringToDate(date_string: string, format: string = null): Date {
    if (!date_string) return null;

    format = format || this.DEFAULT_DATETIME_FORMAT;

    date_string = this._parseDateTimeString(date_string, format);
    if (!!date_string) {
      return DateTime.fromFormat(date_string, format).toJSDate();
    }
    return null;
  }

  private static _parseDateTimeString(date_string: string, format: string): string {
    if (!date_string) return null;

    let datetime = DateTime.fromFormat(date_string, format, { zone: 'utc' });
    if (!datetime.isValid) {
      datetime = DateTime.fromISO(date_string, { zone: 'utc' });
    }
    if (!datetime.isValid) {
      datetime = DateTime.fromSQL(date_string, { zone: 'utc' });
    }

    return datetime.isValid ? datetime.toFormat(format) : null;
  }

  static dateStringToDate(date_string: string) {
    return TimeUtilService.dateTimeStringToDate(date_string, 'yyyy-MM-dd');
  }

  static timeStringToDate(date_string: string): Date {
    return TimeUtilService.dateTimeStringToDate(date_string, 'HH:mm');
  }

  static timeFromNow(date: Date): string {
    return !!date ? DateTime.fromJSDate(date).toRelative() : null;
  }

  /**
   * Returns the difference in duration between two date objects as an hours decimal value
   * eg Difference between 2:00pm and 4:45pm is 2.75 hours
   */
  static differenceBetweenTwoDatesAsHoursDecimal(date_a: Date, date_b: Date): number {
    const total_ms = Math.abs(Math.floor(date_a.valueOf() - date_b.valueOf()));
    return Duration.fromMillis(total_ms).as('hours');
  }

  /**
   * Returns the Monday date that was prior to the given date
   */
  static getMonday(d: Date): Date {
    return this.getWeekStartForDate(d, 'mon');
  }

  /**
   * Returns the first date of the week for the provided date,
   * takes into account that the week start may not necessarily be Monday
   *
   * eg.
   * getWeekStartForDate(*Wed 22 Dec 21*, 'mon') => *Mon 20 Dec 21*
   * getWeekStartForDate(*Wed 22 Dec 21*, 'sat') => *Sat 18 Dec 21*
   * getWeekStartForDate(*Fri 17 Dec 21*, 'sat') => *Sat 11 Dec 21*
   */
  static getWeekStartForDate(date: Date, week_start_day: WeekDayShort): Date {
    date = this.getCleanDate(date);
    const day = date.getDay();

    const week_start_value = this.getWeekDayValue(week_start_day);
    const day_diff = day - week_start_value;

    const value = date.getDate() - (day_diff < 0 ? (7 + day_diff) : day_diff);

    date.setDate(value);
    return date;
  }

  static getWeekEndForDate(date: Date, week_start_day: WeekDayShort): Date {
    const week_start = this.getWeekStartForDate(date, week_start_day);
    return DateTime.fromJSDate(week_start).plus({ days: 6 }).toJSDate();
  }

  /**
   * Returns an index of the given day in the week
   */
  static getWeekDayValue(day: WeekDayShort): number {
    return ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(day);
  }

  /**
   * Returns the index of the given day based on the given start date for the week
   */
  static getDayIndex(day: Date, week_start: Date): number {
    if (!TimeUtilService.dateIsValid(day)) return null;

    const weeks_dates = TimeUtilService.generateWeeksDates(week_start).map(date => DateTime.fromJSDate(date));
    const l_day = DateTime.fromJSDate(day);

    for (let i = 0; i < weeks_dates.length; i++) {
      if (l_day.hasSame(weeks_dates[i], 'day')) {
        return i;
      }
    }
    return null;
  }

  static datesAreEqual(date_a: Date, date_b: Date, include_time: boolean = false): boolean {
    const a = DateTime.fromJSDate(date_a);
    const b = DateTime.fromJSDate(date_b);

    return a.isValid && b.isValid && a.hasSame(b, include_time ? 'minute' : 'day');
  }

  /**
   * Generates an array of date objects for a week based on the given start date
   */
  static generateWeeksDates(start_date: Date): Date[] {
    const week = [];
    let date = this.getCleanDate(start_date);

    for (let i = 0; i < 7; i++) {
      week.push(date);
      date = this.incrementDate(date, 1);
    }

    return week;
  }

  /**
   * Determines if provided date is today
   */
  static isToday(day: Date): boolean {
    return DateTime.now().hasSame(DateTime.fromJSDate(day), 'day');
  }

  static calculateTotalDurationOrUnitsOfSegments(segments: any[]): number {
    let duration = 0;

    for (const segment of segments) {
      duration += segment.unit_flag ? segment.units : segment.duration;
    }

    return duration;
  }

  /**
   * Converts an hours/mins string ("hh:mm") to an hours based decimal
   * eg. 03:45 => 3.75
   */
  static hoursMinsStringToHoursDecimal(hours_mins: string): number {
    const values = hours_mins.split(':');
    const hours = parseInt(values[0], null);
    const mins = parseFloat((parseInt(values[1], null) / 60) + '');
    return hours + mins;
  }

  /**
   * Determines if the given segments start_time / end_time will
   * cause it to overlap with another segment in the list
   */
  static segmentOverlapsAnotherInList(s: any, segments: any[]): boolean {
    if (s.unit_flag) {
      return false;
    }
    for (const segment of segments) {
      if (
        segment.segment_key !== s.segment_key &&
        TimeUtilService.segmentsOverlap(s, segment)
      ) {
        return true;
      }
    }
    return false;
  }

  static segmentsOverlap(segment_a: Segment, segment_b: Segment): boolean {
    if (!!segment_a.start_time && !!segment_b.start_time) {
      return this.twoPeriodsOverlap(
        segment_a.start_time,
        segment_a.end_time,
        segment_b.start_time,
        segment_b.end_time
      );
    }
    return false;
  }

  static cleanDate(date: Date): void {
    try {
      date.setHours(0, 0, 0, 0);
    }
    catch (err) { }
  }

  static cleanTime(time: Date) {
    try {
      time.setSeconds(0, 0);
    }
    catch (err) { }
  }

  static cleanMinutes(time: Date): void {
    try {
      time.setMinutes(0, 0, 0);
    }
    catch (err) { }
  }

  static getCleanDate(date: Date): Date {
    try {
      date = !!date ? _.cloneDeep(date) : new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }
    catch (err) {
      return date;
    }
  }

  static getCleanTime(time: Date): Date {
    try {
      const clean_time = _.cloneDeep(time);
      clean_time.setSeconds(0, 0);
      return clean_time;
    }
    catch (err) {
      return time;
    }
  }

  static getCleanMinutes(time: Date): Date {
    try {
      const clean_time = _.cloneDeep(time);
      clean_time.setMinutes(0, 0, 0);
      return clean_time;
    }
    catch (err) {
      return time;
    }
  }

  static totalSecondsBetweenTwoDates(date_a: Date, date_b: Date, abs_value: boolean = true): number {
    return this._totalDiffBetweenTwoDates(date_a, date_b, abs_value, 'second');
  }

  static totalMinutesBetweenTwoDates(date_a: Date, date_b: Date, abs_value: boolean = true): number {
    return this._totalDiffBetweenTwoDates(date_a, date_b, abs_value, 'minute');
  }

  static totalHoursBetweenTwoDates(date_a: Date, date_b: Date, abs_value: boolean = true): number {
    return this._totalDiffBetweenTwoDates(date_a, date_b, abs_value, 'hour');
  }

  static totalDaysBetweenTwoDates(date_a: Date, date_b: Date, abs_value: boolean = true): number {
    return this._totalDiffBetweenTwoDates(date_a, date_b, abs_value, 'day');
  }

  private static _totalDiffBetweenTwoDates(date_a: Date, date_b: Date, abs_value: boolean, unit: DateTimeUnit) {
    const unit_plural = unit + 's';

    const a = DateTime.fromJSDate(date_a).startOf(unit);
    const b = DateTime.fromJSDate(date_b).startOf(unit);
    const diff = a.diff(b).as(unit_plural as keyof DurationLikeObject);

    return abs_value ? Math.abs(diff) : diff;
  }

  static totalOverlappingTimeBetweenTwoPeriods(
    period_a: { start: Date, end: Date },
    period_b: { start: Date, end: Date },
    granularity: DateTimeUnit
  ): number {
    const range_a = Interval.fromDateTimes(
      DateTime.fromJSDate(period_a.start).startOf(granularity),
      DateTime.fromJSDate(period_a.end).startOf(granularity)
    );
    const range_b = Interval.fromDateTimes(
      DateTime.fromJSDate(period_b.start).startOf(granularity),
      DateTime.fromJSDate(period_b.end).startOf(granularity)
    );

    const intersection = range_a.intersection(range_b);
    return intersection === null ? 0 : intersection.length(granularity);
  }

  static twoPeriodsOverlap(
    period_a_start: Date,
    period_a_end: Date,
    period_b_start: Date,
    period_b_end: Date,
    granularity: DateTimeUnit = 'minute'
  ): boolean {
    return TimeUtilService.totalOverlappingTimeBetweenTwoPeriods(
      { start: period_a_start, end: period_a_end },
      { start: period_b_start, end: period_b_end },
      granularity
    ) > 0;
  }

  static twoPeriodsOverlapPerfectly(
    period_a_start: Date,
    period_a_end: Date,
    period_b_start: Date,
    period_b_end: Date
  ): boolean {
    return DateTime.fromJSDate(period_a_start).hasSame(DateTime.fromJSDate(period_b_start), 'minute') &&
      DateTime.fromJSDate(period_a_end).hasSame(DateTime.fromJSDate(period_b_end), 'minute');
  }

  static getDefaultDateForNewSegment(
    date_range_start: Date,
    date_range_end: Date,
    start_time: Date = null
  ): Date {
    if (!!start_time) {
      return TimeUtilService.getCleanDate(start_time);
    }
    else {
      const l_date = DateTime.now().startOf('day');

      // Use today if it is in the date_range
      if (
        l_date >= DateTime.fromJSDate(date_range_start).startOf('day') &&
        l_date <= DateTime.fromJSDate(date_range_end).startOf('day')
      ) {
        return new Date();
      }
      return _.cloneDeep(date_range_start);
    }
  }

  static getStartTimeForNewSegment(
    segment_date: Date,
    user: any = null,
    existing_user_segments: any[] = []
  ): Date {
    let default_start = _.cloneDeep(segment_date);

    if (!!user?.default_start_time) {
      default_start = TimeUtilService.updateTimePortionOfDateTime(segment_date, user.default_start_time, true);
    }
    else {
      default_start.setHours(9, 0, 0, 0);
    }

    const days_segments = TimeUtilService.getDurationSegmentsOnDay(existing_user_segments, segment_date);
    const newest_segment = TimeUtilService.getNewestDurationSegmentInList(days_segments);

    const newest_segment_end = !!newest_segment?.end_time ? DateTime.fromJSDate(newest_segment.end_time) : null;

    if (
      !!newest_segment_end &&
      newest_segment_end.hasSame(DateTime.fromJSDate(segment_date), 'day') &&
      newest_segment_end > DateTime.fromJSDate(default_start).startOf('minute')
    ) {
      return _.cloneDeep(newest_segment.end_time);
    }
    return default_start;
  }

  static getIndexOfDateInWeek(date: Date, week_start: Date): number {
    const l_date = DateTime.fromJSDate(date).startOf('day');
    const l_week_start = DateTime.fromJSDate(week_start).startOf('day');

    const diff = l_date.diff(l_week_start, 'days').days;
    return (diff >= 0 && diff <= 6) ? diff : null;
  }

  static filterSegmentsToSelectedWeek(segments: Segment[], week_start: Date): Segment[] {
    const l_week_start = DateTime.fromJSDate(week_start).startOf('day');
    const l_week_end = l_week_start.plus({ days: 6 });

    const weeks_segments = [];
    for (const segment of segments) {
      const l_segment_date = DateTime.fromJSDate(segment.segment_date);

      if (l_segment_date >= l_week_start && l_segment_date <= l_week_end) {
        weeks_segments.push(segment);
      }
    }
    return weeks_segments;
  }

  // Used by time-user-table and time-user-calendar
  static calculateWeeklySegmentTotals(segments: Segment[], week_start: Date): SegmentWeeklyTotals {
    segments = this.filterSegmentsToSelectedWeek(segments, week_start);

    let no_hours = true;
    let days_found = false;

    for (const segment of segments) {
      if (segment.unit_type === 'hours') {
        no_hours = false;
      }
      else if (segment.unit_type === 'days') {
        days_found = true;
      }
    }
    const unit_type = no_hours && days_found ? 'days' : 'hours';

    const weekly_totals: SegmentWeeklyTotals = {
      days: [0, 0, 0, 0, 0, 0, 0],
      week: 0,
      unit_type
    };

    for (const segment of segments) {
      if (segment.unit_type.toLowerCase() === unit_type) {
        const i = this.getIndexOfDateInWeek(segment.segment_date, week_start);

        if (i !== null) {
          const value = segment.unit_flag ? segment.units : segment.duration;

          weekly_totals.days[i] += value;
          weekly_totals.week += value;
        }
      }
    }
    return weekly_totals;
  }

  // TODO: remove this
  static formatDateForPosting(date: Date, includeTime: boolean = false) {
    if (includeTime) {
      return TimeUtilService.dateToDateTimeString(date, 'yyyyMMdd HH:mm');
    }
    else {
      return TimeUtilService.dateToDateString(date);
    }
  }

  static isLastDayInMonth(date) {
    const nextDay = _.cloneDeep(date);
    nextDay.setDate(nextDay.getDate() + 1);

    return date.getMonth() !== nextDay.getMonth();
  }

  static getFirstDayOfMonthFromDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  static getDateKeys(
    _start: Date,
    _end: Date,
    _min_date: Date = null,
    _max_date: Date = null
  ): string[] {
    const min_date = !!_min_date ? DateTime.fromJSDate(_min_date).startOf('day') : null;
    const max_date = !!_max_date ? DateTime.fromJSDate(_max_date).endOf('day') : null;

    let start = DateTime.fromJSDate(_start).startOf('day');
    if (!!min_date && start < min_date) {
      start = min_date;
    }

    let end = DateTime.fromJSDate(_end);
    if (!!max_date && end > max_date) {
      end = max_date;
    }

    if (
      (!!min_date && end < min_date) ||
      (!!max_date && start > max_date)
    ) {
      return [];
    }

    const date_keys = [];
    let date = start;

    while (!date.hasSame(end, 'day')) {
      date_keys.push(this.getDateKey(date));
      date = date.plus({ days: 1 });
    }

    if (
      date.hasSame(end, 'day') &&
      end.get('hour') > 0 || end.get('minute') > 0
    ) {
      date_keys.push(this.getDateKey(date));
    }

    return date_keys;
  }

  static getDateKey(date: Date | DateTime) {
    if (!date) return null;
    if (date instanceof Date) {
      date = DateTime.fromJSDate(date);
    }
    return date.toFormat(this.DEFAULT_DATE_KEY_FORMAT);
  }

  /**
   * @param n a number e.g. 8
   * @returns ordinal representation e.g. '8th'
   */
  static ordinal(n) {
    const suffix = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
  }

}
