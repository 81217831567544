import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import _ from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import { Observable, Subject } from 'rxjs';

import { Auth, WeekDayShort } from '../../lib.types';
import { CoreUtilService } from '../core-util/core-util.service';
import { TimeUtilService } from './../time-util/time-util.service';

declare const Base64: any;

@Injectable({
  providedIn: 'root'
})
export class StateDataService {

  session_storage: any = window.sessionStorage;
  local_storage: any = window.localStorage;
  live_storage: any = {};

  private _session_cache_prefix: string = null;
  private _local_cache_prefix: string = null;

  private _selected_day: Date = null;
  private _selected_week_start: Date = null;
  private _selected_month_start: Date = null;

  private _selected_day_updated_event = new Subject<void>();
  private _selected_week_start_updated_event = new Subject<void>();
  private _selected_month_start_updated_event = new Subject<void>();

  constructor(
    @Inject('env') public env: any,
    public datePipe: DatePipe
  ) {
    this._local_cache_prefix = this.env.product + '_';
    this._session_cache_prefix = this.env.product + '_';

    this._selected_month_start = TimeUtilService.dateStringToDate(
      this._getSessionStorageData(this._session_cache_prefix + 'selected_month_start')
    );
    this._selected_week_start = TimeUtilService.dateStringToDate(
      this._getSessionStorageData(this._session_cache_prefix + 'selected_week_start')
    );
    this._selected_day = TimeUtilService.dateStringToDate(
      this._getSessionStorageData(this._session_cache_prefix + 'selected_day')
    );
  }

  getCachedVariable(state_name: string, param_name: string) {
    return this._getSessionStorageData(this._session_cache_prefix + state_name + '_' + param_name);
  }

  cacheVariable(state_name: string, param_name: string, param_value: any) {
    this._setSessionStorageData(this._session_cache_prefix + state_name + '_' + param_name, param_value);
  }

  getSelectedDayUpdatedEvent(): Observable<void> {
    return this._selected_day_updated_event.asObservable();
  }

  getSelectedWeekStartUpdatedEvent(): Observable<void> {
    return this._selected_week_start_updated_event.asObservable();
  }

  getSelectedMonthStartUpdatedEvent(): Observable<void> {
    return this._selected_month_start_updated_event.asObservable();
  }

  // Auth ///////////////////////////////////////////////////////

  get auth(): Auth {
    const session_auth = this._getSessionStorageData(this._session_cache_prefix + 'session_auth');
    const local_auth = this._getLocalStorageData(this._local_cache_prefix + 'local_auth');

    const return_login_token = CoreUtilService.parseJSON(local_auth?.return_login_token) || null;
    if (!!return_login_token) {
      return_login_token.expiry_date = TimeUtilService.utcDateTimeStringToDate(return_login_token.expiry_date);
    }


    const product_refresh_token = local_auth?.product_refresh_token || null;

    return {
      session_key: session_auth?.session_key || null,
      company_key: session_auth?.company_key || null,
      company_code: session_auth?.company_code || null,
      return_session_key: session_auth?.return_session_key || null,
      return_login_token,
      user_access_company_key: session_auth?.user_access_company_key || null,
      product_refresh_token,
      external_user_access_key: session_auth?.external_user_access_key
    };
  }

  set auth(auth: Auth) {
    if (!!auth) {
      const session_auth = {
        session_key: auth.session_key,
        company_key: auth.company_key,
        company_code: auth.company_code,
        return_session_key: auth.return_session_key,
        user_access_company_key: auth.user_access_company_key,
        external_user_access_key: auth.external_user_access_key
      };
      const local_auth = {
        return_login_token: auth.return_login_token,
        product_refresh_token: auth.product_refresh_token
      };

      this._setSessionStorageData(this._session_cache_prefix + 'session_auth', session_auth);
      this._setLocalStorageData(this._local_cache_prefix + 'local_auth', local_auth);
    }
    else {
      this._removeSessionStorageData(this._session_cache_prefix + 'session_auth');
      this._removeLocalStorageData(this._local_cache_prefix + 'local_auth');
    }
  }

  ///////////////////////////////////////////////////////////////

  get route_back_stack(): string[] {
    return this._getSessionStorageData(this._session_cache_prefix + 'route_back_stack');
  }
  set route_back_stack(stack: string[]) {
    this._setSessionStorageData(this._session_cache_prefix + 'route_back_stack', stack);
  }

  private _updateSelectedMonthOnWeekChange() {
    if (!DateTime.fromJSDate(this._selected_week_start).hasSame(DateTime.fromJSDate(this._selected_month_start), 'month')) {
      this._selected_month_start = _.cloneDeep(this._selected_week_start);
      this._selected_month_start.setDate(1);

      this._setSessionStorageData(
        this._session_cache_prefix + 'selected_month_start',
        TimeUtilService.dateToDateString(this._selected_month_start)
      );
    }
  }

  private _updateSelectedWeekOnMonthChange() {
    if (!DateTime.fromJSDate(this._selected_week_start).hasSame(DateTime.fromJSDate(this._selected_month_start), 'month')) {
      const week_start_day = this.datePipe.transform(this._selected_week_start, 'EEE').toLowerCase();
      this._selected_week_start = TimeUtilService.getWeekStartForDate(this._selected_month_start, week_start_day as WeekDayShort);

      this._setSessionStorageData(
        this._session_cache_prefix + 'selected_week_start',
        TimeUtilService.dateToDateString(this._selected_week_start)
      );
    }
  }

  private _updateSelectedDayOnWeekChange() {
    const selected_week = Interval.fromDateTimes(DateTime.fromJSDate(this._selected_week_start), DateTime.fromJSDate(this.selected_week_end));
    if (!selected_week.contains(DateTime.fromJSDate(this._selected_day))) {
      this.selected_day = this._selected_week_start;
    }
  }

  set selected_month_start(date: Date) {
    const current_month_start = DateTime.fromJSDate(this._selected_month_start);
    const new_month_start = DateTime.fromJSDate(date).startOf('month');

    if (!new_month_start.hasSame(current_month_start, 'month')) {
      this._selected_month_start = new_month_start.toJSDate();

      this._setSessionStorageData(
        this._session_cache_prefix + 'selected_month_start',
        TimeUtilService.dateToDateString(this._selected_month_start)
      );

      this._updateSelectedWeekOnMonthChange();
      this._selected_month_start_updated_event.next();
    }
  }
  get selected_month_start(): Date {
    if (!!this._selected_month_start) {
      return _.cloneDeep(this._selected_month_start);
    }
    else {
      this._selected_month_start = new Date();
      this._selected_month_start.setDate(1);

      this._setSessionStorageData(
        this._session_cache_prefix + 'selected_month_start',
        TimeUtilService.dateToDateString(this._selected_month_start)
      );
      return this._selected_month_start;
    }
  }
  get selected_month_end(): Date {
    const selected_month_end = TimeUtilService.incrementMonth(this.selected_month_start, 1);
    selected_month_end.setDate(0);
    return selected_month_end;
  }

  set selected_week_start(date: Date) {
    const new_week_start = DateTime.fromJSDate(date).startOf('day');

    this._selected_week_start = new_week_start.toJSDate();

    this._setSessionStorageData(
      this._session_cache_prefix + 'selected_week_start',
      TimeUtilService.dateToDateString(this._selected_week_start)
    );

    this._updateSelectedMonthOnWeekChange();
    this._updateSelectedDayOnWeekChange();
    this._selected_week_start_updated_event.next();
  }
  get selected_week_start(): Date {
    if (!!this._selected_week_start) {
      return _.cloneDeep(this._selected_week_start);
    }
    else {
      return null;
    }
  }
  get selected_week_end(): Date {
    return TimeUtilService.incrementDate(this.selected_week_start, 6);
  }
  get selected_week(): Date[] {
    const selected_week = [];

    let date = this.selected_week_start;

    for (let i = 0; i < 7; i++) {
      selected_week.push(date);
      date = TimeUtilService.incrementDate(date, 1);
    }

    return selected_week;
  }
  get selected_week_start_day(): WeekDayShort {
    return this.selected_week_start.toString().substring(0, 3).toLowerCase() as WeekDayShort;
  }

  set selected_day(date: Date) {
    const current_day = DateTime.fromJSDate(this._selected_day);
    const new_day = DateTime.fromJSDate(date).startOf('day');

    if (!new_day.hasSame(current_day, 'day')) {
      this._selected_day = new_day.toJSDate();

      this._setSessionStorageData(
        this._session_cache_prefix + 'selected_day',
        TimeUtilService.dateToDateString(this._selected_day)
      );

      this._selected_day_updated_event.next();
    }
  }
  get selected_day(): Date {
    if (!!this._selected_day) {
      return _.cloneDeep(this._selected_day);
    }
    else {
      this._selected_day = TimeUtilService.getCleanDate(new Date());

      this._setSessionStorageData(
        this._session_cache_prefix + 'selected_day',
        TimeUtilService.dateToDateString(this._selected_day)
      );
      return this._selected_day;
    }
  }

  get intercom_hash(): string {
    return this._getSessionStorageData(this._session_cache_prefix + 'intercom_hash');
  }
  set intercom_hash(hash: string) {
    this._setSessionStorageData(this._session_cache_prefix + 'intercom_hash', hash);
  }

  getBackStack() {
    return this._getSessionStorageData(this._session_cache_prefix + 'back_stack');
  }
  setBackStack(stack) {
    this._setSessionStorageData(this._session_cache_prefix + 'back_stack', stack);
  }

  getStickiedMenuState(root_state: string): string {
    const stickiedMenuStates = this._getSessionStorageData(this._session_cache_prefix + 'stickiedMenuStates');
    return stickiedMenuStates ? (stickiedMenuStates[root_state] || null) : null;
  }
  setStickiedMenuState(root_state: string, sub_state: string) {
    const stickiedMenuStates = this._getSessionStorageData(this._session_cache_prefix + 'stickiedMenuStates') || {};
    stickiedMenuStates[root_state] = root_state + '.' + sub_state;
    this._setSessionStorageData(this._session_cache_prefix + 'stickiedMenuStates', stickiedMenuStates);
  }

  getStickiedMenuPath(menu_item_path: string) {
    const stickied_menu_paths = this._getSessionStorageData(this._session_cache_prefix + 'stickied_menu_paths') || {};
    return stickied_menu_paths[menu_item_path] || null;
  }
  setStickiedMenuPath(menu_item_path: string, menu_item_child_path: string) {
    const stickied_menu_paths = this._getSessionStorageData(this._session_cache_prefix + 'stickied_menu_paths') || {};
    stickied_menu_paths[menu_item_path] = menu_item_child_path;
    this._setSessionStorageData(this._session_cache_prefix + 'stickied_menu_paths', stickied_menu_paths);
  }

  ////////////////////////////////////////////////////////////////////

  cacheFcmToken(fcm_token: string) {
    this._setLocalStorageData('FCM_TOKEN', fcm_token);
  }

  // Cached token is deleted on read
  getCachedFcmToken(): string {
    const fcm_token = this._getLocalStorageData('FCM_TOKEN');
    this._removeLocalStorageData('FCM_TOKEN');
    return fcm_token;
  }

  ////////////////////////////////////////////////////////////////////

  cacheComponentLiveData(component_name: string, data_name: string, data: any): void {
    this.live_storage[component_name + '_' + data_name] = data;
  }

  getCachedComponentLiveData(component_name: string, data_name: string): any {
    return this.live_storage[component_name + '_' + data_name] || null;
  }

  clearCachedComponentLiveData(component_name: string, data_name: string): void {
    delete this.live_storage[component_name + '_' + data_name];
  }

  ////////////////////////////////////////////////////////////////////

  cacheComponentSessionData(component_name: string, data_name: string, data: any): void {
    this._setSessionStorageData(this._session_cache_prefix + component_name + '_' + data_name, data);
  }

  getCachedComponentSessionData(component_name: string, data_name: string): any {
    return this._getSessionStorageData(this._session_cache_prefix + component_name + '_' + data_name);
  }

  clearCachedComponentSessionData(component_name: string, data_name: string): void {
    this._removeSessionStorageData(this._session_cache_prefix + component_name + '_' + data_name);
  }

  clearAllCachedSessionData(): void {
    for (const key of Object.keys(this.session_storage)) {
      this._removeSessionStorageData(key);
    }
  }
  // Cache Utils ////////////////////////////////////////////////

  private _getSessionStorageData(data_name: string) {
    const data = this.session_storage.getItem(data_name);
    return data ? this._parseJSON(data) : null;
  }

  private _setSessionStorageData(data_name: string, data: any) {
    this.session_storage.setItem(data_name, JSON.stringify(data));
  }

  private _removeSessionStorageData(data_name: string) {
    this.session_storage.removeItem(data_name);
  }

  private _getLocalStorageData(data_name: string) {
    const data = this.local_storage.getItem(Base64.encode(data_name));
    return data ? this._parseJSON(Base64.decode(data)) : null;
  }

  private _setLocalStorageData(data_name: string, data: any) {
    data = !!data ? Base64.encode(JSON.stringify(data)) : data;
    this.local_storage.setItem(Base64.encode(data_name), data);
  }

  private _removeLocalStorageData(data_name: string) {
    this.local_storage.removeItem(Base64.encode(data_name));
  }

  _parseJSON(data: any) {
    try {
      return JSON.parse(data);
    }
    catch (err) {
      return data;
    }
  }

}
