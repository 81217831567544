import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BannerConfig } from '../../lib.types';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

  @Input() bannerConfig: BannerConfig = null;

  @Output() bannerhidden = new EventEmitter();

  bannerVisible: boolean = false;

  showBanner(message: string = null) {
    if (!!message) {
      this.bannerConfig.message = message;
    }
    this.toggleBannerVisibility(true);
  }

  hideBanner() {
    this.bannerConfig.message = '';
    this.toggleBannerVisibility(false);
    this.bannerhidden.emit();
  }

  toggleBannerVisibility(is_visible: boolean) {
    if (is_visible !== this.bannerVisible) {
      this.bannerVisible = is_visible;

      if (this.bannerVisible && !!this.bannerConfig.auto_hide_timer) {
        setTimeout(() => {
          this.bannerVisible = false;
        }, this.bannerConfig.auto_hide_timer);
      }
    }
  }

}
