import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, HostBinding } from '@angular/core';

@Component({
  selector: 'file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnChanges {

  @HostBinding('class.-fitWidth') @Input() fit_width: boolean = false;
  @HostBinding('class.-fitHeight') @Input() fit_height: boolean = false;

  @Input() file_url: string = null;
  @Input() disabled: boolean = false;

  @Output() clear_file = new EventEmitter<void>();

  file_is_image = true;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.file_url) {
      this.file_is_image = true;
    }
  }

  imageError() {
    this.file_is_image = false;
  }

  clearFile() {
    this.clear_file.emit();
  }

}
