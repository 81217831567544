import { TaskBillRateModifier, TaskUnitOption, TaskUnitOptionValue } from '../../lib.types';

export class KmTaskStub {

  task_key: number;
  task_name: string;
  task_display_name: string;
  task_type: TaskUnitOption;
  default_break_duration: number;
  billable_flag: boolean;
  archived_flag: boolean = false;
  tax_rate: number;
  tax_type: string;
  locked_flag: boolean;
  tax_type_display: string;
  unit_type: ('expense');
  unit_flag: boolean;

  constructor(
    task_key: number,
    task_name: string,
    task_display_name: string,
    default_break_duration: number,
    billable_flag: boolean,
    archived_flag: boolean,
    tax_rate: number = null,
    tax_type: string = null,
    invoxy_locked_flag: boolean = false,
    coworker_locked_flag: boolean = false,
    unit_type: ('expense') = null, // only used for tasks from invoxy
    unit_flag: boolean = false
  ) {
    this.task_key = task_key;
    this.task_name = task_name;
    this.task_display_name = task_display_name;
    this.default_break_duration = default_break_duration;
    this.billable_flag = billable_flag;
    this.archived_flag = archived_flag;
    this.tax_rate = tax_rate;
    this.tax_type = tax_type;
    this.unit_type = unit_type;
    this.unit_flag = unit_flag;

    this.locked_flag = invoxy_locked_flag || coworker_locked_flag;
    this.tax_type_display = tax_type; // display name defaults to tax type - only used for xero mapping of account codes

    this._initTaskType();
  }

  private _initTaskType() {
    const task_type: TaskUnitOption = {
      value: 'INCOME',
      label: 'Income'
    };

    if (!this.unit_flag && !this.billable_flag) {
      task_type.value = 'REPORTING';
      task_type.label = 'Reporting';
    }
    else if (!!this.unit_flag) {
      task_type.value = 'EXPENSE';
      task_type.label = 'Expense';
    }

    this.task_type = task_type;
  }

}
