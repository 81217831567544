<!-- HEADER -->
<div class="app-invoice-header -desktop-only">
  <textarea id="invoiceEditor-header"
            placeholder="Page Header"
            [disabled]="invoice.posted_flag">
  </textarea>
</div>

<!-- SUB HEADER -->
<div class="app-invoice-subHeader -desktop-only">
  <textarea id="invoiceEditor-subHeader"
            placeholder="Invoice Header"
            [disabled]="invoice.posted_flag">
  </textarea>
</div>

<!-- CONTENTS -->
<div class="app-invoice-content">
  <div class="app-invoice-contentHeader"
       *ngIf="!is_mobile">
    <div class="-headerCol -description">
      <div>Description</div>
    </div>
    <div class="-headerCol -quantity">
      <div>Quantity</div>
    </div>
    <div class="-headerCol -rate">
      <div>Rate</div>
    </div>
    <div class="-headerCol -amount">
      <div>Amount</div>
    </div>
  </div>

  <div class="app-invoice-contentLines"
       cdkDropList
       (cdkDropListDropped)="invoiceLineMoved($event)">
    <ng-container *ngFor="let line of invoice.lines">
      <invoice-editor-line *ngIf="!line.deleted_flag"
                           cdkDrag
                           [cdkDragDisabled]="invoice.posted_flag"
                           [cdkDragStartDelay]="is_mobile ? 250 : 0"
                           [cdkDragLockAxis]="'y'"
                           [cdkDragBoundary]="'.app-invoice-contentLines'"
                           [invoice]="invoice"
                           [line]="line"
                           (quantity_changed)="recalculateLineAmounts()"
                           (rate_changed)="recalculateLineAmounts()"
                           (remove_invoice_line)="removeLine($event.line)"
                           (click)="editLine(line)">
        <div *ngIf="!is_mobile"
             slot="drag-handle"
             class="iel-dragHandle"
             cdkDragHandle>
        </div>
      </invoice-editor-line>
    </ng-container>
  </div>

  <div class="app-invoice-contentTotals">
    <div class="app-invoice-contentTotalsLeft">
      <list-drop-down *ngIf="!invoice.posted_flag"
                      [button_color_class]="'-color-success-clear-grey-border'"
                      [button_type_class]="'-type-icon'"
                      [button_icon_class]="'ion-ios-add'"
                      [item_label_prop]="'label'"
                      [item_bullet_colour_prop]="'bullet_colour'"
                      [sort_items]="false"
                      [items]="new_line_dropdown"
                      (item_selected)="newLine($event.item)">
      </list-drop-down>
    </div>
    <div class="app-invoice-contentTotalsRight">
      <div class="-contentTotals-subtotal">
        <div>Invoice Subtotal</div>
        <div>{{invoice.net_amount | appCurrency}}</div>
      </div>
      <div class="-contentTotals-tax">
        <div>Total Tax {{invoice.tax_type}}</div>
        <div>{{invoice.tax_amount | appCurrency}}</div>
      </div>
      <div class="-contentTotals-total">
        <div>Amount Due</div>
        <div>{{invoice.gross_amount | appCurrency}}</div>
      </div>
    </div>
  </div>

</div>

<!-- FOOTER -->
<div class="app-invoice-footer -desktop-only">
  <textarea id="invoiceEditor-footer"
            placeholder="Invoice Footer"
            [disabled]="invoice.posted_flag">
  </textarea>
</div>
