import _ from 'lodash-es';

import { Segment, SegmentTimeValues } from './../segment';
import { PostableObject, PostableObjectErrorMap, PostableObjectLockedFields, PostableObjectUtilService } from '../../../lib-interfaces/postable-object.interface';
import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { CoreUtilService } from '../../../lib-services/core-util/core-util.service';

import { KmTaskStub } from '../../km-task-stub/km-task-stub';
import { KmNote } from '../../km-note/km-note';
import { KmProjectTask } from '../../km-project-task/km-project-task';
import { KmProjectStub } from '../../km-project-stub/km-project-stub';

export class KmSegment extends Segment implements PostableObject<KmSegment> {

  rate_total: number;

  new_flag: boolean;
  pending_flag: boolean;
  approved_flag: boolean;
  invoiced_flag: boolean;
  paid_flag: boolean;
  coworker_key: number;

  created_date: Date;
  notes: KmNote[];

  private _project_task: KmProjectTask;
  private _project: KmProjectStub;
  private _task: KmTaskStub;

  private _project_rate: number;

  constructor(
    segment_key: number = null,
    row_version: string = null,
    project_task: KmProjectTask = null,
    project: KmProjectStub,
    task: KmTaskStub,
    unit_flag: boolean = false,
    credit_flag: boolean = false,
    segment_date: Date = null,
    start_time: Date = null,
    end_time: Date = null,
    break_duration: number = 0,
    units: number = null,
    notes: KmNote[] = null,
    project_rate: number = null,
    new_flag: boolean = true,
    pending_flag: boolean = false,
    approved_flag: boolean = false,
    invoiced_flag: boolean = false,
    paid_flag: boolean = false,
    created_date: Date = null,
    deleted_flag: boolean = false,
    coworker_key: number = null
  ) {
    project = project_task?.project || project || null;
    task = project_task?.task || task || null;

    segment_date = TimeUtilService.getCleanDate(segment_date || null);

    if (unit_flag) {
      start_time = null;
      end_time = null;
      break_duration = null;

      units = units || 0;
    }
    else {
      break_duration = break_duration || 0;

      if (!start_time || !end_time) {

        const d = KmSegment._generateSegmentTimeDefaults(
          unit_flag, segment_date, project, task
        );

        start_time = d.start_time;
        end_time = d.end_time;
        break_duration = d.break_duration;
      }

      units = null;
    }

    super(
      false, true, false,
      segment_key, deleted_flag, row_version, unit_flag, credit_flag,
      segment_date, start_time, end_time, break_duration, units
    );

    this.created_date = created_date || null;
    this.new_flag = new_flag;
    this.pending_flag = pending_flag;
    this.approved_flag = approved_flag;
    this.invoiced_flag = invoiced_flag;
    this.paid_flag = paid_flag;

    this.notes = notes || [];

    this._project_task = project_task;
    this._project = project;
    this._task = task;

    this.coworker_key = coworker_key;

    this._updateProjectRate(project_rate);
    this._updateUnitType();
  }

  // Super class function extensions /////////////////////////////////
  get start_time(): Date {
    return super.start_time;
  }
  set start_time(start_time: Date) {
    super.start_time = start_time;
    this._updateProjectRateTotal();
  }

  get end_time(): Date {
    return super.end_time;
  }
  set end_time(end_time: Date) {
    super.end_time = end_time;
    this._updateProjectRateTotal();
  }

  get segment_date(): Date {
    return super.segment_date;
  }
  set segment_date(segment_date: Date) {
    super.segment_date = segment_date;
    this._updateProjectRateTotal();
  }

  set duration(duration: number) {
    super.duration = duration;
    this._updateProjectRateTotal();
  }
  get duration(): number {
    return super.duration;
  }

  set break_duration(break_duration: number) {
    super.break_duration = break_duration;
    this._updateProjectRateTotal();
  }
  get break_duration(): number {
    return super.break_duration;
  }

  set units(units: number) {
    super.units = units;
    this._updateProjectRateTotal();
  }
  get units(): number {
    return super.units;
  }

  // Super class getters/setters //////////////////////////////////////
  get colour(): string {
    return this._project.project_colour || null;
  }
  get work_name(): string {
    return this._task.task_display_name || null;
  }

  _updateUnitType(): void {
    if (!!this._project_task) {
      const unit_type = this._project.rate_type.toLowerCase();

      this._is_custom_unit = ['hours', 'days'].indexOf(unit_type) === -1;
      this._unit_type = unit_type;

      this._updateUnitFlag();
    }
  }

  private _updateUnitFlag() {
    if (!!this._project_task) {
      this.unit_flag = this.credit_flag || !!(this._project?.rate_type === 'days');

      if (
        (this.unit_flag && this.units === null) ||
        (!this.unit_flag && this.duration === null)
      ) {
        this.reinitSegmentTimeValues(
          KmSegment._generateSegmentTimeDefaults(
            this.unit_flag,
            this.segment_date,
            this._project,
            this._task
          )
        );
      }
    }
  }

  // Class getters/setters //////////////////////////////////////
  get project_task(): KmProjectTask {
    return this._project_task;
  }
  set project_task(project_task: KmProjectTask) {
    this._project_task = project_task;
    this._project = project_task?.project || null;
    this._task = project_task?.task || null;

    if (!!this._project_task) {
      this._updateUnitType();
      this._updateProjectRate();
    }
  }

  private _updateProjectRate(project_rate: number = null) {
    if (project_rate !== null) {
      this.project_rate = project_rate;
    }
    else if (!!this._project_task) {
      this.project_rate = this.isBillable() ? this._project_task.bill_rate : 0;
    }
  }

  private _updateProjectRateTotal() {
    this.rate_total = this.project_rate * (this.unit_flag ? this.units : this.duration);
  }

  get project_rate(): number {
    return this._project_rate;
  }
  set project_rate(project_rate: number) {
    this._project_rate = project_rate;
    this._updateProjectRateTotal();
  }

  get project(): KmProjectStub {
    return this._project;
  }
  get task(): KmTaskStub {
    return this._task;
  }

  get is_active_task(): boolean {
    return !this._task?.archived_flag || false;
  }
  get status_color(): string {
    if (this.paid_flag) {
      return '#005a7d';
    }
    else if (this.invoiced_flag) {
      return '#f60';
    }
    else if (this.approved_flag) {
      return '#5eb22e';
    }
    else if (this.pending_flag) {
      return '#ffce00';
    }
    else {
      return '#00adef';
    }
  }
  get status(): string {
    if (this.paid_flag) {
      return 'Paid';
    }
    else if (this.invoiced_flag) {
      return 'Invoiced';
    }
    else if (this.approved_flag) {
      return 'Approved';
    }
    else if (this.pending_flag) {
      return 'Pending';
    }
    else {
      return 'New';
    }
  }

  get is_locked(): boolean {
    return this.getEditingDisabled();
  }

  isBillable() {
    return this._project_task.bill_type !== 'FIXED' && this._project_task.task.billable_flag && !this._project_task.coworker_locked_flag;
  }

  getEditingDisabled(): boolean {
    if (!!this.coworker_key) return true;

    if (!this._project || !this._task) {
      return false;
    }
    return this._task.archived_flag ||
      !!this._project.completed_flag ||
      this.invoiced_flag ||
      this.paid_flag;
  }

  getLockedFields(): PostableObjectLockedFields<KmSegment> {
    const editing_disabled = this.getEditingDisabled();
    let fields: PostableObjectLockedFields<KmSegment> = {};

    if (editing_disabled) {
      fields = PostableObjectUtilService.lockAllFields(this);
    }

    return fields;
  }

  getErrors(): PostableObjectErrorMap {
    const errors = super.getErrors();

    if (!this._project_task) {
      errors['project_task'] = 'An active ' + CoreUtilService.project_task_label.lowercase + ' must be set';
    }
    else if (this._task.archived_flag) {
      errors['task'] = 'The ' + CoreUtilService.task_label.lowercase + ' for this time entry is archived';
    }
    if (!CoreUtilService.numberIsValid(this.project_rate)) {
      errors['project_rate'] = 'Rate is required';
    }

    return errors;
  }

  hasErrors(): boolean {
    return PostableObjectUtilService.hasErrors(this);
  }

  formatForPosting(): any {
    if (!this.getErrors().has_errors) {
      const project_rate = this.isBillable() ? this.project_rate : 0;

      if (this.unit_flag) {
        const data: any = {
          segment_key: this.segment_key,
          row_version: this.row_version,
          project_task_key: this.project_task.project_task_key,
          project_key: this._project.project_key,
          task_key: this._task.task_key,
          segment_date: TimeUtilService.dateToDateString(this.segment_date),
          deleted_flag: this.deleted_flag,
          units: this.units,
          unit_type: this._project.rate_type,
          project_rate,
          note_key: this.notes[0]?.note_key,
          clock_key: this.notes[0]?.clock_key,
          note_content: this.notes[0]?.note_content
        };

        if (this.credit_flag) {
          data.request_date = TimeUtilService.dateToDateTimeString(new Date());
        }
        return data;
      }
      else {
        return {
          segment_key: this.segment_key,
          row_version: this.row_version,
          project_task_key: this.project_task.project_task_key,
          project_key: this._project.project_key,
          task_key: this._task.task_key,
          segment_date: TimeUtilService.dateToDateString(this.segment_date),
          start_time: TimeUtilService.dateToDateTimeString(this.start_time),
          end_time: TimeUtilService.dateToDateTimeString(this.end_time),
          deleted_flag: this.deleted_flag,
          duration: CoreUtilService.roundNumber(this.duration, 17),
          break_duration: CoreUtilService.roundNumber(this.break_duration, 17),
          project_rate,
          note_key: this.notes[0]?.note_key,
          clock_key: this.notes[0]?.clock_key,
          note_content: this.notes[0]?.note_content
        };
      }
    }
  }

  // Class functions //////////////////////////////////////
  private static _generateSegmentTimeDefaults(
    unit_flag: boolean,
    segment_date: Date,
    project: KmProjectStub,
    task: KmTaskStub
  ): SegmentTimeValues {
    if (!project || !task) {
      return {
        start_time: null,
        end_time: null,
        break_duration: null,
        units: null
      };
    }

    if (unit_flag) {
      return {
        start_time: null,
        end_time: null,
        break_duration: null,
        units: 0
      };
    }
    else {
      const start_time = _.cloneDeep(segment_date);
      start_time.setHours(9, 0, 0, 0);

      const end_time = TimeUtilService.incrementHours(start_time, 8);

      let break_duration = 0;
      const totalDurationDec = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(start_time, end_time);
      // Ensure break duration isn't greater than the total segment duration
      if (break_duration > totalDurationDec) {
        break_duration = totalDurationDec;
      }

      return {
        start_time,
        end_time,
        break_duration,
        units: null
      };
    }
  }

  taskIsVisible(task: KmTaskStub): boolean {
    if (!task) {
      return false;
    }
    if (task.archived_flag) {
      return false;
    }
    if (this.unit_flag) {
      return this?._project_task.project.rate_type === 'days';
    }
  }

  setupKmNote(note: any): KmNote {
    return new KmNote(
      note.note_key,
      note.segment_key || null,
      note.clock_key || null,
      note.note_content
    );
  }

}
