import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon';
import { lastValueFrom } from 'rxjs';
import _ from 'lodash-es';

import { AuthService } from '../auth/auth.service';
import { AuthLoginToken } from '../../lib.types';

import { CoreUtilService } from '../core-util/core-util.service';
import { TimeUtilService } from '../time-util/time-util.service';
import { ApiService } from '../api/api.service';
import { DomService } from '../dom/dom.service';


@Injectable({
  providedIn: 'root'
})
export class TokenLoginService {

  private _sub_company_product_key: number = null;
  private _external_user_access_key: number = null;

  private _token_login_in_progress: boolean = false;

  constructor(
    @Inject('env') public env: any,
    public http: HttpClient,
    public authService: AuthService
  ) { }

  updateCompanyUserKeys(
    sub_company_product_key: number,
    external_user_access_key: number
  ) {
    this._sub_company_product_key = sub_company_product_key;
    this._external_user_access_key = external_user_access_key;
  }

  get token_login_in_progress(): boolean {
    return this._token_login_in_progress;
  }

  updateProductLoginToken() {
    if (
      this.authService.token_login_enabled &&
      !this._validateProductLoginToken()
    ) {
      this._loadNewProductLoginToken()
        .catch(() => { });
    }
  }

  private _validateProductLoginToken(): boolean {
    const token = this.authService.return_login_token;
    // Ensure token exists, hasn't expired and its sub_company_product_key && external_user_access_key
    // matches that of the the current user session

    return !!token &&
      DateTime.fromJSDate(token.expiry_date) > DateTime.now() &&
      token.sub_company_product_key === this._sub_company_product_key &&
      token.external_user_access_key === this._external_user_access_key;
  }

  private _loadNewProductLoginToken(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!!this._sub_company_product_key) {
        const options: any = {
          headers: this.authService.getSubscriptionHttpHeader()
        };

        const data = {
          company_product_key: this._sub_company_product_key
        };

        lastValueFrom(
          this.http.post(ApiService.subscription_api_url + 'user_access_company/token', data, options)
        )
          .then((res: any) => {
            const token: AuthLoginToken = {
              token: res.return_login_token,
              expiry_date: TimeUtilService.utcDateTimeStringToDate(res.expiry_date),
              sub_company_product_key: res.sub_company_product_key,
              external_user_access_key: res.external_user_access_key
            };

            this.authService.return_login_token = token;
            resolve();
          })
          .catch((err) => {
            this.authService.return_login_token = null;
            reject();
          });
      }
      else {
        reject();
      }
    });
  }

  tokenLogin(): Promise<void> {
    if (this.env.product === 'FLEXITIME') {
      return this._productRefreshSession();
    }
    else {
      return this._productTokenLogin();
    }
  }

  private _productRefreshSession() {
    return new Promise<void>((resolve, reject) => {
      if (this.authService.token_login_enabled && !!this.authService.product_refresh_token) {
        this._token_login_in_progress = true;

        const product_refresh_token = this.authService.product_refresh_token;

        this.authService.return_login_token = null;

        const data = {
          token_type: '',
          product_refresh_token
        };


        if (this.env.product === 'FLEXITIME') {
          data.token_type = 'REPORTING REFRESH';
        }

        lastValueFrom(
          this.http.post(ApiService.subscription_api_url + 'user_access/refresh', data)
        )
          .then((res: any) => {
            this.authService.productRefreshLogin(
              res.product_session_key ? res.product_session_key : res.additional_data.session_key,
              res.refresh_token,
              res.additional_data?.return_session_key,
              res.additional_data?.company_code,
              res.additional_data?.user_access_company_key
            );
            this._token_login_in_progress = false;
            resolve();
          })
          .catch((err) => {
            this._token_login_in_progress = false;
            reject();
          });
      } else {
        reject();
      }
    });
  }

  private _productTokenLogin(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (
        this.authService.token_login_enabled &&
        !!this.authService.return_login_token
      ) {
        this._token_login_in_progress = true;

        const login_token = this.authService.return_login_token.token;
        // Clear token from cache to ensure that multiple token login calls aren't attempted
        this.authService.return_login_token = null;

        const data = {
          login_source: this.env.login_source,
          login_token
        };

        lastValueFrom(
          this.http.post(ApiService.subscription_api_url + 'user_access/login', data)
        )
          .then((res: any) => {
            this.authService.return_session_key = res.session_key;
            const company_product_key = CoreUtilService.parseJSON(res.additional_data).company_product_key;

            this._startNewProductSession(company_product_key)
              .then(() => {
                this._token_login_in_progress = false;
                resolve();
              })
              .catch(() => {
                this._token_login_in_progress = false;
                reject();
              });
          })
          .catch(() => {
            this._token_login_in_progress = false;
            reject();
          });
      }
      else {
        reject();
      }
    });
  }

  private _startNewProductSession(company_product_key: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const options = {
        headers: this.authService.getSubscriptionHttpHeader()
      };
      const data = {
        company_product_key
      };

      lastValueFrom(
        this.http.post(ApiService.subscription_api_url + 'company_product/new_session', data, options)
      )
        .then((res: any) => {

          this.authService.externalLogin(
            res.session_key,
            res.external_company_reference,
            res.external_user_access_company_key
          );
          resolve();
        })
        .catch(() => reject());
    });
  }

}
