import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';

import { Component, Input, Injectable, HostBinding } from '@angular/core';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { TimeUtilService } from '../../../public-api';

@Injectable()
export class TimeAdapter extends NgbTimeAdapter<Date> {

  base_date: Date;

  fromModel(value: Date | null): NgbTimeStruct | null {
    this.base_date = value;
    if (!value) {
      return null;
    }
    return {
      hour: value.getHours(),
      minute: value.getMinutes(),
      second: value.getSeconds()
    };
  }

  toModel(time: NgbTimeStruct | null): Date | null {
    if (!time) {
      return null;
    }
    const date = this.base_date ? new Date(this.base_date.getTime()) : new Date();
    date.setHours(time.hour, time.minute, 0, 0);
    return date;
  }
}

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    { provide: NgbTimeAdapter, useClass: TimeAdapter },
    MakeProvider(TimePickerComponent)
  ]
})
export class TimePickerComponent extends AbstractValueAccessor {

  @Input() disabled: boolean = false;
  @HostBinding('class.-readonly') @Input() readonly: boolean = false;

  _value: Date;

  toggleTimeMeridian(event: KeyboardEvent) {
    if (
      TimeUtilService.dateIsValid(this.value) &&
      (event.target as any).classList.contains('btn')
    ) {
      if (event.key === 'p') {
        this.value = TimeUtilService.toggleDateMeridian(this._value, 'PM');
      }
      else if (event.key === 'a') {
        this.value = TimeUtilService.toggleDateMeridian(this._value, 'AM');
      }
      else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        this.value = TimeUtilService.toggleDateMeridian(this._value);
      }
    }
  }

}
