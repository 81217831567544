import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appNumber'
})
export class AppNumberPipe implements PipeTransform {

  constructor(
    public decimalPipe: DecimalPipe
  ) { }

  transform(value: number | string, num_dp: number = null) {
    const min_dp = num_dp === null ? 0 : num_dp;
    const max_dp = num_dp === null ? 2 : num_dp;
    const digitsInfo = '1.' + min_dp + '-' + max_dp;
    return this.decimalPipe.transform(value, digitsInfo);
  }

}
