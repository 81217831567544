import { Pipe, PipeTransform } from '@angular/core';

import { ReportFilter, ReportService } from '../../../public-api';

@Pipe({
  name: 'reportFilterArg'
})
export class ReportFilterArgPipe implements PipeTransform {

  constructor(
    public reportService: ReportService
  ) { }

  transform(
    value: unknown,
    filter: ReportFilter,
    format_to_string: boolean = true,
    num_decimal_places: number = 2
  ): unknown {
    if (filter.func === 'CUSTOM'){
      return value;
    }
    else {
      return this.reportService.formatFieldValue(value, filter.field, format_to_string, num_decimal_places);
    }
  }

}
