import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import _ from 'lodash-es';

import { KmNotification } from '../../../lib-models/km-notification/km-notification';
import { NotificationServiceInterface } from '../../../lib-interfaces/notification-service.interface';
import { ProjectTaskServiceInterface } from '../../../lib-interfaces/project-task-service.interface';
import { AppListComponent } from '../../app-list/app-list.component';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'notification-dropdown-content',
  templateUrl: './notification-dropdown-content.component.html',
  styleUrls: ['./notification-dropdown-content.component.scss']
})
export class NotificationDropdownContentComponent implements OnInit, OnDestroy {

  @ViewChild('notification_list') notification_list: AppListComponent;

  notifications: KmNotification[] = this.notificationService.getNotifications();

  loading: boolean = false;

  event_subscriptions: Subscription[] = [];

  constructor(
    public element_ref: ElementRef,
    public activeOffcanvas: NgbActiveOffcanvas,
    @Inject('notificationService') public notificationService: NotificationServiceInterface,
    @Inject('projectTaskService') public projectTaskService: ProjectTaskServiceInterface,
    @Inject('env') public env
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
    this.notificationService.markAllNotificationsAsSeen();
  }

  closeDropdown() {
    this.activeOffcanvas.close();
  }

  notificationButtonClicked(notification: any) {
    if (!!notification.additional_data) {
      this.notificationService.notificationButtonClicked(notification);
      this.closeDropdown();
    }
  }

  appListReload() {
    setTimeout(() => this.notification_list?.reloadVisibleItems());
  }

  appListLoadMore() {
    setTimeout(() => this.notification_list?.loadMoreVisibleItems());
  }


  loadMoreNotifications() {
    this.loading = true;

    this.notificationService.loadMoreNotifications(false)
      .then((new_notifications) => {
        this.notifications.push(...new_notifications as KmNotification[]);
        this.appListLoadMore();
      })
      .catch(() => { })
      .finally(() => this.loading = false);
  }

  deleteNotification(event: MouseEvent, notification: KmNotification) {
    event.stopPropagation();

    if (!notification.notification_key && notification.notification_type === 'ACTIVITY_FEED') {
      this.notificationService.deleteFauxNotification(notification);
      this.projectTaskService.updateFeedViewed(notification.identity_key, false);
    } else {
      this.notificationService.deleteNotifications([notification]);
    }

  }

  private _updateNotifications() {
    this.notifications = this.notificationService.getNotifications();
    this.appListReload();
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.notificationService.getNotificationUpdatedEvent().subscribe(() => {
        this._updateNotifications();
      })
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
