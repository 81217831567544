import { DateTime } from 'luxon';
import _ from 'lodash-es';

import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { KmSegmentStub } from '../km-segment-stub/km-segment-stub';

export type KmProjectTaskFeedType = (
  'COMMENT' | 'PROJECT_TASK_CREATED' | 'PROJECT_TASK_SHARED'
);

export type KmProjectTaskFeedUser = {
  user_key: number,
  display_name: string,
  display_image: string
};

export class KmProjectTaskFeed implements PostableObject<KmProjectTaskFeed> {

  project_task_key: number;
  child_project_task_key: number;
  feed_type: KmProjectTaskFeedType;
  feed_body: string;
  attachment_url: string;
  attachment_file_name: string;
  segment_stub: KmSegmentStub;
  created_date: Date;
  project_task_feed_key: number;
  update_user: KmProjectTaskFeedUser;
  coworker_locked_flag: boolean;
  deleted_flag: boolean = false;

  created_date_label: string
  feed_icon: string = null;
  feed_title: string = null;

  vimeo_url: string = null;
  youtube_url: string = null;

  feed_body_formatted: string = null;

  imageLoadFailed: boolean = false;

  constructor(
    project_task_key: number,
    child_project_task_key: number = null,
    feed_type: KmProjectTaskFeedType,
    feed_body: string = '',
    attachment_url: string = null,
    attachment_file_name: string = null,
    segment_stub: any = null,
    created_date: Date = null,
    project_task_feed_key: number = null,
    update_user: KmProjectTaskFeedUser = null,
    coworker_locked_flag: boolean = false
  ) {
    this.project_task_key = project_task_key;
    this.child_project_task_key = child_project_task_key;
    this.feed_type = feed_type;
    this.feed_body = feed_body || ''; //has been sanitized
    this.attachment_url = attachment_url;
    this.attachment_file_name = attachment_file_name;
    this.segment_stub = segment_stub;
    this.created_date = created_date;
    this.project_task_feed_key = project_task_feed_key;
    this.update_user = update_user;
    this.coworker_locked_flag = coworker_locked_flag;

    this._initFeedBodyFormatted();
    this._initFeedTitle();
    this._initVideoData();
    this.updateCreatedDateLabel();
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (this.feed_type !== 'COMMENT') {
      errors['feed_type'] = 'Only comments can be user created';
    }
    else if (this.feed_body.length > 500) {
      errors['feed_body'] = 'Comments are limited to a maximum of 500 characters';
    }

    return errors;
  }

  formatForPosting(to_delete: boolean = false) {
    if (!this.hasErrors() || !!to_delete) {
      const postable_feed: any = {
        project_task_feed_key: this.project_task_feed_key,
        project_task_key: this.project_task_key || this.child_project_task_key,
        feed_type: this.feed_type,
        feed_body: this.feed_body,
        attachment_file_name: this.attachment_file_name,
        deleted_flag: to_delete
      };
      //can only save attachments on new comments, can't edit an existing attachment
      if (!this.project_task_feed_key) {
        postable_feed.attachment_url = this.attachment_url;
        //postable_feed.attachment_file_name = this.attachment_file_name;
      }
      return postable_feed;
    }
  }

  updateCreatedDateLabel() {
    const today = DateTime.now();
    const created_date = DateTime.fromJSDate(this.created_date);

    if (created_date.hasSame(today, 'day')) {
      this.created_date_label = created_date.toFormat('hh:mma');
    }
    else {
      this.created_date_label = created_date.toFormat('d LLL, hh:mma');
    }
  }

  private _initFeedTitle() {
    switch (this.feed_type) {
      case 'COMMENT':
        this.feed_title = this.update_user?.display_name || null;
        break;
      case 'PROJECT_TASK_CREATED':
        this.feed_title = CoreUtilService.project_task_label.capitalised + ' Created';
        break;
      case 'PROJECT_TASK_SHARED':
        this.feed_title = CoreUtilService.project_task_label.capitalised + ' Shared';
        break;
      default:
        this.feed_title = null;
    }
  }

  private _initVideoData() {
    this.youtube_url = CoreUtilService.getYoutubeUrl(this.feed_body);
    this.vimeo_url = CoreUtilService.getVimeoUrl(this.feed_body);
  }

  private _initFeedBodyFormatted() {
    // Replace line-feed character code with HTML line breaks
    let feed_body = _.cloneDeep(this.feed_body).replace(new RegExp('&#10;', 'g'), '<br>');

    // Convert urls into links
    feed_body = feed_body.replace(CoreUtilService.url_regex, (url) => this._parseFeedBodyUrl(url));

    this.feed_body_formatted = feed_body;
  }

  private _parseFeedBodyUrl(url: string): string {
    return '<a target="_blank" href="' + url + '">' + url + '</a>';
  }

}
