import { Component, ElementRef, HostListener, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DomService } from '../../lib-services/dom/dom.service';
import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';
import _ from 'lodash-es';
import { BackdropComponent } from '../backdrop/backdrop.component';

@Component({
  selector: 'input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
  providers: [
    MakeProvider(InputAutocompleteComponent)
  ]
})
export class InputAutocompleteComponent extends AbstractValueAccessor {

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (DomService.is_mobile !== this.is_mobile) {
      this.is_mobile = DomService.is_mobile;
    }
  }

  @ViewChild(NgbDropdown) ngb_dropdown: NgbDropdown;
  @ViewChild('iac_content') iac_content: ElementRef;
  @ViewChildren('value_input') value_inputs: QueryList<ElementRef>;

  @Input() items: string[] = [];

  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  @Input() list_width: string = null;
  @Input() list_height: string = '350px';
  @Input() list_fill_width: boolean = true;

  filteredItems: string[] = [];

  private _searchInput: string = '';
  get searchInput(): string {
    return this._searchInput;
  }
  set searchInput(searchInput: string) {
    if (searchInput !== this._searchInput) {
      this._searchInput = searchInput;
      this.value = this._searchInput;
      this.dropdownToggleClicked();
    }
  }

  highlightedFilteredSupplierIndex: number = null;

  placement: string = null;

  backdrop_ref: BackdropComponent = null;

  constructor(
    public domService: DomService,
    private eRef: ElementRef
  ) {
    super();
  }

  ngAfterContentInit() {
    this.searchInputUpdated();
  }

  determineDropAlignment() {
    if (this.list_fill_width) {
      this.list_width = this.eRef.nativeElement.offsetWidth + 'px';
    }

    const dropdown_top = this.eRef.nativeElement.getBoundingClientRect().top;
    const drop_up = dropdown_top > (window.innerHeight / 2);
    const placement = (drop_up ? 'top' : 'bottom') + '-left';

    this.placement = placement;
  }

  selectItem(item) {
    this.searchInput = item;
    this.closeDropdown();
  }

  writeValue(value: any) {
    this._searchInput = value;
    super.writeValue(value);
  }

  searchInputUpdated() {
    if (!!this.searchInput) {
      const searchInputUpper = this.searchInput.toUpperCase();

      this.filteredItems = _.filter(this.items, (item) => {
        const itemUpper = item.toUpperCase();
        return itemUpper.includes(searchInputUpper);
      });
    }
    else {
      this.filteredItems = this.items;
    }
  }

  dropdownToggled(is_open: boolean) {
    if (is_open && !this.is_mobile) {
      this.searchInputUpdated();
      this.backdrop_ref = this.domService.openBackdrop();
    }
    else {
      this.backdrop_ref?.close();
    }
  }

  keyPressed(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowDown':
        if (this.highlightedFilteredSupplierIndex === null) {
          this.highlightedFilteredSupplierIndex = 0;
        }
        else if (this.highlightedFilteredSupplierIndex === this.filteredItems.length - 1) {
          this.highlightedFilteredSupplierIndex = 0;
        }
        else {
          this.highlightedFilteredSupplierIndex++;
        }
        break;
      case 'ArrowUp':
        if (this.highlightedFilteredSupplierIndex === null) {
          this.highlightedFilteredSupplierIndex = this.filteredItems.length - 1;
        }
        else if (this.highlightedFilteredSupplierIndex === 0) {
          this.highlightedFilteredSupplierIndex = this.filteredItems.length - 1;
        }
        else {
          this.highlightedFilteredSupplierIndex--;
        }
        break;
      case 'Enter':
        if (this.highlightedFilteredSupplierIndex === null) {
          this.closeDropdown();
        }
        else {
          this.selectItem(this.filteredItems[this.highlightedFilteredSupplierIndex]);
        }
        break;
      case 'Backspace':
        this.highlightedFilteredSupplierIndex = null;
        break;
    }
  }

  dropdownToggleClicked() {
    this.searchInputUpdated();

    if (!!this.filteredItems.length && !this.disabled && !this.readonly) {
      if (this.is_mobile) {
        this.openMobileDropdown();
      }
      else {
        this.determineDropAlignment();
        this.openDropdown();
      }
    }
    else {
      this.closeDropdown();
    }
  }

  openMobileDropdown() {
    this.domService.openMobileDropdown(this.iac_content, 'top');
  }

  openDropdown() {
    this.ngb_dropdown?.open();
  }

  closeDropdown() {
    this.highlightedFilteredSupplierIndex = null;

    this.domService.closeOffCanvas();
    this.backdrop_ref?.close();
    this.ngb_dropdown?.close();
  }

}
