import { Directive, HostBinding } from '@angular/core';
import { AppTheme } from '../../lib.types';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

@Directive({
  selector: '[]'
})
export abstract class ThemedComponentDirective {

  @HostBinding('class') app_theme: AppTheme = CoreUtilService.app_theme;

  constructor() {
    CoreUtilService.getAppThemeUpdatedEvent().subscribe(() => {
      setTimeout(() => {
        this.app_theme = CoreUtilService.app_theme;
      });
    });
  }

}
