import { ProductValue } from '../../lib.types';

export abstract class ReportingAccount {

  company_reference: string | number;
  company_name: string;
  product: ProductValue;

  constructor(
    company_reference: string | number,
    company_name: string
  ) {
    this.company_reference = company_reference;
    this.company_name = company_name;
  }

}
