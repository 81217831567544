<div class="app-dashBlock-header -dragHandle">
  <div class="app-dashBlock-headerLeft">
    <report-thumbnail [report]="report"></report-thumbnail>
    <button class="app-dashBlock-header-title -dragHandleIgnore"
            (click)="$event.stopPropagation(); goToReport()">
      <div>{{report?.report_title}}</div>
      <i class="ion ion-md-arrow-forward"></i>
    </button>
  </div>
  <div class="app-dashBlock-headerRight">
    <i class="ion ion-md-close app-remove -dragHandleIgnore"
       *ngIf="editing_enabled"
       (click)="$event.stopPropagation(); remove_block.emit({ block: block })">
    </i>
  </div>
</div>

<div class="app-dashBlock-content">
  <report-chart #reportChart
                *ngIf="!!report && report.report_type === 'CHART'"
                [chart_type]="$any(report).graph_type"
                [stack_series]="$any(report).series?.stack || false"
                [show_legend]="$any(report).series?.show_legend || false"
                [dimension_data]="query_data?.dimensions || null"
                [measure_data]="query_data?.measures || null"
                [series_data]="query_data?.series || null"
                [report]="$any(report)">
  </report-chart>

  <report-table #reportTable
                *ngIf="!!report && report.report_type === 'TABLE'"
                [report]="$any(report)"
                [table_rows]="query_data">
  </report-table>

  <report-numeric #reportNumeric
                  *ngIf="!!report && report.report_type === 'NUMERIC'"
                  [report]="$any(report)"
                  [report_data]="query_data">
  </report-numeric>
</div>
