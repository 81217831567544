import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import _ from 'lodash-es';
import irdNz from 'ird-nz/irdnz';

import { AppTheme, InfiniteParams, Label, ProductName, ProductValue, ProjectLabelConfig, ProjectTaskLabelConfig, RGBColor, TaskLabelConfig, WeekDayShort } from '../../lib.types';
import { ListDropdownItemConfig } from '../../../public-api';

type DropdownConfigKey = (
  'KM_PROJECT' | 'KM_TASK' | 'KM_PROJECT_TASK'
);

declare const Base64: any;
@Injectable({
  providedIn: 'root'
})
export class CoreUtilService {

  static readonly product_name_to_value_map: Record<ProductName, ProductValue> = {
    Droppah: 'DROPPAH',
    PayHero: 'PAYHERO',
    Invoxy: 'INVOXY',
    Karmly: 'KARMLY',
    FlexiTime: 'FLEXITIME'
  };

  static readonly product_value_to_name_map: Record<ProductValue, ProductName> = {
    DROPPAH: 'Droppah',
    PAYHERO: 'PayHero',
    INVOXY: 'Invoxy',
    KARMLY: 'Karmly',
    FLEXITIME: 'FlexiTime'
  };

  static getProductValue(product_name: ProductName): ProductValue {
    return this.product_name_to_value_map[product_name];
  }

  static getProductName(product_value: ProductValue): ProductName {
    return this.product_value_to_name_map[product_value];
  }

  private static _appThemeUpdatedEvent = new Subject<void>();

  static set_locked_description(invoxy_locked_flag: boolean, coworker_locked_flag: boolean, label: string): string {
    if (invoxy_locked_flag) {
      return 'This ' + label + ' is managed from Invoxy';
    }
    else if (coworker_locked_flag) {
      return 'This ' + label + ' is managed by a co-worker';
    } else {
      return null;
    }
  }

  static getAppThemeUpdatedEvent(): Observable<any> {
    return this._appThemeUpdatedEvent.asObservable();
  }

  private static _app_theme: AppTheme = '-theme-invoxy';
  static get app_theme(): AppTheme {
    return this._app_theme;
  }
  static set app_theme(app_theme: AppTheme) {
    this._app_theme = app_theme;
    this._appThemeUpdatedEvent.next();
  }

  // $rootScope equivalent global variables

  static readonly project_labels: Record<('INVOXY' | 'KARMLY'), ProjectLabelConfig> = {
    INVOXY: {
      capitalised: 'Placement',
      capitalised_plural: 'Placements',
      lowercase: 'placement',
      lowercase_plural: 'placements'
    },
    KARMLY: {
      capitalised: 'Project',
      capitalised_plural: 'Projects',
      lowercase: 'project',
      lowercase_plural: 'projects'
    }
  };
  static readonly task_labels: Record<('INVOXY' | 'KARMLY'), TaskLabelConfig> = {
    INVOXY: {
      capitalised: 'Work',
      capitalised_plural: 'Work',
      lowercase: 'work',
      lowercase_plural: 'work'
    },
    KARMLY: {
      capitalised: 'Category',
      capitalised_plural: 'Categories',
      lowercase: 'category',
      lowercase_plural: 'categories'
    }
  };
  static readonly project_task_labels: Record<('INVOXY' | 'KARMLY'), ProjectTaskLabelConfig> = {
    INVOXY: {
      capitalised: 'Work',
      capitalised_plural: 'Work',
      lowercase: 'work',
      lowercase_plural: 'work'
    },
    KARMLY: {
      capitalised: 'Task',
      capitalised_plural: 'Tasks',
      lowercase: 'task',
      lowercase_plural: 'tasks'
    }
  }
  static readonly report_labels: Record<('INVOXY' | 'KARMLY' | 'FLEXITIME'), ProjectTaskLabelConfig> = {
    INVOXY: {
      capitalised: 'Report',
      capitalised_plural: 'Reports',
      lowercase: 'report',
      lowercase_plural: 'reports'
    },
    KARMLY: {
      capitalised: 'Insight',
      capitalised_plural: 'Insights',
      lowercase: 'insight',
      lowercase_plural: 'insights'
    },
    FLEXITIME: {
      capitalised: 'Report',
      capitalised_plural: 'Reports',
      lowercase: 'report',
      lowercase_plural: 'reports'
    }
  }

  // These values are overwritten in each app's CompanyService
  static project_label: ProjectLabelConfig = CoreUtilService.project_labels.KARMLY;
  static task_label: TaskLabelConfig = CoreUtilService.task_labels.KARMLY;
  static project_task_label: ProjectTaskLabelConfig = CoreUtilService.project_task_labels.KARMLY;
  static report_label: ProjectTaskLabelConfig = CoreUtilService.report_labels.KARMLY;

  static currency_symbol: string = '$';
  static currency_code: string = 'NZD';
  static blob_sas_key: string = null;
  static country_key: string = 'NZ';
  static week_start: WeekDayShort = 'mon';

  //////////////////////////////////////////////////////////////////

  static readonly url_regex = /(https?:\/\/[^\s]+)/g;

  static readonly colour_palette = [
    ['#EF9A9A', '#CE93D8', '#9FA8DA', '#81D4FA', '#80CBC4', '#C5E1A5', '#FFF59D', '#FFCC80', '#BCAAA4', '#B0BEC5'],
    ['#EF5350', '#AB47BC', '#5C6BC0', '#29B6F6', '#26A69A', '#9CCC65', '#FFEE58', '#FFA726', '#8D6E63', '#78909C'],
    ['#E53935', '#8E24AA', '#3949AB', '#039BE5', '#00897B', '#7CB342', '#FDD835', '#FB8C00', '#6D4C41', '#546E7A'],
    ['#C62828', '#6A1B9A', '#283593', '#0277BD', '#00695C', '#558B2F', '#F9A825', '#EF6C00', '#4E342E', '#37474F']
  ];

  static readonly droppah_colour_palette: string[][] = [
    ['#FFA7C8', '#FFA193', '#FF9369', '#FFD78B', '#BDDE9A', '#9ACBFF', '#AFAFFF'],
    ['#DE6489', '#E12E00', '#FF6622', '#FFB446', '#84CA80', '#1A41FF', '#763EFF'],
    ['#A40D4C', '#991900', '#804413', '#A6790D', '#136841', '#203080', '#483680']
  ];

  static readonly droppah_text_colour_palette: Record<string, string> = {
    '#FFA7C8': '#000000', '#FFA193': '#000000', '#FF9369': '#000000', '#FFD78B': '#000000', '#BDDE9A': '#000000', '#9ACBFF': '#000000', '#AFAFFF': '#000000',
    '#DE6489': '#000000', '#E12E00': '#FFFFFF', '#FF6622': '#000000', '#FFB446': '#000000', '#84CA80': '#000000', '#1A41FF': '#FFFFFF', '#763EFF': '#FFFFFF',
    '#A40D4C': '#FFFFFF', '#991900': '#FFFFFF', '#804413': '#FFFFFF', '#A6790D': '#FFFFFF', '#136841': '#FFFFFF', '#203080': '#FFFFFF', '#483680': '#FFFFFF'
  };

  static nearestColorInPalette(color_hex: string, palette = this.droppah_colour_palette): string {
    // Distance between 2 colors (in RGB)
    const distance = (a: RGBColor, b: RGBColor) => {
      return Math.sqrt(Math.pow(a.r - b.r, 2) + Math.pow(a.g - b.g, 2) + Math.pow(a.b - b.b, 2));
    };

    const flat_palette = _.flatten(palette);
    const color_rgb = this.hexToRgb(color_hex);

    // Return nearest color from flat_palette
    let lowest = Number.POSITIVE_INFINITY;
    let tmp = null;
    let index = 0;

    flat_palette.forEach((test_color, i) => {
      tmp = distance(color_rgb, this.hexToRgb(test_color));

      if (tmp < lowest) {
        lowest = tmp;
        index = i;
      }
    });

    return flat_palette[index];
  }

  static getTextColorForBaseColor(color_hex: string): string {
    if (!color_hex) return '#000000';
    color_hex = color_hex.toUpperCase();

    if (this.droppah_text_colour_palette[color_hex]) {
      return this.droppah_text_colour_palette[color_hex];
    }

    const c = this.hexToRgb(color_hex);
    return (c.r + c.g + c.b) < 384 ? '#FFFFFF' : '#000000';
  }

  static get time_status_colours(): Record<string, string> {
    return {
      new_flag: '#00adef',
      pending_flag: '#ffce00',
      approved_flag: '#5eb22e',
      invoiced_flag: '#005a7d',
      paid_flag: '#ff6600'
    };
  }

  static numberIsValid(value: any): boolean {
    return !(value === null || value === undefined || value === '' || isNaN(Number(value)));
  }

  static stringIsValid(value: any): boolean {
    return typeof value === 'string';
  }

  static booleanIsValid(value: any): boolean {
    return value === true || value === false;
  }

  static dateIsValid(value: any): boolean {
    return Object.prototype.toString.call(value) === '[object Date]' && !isNaN(value.getTime());
  }

  static emailIsValid(value: any): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(value);
  }

  static irdNumberIsValid(ird_number: any, country_key: string = null): boolean {
    if ((!!country_key && country_key === 'NZ') || this.country_key === 'NZ') {
      if (ird_number.length === 8) {
        ird_number = '0' + ird_number;
      }
      return irdNz.isValid(ird_number) || ird_number === '000000000' || ird_number === '000-000-000';
    }
    return true;
  }

  /**
   * Parses a json object returns the unparsed data if an error is thrown
   */
  static parseJSON(data: any): any {
    try {
      return JSON.parse(data);
    }
    catch (err) {
      return data;
    }
  }

  static decode(data: string): any {
    try {
      return Base64.decode(data);
    }
    catch (err) {
      return data;
    }
  }

  static encode(data: string): string {
    try {
      return Base64.encode(data);
    }
    catch (err) {
      return data;
    }
  }

  static decodeQueryParams(params: any): any {
    try {
      return CoreUtilService.parseJSON(CoreUtilService.decode(params));
    }
    catch (err) {
      return params;
    }
  }

  static parseQueryParams(query_params: any): Record<string, any> {
    const parsed_params = {};
    for (const param_name of Object.keys(query_params)) {
      parsed_params[param_name] = CoreUtilService.parseJSON(query_params[param_name]);
    }
    return parsed_params;
  }

  static parseBoolean(boolean: boolean | string | number): boolean {
    return (boolean === true || boolean === 'true' || boolean === 1);
  }

  static numberIsInt(number: number): boolean {
    return number === parseInt((number + ''));
  }

  static capitaliseFirstLetter(str: string): string {
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Similar to indexOf().
   * Returns the nth occurence of a pattern in a string
   */
  static nthIndex(str: string, pattern: string, n: number): number {
    const L = str.length;
    let i = -1;
    while (n-- && i++ < L) {
      i = str.indexOf(pattern, i);
    }
    return i;
  }

  static getIntegrationURL(integration: string): string {
    switch (integration.toUpperCase()) {
      case 'XERO PAYROLL':
        return 'xero/payroll/';
      case 'KEYPAY':
        return 'keypay/';
      case 'XERO':
        return 'xero/';
      case 'PAYHERO':
        return 'payhero/';
      default:
        return null;
    }
  }

  ///////////////////////////////////////////////////////////////////

  static calculateInifiniteScrollDistance(
    scroll_container_height_px: number,
    scroll_distance_px: number
  ): number {
    return 10 / (scroll_container_height_px / scroll_distance_px);
  }

  static generateUrlFromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          resolve(reader.result as string);
        };
      }
      catch (err) {
        reject(err);
      }
    });
  }

  /**
   * Creates an array of identical items using the length provided
   * eg. createArrayOfItems(3, null) = [null, null, null]
   */
  static createArrayOfItems(length: number, item: any): any[] {
    const arr = [];

    for (let i = 0; i < length; i++) {
      arr.push(_.cloneDeep(item));
    }

    return arr;
  }

  static startsWithVowel(str: string): boolean {
    if (!str.length) {
      return false;
    }
    const vowels = ['A', 'E', 'I', 'I', 'O', 'U'];
    const first_letter = str.toUpperCase()[0];
    return vowels.indexOf(first_letter) !== -1;
  }

  static labelAlreadyExists(label: Label, labels: Label[]): boolean {
    for (const l of labels) {
      if (this.labelsMatch(l, label)) {
        return true;
      }
    }
    return false;
  }

  static removeLabelFromList(label: Label, list: Label[]): void {
    for (let i = list.length - 1; i >= 0; i--) {
      if (this.labelsMatch(list[i], label)) {
        list.splice(i, 1);
      }
    }
  }

  static labelsMatch(label_a: Label, label_b: Label): boolean {
    return label_a.title.toUpperCase() === label_b.title.toUpperCase() &&
      label_a.color.toUpperCase() === label_b.color.toUpperCase();
  }


  //  Infinite scroll functions /////////////////

  static reloadVisibleItems(
    infiniteParams: InfiniteParams,
    visibleItems: any[],
    items: any[],
    itemIsVisible: (item: any) => boolean
  ): any[] {
    // Clear visibleItems without losing its object reference
    visibleItems.length = 0;

    infiniteParams.indexOfLastVisibleItem = -1;
    infiniteParams.infiniteScrollDisabled = false;

    this.loadVisibleItems(infiniteParams, visibleItems, items, itemIsVisible);
    return _.clone(visibleItems);
  }

  static loadMoreVisibleItems(
    infiniteParams: InfiniteParams,
    visibleItems: any[],
    items: any[],
    itemIsVisible: (item: any) => boolean
  ): any[] {
    if (!infiniteParams.infiniteScrollDisabled) {

      const numVisibleItems = _.cloneDeep(visibleItems.length);
      this.loadVisibleItems(infiniteParams, visibleItems, items, itemIsVisible);

      // loadVisibleItems() hasn't added any more items,
      // so we must be at the end of the list
      if (numVisibleItems === visibleItems.length) {
        infiniteParams.infiniteScrollDisabled = true;
      }
    }
    return _.clone(visibleItems);
  }

  static loadVisibleItems(
    infiniteParams: InfiniteParams,
    visibleItems: any[],
    items: any[],
    itemIsVisible: (item: any) => boolean
  ): void {
    const maxItems = visibleItems.length + infiniteParams.rowsToRender;

    for (let i = infiniteParams.indexOfLastVisibleItem + 1; i < items.length; i++) {
      const item = items[i];

      if (itemIsVisible(item)) {

        if (infiniteParams.renderInReverse) {
          visibleItems.unshift(item);
        }
        else {
          visibleItems.push(item);
        }
        infiniteParams.indexOfLastVisibleItem = i;

        if (visibleItems.length === maxItems) {
          break;
        }
      }
    }
  }

  ////////////////////////////////////////////////

  static roundNumber(number: number, dp: number): number {
    dp = Number(dp) || 2; //make sure we have a number here. Default 2
    return Number(Math.round((number + 'e' + dp) as any) + 'e-' + dp); //either rounded number or NaN
  }

  static numberOfDp(number: number): number {
    return number % 1 ? number.toString().split('.')[1].length : 0;
  }

  // hello new world => Hello New World
  static toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() +
        txt.substr(1).toLowerCase();
    });
  }

  static toSentenceCase(sentence: string): string {
    return sentence.replace(
      /\w\S*/g,
      (txt) => {
        txt = txt.toUpperCase();
        if (txt === 'KEYPAY') return 'Employment Hero';
        else if (txt === 'PAYHERO') return 'PayHero';
        else return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  static arrayToMap(items: any[], item_key_property: string): Record<number, any> {
    const map = {};
    for (const item of items) {
      map[item[item_key_property]] = item;
    }
    return map;
  }

  static hexToRgb(hex: string): RGBColor {
    // Expand shorthand form (e.g. '03F') to full form (e.g. '0033FF')
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  static shadeColor(color: string, percent: number): string {
    const f = parseInt(color.slice(1), 16);
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const R = f >> 16;
    const G = f >> 8 & 0x00FF;
    const B = f & 0x0000FF;
    return '#' + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
  }

  static hexToRgba(hex: string, opacity: number): string {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
  }

  static rgbaOrHexColorToInt(col) {
    let vals;

    if (col[0] === '#') {
      vals = [
        parseInt(col.slice(1, 3), 16),
        parseInt(col.slice(3, 5), 16),
        parseInt(col.slice(5, 7), 16)
      ];
    }
    else if (col[0] === 'r') {
      col = col.slice(col.indexOf('(') + 1, col.indexOf(')') - 2);
      vals = col.split(',');
    }
    else {
      throw new Error('"' + col + '" is not a valid colour format. Must be rgba or hex');
    }

    return parseInt(vals[0]) * 65536 + parseInt(vals[1]) * 256 + parseInt(vals[2]);
  }
  static rgbaToHex(rgb) {
    rgb = rgb.slice(rgb.indexOf('(') + 1, rgb.indexOf(')') - 2);
    let vals = rgb.split(',');

    return '#' + ((1 << 24) + (parseInt(vals[0]) << 16) + (parseInt(vals[1]) << 8) + parseInt(vals[2])).toString(16).slice(1);
  }

  static intToRgbaColor(num) {
    num >>>= 0;
    const b = num & 0xFF,
      g = (num & 0xFF00) >>> 8,
      r = (num & 0xFF0000) >>> 16;
    //a = ( (num & 0xFF000000) >>> 24 ) / 255 ;

    return 'rgba(' + [r, g, b, 1].join(',') + ')';
  }

  static intToHexColor(num) {
    return CoreUtilService.rgbaToHex(CoreUtilService.intToRgbaColor(num));
  }


  static rgbToHex(r: number, g: number, b: number): string {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  static degreesToRadians(degrees: number): number {
    const pi = Math.PI;
    return degrees * (pi / 180);
  }

  static getRandomNumber(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return (Math.random() * (max - min) + min);
  }

  static getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max + 1);
    return Math.floor((Math.random() * (max - min) + min));
  }

  // Colors must be in hex format
  static getRandomColor(colors_to_exclude: string[] = []): string {
    colors_to_exclude = colors_to_exclude.map((color) => color.toUpperCase());
    const colors = _.filter(
      _.flatten(this.colour_palette),
      (color) => colors_to_exclude.indexOf(color) === -1
    );
    return colors[Math.floor(Math.random() * colors.length)];
  }

  static getRandomKarmlyThumbnail() {
    const graphic_number = this.getRandomInt(1, 11);
    const graphic_number_string = (graphic_number < 10 ? '0' : '') + graphic_number;
    return 'assets/product-imgs/karmly/graphics/thumbnails/Karmly_Thumbnail_' + graphic_number_string + '.svg';
  }

  static getKarmlyBanner(graphic_number: (1 | 2 | 3)) {
    const graphic_number_string = '0' + graphic_number;
    return 'assets/product-imgs/karmly/graphics/banners/Karmly_Banner_' + graphic_number_string + '.svg';
  }

  static generateUUID(): string {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // eslint-disable-next-line eqeqeq
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  static getNestedPropertyValue(object: any, propertyName: string): any {
    try {
      const propertyParts = propertyName.split('.');
      let property = object;

      for (const part of propertyParts) {
        property = property[part];
      }

      return property === undefined ? null : property;
    }
    catch (err) {
      return null;
    }
  }

  /**
   * Returns a subset of allItems, removing any items that exist in filteredItems
   */
  static getUnfilteredItems(allItems: any[], filteredItems: any[], item_key: string): any[] {
    const unfilteredItems = [];
    const filteredItemsMap = {};

    for (const item of filteredItems) {
      filteredItemsMap[item[item_key]] = true;
    }

    for (const item of allItems) {
      if (!filteredItemsMap[item[item_key]]) {
        unfilteredItems.push(item);
      }
    }

    return unfilteredItems;
  }

  /**
   * Checks if two objects or primitives are 'loosely' equal.
   * For objects, object properties need to match but don't need to reference the exact same value
   * ie. Difference between == and ===
   *
   * @param {*} x
   * @param {*} y
   * @param {Array<String>} propsToIgnore
   * @returns {boolean}
   */
  static isLooselyEqual(
    x: any,
    y: any,
    propsToIgnore: any[] = [],
    debug: boolean = false
  ): boolean {
    propsToIgnore = propsToIgnore || [];

    const x_is_arrow_function = this.isArrowFunction(x);
    const y_is_arrow_function = this.isArrowFunction(y);

    if (x_is_arrow_function && y_is_arrow_function) {
      const is_loosely_equal = this.isLooselyEqual(x(), y(), propsToIgnore, debug);
      if (!!debug && !is_loosely_equal) console.log(x(), y());
      return is_loosely_equal;
    }
    else if (x_is_arrow_function) {
      const is_loosely_equal = this.isLooselyEqual(x(), y, propsToIgnore, debug);
      if (!!debug && !is_loosely_equal) console.log(x(), y);
      return is_loosely_equal;
    }
    else if (y_is_arrow_function) {
      const is_loosely_equal = this.isLooselyEqual(x, y(), propsToIgnore, debug);
      if (!!debug && !is_loosely_equal) console.log(x, y());
      return is_loosely_equal;
    }
    else {
      // if both x and y are exactly the same or are both null/undefined/''
      if (x === y || (!x && !y)) {
        return true;
      }

      // if they are not strictly equal, they both need to be Objects
      if (!(x instanceof Object) || !(y instanceof Object)) {
        if (!!debug) console.log(x, y);
        return false;
      }

      // they must have the exact same prototype chain, the closest we can do is
      // test their constructor.
      if (x.constructor !== y.constructor) {
        if (!!debug) console.log(x, y);
        return false;
      }

      // Compare date objects
      if (x instanceof Date && y instanceof Date) {
        const is_loosely_equal = x.valueOf() === y.valueOf();
        if (!!debug && !is_loosely_equal) console.log(x, y);
        return is_loosely_equal;
      }

      const xKeys = Object.keys(x);
      const yKeys = Object.keys(y);

      let validXKeysLength = 0;
      let validYKeysLength = 0;

      for (let i = 0; i < xKeys.length; i++) {
        if (propsToIgnore.indexOf(xKeys[i]) === -1 && xKeys[i] !== '$$hashKey') {
          validXKeysLength++;
        }
      }
      for (let i = 0; i < yKeys.length; i++) {
        if (propsToIgnore.indexOf(yKeys[i]) === -1 && yKeys[i] !== '$$hashKey') {
          validYKeysLength++;
        }
      }

      // taking into account properties that should be ignored,
      // both objects should have the same number of properties
      if (validXKeysLength !== validYKeysLength) {
        if (!!debug) console.log(x, y);
        return false;
      }

      for (const p of Object.keys(x)) {
        // Ignore properties in propsToIgnore
        if (propsToIgnore.indexOf(p) !== -1) {
          continue;
        }

        // if they have the same value or identity then they are equal
        // eslint-disable-next-line eqeqeq
        if (x[p] == y[p]) {
          continue;
        }
        // Objects and Arrays must be tested recursively
        if (!CoreUtilService.isLooselyEqual(x[p], y[p], propsToIgnore, debug)) {
          if (!!debug) {
            console.log(x, y);
          }
          return false;
        }
      }

      return true;
    }
  }

  static isArrowFunction(fn: any): boolean {
    try {
      return (typeof fn === 'function') && /^[^{]+?=>/.test(fn.toString());
    }
    catch (err) {
      return false;
    }
  }

  static getYoutubeVideoID(url: string): string {
    const [a, , b] = url
      .replace(/(>|<)/gi, '')
      .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (b !== undefined) {
      return b.split(/[^0-9a-z_-]/i)[0];
    }
    else {
      return a;
    }
  }

  static getVimeoUrl(str: string): string {
    const urls = str.match(CoreUtilService.url_regex) || [];

    for (const url of urls) {
      if (url.indexOf('vimeo.com') !== -1) {
        return url;
      }
    }
    return null;
  }

  static getYoutubeUrl(str: string): string {
    const urls = str.match(CoreUtilService.url_regex) || [];

    for (const url of urls) {
      if (url.indexOf('youtube.com') !== -1) {
        return url;
      }
    }
    return null;
  }

  static getDefaultDropdownConfig(
    config_key: DropdownConfigKey,
    additional_config_data: ListDropdownItemConfig = {}
  ): ListDropdownItemConfig {
    let config: ListDropdownItemConfig = null;
    additional_config_data = additional_config_data || {};

    switch (config_key) {
      case 'KM_PROJECT':
        config = {
          type: this.project_label.capitalised,
          type_plural: this.project_label.capitalised_plural,
          label: 'project_name',
          bullet_colour: 'project_colour'
        };
        break;
      case 'KM_TASK':
        config = {
          type: this.task_label.capitalised,
          type_plural: this.task_label.capitalised_plural,
          label: 'task_name'
        };
        break;
      case 'KM_PROJECT_TASK':
        config = {
          type: this.project_task_label.capitalised,
          type_plural: this.project_task_label.capitalised_plural,
          label: 'project_task_name'
        };
        break;
    }

    return Object.assign(
      config,
      additional_config_data
    );
  }

}
