import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { PostableObject, PostableObjectErrorMap } from '../../../lib-interfaces/postable-object.interface';
import { CoreUtilService } from '../../../lib-services/core-util/core-util.service';

import { Segment } from './../segment';

import _ from 'lodash-es';
import { DateTime } from 'luxon';

export class InvSegment extends Segment implements PostableObject<InvSegment> {

  company_key: number;

  new_flag: boolean;
  pending_flag: boolean;
  approved_flag: boolean;
  invoiced_flag: boolean;
  paid_flag: boolean;
  admin_only_flag: boolean;

  private _project: any;
  private _task: any;
  user: any;

  notes: string;

  private _project_active_on_day: boolean;

  private _company_expenses_enabled: boolean;

  constructor(
    segment_key: number = null,
    deleted_flag: boolean = false,
    row_version: string,
    unit_flag: boolean,
    credit_flag: boolean,
    segment_date: Date,
    start_time: Date = null,
    end_time: Date = null,
    break_duration: number = 0,
    units: number = null,
    company_key: number,
    notes: string = '',
    project: any,
    task_key: number,
    user: any,
    company_expenses_enabled: boolean,
    new_flag: boolean = true,
    pending_flag: boolean = false,
    approved_flag: boolean = false,
    invoiced_flag: boolean = false,
    paid_flag: boolean = false,
    admin_only_flag: boolean = false
  ) {
    const task = _.find(project.tasks, { task_key });

    if (unit_flag) {
      start_time = null;
      end_time = null;
      break_duration = null;

      units = units || 0;
    }
    else {
      break_duration = break_duration || 0;

      if (start_time === null || end_time === null) {
        const d = InvSegment._generateSegmentTimeDefaults(segment_date, user, task);

        start_time = d.start_time;
        end_time = d.end_time;
        break_duration = d.break_duration;
      }

      units = null;
    }

    super(
      false, true, false,
      segment_key, deleted_flag, row_version, unit_flag, credit_flag,
      segment_date, start_time, end_time, break_duration, units || 0
    );

    this.company_key = company_key;

    this.new_flag = new_flag;
    this.pending_flag = pending_flag;
    this.approved_flag = approved_flag;
    this.invoiced_flag = invoiced_flag;
    this.paid_flag = paid_flag;
    this.admin_only_flag = admin_only_flag;

    this._project = project;
    this._task = task;
    this.user = user;
    this.notes = notes;

    this._company_expenses_enabled = company_expenses_enabled;

    this._updateTaskOnProjectChange();
    this._updateProjectActiveOnDay();
    this._updateUnitType();
  }

  // Super class function extensions /////////////////////////////////
  get segment_date(): Date {
    return super.segment_date;
  }
  set segment_date(segment_date: Date) {
    super.segment_date = segment_date;
    this._updateProjectActiveOnDay();
  }

  // Super class getters/setters //////////////////////////////////////
  get colour(): string {
    return this.task.task_colour;
  }
  get work_name(): string {
    return this.task.task_display_name;
  }
  get is_locked(): boolean {
    return this.task.archived_flag ||
      this.project.archived_flag ||
      this.approved_flag ||
      this.invoiced_flag ||
      this.paid_flag ||
      this.admin_only_flag;
  }
  get project_active_on_day(): boolean {
    return this._project_active_on_day;
  }

  _updateUnitType(): void {
    const unit_type = (this.task.unit_type || this.project.rate_type).toLowerCase();

    this._is_custom_unit = ['hours', 'days', 'expense'].indexOf(unit_type) === -1;
    this._unit_type = unit_type;
  }

  getErrors(): PostableObjectErrorMap {
    const errors = super.getErrors();

    if (!this.project) {
      errors['project'] = 'An active ' + CoreUtilService.project_label.lowercase + ' must be set';
    }
    else if (this.project.archived_flag) {
      errors['project'] = 'The ' + CoreUtilService.project_label.lowercase + ' for this time entry is archived';
    }
    else if (!this._project_active_on_day) {
      errors['project'] = 'Time can\'t be recorded outside the period that the ' + CoreUtilService.project_label.lowercase + ' is active';
    }
    if (!this.task) {
      errors['task'] = 'An active ' + CoreUtilService.task_label.lowercase + ' must be set';
    }
    else if (this.task.archived_flag) {
      errors['task'] = 'The ' + CoreUtilService.task_label.lowercase + ' for this time entry is archived';
    }

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  formatForPosting(to_delete: boolean = false): any {
    if (this.unit_flag) {
      const data: any = {
        segment_key: this.segment_key,
        row_version: this.row_version,
        company_key: this.company_key,
        user_key: this.user.user_key,
        project_key: this.project.project_key,
        task_key: this.task.task_key,
        segment_date: TimeUtilService.dateToDateString(this.segment_date),
        notes: this.notes,
        deleted_flag: to_delete || this.deleted_flag,
        units: this.units,
        unit_type: this.task.unit_flag ? this.task.unit_type : this.project.rate_type
      };

      if (this.credit_flag) {
        data.request_date = TimeUtilService.dateToDateTimeString(new Date());
      }
      return data;
    }
    else {
      return {
        segment_key: this.segment_key,
        row_version: this.row_version,
        company_key: this.company_key,
        user_key: this.user.user_key,
        project_key: this.project.project_key,
        task_key: this.task.task_key,
        segment_date: TimeUtilService.dateToDateString(this.segment_date),
        start_time: TimeUtilService.dateToDateTimeString(this.start_time),
        end_time: TimeUtilService.dateToDateTimeString(this.end_time),
        notes: this.notes,
        deleted_flag: to_delete || this.deleted_flag,
        duration: this.duration,
        break_duration: this.break_duration
      };
    }
  }

  // Class getters/setters //////////////////////////////////////
  get project(): any {
    return this._project;
  }
  set project(project: any) {
    this._project = project;

    this._updateUnitType();
    this._updateTaskOnProjectChange();
    this._updateProjectActiveOnDay();
  }

  get task(): any {
    return this._task;
  }
  set task(task: any) {
    this._task = task;
    this._updateUnitType();
  }

  get is_active_task(): boolean {
    return !this.task.archived_flag;
  }
  get status_color(): string {
    if (this.paid_flag) {
      return '#005a7d';
    }
    else if (this.invoiced_flag) {
      return '#f60';
    }
    else if (this.approved_flag) {
      return '#5eb22e';
    }
    else if (this.pending_flag) {
      return '#ffce00';
    }
    else {
      return '#00adef';
    }
  }
  get status(): string {
    if (this.paid_flag) {
      return 'Paid';
    }
    else if (this.invoiced_flag) {
      return 'Invoiced';
    }
    else if (this.approved_flag) {
      return 'Approved';
    }
    else if (this.pending_flag) {
      return 'Pending';
    }
    else {
      return 'New';
    }
  }
  get resource_status_color(): string {
    if (this.approved_flag || this.invoiced_flag || this.paid_flag) {
      return '#5eb22e';
    }
    else if (this.pending_flag) {
      return '#ffce00';
    }
    else {
      return '#00adef';
    }
  }
  get resource_status(): string {
    if (this.approved_flag || this.invoiced_flag || this.paid_flag) {
      return 'Approved';
    }
    else if (this.pending_flag) {
      return 'Pending';
    }
    else {
      return 'New';
    }
  }

  // Class functions //////////////////////////////////////
  private _updateProjectActiveOnDay() {
    const segment_date = DateTime.fromJSDate(this.segment_date).startOf('day');
    const project_start_date = DateTime.fromJSDate(this.project.start_date).startOf('day');
    const project_end_date = !!this.project.end_date ? DateTime.fromJSDate(this.project.end_date).startOf('day') : null;

    this._project_active_on_day = segment_date >= project_start_date &&
      (!project_end_date || segment_date <= project_end_date);
  }

  // Ensure the current task is valid for the current project
  private _updateTaskOnProjectChange() {
    if (!this.taskIsVisible(this.task)) {
      let task = null;

      for (const t of this.project.tasks) {
        if (this.taskIsVisible(t)) {
          task = t;
          break;
        }
      }

      this.task = task;
    }
  }

  private static _generateSegmentTimeDefaults(segment_date: Date, user: any, task: any): any {
    const start_time = _.cloneDeep(segment_date);
    if (user.default_start_time !== null) {
      start_time.setHours(user.default_start_time.getHours(), user.default_start_time.getMinutes(), 0, 0);
    }
    else {
      start_time.setHours(9, 0, 0, 0);
    }

    const end_time = TimeUtilService.incrementHours(start_time, 8);

    let break_duration = task.default_break_duration;
    const totalDurationDec = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(start_time, end_time);
    // Ensure break duration isn't greater than the total segment duration
    if (break_duration > totalDurationDec) {
      break_duration = totalDurationDec;
    }

    return {
      start_time,
      end_time,
      break_duration
    };
  }

  taskIsVisible(task: any) {
    if (task.archived_flag || !_.find(this.project.tasks, { task_key: task.task_key })) {
      return false;
    }
    // Expense segment
    if (this._company_expenses_enabled && this.unit_type === 'expense') {
      return task.unit_type === 'expense';
    }
    // Unit segment
    else if (this.unit_flag) {
      return (this.credit_flag || task.unit_flag || this.project.rate_type === 'days') &&
        (!this._company_expenses_enabled || task.unit_type !== 'expense');
    }
    // Duration segment
    else {
      return !task.unit_flag;
    }
  }

}
