import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[highlight-on-click]'
})
export class HighlightOnClickDirective {

  @HostListener('click', ['$event'])
  onClick(event: any) {
    if (event.target.tagName === 'INPUT') {
      event.target.select();
    }
  }

}
