<button class="-type-text -color-default-clear"
        *ngIf="!is_mobile"
        (click)="back()">
  <i class="ion ion-md-arrow-dropleft"></i>
  <div>Back</div>
</button>

<button class="-type-icon -color-default-clear"
        *ngIf="is_mobile"
        (click)="back()">
  <i class="ion ion-ios-arrow-back"></i>
</button>
