<button class="-type-icon"
        [ngClass]="is_mobile ? '-color-default-clear' : '-color-white-outline'"
        [disabled]="disabled"
        (click)="backMonth()">
  <i class="ion ion-md-arrow-dropleft"></i>
</button>

<div class="monthSelector-textContainer"
     ngbDropdown
     #ngbDropdown="ngbDropdown"
     [container]="'body'"
     [display]="'static'"
     [dropdownClass]="'dropdown'"
     (openChange)="dropdownToggled($event)">
  <div ngbDropdownToggle>
    {{selected_month | date : 'MMMM, yyyy'}}
  </div>
  <div class="monthSelector-dropdown"
       ngbDropdownMenu>
    <calendar-month-grid *ngIf="is_open"
                         (month_selected)="monthSelected()">
    </calendar-month-grid>
  </div>
</div>

<button class="-type-icon"
        [ngClass]="is_mobile ? '-color-default-clear' : '-color-white-outline'"
        [disabled]="disabled"
        (click)="forwardMonth()">
  <i class="ion ion-md-arrow-dropright"></i>
</button>
