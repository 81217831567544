import { KmNote } from '../km-note/km-note';

export class KmSegmentStub {

  segment_key: number;
  project_key: number;
  project_name: string;
  project_colour: string;
  project_completed_flag: boolean;
  project_rate_type: ('hours' | 'days');
  task_key: number;
  task_name: string;
  task_archived_flag: boolean;
  project_task_key: number;
  project_task_name: string;
  segment_date: Date;
  start_time: Date;
  end_time: Date;
  duration: number;
  break_duration: number;
  units: number;
  unit_type: string;
  unit_flag: boolean;
  notes: KmNote[];
  coworker_key: number;
  user_display_name: string;

  constructor(
    segment_key: number,
    project_key: number,
    project_name: string,
    project_colour: string,
    project_completed_flag: boolean,
    project_rate_type: ('hours' | 'days'),
    task_key: number,
    task_name: string,
    task_archived_flag: boolean,
    project_task_key: number,
    project_task_name: string,
    segment_date: Date,
    start_time: Date,
    end_time: Date,
    duration: number,
    break_duration: number,
    units: number,
    unit_type: string,
    unit_flag: boolean,
    notes: KmNote[],
    coworker_key: number = null,
    user_display_name: string,
    coworker_name: string,
    coworker_url: string,
    active_flag: boolean
  ) {
    this.segment_key = segment_key;
    this.project_key = project_key;
    this.project_name = project_name;
    this.project_colour = project_colour;
    this.project_completed_flag = project_completed_flag;
    this.project_rate_type = project_rate_type;
    this.task_key = task_key;
    this.task_name = task_name;
    this.task_archived_flag = task_archived_flag;
    this.project_task_key = project_task_key;
    this.project_task_name = project_task_name;
    this.segment_date = segment_date;
    this.start_time = start_time;
    this.end_time = end_time;
    this.duration = duration;
    this.break_duration = break_duration;
    this.units = units;
    this.unit_type = unit_type || project_rate_type;
    this.unit_flag = unit_flag;
    this.notes = notes;
    this.coworker_key = coworker_key;
    this.user_display_name = user_display_name;
  }

}
