<div ngbDropdown
     #ngbDropdown="ngbDropdown"
     [container]="'body'"
     [autoClose]="'outside'"
     [placement]="'bottom-right top-right'"
     (click)="$event.stopPropagation()">
  <button class="-color-white-outline -type-dropdown ld-dropdownBtn"
          [disabled]="dropdown_disabled"
          ngbDropdownToggle>
    <div>Add Label</div>
    <i class="ion ion-md-arrow-dropdown"></i>
  </button>

  <div class="ld-dropdown"
       ngbDropdownMenu>

    <div class="ld-dropdown-header">
      <app-search [placeholder]="'Type to add a label...'"
                  [(search)]="search"
                  (searchChange)="reloadVisibleLabels()">
      </app-search>

      <colour-picker [(ngModel)]="colour"
                     [disabled]="!valid_label"
                     [disable_body_append]="true">
      </colour-picker>

      <button class="-type-icon -color-success"
              [disabled]="!valid_label"
              (click)="newLabel()">
        <i class="ion ion-ios-add"></i>
      </button>
    </div>

    <div class="app-label-container">
      <div class="app-label"
           *ngFor="let label of visible_labels"
           [ngStyle]="{ 'background-color': label.color }"
           (click)="addExistingLabel(label)">
        <div>
          {{label.title}}
        </div>
      </div>
    </div>

    <div class="ld-dropdown-empty"
         *ngIf="visible_labels.length === 0">
      <div *ngIf="!!search">No results for '{{search}}'</div>
      <div *ngIf="!search">No labels found</div>
    </div>
  </div>
</div>
