<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div [innerHTML]="line.line_description || 'New Line'">
          </div>
          <div class="app-nav-titleDescription"
               *ngIf="!line.generated_flag && !!project">
            <item-bullet [bullet_colour]="project.project_colour"></item-bullet>
            <span>{{project.project_name}}</span>
          </div>
          <div class="app-nav-titleDescription"
               *ngIf="line.generated_flag">
            <i class="bi bi-lightning-charge-fill"></i>
            <span>This line was generated automatically</span>
          </div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <div class="app-formItem-container">

        <app-form-item [form_errors]="line_errors"
                       [label]="'Description'"
                       [error_keys]="'line_description'"
                       [readonly]="invoice.posted_flag">

          <textarea placeholder="Add Description"
                    [id]="'line-editor-modal-' + line.line_index"
                    [disabled]="invoice.posted_flag">
          </textarea>
        </app-form-item>

        <app-form-item *ngIf="show_quantity"
                       [form_errors]="line_errors"
                       [label]="'Quantity'"
                       [error_keys]="'quantity'"
                       [readonly]="invoice.posted_flag">

          <input *ngIf="!invoice.posted_flag"
                 type="number"
                 class="form-control"
                 placeholder="0.00"
                 highlight-on-click
                 [disabled]="invoice.template_type === 'AOG'"
                 [ngModelOptions]="{ 'updateOn': 'blur' }"
                 [(ngModel)]="line.quantity"
                 (ngModelChange)="recalculateLineAmounts()">

          <div class="app-invoice-lineCol-value"
               *ngIf="invoice.posted_flag">
            {{line.quantity | appNumber}}
          </div>
        </app-form-item>

        <app-form-item *ngIf="show_rate"
                       [form_errors]="line_errors"
                       [label]="'Rate'"
                       [error_keys]="'rate'"
                       [readonly]="invoice.posted_flag">

          <div class="input-group"
               *ngIf="!invoice.posted_flag">
            <div class="input-group-addon">
              {{currency_symbol}}
            </div>
            <input type="number"
                   class="form-control"
                   placeholder="0.00"
                   highlight-on-click
                   [disabled]="invoice.template_type === 'AOG'"
                   [ngModelOptions]="{ 'updateOn': 'blur' }"
                   [(ngModel)]="line.rate"
                   (ngModelChange)="recalculateLineAmounts()">
          </div>

          <div class="input-group"
               *ngIf="invoice.posted_flag">
            <div class="input-group-addon">
              {{currency_symbol}}
            </div>
            <div class="input-group-value">
              {{line.rate | appNumber : 2}}
            </div>
          </div>
        </app-form-item>

        <app-form-item *ngIf="show_total"
                       [form_errors]="line_errors"
                       [label]="'Amount'"
                       [readonly]="true"
                       [hide_border]="true">

          <div class="input-group">
            <div class="input-group-addon">
              {{currency_symbol}}
            </div>
            <div class="input-group-value">
              {{line.net_amount || 0 | appNumber : 2}}
            </div>
          </div>
        </app-form-item>

      </div>

    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterLeft">
      <button class="-type-icon -color-grey-clear"
              (click)="deleteLine()">
        <i class="ion ion-md-trash"></i>
      </button>
    </div>
    <div class="app-modal-btnFooterRight">
      <button class="-color-success"
              (click)="validateForm()">
        Save
      </button>
      <button class="-color-white-outline -desktop-only"
              (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
