import { TaskBillRateModifier, TaskPayRateModifier } from '../../lib.types';

export class InvTaskStub {

  allow_expenses: boolean;
  account_code: string;
  archived_flag: boolean;
  pay_code: string;
  bill_type: TaskBillRateModifier;
  pay_type: TaskPayRateModifier;
  task_colour: string;
  common_flag: boolean;
  created_date: Date;
  updated_date: Date;
  default_break_duration: number;
  include_oncosts: boolean;
  notes: string;
  on_cost_rate: number;
  payhero_project_key: number;
  task_display_name: string;
  task_key: number;
  task_name: string;
  task_rate: number;
  tax_rate: number;
  tax_type: string;
  unit_flag: boolean;
  unit_type: string;

  pay_add: number;
  pay_fixed: number;
  pay_multiplier: number;
  bill_add: number;
  bill_fixed: number;
  billable_multiplier: number;
  bill_margin: number;

  monday_hours: number;
  tuesday_hours: number;
  wednesday_hours: number;
  thursday_hours: number;
  friday_hours: number;
  saturday_hours: number;
  sunday_hours: number;

  constructor(
    allow_expenses: boolean,
    account_code: string = null,
    archived_flag: boolean = false,
    pay_code: string = null,
    bill_type: TaskBillRateModifier = 'Multiplier',
    pay_type: TaskPayRateModifier = 'Multiplier',
    task_colour: string = '#F9A825',
    common_flag: boolean = false,
    created_date: Date = null,
    updated_date: Date = null,
    default_break_duration: number = null,
    include_oncosts: boolean = false,
    notes: string = '',
    on_cost_rate: number = null,
    payhero_project_key: number = null,
    task_display_name: string = '',
    task_key: number = null,
    task_name: string = '',
    task_rate: number = 0,
    tax_rate: number = null,
    tax_type: string = null,
    unit_flag: boolean,
    unit_type: string = null,
    pay_add: number = 0,
    pay_fixed: number = 0,
    pay_multiplier: number = 0,
    bill_add: number = 0,
    bill_fixed: number = 0,
    billable_multiplier: number = 0,
    bill_margin: number = 0,
    monday_hours: number = 0,
    tuesday_hours: number = 0,
    wednesday_hours: number = 0,
    thursday_hours: number = 0,
    friday_hours: number = 0,
    saturday_hours: number = 0,
    sunday_hours: number = 0,
  ){
    this.allow_expenses = allow_expenses;
    this.account_code = account_code;
    this.archived_flag = archived_flag;
    this.pay_code = pay_code;
    this.bill_type = bill_type;
    this.pay_type = pay_type;
    this.task_colour = task_colour;
    this.common_flag = common_flag;
    this.created_date = created_date;
    this.updated_date = updated_date;
    this.default_break_duration = default_break_duration;
    this.include_oncosts = include_oncosts;
    this.notes = notes;
    this.on_cost_rate = on_cost_rate;
    this.payhero_project_key = payhero_project_key;
    this.task_display_name = task_display_name;
    this.task_key = task_key;
    this.task_name = task_name;
    this.task_rate = task_rate;
    this.tax_rate = tax_rate;
    this.tax_type = tax_type;
    this.unit_flag = unit_flag;
    this.unit_type = unit_type;
    this.pay_add = pay_add;
    this.pay_fixed = pay_fixed;
    this.pay_multiplier = pay_multiplier;
    this.bill_add = bill_add;
    this.bill_fixed = bill_fixed;
    this.billable_multiplier = billable_multiplier;
    this.bill_margin = bill_margin;
    this.monday_hours = monday_hours;
    this.tuesday_hours = tuesday_hours;
    this.wednesday_hours = wednesday_hours;
    this.thursday_hours = thursday_hours;
    this.friday_hours = friday_hours;
    this.saturday_hours = saturday_hours;
    this.sunday_hours = sunday_hours;
  }

}
