import { ElementRef, EventEmitter, QueryList } from '@angular/core';
import { GridsterComponent, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import _ from 'lodash-es';

import { CoreUtilService } from '../../../lib-services/core-util/core-util.service';
import { DashGridBlock, DashGridBlockComponent } from './dash-grid-block';
import { DashGridBlockConfig } from '../../../lib.types';

type GridResizableHandles = {
  s: boolean, e: boolean, n: boolean, w: boolean,
  se: boolean, ne: boolean, sw: boolean, nw: boolean
};

export abstract class DashGrid {

  private _component_visible: boolean = false;

  abstract readonly default_blocks: DashGridBlockConfig[];
  abstract readonly block_size_defaults: Record<string, { rows: number, cols: number }>;

  currency_symbol = CoreUtilService.currency_symbol;
  grid_options: GridsterConfig = null;

  grid_snapshot_map = {};
  editing_enabled = false;
  empty_block_only_at_bottom_of_grid = false;

  non_empty_block_count: number = 0;

  new_block_default_height = 3;

  readonly columns: number = 12;

  abstract is_mobile: boolean;

  abstract gridster_component: GridsterComponent;
  abstract block_components: QueryList<DashGridBlockComponent>;

  abstract blocks: DashGridBlock[];
  abstract backup_block: DashGridBlock;

  // Inputs
  abstract block_config: DashGridBlockConfig[];

  // Outputs
  abstract save_block_config: EventEmitter<any>;

  _initDashGrid() {
    this._initGridOptions();
    this.setupDashBlocks();
    this.updateMinRows();
    this._updateNonEmptyBlockCount();
    this._fillEmptySpaces();
  }

  abstract allBlocksActive(): boolean;
  abstract setupDashBlocks(): void;
  abstract addEmptyBlock(x: number, y: number): void;

  constructor(
    public elementRef: ElementRef
  ) { }

  doCheck() {
    this._checkComponentVisibility();
  }

  // Trigger Gridster to check if it needs to re-render after the visiblilty of the component has changed
  private _checkComponentVisibility() {
    // offsetParent is null when component or a parent component is hidden
    const component_visible = !!this.elementRef.nativeElement.offsetParent;
    if (this._component_visible !== component_visible) {
      this._component_visible = component_visible;

      if (this._component_visible) {
        this.grid_options?.api?.resize();
      }
    }
  }

  // Remove unnecessary fields from DashGridBlock class and convert to JSON
  filterBlocksForSaving(): DashGridBlockConfig[] {
    const blocks = this._filterEmptyDashBlocks();
    const block_data = [];

    for (const block of blocks) {
      block_data.push({
        rows: block.rows,
        cols: block.cols,
        y: block.y,
        x: block.x,
        block_type: block.block_type,
        report_key: block.report_key
      });
    }
    return block_data;
  }

  removeBlock(block: DashGridBlock) {
    for (let i = 0; i < this.blocks.length; i++) {
      const b = this.blocks[i];
      if (b.y === block.y && b.x === block.x) {
        this.blocks.splice(i, 1);
      }
    }
    this.saveConfig();
    this.updateMinRows();
    this._fillEmptySpaces();
    // TODO: review this
    // Current way of handling bug where gridster-preview hangs around after deleting block sometimes
    // https://github.com/tiberiuzuld/angular-gridster2/issues/516
    this.gridster_component.movingItem = null;
  }

  updateMinRows() {
    const lowest_block = this.getLowestBlock();

    let rows = 0;
    if (lowest_block) {
      rows = lowest_block.y + lowest_block.rows;
    }
    rows += this.new_block_default_height;

    this.grid_options.minRows = rows;
    setTimeout(() => this.grid_options.api.optionsChanged());
  }

  saveConfig() {
    this._updateNonEmptyBlockCount();
    this.save_block_config?.emit({ config: this.filterBlocksForSaving() });
  }

  setResizableHandles(active_handles: Partial<GridResizableHandles>) {
    const all_handles: GridResizableHandles = {
      s: active_handles.s || false,
      e: active_handles.e || false,
      n: active_handles.n || false,
      w: active_handles.w || false,
      se: active_handles.se || false,
      ne: active_handles.ne || false,
      sw: active_handles.sw || false,
      nw: active_handles.nw || false
    };
    this.grid_options.resizable.handles = all_handles;
  }

  _updateBlockDimensionsAndPositionForNewBlock(selected_block: DashGridBlock) {
    // Need to remove current block form list of blocks we're comparing against
    const blocks = this._getAllBlocksExcludingThisOne(selected_block);

    const closestLeft = this._getClosestLeftBlock(selected_block, blocks);
    const closestRight = this._getClosestRightBlock(selected_block, blocks);

    selected_block.x = closestLeft ? closestLeft.x + closestLeft.cols : 0;
    selected_block.cols = closestRight ? closestRight.x - selected_block.x : (this.grid_options.columns - selected_block.x);

    const closestAbove = this._getClosestAboveBlock(selected_block, blocks);
    const closestBelow = this._getClosestBelowBlock(selected_block, blocks);

    selected_block.y = closestAbove ? closestAbove.y + closestAbove.rows : 0;

    if (closestBelow) {
      selected_block.rows = closestBelow.y - selected_block.y;
    }
    else {
      const lowestBlock = this.getLowestBlock(blocks);

      if (!!lowestBlock && (lowestBlock.y + lowestBlock.rows) > (selected_block.y + 4)) {
        selected_block.rows = lowestBlock.rows - (selected_block.y - lowestBlock.y);
      }
      else {
        selected_block.rows = this.new_block_default_height;
      }
    }
  }

  _filterEmptyDashBlocks() {
    const blocks = [];
    for (const block of this.blocks) {
      if (!!block.block_type) {
        blocks.push(block);
      }
    }
    return blocks;
  }

  _clearEmptyBlocks() {
    for (let i = this.blocks.length - 1; i >= 0; i--) {
      if (this.blocks[i].block_type === null) {
        this.blocks.splice(i, 1);
      }
    }
  }

  _fillEmptySpaces() {
    this._clearEmptyBlocks();

    if (this.editing_enabled && !this.allBlocksActive()) {
      const maxX = this.grid_options.columns - 1;
      let maxY = 0;

      const lowest_block = this.getLowestBlock();

      if (!!lowest_block) {
        const lowest_block_bottom = lowest_block.y + (lowest_block.rows - 1);
        maxY = lowest_block_bottom + (!!lowest_block.block_type ? 2 : 0);
      }

      if (!this.empty_block_only_at_bottom_of_grid && !this.is_mobile) {
        for (let y = 0; y < maxY; y++) {
          for (let x = 0; x < maxX; x++) {
            if (!this._twoByTwoSquareOverlapsBlock(x, y)) {
              this.addEmptyBlock(x, y);
            }
          }
        }
      }
      else {
        for (let x = 0; x < maxX; x++) {
          if (!this._twoByTwoSquareOverlapsBlock(x, maxY)) {
            this.addEmptyBlock(x, maxY);
          }
        }
      }

      if (this.blocks.length === 0) {
        this.addEmptyBlock(0, 0);
      }
    }
  }

  getLowestBlock(
    blocks: DashGridBlock[] = this.blocks,
    block_type: string = null
  ): DashGridBlock {
    let lowest_block = null;
    let lowest_block_bottom = null;

    for (const block of blocks) {
      const block_bottom = block.y + block.rows - 1;

      if (!lowest_block || block_bottom > lowest_block_bottom) {
        lowest_block = block;
        lowest_block_bottom = block_bottom;
      }
    }

    return lowest_block;
  }

  getLowestEmptyBlock() {
    return this.getLowestBlock(this.blocks, null);
  }

  private _tryRevertOtherBlocksToSnapshotLocations(selected_block: DashGridBlock) {
    const blocks = this._filterEmptyDashBlocks();
    const blocks_with_overlap = [];

    for (const block of blocks) {
      if (block.block_id !== selected_block.block_id) {
        const block_snapshot = this.grid_snapshot_map[block.block_id];

        // If no overlap, revert block back to original position
        if (!this._areaOverlapsBlock(
          block_snapshot.x,
          block_snapshot.y,
          block_snapshot.cols,
          block_snapshot.rows,
          block_snapshot.block_id
        )) {
          block.x = block_snapshot.x;
          block.y = block_snapshot.y;
        }
        else {
          blocks_with_overlap.push(block);
        }
      }
    }

    this._sortBlocksFromTopLeftToBottomRight(blocks_with_overlap);

    // For blocks that've been pushed down and weren't able to be
    // reverted to their original position, instead check if there's
    // now any free space above them and if so, float them upwards to
    // fill any empty space
    for (const block of blocks_with_overlap) {
      const closest_above = this._getClosestAboveBlock(block, blocks);
      if (!closest_above) {
        block.y = 0;
      }
      else {
        block.y = closest_above.y + (closest_above.cols);
      }
    }
  }

  private _getGridSnapshotMap() {
    const snapshot = _.cloneDeep(this._filterEmptyDashBlocks());
    const snapshot_map = {};

    for (const block of snapshot) {
      snapshot_map[block.block_id] = block;
    }
    return snapshot_map;
  }

  private _twoByTwoSquareOverlapsBlock(x: number, y: number) {
    return this._areaOverlapsBlock(x, y, 2, 2, null);
  }

  private _areaOverlapsBlock(
    x: number, y: number, cols: number, rows: number, block_id: string = null
  ): boolean {
    for (const block of this.blocks) {
      if (!block_id || block_id !== block.block_id) {

        const squareLeftOfBlock = (x + (cols - 1)) < block.x;
        const squareRightOfBlock = x > (block.x + (block.cols - 1));
        const squareAboveBlock = (y + (rows - 1)) < block.y;
        const squareBelowBlock = y > (block.y + (block.rows - 1));

        if (!(squareLeftOfBlock || squareRightOfBlock || squareAboveBlock || squareBelowBlock)) {
          return true;
        }
      }
    }
    return false;
  }

  private _getAllBlocksExcludingThisOne(selected_block: DashGridBlock) {
    const blocks = _.cloneDeep(this.blocks);
    for (let i = 0; i < blocks.length; i++) {

      if (blocks[i].block_id === selected_block.block_id) {
        blocks.splice(i);
        break;
      }
    }
    return blocks;
  }

  private _getClosestAboveBlock(selected_block: DashGridBlock, blocks: DashGridBlock[]) {
    let closest = null;

    for (const block of blocks) {
      if (this._isAbove(selected_block, block)) {
        if (!closest || (block.y + block.rows) > (closest.y + closest.rows)) {
          closest = block;
        }
      }
    }

    return closest;
  }

  _getClosestBelowBlock(
    selected_block: DashGridBlock,
    blocks: DashGridBlock[]
  ) {
    let closest = null;

    for (const block of blocks) {
      // Exclude empty blocks
      if (!!block.block_type && this._isBelow(selected_block, block)) {
        if (!closest || block.y < closest.y) {
          closest = block;
        }
      }
    }

    return closest;
  }

  private _getClosestLeftBlock(selected_block: DashGridBlock, blocks: DashGridBlock[]) {
    let closest = null;

    for (const block of blocks) {
      if (this._isLeft(selected_block, block)) {
        if (!closest || (block.x + block.cols) > (closest.x + closest.cols)) {
          closest = block;
        }
      }
    }

    return closest;
  }

  private _getClosestRightBlock(selected_block: DashGridBlock, blocks: DashGridBlock[]) {
    let closest = null;

    for (const block of blocks) {
      if (this._isRight(selected_block, block)) {
        if (!closest || block.x < closest.x) {
          closest = block;
        }
      }
    }

    return closest;
  }

  private _isAbove(selected_block: DashGridBlock, block: DashGridBlock) {
    return block.y + block.rows <= selected_block.y &&
      (block.x + block.cols) > selected_block.x &&
      block.x < (selected_block.x + selected_block.cols);
  }

  private _isBelow(selected_block: DashGridBlock, block: DashGridBlock) {
    return block.y >= selected_block.y + selected_block.rows &&
      (block.x + block.cols) > selected_block.x &&
      block.x < (selected_block.x + selected_block.cols);
  }

  private _isLeft(selected_block: DashGridBlock, block: DashGridBlock) {
    return block.x + block.cols <= selected_block.x &&
      (block.y + block.rows) > selected_block.y &&
      block.y < (selected_block.y + selected_block.rows);
  }

  private _isRight(selected_block: DashGridBlock, block: DashGridBlock) {
    return block.x >= (selected_block.x + selected_block.cols) &&
      (block.y + block.rows) > selected_block.y &&
      block.y < (selected_block.y + selected_block.rows);
  }

  private _sortBlocksFromTopLeftToBottomRight(blocks: DashGridBlock[]) {
    return blocks.sort((a, b) => {
      if (a.y !== b.y) {
        return a.y < b.y ? -1 : 1;
      }
      else if (a.x !== b.x) {
        return a.x < b.x ? -1 : 1;
      }
      return 0;
    });
  }

  private _initGridOptions() {
    this.grid_options = {
      gridType: GridType.VerticalFixed,
      setGridSize: true,
      minCols: this.columns,
      maxCols: this.columns,
      columns: this.columns,
      minRows: 10,
      pushing: this.editing_enabled,
      swapping: this.editing_enabled,
      disableScrollHorizontal: true,
      mobileModeEnabled: false,
      mobileBreakpoint: 850,
      width: 'auto',
      colWidth: 'auto',
      fixedRowHeight: 70,
      keepFixedHeightInMobile: true,
      minItemRows: 1,
      minItemCols: 2,
      margin: 20,
      outerMargin: false,
      resizable: {
        enabled: this.editing_enabled,
        start: (block) => this.gridOptionsResizeStart(block as DashGridBlock),
        stop: (block) => this.gridOptionsResizeStop(block as DashGridBlock)
      },
      draggable: {
        ignoreContent: true,
        dragHandleClass: '-dragHandle',
        ignoreContentClass: '-dragHandleIgnore',
        enabled: this.editing_enabled,
        start: (block) => this.gridOptionsDragStart(block as DashGridBlock),
        stop: (block) => this.gridOptionsDragStop(block as DashGridBlock)
      }
    };
  }

  gridOptionsResizeStart(block: DashGridBlock) {
    this.backup_block = _.cloneDeep(block as DashGridBlock);
    this.grid_snapshot_map = this._getGridSnapshotMap();

    this._clearEmptyBlocks();
  }

  gridOptionsResizeStop(block: DashGridBlock) {
    this._tryRevertOtherBlocksToSnapshotLocations(block);
    this.backup_block = null;

    setTimeout(() => {
      this._fillEmptySpaces();

      if (this._blockHasResized(block)) {
        const block_component = this._getBlockComponentForBlock(block);
        block_component?.blockResized();
      }

      if (this._blockConfigHasChanged()) {
        this.saveConfig();
      }
    });
  }

  gridOptionsDragStart(block: DashGridBlock) {
    this.backup_block = _.cloneDeep(block as DashGridBlock);
    this.grid_snapshot_map = this._getGridSnapshotMap();

    this._clearEmptyBlocks();
  }

  gridOptionsDragStop(block: DashGridBlock) {
    this._tryRevertOtherBlocksToSnapshotLocations(block as DashGridBlock);
    this.backup_block = null;

    setTimeout(() => {
      this._fillEmptySpaces();

      if (this._blockConfigHasChanged()) {
        this.saveConfig();
      }
    });
  }

  private _getBlockComponentForBlock(block: DashGridBlock): DashGridBlockComponent {
    for (const block_component of this.block_components) {
      if (block.block_id === block_component.block.block_id) {
        return block_component;
      }
    }
    return null;
  }

  private _blockConfigHasChanged(): boolean {
    for (const block of this.blocks) {
      if (
        this._blockHasResized(block) ||
        this._blockHasMoved(block)
      ) {
        return true;
      }
    }
    return false;
  }

  private _blockHasResized(block: DashGridBlock): boolean {
    for (const block_id of Object.keys(this.grid_snapshot_map)) {
      const b = this.grid_snapshot_map[block_id];

      if (block.block_id === b.block_id) {
        return block.cols !== b.cols ||
          block.rows !== b.rows;
      }
    }
  }

  private _blockHasMoved(block: DashGridBlock): boolean {
    for (const block_id of Object.keys(this.grid_snapshot_map)) {
      const b = this.grid_snapshot_map[block_id];

      if (block.block_id === b.block_id) {
        return block.x !== b.x ||
          block.y !== b.y;
      }
    }
  }

  private _updateNonEmptyBlockCount() {
    let non_empty_block_count = 0;
    for (const block of this.blocks) {
      if (!!block.block_type) {
        non_empty_block_count++;
      }
    }
    this.non_empty_block_count = non_empty_block_count;
  }

}
