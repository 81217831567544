export type InvCompanySetting = {
  company_key: number,
  company_settings_key: number,
  setting_key: string,
  setting_value: string | string | boolean
}

export class InvCompany {

  active_flag: boolean;
  bank_account: string;
  company_address: string;
  company_business_number: string;
  company_domain: string;
  company_key: number;
  company_logo: string;
  company_name: string;
  company_settings: InvCompanySetting[];
  company_tax_number: string;
  contact_email: string;
  contact_name: string;
  country_key: string;
  created_on: Date;
  currency_code: string;
  in_tutorial: boolean;
  money_symbol: string;
  sub_company_product_key: number;
  subscription_product: ('PROJECTS' | 'PLACEMENTS')

  constructor(
    active_flag: boolean,
    bank_account: string,
    company_address: string,
    company_business_number: string,
    company_domain: string,
    company_key: number,
    company_logo: string,
    company_name: string,
    company_settings: InvCompanySetting[],
    company_tax_number: string,
    contact_email: string,
    contact_name: string,
    country_key: string,
    created_on: Date,
    currency_code: string,
    in_tutorial: boolean,
    money_symbol: string,
    sub_company_product_key: number,
    subscription_product: ('PROJECTS' | 'PLACEMENTS')
  ) {
    this.active_flag = active_flag;
    this.bank_account = bank_account;
    this.company_address = company_address;
    this.company_business_number = company_business_number;
    this.company_domain = company_domain;
    this.company_key = company_key;
    this.company_logo = company_logo;
    this.company_name = company_name;
    this.company_settings = company_settings;
    this.company_tax_number = company_tax_number;
    this.contact_email = contact_email;
    this.contact_name = contact_name;
    this.country_key = country_key;
    this.created_on = created_on;
    this.currency_code = currency_code;
    this.in_tutorial = in_tutorial;
    this.money_symbol = money_symbol;
    this.sub_company_product_key = sub_company_product_key;
    this.subscription_product = subscription_product;
  }

}
