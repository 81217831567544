import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CoreUtilService } from '../../../public-api';
import { ProductValue } from '../../lib.types';

@Component({
  selector: 'product-icon',
  templateUrl: './product-icon.component.html',
  styleUrls: ['./product-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductIconComponent implements OnChanges {

  @HostBinding('class.-grayscale') @Input() grayscale: boolean = false;

  @Input() product: ProductValue = null;
  @Input() tooltip_content: string = null;

  icon_url: string = null;

  constructor(
    @Inject('env') public env: any
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.product || !!changes.icon_size) {
      this._updateIconUrl();
    }
  }

  private _updateIconUrl() {
    if (!this.product) {
      this.product = this.env.product;
    }

    const product_name = CoreUtilService.getProductName(this.product);
    const file_location = 'assets/product-imgs/' + this.product.toLowerCase() + '/';

    this.icon_url = file_location + product_name + '_Icon.svg';
  }

}
