import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { DomService } from '../../lib-services/dom/dom.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { StateDataService } from '../../lib-services/state-data/state-data.service';
import { BackdropComponent } from '../backdrop/backdrop.component';

@Component({
  selector: 'month-selector',
  templateUrl: './month-selector.component.html',
  styleUrls: ['./month-selector.component.scss']
})
export class MonthSelectorComponent implements OnInit, OnDestroy {

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  @Input() disabled: boolean = false;

  @Output() month_changed = new EventEmitter();


  private _selected_month: Date;
  get selected_month(): Date {
    return this._selected_month;
  }
  set selected_month(monthStart: Date) {
    this._selected_month = monthStart;

    this.stateDataService.selected_month_start = this._selected_month;
    setTimeout(() => this.month_changed.emit());
  }

  backdrop_ref: BackdropComponent = null;
  is_open: boolean = false;

  is_mobile: boolean = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.is_mobile = DomService.is_mobile;
  }

  constructor(
    public stateDataService: StateDataService,
    private domService: DomService
  ) { }

  ngOnInit(): void {
    this._selected_month = this.stateDataService.selected_month_start;
  }

  ngOnDestroy() {
    this.backdrop_ref?.close();
  }

  dropdownToggled(is_open: boolean) {
    this.is_open = is_open;
    if (this.is_open) {
      this.backdrop_ref = this.domService.openBackdrop(null, { force_dark_backdrop: true });
    }
    else {
      this.backdrop_ref?.close();
    }
  }

  closeDropdown() {
    this.dropdown?.close();
    this.backdrop_ref?.close();
  }

  monthSelected() {
    this.selected_month = this.stateDataService.selected_month_start;
    this.closeDropdown();
  }

  forwardMonth() {
    this.selected_month = TimeUtilService.incrementMonth(this.selected_month, 1);
  }

  backMonth() {
    this.selected_month = TimeUtilService.incrementMonth(this.selected_month, -1);
  }

}
