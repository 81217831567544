import { Component, Input } from '@angular/core';

import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';
import { Duration } from 'luxon';

@Component({
  selector: 'duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss'],
  providers: [
    MakeProvider(DurationInputComponent)
  ]
})
export class DurationInputComponent extends AbstractValueAccessor {

  readonly timeRegex: RegExp = /((((^[01]|^)\d)|(2[0-3])):([0-5]\d))|(24:00)/;
  readonly decimalRegex: RegExp = /^([0-9]+|)([,.][0-9]+)?$/;



  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() disable_time_parsing: boolean = false; //used for inputs when just float parsing is desired
  @Input() bold_non_zeros: boolean = false;
  @Input() tooltip_content: string = null;
  @Input() input_prefix: string | number = null;
  @Input() input_suffix: string | number = null;

  _value: number = null;
  _previous_value: number = null;

  writeValue(value: any) {
    // Initialise previous value
    if (this._previous_value === null && !isNaN(value)) {
      this._previous_value = value;
    }
    super.writeValue(value);
  }

  parseModelFromView(view_value: any): void {
    let model_value = null;



    // attempt time parsing if not disabled
    if (this.timeRegex.test(view_value) && !this.disable_time_parsing) {
      model_value = TimeUtilService.hoursMinsStringToHoursDecimal(view_value);
    }
    //converts value to float
    else if (this.decimalRegex.test(view_value)) {
      model_value = parseFloat(view_value);
    }


    if (!!model_value && model_value > 24 && !this.disable_time_parsing) {
      if (model_value > 1439) {
        this._revertToPreviousValue();
        return;
      }
      else {
        model_value = Duration.fromObject({ minutes: model_value }).as('hours');
      }
    }


    if (model_value !== null && !isNaN(model_value)) {
      this.value = parseFloat(model_value);
      this._previous_value = this.value;
    }
    else {
      this._revertToPreviousValue();
    }
  }

  private _revertToPreviousValue(){
    // Ensures input view is re-rendered
    this._value = null;
    setTimeout(() => this.value = this._previous_value);
  }

}
