import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'dateToTimePipe'
})
export class DateToTimePipe implements PipeTransform {

  transform(
    value: Date,
    format: string = null
  ): string {
    //const default_format = 'h' + (value?.getMinutes() === 0 ? '' : ':mm') + 'a';
    const default_format = 'h:mma';
    return DateTime.fromJSDate(value).toFormat(!!format ? format : default_format);
  }

}
