<div class="uacd-container"
     *ngIf="!is_mobile"
     ngbDropdown
     #ngb_dropdown="ngbDropdown"
     [container]="'body'"
     [placement]="'bottom-right'"
     [autoClose]="'outside'"
     (openChange)="dropdownToggled($event)">

  <user-icon ngbDropdownAnchor
             [display_name]="display_name"
             [display_image]="display_image"
             (click)="dropdownToggleClicked()">
  </user-icon>

  <div ngbDropdownMenu>
    <ng-container *ngTemplateOutlet="uacd_content"></ng-container>
  </div>
</div>

<div class="uacd-container"
     *ngIf="is_mobile"
     (click)="dropdownToggleClicked()">
  <user-icon [display_name]="display_name"
             [display_image]="display_image">
  </user-icon>

  <div class="uacd-unseenCount"
       *ngIf="unseen_count !== 0 && product !== 'DROPPAH'">
  </div>
</div>

<ng-template #uacd_content>
  <div class="uacd-list">
    <ng-container *ngFor="let item of uac_dropdown_items">
      <li class="uacd-item"
          *ngIf="item !== null"
          (click)="dropdownItemSelected(item)">
        <i class="ion uacd-item-icon"
           [ngClass]="item.icon_class">
        </i>
        <div class="uacd-item-label">
          {{item.label}}
          <i *ngIf="!!item.opens_new_window"
             class="bi bi-box-arrow-up-right"></i>
        </div>
      </li>

      <div class="uacd-itemDivider"
           *ngIf="item === null">
      </div>
    </ng-container>
  </div>

</ng-template>
