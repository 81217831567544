import { DpTeam } from '../dp-team/dp-team';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { DpPerson } from '../dp-person/dp-person';
import { DpSkill } from '../dp-skill/dp-skill';
import { DpShift } from '../dp-shift/dp-shift';
import { PostableObject, PostableObjectErrorMap } from './../../lib-interfaces/postable-object.interface';

import * as _ from 'lodash-es';


type SegmentStatus = ('default' | 'incoming' | 'pending' |'no-action' | 'approved-request' | 'declined-request')

export class DpRosterSegment implements PostableObject<DpRosterSegment> {

  segment_cost: number;
  roster_segment_key: number;
  additional_data: any;
  //rules_broken: Rule[] = [];
  segment_times_adjusted: boolean;
  segment_changed_since_published: boolean;
  deleted_flag: boolean = false;
  selectedTeamKey: number = null;

  segment_status: SegmentStatus = 'default';

  private _shift_date: Date;
  private _start_time: Date;
  private _end_time: Date;
  private _duration: number;
  private _break_duration: number;
  private _shift: DpShift;

  skill: DpSkill = null;
  person: DpPerson = null;
  team: DpTeam = null;

  constructor(
    shift_date: Date,
    shift: DpShift,
    skill: DpSkill = null,
    person: DpPerson = null,
    team: DpTeam = null,
    segment_cost: number = null,
    start_time: Date = null,
    end_time: Date = null,
    break_duration: number = 0,
    roster_segment_key: number = null,
    additional_data: any = null,
    published_segment: any = null,
    segment_status: SegmentStatus = 'default',
    selectedTeamKey: number = null
  ) {
    this._shift = shift;
    this.skill = skill;
    this.person = person;
    this.team = team;
    this.segment_cost = segment_cost;
    this.segment_status = segment_status;

    this.roster_segment_key = roster_segment_key;
    this.additional_data = additional_data;

    this._shift_date = TimeUtilService.getCleanDate(shift_date);
    this._break_duration = break_duration;

    this._initStartAndEndTime(start_time, end_time);
    this._updateTimesOnDateChange();
    this._updateDurationOnTimeChange();
    //this._updateSegmentTimesAdjusted();
    //this._updateSegmentChangedSincePublished();
  }

  get shift_date(): Date {
    return this._shift_date;
  }
  set shift_date(shift_date: Date) {
    if (TimeUtilService.dateIsValid(shift_date)) {
      TimeUtilService.cleanDate(shift_date);

      this._shift_date = shift_date;

      this._updateTimesOnDateChange();
    }
  }

  get start_time(): Date {
    return this._start_time;
  }
  set start_time(start_time: Date) {
    if (TimeUtilService.dateIsValid(start_time)) {
      TimeUtilService.cleanTime(start_time);

      this._start_time = TimeUtilService.updateDatePortionOfDateTime(start_time, this._shift_date);
      this._end_time = TimeUtilService.updateDatePortionOfDateTime(this._end_time, this._start_time);
      this._checkEndTimeValidIfMultiDaySegment();

      this._updateDurationOnTimeChange();
      //this._updateSegmentTimesAdjusted();
      //this._updateSegmentChangedSincePublished();
    }
  }

  get end_time(): Date {
    return this._end_time;
  }
  set end_time(end_time: Date) {
    if (TimeUtilService.dateIsValid(end_time)) {
      TimeUtilService.cleanTime(end_time);

      this._end_time = TimeUtilService.updateDatePortionOfDateTime(end_time, this._shift_date);
      this._checkEndTimeValidIfMultiDaySegment();

      this._updateDurationOnTimeChange();
      //this._updateSegmentTimesAdjusted();
      //this._updateSegmentChangedSincePublished();
    }
  }

  get duration(): number {
    return this._duration;
  }
  set duration(duration: number) {
    if (TimeUtilService.numberIsValid(this._duration)) {
      if (duration > 24) {
        duration = 24;
      }
      else if (duration < 0) {
        duration = 0;
      }

      this._duration = duration;

      if (this._start_time && this._end_time) {
        // Update end time to match new duration values
        const totalHoursMins = TimeUtilService.hoursDecimalAsHoursAndMinutes(this._duration + this._break_duration);
        const totalHours = totalHoursMins[0];
        const totalMins = totalHoursMins[1];

        let newEnd = _.cloneDeep(this._start_time);
        newEnd = TimeUtilService.incrementHours(newEnd, totalHours);
        newEnd = TimeUtilService.incrementMinutes(newEnd, totalMins);

        this._end_time = newEnd;
      }
    }
  }

  get break_duration(): number {
    return this._break_duration;
  }
  set break_duration(break_duration: number) {
    if (TimeUtilService.numberIsValid(break_duration)) {
      const totalDurationDec = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time);
      // Ensure break duration isn't greater than the total segment duration
      if (break_duration > totalDurationDec) {
        break_duration = totalDurationDec;
      }

      this._break_duration = break_duration;
      this._duration = totalDurationDec - this._break_duration;
    }
  }

  get shift(): DpShift {
    return this._shift;
  }
  set shift(shift: DpShift) {
    this._shift = shift;
    this._start_time.setHours(
      this._shift.start_time.getHours(), this._shift.start_time.getMinutes()
    );
    this._end_time.setHours(
      this._shift.end_time.getHours(), this._shift.end_time.getMinutes()
    );
    //this._updateSegmentTimesAdjusted();
  }

  /*
  addRule(rule: Rule) {
    if (!this.rules_broken.includes(rule)) {
      this.rules_broken.push(rule);
    }
  }*/


  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};
    /*
    if (!TimeUtilService.dateIsValid(this.start_time)) {
      errors.push({
        error_path: 'start_time',
        error_message: 'Start time required'
      });
    }
    if (!TimeUtilService.dateIsValid(this.end_time)) {
      errors.push({
        error_path: 'end_time',
        error_message: 'End time required'
      });
    }*/

    return errors;
  }

  formatForPosting(): any {
    if (!this.hasErrors()) {
      return {
        roster_segment_key: this.roster_segment_key,
        shift_key: this.shift?.shift_key || null,
        person_key: this.person?.person_key || null,
        skill_key: this.skill?.skill_key || null,
        team_key: this.team?.team_key || null,
        shift_date: TimeUtilService.formatDateForPosting(this.shift_date),
        start_time: TimeUtilService.formatDateForPosting(this.start_time, true),
        end_time: TimeUtilService.formatDateForPosting(this.end_time, true),
        default_break_duration: this.break_duration,
        additional_data: this.additional_data,
        deleted_flag: this.deleted_flag
      };
    }
    return null;
  }

  /*
  private _updateSegmentTimesAdjusted() {
    this.segment_times_adjusted =
      !TimeUtilService.timesAreEqual(this._start_time, this._shift.start_time) ||
      !TimeUtilService.timesAreEqual(this._end_time, this._shift.end_time);
  }
  private _updateSegmentChangedSincePublished() {
    if (this.published_segment) {
      this.segment_changed_since_published =
        this.shift.shift_key !== this.published_segment.shift.shift_key ||
        this.skill.skill_key !== this.published_segment.skill.skill_key ||
        this.team.team_key !== this.published_segment.team.team_key ||
        this.person.person_key !== this.published_segment.person.person_key ||
        !moment(this.start_time).isSame(moment(this.published_segment.start_time), 'minutes') ||
        !moment(this.end_time).isSame(moment(this.published_segment.end_time), 'minutes') ||
        this.duration !== this.published_segment.duration ||
        this.break_duration !== this.published_segment.break_duration;
    }
    else {
      this.segment_changed_since_published = false;
    }
  }*/
  private _updateDurationOnTimeChange() {
    const totalDurationDec = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time);

    // If new total duration is less than break duration,
    // we need to reset break duration
    if (totalDurationDec < this._break_duration) {
      this._break_duration = 0;
    }

    this._duration = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time) - (this._break_duration);
  }
  private _updateTimesOnDateChange() {
    this._start_time = TimeUtilService.updateDatePortionOfDateTime(this._start_time, this._shift_date);
    this._end_time = TimeUtilService.updateDatePortionOfDateTime(this._end_time, this._shift_date);
    this._checkEndTimeValidIfMultiDaySegment();
  }
  private _initStartAndEndTime(
    start_time: Date = null,
    end_time: Date = null
  ): void {
    this._start_time = start_time || TimeUtilService.updateDatePortionOfDateTime(this.shift.start_time, this.shift_date, true);
    this._end_time = end_time || TimeUtilService.updateDatePortionOfDateTime(this.shift.end_time, this.shift_date, true);
    this._checkEndTimeValidIfMultiDaySegment();

    TimeUtilService.cleanTime(this._start_time);
    TimeUtilService.cleanTime(this._end_time);
  }

  private _checkEndTimeValidIfMultiDaySegment() {
    if (TimeUtilService.endTimeLessThanStartTime(this._start_time, this._end_time)) {
      const next_day = TimeUtilService.incrementDate(this._shift_date, 1);
      this._end_time = TimeUtilService.updateDatePortionOfDateTime(this._end_time, next_day);
    }
  }

}
