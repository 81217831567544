import { InvProjectStub } from '../inv-project-stub/inv-project-stub';
import { ClientPaymentTermRule } from '../../lib.types';
import { PostableObject } from '../../lib-interfaces/postable-object.interface';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

export type InvClientContact = {
  user_key: number,
  first_name: string,
  last_name: string,
  display_name: string,
  email_address: string,
  phone_mobile: string,
  phone_office: string
}

export class InvClient implements PostableObject<InvClient> {

  contacts: InvClientContact[];
  projects: InvProjectStub[];
  integrations: any[];

  invoice_template_key: number;
  approval_message_template_key: number;
  onboard_template_key: number;
  payment_term_days: number;
  payment_term_rule: ClientPaymentTermRule;
  client_key: number;
  address: string;
  billing_email: string;
  billing_name: string;
  client_billing_name: string;
  client_name: string;
  company_number: string;
  company_business_number: string;
  contact_number: string;
  contact_phone: string;

  constructor(
    invoice_template_key: number = null,
    approval_message_template_key: number = null,
    onboard_template_key: number = null,
    payment_term_days: number = null,
    payment_term_rule: ClientPaymentTermRule = 'Invoice Due Date',
    contacts: InvClientContact[] = [],
    projects: InvProjectStub[] = [],
    integrations: any[] = [],
    client_key: number = null,
    address: string = '',
    billing_email: string = '',
    billing_name: string = '',
    client_billing_name: string = '',
    client_name: string = '',
    company_number: string = '',
    company_business_number: string = '',
    contact_number: string = '',
    contact_phone: string = ''
  ) {
    this.invoice_template_key = invoice_template_key;
    this.approval_message_template_key = approval_message_template_key;
    this.onboard_template_key = onboard_template_key;
    this.payment_term_days = payment_term_days;
    this.payment_term_rule = payment_term_rule;
    this.contacts = contacts;
    this.projects = projects;
    this.integrations = integrations;
    this.client_key = client_key;
    this.address = address;
    this.billing_email = billing_email;
    this.billing_name = billing_name;
    this.client_billing_name = client_billing_name;
    this.client_name = client_name;
    this.company_number = company_number;
    this.company_business_number = company_business_number;
    this.contact_number = contact_number;
    this.contact_phone = contact_phone;
  }

  getErrors(): Record<string, string> {
    const errors = {};

    if (!this.client_name) {
      errors['client_name'] = 'Client name required';
    }
    
    if (this.payment_term_rule !== 'Invoice Due Date') {
      if (this.payment_term_rule === 'Day of current month' || this.payment_term_rule === 'Day of next month') {
        if (
          !CoreUtilService.numberIsValid(this.payment_term_days) ||
          this.payment_term_days < 1 ||
          this.payment_term_days > 31
        ) {
          errors['payment_term_days'] = 'Valid day of month required (1 - 31)';
        }
      }
      else {
        if (!CoreUtilService.numberIsValid(this.payment_term_days)) {
          errors['payment_term_days'] = 'Number of days required';
        }
      }
    }

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  formatForPosting(to_delete: boolean = false): any {
    if (!this.getErrors().has_errors) {

      if (this.payment_term_rule === 'Invoice Due Date') {
        this.payment_term_rule = null;
        this.payment_term_days = null;
      }

      return {
        client_key: this.client_key,
        client_name: this.client_name,
        client_billing_name: this.client_billing_name,
        address: this.address,
        deleted_flag: to_delete,
        invoice_template_key: this.invoice_template_key,
        onboard_template_key: this.onboard_template_key,
        approval_message_template_key: this.approval_message_template_key,
        billing_email: this.billing_email,
        billing_name: this.billing_name,
        payment_term_days: this.payment_term_days,
        payment_term_rule: this.payment_term_rule,
        contact_phone: this.contact_phone,
        company_number: this.company_number,
        company_business_number: this.company_business_number
      };
    }
    return null;
  }

}
