<ng-container *ngFor="let clock_group of clock_groups">
  <div class="acl-projectTaskBorder"
       *ngIf="!clock_group.active_clock">
  </div>

  <div class="acl-projectTask"
       [ngClass]="{ '-onHold': !clock_group.active_clock }">

    <project-task-clock [project_task]="clock_group.project_task"
                        [group_clock_key]="clock_group.group_clock_key"
                        [active_clock_editing_enabled]="active_clock_editing_enabled"
                        [active_clock_stop_enabled]="active_clock_stop_enabled"
                        [active_clock_delete_enabled]="active_clock_delete_enabled"
                        [clockOutOverrideFunction]="clockOutOverrideFunction"
                        [deleteActiveClockOverrideFunction]="deleteActiveClockOverrideFunction"
                        (clock_modal_opened)="clock_modal_opened.emit()">
    </project-task-clock>

    <div class="app-formItem acl-projectTask-details"
         *ngIf="!!clock_group.project_task"
         [hidden]="clock_group.active_clock && active_clock_hide_details">
      <div class="app-formItem-value">
        <span>{{clock_group.project_task?.project_task_name}}</span>
      </div>
      <div class="app-formItem-label">
        <item-bullet [bullet_colour]="clock_group.project_task?.project.project_colour"></item-bullet>
        <span>{{clock_group.project_task?.project.project_name}}</span>
      </div>

    </div>

  </div>
</ng-container>
