import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';

@Component({
  selector: 'attachment-popover',
  templateUrl: './attachment-popover.component.html',
  styleUrls: ['./attachment-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentPopoverComponent implements OnChanges {

  @Input() timesheet_attachments: any[] = [];
  protected _timesheet_attachments: any[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.timesheet_attachments) {
      this._timesheet_attachments = SortUtilService.sortList(
        this.timesheet_attachments || [],
        { primary_sort_property: 'timesheet_attachment_name' }
      );
    }
  }

}
