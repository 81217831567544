import { Component, OnInit, Output, EventEmitter, ViewChildren, QueryList, Input } from '@angular/core';

import _ from 'lodash-es';

import { InfiniteParams, ReportMeasurePermission, ReportTableColumn } from '../../lib.types';
import { TableReport } from '../../lib-models/report/table-report/table-report';
import { ReportTableColumnComponent } from './report-table-column/report-table-column.component';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';
import { ReportService } from '../../lib-services/report/report.service';

type RowSortConfig = {
  column_key: string,
  reverse: boolean
};

@Component({
  selector: 'report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit {

  @ViewChildren(ReportTableColumnComponent) column_components: QueryList<ReportTableColumnComponent> = null;

  readonly day_sorter = {
    MONDAY: 1, TUESDAY: 2, WEDNESDAY: 3,
    THURSDAY: 4, FRIDAY: 5, SATURDAY: 6, SUNDAY: 7
  };

  readonly month_sorter = {
    JANUARY: 1, FEBRUARY: 2, MARCH: 3,
    APRIL: 4, MAY: 5, JUNE: 6, JULY: 7, AUGUST: 8,
    SEPTEMBER: 9, OCTOBER: 10, NOVEMBER: 11, DECEMBER: 12
  };

  readonly defaultColWidth = 160;
  readonly defaultNumberColWidth = 120;

  readonly dateFormat = 'dd MMM yyyy';
  readonly timeFormat = 'h:mm a';

  @Input() report: TableReport = null;
  @Input() table_rows: any[] = [];

  row_sort: RowSortConfig = {
    column_key: null,
    reverse: false
  };

  col_list: ReportTableColumn[] = [];

  infinite_params: InfiniteParams = {
    rowsToRender: 100,
    indexOfLastVisibleItem: -1,
    infiniteScrollDisabled: false
  };
  visible_table_rows = [];

  constructor(
    public reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.initialiseTableColumns();
  }

  renderReport() {
    this.initialiseTableColumns();
  }

  reloadVisibleRows() {
    this.visible_table_rows = CoreUtilService.reloadVisibleItems(
      this.infinite_params,
      this.visible_table_rows,
      this.table_rows,
      () => true
    );
  }

  loadMoreVisibleRows() {
    this.visible_table_rows = CoreUtilService.loadMoreVisibleItems(
      this.infinite_params,
      this.visible_table_rows,
      this.table_rows,
      () => true
    );
  }

  initialiseTableColumns() {
    // Generate cols from first table row
    this.table_rows = !this.table_rows ? [] : this.table_rows;
    this.col_list = [];

    if (this.table_rows.length > 0) {
      const cols = [];
      const row = this.table_rows.length > 0 ? this.table_rows[0] : {};

      for (const column_key of Object.keys(row)) {
        cols.push(column_key);
      }

      if (cols.length > 0) {

        for (const column_key of cols) {
          const field_id = this.reportService.parseReportDataRowFieldId(column_key);
          const column_function = this.reportService.parseReportDataRowFieldFunction(column_key);

          const field_config = this.reportService.getConfigFactField(this.report.table_config, field_id);

          const width = CoreUtilService.numberIsValid(row[column_key]) ? this.defaultNumberColWidth : this.defaultColWidth;

          this.col_list.push({
            column_key,
            field_config,
            column_function,
            width,
          });
        }

        this.row_sort = {
          column_key: this.col_list[0].column_key,
          reverse: false
        };
        this.sortRows();
      }
    }

    this.reloadVisibleRows();

    // If any col widths are less than the width of the col header text,
    // extend the col width so that the text isn't cut off
    setTimeout(() => {
      let width_adjusted = false;
      const column_components = this.column_components.toArray();

      for (let i = 0; i < column_components.length; i++) {
        const is_selected_column = column_components[i].column.column_key === this.row_sort.column_key;
        const text_width = column_components[i].header_row_name.nativeElement.offsetWidth + 30;
        const column_min_width = text_width + (is_selected_column ? 50 : 30);

        if (column_min_width > this.col_list[i].width) {
          this.col_list[i].width = column_min_width;
          width_adjusted = true;
        }
      }

      if (width_adjusted) {
        this.reloadVisibleRows();
      }
    });
  }

  sortRows() {
    const column_key = this.row_sort.column_key;
    const column = _.find(this.col_list, { column_key });

    switch (column.field_config.datatype) {
      case 'WEEK':
      case 'YEAR':
      case 'NUMBER':
      case 'BOOLEAN':
      case 'STRING':
      case 'TIME':
      case 'DATE': {
        SortUtilService.sortList(
          this.table_rows,
          {
            primary_sort_property: column_key,
            forward_order: !this.row_sort.reverse
          }
        );
        break;
      }
      case 'MONTH': {
        this.table_rows.sort((a, b) => {
          const month1 = a[column_key].toUpperCase();
          const month2 = b[column_key].toUpperCase();

          if (!this.row_sort.reverse) {
            return (this.month_sorter[month1] - this.month_sorter[month2]);
          }
          else {
            return (this.month_sorter[month2] - this.month_sorter[month1]);
          }
        });
        break;
      }
      case 'WEEKDAY': {
        this.table_rows.sort((a, b) => {
          const day1 = a[column_key].toUpperCase();
          const day2 = b[column_key].toUpperCase();

          if (!this.row_sort.reverse) {
            return (this.day_sorter[day1] - this.day_sorter[day2]);
          }
          else {
            return (this.day_sorter[day2] - this.day_sorter[day1]);
          }
        });
        break;
      }
    }

    this.reloadVisibleRows();
  }

  sortRowsByCol(column_key: string) {
    if (this.row_sort.column_key === column_key) {
      this.row_sort.reverse = !this.row_sort.reverse;
    }
    else {
      this.row_sort = {
        column_key,
        reverse: false
      };
    }
    this.sortRows();
  }

}
