import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';

import { CompanyAction } from '../../lib.types';

declare const Base64: any;

export class DpUac implements PostableObject<DpUac> {

  user_access_company_key: number;
  private _pin: string;
  pin_enabled: boolean;
  sample_user: boolean;
  company_actions: CompanyAction[];
  person_key: number = null;
  external_user_access_key: number;

  owner_flag: boolean = false;
  admin_flag: boolean = false;
  team_manager_flag: boolean = false;
  pay_rates_visible: boolean = false;

  intercom_settings: any;
  profile_image: string;

  constructor(
    user_access_company_key: number,
    person_key: number,
    external_user_access_key: number,
    owner_flag: boolean,
    admin_flag: boolean,
    team_manager_flag: boolean,
    pin: string = '',
    pin_enabled: boolean = false,
    sample_user: boolean,
    pay_rates_visible: boolean = false,
    company_actions: CompanyAction[] = [],
    intercom_settings: any,
    profile_image: string
  ) {
    this.user_access_company_key = user_access_company_key;
    this.sample_user = sample_user;
    this.person_key = person_key;
    this.external_user_access_key = external_user_access_key;

    this.owner_flag = owner_flag;
    this.admin_flag = admin_flag;
    this.team_manager_flag = team_manager_flag;
    this.pay_rates_visible = pay_rates_visible;

    this._pin = pin;
    this.pin_enabled = pin_enabled;
    this.company_actions = company_actions;

    this.intercom_settings = intercom_settings;
    this.profile_image = profile_image;
  }

  get pin(): string {
    return this._pin;
  }
  set pin(pin: string) {
    if (!!pin && pin.length === 4) {
      this._pin = Base64.encode(pin);
    }
    else {
      this._pin = null;
      this.pin_enabled = false;
    }
  }

  pinMatches(pin: string): boolean {
    return this._pin === Base64.encode(pin);
  }
  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    return errors;
  }

  formatForPosting(): any {
    if (!this.hasErrors()) {
      return {
        user_access_company_key: this.user_access_company_key,
        pin: this.pin === null ? '' : this.pin,
        pin_enabled: this.pin_enabled
      };
    }
    return null;
  }
}
