import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'client-icon',
  templateUrl: './client-icon.component.html',
  styleUrls: ['./client-icon.component.scss']
})
export class ClientIconComponent implements OnInit {

  icon_classes: string = null;
  tooltip_content: string = null;

  constructor(
    @Inject('env') public env: any
  ) { }

  ngOnInit(): void {
    switch (this.env.product) {
      case 'KARMLY':
        this.icon_classes = 'bi-person-circle';
        this.tooltip_content = 'Client';
        return;
      case 'INVOXY':
        this.icon_classes = 'bi-person-circle';
        this.tooltip_content = 'Client';
        return;
    }
  }

}
