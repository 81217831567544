import { Subject, Subscription } from 'rxjs';
import _ from 'lodash-es';

import { NavMenuExternalItem, NavMenuItemChild, _NavMenuItem } from '../../../lib.types';
import { StateAccessServiceInterface } from '../../../lib-interfaces/state-access-service.interface';
import { StateDataService, CoreUtilService } from '../../../../public-api';

export type NavMenuServiceEventType = (
  'TOGGLE_NAV_MENU' | 'NAV_MENU_TOGGLED'
);

export abstract class NavMenuServiceAbstract {

  readonly join_karmly_slack_link = 'https://join.slack.com/t/karmly/shared_invite/zt-1d442wn02-HbabeSU6Kb0yfUpoCSe6Fg';

  readonly project_label = CoreUtilService.project_label;
  readonly task_label = CoreUtilService.task_label;
  readonly project_task_label = CoreUtilService.project_task_label;

  abstract readonly non_menu_states: string[];
  abstract readonly nav_menu_config: _NavMenuItem[];
  abstract readonly popup_menu_config: Record<string, NavMenuExternalItem>;
  abstract readonly custom_nav_menu_config_rules: Record<string, () => boolean>;

  readonly events: Record<NavMenuServiceEventType, Subject<any>> = {
    TOGGLE_NAV_MENU: new Subject<void>(),
    NAV_MENU_TOGGLED: new Subject<boolean>()
  };

  constructor(
    public stateAccessService: StateAccessServiceInterface,
    public stateDataService: StateDataService
  ) { }

  abstract generatePopupMenu(): NavMenuExternalItem[];
  abstract generateExternalNavMenu(): NavMenuExternalItem[];

  toggleNavMenu() {
    this.events.TOGGLE_NAV_MENU.next(null);
  }

  menuToggled(menu_closed: boolean) {
    setTimeout(() => this.events.NAV_MENU_TOGGLED.next(menu_closed), 250);
  }

  subscribeToEvent(
    event_type: NavMenuServiceEventType,
    callback: (event_data: any) => void
  ): Subscription {
    if (!!this.events[event_type]) {

      return this.events[event_type]
        .asObservable()
        .subscribe((event_data) => callback(event_data));
    }
  }

  checkNonMenuPath(active_path: string): boolean {
    return this.non_menu_states.find((state) => state === active_path) !== undefined;
  }

  generateNavMenu(): _NavMenuItem[] {
    let nav_menu = _.cloneDeep(this.nav_menu_config);

    for (const item of nav_menu) {
      if (!!item) {
        item.children = _.filter(item.children, (item_child: NavMenuItemChild) => {
          return this.stateAccessService.canAccessRoute([item.path, item_child.path]) &&
            this._routePathMatchesCustomNavMenuConfigRules(item.path + '/' + item_child.path);
        });
      }
    }

    nav_menu = _.filter(nav_menu, (item: _NavMenuItem) => {
      return !item || (!!item.children.length && this.stateAccessService.canAccessRoute([item.path]));
    });

    return nav_menu;
  }

  getDefaultChildPathForMenuItem(item_path: string): string {
    const menu_item: _NavMenuItem = _.find(this.nav_menu_config, { path: item_path });

    if (!!menu_item && this.stateAccessService.canAccessRoute([item_path])) {
      const stickied_child_path = this.stateDataService.getStickiedMenuPath(item_path);

      if (
        !!stickied_child_path &&
        this.stateAccessService.canAccessRoute([item_path, stickied_child_path]) &&
        this._routePathMatchesCustomNavMenuConfigRules(item_path + '/' + stickied_child_path)
      ) {
        return stickied_child_path;
      }

      for (const child of menu_item.children) {
        if (
          this.stateAccessService.canAccessRoute([item_path, child.path]) &&
          this._routePathMatchesCustomNavMenuConfigRules(item_path + '/' + child.path)
        ) {
          return child.path;
        }
      }
    }
    return null;
  }

  private _routePathMatchesCustomNavMenuConfigRules(state: string): boolean {
    if (!this.custom_nav_menu_config_rules[state]) {
      return true;
    }
    return this.custom_nav_menu_config_rules[state]();
  }

  openJoinKarmlySlackLink() {
    window.open(this.join_karmly_slack_link);
  }

}
