import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';

import { LibPipesModule, LibComponentsModule } from 'flexitime-library';

import { LoginExternalComponent } from './login-external/login-external.component';

const module_routes: Route[] = [
  {
    path: 'loginExternal',
    component: LoginExternalComponent
  }
];

@NgModule({
  declarations: [
    LoginExternalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LibPipesModule,
    LibComponentsModule,
    RouterModule.forChild(module_routes)
  ]
})
export class LoginModule { }
