import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash-es';
import * as FileSaver from 'file-saver';

import { AppProduct, DropdownItem, Label, ReportType } from '../../lib.types';
import { Report } from '../../lib-models/report/report';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { GuardedUnsavedChangesComponent, UnsavedChangesData } from '../../lib-guards/unsaved-changes/unsaved-changes.guard';
import { LibModalService } from '../../lib-services/lib-modal/lib-modal.service';
import { ReportService } from '../../lib-services/report/report.service';
import { StateChangeService } from '../../lib-services/state-change/state-change.service';
import { ReportChartComponent } from '../../lib-components/report-chart/report-chart.component';
import { ReportTableComponent } from '../../lib-components/report-table/report-table.component';
import { LabelDropdownComponent } from '../../lib-components/label-dropdown/label-dropdown.component';
import { LabelPanelComponent } from '../../lib-components/label-panel/label-panel.component';

import { PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { ReportNumericComponent } from '../../lib-components/report-numeric/report-numeric.component';
import { ReportingAccount } from '../../lib-models/reporting-account/reporting-account';
import { AccountServiceInterface, DomService, StateDataService } from '../../../public-api';

type ReportTabValue = ReportType | 'FILTER';
type ReportTab = {
  value: ReportTabValue,
  label: string
};

type ReportDropdownItemValue = ('DOWNLOAD' | 'DELETE' | 'TOGGLE_MENU' | 'SAVE');

@Component({
  selector: 'app-report-edit',
  templateUrl: './app-report-edit.component.html',
  styleUrls: ['./app-report-edit.component.scss']
})
export class AppReportEditComponent implements OnInit, GuardedUnsavedChangesComponent {

  readonly query_params = CoreUtilService.parseQueryParams(this.route.snapshot.queryParams);

  readonly PRODUCT: AppProduct = this.env.product;

  @ViewChild(LabelDropdownComponent) label_dropdown: LabelDropdownComponent;
  @ViewChild(LabelPanelComponent) label_panel: LabelPanelComponent;

  @ViewChild(ReportChartComponent) report_chart: ReportChartComponent;
  @ViewChild(ReportTableComponent) report_table: ReportTableComponent;
  @ViewChild(ReportNumericComponent) report_numeric: ReportNumericComponent;

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.is_mobile !== DomService.is_mobile) {
      this.is_mobile = DomService.is_mobile;
      this._updateMenuDropown();
    }
  }

  readonly config = this.reportService.getConfig();
  readonly graph_types = this.reportService.getChartTypes();
  readonly all_labels = this.reportService.getAllLabels();
  readonly colour_palette = CoreUtilService.colour_palette;
  readonly labels_enabled = this.PRODUCT === 'INVOXY';

  report: Report = null;
  report_copy: Report = null;

  query_data: any = null;

  report_type: ReportType = this.query_params.report_type;

  // The same limits are defined in the API sql_generator reporting script. This is just for determining whether the limit has been applied.
  // Actual limiting occurs in the API
  resultsLimit = this.report_type === 'TABLE' ? 5000 : 500;

  tab_map: Record<ReportTabValue, ReportTab> = {
    TABLE: {
      label: 'Table',
      value: 'TABLE'
    },
    CHART: {
      label: 'Chart',
      value: 'CHART'
    },
    NUMERIC: {
      label: 'Numeric',
      value: 'NUMERIC'
    },
    FILTER: {
      label: 'Filters',
      value: 'FILTER'
    }
  };
  tabs: ReportTab[] = [];
  selected_tab: ReportTab = null;

  menu_dropdown_map: Record<ReportDropdownItemValue, DropdownItem> = {
    DOWNLOAD: {
      label: 'Download Report',
      value: 'DOWNLOAD'
    },
    DELETE: {
      label: 'Delete',
      value: 'DELETE',
      text_colour: 'var(--app-color-red)'
    },
    TOGGLE_MENU: {
      label: 'Show Side Menu',
      value: 'TOGGLE_MENU',
      toggleable: true,
      toggle_value: true
    },
    SAVE: {
      label: 'Save',
      value: 'SAVE',
      text_colour: 'var(--theme-color-success)'
    }
  };
  menu_dropdown = [];

  loading = false;
  generating_report = false;

  report_errors: PostableObjectErrorMap = {};

  unsaved_changes_data: UnsavedChangesData = {
    data_pairs: [
      {
        currentData: () => this.report,
        originalData: () => this.report_copy,
        props_to_ignore: ['table_config']
      }
    ],
    function_checks: [() => this.report.table_config.id === this.report_copy.table_config.id],
    saveFunction: () => this.validateReport()
  };

  labelIsVisible = (label: Label) => !CoreUtilService.labelAlreadyExists(label, this.report.labels);

  constructor(
    @Inject('env') public env: any,
    @Inject('accountService') public accountService: AccountServiceInterface,
    public router: Router,
    public route: ActivatedRoute,
    public stateChangeService: StateChangeService,
    public reportService: ReportService,
    public libModalService: LibModalService,
    public domService: DomService,
    public stateDataService: StateDataService
  ) { }

  ngOnInit(): void {
    this.initialiseReport();
  }

  back() {
    this.stateChangeService.back();
  }

  accountsSelected() {
    if (!this.report.hasErrors(false)) {
      this.generateReport();
    }
  }

  addNewFilter() {
    this.libModalService.reportFilterModal(this.report)
      .then((filter) => {
        this.report.filters.push(filter);
        this.generateReport();
      })
      .catch(() => { });
  }

  openReportSettingModal() {
    this.libModalService.reportSettingModal(this.report)
      .then(() => this.generateReport())
      .catch(() => { });
  }

  selectTab(tab: ReportTab) {
    this.selected_tab = tab;
  }

  dropdownItemSelected(item: DropdownItem) {
    switch (item.value) {
      case 'TOGGLE_MENU':
        this.toggleMenu();
        break;
      case 'DOWNLOAD':
        this.downloadCSV();
        break;
      case 'DELETE':
        this.deleteReport();
        break;
      case 'SAVE':
        this.validateReport();
        break;
    }
  }

  downloadCSV() {
    if (!!this.query_data?.length) {
      const csv_string = this.reportService.generateReportCsv(
        this.query_data, this.report
      );
      if (csv_string !== null) {
        const csv_content = new Blob([csv_string], { type: 'data:text/csv;charset=utf-8,' });

        let title = this.report.report_title.replace(/ /g, '_');
        if (!title) { title = 'New_Report'; }

        FileSaver.saveAs(csv_content, title + '.csv');
      }
    }
  }

  initialiseReport() {
    const report_key = this.query_params.report_key;

    if (!!report_key) {
      this.report = this.reportService.getReport(report_key);
    }
    if (!this.report) {
      this.report = this.reportService.initNewReport(this.report_type);
    }
    this.report_copy = _.cloneDeep(this.report);

    this._updateMenuDropown();
    this._initTabs();

    if (!!this.report.report_key) {
      this.generateReport(true, false);
    }
  }

  toggleMenu() {
    this.menu_dropdown_map.TOGGLE_MENU.toggle_value = !this.menu_dropdown_map.TOGGLE_MENU.toggle_value;
    this.stateDataService.cacheComponentSessionData('AppReportEditComponent', 'TOGGLE_MENU', this.menu_dropdown_map.TOGGLE_MENU.toggle_value);
    setTimeout(() => this.report_chart?.resizeChart(), 300);
  }

  addLabel(label: Label) {
    if (!CoreUtilService.labelAlreadyExists(label, this.report.labels)) {
      this.report.labels.push(label);
      this.label_panel?.addLabel(label);
      this.renderLabelComponents();
    }
  }

  removeLabel(label: Label) {
    CoreUtilService.removeLabelFromList(label, this.report.labels);
    this.renderLabelComponents();
  }

  renderLabelComponents() {
    if (this.labels_enabled) {
      setTimeout(() => {
        this.label_panel?.reloadVisibleLabels();
        this.label_dropdown?.reloadVisibleLabels();
      });
    }
  }

  validateReport() {
    return new Promise<void>((resolve, reject) => {

      this.report_errors = this.report.getErrors();

      if (this.report.hasErrors()) {
        if (this.PRODUCT === 'KARMLY') {
          for (const error of Object.values(this.report_errors)) {
            return this.domService.openNotificationPopover(error, 'ERROR');
          }
        }
        else {
          return this.libModalService.errorModal(null, Object.values(this.report_errors));
        }
        reject();
      }
      else {
        this._saveReport()
          .then(() => resolve())
          .catch(() => reject());
      }
    });
  }

  private _saveReport() {
    return new Promise<void>((resolve, reject) => {
      this.loading = true;

      this.reportService.saveReport(this.report)
        .then((report) => {
          this.report = report;
          this.report_copy = _.cloneDeep(this.report);

          if (!!this.query_params.previous_route) {
            this._goBackIfRouteProvided();
          }
          this.generateReport(false, true);
          resolve();
        })
        .catch(() => reject())
        .finally(() => this.loading = false);
    });
  }

  private _goBackIfRouteProvided() {
    if (!!this.query_params.previous_route) {
      const path = this.stateChangeService.getUrlWithoutParams(
        this.query_params.previous_route
      );
      const query_params = StateChangeService.mergeOldAndNewParams(
        this.query_params.previous_route_query_params,
        { new_report_key: this.report.report_key || null }
      );

      this.stateChangeService.backToRoute(
        path,
        query_params
      );
    }
  }

  deleteReport() {
    if (!!this.report.report_key) {
      this.libModalService.confirmDeleteModal(this.report_copy.report_title)
        .then(() => {
          this.loading = true;

          this.reportService.deleteReport(this.report_copy)
            .then(() => this.back())
            .catch(() => this.loading = false);
        })
        .catch(() => { });
    }
  }

  generateReport(report_init: boolean = false, report_saved: boolean = false) {
    this.generating_report = true;

    this._generateQuery()
      .then((query_data) => {
        this.query_data = query_data;

        setTimeout(() => {
          this.report_chart?.renderReport();
          this.report_table?.renderReport();
          this.report_numeric?.renderReport();
        });
      })
      .finally(() => {
        if (this.query_data?.length === this.resultsLimit && !report_init) {
          const message = 'Showing the first ' + this.resultsLimit + ' results. Use filters to further refine the result set.';

          if (!report_saved) {
            this.domService.openNotificationPopover(message, 'ERROR');
          }
          else {
            this.domService.openNotificationPopover(message, 'SUCCESS', true);
          }
        }
        else if (report_saved) {
          this.domService.openNotificationPopover('Report saved successfully.', 'SUCCESS', true);
        }

        this.generating_report = false;
      });
  }

  private _generateQuery() {
    if (this.PRODUCT === 'FLEXITIME') {
      return this.reportService.generateQueryForAccounts(this.report, this.accountService.selected_accounts);
    }
    else {
      return this.reportService.generateQuery(this.report);
    }
  }

  private _updateMenuDropown() {
    this.menu_dropdown = [];

    if (!this.is_mobile) {
      const cached_toggle_value = this.stateDataService.getCachedComponentSessionData('AppReportEditComponent', 'TOGGLE_MENU');
      if (cached_toggle_value !== null) {
        this.menu_dropdown_map.TOGGLE_MENU.toggle_value = cached_toggle_value;
      }

      this.menu_dropdown.push(
        this.menu_dropdown_map.TOGGLE_MENU,
        null
      );
    }

    if (this.report.report_type === 'TABLE') {
      this.menu_dropdown.push(
        this.menu_dropdown_map.DOWNLOAD,
        null
      );
    }
    if (!!this.report.report_key) {
      this.menu_dropdown.push(
        this.menu_dropdown_map.DELETE,
        null
      );
    }
    if (this.is_mobile) {
      this.menu_dropdown.push(
        this.menu_dropdown_map.SAVE
      );
    }
  }

  private _initTabs() {
    this.tabs = [];
    if (this.report.report_type === 'TABLE') {
      this.tabs.push(this.tab_map.TABLE);
    }
    else if (this.report.report_type === 'CHART') {
      this.tabs.push(this.tab_map.CHART);
    }
    else if (this.report.report_type === 'NUMERIC') {
      this.tabs.push(this.tab_map.NUMERIC);
    }
    this.tabs.push(this.tab_map.FILTER);
    this.selected_tab = this.tabs[0];
  }

}
