import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import _ from 'lodash-es';

import {
  KmClock,
  KmProjectStub,
  CoreUtilService,
  KmProjectTask,
  PostableObjectErrorMap,
  KmNote,
  ListDropDownComponent,
  ProjectServiceInterface,
  ProjectTaskServiceInterface,
  IntegrationServiceAbstract,
  DropdownCustomDivider,
  KmTaskStub,
  ListDropdownChildNodeResolver
} from '../../../public-api';

import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';
import { ProjectTaskUtilService } from '../../lib-services/project-task-util/project-task-util.service';
import { DomService } from '../../lib-services/dom/dom.service';
import { ClockServiceInterface } from '../../lib-interfaces/clock-service.interface';
import { TaskServiceInterface } from '../../lib-interfaces/task-service.interface';

@Component({
  selector: 'app-km-clock-modal',
  templateUrl: './km-clock-modal.component.html',
  styleUrls: ['./km-clock-modal.component.scss']
})
export class KmClockModalComponent implements OnInit, AppModalInterface {

  readonly project_label = CoreUtilService.project_label;
  readonly project_task_label = CoreUtilService.project_task_label;
  readonly task_label = CoreUtilService.task_label;
  readonly interruption_disabled_delay_seconds = this.clockService.interruption_disabled_delay_seconds;

  getDropdownTasksForNewProjectTask: ListDropdownChildNodeResolver = () => {
    return {
      items: this.tasks,
      config: this.task_dropdown_item_config
    };
  }

  getDropdownProjectsForNewProjectTask: ListDropdownChildNodeResolver = () => {
    return {
      items: this.projects,
      config: this.project_dropdown_item_config
    };
  }

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.is_mobile = DomService.is_mobile;
  }

  @ViewChild('project_dropdown') project_dropdown: ListDropDownComponent;
  @ViewChild('project_task_dropdown') project_task_dropdown: ListDropDownComponent;

  @Input() init_new_clock: boolean = false;
  @Input() new_clock_note: string = null;

  @Output() close = new EventEmitter<{ clock: KmClock }>();
  @Output() dismiss = new EventEmitter<void>();

  clock: KmClock = null;

  invoxy_integrated: boolean = this.integrationService.isIntegrated('INVOXY');

  projects: KmProjectStub[] = this.projectService.getProjects(true);

  project_task_dropdown_items: (KmProjectTask | DropdownCustomDivider)[] = [];

  tasks: KmTaskStub[] = this.taskService.getTimeBasedTasks();

  selected_project: KmProjectStub = null;
  clock_notes: KmNote[];

  clock_out_errors: PostableObjectErrorMap = {};

  createNewProject = (project_name: string) => {
    const new_project = this.projectService.initNewProject(
      project_name,
      this.clock?.clock_in_time || null
    );

    this.projectService.updateProject(new_project)
      .then((project) => {
        if (!!project.project_key) {
          this.projects = this.projectService.getProjects(true);
          this.updateProjectTaskDropdownItems();
          this.projectSelected(this.projectService.getProjectStub(project.project_key));
        }
      })
      .catch(() => { });
  };

  createNewProjectTask = (
    project_task_name: string,
    task: KmTaskStub = null,
    project: KmProjectStub = null
  ) => {
    if (this.taskService.checkForActiveTimeBasedTask()) {
      task = task || this.tasks[0];
      project = project || this.selected_project;

      const new_project_task = this.projectTaskService.initNewProjectTask(
        project.project_key,
        task.task_key,
        project_task_name
      );

      this.projectTaskService.updateProjectTask(new_project_task)
        .then((project_task) => {
          if (!!project_task.project_task_key) {
            this.updateProjectTaskDropdownItems();
            this.projectTaskSelected(project_task);
          }
        })
        .catch(() => { });
    }
  };

  projectIsVisible = (project: KmProjectStub) => {
    const date = this.clock.clock_in_time || new Date();
    return !project.locked_flag || ((!!project.coworker_locked_flag || this.invoxy_integrated) && project.isCurrent(date));
  }

  projectTaskIsVisible = (project_task: KmProjectTask) => {
    if (!!project_task.completed_date || project_task.task.archived_flag) {
      return false;
    }
    if (!!this.selected_project) {
      return (project_task.project.project_key === this.selected_project.project_key) && !project_task.task.archived_flag;
    }
    return this.projectIsVisible(project_task.project);
  }

  readonly project_dropdown_item_config = CoreUtilService.getDefaultDropdownConfig(
    'KM_PROJECT',
    { itemIsVisible: this.projectIsVisible }
  );
  readonly pt_creation_project_dropdown_item_config = CoreUtilService.getDefaultDropdownConfig(
    'KM_PROJECT',
    {
      itemIsVisible: this.projectIsVisible,
      getChildNode: () => {
        return {
          items: this.tasks,
          config: this.task_dropdown_item_config
        };
      }
    }
  );
  readonly task_dropdown_item_config = CoreUtilService.getDefaultDropdownConfig(
    'KM_TASK'
  );
  readonly project_task_dropdown_item_config = CoreUtilService.getDefaultDropdownConfig(
    'KM_PROJECT_TASK',
    { itemIsVisible: this.projectTaskIsVisible }
  );

  stopActiveClockWrapper = () => this.stopActiveClock();
  deleteActiveClockWrapper = () => this.deleteActiveClock();

  constructor(
    @Inject('projectService') public projectService: ProjectServiceInterface,
    @Inject('projectTaskService') public projectTaskService: ProjectTaskServiceInterface,
    @Inject('integrationService') public integrationService: IntegrationServiceAbstract,
    @Inject('clockService') public clockService: ClockServiceInterface,
    @Inject('taskService') public taskService: TaskServiceInterface
  ) { }

  ngOnInit(): void {
    this._initClock();
    this._initSelectedProjectTask();
    this.updateProjectTaskDropdownItems();
  }

  updateProjectTaskDropdownItems() {
    const project_tasks = this.projectTaskService.getHoursBasedProjectTasks();
    const grouped_project_tasks = ProjectTaskUtilService.groupProjectTasksByProject(project_tasks);

    this.project_task_dropdown_items = [];
    for (const group of grouped_project_tasks) {

      if (grouped_project_tasks.length > 1) {
        this.project_task_dropdown_items.push({
          is_custom_divider: true,
          divider_type: 'GROUP_HEADER',
          divider_label: group.project.project_name,
          divider_bullet_colour: group.project.project_colour
        });
      }

      this.project_task_dropdown_items.push(...group.project_tasks);
    }
  }

  projectSelected(project: KmProjectStub) {
    this.selected_project = project;
    this.project_task_dropdown?.reloadVisibleItems();

    if (
      !this.clock.project_task ||
      this.clock.project_task.project.project_key !== this.selected_project.project_key
    ) {
      let project_task = this.projectTaskService.getDefaultProjectTaskForProject(this.selected_project?.project_key || null);
      if (!!project_task?.completed_date) project_task = null;

      this.projectTaskSelected(project_task);
    }
  }

  projectTaskSelected(project_task: KmProjectTask) {
    this.clock.project_task = project_task || null;

    if (!!this.clock.project_task) {
      this.selected_project = this.clock.project_task.project;
    }

    this.updateActiveClockProjectTask();
  }

  cancel() {
    this.dismiss.emit();
  }

  updateActiveClockProjectTask() {
    if (!!this.clock.clock_key && !!this.clock.project_task) {
      this._clearErrors();

      this.clockService.updateActiveClockProjectTask(this.clock.project_task.project_task_key)
        .catch(() => { })
        .finally(() => {
          this.clock = this.clockService.getActiveClock();
        });
    }
  }

  updateActiveClockNote() {
    if (!!this.clock.clock_key) {
      this._clearErrors();

      this.clockService.updateActiveClockNote(this.clock.notes[0])
        .catch(() => { })
        .finally(() => {
          this.clock = this.clockService.getActiveClock();
        });
    }
  }

  deleteActiveClock() {
    if (!!this.clock.clock_key) {
      this._clearErrors();

      this.clockService.deleteActiveClock()
        .catch(() => { });
      this.close.emit();
    }
  }

  startInterruption() {
    this.clockService.clockIn()
      .catch(() => { });
    this.close.emit();
  }

  startClock() {
    if (!this.clock.clock_key) {
      const project_task_key = this.clock.project_task?.project_task_key || null;
      const note_content = this.clock.notes[0].note_content || null;

      this.clockService.clockIn(project_task_key, note_content)
        .catch(() => { });
      this.close.emit();
    }
  }

  stopActiveClock() {
    if (!!this.clock.clock_key && !this._hasErrors()) {
      this.clockService.clockOut()
        .catch(() => { });
      this.close.emit();
    }
  }

  private _getErrors() {
    this._clearErrors();

    if (!this.clock.project_task) {
      this.clock_out_errors['project_task'] = this.project_task_label.capitalised + ' Required';
    }
  }

  private _hasErrors(): boolean {
    if (!this.taskService.checkForActiveTimeBasedTask()) {
      return true;
    }
    else {
      this._getErrors();
      return Object.keys(this.clock_out_errors).length > 0;
    }
  }

  private _clearErrors() {
    this.clock_out_errors = {};
  }

  private _initSelectedProjectTask() {
    if (!!this.clock.project_task) {
      this.selected_project = this.clock.project_task.project;
      return;
    }
  }

  private _initClock() {
    if (this.init_new_clock) {
      this.clock = this.clockService.initNewClock(this.new_clock_note || null);
    }
    else {
      this.clock = this.clockService.getActiveClock();
    }
  }


}
