
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
export class KmExpenseStub {

  expense_key: number;
  project_key: number;
  project_name: string;
  project_colour: string;
  project_archived_date: Date;
  client_key: number;
  client_name: string;
  task_key: number;
  task_name: string;
  billable_flag: boolean;
  supplier: string;
  expense_date: Date;
  description: string;
  includes_gst: boolean;
  attachment_url: string;
  expense_total: number;
  gst_amount: number;
  invoiced_flag: boolean;
  receipt_total: number;
  business_use_percentage: number;

  // Used by mega-search
  expense_date_string: string;
  expense_total_string: string;

  constructor(
    expense_key: number,
    project_key: number,
    project_name: string,
    project_colour: string,
    project_archived_date: Date,
    client_key: number,
    client_name: string,
    task_key: number,
    task_name: string,
    billable_flag: boolean,
    supplier: string,
    expense_date: Date,
    description: string,
    includes_gst: boolean,
    attachment_url: string,
    expense_total: number,
    gst_amount: number,
    invoiced_flag: boolean,
    receipt_total: number,
    business_use_percentage: number
  ) {
    this.expense_key = expense_key;
    this.project_key = project_key;
    this.project_name = project_name;
    this.project_colour = project_colour;
    this.project_archived_date = project_archived_date;
    this.client_key = client_key;
    this.client_name = client_name;
    this.task_key = task_key;
    this.task_name = task_name;
    this.billable_flag = billable_flag;
    this.supplier = supplier;
    this.expense_date = expense_date;
    this.description = description;
    this.includes_gst = includes_gst;
    this.attachment_url = attachment_url;
    this.expense_total = expense_total;
    this.gst_amount = gst_amount;
    this.invoiced_flag = invoiced_flag;
    this.receipt_total = receipt_total;
    this.business_use_percentage = business_use_percentage;

    this.expense_date_string = TimeUtilService.dateToDateTimeString(this.expense_date, 'd MMMM yyyy');
    this.expense_total_string = CoreUtilService.currency_symbol + this.expense_total;
  }

}
