<div class="app-formItem-container">
  <app-form-item [label]="'Type'">
    <list-drop-down [item_label_prop]="'label'"
                    [button_title]="selected_graph_type?.label"
                    [list_fill_width]="true"
                    [disabled]="!report.table_config"
                    [items]="graph_types"
                    (item_selected)="selectGraphType($event.item)">
    </list-drop-down>
  </app-form-item>

  <app-form-item [label]="'Table'">
    <list-drop-down [item_label_prop]="'label'"
                    [button_title]="report.table_config?.label"
                    [list_fill_width]="true"
                    [items]="available_facts"
                    (item_selected)="selectFact($event.item)">
    </list-drop-down>
  </app-form-item>

  <app-form-item [label]="'Measure (Y)'">
    <list-drop-down [item_label_prop]="'label'"
                    [button_title]="report.measure?.label"
                    [list_fill_width]="true"
                    [show_search]="true"
                    [itemIsVisible]="fieldIsVisible"
                    [items]="report.table_config?.measures || []"
                    (item_selected)="selectMeasure($event.item)">
    </list-drop-down>
  </app-form-item>

  <app-form-item [label]="'Aggregate'">
    <list-drop-down [button_title]="report.graph_function"
                    [list_fill_width]="true"
                    [items]="config.permissions.measures"
                    (item_selected)="selectFunction($event.item)">
    </list-drop-down>
  </app-form-item>

  <app-form-item [label]="'Category (X)'">
    <list-drop-down [item_label_prop]="'label'"
                    [button_title]="report.dimension?.label"
                    [list_fill_width]="true"
                    [show_search]="true"
                    [itemIsVisible]="fieldIsVisible"
                    [items]="report.table_config?.dimensions || []"
                    (item_selected)="selectDimension($event.item)">
    </list-drop-down>
  </app-form-item>

  <app-form-item [label]="'Series'">
    <list-drop-down [item_label_prop]="'label'"
                    [button_title]="report.series?.dimension.label"
                    [button_title_placeholder]="'No Series'"
                    [list_fill_width]="true"
                    [show_search]="true"
                    [default_item]="no_series"
                    [items]="available_series"
                    (item_selected)="selectSeries($event.item)">
    </list-drop-down>
  </app-form-item>

  <app-form-item *ngIf="!!report.series"
                 [label]="'Show Legend'"
                 [hide_border]="true">
    <app-checkbox [(ngModel)]="report.series.show_legend"></app-checkbox>
  </app-form-item>

  <app-form-item *ngIf="!!report.series && (report.graph_type === 'bar' || report.graph_type === 'horizontal_bar')"
                 [label]="'Stack Series'"
                 [hide_border]="true">
    <app-checkbox [(ngModel)]="report.series.stack"></app-checkbox>
  </app-form-item>

</div>
