import { Component, OnDestroy, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';

import {
  KmProjectTask,
  ClockServiceInterface,
  ProjectTaskServiceInterface
} from '../../../public-api';
import { Subscription } from 'rxjs';

type ProjectTaskDropdownItem = {
  label: string,
  description: string,
  colour: string,
  project_task: KmProjectTask
};

@Component({
  selector: 'active-clock-list',
  templateUrl: './active-clock-list.component.html',
  styleUrls: ['./active-clock-list.component.scss']
})
export class ActiveClockListComponent implements OnInit, OnDestroy {

  @Input() active_clock_editing_enabled: boolean = true;
  @Input() active_clock_stop_enabled: boolean = false;
  @Input() active_clock_delete_enabled: boolean = false;
  @Input() active_clock_hide_details: boolean = false;
  
  @Input() clockOutOverrideFunction: () => void = null;
  @Input() deleteActiveClockOverrideFunction: () => void = null;

  @Output() clock_modal_opened = new EventEmitter<void>();

  active_clock = this.clockService.getActiveClock();
  clock_groups = this.clockService.getClockGroups();

  project_task_dropdown: ProjectTaskDropdownItem[] = [];

  event_subscriptions: Subscription[] = [];

  loading: boolean = false;

  constructor(
    @Inject('clockService') public clockService: ClockServiceInterface,
    @Inject('projectTaskService') public projectTaskService: ProjectTaskServiceInterface
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
  }


  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  clockIn(event: any, project_task: KmProjectTask = null) {
    event.stopPropagation();
    this.clockService.clockIn(project_task?.project_task_key || null, null);
  }

  clockOut(event: any) {
    event.stopPropagation();
    if (!!this.clockOutOverrideFunction) {
      this.clockOutOverrideFunction();
    }
    else {
      this.clockService.clockOut();
    }
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.clockService.getActiveClockUpdatedEvent().subscribe(() => {
        this.active_clock = this.clockService.getActiveClock();
        this.clock_groups = this.clockService.getClockGroups();
      })
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
