import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Route } from '@angular/router';

import { LibPipesModule, LibComponentsModule, UnsavedChangesGuard, ModulePathRedirectGuard, AppReportEditComponent } from 'flexitime-library';

import { ReportAllComponent } from './report-all/report-all.component';
import { ReportRoutePath } from '../../app.route-types';

export const module_routes: (Route & { path: (ReportRoutePath | '') })[] = [
  {
    path: '',
    canActivate: [ModulePathRedirectGuard],
    children: []
  },
  {
    path: 'all',
    component: ReportAllComponent
  },
  {
    path: 'edit',
    component: AppReportEditComponent,
    canDeactivate: [UnsavedChangesGuard]
  }
];

@NgModule({
  declarations: [
    ReportAllComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LibPipesModule,
    LibComponentsModule,
    RouterModule.forChild(module_routes)
  ]
})
export class ReportModule { }
