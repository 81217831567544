import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { Report, AppModalInterface, LibModalService } from '../../../public-api';
import { ReportType } from '../../lib.types';

type ReportTabValue = ReportType | 'FILTER';
type ReportTab = {
  value: ReportTabValue,
  label: string
};

@Component({
  selector: 'lib-report-setting-modal',
  templateUrl: './report-setting-modal.component.html',
  styleUrls: ['./report-setting-modal.component.scss']
})
export class ReportSettingModalComponent implements OnInit, AppModalInterface {

  @Input() report: Report;

  tab_map: Record<ReportTabValue, ReportTab> = {
    TABLE: {
      label: 'Table',
      value: 'TABLE'
    },
    CHART: {
      label: 'Chart',
      value: 'CHART'
    },
    NUMERIC: {
      label: 'Numeric',
      value: 'NUMERIC'
    },
    FILTER: {
      label: 'Filters',
      value: 'FILTER'
    }
  };
  tabs: ReportTab[] = [];
  selected_tab: ReportTab = null;

  @Output() close = new EventEmitter();
  @Output() dismiss = new EventEmitter();

  constructor(
    public libModalService: LibModalService
  ) { }

  ngOnInit(): void {
    this._initTabs();
  }

  ok(){
    this.close.emit();
  }

  cancel(){
    this.dismiss.emit();
  }

  addNewFilter() {
    this.libModalService.reportFilterModal(this.report)
      .then((filter) => {
        this.report.filters.push(filter);
      })
      .catch(() => { });
  }

  private _initTabs() {
    if (this.report.report_type === 'TABLE') {
      this.tabs.push(this.tab_map.TABLE);
    }
    else if (this.report.report_type === 'CHART') {
      this.tabs.push(this.tab_map.CHART);
    }
    else if (this.report.report_type === 'NUMERIC') {
      this.tabs.push(this.tab_map.NUMERIC);
    }

    this.tabs.push(this.tab_map.FILTER);
    this.selected_tab = this.tabs[0];
  }

}
