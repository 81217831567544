<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>Update Timesheet Rates</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <div class="usrModal-fromDate">
        <div>Update timesheet entries that are not paid or billed from:</div>
        <date-picker [(ngModel)]="from_date"
                     [max_date]="today"
                     (ngModelChange)="fromDateChanged()">
        </date-picker>
      </div>

      <!--Placement Details-->
      <div class="usrModal-segment-details"
           *ngIf="project">
        <p class="usrModal-segment-title">{{project.uppercase}} Rate:</p>
        <div class="usrModal-segment-info split-two">
          <div>{{project.project_name}}</div>
          <div><span class="detail-title">Billing Rate</span> {{project.project_rate | currency}}</div>
        </div>
      </div>

      <div class="usrModal-segment-details"
           *ngIf="resources.length && project">
        <p class="usrModal-segment-title">Resource Rates:</p>
        <div class="usrModal-segment-info split-two"
             *ngFor="let resource of resources">
          <div>
            {{resource.display_name}}
          </div>
          <div><span class="detail-title">Resource Rate</span> {{resource.resource_rate | currency}}</div>
        </div>
      </div>


      <!--Work Details-->
      <div class="usrModal-segment-details"
           *ngIf="task">
        <p class="usrModal-segment-title">Work Rates:</p>
        <div class="usrModal-segment-info split-three">
          <div>{{task.task_display_name}}</div>
          <div>
            <span class="detail-title">Pay Rate Modifier</span>
            {{task.pay_type === "Fixed" ? '$' : (task.pay_type === "Multiplier" ? 'x' : '+$')}}
            {{task.pay_rate}}
          </div>
          <div>
            <span class="detail-title">Bill Rate Modifier</span>
            {{task.bill_type === "Fixed" ? '$' : (task.bill_type === "Multiplier" ? 'x' : '+$')}}
            {{task.bill_rate}}
          </div>
        </div>
      </div>

      <div class="usrModal-segment-details"
           *ngIf="overtime.length && task">
        <p class="usrModal-segment-title">Overtime & Allowance Rates:</p>
        <div class="usrModal-segment-info split-three"
             *ngFor="let overtime of overtime"
             [ngClass]="{'split-two': overtime.deduction_flag}">
          <div>{{overtime.overtime_name}}</div>
          <div>
            <span class="detail-title">Pay Rate</span>
            <span *ngIf="!overtime.deduction_flag">{{overtime.pay_symbol + overtime.pay_rate}}</span>
            <span *ngIf="overtime.deduction_flag"
                  style="white-space: nowrap">
              {{overtime.pay_rate | appCurrency}}
              <span style="color: #999;">{{overtime.deduction_period}}</span>
            </span>
          </div>
          <div *ngIf="overtime.bill_rate">
            <span class="detail-title">Bill Rate</span>
            {{overtime.bill_margin ? (overtime.bill_rate + overtime.bill_symbol) : (overtime.bill_symbol +
            overtime.bill_rate)}}
          </div>
        </div>
      </div>

      <div class="usrModal-segment-details"
           *ngIf="oncost.length && task">
        <p class="usrModal-segment-title">On-Cost Rates:</p>
        <div class="usrModal-segment-info split-two"
             *ngFor="let oncost of oncost">
          <div>
            {{oncost.description}}
            <i class="ion ion-md-checkbox-outline -tooltipIcon"
               *ngIf="oncost.billable_flag">
              <tooltip-popover [tooltip_content]="'This On-Cost is Billable'"></tooltip-popover>
            </i>
          </div>
          <div>
            {{oncost.on_cost_rate}}% <span class="detail-label">of Pay Rate</span>
          </div>
        </div>
      </div>

      <div class="usrModal-segment-details"
           *ngIf="oncharge.length && task">
        <p class="usrModal-segment-title">On-Charge Rates:</p>
        <div class="usrModal-segment-info split-two"
             *ngFor="let oncharge of oncharge">
          <div>
            {{oncharge.description}}
            <i class="ion ion-md-checkbox-outline -tooltipIcon"
               *ngIf="oncharge.exclude_margin_flag">
              <tooltip-popover [tooltip_content]="'This On-Charge will be excluded from revenue'"></tooltip-popover>
            </i>
          </div>
          <div *ngIf="oncharge.on_charge_type === 'Fixed'">
            ${{oncharge.on_cost_rate}} <span class="detail-label">Fixed Rate</span>
          </div>
          <div *ngIf="oncharge.on_charge_type === 'Revenue'">
            {{oncharge.on_cost_rate}}% <span class="detail-label">of Revenue</span>
          </div>
          <div *ngIf="oncharge.on_charge_type !== 'Fixed' && oncharge.on_charge_type !== 'Revenue'">
            {{oncharge.on_cost_rate}}% <span class="detail-label">of {{oncharge.on_charge_type}} Rate</span>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterLeft">
    </div>
    <div class="app-modal-btnFooterRight">
      <button class="-color-green"
              (click)="confirm()">
        Update
      </button>
      <button class="-color-white-outline -desktop-only"
              (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
