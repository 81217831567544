import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { CompanyServiceInterface } from '../../lib-interfaces/company-service.interface';
import { UserServiceInterface } from '../../lib-interfaces/user-service.interface';
import { StateAccessServiceInterface } from '../../lib-interfaces/state-access-service.interface';
import { NotificationServiceInterface } from '../../lib-interfaces/notification-service.interface';
import { NavMenuExternalItem } from '../../lib.types';
import { DomService } from '../../lib-services/dom/dom.service';
import { BackdropComponent, NavMenuServiceAbstract } from '../../../public-api';

@Component({
  selector: 'uac-dropdown',
  templateUrl: './uac-dropdown.component.html',
  styleUrls: ['./uac-dropdown.component.scss']
})
export class UacDropdownComponent implements OnInit, OnDestroy {

  @ViewChild('ngb_dropdown') ngb_dropdown: NgbDropdown;
  @ViewChild('uacd_content') uacd_content: ElementRef;

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (DomService.is_mobile !== this.is_mobile) {
      this.is_mobile = DomService.is_mobile;
      this.uac_dropdown_items = this.navMenuService.generatePopupMenu();
    }
  }

  display_name: string = null;
  display_image: string = null;

  uac_dropdown_items: NavMenuExternalItem[] = [];
  event_subscriptions: Subscription[] = [];

  unseen_count: number = this.notificationService.getUnseenNotificationCount();

  uac: any = this.companyService.getUac();

  product: string = this.env.product;

  backdrop_ref: BackdropComponent = null;

  constructor(
    public domService: DomService,
    @Inject('env') public env: any,
    @Inject('navMenuService') public navMenuService: NavMenuServiceAbstract,
    @Inject('stateAccessService') public stateAccessService: StateAccessServiceInterface,
    @Inject('companyService') public companyService: CompanyServiceInterface,
    @Inject('userService') public userService: UserServiceInterface,
    @Inject('notificationService') public notificationService: NotificationServiceInterface,
    @Inject('modalService') public modalService: any, // TODO: should be typed to an interface
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
    this.stateAccessReloaded();

    this.display_name = this.userService.getDisplayName();
    this.display_image = this.companyService.getProfileImage();
  }

  ngOnDestroy() {
    this._clearEventListeners();
    this.domService.closeOffCanvas();
    this.backdrop_ref?.close();
  }

  dropdownToggleClicked() {
    if(this.env.product === 'DROPPAH') {
      this.modalService.openProfileDropdownModal()
        .then(() => { })
        .catch(() => { });
      return;
    }
    if (this.is_mobile) {
      this.openMobileDropdown();
    }
    else {
      this.openDropdown();
    }
  }

  openDropdown() {
    this.ngb_dropdown?.open();
  }

  openMobileDropdown() {
    this.domService.openMobileDropdown(this.uacd_content, 'top');
  }

  dropdownToggled(is_open: boolean) {
    if (is_open && !this.is_mobile) {
      this.backdrop_ref = this.domService.openBackdrop();
    }
    else {
      this.backdrop_ref?.close();
    }
  }

  stateAccessReloaded() {
    this.uac_dropdown_items = this.navMenuService.generatePopupMenu();
  }

  displayImageUpdated() {
    this.display_image = this.companyService.getProfileImage();
  }

  dropdownItemSelected(item: NavMenuExternalItem) {
    this.ngb_dropdown?.close();
    this.backdrop_ref?.close();
    this.domService.closeOffCanvas();

    if (!!item.itemSelected) {
      item.itemSelected();
    }
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.stateAccessService.getStateAccessReloadedEvent().subscribe(() => {
        setTimeout(() => this.stateAccessReloaded());
      }),
      this.userService.getDisplayImageUpdatedEvent().subscribe(() => {
        setTimeout(() => this.displayImageUpdated());
      }),
      this.companyService.subscribeToEvent('UAC_UPDATED', () => {
        setTimeout(() => this.stateAccessReloaded());
      }),
      this.notificationService.getNotificationUpdatedEvent().subscribe(() => {
        this.unseen_count = this.notificationService.getUnseenNotificationCount();
      })
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
