import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { PostableObject, PostableObjectErrorMap } from './../../lib-interfaces/postable-object.interface';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

export class DpSkill implements PostableObject<DpSkill> {

  skill_key: number;
  skill_name: string;
  skill_colour: string;

  average_cost: number;
  payhero_project_key: number;
  xero_pay_item_id: string;
  export_id: string;

  archived_flag: boolean;

  constructor(
    skill_key: number = null, skill_name: string = '',
    skill_colour: number | string = null, average_cost: number = 20,
    payhero_project_key: number = null, xero_pay_item_id: string = null,
    export_id: string = null, archived_flag: boolean = false
  ) {

    this.skill_key = skill_key;
    this.skill_name = skill_name;
    this.average_cost = average_cost;
    this.payhero_project_key = payhero_project_key;
    this.xero_pay_item_id = xero_pay_item_id;
    this.export_id = export_id;
    this.archived_flag = archived_flag;
    this._initColour(skill_colour);
  }

  private _initColour(skill_colour: number | string) {
    if (skill_colour !== null) {
      if (typeof skill_colour === 'number') {
        this.skill_colour = CoreUtilService.intToHexColor(skill_colour);
      }
      else {
        this.skill_colour = skill_colour;
      }
    }
    else {
      this.skill_colour = '#EF9A9A';
    }
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

 
  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    return errors;
  }

  formatForPosting(toArchive: boolean = false): any {
    if (this.hasErrors()) {
      return {
        skill_key: this.skill_key,
        skill_name: this.skill_name,
        skill_colour: CoreUtilService.rgbaOrHexColorToInt(this.skill_colour),
        payhero_project_key: this.payhero_project_key,
        xero_pay_item_id: this.xero_pay_item_id,
        export_id: this.export_id,
        archived_flag: toArchive
      };
    }
    return null;
  }

}
