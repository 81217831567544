import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  CoreUtilService,
  AuthService,
  StateDataService,
  TokenLoginService
} from 'flexitime-library';

declare const Base64: any;

@Component({
  selector: 'app-login-external',
  templateUrl: './login-external.component.html',
  styleUrls: ['./login-external.component.scss']
})
export class LoginExternalComponent implements OnInit {

  readonly query_params = CoreUtilService.parseQueryParams(this.route.snapshot.queryParams);

  params: any = null;

  constructor(
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public stateDataService: StateDataService,
    public tokenLoginService: TokenLoginService
  ) { }

  ngOnInit(): void {
    this.params = this.query_params.url_params ?
      CoreUtilService.parseJSON(Base64.decode(this.query_params.url_params)) : {};

    this.externalLogin();
  }

  externalLogin() {
    if (this._validateAuthParamsOrExistingSession()) {
      this.tokenLoginService.tokenLogin()
        .then(() => this._goToSplash())
        .catch(() => this._logout());
    }
    else {
      this._logout();
    }
  }

  private _logout() {
    const domain = this.query_params.domain || null;
    this.authService.logout(null, domain);
  }

  private _goToSplash() {
    const route_path = this.query_params.route_path || this.params.route_path || this.params.state_name || null;
    const route_params = CoreUtilService.parseJSON(this.query_params.route_params || this.params.route_params || this.params.state_params);

    this.router.navigate(['splash'], {
      queryParams: {
        route_path,
        route_params: JSON.stringify(route_params)
      }
    });
  }

  private _validateAuthParamsOrExistingSession() {
    if (!!this.params?.refresh_token) {
      this.authService.product_refresh_token = this.params.refresh_token;
      return true;
    }
    else if (this.authService.hasSession()) {
      return true;
    }
    return false;
  }

}
