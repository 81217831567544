export class DpCountry {

  country_key: string;
  country_name: string;
  money_symobl: string;
  timezone_name: string;

  constructor(
    country_key: string, country_name: string, money_symbol: string, timezone_name: string
  ) {
    this.country_key = country_key;
    this.country_name = country_name;
    this.money_symobl = money_symbol;
    this.timezone_name = timezone_name;
  }

}
