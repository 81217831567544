<div class="input-group">
  <button class="-type-icon -color-link-hoverFill"
          ngbDropdown
          #ngbDropdown="ngbDropdown"
          [container]="'body'"
          [display]="'static'"
          [dropdownClass]="'dropdown'"
          (openChange)="dropdownToggled($event)">
    <i ngbDropdownToggle
      class="ion ion-md-calendar">
      <div class="monthPicker-dropdown"
            ngbDropdownMenu>
        <calendar-month-grid *ngIf="is_open"
                            [month]="selected_date"
                            [update_state_data]="false"
                            (month_selected)="monthSelected($event)">
        </calendar-month-grid>
      </div>
    </i>
  </button>

  <month-input [(ngModel)]="month_index"
              (ngModelChange)="updateMonth()">
  </month-input>

  <div class="app-formItem-inputContainer">
    <div class="input-group">
      <input class="form-control"
            highlight-on-click
            [placeholder]="'Year'"
            [ngModel]="selected_year?.getFullYear()"
            [ngModelOptions]="{ 'updateOn': 'blur'}"
            (ngModelChange)="parseYearFromView($event)">
    </div>
    <div class="app-formItem-inputContainer-border">
    </div>
  </div>
</div>
