import { Component, OnInit } from '@angular/core';
import { AbstractValueAccessor, CoreUtilService, MakeProvider, TimeUtilService } from '../../../public-api';

@Component({
  selector: 'month-input',
  templateUrl: './month-input.component.html',
  styleUrls: ['./month-input.component.scss'],
  providers: [
    MakeProvider(MonthInputComponent)
  ]
})
export class MonthInputComponent extends AbstractValueAccessor {

  _value: number = null;
  _previous_value: number = null;

  month_label: string = '';

  writeValue(value: any) {
    // Initialise previous value
    if (this._previous_value === null && !isNaN(value)) {
      this._previous_value = value;
    }
    super.writeValue(value);

    this.month_label = this._getMonthLabel(value);
  }

  parseModelFromView(value: any) {
    let model_value: number = -1;

    if (!!value && !CoreUtilService.numberIsValid(value)) {
      value = value.toLowerCase();
      if (TimeUtilService.months_shorts.includes(value.substring(0, 3))) {
        model_value = TimeUtilService.months_shorts.indexOf(value.substring(0, 3));
      }
      else {
        this._revertToPreviousValue();
        return;
      }
    } else if (!!value) {
      if (value < 0 || value > 12) {
        this._revertToPreviousValue();
        return;
      }
      else {
        model_value = value;
        model_value--; //account for pos in array
      }
    }

    if (model_value !== null && !isNaN(model_value) && model_value > -1) {
      this.value = model_value;

      this.month_label = this._getMonthLabel(model_value);
      this._previous_value = this.value;
    } else if (!!value) {
      this._revertToPreviousValue();
    } else {
      this.value = null;
      this.month_label = null;
    }
  }

  private _getMonthLabel(month_index: number) {
    return TimeUtilService.months[month_index]?.substring(0, 3);
  }

  private _revertToPreviousValue(){
    // Ensures input view is re-rendered
    this._value = null;
    this.month_label = null;
    setTimeout(() => {
      this.month_label = this._getMonthLabel(this._previous_value);
      this.value = this._previous_value;
    });
  }
}
