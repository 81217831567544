import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { DpSkill } from '../dp-skill/dp-skill';
import { PostableObject, PostableObjectErrorMap } from './../../lib-interfaces/postable-object.interface';

export class DpPersonSkill implements PostableObject<DpPersonSkill> {

  person_skill_key: number;
  person_key: number;
  rating: number;
  pay_rate: number;
  experience: number;
  skill: DpSkill;
  deleted_flag: boolean = false;

  constructor(
    person_skill_key: number = null, person_key: number = null, skill: DpSkill, rating: number = 3,
    pay_rate: number = null, experience: number = 0
  ) {
    this.person_skill_key = person_skill_key;
    this.person_key = person_key;
    this.skill = skill;
    this.rating = rating;
    this.pay_rate = pay_rate;
    this.experience = experience;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

 

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};


    /*
    if (!CoreUtilService.numberIsValid(this.rating)) {
      errors.push({
        error_path: 'rating',
        error_message: 'Please enter a valid skill rating value for ' + this.skill.skill_name
      });
    }
    if (this.pay_rate !== null && !CoreUtilService.numberIsValid(this.pay_rate)) {
      errors.push({
        error_path: 'pay_rate',
        error_message: 'Please enter a valid skill cost value for ' + this.skill.skill_name
      });
    }
    if (!CoreUtilService.numberIsValid(this.experience)) {
      errors.push({
        error_path: 'experience',
        error_message: 'Please enter a valid skill experience value for ' + this.skill.skill_name
      });
    }*/

    return errors;
  }

  formatForPosting(): any {
    if (this.hasErrors()) {
      // No need to post a new but deleted person skill
      if (this.person_skill_key === null && this.deleted_flag) {
        return null;
      }
      else {
        return {
          person_skill_key: this.person_skill_key,
          person_key: this.person_key,
          skill_key: this.skill.skill_key,
          rating: this.rating,
          pay_rate: this.pay_rate,
          experience: this.experience,
          deleted_flag: this.deleted_flag
        };
      }
    }
    return null;
  }
}
