import { Component, TemplateRef, ContentChild, HostBinding, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import * as  _ from 'lodash-es';

import { AppListItemDirective } from './app-list-item/app-list-item.directive';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';
import { StateDataService } from '../../lib-services/state-data/state-data.service';
import { DomService } from '../../lib-services/dom/dom.service';
import { InfiniteParams } from '../../lib.types';


export type AppListTableHeader = {
  label?: string,
  label_description?: string,
  whats_this?: string,
  property?: string,
  desktop_only?: boolean,
  hidden?: boolean,
  css_class?: string
};

type ScrollPosition = {
  scroll_top: number,
  primary_sort_property: string,
  forward_order: boolean
};

type RenderingStrategy = (
  'INFINITE' | 'VIRTUAL'
);

type ItemLoadStrategy = (
  'SYNCRONOUS' | 'ASYNCRONOUS'
);

// itemToggleIdProperty -> boolean
type ToggledItemMap = Record<string | number, boolean>;

@Component({
  selector: 'app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.scss']
})
export class AppListComponent implements OnInit, OnDestroy, OnChanges {

  readonly item_height_required_error = 'itemHeight is required when app-list renderingStrategy = \'VIRTUAL\'. If the item height is unknown, set renderingStrategy to \'INFINITE\'';

  @HostListener('window:beforeunload')
  clearScrollPositionCache() {
    this.stateDataService.clearCachedComponentSessionData(
      'AppListComponent',
      'scroll_positions',
    );
    this.stateDataService.clearCachedComponentSessionData(
      'AppListComponent',
      'toggled_item_maps',
    );
  }

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.is_mobile !== DomService.is_mobile) {
      this.is_mobile = DomService.is_mobile;
      this._checkScrollContainerHeight();
    }
  }

  @HostBinding('class.app-list') base_class: boolean = true;
  @HostBinding('class.-listEmpty') listEmpty: boolean;
  @HostBinding('class.-listTallerThanScrollContainer') list_taller_than_scroll_container: boolean = false;

  @HostBinding('class.-reverseScroll') @Input() reverseScroll: boolean = false;

  @ViewChild('list_header') list_header: ElementRef;
  @ViewChild('scroll_outer_elem') scroll_outer_elem: ElementRef;
  @ViewChild('scroll_outer_viewport') scroll_outer_viewport: CdkVirtualScrollViewport;

  @ContentChild(AppListItemDirective, { static: true, read: TemplateRef }) appListItemTemplate: TemplateRef<any>;

  @Input() listEndTemplate: TemplateRef<any> = null;

  @Input() tableHeaders: AppListTableHeader[];
  @Input() primarySortProperty: string = null;
  @Input() secondarySortProperty: string = null;
  @Input() endSortProperty: string = null;
  @Input() showHeaderOnMobile: boolean = false;
  forwardOrder: boolean = true;

  @Input() infiniteParams: InfiniteParams = {
    rowsToRender: 40,
    indexOfLastVisibleItem: -1,
    infiniteScrollDisabled: false,
    renderInReverse: false
  };

  @Input() itemType: string = 'item';
  @Input() itemTypePlural: string = 'items';
  @Input() itemSelectEnabledProperty: string = null;
  @Input() itemSelectDisabledProperty: string = null;
  @Input() itemDeleteHiddenProperty: string = null;
  @Input() itemSelectDisabled: boolean = false;
  @Input() itemDeleteDisabled: boolean = false;
  @Input() itemAddDisabled: boolean = false;

  // Required for tracking which items are toggled
  @Input() itemToggleIdProperty: string = null;
  @Input() itemToggleVisible: boolean = false;
  @Input() itemToggleDisabled: boolean = false;
  @Input() itemToggleCachingDisabled: boolean = false;

  @Input() sortDisabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() show_infinite_loading_spinner: boolean = false;
  @Input() canDeleteItems: boolean = false;
  @Input() canAddItems: boolean = false;

  @Input() listEmptyHeader: string = null;
  @Input() listEmptyDescription: string = null;
  @Input() showListEmptyButton: boolean = false;
  @Input() listEmptyButtonContent: string = null;


  // Required for scroll position caching
  @Input() itemHeight: number = null;
  @Input() renderingStrategy: RenderingStrategy = 'VIRTUAL';
  @Input() itemLoadStrategy: ItemLoadStrategy = 'SYNCRONOUS';
  @Input() scrollPositionCachingDisabled: boolean = false;

  // number of px to the top or bottom of scroll container required to trigger loading more segments
  @Input() infinite_scroll_distance_px: number = 200;

  @Input() cacheId: string = null;

  @Input() itemIsVisible: (item: any) => boolean = () => true;

  @Output() itemSelected = new EventEmitter();
  @Output() itemDeleted = new EventEmitter();
  @Output() itemAdded = new EventEmitter();
  @Output() itemToggled = new EventEmitter<{ toggle_count: number }>();
  @Output() listEmptyButtonClicked = new EventEmitter<void>();
  @Output() loadMoreItemsAsync = new EventEmitter<void>();

  @Input() debug: boolean = false;

  @Input() items: any[];
  @Input() supporting_item_datasets: Record<string, Record<string, any>> = {};

  // key that is common between main item list and supporting item data
  @Input() supporting_key_property: string = null;

  visible_items: any[] = [];

  toggled_item_map: ToggledItemMap = {};
  all_items_toggled: boolean = false;

  list_id: number = Math.floor(Math.random() * 10000000);

  event_subscriptions: Subscription[] = [];

  virtual_scroll_loading = false;

  list_empty_config = {
    header: null,
    description: null,
    button_content: null
  };

  // Determined by this.infinite_scroll_distance_px
  // eg 1.5 => 15% of scroll distance
  infinite_scroll_distance: number = 1;

  private _component_visible: boolean = false;
  scroll_initialised = false;

  constructor(
    public router: Router,
    public stateDataService: StateDataService,
    public elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    if (this.renderingStrategy === 'VIRTUAL' && this.itemHeight === null) {
      throw new Error(this.item_height_required_error);
    }

    this.forwardOrder = !this.reverseScroll;
    this.infiniteParams.renderInReverse = this.reverseScroll;

    this._updateListEmptyConfig();
    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  ngOnChanges(c: SimpleChanges) {
    if (c.reverseScroll) {
      this.infiniteParams.renderInReverse = this.reverseScroll;
    }
    if (!!c.items || !!c.supporting_item_data) {
      this.reloadVisibleItems(true);
      this._initToggledItemMap();
      this._checkScrollContainerHeight();
      this.scroll_initialised = false;
    }
    if (!!c.cacheId) {
      this.cacheScrollPosition(c.cacheId.previousValue);
    }
    if (
      !!c.itemType ||
      !!c.itemTypePlural ||
      !!c.listEmptyHeader ||
      !!c.listEmptyDescription ||
      !!c.listEmptyButtonContent
    ) {
      this._updateListEmptyConfig();
    }
    if (!!c.infinite_scroll_distance_px) {
      this._updateInfiniteScrollDistance();
    }
  }

  ngAfterViewInit() {
    // ngAfterViewChecked sets this.scroll_initialised = true when this.visible_items is long enough to require a scrollable list
    // The below line acts a fallback for lists that aren't long enought to require a scrollable list
    setTimeout(() => {
      this.scroll_initialised = true;
      this._checkScrollContainerHeight();
    });
  }

  // Set the inital scroll position
  ngAfterViewChecked(): void {
    this._checkComponentVisibility();

    if (!this.scroll_initialised) {
      this._scrollToDefaultPosition();
    }
  }

  private _scrollToDefaultPosition() {
    const element_ref = this.renderingStrategy === 'VIRTUAL' ? this.scroll_outer_viewport?.elementRef : this.scroll_outer_elem;
    const element_scroll_height = element_ref?.nativeElement.scrollHeight;
    const element_height = element_ref?.nativeElement.offsetHeight;

    if (element_scroll_height > element_height) {
      const default_scroll_top = this.reverseScroll ? element_scroll_height : 0;
      const scroll_position = this._getCachedScrollPosition();
      const scroll_top = scroll_position?.scroll_top || default_scroll_top;

      this._scrollTo(scroll_top);
      this.scroll_initialised = true;
    }
  }

  private _checkComponentVisibility() {
    // offsetParent is null when component or a parent component is hidden
    const component_visible = !!this.elementRef.nativeElement.offsetParent;
    if (this._component_visible !== component_visible) {
      this._component_visible = component_visible;

      if (this._component_visible) {
        this.scroll_initialised = false;
        setTimeout(() => this._updateInfiniteScrollDistance());
      }
    }
  }

  private _initToggledItemMap() {
    if (this.itemToggleVisible) {
      this.toggled_item_map = this._getCachedToggledItemMap();
      this._checkAllItemsToggled();
      this._itemToggled();
    }
  }

  toggleItem(event: MouseEvent, item: any) {
    if (!!event) event.stopPropagation();
    const toggle_id = item[this.itemToggleIdProperty] || null;

    if (!!toggle_id) {
      this.toggled_item_map[toggle_id] = !this.toggled_item_map[toggle_id];
    }

    this._checkAllItemsToggled();
    this._itemToggled();
  }

  toggleAllItems() {
    // Toggle all visible items
    if (this.all_items_toggled) {
      for (const item of this.items) {
        if (this.itemIsVisible(item)) {
          this.toggled_item_map[item[this.itemToggleIdProperty]] = true;
        }
      }
    }
    // Untoggle all items by clearing the toggle map
    else {
      this.toggled_item_map = {};
    }

    this._itemToggled();
  }

  clearAllToggledItems() {
    this.toggled_item_map = {};
    this._checkAllItemsToggled();
    this._itemToggled();
  }

  private _checkAllItemsToggled() {
    let all_items_toggled = true;

    for (const item of this.items) {
      if (
        this.itemIsVisible(item) &&
        !this.toggled_item_map[item[this.itemToggleIdProperty]]
      ) {
        all_items_toggled = false;
      }
    }

    this.all_items_toggled = all_items_toggled;
  }

  private _itemToggled() {
    this.cacheToggledItemMap();
    // Wrapped in timeout to help avoid ExpressionChangedAfterItHasBeenCheckedError errors
    setTimeout(() => {
      this.itemToggled.emit({ toggle_count: this.getToggledItemCount() });
    });
  }

  getToggledItems(): any[] {
    if (this.itemToggleVisible && this.itemToggleIdProperty) {
      return _.filter(this.items, (item) => !!this.toggled_item_map[item[this.itemToggleIdProperty]]);
    }
    return [];
  }

  getToggledItemCount() {
    return this.getToggledItems().length;
  }

  reloadVisibleItems(list_init: boolean = false) {
    switch (this.renderingStrategy) {
      case 'VIRTUAL': {
        this._reloadVirtualScroll(list_init);
        break;
      }
      case 'INFINITE': {
        this._reloadInfiniteScroll(list_init);
        break;
      }
    }

    this.listEmpty = this.items.length === 0 || this.visible_items.length === 0;
    setTimeout(() => this._updateInfiniteScrollDistance());
  }

  // load_cached_scroll_position should only be true each time this._items is first set
  // ie. we're note expecting to reload a cached scroll position after
  // filtering/searching on an existing this._items dataset
  private _reloadVirtualScroll(list_init: boolean = false) {
    const items = this._getSortedItems(list_init);
    const visible_items = [];

    for (const item of items) {
      if (this.itemIsVisible(item)) {
        visible_items.push(item);
      }
    }
    this.visible_items = visible_items;
  }

  private _reloadInfiniteScroll(list_init: boolean = false) {
    CoreUtilService.reloadVisibleItems(
      this.infiniteParams,
      this.visible_items,
      this._getSortedItems(list_init),
      (item: any) => this.itemIsVisible(item)
    );
  }

  loadMoreVisibleItems() {
    if (this.renderingStrategy === 'INFINITE') {
      CoreUtilService.loadMoreVisibleItems(
        this.infiniteParams,
        this.visible_items,
        this._getSortedItems(),
        (item: any) => this.itemIsVisible(item)
      );
    }
    setTimeout(() => this._updateInfiniteScrollDistance());
  }

  scrolledToBottom() {
    if (!this.reverseScroll) {
      this.loadMoreOnScrollEnd();
    }
  }

  scrolledToTop() {
    if (this.reverseScroll) {
      this.loadMoreOnScrollEnd();
    }
  }

  shouldLoadMoreItems(): boolean {
    return this.itemLoadStrategy === 'ASYNCRONOUS' &&
      // -10 acts as a bit of buffer.
      // If we're nearly at the end of all items, then it's
      // probably worth trying to load more from the network now
      this.visible_items.length >= (this.items.length - 10);
  }

  private loadMoreOnScrollEnd() {
    if (this.shouldLoadMoreItems()) {
      this.loadMoreItemsAsync.emit();
    }
    else {
      this.loadMoreVisibleItems();
    }
  }

  tableHeaderClicked(property: string = null) {
    if (!!property && !this.sortDisabled) {
      // Wouldn't make sense to be using reverseScroll when headers are used
      this._scrollTo(0);
      this.forwardOrder = this.primarySortProperty === property ? !this.forwardOrder : true;

      this.primarySortProperty = property;

      this.reloadVisibleItems();
    }
  }

  private _getSortedItems(list_init: boolean = false): any[] {
    if (list_init) {
      this.primarySortProperty = this.primarySortProperty || this._getFirstSortProperty();
      const scroll_position = this._getCachedScrollPosition();

      if (!!scroll_position) {
        this.primarySortProperty = scroll_position.primary_sort_property;
        this.secondarySortProperty = null;
        this.forwardOrder = scroll_position.forward_order;
      }
    }

    return this.primarySortProperty && !this.sortDisabled ?
      SortUtilService.sortList(
        this.items,
        {
          primary_sort_property: this.primarySortProperty,
          secondary_sort_property: this.secondarySortProperty,
          end_sort_property: this.endSortProperty,
          forward_order: this.forwardOrder,
          list_primary_key: this.supporting_key_property,
          supporting_datasets: this.supporting_item_datasets
        }
      ) :
      this.items;
  }

  selectItem(item: any) {
    if (
      this.itemSelectDisabled ||
      (!!this.itemSelectEnabledProperty && !item[this.itemSelectEnabledProperty]) ||
      (!!this.itemSelectDisabledProperty && !!item[this.itemSelectDisabledProperty])
    ) {
      return;
    }
    this.itemSelected.emit({ item });
  }

  deleteItem(event: any, item: any) {
    if (!this.itemDeleteDisabled && (!this.itemDeleteHiddenProperty || !item[this.itemDeleteHiddenProperty])) {
      event.stopPropagation();
      this.itemDeleted.emit({ item });
    }
  }

  addItem(event: any, item: any) {
    if (!this.itemAddDisabled) {
      event.stopPropagation();
      this.itemAdded.emit({ item });
    }
  }

  private _scrollTo(scroll_top: number) {
    const element_ref = this.renderingStrategy === 'VIRTUAL' ? this.scroll_outer_viewport?.elementRef : this.scroll_outer_elem;
    element_ref.nativeElement.scrollTop = scroll_top;
  }

  // scrolls infinite scroll to top of list
  scrollToTop() {
    this.reverseScroll ? this._scrollTo(this.scroll_outer_elem?.nativeElement?.scrollHeight)
      : this._scrollTo(0);
  }

  private _getFirstSortProperty() {
    if (this.tableHeaders?.length) {
      for (const header of this.tableHeaders) {
        if (header.property) {
          return header.property;
        }
      }
    }
    return null;
  }

  cacheToggledItemMap() {
    if (
      this.itemToggleVisible &&
      !!this.itemToggleIdProperty &&
      !this.itemToggleCachingDisabled
    ) {
      const cache_id = this.cacheId || this.router.url;

      const cached_maps: Record<string, ToggledItemMap> = this.stateDataService.getCachedComponentSessionData(
        'AppListComponent',
        'toggled_item_maps'
      ) || {};
      cached_maps[cache_id] = this.toggled_item_map;

      this.stateDataService.cacheComponentSessionData(
        'AppListComponent',
        'toggled_item_maps',
        cached_maps
      );
    }
  }

  private _getCachedToggledItemMap() {
    if (
      this.itemToggleVisible &&
      !!this.itemToggleIdProperty &&
      !this.itemToggleCachingDisabled
    ) {
      const cache_id = this.cacheId || this.router.url;

      const cached_maps: ToggledItemMap = this.stateDataService.getCachedComponentSessionData(
        'AppListComponent',
        'toggled_item_maps'
      ) || {};

      return cached_maps[cache_id] || {};
    }
    return {};
  }

  // Caching scroll position only works with the VIRTUAL scroll strategy
  cacheScrollPosition(cache_id: string = this.cacheId) {
    if (
      !!this.items &&
      !this.scrollPositionCachingDisabled &&
      this.renderingStrategy === 'VIRTUAL' &&
      !!this.scroll_outer_viewport
    ) {
      const scroll_top = parseInt(this.scroll_outer_viewport.elementRef.nativeElement.scrollTop as any);
      cache_id = cache_id || this.router.url;

      const cached_positions: Record<string, ScrollPosition> = this.stateDataService.getCachedComponentSessionData(
        'AppListComponent',
        'scroll_positions'
      ) || {};

      const scroll_position: ScrollPosition = {
        scroll_top,
        primary_sort_property: this.primarySortProperty,
        forward_order: this.forwardOrder
      };

      cached_positions[cache_id] = scroll_position;

      this.stateDataService.cacheComponentSessionData(
        'AppListComponent',
        'scroll_positions',
        cached_positions
      );
    }
  }

  private _getCachedScrollPosition(): ScrollPosition {
    if (
      !this.scrollPositionCachingDisabled &&
      this.renderingStrategy === 'VIRTUAL'
    ) {
      const cache_id = this.cacheId || this.router.url;

      const cached_positions = this.stateDataService.getCachedComponentSessionData(
        'AppListComponent',
        'scroll_positions'
      ) || {};

      return cached_positions[cache_id] || null;
    }
    return null;
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.router.events
        .pipe(filter((event: RouterEvent) => event instanceof NavigationStart))
        .subscribe(() => this.cacheScrollPosition())
    );

    this.event_subscriptions.push(
      DomService.resizeObservable(this.elementRef.nativeElement, 100)
        .subscribe(() => {
          this.scroll_outer_viewport?.checkViewportSize();
        })
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private _updateListEmptyConfig() {
    this.list_empty_config = {
      header: this.listEmptyHeader !== null ? this.listEmptyHeader : this._getDefaultListEmptyHeader(),
      description: this.listEmptyDescription !== null ? this.listEmptyDescription : this._getDefaultListEmptyDescription(),
      button_content: this.listEmptyButtonContent !== null ? this.listEmptyButtonContent : this._getDefaultListEmptyButtonContent()
    };
  }

  private _getDefaultListEmptyHeader(): string {
    switch (CoreUtilService.app_theme) {
      case '-theme-invoxy':
        return null;
      case '-theme-karmly-dark':
      case '-theme-karmly-light':
        return 'No ' + this.itemTypePlural;
    }
  }

  private _getDefaultListEmptyDescription(): string {
    switch (CoreUtilService.app_theme) {
      case '-theme-invoxy':
        return 'No ' + this.itemTypePlural + ' to show';
      case '-theme-karmly-dark':
      case '-theme-karmly-light':
        return 'No ' + this.itemTypePlural + ' to show';
    }
  }

  private _getDefaultListEmptyButtonContent(): string {
    switch (CoreUtilService.app_theme) {
      case '-theme-invoxy':
        return this.showListEmptyButton ? 'New  ' + this.itemType : null;
      case '-theme-karmly-dark':
      case '-theme-karmly-light':
        return this.showListEmptyButton ? 'New  ' + this.itemType : null;
    }
  }

  private _checkScrollContainerHeight() {
    setTimeout(() => {
      const scroll_container_ref = this.renderingStrategy === 'VIRTUAL' ? this.scroll_outer_viewport?.elementRef : this.scroll_outer_elem;

      const list_container_class = this.renderingStrategy === 'VIRTUAL' ? '.cdk-virtual-scroll-content-wrapper' : '.app-list-scrollInner';
      const list_container = this.elementRef.nativeElement.querySelectorAll(list_container_class).item(0);

      this.list_taller_than_scroll_container = list_container?.offsetHeight > scroll_container_ref?.nativeElement.offsetHeight;
    });
  }

  private _updateInfiniteScrollDistance() {
    const scroll_container_ref = this.renderingStrategy === 'VIRTUAL' ? this.scroll_outer_viewport?.elementRef : this.scroll_outer_elem;
    const scroll_height_px = scroll_container_ref?.nativeElement.scrollHeight;

    if (!!scroll_height_px) {
      const distance = CoreUtilService.roundNumber((10 / (scroll_height_px / this.infinite_scroll_distance_px)), 2);
      this.infinite_scroll_distance = Math.min(distance, 5);
    }
  }

}
