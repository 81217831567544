import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'app-toggle',
  templateUrl: './app-toggle.component.html',
  styleUrls: ['./app-toggle.component.scss'],
  providers: [MakeProvider(AppToggleComponent)]
})
export class AppToggleComponent extends AbstractValueAccessor {

  @HostBinding('class.app-toggled') _value: boolean;

  get value(): any {
    if (this.invertModel){
      return !this._value;
    }
    else {
      return this._value;
    }
  }
  set value(v: any) {
    if (this.invertModel){
      super.value = !v;
    }
    else {
      super.value = v;
    }
  }

  @Input() disableInternalToggle: boolean = false;
  @Input() trueValueColour: string = null;
  @Input() falseValueColour: string = null;
  @Input() invertModel: boolean = false;

  @HostListener('click', ['$event'])
  handleClick() {
    if (!this.disableInternalToggle) {
      this.value = !this.value;
    }
  }

}
