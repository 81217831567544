import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ThrottledScrollListenerDirective } from './throttled-scroll-listener/throttled-scroll-listener.directive';
import { HighlightOnClickDirective } from './highlight-on-click/highlight-on-click.directive';
import { FileReaderDirective } from './file-reader/file-reader.directive';
import { TextareaAutoresizeDirective } from './textarea-autoresize/textarea-autoresize.directive';
import { AnimationDisabledOnInitDirective } from './animation-disabled-on-init/animation-disabled-on-init.directive';
import { ComponentLoaderDirective } from './component-loader/component-loader.directive';
import { ProductGraphicDirective } from './product-graphic/product-graphic.directive';

@NgModule({
  declarations: [
    ThrottledScrollListenerDirective,
    HighlightOnClickDirective,
    FileReaderDirective,
    TextareaAutoresizeDirective,
    AnimationDisabledOnInitDirective,
    ComponentLoaderDirective,
    ProductGraphicDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ThrottledScrollListenerDirective,
    HighlightOnClickDirective,
    FileReaderDirective,
    TextareaAutoresizeDirective,
    AnimationDisabledOnInitDirective,
    ComponentLoaderDirective,
    ProductGraphicDirective
  ]
})
export class LibDirectivesModule { }
