<ng-template #popoverContent>
  <div [innerHTML]="tooltip_content"></div>
  <div class="tooltipPopover-buttonRow"
       *ngIf="manual_trigger_flag && show_button_flag">
    <button class="-color-default"
            (click)="close()">
      Ok
    </button>
  </div>
</ng-template>

<div #tooltip="ngbTooltip"
     [ngbTooltip]="popoverContent"
     [triggers]="manual_trigger_flag ? 'manual' : ('touchend:touchend mouseenter:mouseleave')"
     [autoClose]="manual_trigger_flag ? 'outside' : true"
     [placement]="placement || 'auto'"
     [container]="'body'"
     [animation]="!animation_disabled"
     [tooltipClass]="'tooltipPopover ' + (light_theme_flag ? 'tooltipPopover-light' : (workload_tooltips ? 'workloadTooltips': '')) +
                    (!!placement ? '-verboseTooltip' : '')"
     (shown)="tooltipOpened()">
</div>
