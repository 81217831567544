<div class="app-tabs-tab"
     *ngFor="let tab of tabs"
     [ngClass]="{ 
       '-selected': selectedTab?.value === tab.value, 
       '-disabled': tab.disabled 
     }"
     (click)="selectTab(tab)">
  <div class="app-tabs-tabLabel">{{tab.label}}</div>
  <div class="app-tabs-tabHighlighter">
  </div>
</div>