import { Inject, Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Subject, Subscription } from 'rxjs';
import _ from 'lodash-es';

import {
  SocketEventType,
  SocketEvent
} from '../../lib.types';

import { AuthService } from './../auth/auth.service';
import { CoreUtilService } from '../core-util/core-util.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  readonly api_url: string;

  socket: Socket = null;

  socket_events: Record<SocketEventType, Subject<any>> = {
    report_updated: new Subject(),
    report_deleted: new Subject(),
    integration_batch_start: new Subject(),
    integration_batch_complete: new Subject(),
    NOTIFICATION: new Subject(),
    INVOICE_BATCH_COMPLETE: new Subject(),
    INVOICE_BATCH_ERROR: new Subject(),
    INVOICE_SEND_COMPLETE: new Subject(),
    INVOICE_SEND_ERROR: new Subject(),
    INVOICE_CREATED: new Subject(),
    INVOICE_XERO_ERROR: new Subject(),
    PROJECT_TASK_FEED: new Subject(),
    PROJECT_TASK_FEED_DELETED: new Subject(),
    SEGMENTS_UPDATED: new Subject(),
    SEGMENTS_DELETED: new Subject(),
    PROJECT_UPDATED: new Subject(),
    INTEGRATION_CONNECTED: new Subject(),
    INTEGRATION_CONNECTION_ERROR: new Subject(),
    INTEGRATION_DISCONNECTED: new Subject(),
    INVOXY_INTEGRATION_UPDATED: new Subject(),
    CLOCK_UPDATED: new Subject(),
    COWORKER_PROJECT_TASK_UPDATED: new Subject(),
    COWORKER_ACTIVE_UPDATED: new Subject(),
    COWORKERS_UPDATED: new Subject()
  };

  constructor(
    @Inject('env') public env: any,
    private authService: AuthService
  ) {
    this.api_url = env.node_url || env.api_url;
  }

  initWebSocket() {
    if (!this.socket) {
      this.socket = io(
        this.api_url,
        {
          query: this.authService.getHttpHeader(false, false),
          transports: ['websocket', 'polling']
        }
      );

      this.socket.on('connect', () => this._handleConnect());
      this.socket.on('message', (event: SocketEvent) => this._handleMessage(event));
      this.socket.on('disconnect', (event: any) => this._handleClose(event));
      this.socket.on('error', (event: any) => this._handleError(event));
    }
  }

  closeSocket() {
    this.socket?.disconnect();
    this.socket = null;
  }

  subscribeToEvent(
    event_type: SocketEventType,
    callback: (event_data: any) => void
  ): Subscription {
    if (!!this.socket_events[event_type]) {

      return this.socket_events[event_type]
        .asObservable()
        .subscribe((event_data) => callback(event_data));
    }
  }

  private _handleConnect() {
  }

  private _handleMessage(event: SocketEvent) {
    const event_data = CoreUtilService.parseJSON(event?.event_data);
    this.socket_events[event.event_type]?.next(event_data);
  }

  private _handleClose(event: any) {
    console.log('Socket Close', event);
  }

  private _handleError(event: any) {
    console.log('Socket Error', event);

    if (event === 'unauthorized') {
      this.closeSocket();
    }
  }

  get socket_id(): string {
    return this.socket.id;
  }

}
