<div class="app-checkbox-label"
     *ngIf="label && labelLeft">
  {{label}}
</div>

<button class="-type-icon"
        type="button"
        [ngClass]="{
          '-color-primary' : !!value,
          '-color-white-outline' : !value,
          '-size-btn-lg': !btnSmall,
          '-size-btn-sm': btnSmall,
          '-size-icon-sm': btnSmall,
          '-readonly': readonly }"
        [disabled]="disabled">
  <i class="ion-md-checkmark"
     [ngStyle]="{ 'display': !!value ? 'block' : 'none'}">
  </i>
  <tooltip-popover *ngIf="tooltip"
                   [tooltip_content]="tooltip">
  </tooltip-popover>
</button>

<div class="app-checkbox-label"
     *ngIf="label && !labelLeft">
  {{label}}
</div>
