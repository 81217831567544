import {
  Injectable,
  ComponentRef,
  ViewContainerRef,
  Inject
} from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, throttleTime, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import _ from 'lodash-es';


import { BackdropComponent, BackdropOptions } from './../../lib-components/backdrop/backdrop.component';
import { CameraComponent } from '../../lib-components/camera/camera.component';
import { NotificationPopoverComponent, NotificationPopoverType } from '../../lib-components/notification-popover/notification-popover.component';
import { CoreUtilService } from '../core-util/core-util.service';
import { ExpandingCanvasWrapperComponent, ExpandingCanvasOptions } from '../../lib-modals/expanding-canvas-wrapper/expanding-canvas-wrapper.component';
import { KmConfettiComponent } from '../../lib-components/km-confetti/km-confetti.component';
import { KmCalendarEvent } from '../../../public-api';

@Injectable({
  providedIn: 'root'
})
export class DomService {

  readonly DEFAULT_MODAL_Z_INDEX = 1051;

  app_view_container_ref: ViewContainerRef = null;

  confetti_ref: ComponentRef<KmConfettiComponent> = null;
  camera_ref: ComponentRef<CameraComponent> = null;
  offcavas_ref: NgbOffcanvasRef = null;

  backdrop_refs: Record<string, ComponentRef<BackdropComponent>> = {};
  expanding_canvas_refs: Record<string, ComponentRef<ExpandingCanvasWrapperComponent>> = {};

  notification_popover_ref: ComponentRef<NotificationPopoverComponent> = null;

  constructor(
    @Inject('env') public env: any,
    public router: Router,
    public ngbOffcanvas: NgbOffcanvas
  ) {
    this._destroyComponentsOnRouteChange();
  }

  // Required to be called by app.component.ts on init for DomService to work
  initAppViewContainerRef(app_view_container_ref: ViewContainerRef) {
    this.app_view_container_ref = app_view_container_ref;
  }

  // Destroy Component ////////////////////////////////////
  closeCamera() {
    this._destroyComponent(this.camera_ref);
    this.camera_ref = null;
  }

  closeOffCanvas() {
    this.offcavas_ref?.close();
    this.offcavas_ref = null;
  }

  closeConfetti() {
    this._destroyComponent(this.confetti_ref);
    this.confetti_ref = null;
  }

  // Create Component /////////////////////////////////////
  openCamera(
    squareImage: boolean = false,
    selfieCamera: boolean = true
  ): Promise<Blob> {
    return new Promise<Blob>((resolve) => {
      if (!this.camera_ref) {
        this.camera_ref = this.app_view_container_ref.createComponent(CameraComponent);

        // Inputs
        this.camera_ref.instance.squareImage = squareImage;
        this.camera_ref.instance.selfieCamera = selfieCamera;

        // Outputs
        this.camera_ref.instance.cameraCancelled.subscribe(() => {
          this.closeCamera();
          resolve(null);
        });
        this.camera_ref.instance.cameraConfirmed.subscribe((image: Blob) => {
          this.closeCamera();
          resolve(image);
        });
      }
    });
  }


  openConfetti() {
    if (!this.confetti_ref) {
      this.confetti_ref = this.app_view_container_ref.createComponent(KmConfettiComponent);
      this.confetti_ref.instance.confettiFinished.subscribe(() => this.closeConfetti());
    }
  }

  openNotificationPopover(
    notification_message: string,
    notification_type: NotificationPopoverType = null,
    timeout_flag: boolean = true,
    custom_timeout: number = null,
    is_html: boolean = false
  ) {
    if (!this.notification_popover_ref) {
      this.notification_popover_ref = this.app_view_container_ref.createComponent(NotificationPopoverComponent);
    }

    const timeout = custom_timeout || (timeout_flag ? 5000 : null);
    this.notification_popover_ref.instance.addNotification(
      notification_message,
      notification_type,
      is_html,
      timeout
    );
  }

  openMobileDropdown(
    content: any,
    position: ('start' | 'end' | 'top' | 'bottom') = 'bottom',
    inputs: Record<string, any> = {},
    outputs: Record<string, (...args: any) => any> = {}
  ): void {
    this.openOffCanvas(
      content,
      position,
      inputs,
      outputs
    ).catch(() => { });
  }

  openOffCanvas(
    content: any,
    position: ('start' | 'end' | 'top' | 'bottom') = 'bottom',
    inputs: Record<string, any> = {},
    outputs: Record<string, (...args: any) => void> = {}
  ): Promise<any> {
    if (!this.offcavas_ref) {
      this.offcavas_ref = this.ngbOffcanvas.open(
        content,
        {
          position,
          keyboard: true
        }
      );

      if (!!inputs) {
        for (const input_key of Object.keys(inputs)) {
          this.offcavas_ref.componentInstance[input_key] = inputs[input_key];
        }
      }
      if (!!outputs) {
        for (const output_key of Object.keys(outputs)) {
          this.offcavas_ref.componentInstance[output_key].subscribe((event: any) => {
            outputs[output_key](event);
            this.offcavas_ref.close();
          });
        }
      }

      this.offcavas_ref.dismissed.subscribe(() => this.offcavas_ref = null);
      this.offcavas_ref.closed.subscribe(() => this.offcavas_ref = null);
    }
    return this.offcavas_ref.result;
  }

  openExpandingCanvas(
    component: any,
    component_properties: Record<string, any> = {},
    options: ExpandingCanvasOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {

      options = options || {};
      options.z_index = this.DEFAULT_MODAL_Z_INDEX + (Object.keys(this.expanding_canvas_refs).length * 2);

      const backdrop_ref = this.openBackdrop(
        () => expanding_canvas_ref.instance.triggerDismiss(),
        {
          force_dark_backdrop: true,
          backdrop_class: options?.backdrop_class || null,
          z_index: options.z_index - 1
        }
      );

      const expanding_canvas_ref_id = this._generateComponentRefId(this.backdrop_refs);
      const expanding_canvas_ref = this.app_view_container_ref.createComponent(ExpandingCanvasWrapperComponent);

      this.expanding_canvas_refs[expanding_canvas_ref_id] = expanding_canvas_ref;

      expanding_canvas_ref.instance.component = component;
      expanding_canvas_ref.instance.component_properties = component_properties;
      expanding_canvas_ref.instance.options = options;

      expanding_canvas_ref.instance.close.subscribe((result) => {
        this._destroyExpandingCanvas(expanding_canvas_ref_id);
        backdrop_ref.close(true);
        resolve(result || null);
      });
      expanding_canvas_ref.instance.dismiss.subscribe(() => {
        this._destroyExpandingCanvas(expanding_canvas_ref_id);
        backdrop_ref.close(true);
        reject();
      });
    });
  }

  closeAllExpandingCanvases() {
    for (const expanding_canvas_ref_id of Object.keys(this.expanding_canvas_refs)) {
      this._destroyExpandingCanvas(expanding_canvas_ref_id);
    }
  }

  private _destroyExpandingCanvas(expanding_canvas_ref_id: string) {
    if (!!this.expanding_canvas_refs[expanding_canvas_ref_id]) {
      this._destroyComponent(this.expanding_canvas_refs[expanding_canvas_ref_id]);
      delete this.expanding_canvas_refs[expanding_canvas_ref_id];
    }
  }

  openBackdrop(
    backdropClicked: () => void = null,
    options: BackdropOptions = null
  ): BackdropComponent {
    const backdrop_ref_id = this._generateComponentRefId(this.backdrop_refs);
    const backdrop_ref = this.app_view_container_ref.createComponent(BackdropComponent);

    this.backdrop_refs[backdrop_ref_id] = backdrop_ref;

    // Inputs
    backdrop_ref.instance.options = options;

    // Outputs
    backdrop_ref.instance.backdropClicked.subscribe((res) => {
      const ignore_callback = res?.ignore_callback || false;

      if (backdropClicked && !ignore_callback) {
        backdropClicked();
      }
      this._destroyBackdrop(backdrop_ref_id);
    });
    return backdrop_ref.instance;
  }

  closeAllBackdrops() {
    for (const backdrop_ref_id of Object.keys(this.backdrop_refs)) {
      this._destroyBackdrop(backdrop_ref_id);
    }
  }

  private _destroyBackdrop(backdrop_ref_id: string) {
    if (!!this.backdrop_refs[backdrop_ref_id]) {
      this._destroyComponent(this.backdrop_refs[backdrop_ref_id]);
      delete this.backdrop_refs[backdrop_ref_id];
    }
  }

  // Ensures that the youtube api is only ever loaded once
  // Used for rendering youtube videos in the youtube-player component
  static ensureYouTubeApiLoaded() {
    const id = 'youtube-api';

    if (document.getElementById(id) === null) {
      const script = document.createElement('script');
      script.id = id;
      script.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(script);
    }
  }

  // Ensures that the viemo api is only ever loaded once
  // Used for rendering viemo videos in an iframe
  static ensureVimeoApiLoaded() {
    const id = 'vimeo-api';

    if (document.getElementById(id) === null) {
      const script = document.createElement('script');
      script.id = id;
      script.src = 'https://player.vimeo.com/api/player.js';
      document.body.appendChild(script);
    }
  }

  static resizeObservable(element: any, throttle_time: number = 1000): Observable<ResizeObserverEntry[]> {
    return new Observable(subscriber => {
      const ro = new ResizeObserver(entries => {
        subscriber.next(entries);
      });

      ro.observe(element);

      return () => ro.disconnect();
    })
      .pipe(
        throttleTime(throttle_time),
        tap((ev: ResizeObserverEntry[]) => ev)
      );
  }

  private _destroyComponentsOnRouteChange() {
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.closeCamera();
        this.closeAllBackdrops();
        this.closeOffCanvas();
      });
  }

  ////////////////////////////////////////////////////////////

  // Ensures we generate a unique int
  private _generateComponentRefId(component_ref_map: Record<number, ComponentRef<any>>): string {
    let component_ref_id = CoreUtilService.getRandomInt(0, 99999);

    while (!!component_ref_map[component_ref_id]) {
      component_ref_id = CoreUtilService.getRandomInt(0, 99999);
    }

    return component_ref_id + '';
  }

  private _destroyComponent(component_ref: ComponentRef<any>) {
    if (!!component_ref) {
      const index = this.app_view_container_ref.indexOf(component_ref.hostView);
      this.app_view_container_ref.remove(index);
    }
  }

  static get is_mobile(): boolean {
    return window.innerWidth <= 850;
  }

}
