<div class="ldd-container"
     ngbDropdown
     #ngb_dropdown="ngbDropdown"
     *ngIf="!is_mobile"
     [id]="'ngbDropDown_' + dropdown_id"
     [title]="button_title || ''"
     [placement]="placement"
     [container]="disable_body_append ? null : 'body'"
     [autoClose]="'outside'"
     (openChange)="dropdownToggled($event)">

  <div class="ldd-buttonContainer"
       ngbDropdownAnchor>
    <ng-container *ngTemplateOutlet="ldd_button">
    </ng-container>
  </div>

  <div ngbDropdownMenu>
    <ng-container *ngTemplateOutlet="ldd_content"></ng-container>
  </div>
</div>

<div class="ldd-container"
     *ngIf="is_mobile">
  <div class="ldd-buttonContainer">
    <ng-container *ngTemplateOutlet="ldd_button">
    </ng-container>
  </div>
</div>

<ng-template #ldd_button>
  <button class="ldd-button"
          tabindex="-1"
          [ngClass]="[ button_type_class || '', (is_open ? button_toggled_color_class || button_color_class : button_color_class) ]"
          [style.text-transform]="button_title_uppercase ? 'uppercase' : ''"
          [disabled]="disabled || readonly || (no_visible_items && !dynamic_creation_enabled)"
          (click)="dropdownToggleClicked()">

    <div class="button-animatedIcon -buttonIconAnimation-spinWhenActivated -alignLeft"
         *ngIf="button_icon_class && button_icon_align_left"
         [ngClass]="button_toggled_icon_class && is_open ? '-activated' : ''">
      <i class="ion -iconInactive"
         [ngClass]="[button_icon_class]">
      </i>
      <i class="ion -iconActive"
         *ngIf="button_toggled_icon_class"
         [ngClass]="[button_toggled_icon_class]">
      </i>
    </div>

    <div class="buttonColor"
         *ngIf="button_fill_color && button_type_class === '-type-colourpicker'"
         [style.background-color]="button_fill_color"
         [style.border]="button_fill_color ? '' : '1px solid #dedede'">
    </div>

    <user-icon class="-user-icon"
               *ngIf="!!list_show_user_icons && !!button_title"
               [display_image]="button_user_url"
               [display_name]="button_title"
               [placeholder_color]="'var(--app-color-grey-light)'"
               [text_color]="'var(--app-color-grey-dark)'">
    </user-icon>

    <item-bullet *ngIf="!!button_bullet_colour"
                 [bullet_colour]="button_bullet_colour"
                 [bullet_type]="button_bullet_type">
    </item-bullet>

    <div *ngIf="button_show_completed_icon">
      <i class="ion ion-ios-checkmark-circle completedIcon">
      </i>
    </div>

    <span class="-textPrefix"
          *ngIf="button_title_prefix">
      {{button_title_prefix}}
    </span>
    <span class="-title"
          *ngIf="button_title || button_title_placeholder"
          [style.margin-right]="button_description ? 0 : ''"
          [style.font-size]="!!active_node.config.font_size ? active_node.config.font_size + 'px' : ''"
          [class.-titlePlaceholder]="(!button_title && !!button_title_placeholder) || (!!button_title_placeholder && button_title === button_title_placeholder)">
      <span>{{button_title || button_title_placeholder}}</span>
      <span *ngIf="button_description"
            class="-description">
        {{button_description}}
      </span>
    </span>

    <div class="button-animatedIcon -buttonIconAnimation-spinWhenActivated"
         *ngIf="button_icon_class && !button_icon_align_left"
         [ngClass]="button_toggled_icon_class && is_open ? '-activated' : ''">
      <i class="ion -iconInactive"
         [ngClass]="[button_icon_class]">
      </i>
      <i class="ion -iconActive"
         *ngIf="button_toggled_icon_class"
         [ngClass]="[button_toggled_icon_class]">
      </i>
    </div>

    <tooltip-popover *ngIf="!!button_tooltip"
                     [tooltip_content]="button_tooltip">
    </tooltip-popover>
  </button>
</ng-template>

<ng-template #ldd_content>
  <div class="ldd-listContainer"
       [ngStyle]="{
          'min-width': is_mobile ? '' : list_width,
          'max-width': is_mobile ? '' : list_width }">
    <div class="ldd-listContainerInner">

      <div class="ldd-header"
           *ngIf="search_visible || !!parent_node">

        <div class="app-formItem-inputContainer">

          <button class="-color-default-hoverFill -type-icon ldd-back"
                  *ngIf="!!parent_node"
                  (click)="backToParentNode($event)">
            <i class="ion ion-ios-arrow-back"></i>
          </button>

          <ng-container *ngIf="!!parent_node">
            <div class="ldd-nestedListParent"
                 *ngIf="!dynamic_creation_item">
              {{!!parent_node.config?.label ? parent_node_selected_item[parent_node.config.label] : parent_node_selected_item}}
            </div>

            <div class="ldd-nestedListParent"
                 *ngIf="!!dynamic_creation_item">
              Select {{active_node.config.type || 'item'}} for {{root_node.config.type || 'item'}}: '{{dynamic_creation_item}}'
            </div>
          </ng-container>

          <app-search *ngIf="search_visible && !parent_node"
                      [show_input_container]="false"
                      [placeholder]="search_placeholder"
                      [(search)]="search"
                      (searchChange)="searchUpdated()"
                      (click)="$event.stopPropagation()">
          </app-search>

          <div class="app-formItem-inputContainer-border">
          </div>
        </div>
      </div>

      <div class="ldd-multiselect-header"
           *ngIf="multi_select_config?.enabled && visible_items.length">
        <div class="ldd-multiselect-count">
          {{selected_item_count}} / {{visible_items.length}} Selected
        </div>
        <div class="ldd-multiselect-toggleAll">
          <button class="-type-text -color-link-clear"
                  (click)="multiSelectToggleAll()">
            {{selected_item_count === visible_items.length ? 'Deselect' : 'Select'}} All
          </button>
        </div>
      </div>

      <div *ngIf="!!listDescription && visible_items.length"
           class="ldd-listDescription">
        {{listDescription}}
      </div>

      <div class="ldd-list">

        <li class="ldd-item -dynamicItemCreator"
            *ngIf="dynamic_creation_enabled && !dynamic_creation_item && !!search"
            ngbDropdownItem
            (click)="selectDynamicCreationItem()">
          <div class="ldd-itemInner">
            <div class="ldd-itemLeft">
              <div class="ldd-itemLeftInner">

                <div class="itemName">
                  <span>Create {{active_node.config.type || 'item'}}: '<b>{{search}}</b>'</span>
                </div>

              </div>
            </div>
            <div class="ldd-itemRight">
              <i class="ion ion-ios-arrow-forward"
                 *ngIf="dynamic_creation_child_items?.length > 1">
              </i>
            </div>
          </div>
        </li>

        <li class="ldd-item"
            *ngIf="!!default_item && !search && !dynamic_creation_item"
            ngbDropdownItem
            [ngClass]="{
              '-itemSelected': highlight_item_matching_title && !!button_title && (!!active_node.config.label ? button_title === default_item[active_node.config.label] : button_title === default_item)
            }"
            (click)="selectDefaultItem()">
          <div class="ldd-itemInner">
            <div class="ldd-itemLeft">
              <item-bullet *ngIf="!!default_item[active_node.config.bullet_colour]"
                           [bullet_colour]="default_item[active_node.config.bullet_colour]"
                           [bullet_type]="default_item[active_node.config.bullet_type] || list_bullet_type">
              </item-bullet>

              <div class="ldd-itemLeftInner">
                <div class="itemName"
                     [style.margin-left]="!!default_item[active_node.config.bullet_colour] ? '10px' : ''"
                     [style.font-size]="!!active_node.config.font_size ? active_node.config.font_size + 'px' : ''">
                  <span>{{!!active_node.config.label ? default_item[active_node.config.label] : default_item}}</span>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li *ngIf="(!!default_item && !search) || (dynamic_creation_enabled && !!search)"
            class="ldd-divider">
        </li>

        <ng-container *ngFor="let item of visible_items; let i = index">
          <list-drop-down-item *ngIf="!!item"
                               [class.-multiSelectItemToggled]="multi_select_config?.enabled && selected_item_ids[item[active_node.config.id]]"
                               [multi_select_toggled]="multi_select_config?.enabled && selected_item_ids[item[active_node.config.id]]"
                               [is_item]="!item.is_custom_divider"
                               [is_divider]="item.is_custom_divider"
                               [is_custom_divider]="item.is_custom_divider"
                               [divider_label]="item.divider_label"
                               [divider_bullet_colour]="item.divider_bullet_colour"
                               [divider_bullet_type]="item.divider_bullet_type"
                               [item_selected]="!!highlight_item_matching_title && !!button_title && (button_title === active_node.config.label ? item[active_node.config.label] : item)"
                               [item_highlighted]="highlighted_item_index === i"
                               [type]="active_node.config.type"
                               [type_plural]="active_node.config.type_plural"
                               [font_size]="active_node.config.font_size"
                               [label]="active_node.config.label ? item[active_node.config.label] : item"
                               [popover_content]="item[active_node.config.popover_content]"
                               [sort]="item[active_node.config.sort]"
                               [description]="item[active_node.config.description]"
                               [description_bullet_colour]="item[active_node.config.description_bullet_colour]"
                               [description_bullet_type]="item[active_node.config.description_bullet_type]"
                               [hidden]="item[active_node.config.hidden]"
                               [disabled]="item[active_node.config.disabled]"
                               [bullet_colour]="item[active_node.config.bullet_colour]"
                               [bullet_type]="item[active_node.config.bullet_type]"
                               [text_colour]="item[active_node.config.text_colour]"
                               [icon_class]="item[active_node.config.icon_class]"
                               [icon_colour]="item[active_node.config.icon_colour]"
                               [toggleable]="item[active_node.config.toggleable]"
                               [toggle_value]="item[active_node.config.toggle_value]"
                               [checkable]="item[active_node.config.checkable]"
                               [checked_value]="item[active_node.config.checked_value]"
                               [user_img_url]="item[active_node.config.user_img_url]"
                               [product]="item[active_node.config.product]"
                               [item_index]="i"
                               [visible_items_length]="visible_items.length"
                               [list_show_user_icons]="list_show_user_icons"
                               [list_bullet_type]="list_bullet_type"
                               [list_align_icon_right]="list_align_icon_right"
                               [child_node_items_length]="visible_item_child_node_item_lengths[i] || null"
                               (click)="selectItem(item)">
          </list-drop-down-item>
          <list-drop-down-item *ngIf="!item"
                               [is_divider]="true">
          </list-drop-down-item>
        </ng-container>

        <div *ngIf="!visible_items.length && !(dynamic_creation_enabled && !!search)"
             class="ldd-empty">
          No {{active_node.config.type_plural || 'items'}} found
        </div>
      </div>

      <div class="ldd-multiselect-footer"
           *ngIf="multi_select_config?.enabled && !multi_select_config?.update_on_close && visible_items.length">
        <button class="-color-success"
                [disabled]="selected_item_count === 0"
                (click)="itemsSelected()">
          <span *ngIf="multi_select_config?.button_label">
            {{multi_select_config?.button_label}}
          </span>
          <span *ngIf="!multi_select_config?.button_label">
            Add Selected {{active_node.config.type_plural || 'items'}}
          </span>
        </button>
      </div>

    </div>
  </div>
</ng-template>
