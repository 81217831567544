import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { GridsterModule } from 'angular-gridster2';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';

import { LibPipesModule } from '../lib-pipes/lib-pipes.module';
import { LibDirectivesModule } from '../lib-directives/lib-directives.module';
import { LibComponentsModule } from '../lib-components/lib-components.module';

import { AppReportEditComponent } from './app-report-edit/app-report-edit.component';
import { AppNavMenuComponent } from './app-nav-menu/app-nav-menu.component';

@NgModule({
  declarations: [
    AppReportEditComponent,
    AppNavMenuComponent
  ],
  imports: [
    LibPipesModule,
    CommonModule,
    NgbModule,
    FormsModule,
    LibDirectivesModule,
    LibComponentsModule,
    GridsterModule,
    InfiniteScrollModule,
    NgxMaskModule,
    RouterModule
  ],
  exports: [
    AppReportEditComponent,
    AppNavMenuComponent
  ]
})
export class LibRoutesModule { }
