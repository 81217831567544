//import { DpRosterDay } from '../dp-roster-day/dp-roster-day';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { DpTeam } from '../dp-team/dp-team';
import { PostableObject, PostableObjectErrorMap } from './../../lib-interfaces/postable-object.interface';

import * as _ from 'lodash-es';

export class Roster implements PostableObject<Roster> {


  roster_key: number;
  team: DpTeam;
  end_date: Date;
  additional_data: any;
  priority: string;

  //private _plan: Plan;
  private _start_date: Date;

  constructor(
    /*plan: Plan,*/ team: DpTeam, start_date: Date,
    additional_data: any = null, priority: string = 'None'
  ) {
    //this._plan = plan;
    this.team = team;
    this._start_date = start_date;
    this.additional_data = additional_data;
    this.priority = priority;
  }

  /*
  get plan(): Plan {
    return this._plan;
  }
  set plan(plan: Plan) {
    this._plan = plan;
    if (plan) {
      this._updateEndDate();
    }
  }*/

  get start_date(): Date {
    return this._start_date;
  }
  set start_date(start_date: Date) {
    this._start_date = start_date;
    /*
    if (this.plan) {
      this._updateEndDate();
    }*/
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  /*
  private _updateEndDate() {
    this.end_date = TimeUtilService.incrementDate(this._start_date, this._plan.number_of_days - 1);
  }*/

  getErrors(): PostableObjectErrorMap{
    const errors: PostableObjectErrorMap = {};

    /*
    if (!TimeUtilService.dateIsValid(this.start_date)) {
      errors.push({
        error_path: 'start_date',
        error_message: 'Start time required'
      });
    }
    if (!TimeUtilService.dateIsValid(this.end_date)) {
      errors.push({
        error_path: 'end_date',
        error_message: 'End date required'
      });
    }*/

    return errors;
  }

  formatForPosting(): any {
    if (this.hasErrors()) {
      return {
        //plan_key: this.plan.plan_key,
        team_key: this.team.team_key,
        start_date: TimeUtilService.dateToDateString(this.start_date),
      };
    }
    return null;
  }
}
