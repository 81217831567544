<i class="ion ion-md-attach"
   [ngbTooltip]="popoverContent"
   [container]="'body'"
   [triggers]="'hover'"></i>

<ng-template #popoverContent>
  <div class="attachmentPopover-container">
    <div class="attachmentPopover-header">
      <div>Timesheet Attachments</div>
    </div>
    <div class="attachmentPopover-item"
         *ngFor="let item of _timesheet_attachments">
      <div>
        {{item.timesheet_attachment_name}}
      </div>
    </div>
  </div>
</ng-template>
