import { Component, HostBinding, Inject, Input } from '@angular/core';
import { NavMenuServiceAbstract } from '../../../public-api';

@Component({
  selector: 'menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss']
})
export class MenuButtonComponent {

  @HostBinding('class.-showOnDesktop') @Input() show_on_desktop: boolean = false;

  constructor(
    @Inject('navMenuService') public navMenuService: NavMenuServiceAbstract,
  ) { }

  toggleNavMenu() {
    this.navMenuService.toggleNavMenu();
  }

}
