export type KmCountryTaxType = {
  rate: 15,
  tax_type: 'GST 15%',
  income_tax_flag: false
};

export class KmCountry {

  country_key: string;
  country_name: string;
  currency_code: string;
  localised_business_number_name: string;
  localised_tax_number_format: string;
  localised_tax_number_name: string;
  money_symbol: string;
  tax_types: KmCountryTaxType[]

  constructor(
    country_key: string,
    country_name: string,
    currency_code: string,
    localised_business_number_name: string,
    localised_tax_number_format: string,
    localised_tax_number_name: string,
    money_symbol: string,
    tax_types: KmCountryTaxType[] = []
  ) {
    this.country_key = country_key;
    this.country_name = country_name;
    this.currency_code = currency_code;
    this.localised_business_number_name = localised_business_number_name;
    this.localised_tax_number_format = localised_tax_number_format;
    this.localised_tax_number_name = localised_tax_number_name;
    this.money_symbol = money_symbol;
    this.tax_types = tax_types;
  }
}
