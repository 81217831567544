import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'completed-icon',
  templateUrl: './completed-icon.component.html',
  styleUrls: ['./completed-icon.component.scss']
})
export class CompletedIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
