<gridster *ngIf="grid_options"
          #gridsterComponent
          [options]="grid_options">
  <gridster-item *ngFor="let block of blocks; let i = index"
                 [item]="block"
                 [ngClass]="{ 'dg-block-empty': !block.block_type }">

    <report-dash-block #block_component
                       *ngIf="!!block.block_type"
                       [ngClass]="{ '-editingDisabled': !editing_enabled }"
                       [project_key]="project_key"
                       [selected_accounts]="selected_accounts"
                       [block]="block"
                       [editing_enabled]="editing_enabled"
                       (remove_block)="removeBlock(block)">
    </report-dash-block>

    <div class="rd-addReport"
         *ngIf="!block.block_type"
         [ngClass]="{ '-noBlocks': non_empty_block_count === 0 }"
         (click)="addBlock(block)">
      <i class="ion ion-ios-add"></i>&nbsp;
      <div>Add {{report_label.capitalised_plural}}</div>
      <!-- Potentially only show this icon and tooltip if there aren't any insight graphs added.
           Could also have tooltip that shows on hover over 'Add Insights' text, and not have the icon -->
      <whats-this [hover_text]="'Add new or existing ' + report_label.lowercase_plural + ' to your dashboard'"
                  [help_type]="'HOVER'"
                  [ngStyle]="{ 'padding': '1px 0 0 3px' }">
      </whats-this>
    </div>

  </gridster-item>
</gridster>
