import { GridsterItem } from 'angular-gridster2';
import _ from 'lodash-es';

import { DateTime, Duration } from 'luxon';
import { CalendarEvent } from '../../../lib-models/calendar-event/calendar-event';
import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';

export class TimeUserCalendarEvent implements GridsterItem {

  calendar_event: CalendarEvent;

  calendar_start_date: Date = null;
  calendar_end_date: Date = null;
  days_in_calendar: number = null;
  multi_day_event: boolean = false;

  // Number of minutes per row
  private _mins_per_row: number = null;
  private _rows_per_hour: number = null;

  is_locked: boolean = false;
  x_offset: number = 0;

  extension: TimeUserCalendarEventExtension = null;

  readonly cols: number = 1;
  rows: number = 0;
  x: number = -1;
  y: number = -1;
  layerIndex: number = 1;
  resizeEnabled: boolean = false;
  dragEnabled: boolean = false;

  constructor(
    calendar_event: CalendarEvent,
    calendar_start_date: Date,
    calendar_end_date: Date,
    mins_per_row: number = 15,
    x_offset: number = 0,
    layerIndex: number = 1
  ) {
    this._mins_per_row = mins_per_row;
    this._rows_per_hour = 60 / this._mins_per_row;

    this.x_offset = x_offset;
    this.layerIndex = layerIndex;

    this.calendar_start_date = TimeUtilService.getCleanDate(calendar_start_date);
    this.calendar_end_date = TimeUtilService.getCleanDate(calendar_end_date);
    this._initDaysInCalendar();

    this.calendar_event = calendar_event;

    this.multi_day_event = this._multiDayEvent();

    this._initDimensions();
    this._initCalendarEventExtension();
  }

  private _initDaysInCalendar() {
    const start = DateTime.fromJSDate(this.calendar_start_date);
    const end = DateTime.fromJSDate(this.calendar_end_date);
    this.days_in_calendar = end.diff(start, 'days').days + 1;
  }

  private _initDimensions() {
    if (this.calendar_event.overlapsPeriodOfDays(this.calendar_start_date, this.calendar_end_date)) {
      this.updateEventDimensions();
    }
  }

  private _initCalendarEventExtension() {
    if (this.multi_day_event) {
      const rows = this._getRow(this.calendar_event.end_time);
      const x = this._getCol(this.calendar_event.end_time);

      this.extension = new TimeUserCalendarEventExtension(
        rows, x, this.x_offset, this.calendar_event
      );
    }
    else {
      this.extension = null;
    }
  }

  updateEventDimensions() {
    this.x = this._getCol(this.calendar_event.start_time);
    this.y = this._getRow(this.calendar_event.start_time);
    this.rows = this._getRows();
    this._checkIsLocked();
  }

  private _getRows(): number {
    if (this.multi_day_event) {
      return Math.max(1, (1440 / this._mins_per_row) - this.y);
    }
    else {
      return Math.max(1, this._getRow(this.calendar_event.end_time) - this.y);
    }
  }

  private _getRow(time: Date): number {
    const hours = time.getHours();
    const totalMins = time.getMinutes();
    const roundedMins = totalMins - (totalMins % this._mins_per_row);
    return (hours * this._rows_per_hour) + (roundedMins / this._mins_per_row);
  }

  private _getCol(date: Date): number {
    const diff = TimeUtilService.totalDaysBetweenTwoDates(date, this.calendar_start_date, false);
    return (diff < 0 || diff >= this.days_in_calendar) ? -1 : diff;
  }

  private _checkIsLocked() {
    this.is_locked = this.rows <= 1 || this.calendar_event.is_locked;
  }

  private _multiDayEvent(): boolean {
    return !DateTime.fromJSDate(this.calendar_event.start_time).hasSame(DateTime.fromJSDate(this.calendar_event.end_time), 'day');
  }

}

export class TimeUserCalendarEventExtension implements GridsterItem {

  readonly cols: number = 1;
  readonly dragEnabled: boolean = false;
  readonly y: number = 0;
  resizeEnabled: boolean = true;
  rows: number = 0;
  x: number = -1;
  layerIndex: number = 1002;
  x_offset: number = 0;

  calendar_event: CalendarEvent;

  constructor(
    rows: number,
    x: number,
    x_offset: number,
    calendar_event: CalendarEvent,
  ) {
    this.rows = rows;
    this.x = x;
    this.x_offset = x_offset;
    this.calendar_event = calendar_event;
    this.resizeEnabled = this.x !== 0 && !this.calendar_event.is_locked;
  }

}
