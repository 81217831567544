import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ItemBulletType, ProductValue } from '../../../lib.types';

@Component({
  selector: 'list-drop-down-item',
  templateUrl: './list-drop-down-item.component.html',
  styleUrls: ['./list-drop-down-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListDropDownItemComponent {

  @HostBinding('class.ldd-item') @Input() is_item: boolean = false;
  @HostBinding('class.ldd-divider') @Input() is_divider: boolean = false;
  @HostBinding('class.-customDivider') @Input() is_custom_divider: boolean = false;
  @HostBinding('class.-itemSelected') @Input() item_selected: boolean = false;
  @HostBinding('class.-itemHighlighted') @Input() item_highlighted: boolean = false;

  @Input() multi_select_toggled: boolean = false;

  @Input() divider_label: string = null;
  @Input() divider_bullet_colour: string = null;
  @Input() divider_bullet_type: ItemBulletType = null;

  @Input() type: string = null;
  @Input() type_plural: string = null;
  @Input() font_size: number = null;
  @Input() label: string = null;
  @Input() popover_content: string = null;
  @Input() sort: boolean = false;
  @Input() description: string = null;
  @Input() description_bullet_colour: string = null;
  @Input() description_bullet_type: ItemBulletType = null;
  @HostBinding('class.-itemDisabled') @Input() disabled: boolean = false;
  @Input() bullet_colour: string = null;
  @Input() bullet_type: ItemBulletType = null;
  @Input() text_colour: string = null;
  @Input() icon_class: string = null;
  @Input() icon_colour: string = null;
  @Input() toggleable: boolean = false;
  @Input() toggle_value: boolean = false;
  @Input() checkable: boolean = false;
  @Input() checked_value: boolean = false;
  @Input() user_img_url: string = null;
  @Input() product: ProductValue;

  @Input() item_index: number;
  @Input() visible_items_length: number;
  @Input() list_show_user_icons: boolean = false;
  @Input() list_bullet_type: ItemBulletType = null;
  @Input() list_align_icon_right: boolean = true;
  @Input() child_node_items_length: number = null;

}
