import { AfterViewInit, Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[animationDisabledOnInit]'
})
export class AnimationDisabledOnInitDirective implements AfterViewInit {

  @HostBinding('style.transition') view_initialised: string = 'none';

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.view_initialised = '';
    });
  }

}
