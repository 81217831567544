import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import _ from 'lodash-es';
import { Chart, registerables, ChartType, TooltipItem, ChartConfiguration } from 'chart.js';
Chart.register(...registerables);
import 'chartjs-adapter-luxon';

import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { AppCurrencyPipe } from '../../lib-pipes/app-currency/app-currency.pipe';
import { AppNumberPipe } from '../../lib-pipes/app-number/app-number.pipe';
import { ReportChartType, ChartReportStub } from '../../lib.types';
import { ReportService } from '../../lib-services/report/report.service';
import { ChartReport } from '../../lib-models/report/chart-report/chart-report';
import { EnvService } from '../../lib-services/env/env.service';

@Component({
  selector: 'report-chart',
  templateUrl: './report-chart.component.html',
  styleUrls: ['./report-chart.component.scss']
})
export class ReportChartComponent implements OnInit, OnDestroy {

  readonly query_params = CoreUtilService.parseQueryParams(this.route.snapshot.queryParams);

  @ViewChild('reportChart') reportChart: ElementRef;

  readonly chartTypes: ReportChartType[] = ['bar', 'horizontal_bar', 'line', 'pie'];
  readonly colors = [
    '#EF9A9A', '#CE93D8', '#9FA8DA', '#81D4FA', '#80CBC4', '#C5E1A5', '#FFF59D', '#FFCC80',
    '#E53935', '#8E24AA', '#3949AB', '#039BE5', '#00897B', '#7CB342', '#FDD835', '#FB8C00'
  ];

  selectedChartType = this.chartTypes[0];
  currentState = null;
  chart: Chart = null;

  @Input() chart_title = null;
  @Input() chart_type: ReportChartType = null;
  @Input() stack_series = false;
  @Input() show_legend = false;
  @Input() dimension_data = null;
  @Input() measure_data = null;
  @Input() series_data = null;
  @Input() measure_is_currency = false;
  @Input() num_decimal_places = 0;
  @Input() randomise_colors = false;
  @Input() line_color = null;
  @Input() text_color = null;
  @Input() hide_empty_measure_data = false;
  @Input() report: (ChartReport | ChartReportStub) = null;
  @Input() time_axis: boolean = false;
  @Input() data_point_radius: number = 0;
  @Input() line_tension: number = 0.2;
  @Input() show_dimension_grid: boolean = true;
  @Input() tooltip_label: string = null;
  @Input() tooltip_show_colors: boolean = true;
  @Input() tooltip_show_zero: boolean = false; // whether to show 0 values in tooltips
  @Input() tooltip_show_title: boolean = true;
  @Input() measure_max: number = null;

  constructor(
    public route: ActivatedRoute,
    public appCurrencyPipe: AppCurrencyPipe,
    public appNumberPipe: AppNumberPipe,
    public reportService: ReportService,
    public ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.currentState = this.query_params.name;
  }

  ngOnDestroy(): void {
    this.chart?.destroy();
  }

  renderReport() {
    setTimeout(() => this.setupChart());
  }

  resizeChart() {
    setTimeout(() => this.chart?.resize());
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  setupChart() {
    try {
      if (this.hide_empty_measure_data === true) {
        const mData = [];
        const sData = [];

        if (this.series_data && this.series_data.length) {
          for (let i = 0; i < this.measure_data.length; i++) {
            let emptyData = true;

            for (let j = 0; j < this.measure_data[i].length; j++) {
              if (this.measure_data[i][j] !== 0) {
                emptyData = false;
                break;
              }
            }
            if (!emptyData) {
              mData.push(this.measure_data[i]);
              sData.push(this.series_data[i]);
            }
          }
          this.measure_data = mData;
          this.series_data = sData;
        }
      }

      // Used when generating label data to ensure that the
      // chart type used is the last chart that was actually generated.
      // Needed to stop issues that can occur with label generation if
      // the user changes the chart type but doesn't refresh the chart
      this.selectedChartType = _.cloneDeep(this.chart_type);

      const measure_data = _.cloneDeep(this.measure_data);
      const dimension_data = _.cloneDeep(this.dimension_data);
      const series_data = _.cloneDeep(this.series_data);

      const chart_data = [];
      let colors = this.randomise_colors ? this.shuffleArray(this.colors) : this.colors;

      if (series_data && series_data.length > 0) {
        // DataSets is 2D array
        let iOffset = 0;
        const borderWidth = this.chart_type === 'line' ? 2 : 0;

        for (let i = 0; i < measure_data.length; i++) {

          if (i - iOffset === colors.length) {
            iOffset += colors.length;
          }
          const col = colors[i - iOffset];

          chart_data.push({
            label: series_data[i],
            data: measure_data[i],
            backgroundColor: col,
            borderWidth: borderWidth,
            borderColor: col,
            pointHitRadius: 20
          });
        }
      }
      else {
        if (this.chart_type === 'line') {
          // use chart line color if passed in, otherwise choose random one from array
          const lineColour = this.line_color ? this.line_color : colors[Math.floor(Math.random() * colors.length)];

          chart_data.push({
            data: measure_data,
            backgroundColor: lineColour,
            borderColor: lineColour,
            borderWidth: 2,
            fill: false,
            pointHitRadius: 20
          });
        }
        else {
          // Ensures that all items in measure_data have a color assigned to them
          // If there's more than 200 items, there's probably something going wrong
          while (measure_data?.length > colors.length && colors.length < 200) {
            colors = colors.concat(colors);
          }

          chart_data.push({
            data: measure_data,
            backgroundColor: colors,
            borderWidth: 0
          });
        }
      }

      let chart_type: ChartType = null;
      let index_axis: ('x' | 'y') = 'x';

      if (this.chart_type === 'horizontal_bar') {
        chart_type = 'bar';
        index_axis = 'y';
      }
      else {
        chart_type = this.chart_type;
      }

      if (!!this.chart) {
        this.chart.destroy();
      }

      // Check to ensure that state hasn't changed between 'setupChart' being broadcast
      // and chartjs generating the chart.
      if (this.currentState === this.query_params.name) {
        // For now just manually setting the chart font the same as our global styles
        Chart.defaults.font.family = EnvService.font_family;
        Chart.defaults.font.weight = '500';

        //set text color if passed in
        if (!!this.text_color) { Chart.defaults.color = this.text_color; }

        const chart_config: ChartConfiguration = {
          type: chart_type,
          data: {
            labels: dimension_data,
            datasets: chart_data
          },
          options: {
            // interaction: {
            //   mode: 'index'
            // },
            plugins: {
              title: {
                display: !!this.chart_title,
                text: this.chart_title
              },
              legend: {
                display: this.show_legend && (series_data.length > 0 || this.chart_type === 'pie'),
                labels: {
                  usePointStyle: true,
                  boxWidth: 5
                }
              },
              tooltip: {
                mode: this._getTooltipMode(),
                intersect: true,
                position: 'nearest',
                itemSort: (a, b, item_data) => {
                  const a_val = item_data.datasets[a.datasetIndex].data[a.dataIndex] as number;
                  const b_val = item_data.datasets[b.datasetIndex].data[b.dataIndex] as number;
                  return b_val - a_val;
                },
                titleMarginBottom: 15,
                footerMarginTop: 10,
                multiKeyBackground: 'rgba(0,0,0,0)',
                bodySpacing: 4,
                padding: 10,
                usePointStyle: true,
                displayColors: this.tooltip_show_colors,
                callbacks: {
                  label: (tooltip_item) => this._createTooltipDataLine(tooltip_item, chart_type),
                  labelPointStyle: () => { return { rotation: 0, pointStyle: 'circle' }; },
                  labelColor: (tooltip_item) => this._getLabelColor(tooltip_item),
                  title: (tooltip_items) => this._createTooltipTitle(tooltip_items),
                  footer: (tooltip_items) => this._getLabelFooter(tooltip_items, chart_type, series_data)
                }
              }
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: this._getScalesOptions(),
            elements: {
              point: {
                radius: this.data_point_radius
              },
              line: {
                tension: this.line_tension
              }
            }
          }
        };

        if (chart_type === 'bar' || chart_type === 'line') {
          chart_config.options.indexAxis = index_axis;
        }

        this.ngZone.runOutsideAngular(() => this.chart = new Chart(this.reportChart.nativeElement, chart_config));

        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        });
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  private _getTooltipMode() {
    switch (this.chart_type) {
      case 'horizontal_bar':
        return 'y';
      case 'bar':
        return 'x';
      case 'line':
        return 'index';
      case 'pie':
        return 'nearest';
    }
  }

  private _getLabelColor(
    tooltipItem: TooltipItem<ChartType>
  ) {
    const backgroundColor = tooltipItem.dataset.backgroundColor instanceof Array ?
      tooltipItem.dataset.backgroundColor[tooltipItem.dataIndex] as string :
      tooltipItem.dataset.backgroundColor as string;
    return {
      borderColor: 'rgba(0, 0, 0, 0)',
      backgroundColor
    };
  }

  private _getLabelFooter(
    tooltipItems: TooltipItem<ChartType>[],
    chart_type: ChartType,
    series_data: any
  ) {
    if (series_data.length > 0 && chart_type !== 'pie') {
      let total = 0;
      for (const item of tooltipItems) {
        total += (item.raw || 0) as number;
      }
      return 'Total:  ' + this._formatTooltipLineMeasureValue(total);
    }
    return null;
  }

  private _createTooltipTitle(tooltip_items: TooltipItem<ChartType>[]) {
    if (!this.tooltip_show_title) {
      return null;
    }

    if (this.chart_type === 'pie') {
      return null;
    }
    else {
      const title = this.dimension_data[tooltip_items[0].dataIndex];

      if (!!this.report) {
        return this.reportService.formatFieldValue(
          title,
          this.report.dimension,
          true,
          this.num_decimal_places
        );
      }
      else {
        return title;
      }
    }
  }

  private _createTooltipDataLine(
    tooltipItem: TooltipItem<ChartType>,
    chart_type: ChartType
  ) {
    let value = CoreUtilService.parseJSON(tooltipItem.dataset.data[tooltipItem.dataIndex]);

    if (value === 0 && !this.tooltip_show_zero) {
      return null;
    }
    else {
      value = this._formatTooltipLineMeasureValue(value);

      let label: any = chart_type === 'pie' ?
        tooltipItem.label :
        tooltipItem.dataset.label;

      if (label !== null) {
        label = this._formatTooltipLineSeriesLabel(label);
      }

      if (!!this.tooltip_label) {
        return value + ' ' + this.tooltip_label;
      }

      return '   ' + value + (label ? ('  -  ' + label) : '');
    }
  }

  private _formatTooltipLineMeasureValue(value: any): string {
    if (!!this.report) {
      return this.reportService.formatFieldValue(
        value,
        this.report.measure,
        true,
        this.num_decimal_places
      );
    }
    else {
      return this.measure_is_currency ?
        this.appCurrencyPipe.transform(value, this.num_decimal_places) :
        this.appNumberPipe.transform(value, this.num_decimal_places);
    }
  }

  private _formatTooltipLineSeriesLabel(label: any): string {
    if (!!this.report?.series) {
      return this.reportService.formatFieldValue(
        label,
        this.report.series.dimension,
        true,
        this.num_decimal_places
      );
    }
    else {
      if (label === true || label === false) {
        label = (label + '').toUpperCase();
      }
      return label;
    }
  }

  private _getScalesOptions(): any {
    if (this.chart_type === 'pie') {
      return null;
    }
    else {
      const measure_axis: any = {
        stacked: this._stackAxis(),
        ticks: {
          major: {
            enabled: true
          },
          beginAtZero: true,
          callback: (value: any, index: number, ticks: any) => this._axisTickCallback(value, index, true)
        },
        max: this.measure_max
      };

      const dimension_axis: any = {
        stacked: this._stackAxis(),
        time: {
          tooltipFormat: 'MMM d yyyy'
        },
        ticks: {
          major: {
            enabled: true
          },
          callback: (value: any, index: number, ticks: any) => this._axisTickCallback(value, index, false)
        },
        grid: {
          display: this.show_dimension_grid,
        }
      };

      if (this.report.dimension.datatype === 'DATE' || this.time_axis) {
        dimension_axis.type = 'time';
      }

      const scales = {
        y: this.chart_type === 'horizontal_bar' ? dimension_axis : measure_axis,
        x: this.chart_type === 'horizontal_bar' ? measure_axis : dimension_axis
      };

      return scales;
    }
  }

  private _stackAxis() {
    return this.stack_series && (this.chart_type === 'bar' || this.chart_type === 'horizontal_bar');
  }

  private _axisTickCallback(
    value: any,
    index: number,
    is_measure: boolean
  ): string {
    if (!!this.report) {
      const field_config = is_measure ? this.report.measure : this.report.dimension;

      // Date and time label formatting handled by ChartJS
      if (field_config.datatype === 'DATE' || field_config.datatype === 'TIME') {
        return value;
      }
      else {
        // https://github.com/chartjs/Chart.js/issues/9573
        if (!is_measure) {
          value = this.dimension_data[index];
        }
        return this.reportService.formatFieldValue(
          value,
          field_config,
          true,
          this.num_decimal_places
        );
      }
    }
    else {
      return this.measure_is_currency ?
        this.appCurrencyPipe.transform(value, this.num_decimal_places) :
        this.appNumberPipe.transform(value, this.num_decimal_places);
    }
  }

}
