<div class="app-label-container">
  <div class="app-label"
       #label
       *ngFor="let label of labels"
       [ngStyle]="{ 'background-color': label.color, 'padding-right': enable_remove ? '3px' : '' }"
       [id]="'label_' + label.title + '_' + label.color">
    <div>
      {{label.title}}
    </div>
    <i class="ion ion-md-close app-remove"
       *ngIf="enable_remove"
       (click)="$event.stopPropagation(); removeLabel(label)">
    </i>
  </div>
</div>

<div ngbDropdown
     #ngbDropdown="ngbDropdown"
     [container]="'body'"
     [autoClose]="'outside'"
     [placement]="'bottom-right top-right'"
     [hidden]="hidden_labels.length === 0"
     (openChange)="dropdownToggled($event)"
     (click)="$event.stopPropagation()">
  <button class="-type-icon -color-grey-clear lp-dropdownBtn"
          ngbDropdownToggle>
    <i class="ion ion-ios-more"></i>
  </button>

  <div class="lp-dropdown"
       ngbDropdownMenu>
    <div class="app-label-container">
      <div class="app-label"
           *ngFor="let label of hidden_labels"
           [ngStyle]="{ 'background-color': label.color, 'padding-right': enable_remove ? '3px' : '' }">
        <div>
          {{label.title}}
        </div>
        <i class="ion ion-md-close app-remove"
           *ngIf="enable_remove"
           (click)="removeLabel(label)">
        </i>
      </div>
    </div>
  </div>
</div>
