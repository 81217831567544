<div *ngIf="help_type === 'HOVER'">
  <i class="ion"
     [ngClass]="icon_class"></i>
  <tooltip-popover [placement]="is_mobile && !!hover_text_placement ? hover_text_placement : null"
                   [tooltip_content]="hover_text">
  </tooltip-popover>
</div>

<div *ngIf="help_type !== 'HOVER'"
     [title]="'More info'"
     (click)="linkClicked()">
  <i class="ion link"
     [ngClass]="icon_class"></i>
</div>
