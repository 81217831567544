import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import _ from 'lodash-es';

import {
  KmClock,
  KmProjectTask,
  CoreUtilService,
  BackdropComponent,
  DomService,
  LibModalService,
  ClockServiceInterface
} from '../../../public-api';

@Component({
  selector: 'clock-dropdown',
  templateUrl: './clock-dropdown.component.html',
  styleUrls: ['./clock-dropdown.component.scss']
})
export class ClockDropdownComponent implements OnInit, OnDestroy {

  @ViewChild('ngb_dropdown') ngb_dropdown: NgbDropdown;
  @ViewChild('cd_content') cd_content: ElementRef;

  readonly interruption_disabled_delay_seconds = this.clockService.interruption_disabled_delay_seconds;
  readonly project_task_label = CoreUtilService.project_task_label;

  is_mobile: boolean = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.is_mobile = DomService.is_mobile;
  }

  active_clock: KmClock = this.clockService.getActiveClock();

  clock_groups = this.clockService.getClockGroups();

  backdrop_ref: BackdropComponent = null;

  new_clock_note: string = '';

  container_inner_bottom: string = '';
  tooltip_content: string = '';

  event_subscriptions: Subscription[] = [];

  loading: boolean = false;

  constructor(
    @Inject('clockService') public clockService: ClockServiceInterface,
    public domService: DomService,
    public libModalService: LibModalService
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
    this._updateContainerInnerBottom();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
    this.backdrop_ref?.close();
  }

  private _updateContainerInnerBottom() {
    const row_count = this.clock_groups.length;
    this.container_inner_bottom = 'calc(0px - ((var(--clockDropdown-clock-height) - 2px) * ' + row_count + '))';
  }

  newClockNoteBlurred() {
    // Wait a change detection cycle to ensure that clock-in 
    // hasn't also been triggered via clicking the clock in button
    setTimeout(() => {
      if (this._canStartNewClockWithNote()) {
        this.clockIn(
          null,
          null,
          this.new_clock_note
        );
      }
    });    
  }

  newClockNoteKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this._canStartNewClockWithNote()) {
        this.clockIn(
          null,
          null,
          this.new_clock_note
        );
      }
    }
  }

  private _canStartNewClockWithNote() {
    return !this.loading &&
      !this.active_clock &&
      !!this.new_clock_note;
  }

  dropdownToggleClicked() {
    if (!!this.active_clock) {
      if (this.is_mobile) {
        this._clockModal();
      }
      else {
        this.ngb_dropdown?.open();
      }
    }
    else {
      if (!this.is_mobile) {
        this.clockIn()
          .catch(() => { });
      }
    }
  }

  closeDropdown() {
    this.ngb_dropdown?.close();
    this.backdrop_ref?.close();
    this.domService.closeOffCanvas();
  }

  dropdownToggled(is_open: boolean) {
    if (is_open && !this.is_mobile) {
      this.backdrop_ref = this.domService.openBackdrop();
    }
    else {
      this.backdrop_ref?.close();
    }
  }

  clockIn(
    event: any = null,
    project_task: KmProjectTask = null,
    note_content: string = null
  ): Promise<void> {
    return new Promise((resolve) => {
      event?.stopPropagation();

      this.loading = true;
      this.clockService.clockIn(
        project_task?.project_task_key || null,
        note_content || null
      )
        .catch(() => { })
        .finally(() => {
          this.active_clock = this.clockService.getActiveClock();
          this.new_clock_note = '';
          setTimeout(() => this.loading = false, 600);
          resolve();
        });
    });
  }

  clockOut(event: any) {
    event.stopPropagation();

    this.loading = true;
    this.closeDropdown();

    this.clockService.pauseTimer();

    if (!!this.active_clock.project_task) {
      this.clockService.clockOut()
        .finally(() => this.loading = false)
        .catch(() => { });
    }
    else {
      this._clockModal();
    }
  }

  private _clockModal() {
    this.libModalService.clockModal()
      .catch(() => {
        this.clockService.restartTimer();
      })
      .finally(() => {
        this.active_clock = this.clockService.getActiveClock();
        this.new_clock_note = '';
        this.loading = false;
      });
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.clockService.getActiveClockUpdatedEvent().subscribe(() => {
        this.active_clock = this.clockService.getActiveClock();

        if (
          !!this.active_clock &&
          !!this.active_clock.project_task &&
          !!this.clock_groups[1]?.project_task &&
          this.clock_groups.length > 1 &&
          this.clock_groups[1].project_task.project_task_key === this.active_clock.project_task.project_task_key &&
          this.clock_groups[1].group_clock_key === this.active_clock.group_clock_key
        ) {
          //mmm toast
          const toast_spread = this.active_clock.project_task.project_task_name + ' resumed';
          this.domService.openNotificationPopover(toast_spread, 'INFO', true);
        }
        this.clock_groups = this.clockService.getClockGroups();

        this._updateContainerInnerBottom();

        if (!this.active_clock) {
          this.closeDropdown();
        }
      })
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
