import { Injectable } from '@angular/core';

import { DpTeam } from '../../lib-models/dp-team/dp-team';
import { DpPerson } from '../../lib-models/dp-person/dp-person';
import { DpCompanyAdmin } from '../../lib-models/dp-company-admin/dp-company-admin';
import { SortUtilService } from './../sort-util/sort-util.service';
import { PersonUtilService } from '../person-util/person-util.service';

export type PersonTeam = {
  team: DpTeam,
  team_person_key: number,
  deleted_flag: boolean
};

@Injectable({
  providedIn: 'root'
})
export class TeamUtilService {

  static getPeopleForTeam(team: DpTeam, people_with_skill: boolean = false): DpPerson[] {
    const people = [];

    for (const team_person of team.team_people) {
      if (
        !team_person.person.archived_flag &&
        (!people_with_skill || PersonUtilService.personHasAnActiveSkill(team_person.person))
      ) {
        people.push(team_person.person);
      }
    }

    SortUtilService.sortList(people, { primary_sort_property: 'display_name' });

    return people;
  }

  static getTeamPersonIndex(team: DpTeam, person_key: number) {
    for (let i = 0; i < team.team_people.length; i++) {
      if (team.team_people[i].person.person_key === person_key) {
        return i;
      }
    }

    return -1;
  }

  static getUnusedPeopleForTeam(team: DpTeam, allPeople: DpPerson[]): DpPerson[] {
    const unusedPeople = [];
    const usedPeople = {};

    for (const team_person of team.team_people) {
      if (!team_person.deleted_flag) {
        usedPeople[team_person.person.person_key] = true;
      }
    }

    for (const person of allPeople) {
      if (!usedPeople[person.person_key]) {
        unusedPeople.push(person);
      }
    }

    SortUtilService.sortList(unusedPeople, { primary_sort_property: 'display_name' });

    return unusedPeople;
  }

  static getTeamManagerIndex(team: DpTeam, user_access_company_key: number) {
    for (let i = 0; i < team.team_managers.length; i++) {
      if (team.team_managers[i].companyAdmin.user_access_company_key === user_access_company_key) {
        return i;
      }
    }

    return -1;
  }

  static getUnusedManagersForTeam(team: DpTeam, allManagers: DpCompanyAdmin[]): DpCompanyAdmin[] {
    const unusedManagers = [];
    const usedManagers = {};

    for (const team_manager of team.team_managers) {
      if (!team_manager.deleted_flag && team_manager.companyAdmin) {
        usedManagers[team_manager.companyAdmin.user_access_company_key] = true;
      }
    }

    for (const admin of allManagers) {
      if (!usedManagers[admin.user_access_company_key]) {
        unusedManagers.push(admin);
      }
    }

    SortUtilService.sortList(unusedManagers, { primary_sort_property: 'full_name' });

    return unusedManagers;
  }

  static teamKeyExistsInTeams(team_key: number, teams: DpTeam[]) {
    for (const team of teams) {
      if (team.team_key === team_key) {
        return true;
      }
    }
    return false;
  }

  static getPersonTeamsForPerson(teams: DpTeam[], person_key: number): PersonTeam[] {
    const person_teams: PersonTeam[] = [];

    for (const team of teams) {
      for (const team_person of team.team_people) {
        if (team_person.person.person_key === person_key) {
          person_teams.push({
            team,
            team_person_key: team_person.team_person_key,
            deleted_flag: false
          });
          break;
        }
      }
    }

    return person_teams;
  }

  static getTeamsForPerson(teams: DpTeam[], person_key: number): DpTeam[] {
    const person_teams: DpTeam[] = [];

    for (const team of teams) {
      for (const team_person of team.team_people) {
        if (team_person.person.person_key === person_key) {
          person_teams.push(team);
          break;
        }
      }
    }

    return person_teams;
  }

  static getUnusedTeamsForPerson(allTeams: DpTeam[], person_key: number): DpTeam[] {
    const unusedTeams = [];
    const usedTeams = {};

    for (const team of allTeams) {
      for (const team_person of team.team_people) {
        if (team_person.person.person_key === person_key) {
          usedTeams[team.team_key] = true;
          break;
        }
      }
    }

    for (const team of allTeams) {
      if (!usedTeams[team.team_key]) {
        unusedTeams.push(team);
      }
    }

    return unusedTeams;
  }

}
