export interface PostableObject<T> {
  formatForPosting: (...args: any[]) => any;
  hasErrors(): boolean;
  getErrors(...args: any[]): PostableObjectErrorMap,
  getEditingDisabled?(): boolean,
  getLockedFields?(): PostableObjectLockedFields<T>
}

// object_property -> error_message
// eg. client_name -> 'Client name required'
export type PostableObjectErrorMap = Record<string, string>;

// eg. client_name -> true
export type PostableObjectLockedFields<T> = Partial<Record<keyof T, boolean>>;

export class PostableObjectUtilService {

  static lockAllFields<T>(object: PostableObject<T>): PostableObjectLockedFields<T> {
    const fields: PostableObjectLockedFields<T> = {};

    for (let key of Object.keys(object)) {
      // Account for private variables that are accessible via getters
      key = key[0] === '_' ? key.substring(1) : key;
      fields[key] = true;
    }

    return fields;
  }

  static lockFields<T>(keys: string[]): PostableObjectLockedFields<T> {
    const fields: PostableObjectLockedFields<T> = {};

    for (let key of keys) {
      // Account for private variables that are accessible via getters
      key = key[0] === '_' ? key.substring(1) : key;
      fields[key] = true;
    }

    return fields;
  }

  static hasErrors<T>(object: PostableObject<T>): boolean {
    return Object.keys(object.getErrors()).length > 0;
  }

}
