import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BankValidatorService {

  static bankData = [
    {  id: 1, branches: [ { from: 1, to: 999 }, { from: 1100, to: 1199 }, { from: 1800, to: 1899 }] },
    {  id: 2, branches: [  { from: 1,  to: 999 }, { from: 1200, to: 1299 }] },
    {  id: 3, branches: [  { from: 1, to: 999  },  { from: 1300, to: 1399  }, { from: 1500, to: 1599  }, { from: 1700, to: 1799  }, { from: 1900, to: 1999  }] },
    {  id: 4, branches: [  { from: 2020, to: 2024  }] },
    {  id: 6, branches: [  { from: 1, to: 999  },  { from: 1400, to: 1499  }] },
    {  id: 8, branches: [  { from: 6500, to: 6599  }] },
    {  id: 9, branches: [  { from: 0, to: 0  }] },
    {  id: 10, branches: [  { from: 5165, to: 5169  }] },
    {  id: 11, branches: [  { from: 5000, to: 6499  }, { from: 6600, to: 8999 }] },
    {  id: 12, branches: [  { from: 3000, to: 3299  }, { from: 3400, to: 3499 },  { from: 3600, to: 3699  }] },
    {  id: 13, branches: [  { from: 4900, to: 4999  }] },
    {  id: 14, branches: [  { from: 4700, to: 4799  }] },
    {  id: 15, branches: [  { from: 3900, to: 3999  }] },
    {  id: 16, branches: [  { from: 4400, to: 4499  }] },
    {  id: 17, branches: [  { from: 3300, to: 3399  }] },
    {  id: 18, branches: [  { from: 3500, to: 3599  }] },
    {  id: 19, branches: [  { from: 4600, to: 4649  }] },
    {  id: 20, branches: [  { from: 4100, to: 4199  }] },
    {  id: 21, branches: [  { from: 4800, to: 4899  }] },
    {  id: 22, branches: [  { from: 4000, to: 4049  }] },
    {  id: 23, branches: [  { from: 3700, to: 3799  }] },
    {  id: 24, branches: [  { from: 4300, to: 4349  }] },
    {  id: 25, branches: [  { from: 2500, to: 2599  }] },
    {  id: 26, branches: [  { from: 2600, to: 2699  }] },
    {  id: 27, branches: [  { from: 3800, to: 3849  }] },
    {  id: 28, branches: [  { from: 2100, to: 2149  }] },
    {  id: 29, branches: [  { from: 2150, to: 2299  }] },
    {  id: 30, branches: [  { from: 2900, to: 2949  }] },
    {  id: 31, branches: [  { from: 2800, to: 2849  }] },
    {  id: 33, branches: [  { from: 6700, to: 6799  }] },
    {  id: 35, branches: [  { from: 2400, to: 2499  }] },
    {  id: 38, branches: [  { from: 9000, to: 9499  }] },
    {  id: 88, branches: [  { from: 8800, to: 8805  }] }
  ];

  constructor() { }

  static _isValidBankAndBranch(bankId: number, branch: number) {
    if (!this.bankData.map(o => o.id).includes(+bankId)) return false;
    const bank = this.bankData.find(o => o.id === +bankId);
    return bank.branches.map(b => +branch >= b.from && +branch <= b.to).some(r => r);
  }

  static _getWeightFactor(bankId: number, accountNumber: number) {
    switch (+bankId) {
      case 8:
        return '000000076543210000';
      case 9:
        return '000000000054320001';
      case 25:
      case 33:
        return '000000017317310000';
      case 26:
      case 28:
      case 29:
        return '000000013713710371';
      case 31:
        return '000000000000000000';
      default:
        if (+accountNumber < 990000) return '00637900A584210000';
        else return '00000000A584210000';
    }
  }

  static _getModulo(bankId: number){
    switch (+bankId) {
      case 25:
      case 33:
      case 26:
      case 28:
      case 29:
        return 10;
      case 31:
        return 1;
      default:
        return 11;
    }
  }

  static _isValidNZBankNumber(bk: any, brch: any, acct: any, suf: any) {
    const bank = bk.padStart(2, '0');
    const branch = brch.padStart(4, '0');
    const account = acct.padStart(8, '0');
    const suffix = suf.padStart(4, '0');
    if (+account === 0) return false;
    if (!this._isValidBankAndBranch(bank, branch)) return false;
    const checkString = bank + branch + account + suffix;
    if (checkString.length !== 18) return false;
    const weightFactor = this._getWeightFactor(bank, account);
    const result = weightFactor.split('')
      .map((v, i) => (v === 'A' ? 10 : +v) * +checkString
        .split('')[i])
      .reduce((acc, curr) => acc + curr);
    const checkNumber = this._getModulo(bank);
    return result % checkNumber === 0;
  }

  static nzBankAccountIsValid(bank_account: any): boolean {
    if (!bank_account) {
      return false;
    }
    const bankSplit = bank_account.split('-');

    if (bankSplit.length === 1) {
      bankSplit[0] = bank_account.slice(0, 2);
      bankSplit[1] = bank_account.slice(2, 6);
      bankSplit[2] = bank_account.slice(6, 13);
      bankSplit[3] = bank_account.slice(13, 16);
    }
    if (bankSplit.length < 4 || !bankSplit.every((element: any) => element)) {
      return false;
    }
    return this._isValidNZBankNumber(bankSplit[0], bankSplit[1], bankSplit[2], bankSplit[3]);

  }
}
