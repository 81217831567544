import { Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';

import { ReportTableColumn } from '../../../lib.types';

@Directive({
  selector: '[reportTableResizer]'
})
export class ReportTableResizerDirective implements OnDestroy {

  @Input() col: ReportTableColumn = null;

  mousemove_listener: any = null;
  mouseup_listener: any = null;

  @HostListener('mousedown', ['$event'])
  onClick(event: any) {
    event.preventDefault();
    if (event.button === 0) {
      this.mousemove_listener = this.renderer.listen('document', 'mousemove', this.mouseMove);
      this.mouseup_listener = this.renderer.listen('document', 'mouseup', this.mouseUp);
    }
  }

  mouseMove = (event: MouseEvent) => {
    const offset = event.pageX - this.element.nativeElement.getBoundingClientRect().left;
    
    if (this.col.width + offset >= 50) {
      this.col.width += offset;
    }
  }

  mouseUp = () => {
    this._unlisten();
  }

  constructor(
    public renderer: Renderer2,
    public element: ElementRef
  ) { }

  ngOnDestroy() {
    this._unlisten();
  }

  private _unlisten() {
    if (!!this.mousemove_listener) {
      this.mousemove_listener();
    }
    if (!!this.mouseup_listener) {
      this.mouseup_listener();
    }
  }

}
