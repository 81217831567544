import { Component, Renderer2, ViewContainerRef} from '@angular/core';

import { CoreUtilService, DomService } from 'flexitime-library';

import { env } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'flexitime-app';

  constructor(
    public renderer: Renderer2,
    public viewContainerRef: ViewContainerRef,
    public domService: DomService
  ) {
    this._initBodyAppVersion();
    this._removeExistingThemesFromBody();
    this.renderer.addClass(document.body, CoreUtilService.app_theme);

    CoreUtilService.getAppThemeUpdatedEvent().subscribe(() => {
      setTimeout(() => {
        this._removeExistingThemesFromBody();
        this.renderer.addClass(document.body, CoreUtilService.app_theme);
      });
    });
  }

  private _removeExistingThemesFromBody() {
    document.body.classList.forEach((c) => {
      if (c.indexOf('-theme-') === 0) {
        this.renderer.removeClass(document.body, c);
      }
    });
  }

  ngOnInit(){
    this.domService.initAppViewContainerRef(this.viewContainerRef);
  }

  private _initBodyAppVersion(){
    this.renderer.setAttribute(document.body, 'app-version', env.app_version);
  }

}
