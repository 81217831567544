import { Inject, Injectable } from '@angular/core';
import { ProductValue } from '../../lib.types';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  public static font_family: string = '';
  public static PRODUCT: ProductValue = null;

  constructor(
    @Inject('env') public env: any
  ) { }

  init() {
    EnvService.PRODUCT = this.env.product;

    switch (this.env.product) {
      case 'KARMLY':
        EnvService.font_family = 'Silka';
        break;
      case 'DROPPAH':
        EnvService.font_family = 'Sharp Grotesk';
        break;
      case 'INVOXY':
        EnvService.font_family = 'Work Sans';
        break;
      case 'FLEXITIME':
        EnvService.font_family = 'Work Sans';
        break;
    }
  }
}
