<div class="app-radioButton-label"
     *ngIf="label && labelLeft">
  {{label}}
</div>

<div class="app-radioButton">
  <div class="app-radioButton-inner"
       [ngStyle]="{ 
         'opacity': !!value ? 1 : '',
         'cursor': radioButtonDisabled ? 'default' : ''
         }">
  </div>
</div>

<div class="app-radioButton-label"
     *ngIf="label && !labelLeft">
  {{label}}
</div>
