import { DpCompanyAdmin } from '../dp-company-admin/dp-company-admin';
import { PostableObject, PostableObjectErrorMap } from './../../lib-interfaces/postable-object.interface';

export class DpTeamManager implements PostableObject<DpTeamManager> {

  team_user_access_company_key: number;
  companyAdmin: DpCompanyAdmin;
  deleted_flag: boolean = false;

  constructor(
    team_user_access_company_key: number = null, companyAdmin: DpCompanyAdmin
  ) {
    this.team_user_access_company_key = team_user_access_company_key;
    this.companyAdmin = companyAdmin;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }
  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    return errors;
  }

  formatForPosting(): any {
    if (this.hasErrors()) {
      // No need to post a new but deleted team manager
      if (this.team_user_access_company_key === null && this.deleted_flag){
        return null;
      }
      else {
        return {
          team_user_access_company_key: this.team_user_access_company_key,
          user_access_company_key: this.companyAdmin.user_access_company_key,
          deleted_flag: this.deleted_flag
        };
      }
    }
    return null;
  }
}
