
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';

import * as _ from 'lodash-es';
import { DpPerson } from '../dp-person/dp-person';

export type DpCoverRequestNote = {
  display_name: string,
  note: string,
  created_date: Date
};

export type DpCoverRequestResponse = {
  approved_flag: boolean
  declined_flag: boolean,
  manager_flag: boolean,
  request_response_type: 'COVER REQUEST'
};

export class DpCoverRequest implements PostableObject<DpCoverRequest> {

  new_person_key: number;
  original_person_key: number;
  roster_segment_cover_request_key: number;
  roster_segment_key: number;
  deleted_flag: boolean = false;

  notes: DpCoverRequestNote[] = [];
  requests_responses: DpCoverRequestResponse[] = [];
  request_accepted: boolean = false;
  approved_declined_date: Date = null;

  newPerson: DpPerson = null;

  constructor(
    newPerson: DpPerson,
    new_person_key: number,
    original_person_key: number,
    roster_segment_cover_request_key: number,
    roster_segment_key: number,
    notes: DpCoverRequestNote[] = [],
    requests_responses: DpCoverRequestResponse[] = [],
    request_accepted: boolean = false,
    approved_declined_date: Date = null,
    deleted_flag: boolean = false
  ) {
    this.newPerson = newPerson;
    this.new_person_key = new_person_key;
    this.original_person_key = original_person_key;
    this.roster_segment_cover_request_key = roster_segment_cover_request_key;
    this.roster_segment_key = roster_segment_key;
    this.notes = notes;
    this.requests_responses = requests_responses;
    this.request_accepted = request_accepted;
    this.approved_declined_date = approved_declined_date;
    this.deleted_flag = deleted_flag;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    return errors;
  }

  formatForPosting(
    accept_request: boolean = false,
    note: string = null,
    deleted_flag: boolean = false
  ): any {
    return {
      new_person_key: this.new_person_key,
      roster_segment_cover_request_key: this.roster_segment_cover_request_key,
      roster_segment_key: this.roster_segment_key,
      requests_responses: this.requests_responses,
      note: note,
      accept_request: accept_request,
      deleted_flag: deleted_flag
    };
  }



}
