import { DateTime } from 'luxon';
import { PostableObject, PostableObjectErrorMap, PostableObjectUtilService } from '../../lib-interfaces/postable-object.interface';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { ProjectUtilService } from '../../lib-services/project-util/project-util.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';

import { Label, KmProjectType } from '../../lib.types';

export class KmProjectStub {

  project_key: number;
  client_key: number;
  client_name: string;
  start_date: Date;
  end_date: Date;
  labels: Label[];
  project_name: string;
  project_rate: number;
  project_fee: number;
  project_type: KmProjectType;
  rate_type: ('hours' | 'days');
  reference: string;
  project_colour: string;
  completed_flag: boolean;
  locked_flag: boolean;
  coworker_locked_flag: boolean;
  invoxy_task_keys: number[];

  constructor(
    project_key: number,
    client_key: number = null,
    client_name: string = null,
    start_date: Date,
    end_date: Date,
    labels: Label[],
    project_name: string,
    project_rate: number,
    project_fee: number,
    project_type: KmProjectType,
    rate_type: ('hours' | 'days'),
    reference: string,
    project_colour: string,
    completed_flag: boolean,
    invoxy_locked_flag: boolean = false,
    coworker_locked_flag: boolean = false,
    invoxy_task_keys: number[]
  ) {
    this.project_key = project_key;
    this.client_key = client_key;
    this.client_name = client_name;
    this.start_date = start_date;
    this.end_date = end_date;
    this.labels = labels;
    this.project_name = project_name;
    this.project_rate = project_rate;
    this.project_fee = project_fee;
    this.project_type = project_type;
    this.rate_type = rate_type;
    this.reference = reference;
    this.project_colour = project_colour;
    this.completed_flag = completed_flag;
    this.invoxy_task_keys = invoxy_task_keys;

    this.locked_flag = invoxy_locked_flag || coworker_locked_flag;
    this.coworker_locked_flag = coworker_locked_flag;
  }

  isCurrent(date: Date = new Date()): boolean {
    const d = DateTime.fromJSDate(date).startOf('day');
    const start = DateTime.fromJSDate(this.start_date).startOf('day');
    const end = this.end_date ? DateTime.fromJSDate(this.end_date).startOf('day') : null;

    return !this.completed_flag && d >= start &&
      (!end || d <= end);
  }

}
