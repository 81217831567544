import { TimesheetAttachmentStub } from '../../lib.types';

export class KmInvoiceStub {

  client_key: number;
  client_name: string;
  due_date: Date;
  invoice_date: Date;
  created_date: Date;
  to_date: Date;
  from_date: Date;
  invoice_key: number;
  invoice_number: string;
  net_amount: number;
  tax_amount: number;
  gross_amount: number;
  posted_flag: boolean;
  posted_date: Date;
  paid_flag: boolean;
  paid_date: Date;
  projects: [];
  timesheet_attachments: TimesheetAttachmentStub[];
  payable_flag: boolean;
  coworker_key: number;
  coworker_name: string;
  coworker_image: string;

  constructor(
    client_key: number,
    client_name: string,
    due_date: Date,
    invoice_date: Date,
    created_date: Date,
    to_date: Date,
    from_date: Date,
    invoice_key: number,
    invoice_number: string,
    net_amount: number,
    tax_amount: number,
    gross_amount: number,
    posted_flag: boolean,
    posted_date: Date,
    paid_flag: boolean,
    paid_date: Date,
    projects: [],
    timesheet_attachments: TimesheetAttachmentStub[] = [],
    payable_flag: boolean = false,
    coworker_key: number = null,
    coworker_name: string = null,
    coworker_image: string = null
  ) {
    this.client_key = client_key;
    this.client_name = client_name;
    this.due_date = due_date;
    this.invoice_date = invoice_date;
    this.created_date = created_date;
    this.to_date = to_date;
    this.from_date = from_date;
    this.invoice_key = invoice_key;
    this.invoice_number = invoice_number;
    this.net_amount = net_amount;
    this.tax_amount = tax_amount;
    this.gross_amount = gross_amount;
    this.posted_flag = posted_flag;
    this.posted_date = posted_date;
    this.paid_flag = paid_flag;
    this.paid_date = paid_date;
    this.projects = projects;
    this.timesheet_attachments = timesheet_attachments;
    this.payable_flag = payable_flag;
    this.coworker_key = coworker_key;
    this.coworker_name = coworker_name;
    this.coworker_image = coworker_image;
  }

}


