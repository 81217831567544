import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, OnInit, AfterContentInit, HostBinding, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';
import { BackdropComponent } from '../backdrop/backdrop.component';
import { DomService } from '../../lib-services/dom/dom.service';

import { ItemBulletType, DropdownCustomDivider, ProductValue } from '../../lib.types';

import _ from 'lodash-es';
import { AppSearchComponent } from '../app-search/app-search.component';

export type ListDropdownItemConfig = {
  // Values
  type?: string,
  type_plural?: string,
  font_size?: number,
  // Item properties
  // id required multi_select_enabled = true
  id?: string | number,
  label?: string,
  popover_content?: string,
  sort?: string,
  description?: string,
  description_bullet_colour?: string,
  description_bullet_type?: string,
  hidden?: string,
  disabled?: string,
  bullet_colour?: string,
  bullet_type?: string,
  text_colour?: string,
  icon_class?: string,
  icon_colour?: string,
  toggleable?: string,
  toggle_value?: string,
  checkable?: string,
  checked_value?: string,
  user_img_url?: string,
  product?: string,
  // Functions
  getChildNode?: ListDropdownChildNodeResolver,
  itemIsVisible?: ListDropdownItemVisibilityResolver
};

export type ListDropdownMultiSelectConfig = {
  enabled: boolean,
  button_label: string,
  update_on_close: boolean,
  one_item_minimum: boolean
};

export type ListDropdownChildNodeResolver = (item?: any) => ListDropdownNode;
export type ListDropdownItemVisibilityResolver = (item: any) => boolean;

export type ListDropdownNode = {
  items: any[],
  config: ListDropdownItemConfig
};

@Component({
  selector: 'list-drop-down',
  templateUrl: './list-drop-down.component.html',
  styleUrls: ['./list-drop-down.component.scss']
})
export class ListDropDownComponent implements OnDestroy, OnInit, AfterContentInit, OnChanges {

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.keyPressed(event);
  }

  @ViewChild('ngb_dropdown') ngb_dropdown: NgbDropdown;
  @ViewChild('ldd_content') ldd_content: ElementRef;
  @ViewChild(AppSearchComponent) app_search: AppSearchComponent;

  @HostBinding('class.-floatOnMobile') @Input() float_on_mobile: boolean = false;
  @HostBinding('class.-floatLeftOnMobile') @Input() float_left_on_mobile: boolean = false;
  @HostBinding('class.-readonly') @Input() readonly: boolean = false;
  @HostBinding('style.z-index') get zIndex() {
    return this.is_open && this.float_on_mobile ? 1100 : '';
  }

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (DomService.is_mobile !== this.is_mobile) {
      this.is_mobile = DomService.is_mobile;
    }
  }

  @Input() items: any[] = [];
  // Use item_config going forward
  @Input() item_config: ListDropdownItemConfig = null;

  //////////////////////////////
  // DON'T USE THESE ANYMORE
  // These values will be ignored if item_config is provided
  // They'll be removed once all existing usages have been changed to use item_config instead
  //
  // Values
  @Input() item_type: string = 'item';
  @Input() item_type_plural: string = 'items';
  @Input() item_font_size: number = null;
  // Item properties
  @Input() item_label_prop: string = null;
  @Input() item_popover_content_prop: string = null;
  @Input() item_sort_prop: string = null;
  @Input() item_description_prop: string = null;
  @Input() item_description_bullet_colour_prop: string = null;
  @Input() item_description_bullet_type_prop: string = null;
  @Input() item_hidden_prop: string = null;
  @Input() item_disabled_prop: string = null;
  @Input() item_bullet_colour_prop: string = null;
  @Input() item_bullet_type_prop: string = null;
  @Input() item_text_colour_prop: string = null;
  @Input() item_icon_class_prop: string = null;
  @Input() item_icon_colour_prop: string = null;
  @Input() item_toggleable_prop: string = null;
  @Input() item_toggle_value_prop: string = null;
  @Input() item_checkable_prop: string = null;
  @Input() item_checked_value_prop: string = null;
  @Input() item_user_img_url_prop: string = null;
  // Functions
  @Input() getChildNode: ListDropdownChildNodeResolver = null
  @Input() itemIsVisible: ListDropdownItemVisibilityResolver = null;
  //////////////////////////////

  // Option to create new item
  @Input() dynamic_creation_enabled: boolean = false;
  @Input() dynamic_creation_enabled_on_empty: boolean = false; // If true, dynamic creation will be enabled only when there are no visible items in list
  @Input() dynamic_creation_child_items: any[] = [];
  @Input() dynamic_creation_child_item_config: ListDropdownItemConfig;

  // Button config
  @Input() button_color_class: string = '-color-white-outline';
  @Input() button_toggled_color_class: string = null;
  @Input() button_type_class: string = '-type-dropdown';
  @Input() button_icon_class: string = null;
  @Input() button_toggled_icon_class: string = null;
  @Input() button_icon_align_left: boolean = false;
  @Input() button_bullet_colour: string = null;
  @Input() button_bullet_type: ItemBulletType = null;
  @Input() button_title: string = null;
  @Input() button_title_prefix: string = null;
  @Input() button_title_placeholder: string = null;
  @Input() button_title_uppercase: string = null;
  @Input() button_description: string = null;
  @Input() button_fill_color: string = null;
  @Input() button_tooltip: string = null;
  @Input() button_show_archived_icon: boolean = false;
  @Input() button_show_completed_icon: boolean = false;
  @Input() button_user_url: string = null;

  // List config
  @Input() list_width: string = '';
  @Input() list_fill_width: boolean = false;
  @Input() list_disable_auto_close: boolean = false;
  @Input() list_placement: string = null;
  @Input() list_align_icon_right: boolean = true;
  @Input() list_bullet_type: ItemBulletType = null;
  @Input() list_show_user_icons: boolean = false;

  // Multi select config
  @Input() multi_select_config: ListDropdownMultiSelectConfig = null;
  @Input() multi_select_selected_items: any[] = [];
  // @Input() multi_select_enabled: boolean = false;
  // @Input() multi_select_button_label: string = null;
  // @Input() multi_select_update_on_close: boolean = false;
  // @Input() multi_select_one_item_minimum: boolean = false;
  @Output() multi_select_items_selected = new EventEmitter<{ items: any[] }>();

  // Misc config
  @Input() disabled: boolean = false;
  @Input() default_item: any = null;
  @Input() show_search: boolean = false;
  @Input() highlight_item_matching_title: boolean = false;
  @Input() sort_items: boolean = true;
  @Input() disable_body_append: boolean = false;

  @Input() listDescription: string = null;

  @Output() item_selected = new EventEmitter<{ item: any, parent_items: any[] }>();
  @Output() dynamic_creation_item_selected = new EventEmitter<{ item_name: string, child_items: any[] }>();

  @Output() dropdown_toggled = new EventEmitter<boolean>();

  dropdown_id: number = Math.floor(Math.random() * 10000000);

  is_open = false;

  selected_node_stack: { node: ListDropdownNode, selected_item?: any }[] = [];

  root_node: ListDropdownNode = null;
  // selected_node_stack[0]
  active_node: ListDropdownNode = null;
  // selected_node_stack[1]
  parent_node: ListDropdownNode = null;
  parent_node_selected_item: any = null;

  dynamic_creation_item: string = null;

  title_matches_list_item: boolean = false;

  highlighted_item_index: number = null;

  placement: string = null;

  // Used when multi_select_enabled = true
  selected_item_ids: Record<(string | number), boolean> = {};
  selected_item_count: number = 0;

  // visble_item index -> getChildNode(item).length
  visible_item_child_node_item_lengths: Record<number, number> = {};

  search: string = '';
  search_placeholder: string = '';
  visible_items: any[] = [];
  no_visible_items: boolean = false;

  backdrop_ref: BackdropComponent = null;

  constructor(
    private eRef: ElementRef,
    private domService: DomService
  ) { }

  ngOnInit() {
    this._throwErrorIfItemConfigAndItemFieldsBothInUse();

    if (
      this.button_icon_class === null &&
      (this.button_type_class === '-type-dropdown' || this.button_type_class === '-type-dropdown-round')
    ) {
      this.button_icon_class = 'ion-md-arrow-dropdown';
    }
    this._initSearchPlaceholder();
  }

  multiSelectToggleAll() {
    if (this.selected_item_count === this.visible_items.length) {
      this.selected_item_ids = {};
    }
    else {
      for (const item of this.visible_items) {
        const id = item[this.active_node.config.id];
        this.selected_item_ids[id] = true;
      }
    }
    this._updateSelectedItemCount();
  }

  private _multiSelectToggleItem(item: any) {
    const id = item[this.active_node.config.id];
    this.selected_item_ids[id] = !this.selected_item_ids[id];
    this._updateSelectedItemCount();
  }

  private _updateSelectedItemCount() {
    this.selected_item_count = 0;
    for (const id of Object.keys(this.selected_item_ids)) {
      if (this.selected_item_ids[id] === true) {
        this.selected_item_count++;
      }
    }
  }

  private _initSelectedItemIds() {
    if (!!this.multi_select_selected_items?.length) {
      this.selected_item_ids = {};

      for (const item of this.multi_select_selected_items) {
        const id = item[this.active_node.config.id];
        this.selected_item_ids[id] = true;
      }
    }

    this._updateSelectedItemCount();
  }

  keyPressed(event: KeyboardEvent): void {
    if (this.is_open) {
      switch (event.key) {
        case 'ArrowDown':
          this._incrementHighlightedItemIndex(true);
          break;
        case 'ArrowUp':
          this._incrementHighlightedItemIndex(false);
          break;
        case 'Enter':
          if (this.highlighted_item_index !== null) {
            this.selectItem(this.visible_items[this.highlighted_item_index]);
          }
          break;
        case 'Backspace':
          if (!!this.parent_node) {
            this.backToParentNode();
          }
          break;
      }
    }
  }

  private _incrementHighlightedItemIndex(positive_increment: boolean = true) {
    const valid_indices = [];
    for (let i = 0; i < this.visible_items.length; i++) {
      if (!this._itemIsDivider(this.visible_items[i])) {
        valid_indices.push(i);
      }
    }

    if (!valid_indices.length) {
      return;
    }

    if (this.highlighted_item_index === null) {
      this.highlighted_item_index = positive_increment ? valid_indices[0] : valid_indices[valid_indices.length - 1];
    }
    else {
      const starting_valid_index = _.findIndex(valid_indices, (i) => i === this.highlighted_item_index);

      if (positive_increment) {
        if (starting_valid_index === valid_indices.length - 1) {
          this.highlighted_item_index = valid_indices[0];
        }
        else {
          this.highlighted_item_index = valid_indices[starting_valid_index + 1];
        }
      }
      else {
        if (starting_valid_index === 0) {
          this.highlighted_item_index = valid_indices[valid_indices.length - 1];
        }
        else {
          this.highlighted_item_index = valid_indices[starting_valid_index - 1];
        }
      }
    }
  }

  private _initSearchPlaceholder() {
    const config = this.active_node.config;
    const item_type = !!config?.type ? config.type : 'item';

    const search_text = 'Search';
    const create_text = 'Create ' + item_type;

    this.search_placeholder =
      (this.show_search ? search_text : '') +
      (this.show_search && this.dynamic_creation_enabled ? ' or ' : '') +
      (this.dynamic_creation_enabled ? create_text : '');
  }

  private _updateVisibleItemChildNodeItemLengths() {
    this.visible_item_child_node_item_lengths = {};

    if (!!this.active_node?.config.getChildNode) {
      for (let i = 0; i < this.visible_items.length; i++) {
        this.visible_item_child_node_item_lengths[i] = this.active_node?.config.getChildNode(this.visible_items[i])?.items.length;
      }
    }
  }

  ngAfterContentInit() {
    this.determineDropAlignment();
  }

  ngOnDestroy() {
    this.backdrop_ref?.close();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.items || !!changes.dynamic_creation_child_items) {
      this._resetActiveNode();
      this.reloadVisibleItems();
    }
    if (!!changes.dynamic_creation_enabled || !!changes.show_search) {
      this._initSearchPlaceholder();
    }
    if (!!changes.multi_select_selected_items) {
      this._initSelectedItemIds();
    }
  }

  get search_visible(): boolean {
    return (this.show_search && this.active_node.items.length > 5) || this.dynamic_creation_enabled;
  }

  openMobileDropdown() {
    this.domService.openMobileDropdown(this.ldd_content, 'top');
  }

  openDropdown() {
    this.ngb_dropdown?.open();
    setTimeout(() => this.app_search?.focus());
  }

  dropdownToggleClicked() {
    this.determineDropAlignment();
    if (!this.disabled && !this.readonly && !this.is_open) {
      if (this.is_mobile) {
        this.openMobileDropdown();
      }
      else {
        this.openDropdown();
      }
    }
  }

  dropdownToggled(is_open: boolean) {
    this.is_open = is_open;

    if (this.is_open && !this.is_mobile) {
      this.backdrop_ref = this.domService.openBackdrop();
    }
    else {
      this.backdrop_ref?.close();
    }
    this.dropdown_toggled.emit(this.is_open);

    if (!this.is_open) {
      this.highlighted_item_index = null;

      if (this.multi_select_config?.enabled && this.multi_select_config.update_on_close) {
        this.itemsSelected();
      }
    }
  }

  closeDropdown() {
    this.domService.closeOffCanvas();
    this.backdrop_ref?.close();
    this.ngb_dropdown?.close();
  }

  reloadVisibleItems() {
    // Sort by default
    if (!!this.sort_items) {
      this.sortGroup();
    }

    this.searchUpdated();
    // Ignore null spacers
    this.no_visible_items = _.filter(this.visible_items, (item) => item !== null).length === 0 && !this.default_item;

    if (!!this.no_visible_items && !!this.dynamic_creation_enabled_on_empty) {
      this.dynamic_creation_enabled = true;
    }
  }

  determineDropAlignment() {
    if (this.list_fill_width) {
      this.list_width = this.eRef.nativeElement.offsetWidth + 'px';
    }

    const dropdown_top = this.eRef.nativeElement.getBoundingClientRect().top;
    const dropdown_left = this.eRef.nativeElement.getBoundingClientRect().left;

    const drop_up = dropdown_top > (window.innerHeight / 2);
    const align_left = dropdown_left < (window.innerWidth / 2);
    const placement = (drop_up ? 'top' : 'bottom') + '-' + (align_left ? 'left' : 'right');

    this.placement = this.list_placement || placement;
  }

  sortGroup() {
    const config = this.active_node.config;
    const primary_sort_property = config?.sort || config?.label || '';
    SortUtilService.sortList(this.active_node.items, { primary_sort_property });
  }

  clearSearchInput() {
    this.search = '';
  }

  // Further need to filter items to those that match the search value
  searchUpdated() {
    this.highlighted_item_index = null;

    const items = this.active_node.items;
    this.visible_items = [];

    for (const item of items) {
      if (this._itemIsDivider(item)) {
        this.visible_items.push(item);
      }
      else if (
        !this._itemIsHidden(item) &&
        this._itemIsVisible(item) &&
        this.itemMatchesSearch(item)
      ) {
        this.visible_items.push(item);
      }
    }

    this._filterCustomDividers();
    this._updateVisibleItemChildNodeItemLengths();
  }

  private _filterCustomDividers() {
    for (let i = this.visible_items.length - 1; i >= 0; i--) {
      const item = this.visible_items[i];

      if (this._itemIsDivider(item) && item !== null) {
        switch ((item as DropdownCustomDivider).divider_type) {
          case 'GROUP_HEADER':
            // Ensure GROUP_HEADER dividers are removed if they don't
            // have a group of items that follow directly afer them
            if (
              i === this.visible_items.length - 1 ||
              this._itemIsDivider(this.visible_items[i + 1])
            ) {
              this.visible_items.splice(i, 1);
            }
        }
      }
    }
  }

  itemMatchesSearch(item: any) {
    if (!this.search_visible || this.search === '') {
      return true;
    }
    if (this._itemIsDivider(item)) {
      return true;
    }
    const search_string = this.search.toUpperCase();
    const config = this.active_node.config;

    const item_label_string = item[config.label]?.toUpperCase() || '';
    const item_description_string = item[config.description]?.toUpperCase() || '';

    return item_label_string.indexOf(search_string) !== -1 || item_description_string.indexOf(search_string) !== -1;
  }

  selectDefaultItem() {
    this.selectItem(this.default_item);
  }

  selectDynamicCreationItem() {
    if (this.dynamic_creation_enabled) {
      this.dynamic_creation_item = this.search;
      this.search = '';

      const config: ListDropdownItemConfig = {
        getChildNode: () => {
          return {
            items: this.dynamic_creation_child_items,
            config: this.dynamic_creation_child_item_config
          };
        }
      };

      this._selectItem(this.dynamic_creation_item, config);
    }
  }

  itemsSelected() {
    if (this.multi_select_config?.enabled && (!this.multi_select_config.one_item_minimum || this.selected_item_count > 0)) {
      const selected_items = [];

      for (const item of this.active_node.items) {
        if (this.selected_item_ids[item[this.active_node.config.id]]) {
          selected_items.push(item);
        }
      }
      this.multi_select_selected_items = selected_items;
      this.multi_select_items_selected.emit({ items: this.multi_select_selected_items });
      this.closeDropdown();
    }
  }

  selectItem(item: any) {
    const config = this.active_node.config;

    if (!!config.disabled && item[config.disabled]) {
      return;
    }

    if (this.multi_select_config?.enabled) {
      this._multiSelectToggleItem(item);
    }
    else {
      this._selectItem(item, config);
    }
  }

  private _selectItem(
    item: any,
    config: ListDropdownItemConfig
  ) {
    const child_node = this._getChildNode(item, config);

    if (!!child_node) {
      this._setChildNodeAsActive(child_node, item);
    }
    else {
      if (!!this.dynamic_creation_item) {
        this._emitDynamicCreationItemSelected(item);
      }
      else {
        this._emitItemSelected(item);
      }
      this._resetActiveNode();
    }
    this.search = '';
    this.reloadVisibleItems();

    if (
      !child_node &&
      !this.list_disable_auto_close &&
      !this._itemIsToggleable(item)
    ) {
      this.closeDropdown();
    }
  }

  private _emitItemSelected(item: any) {
    const parent_items = this._getParentSelectedItems();

    setTimeout(() => {
      this.item_selected.emit({
        item,
        parent_items
      });
    });
  }

  private _emitDynamicCreationItemSelected(item: any) {
    const item_name = this.dynamic_creation_item;
    const child_items = [item];
    // Ignore last element in node stack as it's selected item will just be this.dynamic_creation_item_name
    const node_stack = this.selected_node_stack.slice(0, this.selected_node_stack.length - 1);

    for (const elem of node_stack) {
      child_items.push(elem.selected_item);
    }

    setTimeout(() => {
      this.dynamic_creation_item_selected.emit({
        item_name,
        child_items
      });
    });
  }

  backToParentNode(event: MouseEvent = null) {
    event?.stopPropagation();
    this._setParentNodeAsActive();

    this.search = '';
    if (this.search_visible) {
      setTimeout(() => this.app_search?.focus());
    }

    this.reloadVisibleItems();
  }

  private _itemIsDivider(item: any): boolean {
    return item === null || item?.is_custom_divider;
  }

  private _itemIsHidden(item: any): boolean {
    return !!item && !!this.active_node.config?.hidden ? item[this.active_node.config.hidden] : false;
  }

  private _itemIsVisible(item: any): boolean {
    return !!item && !!this.active_node.config?.itemIsVisible ? this.active_node.config.itemIsVisible(item) : true;
  }

  private _itemIsToggleable(item: any): boolean {
    return !!item && !!this.active_node.config?.toggleable ? item[this.active_node.config.toggleable] : false;
  }

  private _getParentSelectedItems(): any[] {
    const items = [];

    for (const elem of this.selected_node_stack) {
      items.push(elem.selected_item);
    }

    return items;
  }

  private _setParentNodeAsActive() {
    if (!!this.selected_node_stack?.length) {
      this.active_node = this.selected_node_stack[0].node;

      this.selected_node_stack = this.selected_node_stack.slice(1);

      if (!!this.selected_node_stack?.length) {
        this.parent_node = this.selected_node_stack[0].node;
        this.parent_node_selected_item = this.selected_node_stack[0].selected_item;
      }
      else {
        this.parent_node = null;
        this.parent_node_selected_item = null;
        this.dynamic_creation_item = null;
      }
    }

    this.reloadVisibleItems();
  }

  private _setChildNodeAsActive(child_node: ListDropdownNode, selected_item: any) {
    this.parent_node = this.active_node;
    this.parent_node_selected_item = selected_item;

    this.selected_node_stack.unshift({
      node: this.parent_node,
      selected_item: this.parent_node_selected_item
    });

    this.active_node = child_node;

    this.reloadVisibleItems();
  }

  private _getChildNode(item: any, config: ListDropdownItemConfig) {
    if (!!config && !!config.getChildNode) {
      const child_node = config.getChildNode(item);

      if (!!child_node?.items?.length) {
        return child_node;
      }
    }
    return null;
  }

  private _resetActiveNode() {
    this.highlighted_item_index = null;

    this.root_node = {
      items: this.items,
      config: this.item_config || this._checkForLegacyItemConfig() || {}
    };

    this.active_node = this.root_node;

    this.parent_node = null;
    this.parent_node_selected_item = null;

    this.selected_node_stack = [];

    this.dynamic_creation_item = null;
  }

  private _checkForLegacyItemConfig(): ListDropdownItemConfig {
    if (!!this.item_label_prop) {
      return {
        type: this.item_type || 'item',
        type_plural: this.item_type || 'items',
        font_size: this.item_font_size || null,
        label: this.item_label_prop || null,
        popover_content: this.item_popover_content_prop || null,
        sort: this.item_sort_prop || null,
        description: this.item_description_prop || null,
        description_bullet_colour: this.item_description_bullet_colour_prop || null,
        description_bullet_type: this.item_description_bullet_type_prop || null,
        hidden: this.item_hidden_prop || null,
        disabled: this.item_disabled_prop || null,
        bullet_colour: this.item_bullet_colour_prop || null,
        bullet_type: this.item_bullet_type_prop || null,
        text_colour: this.item_text_colour_prop || null,
        icon_class: this.item_icon_class_prop || null,
        icon_colour: this.item_icon_colour_prop || null,
        toggleable: this.item_toggleable_prop || null,
        toggle_value: this.item_toggle_value_prop || null,
        checkable: this.item_checkable_prop || null,
        checked_value: this.item_checked_value_prop || null,
        user_img_url: this.item_user_img_url_prop || null,
        product: null,
        getChildNode: this.getChildNode || null,
        itemIsVisible: this.itemIsVisible || null
      };
    }
    return null;
  }

  private _throwErrorIfItemConfigAndItemFieldsBothInUse() {
    if (!!this.item_config) {
      if (
        !!this.item_font_size ||
        !!this.item_label_prop ||
        !!this.item_popover_content_prop ||
        !!this.item_sort_prop ||
        !!this.item_description_prop ||
        !!this.item_description_bullet_colour_prop ||
        !!this.item_description_bullet_type_prop ||
        !!this.item_hidden_prop ||
        !!this.item_disabled_prop ||
        !!this.item_bullet_colour_prop ||
        !!this.item_bullet_type_prop ||
        !!this.item_text_colour_prop ||
        !!this.item_icon_class_prop ||
        !!this.item_icon_colour_prop ||
        !!this.item_toggleable_prop ||
        !!this.item_toggle_value_prop ||
        !!this.item_checkable_prop ||
        !!this.item_checked_value_prop ||
        !!this.item_user_img_url_prop ||
        !!this.getChildNode ||
        !!this.itemIsVisible
      ) {
        throw new Error('using this.item_config with individual item fields is not permitted. See comment on line 81 for more details');
      }
    }
  }

}
