import { Label } from '../../lib.types';

export type InvProjectUserStub = {
  display_name: string;
  user_key: number;
  resource_flag: boolean;
  default_start_time: Date;
};

export type InvProjectTaskStub = {
  task_name: string;
  task_display_name: string;
  task_key: number;
  project_key: number;
  admin_only_flag: boolean;
  archived_flag: boolean;
  unit_flag: boolean;
  unit_type: string;
  is_custom_unit: boolean;
  task_colour: string;
};

export class InvProjectStub {

  tasks: InvProjectTaskStub[];
  users: InvProjectUserStub[];

  project_key: number;
  client_key: number;
  client_name: string;
  start_date: Date;
  end_date: Date;
  labels: Label[];
  project_name: string;
  project_rate: number;
  project_type: string;
  rate_type: string;
  reference: string;
  archived_flag: boolean;

  constructor(
    tasks: InvProjectTaskStub[],
    users: InvProjectUserStub[],
    project_key: number,
    client_key: number,
    client_name: string,
    start_date: Date,
    end_date: Date,
    labels: Label[],
    project_name: string,
    project_rate: number,
    project_type: string,
    rate_type: string,
    reference: string,
    archived_flag: boolean
  ) {
    this.tasks = tasks;
    this.users = users;
    this.project_key = project_key;
    this.client_key = client_key;
    this.client_name = client_name;
    this.start_date = start_date;
    this.end_date = end_date;
    this.labels = labels;
    this.project_name = project_name;
    this.project_rate = project_rate;
    this.project_type = project_type;
    this.rate_type = rate_type;
    this.reference = reference;
    this.archived_flag = archived_flag;
  }

}
