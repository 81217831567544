<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>{{title}}</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <div class="app-modal-contentInfo"
           *ngIf="!message_is_html">
        <div>{{message}}</div>
      </div>
      <div class="app-modal-contentInfo"
           *ngIf="message_is_html">
        <div [innerHTML]="message | safeHtml"></div>
      </div>

    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterLeft">
    </div>
    <div class="app-modal-btnFooterRight">
      <button [ngClass]="button_colour_class"
              (click)="confirm()">
        {{button_title}}
      </button>
      <button class="-color-white-outline -desktop-only"
              (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
