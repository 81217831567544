import _ from 'lodash-es';

import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';

export type KmProfileDetails = {
  display_name: string,
  email_address: string,
  phone_number: string,
  website_link: string,
  location: string
}

export type KmResumeSkill = {
  resume_skill_key: number;
  skill_key: number;
  skill_name: string;
  deleted_flag: boolean;
}

export type KmResumeHistoryTypes = [
  'Experience', 'Education', 'Interest'
]

export type KmResumeHistory = {
  title: string;
  description: string;
  start_date: Date;
  end_date: Date;
  resume_history_key: number;
  visibility_key: number;
  history_type: string;
  hidden_flag: boolean;
  deleted_flag: boolean;
}

export type KmResumeProject = {
  project_key: number;
  project_name: string;
  hidden_flag: boolean;
  start_date: Date;
  end_date: Date;
  notes: string;
  visibility_key: number;
}

export type KmResumeTestimonial = {
  review_key: number;
  resume_testimonial_key: number;
  rating: number;
  description: string;
  author: string;
  deleted_flag: boolean;
}

export class KmResume implements PostableObject<KmResume> {

  cover_image: any = null;
  display_image: any = null;
  display_name: string;
  resume_key: number;
  availability: string;
  email_address: string;
  phone_number: string;
  website_link: string;
  about_me: string;
  location: string;
  skills: KmResumeSkill[];
  testimonials: KmResumeTestimonial[];
  projects: KmResumeProject[];
  history: KmResumeHistory[];
  token_sas: string;

  constructor(
    cover_image: string = null,
    display_image: string = null,
    display_name: string = null,
    resume_key: number = null,
    availability: string = null,
    email_address: string = null,
    phone_number: string = null,
    website_link: string = null,
    about_me: string = null,
    location: string = null,
    skills: KmResumeSkill[] = [],
    testimonials: KmResumeTestimonial[] = [],
    projects: KmResumeProject[] = [],
    history: KmResumeHistory[] = [],
    token_sas: string
  ) {
    this.cover_image = cover_image;
    this.display_image = display_image;
    this.display_name = display_name;
    this.resume_key = resume_key;
    this.availability = availability;
    this.email_address = email_address;
    this.phone_number = phone_number;
    this.website_link = website_link;
    this.about_me = about_me;
    this.location = location;
    this.skills = skills;
    this.testimonials = testimonials;
    this.projects = projects;
    this.history = history;
    this.token_sas = token_sas;
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    // if (!this.availability) {
    //   errors['availability'] = 'Availability required';
    // }
    // else if (this.availability !== 'Available' && this.availability !== 'Unavailable') {
    //   errors['availability'] = 'Valid availability required';
    // }

    // todo ???

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  formatForPosting(): any {
    if (!this.getErrors().has_errors) {

      return {
        resume_key: this.resume_key,
        availability: this.availability || 'Unavailable', //todo -> remove when we want to use availability again
        display_name: this.display_name,
        email_address: this.email_address,
        phone_number: this.phone_number + '',
        website_link: this.website_link,
        about_me: this.about_me,
        location: this.location,
        skills: !!this.skills ? this._formatSkillsForPosting() : null,
        testimonials: !!this.testimonials ? this._formatTestimonialsForPosting() : null,
        projects: !!this.projects ? this._formatProjectsForPosting() : null,
        history: !!this.history ? this._formatHistoryForPosting() : null,
        cover_image: !!this.cover_image ? this.cover_image : '',
        display_image: !!this.display_image ? this.display_image : ''
      };
    }
    return null;
  }

  private _formatTestimonialsForPosting() {
    const testimonials: any[] = [];

    for (const t of this.testimonials) {
      testimonials.push({
        author: t.author,
        description: t.description,
        rating: t.rating,
        resume_testimonial_key: t.resume_testimonial_key,
        review_key: t.review_key,
        deleted_flag: t.deleted_flag || false
      });
    }

    return testimonials;
  }

  private _formatSkillsForPosting() {
    const skills: any[] = [];

    for (const s of this.skills) {
      skills.push({
        resume_skill_key: s.resume_skill_key,
        skill_key: s.skill_key,
        skill_name: s.skill_name,
        deleted_flag: s.deleted_flag || false
      });
    }

    return skills;
  }

  private _formatProjectsForPosting(): any[] {
    const projects: any[] = [];

    for (const p of this.projects) {
      projects.push({
        project_key: p.project_key,
        hidden_flag: p.hidden_flag || false,
        visibility_key: p.visibility_key
      });
    }

    return projects;
  }

  private _formatHistoryForPosting(): any[] {
    const history: any[] = [];

    for (const h of this.history) {
      history.push(this._formatHistoryItemForPosting(h));
    }

    return history;
  }

  private _formatHistoryItemForPosting(history: KmResumeHistory): any {
    return {
      history_type: history.history_type,
      resume_key: this.resume_key,
      resume_history_key: history.resume_history_key,
      title: history.title,
      description: history.description,
      start_date: history.start_date !== null ? TimeUtilService.dateToDateString(history.start_date) : '',
      end_date: history.end_date !== null ? TimeUtilService.dateToDateString(history.end_date) : '',
      visibility_key: history.visibility_key,
      hidden_flag: history.hidden_flag,
      deleted_flag: history.deleted_flag || false,
    };
  }

}
