import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ClockServiceInterface } from '../../lib-interfaces/clock-service.interface';
import { ProjectTaskServiceInterface } from '../../lib-interfaces/project-task-service.interface';

@Component({
  selector: 'clock-footer',
  templateUrl: './clock-footer.component.html',
  styleUrls: ['./clock-footer.component.scss']
})
export class ClockFooterComponent implements OnInit, OnDestroy {

  active_clock = this.clockService.getActiveClock();

  event_subscriptions: Subscription[] = [];

  constructor(
    @Inject('clockService') public clockService: ClockServiceInterface,
    @Inject('projectTaskService') public projectTaskService: ProjectTaskServiceInterface
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.clockService.getActiveClockUpdatedEvent().subscribe(() => {
        this.active_clock = this.clockService.getActiveClock();
      })
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
