import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { DashGridBlockComponent, Report, ReportService } from '../../../../public-api';
import { ReportingAccount } from '../../../lib-models/reporting-account/reporting-account';
import { ReportChartComponent } from '../../report-chart/report-chart.component';
import { ReportNumericComponent } from '../../report-numeric/report-numeric.component';
import { ReportTableComponent } from '../../report-table/report-table.component';
import { ReportDashBlock } from '../report-dash-block';

@Component({
  selector: 'report-dash-block',
  templateUrl: './report-dash-block.component.html',
  styleUrls: ['./report-dash-block.component.scss']
})
export class ReportDashBlockComponent extends DashGridBlockComponent implements OnInit {

  @HostBinding('class.app-dashBlock') app_dash_block = true;

  @ViewChild(ReportChartComponent) report_chart: ReportChartComponent;
  @ViewChild(ReportTableComponent) report_table: ReportTableComponent;
  @ViewChild(ReportNumericComponent) report_numeric: ReportNumericComponent;

  @Input() project_key: number = null;
  @Input() selected_accounts: ReportingAccount[] = [];
  @Input() block: ReportDashBlock = null;
  @Input() is_mobile: boolean = false;
  @Input() editing_enabled: boolean = true;
  @Output() remove_block = new EventEmitter();

  report_config = this.reportService.getConfig();
  // An additional filter on project_key is automatically included to this report on init
  report: Report = null;
  filtered_report: Report = null;

  loading_query: boolean = true;
  query_data: any = null;

  constructor(
    @Inject('env') public env: any,
    public router: Router,
    public reportService: ReportService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initReport();
    this.generateQuery();
  }

  goToReport() {
    const route = this.env === 'KARMLY' ? 'insight/edit' : 'report/edit';

    if (!!this.report) {
      this.router.navigate([route], {
        queryParams: {
          report_key: this.report.report_key,
          report_type: this.report.report_type
        }
      });
    }
  }

  initReport() {
    if (!!this.block.report_key && !this.report) {
      const all_reports = this.reportService.getAllReports();

      for (const report of all_reports) {
        if (report.report_key === this.block.report_key) {
          this.report = report;
        }
      }
    }
  }

  blockResized() {
    this.renderReport();
  }

  renderReport() {
    setTimeout(() => {
      this.report_chart?.renderReport();
      this.report_table?.renderReport();
      this.report_numeric?.renderReport();
    });
  }

  generateQuery() {
    if (!!this.report) {
      this.loading_query = true;

      this._generateQuery()
        .then((query_data) => {
          this.query_data = query_data;
          this.renderReport();
        })
        .finally(() => {
          this.loading_query = false;
        });
    }
  }

  private _generateQuery(): Promise<any> {
    if (!!this.project_key) {
      return this.reportService.generateQueryForProject(this.report, this.project_key);
    }
    else if (!!this.selected_accounts.length) {
      return this.reportService.generateQueryForAccounts(this.report, this.selected_accounts);
    }
    else {
      return this.reportService.generateQuery(this.report);
    }
  }

}
