import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TimeUtilService, StateDataService, StateChangeService } from '../../../public-api';
import { Router } from '@angular/router';

import { DateRangeValue, DateRange, DateRangeDropdownItem } from '../../lib.types';
import _ from 'lodash';

@Component({
  selector: 'date-range-dropdown',
  templateUrl: './date-range-dropdown.component.html',
  styleUrls: ['./date-range-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeDropdownComponent implements OnInit, OnChanges {

  readonly custom_range_dropdown_item: DateRangeDropdownItem = {
    value: 'CUSTOM', label: 'Custom', hidden: true
  };

  selected_item: DateRangeDropdownItem = null;

  @Input() dropdown_items?: DateRangeDropdownItem[] = [
    { value: 'WEEK', label: 'This Week' },
    { value: 'LAST_WEEK', label: 'Last Week' },
    { value: 'MONTH', label: 'This Month' },
    { value: 'LAST_MONTH', label: 'Last Month' },
    { value: 'QUARTER', label: 'This Quarter' },
    { value: 'LAST_QUARTER', label: 'Last Quarter' },
    { value: 'ALL_TIME', label: 'All Time' },
    this.custom_range_dropdown_item
  ];

  @Input() disabled: boolean = false;
  @Input() custom_range_enabled: boolean = false;
  @Input() date_range_id: string = null;

  @Output() date_range_selected = new EventEmitter<DateRange>();
  @Output() custom_date_range_selected = new EventEmitter<void>();

  cache_id: string = 'date_range';

  constructor(
    public stateDataService: StateDataService,
    public stateChangeService: StateChangeService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.cache_id = this.date_range_id + '_' + this.stateChangeService.getUrlWithoutParams(this.router.url);
    this._initYearLabels();
    this._initSelectedItem();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.custom_range_enabled) {
      this.custom_range_dropdown_item.hidden = !this.custom_range_enabled;
    }
  }

  selectDateRange(item: DateRangeDropdownItem) {
    this.selected_item = item;

    if (this.selected_item.value === 'CUSTOM') {
      this.custom_date_range_selected.emit();
    }
    else {
      const date_range = TimeUtilService.calculateDateRange(this.selected_item.value, this.selected_item.SOFY_month_index || null);

      this.date_range_selected.emit({
        start_date: date_range.start_date,
        end_date: date_range.end_date
      });
    }

    this.stateDataService.cacheComponentSessionData(
      'DateRangeDropdownComponent', this.cache_id, this.selected_item);
  }

  private _initSelectedItem() {
    let selected_item;
    if (!!this.cache_id) {
      selected_item = this.stateDataService.getCachedComponentSessionData(
        'DateRangeDropdownComponent', this.cache_id);
    }
    if (!selected_item) {
      selected_item = this.dropdown_items[0];
    }
    this.selectDateRange(selected_item);
  }

  private _initYearLabels() {
    for (const item of this.dropdown_items) {
      if (item.value === 'THIS_YEAR' || item.value === 'LAST_YEAR') {
        const date_range = TimeUtilService.calculateDateRange(item.value, item.SOFY_month_index);
        item.label = TimeUtilService.dateToDateTimeString(date_range.start_date, 'MMM yyyy') + ' - '
          + TimeUtilService.dateToDateTimeString(date_range.end_date, 'MMM yyyy');
      }
    }
  }

}
