import { EventEmitter } from '@angular/core';
import { GridsterItem } from 'angular-gridster2';

export abstract class DashGridBlock implements GridsterItem {

  rows: number;
  cols: number;
  x: number;
  y: number;
  block_type: string;
  report_key: number = null;

  dragEnabled: boolean;
  resizeEnabled: boolean;
  layerIndex: number = 1;

  block_id: string;

  constructor(
    rows: number,
    cols: number,
    x: number,
    y: number,
    block_type: string = null,
    report_key: number = null,
    dragEnabled: boolean = true,
    resizeEnabled: boolean = true
  ) {
    this.rows = rows;
    this.cols = cols;
    this.x = x;
    this.y = y;
    this.block_type = block_type;
    this.report_key = report_key;

    this.dragEnabled = !block_type ? false : dragEnabled;
    this.resizeEnabled = !block_type ? false : resizeEnabled;

    this.block_id = this._generateUUID();
  }

  private _generateUUID(): string {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
}

export abstract class DashGridBlockComponent {

  // Inputs
  abstract editing_enabled: boolean;
  abstract is_mobile: boolean;
  abstract block: DashGridBlock;

  // Outputs
  abstract remove_block: EventEmitter<DashGridBlock>;

  // Optional function to be overriden for block components that 
  // need to be re-rendered after being resized
  blockResized(): void { }

}