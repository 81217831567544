import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

import { LibComponentsModule, LibPipesModule, AuthGuard, ModulePathRedirectGuard, AppNavMenuComponent } from 'flexitime-library';
import { SplashComponent } from './splash/splash.component';

import { module_routes as report_routes } from '../report/report.module';

const module_routes: Route[] = [
  {
    path: '',
    component: AppNavMenuComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/loginExternal',
        pathMatch: 'full'
      },
      {
        path: 'report',
        canActivateChild: [ModulePathRedirectGuard],
        children: report_routes
      }
    ]
  },
  {
    path: 'splash',
    component: SplashComponent
  }
];

@NgModule({
  declarations: [
    SplashComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LibPipesModule,
    LibComponentsModule,
    RouterModule.forChild(module_routes)
  ]
})
export class CoreModule { }
