import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DomService } from '../../lib-services/dom/dom.service';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './app-youtube-player.component.html',
  styleUrls: ['./app-youtube-player.component.scss']
})
export class AppYoutubePlayerComponent implements OnInit {

  // Only one of these values are required
  @Input() video_url: string;
  @Input() video_id: string;

  @Output() player_state_changed = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
    DomService.ensureYouTubeApiLoaded();

    if (!!this.video_url) {
      this.video_id = CoreUtilService.getYoutubeVideoID(this.video_url);
    }
  }

  /*
   -1 – unstarted
    0 – ended
    1 – playing
    2 – paused
    3 – buffering
    5 – video cued
  */
  playerStateChanged(state: number) {
    this.player_state_changed.emit(state);
  }

}
