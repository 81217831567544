import { Injectable } from '@angular/core';
import { OnChargeTypeOption, TaskPayRateModifierOption, TaskBillRateModifierOption, TaskOvertimePayRateModifierOption, TaskOvertimeBillRateModifierOption } from '../../lib.types';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

@Injectable({
  providedIn: 'root'
})
export class TaskUtilService {

  static get pay_rate_modifiers(): TaskPayRateModifierOption[] {
    return [
      { value: 'Multiplier', label: 'x', label_description: 'Multiply Rate' },
      { value: 'Add', label: '+', label_description: 'Add to Rate' },
      { value: 'Fixed', label: CoreUtilService.currency_symbol, label_description: 'Fixed Rate' }
    ];
  }

  static get bill_rate_modifiers(): TaskBillRateModifierOption[] {
    return [
      { value: 'Multiplier', label: 'x', label_description: 'Multiply Rate' },
      { value: 'Add', label: '+', label_description: 'Add to Rate' },
      { value: 'Fixed', label: CoreUtilService.currency_symbol, label_description: 'Fixed Rate' },
      { value: 'Margin', label: '%', label_description: 'Margin' }
    ];
  }

  // Only used for overtime, not allowance/deduction
  static get overtime_pay_rate_modifiers(): TaskOvertimePayRateModifierOption[] {
    return [
      { value: 'Multiplier', label: 'x', label_description: 'Multiply Rate' },
      { value: 'Add', label: '+', label_description: 'Add to Rate' }
    ];
  }

  // Only used for overtime, not allowance/deduction
  static get overtime_bill_rate_modifiers(): TaskOvertimeBillRateModifierOption[] {
    return [
      { value: 'Multiplier', label: 'x', label_description: 'Multiply Rate' },
      { value: 'Add', label: '+', label_description: 'Add to Rate' },
      { value: 'Margin', label: '%', label_description: 'Margin' }
    ];
  }

  static get on_charge_types(): OnChargeTypeOption[] {
    return [
      { label: '% of Bill Rate', value: 'Bill' },
      { label: '% of Pay Rate', value: 'Pay' },
      { label: '% of Revenue', value: 'Revenue' },
      { label: '$ Fixed Rate', value: 'Fixed' }
    ];
  }

}
