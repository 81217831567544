<div class="cd-container"
     *ngIf="!is_mobile"
     ngbDropdown
     #ngb_dropdown="ngbDropdown"
     [container]="'body'"
     [placement]="'bottom-right'"
     [autoClose]="'outside'"
     (openChange)="dropdownToggled($event)">

  <div class="cd-buttonContainerOuter"
       ngbDropdownAnchor
       [ngClass]="{ '-hasClock': !!active_clock }"
       (click)="dropdownToggleClicked()">
    <ng-container *ngTemplateOutlet="cd_button">
    </ng-container>
  </div>

  <ul ngbDropdownMenu
      class="cd-clocks">
    <ng-container *ngTemplateOutlet="cd_content">
    </ng-container>
  </ul>
</div>

<div class="cd-container"
     *ngIf="is_mobile">
  <div class="cd-buttonContainerOuter"
       [ngClass]="{ '-hasClock': !!active_clock }"
       (click)="dropdownToggleClicked()">
    <ng-container *ngTemplateOutlet="cd_button">
    </ng-container>
  </div>
</div>

<ng-template #cd_button>
  <div class="cd-buttonContainerFixed"
       *ngIf="!!active_clock && !loading">
    <div class="cd-clock">
      <div class="cd-buttons">
        <button class="-type-icon-round -color-green-translucent cd-button-pausePlay"
                *ngIf="!is_mobile"
                [hidden]="clockService.timer_active_project_task_seconds < interruption_disabled_delay_seconds"
                (click)="clockIn($event)">
          <tooltip-popover [tooltip_content]="'Start an interruption'">
          </tooltip-popover>
          <img [src]="'assets/icons/PausePlay_Icon.svg'" />
        </button>
        <button class="-type-icon-round -color-red-translucent cd-button cd-button-stop"
                (click)="clockOut($event)">
          <i class="ion ion-ios-square"></i>
          <tooltip-popover [tooltip_content]="'Stop Timer'">
          </tooltip-popover>
        </button>
      </div>
    </div>
  </div>

  <div class="cd-buttonContainerInner"
       animationDisabledOnInit
       [ngStyle]="{ 'bottom': container_inner_bottom }">

    <div class="cd-clock"
         *ngFor="let clock_group of clock_groups; let i = index">

      <div class="cd-colour">
        <item-bullet [bullet_colour]="clock_group.project_task?.project.project_colour">
        </item-bullet>
      </div>

      <div class="cd-timer"
           *ngIf="!!clock_group.active_clock">
        <div>{{clockService.timer_active_project_task_seconds | secondsToTimePipe}}</div>
        <div class="-label">:{{clockService.timer_active_project_task_seconds % 60 | number : '2.0-0'}}</div>
      </div>

      <div class="cd-timer"
           *ngIf="!clock_group.active_clock">
        <div>{{clock_group.on_hold_clock_seconds | secondsToTimePipe}}</div>
        <div class="-label">:{{clock_group.on_hold_clock_seconds % 60 | number : '2.0-0'}}</div>
      </div>

      <div class="cd-onHoldCounter"
           *ngIf="i < clock_groups.length - 1">
        {{clock_groups.length}}
      </div>

    </div>

    <div class="cd-clock">
      <div class="cd-clock-startTimerLabel"
           *ngIf="!is_mobile">
        Start Timer
      </div>

      <app-form-item class="cd-clock-newClockNote"
                     *ngIf="is_mobile"
                     [hide_border_without_focus]="true">
        <input placeholder="I'm working on..."
               [(ngModel)]="new_clock_note"
               (blur)="newClockNoteBlurred()"
               (keyup)="newClockNoteKeyup($event)">
      </app-form-item>

      <div class="cd-buttons">
        <button class="-type-icon-round -color-green-translucent cd-button cd-button-start"
                (click)="clockIn($event, null, new_clock_note)">
          <i class="ion ion-ios-play"></i>
        </button>
      </div>
    </div>

  </div>

  <tooltip-popover #tooltip_popover
                   [tooltip_content]="tooltip_content"
                   [manual_trigger_flag]="true"
                   [show_button_flag]="true"
                   [light_theme_flag]="true">
  </tooltip-popover>
</ng-template>

<ng-template #cd_content>
  <active-clock-list (clock_modal_opened)="closeDropdown()"></active-clock-list>

  <button [disabled]="!active_clock || clockService.timer_active_project_task_seconds < interruption_disabled_delay_seconds"
          class="-color-green-hoverFill cd-interruption"
          (click)="clockIn($event)">
    <i class="ion ion-ios-play"></i>
    <span>Interruption</span>
  </button>
</ng-template>
