import _ from 'lodash-es';

import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { InvoiceLineType } from '../../lib.types';

export type KmInvoiceTemplateLine = {
  invoice_template_line_key: number;
  invoice_template_key: number;
  line_index: number;
  line_type: InvoiceLineType;
  line_description: string;
  quantity: number;
  rate: number;
  deleted_flag: boolean;
  lines: KmInvoiceTemplateLine[];
};

export class KmInvoiceTemplate implements PostableObject<KmInvoiceTemplate> {
  invoice_template_key: number;
  template_name: string;
  header_left_text: string;
  header_bottom_text: string;
  footer_text: string;
  lines: KmInvoiceTemplateLine[];
  include_timesheets: boolean;

  constructor(
    invoice_template_key: number,
    template_name: string,
    header_left_text: string,
    header_bottom_text: string,
    footer_text: string,
    lines: KmInvoiceTemplateLine[],
    include_timesheets: boolean
  ) {
    this.invoice_template_key = invoice_template_key;
    this.template_name = template_name;
    this.header_left_text = header_left_text;
    this.header_bottom_text = header_bottom_text;
    this.footer_text = footer_text;
    this.lines = lines;
    this.include_timesheets = include_timesheets;
  }

  formatForPosting(): any {
    if (!this.getErrors().has_errors) {
      const lines = this.formatLinesForPosting();

      return {
        invoice_template_key: this.invoice_template_key,
        template_name: this.template_name,
        header: JSON.stringify({ topLeftModel: this.header_left_text, subHeaderModel: this.header_bottom_text }),
        footer: this.footer_text,
        include_timesheets: this.include_timesheets,
        main_list: lines
      };
    }
    return null;
  }

  formatLinesForPosting(): any {
    const lines = _.cloneDeep(this.lines);

    const formatted_lines = [];

    for (const line of lines) {
      formatted_lines.push({
        invoice_template_line_key: line.invoice_template_line_key,
        invoice_template_key: line.invoice_template_key,
        line_index: line.line_index,
        line_type: line.line_type.value,
        description: line.line_description,
        quantity: line.quantity,
        rate: line.rate,
        template: 'line',
        deleted_flag: line.deleted_flag
      });
    }

    return formatted_lines;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (!this.header_left_text) {
      errors['header_left_text'] = 'Page header required';
    }
    if (!this.header_bottom_text) {
      errors['header_bottom_text'] = 'Invoice header required';
    }
    if (!this.footer_text) {
      errors['footer_text'] = 'Invoice footer required';
    }

    return errors;
  }

  getLineErrors(line: KmInvoiceTemplateLine): PostableObjectErrorMap {
    const errors = {};

    // TODO:

    return errors;
  }

  lineHasErrors(line: KmInvoiceTemplateLine): boolean {
    return Object.keys(this.getLineErrors(line)).length > 0;
  }
}
