
import _ from 'lodash-es';

import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { KmProjectTask } from '../km-project-task/km-project-task';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

export type KmBoardList = {
  // unique identifier used by drag and drop library
  board_list_id: string,
  board_list_title: string,
  project_tasks: KmProjectTask[]
};

export class KmBoard implements PostableObject<KmBoard> {

  readonly board_key: number;
  board_title: string;
  board_lists: KmBoardList[];

  constructor(
    board_key: number = null,
    board_title: string = '',
    board_lists: KmBoardList[] = []
  ) {
    this.board_key = board_key;
    this.board_title = board_title;
    this.board_lists = board_lists;
  }

  newBoardList(board_list_title: string = '') {
    const board_list = {
      board_list_id: CoreUtilService.generateUUID(),
      board_list_title: board_list_title || '',
      project_tasks: []
    };
    this.board_lists.push(board_list);
  }

  deleteBoardList(board_list_id: string) {
    _.remove(this.board_lists, (board_list) => {
      return board_list.board_list_id === board_list_id;
    });
  }

  // TODO:
  addProjectTask(board_list_id: string, project_task: KmProjectTask) {
    if (!this._projectTaskOnBoard(project_task.project_task_key)) {
      for (const board_list of this.board_lists) {
        if (board_list.board_list_id === board_list_id) {
          board_list.project_tasks.push(project_task);
        }
      }
    }
  }

  removeProjectTask(board_list_id: string, project_task_key: number) {
    for (const board_list of this.board_lists) {
      if (board_list.board_list_id === board_list_id) {
        _.remove(board_list.project_tasks, { project_task_key });
      }
    }
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (!this.board_title) {
      errors['board_title'] = 'Board Name required';
    }
    // Check all lists have a title
    for (let i = 0; i < this.board_lists.length; i++) {
      if (!this.board_lists[i].board_list_title) {
        errors['board_list?index=' + i + '.board_list_title'] = 'List name required';
      }
    }

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  formatForPosting(to_delete: boolean = false): any {
    if (!this.getErrors().has_errors) {
      return {
        board_key: this.board_key,
        board_title: this.board_title,
        board_lists: this._formatBoardListsForPosting(),
        deleted_flag: to_delete
      };
    }
  }

  private _formatBoardListsForPosting(): any {
    const data = [];

    for (const board_list of this.board_lists) {
      data.push({
        board_list_id: board_list.board_list_id,
        board_list_title: board_list.board_list_title,
        project_task_keys: board_list.project_tasks.map((project_task) => project_task.project_task_key)
      });
    }

    return data;
  }

  private _projectTaskOnBoard(project_task_key: number) {
    for (const board_list of this.board_lists) {
      for (const project_task of board_list.project_tasks) {
        if (project_task.project_task_key === project_task_key) {
          return true;
        }
      }
    }
    return false;
  }

}
