import { FtIntegrationType, ReportDashBlockConfig } from '../../lib.types';

export class FtUserAccess {

  contact_phone: string;
  full_name: string;
  login_integrations: Set<FtIntegrationType>;
  partner_flag: boolean;
  partner_owner_flag: boolean;
  partner_admin_flag: boolean;
  password_set: boolean;
  registered_email: string;
  subscription_flag: boolean;
  two_factor_enabled: boolean;
  two_factor_required: boolean;
  user_access_key: number;
  username: string;
  verification_email: string;
  first_name: string;
  intercom_settings: any;
  profile_picture: string;
  thumbnail_picture: string;
  dashboard_config: ReportDashBlockConfig[];

  constructor(
    contact_phone: string,
    full_name: string,
    login_integrations: Set<FtIntegrationType>,
    partner_flag: boolean,
    partner_owner_flag: boolean,
    partner_admin_flag: boolean,
    password_set: boolean,
    registered_email: string,
    subscription_flag: boolean,
    two_factor_enabled: boolean,
    two_factor_required: boolean,
    user_access_key: number,
    username: string,
    verification_email: string,
    intercom_settings: string,
    profile_picture: string,
    thumbnail_picture: string,
    dashboard_config: ReportDashBlockConfig[]
  ) {
    this.contact_phone = contact_phone;
    this.full_name = full_name;
    this.login_integrations = login_integrations;
    this.partner_flag = partner_flag;
    this.partner_owner_flag = partner_owner_flag;
    this.partner_admin_flag = partner_admin_flag;
    this.password_set = password_set;
    this.registered_email = registered_email;
    this.subscription_flag = subscription_flag;
    this.two_factor_enabled = two_factor_enabled;
    this.two_factor_required = two_factor_required;
    this.user_access_key = user_access_key;
    this.username = username;
    this.verification_email = verification_email;
    this.first_name = full_name ? full_name.split(' ')[0] : null;
    this.intercom_settings = JSON.parse(intercom_settings);
    this.profile_picture = profile_picture;
    this.thumbnail_picture = thumbnail_picture;
    this.dashboard_config = dashboard_config;
  }

}
