import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { ProjectUtilService } from '../../../lib-services/project-util/project-util.service';

import { Expense } from './../expense';

import _ from 'lodash-es';

export class InvExpense extends Expense {

  segment_key: number;

  ph_expense_key: number;
  ph_employee_key: number;
  ph_project_key: number;

  timesheet_attachment_key: number;
  timesheet_attachment_name: string;
  timesheet_attachment_url: string;

  invoiced_flag: boolean;
  row_version: string;

  inv_new_flag: boolean;
  inv_pending_flag: boolean;
  inv_approved_flag: boolean;
  inv_invoiced_flag: boolean;
  inv_paid_flag: boolean;

  private _project: any;
  private _task: any;
  user: any;

  private _is_active_project: boolean;
  private _ph_employee_manageable_flag: boolean;
  private _inv_response_step: number;

  constructor(
    segment_key: number = null, ph_expense_key: number = null, project: any, user_key: number, task_key: number,
    timesheet_attachment_key: number = null, timesheet_attachment_name: string = null, timesheet_attachment_url: string = null,
    row_version: string = null, ph_employee_manageable_flag: boolean = false,
    inv_pending_flag: boolean = false, inv_approved_flag: boolean = false, inv_invoiced_flag: boolean = false, inv_paid_flag = false,
    expense_amount: number, expense_date: Date, description: string = '',
    ph_authorised_flag: boolean = false, ph_paid_flag: boolean = false, ph_approval_date: Date = null, ph_declined_date: Date = null,
    ph_employee_key: number = null, ph_project_key: number = null
  ) {

    const task = project.projectTasksMap[task_key];
    const user = project.projectUsersMap[user_key];

    super(
      false, true,
      segment_key, expense_amount, expense_date, description,
      ph_approval_date, ph_declined_date, ph_paid_flag, ph_authorised_flag
    );

    this.segment_key = segment_key;

    this.ph_expense_key = ph_expense_key;
    this.ph_employee_key = ph_employee_key;
    this.ph_project_key = ph_project_key;

    this._project = project;
    this._task = task;
    this.user = user;
    this._updateTaskOnProjectChange();
    this._updateIsActiveProject();
    this._updateUnitType();

    this.timesheet_attachment_key = timesheet_attachment_key;
    this.timesheet_attachment_name = timesheet_attachment_name;
    this.timesheet_attachment_url = timesheet_attachment_url;

    this.row_version = row_version;

    this.inv_new_flag = !inv_pending_flag && !inv_approved_flag && !inv_invoiced_flag && !inv_paid_flag;
    this.inv_pending_flag = inv_pending_flag;
    this.inv_approved_flag = inv_approved_flag;
    this.inv_invoiced_flag = inv_invoiced_flag;
    this.inv_paid_flag = inv_paid_flag;

    this._ph_employee_manageable_flag = ph_employee_manageable_flag;

    this._inv_response_step = null;
    this._updateInvResponseStep();
  }

  // Super class function extensions /////////////////////////////////
  get expense_date(): Date {
    return super.expense_date;
  }
  set expense_date(expense_date: Date) {
    super.expense_date = expense_date;
    this._updateIsActiveProject();
  }

  // Super class getters/setters //////////////////////////////////////
  get is_active_project(): boolean {
    return this._is_active_project;
  }

  get inv_locked(): boolean {
    return this._project.archived_flag ||
      this.task.archived_flag ||
      this.inv_approved_flag ||
      this.inv_invoiced_flag ||
      this.inv_paid_flag;
  }
  get ph_locked(): boolean {
    return (!this.ph_authorised_flag && !!this.ph_expense_key) ||
      this.ph_paid_flag ||
      this.ph_declined_flag ||
      (this.ph_approved_flag && !this._ph_employee_manageable_flag);
  }

  get colour(): string {
    return this.task.task_colour;
  }

  _updateUnitType(): void {
    const unit_type = (this.task.unit_type || this.project.rate_type).toLowerCase();

    this._is_custom_unit = ['expense'].indexOf(unit_type) === -1;
    this._unit_type = unit_type;
  }

  // Class getters/setters //////////////////////////////////////
  get project(): any {
    return this._project;
  }
  set project(project: any) {
    this._project = project;

    this._updateUnitType();
    this._updateTaskOnProjectChange();
    this._updateIsActiveProject();
  }

  get task(): any {
    return this._task;
  }
  set task(task: any) {
    this._task = task;
    this._updateUnitType();
  }

  get is_active_task(): boolean {
    return !this.task.archived_flag;
  }
  get ph_employee_manageable_flag() {
    return this._ph_employee_manageable_flag;
  }
  get inv_response_step() {
    return this._inv_response_step;
  }

  get status_color(): string {
    if (this.inv_approved_flag) {
      return '#5eb22e';
    }
    else if (this.inv_pending_flag) {
      return '#fdd835';
    }
    else {
      return '#00adef';
    }
  }
  get status(): string {
    if (this.inv_approved_flag) {
      return 'Approved';
    }
    else if (this.inv_pending_flag) {
      return 'Approval Pending';
    }
    else {
      return 'Unapproved';
    }
  }
  get ph_status_color(): string {
    if (this.ph_pending_flag) {
      return '#999';
    }
    else if (this.ph_approved_flag || this.ph_paid_flag) {
      return '#5eb22e';
    }
    else if (this.ph_declined_flag) {
      return '#d9534f';
    }
    return null;
  }
  get ph_status(): string {
    if (this.ph_pending_flag) {
      return 'Approval Pending';
    }
    else if (this.ph_approved_flag && !this.ph_paid_flag) {
      return 'Approved';
    }
    else if (this.ph_paid_flag) {
      return 'Paid';
    }
    else if (this.ph_declined_flag) {
      return 'Declined';
    }
    return null;
  }

  // Interface functions //////////////////////////////////////
  formatExpenseForPosting(): any {
    const data = new FormData();

    data.append('project_key', this._project.project_key + '');
    data.append('user_key', this.user.user_key + '');
    data.append('task_key', this.task.task_key + '');
    data.append('expense_amount', this.expense_amount + '');
    data.append('expense_date', TimeUtilService.dateToDateString(this.expense_date));
    data.append('description', this.description || '');

    if (this.segment_key) {
      data.append('segment_key', this.segment_key + '');
    }
    if (this.row_version) {
      data.append('row_version', this.row_version);
    }
    if (this.ph_approval_date) {
      data.append('ph_approval_date', TimeUtilService.dateToDateTimeString(this.ph_approval_date));
    }
    if (this.ph_declined_date) {
      data.append('ph_declined_date', TimeUtilService.dateToDateTimeString(this.ph_declined_date));
    }
    if (this.deleted_flag) {
      data.append('deleted_flag', this.deleted_flag + '');
    }

    return data;
  }

  // Class functions //////////////////////////////////////
  private _updateIsActiveProject() {
    this._is_active_project = ProjectUtilService.invProjectIsActive(this.project, this.expense_date);
  }

  // Ensure the current task is valid for the current project
  private _updateTaskOnProjectChange() {
    if (!this.taskIsVisible(this.task)) {
      let task = null;

      for (const t of this.project.tasks) {
        if (this.taskIsVisible(t)) {
          task = t;
          break;
        }
      }

      this.task = task;
    }
  }

  private _updateInvResponseStep() {
    if (this.inv_new_flag) {
      this._inv_response_step = 5;
    }
    if (this.inv_pending_flag) {
      this._inv_response_step = 4;
    }
    if (this.inv_approved_flag) {
      this._inv_response_step = 3;
    }
    if (this.inv_invoiced_flag) {
      this._inv_response_step = 2;
    }
    if (this.inv_paid_flag) {
      this._inv_response_step = 1;
    }
  }

  taskIsVisible(task: any) {
    if (!_.find(this.project.tasks, { task_key: task.task_key })) {
      return false;
    }
    return !task.archived_flag && task.unit_type === 'expense';
  }

}
