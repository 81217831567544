import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash-es';

import { AppTab } from '../../lib.types';
import { StateDataService } from '../../lib-services/state-data/state-data.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './app-tabs.component.html',
  styleUrls: ['./app-tabs.component.scss']
})
export class AppTabsComponent implements OnInit, OnChanges {

  // Two way binding
  @Input() selectedTab: (AppTab | any) = null;
  @Output() selectedTabChange = new EventEmitter<(AppTab | any)>();

  @Input() tabs: (AppTab[] | any[]) = [];
  @Input() cache_disabled: boolean = false;
  @Input() cache_id: string = null;
  @Input() default_tab: AppTab = null;

  scroll_initialised = false;

  constructor(
    public router: Router,
    public stateDataService: StateDataService,
    public element: ElementRef
  ) { }

  ngOnInit(): void {
    this.initDefaultTab();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.tabs) {
      // Only run _initDefaultTab() when tabs are set for the first time
      if (
        !!changes.tabs.currentValue?.length &&
        (!changes.tabs.previousValue || !changes.tabs.previousValue.length)
      ) {
        this.initDefaultTab();
      }
    }
  }

  ngAfterViewChecked() {
    if (!this.scroll_initialised) {
      this._scrollToDefaultPosition();
    }
  }

  selectTab(tab: AppTab) {
    if (!tab.disabled) {
      if (!this.cache_disabled) {
        const cache_id = this.cache_id || this.router.url;

        this.stateDataService.cacheComponentSessionData(
          cache_id,
          'selected_tab_value',
          tab.value
        );
      }

      this.selectedTab = tab;

      // timeout to avoid ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => this.selectedTabChange.emit(this.selectedTab));
    }

    this._cacheTabScrollPosition();
  }

  initDefaultTab() {
    if (!this.selectedTab && !!this.tabs?.length) {
      let tab: AppTab = null;

      // Check if we have a cached tab
      if (!this.cache_disabled) {
        const cache_id = this.cache_id || this.router.url;

        const cached_tab_value = this.stateDataService.getCachedComponentSessionData(
          cache_id,
          'selected_tab_value'
        );

        if (cached_tab_value) {
          tab = _.find(this.tabs, { value: cached_tab_value }) || null;
        }
      }

      if (!tab) {
        // Check if we have a default tab
        if (!!this.default_tab) {
          tab = this.default_tab;
        }
        // Default to first enabled tab
        else {
          for (const t of this.tabs) {
            if (!t.disabled) {
              tab = t;
              break;
            }
          }
        }
      }

      this.selectedTab = tab;
      // timeout to avoid ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => this.selectedTabChange.emit(this.selectedTab));
    }
  }

  //cache current position of tabs
  private _cacheTabScrollPosition() {
    if (!this.cache_disabled) {
      const cache_id = this.cache_id || this.router.url;
      const scroll_left = parseInt(this.element.nativeElement.scrollLeft);

      const cached_positions: Record<string, number> = this.stateDataService.getCachedComponentSessionData(
        'AppTabsComponent',
        'tab_position'
      ) || {};

      cached_positions[cache_id] = scroll_left;

      this.stateDataService.cacheComponentSessionData(
        'AppTabsComponent',
        'tab_position',
        cached_positions
      );
    }
  }

  private _scrollToDefaultPosition() {
    const element_scroll_width = this.element?.nativeElement.scrollWidth;
    const element_width = this.element?.nativeElement.offsetWidth;

    if (element_scroll_width > element_width) {
      const default_scroll_left = 0;
      const scroll_position = this._getCachedTabScrollPosition();
      const scroll_left = scroll_position || default_scroll_left;

      this._scrollTo(scroll_left);
      this.scroll_initialised = true;
    }
  }

  private _getCachedTabScrollPosition() {
    if (!this.cache_disabled) {
      const cache_id = this.cache_id || this.router.url;
      const cached_positions = this.stateDataService.getCachedComponentSessionData(
        'AppTabsComponent',
        'tab_position'
      ) || {};

      return cached_positions[cache_id] || 0;
    }
    return 0;
  }

  private _scrollTo(scroll_left: number) {
    this.element.nativeElement.scrollLeft = scroll_left;
  }

}
