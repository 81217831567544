import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements AppModalInterface {

  @Input() message: string;

  @Output() close = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<void>();

  cancel() {
    this.close.emit();
  }

}
