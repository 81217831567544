import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { TaskBillRateModifier, TaskPayRateModifier, TaskOvertimeType, TaskOvertimeDeductionPeriod } from '../../lib.types';

export class InvTaskOvertime implements PostableObject<InvTaskOvertime> {

  task_key: number;
  allowance_flag: boolean;
  deduction_flag: boolean;
  overtime_type: TaskOvertimeType;
  bill_type: TaskBillRateModifier;
  pay_type: TaskPayRateModifier;
  private _deduction_period: TaskOvertimeDeductionPeriod;
  include_oncosts: boolean;
  overtime_name: string;
  pay_code: string;
  task_overtime_key: number;
  mon_flag: boolean;
  tue_flag: boolean;
  wed_flag: boolean;
  thu_flag: boolean;
  fri_flag: boolean;
  sat_flag: boolean;
  sun_flag: boolean;
  week_flag: boolean;
  public_holiday_flag: boolean;
  from_hours: number;

  bill_rate: number = null;
  pay_rate: number = null;

  constructor(
    task_key: number,
    allowance_flag: boolean = false,
    deduction_flag: boolean = false,
    overtime_type: TaskOvertimeType = 'Overtime',
    bill_type: TaskBillRateModifier = 'Multiplier',
    pay_type: TaskPayRateModifier = 'Multiplier',
    deduction_period: TaskOvertimeDeductionPeriod = 'per day',
    include_oncosts: boolean = false,
    overtime_name: string = '',
    pay_code: string = null,
    task_overtime_key: number = null,
    mon_flag: boolean = false,
    tue_flag: boolean = false,
    wed_flag: boolean = false,
    thu_flag: boolean = false,
    fri_flag: boolean = false,
    sat_flag: boolean = false,
    sun_flag: boolean = false,
    week_flag: boolean = false,
    public_holiday_flag: boolean = false,
    from_hours: number = null,
    pay_add: number = null,
    pay_fixed: number = null,
    pay_multiplier: number = null,
    bill_add: number = null,
    bill_fixed: number = null,
    bill_multiplier: number = null,
    bill_margin: number = null
  ) {
    this.task_key = task_key;
    this.allowance_flag = allowance_flag;
    this.deduction_flag = deduction_flag;
    this.overtime_type = overtime_type;
    this.bill_type = bill_type;
    this.pay_type = pay_type;
    this.deduction_period = deduction_period;
    this.include_oncosts = include_oncosts;
    this.overtime_name = overtime_name;
    this.pay_code = pay_code;
    this.task_overtime_key = task_overtime_key;
    this.mon_flag = mon_flag;
    this.tue_flag = tue_flag;
    this.wed_flag = wed_flag;
    this.thu_flag = thu_flag;
    this.fri_flag = fri_flag;
    this.sat_flag = sat_flag;
    this.sun_flag = sun_flag;
    this.week_flag = week_flag;
    this.public_holiday_flag = public_holiday_flag;
    this.from_hours = from_hours;

    this._initBillRate(
      bill_add,
      bill_fixed,
      bill_margin,
      bill_multiplier
    );
    this._initPayRate(
      pay_add,
      pay_fixed,
      pay_multiplier
    );
  }

  get deduction_period(): TaskOvertimeDeductionPeriod {
    return this._deduction_period;
  }
  set deduction_period(period: TaskOvertimeDeductionPeriod) {
    if (this.deduction_flag) {
      this._deduction_period = period;

      // 'per hour' uses pay_add while day and week use pay_fixed
      if (this._deduction_period === 'per hour') {
        this.pay_type = 'Add';
      }
      else {
        this.pay_type = 'Fixed';
      }
    }
    else {
      this._deduction_period = null;
    }
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (!this.overtime_name) {
      errors['overtime_name'] = 'Overtime name required';
    }

    if (this.deduction_flag) {
      if (!CoreUtilService.numberIsValid(this.pay_rate) || this.pay_rate === 0) {
        errors['pay_rate'] = 'Deduction amount required';
      }
    }
    else {
      if (!CoreUtilService.numberIsValid(this.from_hours) || this.from_hours === 0) {
        errors['from_hours'] = 'Hour threshold required';
      }
      if (!CoreUtilService.numberIsValid(this.pay_rate) || this.pay_rate === 0) {
        errors['pay_rate'] = 'Pay rate required';
      }
      if (!CoreUtilService.numberIsValid(this.bill_rate) || this.bill_rate === 0) {
        errors['bill_rate'] = 'Bill rate required';
      }
    }

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  formatForPosting(to_delete: boolean = false): any {
    if (!this.getErrors().has_errors) {

      const from_hours = this.deduction_flag ? 0 : this.from_hours;

      let week_flag = this.week_flag;
      if (this.deduction_flag) {
        week_flag = this.deduction_period === 'per week';
      }

      if (CoreUtilService.country_key !== 'NZ') {
        this.public_holiday_flag = false;
      }

      return {
        allowance_flag: this.allowance_flag,
        deduction_flag: this.deduction_flag,
        overtime_type: this.overtime_type,
        bill_type: this.bill_type,
        pay_type: this.pay_type,
        deduction_period: this.deduction_period,
        deleted_flag: to_delete,
        include_oncosts: this.include_oncosts,
        overtime_name: this.overtime_name,
        pay_code: this.pay_code,
        task_key: this.task_key,
        task_overtime_key: this.task_overtime_key,
        mon_flag: this._getDayFlag('mon_flag'),
        tue_flag: this._getDayFlag('tue_flag'),
        wed_flag: this._getDayFlag('wed_flag'),
        thu_flag: this._getDayFlag('thu_flag'),
        fri_flag: this._getDayFlag('fri_flag'),
        sat_flag: this._getDayFlag('sat_flag'),
        sun_flag: this._getDayFlag('sun_flag'),
        week_flag,
        public_holiday_flag: this.public_holiday_flag,
        from_hours,
        pay_add: this._getPayRateValue('Add'),
        pay_fixed: this._getPayRateValue('Fixed'),
        pay_multiplier: this._getPayRateValue('Multiplier'),
        bill_add: this._getBillRateValue('Add'),
        bill_fixed: this._getBillRateValue('Fixed'),
        billable_multiplier: this._getBillRateValue('Multiplier'),
        bill_margin: this._getBillRateValue('Margin')
      };
    }
    return null;
  }

  private _getDayFlag(flag_name: string) {
    if (this.deduction_flag) {
      return this.deduction_period !== 'per week';
    }
    else {
      return this[flag_name];
    }
  }

  private _getPayRateValue(pay_type: TaskPayRateModifier) {
    let rate = null;

    if (this.pay_type === pay_type) {
      rate = this.pay_rate;

      // Deduction rate stored as negative value in DB
      // but treated as positive in front end
      if (this.deduction_flag) {
        rate = rate * -1;
      }
    }
    return rate;
  }

  private _getBillRateValue(bill_type: TaskBillRateModifier) {
    if (this.deduction_flag) {
      return null;
    }
    else {
      this.bill_type === bill_type ? this.bill_rate : null;
    }
  }

  private _initBillRate(
    bill_add: number,
    bill_fixed: number,
    bill_margin: number,
    billable_multiplier: number
  ) {
    if (this.deduction_flag) {
      this.bill_rate = null;
    }
    else {
      switch (this.bill_type) {
        case 'Add':
          this.bill_rate = bill_add;
          break;
        case 'Fixed':
          this.bill_rate = bill_fixed;
          break;
        case 'Margin':
          this.bill_rate = bill_margin;
          break;
        case 'Multiplier':
          this.bill_rate = billable_multiplier;
          break;
      }
    }
  }

  private _initPayRate(
    pay_add: number,
    pay_fixed: number,
    pay_multiplier: number
  ) {
    let rate = null;

    switch (this.pay_type) {
      case 'Add':
        rate = pay_add;
        break;
      case 'Fixed':
        rate = pay_fixed;
        break;
      case 'Multiplier':
        rate = pay_multiplier;
        break;
    }

    // Deduction rate stored as negative value in DB
    // but treated as positive in front end
    if (this.deduction_flag) {
      rate = rate * -1;
    }
    this.pay_rate = rate;
  }

}
