import { DpTeam } from '../../dp-team/dp-team';
import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { PostableObject, PostableObjectErrorMap } from '../../../lib-interfaces/postable-object.interface';
import { CoreUtilService } from '../../../lib-services/core-util/core-util.service';

import { DpSkill } from '../../dp-skill/dp-skill';
import { DpPerson } from '../../dp-person/dp-person';

import { Segment } from '../segment';

import * as _ from 'lodash-es';



export type SegmentClockNote = {
  clock_note_key: number,
  note: string,
  updated_date: Date
};
export type SegmentClockBreak = {
  clock_break_key: number,
  start_time: Date;
  end_time: Date;
  break_duration: number;
};
export type SegmentClock = {
  clock_key: number;
  clock_in_time: Date,
  clock_out_time: Date,
  clock_notes: SegmentClockNote[],
  clock_breaks: SegmentClockBreak[]
};

export class DpSegment extends Segment implements PostableObject<DpSegment> {

  company_key: number;
  description: string;

  segment_clock: SegmentClock;

  skill: DpSkill;
  person: DpPerson;
  team: DpTeam;

  constructor(
    unit_flag: boolean = false,
    credit_flag: boolean = false,
    units: number = null,
    segment_key: number = null, deleted_flag: boolean = false, row_version: string, segment_date: Date,
    start_time: Date = null, end_time: Date = null, break_duration: number = 0,
    company_key: number, description: string = '', person: DpPerson, skill: DpSkill, team: DpTeam,
    segment_clock: SegmentClock = null
  ) {
    if (start_time === null) {
      start_time = _.cloneDeep(segment_date);
      start_time.setHours(9, 0, 0, 0);
    }
    /*
    if (end_time === null || moment(end_time).isSameOrBefore(moment(start_time))) {
      end_time = TimeUtilService.incrementHours(start_time, 1);
    }*/

 
    super(
      false, false, true,
      segment_key, deleted_flag, row_version, unit_flag, credit_flag,
      segment_date, start_time, end_time, break_duration, units
    );
    this.company_key = company_key;
    this.description = description;
    this.skill = skill;
    this.person = person;
    this.team = team;

    this.segment_clock = segment_clock;
    this._updateUnitType();
  }

  get colour(): string {
    return this.skill.skill_colour;
  }
  get skill_name(): string {
    return this.skill.skill_name;
  }

  get is_locked(): boolean {
    return false;
  }
  get work_name(): string {
    return "test";
  }

  _updateUnitType(): void {
    const unit_type = ('hours').toLowerCase();

    this._is_custom_unit = ['hours', 'days', 'expense'].indexOf(unit_type) === -1;
    this._unit_type = unit_type;
  }

  

  // Interface functions //////////////////////////////////////
  formatForPosting(): any {
    return {
      segment_key: this.segment_key,
      row_version: this.row_version,
      deleted_flag: this.deleted_flag,
      segment_date: TimeUtilService.formatDateForPosting(this.segment_date, false),
      start_time: TimeUtilService.formatDateForPosting(this.start_time, true),
      end_time: TimeUtilService.formatDateForPosting(this.end_time, true),
      break_duration: this.break_duration,
      company_key: this.company_key,
      person_key: this.person.person_key,
      skill_key: this.skill.skill_key,
      team_key: this.team?.team_key || null,
      description: this.description
    };
  }

}
