<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>Add to Home</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>

    <div class="app-navRow -tabRow"
         *ngIf="tabs.length > 1">
      <app-tabs [tabs]="tabs"
                [(selectedTab)]="selected_tab"
                (selectedTabChange)="selectTab($event)">
      </app-tabs>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="dgbm-blocks">

      <div class="dgbm-block"
           *ngFor="let block of available_blocks[selected_tab.value]"
           (click)="addBlock(block)">
        <div class="dgbm-block-label">
          {{block.label}}
        </div>
        <div class="dgbm-block-thumbnail">
          <report-thumbnail *ngIf="block.report_key"
                            [report]="reports_map[block.report_key]">
          </report-thumbnail>
          <i class="dgbm-block-thumbnail-icon"
             *ngIf="!block.report_key"
             [ngClass]="[ widget_thumbnails[block.block_type].icon_class_prefix, widget_thumbnails[block.block_type].icon_class ]">
          </i>
        </div>
      </div>

    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterRight">
      <button class="-color-white-outline"
              (click)="cancel()">
        Close
      </button>
    </div>
  </div>
</div>
