<div class="app-list rfs-filterContainer">

  <div class="app-list-item -itemSelectDisabled"
       *ngFor="let filter of report.filters; let filter_i = index">
    <div class="app-list-itemRow">

      <div class="app-list-itemCell">
        <button class="-type-text -color-link-clear rfs-editFilter"
                (click)="editFilter(filter_i)">
          <i class="ion ion-md-create"></i>
          <div>{{filter.field.label}}</div>
        </button>
      </div>
      <div class="app-list-itemCell -rowDelete"
           (click)="deleteFilter(filter_i)">
        <i class="ion ion-md-close app-remove"></i>
      </div>
    </div>
    <div class="app-list-itemRow">
      <div class="rfs-filterDetails">

        <div class="rfs-filterDetails-header"
             *ngIf="filter.func === 'IN'">
          <div>{{filter.inverse ? 'Excluded' : 'Included'}} Values:</div>
        </div>

        <div class="rfs-filterDetails-values -list"
             *ngIf="filter.func === 'IN'">
          <div class="-value"
               *ngFor="let arg of filter.args; let arg_i = index"
               [ngClass]="{ '-isNull': arg === null }">
            <ng-container *ngIf="arg === null">
              No {{filter.field.label}}
            </ng-container>
            <ng-container *ngIf="arg !== null">
              {{arg | reportFilterArg : filter}}
            </ng-container>
          </div>
        </div>

        <div class="rfs-filterDetails-values -range"
             *ngIf="filter.func === 'BETWEEN'">
          <div>{{filter.inverse ? 'Outside of' : 'Between'}}</div>
          <div class="-value">
            &nbsp;{{filter.args[0] | reportFilterArg : filter}}&nbsp;
          </div>
          <div>and</div>
          <div class="-value">
            &nbsp;{{filter.args[1] | reportFilterArg : filter}}
          </div>
        </div>

        <div class="rfs-filterDetails-values -dynamic"
             *ngIf="filter.func !== 'IN' && filter.func !== 'BETWEEN'">
          <div class="-value"
               *ngFor="let arg of filter.args; let arg_i = index">
               {{arg | reportFilterArg : filter}}&nbsp;
          </div>
          <div class="-value"
               *ngIf="!filter.args.length">
            {{formatDynamicFunctionName(filter.func)}}
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="app-list-empty"
       *ngIf="!report.filters.length">
    <div class="app-list-emptyDescription">
      No filters for this report
    </div>
  </div>
</div>
