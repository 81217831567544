import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { NavMenuServiceAbstract } from '../../../public-api';
import { StateAccessServiceInterface } from '../../lib-interfaces/state-access-service.interface';
import { StateChangeService } from '../../lib-services/state-change/state-change.service';

@Injectable({
  providedIn: 'root'
})
export class ModulePathRedirectGuard implements CanActivate, CanActivateChild {

  constructor(
    public router: Router,
    public stateChangeService: StateChangeService,
    @Inject('navMenuService') public navMenuService: NavMenuServiceAbstract,
    @Inject('stateAccessService') public stateAccessService: StateAccessServiceInterface
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this._doCheck(state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this._doCheck(state);
  }

  private _doCheck(state: RouterStateSnapshot): boolean {
    const url_segment_paths = this.stateChangeService.getUrlSegmentPaths(state.url);

    const item_path = url_segment_paths[0];
    let item_child_path = url_segment_paths.length > 1 ? url_segment_paths[1] : null;
    if (this.stateAccessService.canAccessRoute([item_path])) {
      if (
        !!item_child_path &&
        this.stateAccessService.canAccessRoute([item_path, item_child_path])
      ) {
        return true;
      }
      else {
        item_child_path = this.navMenuService.getDefaultChildPathForMenuItem(item_path);

        if (!!item_child_path) {
          this.router.navigate([item_path + '/' + item_child_path]);
          return false;
        }
      }
    }
    this.router.navigate(['splash']);
    return false;
  }


}
