import { DashGridBlock, ReportType } from '../../../public-api';

export class ReportDashBlock extends DashGridBlock {

  constructor(
    rows: number,
    cols: number,
    x: number,
    y: number,
    block_type: ReportType,
    report_key: number
  ) {
    super(rows, cols, x, y, block_type, report_key);
  }

}