import { Component, Input } from '@angular/core';
import { Duration } from 'luxon';

import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';

@Component({
  selector: 'duration-split-input',
  templateUrl: './duration-split-input.component.html',
  styleUrls: ['./duration-split-input.component.scss'],
  providers: [
    MakeProvider(DurationSplitInputComponent)
  ]
})
export class DurationSplitInputComponent extends AbstractValueAccessor {

  @Input() input_disabled: boolean = false;
  @Input() bold_non_zeros: boolean = false;

  _value: number;

  hours: number = 0;
  minutes: number = 0;

  private _previous_hours: number = 0;
  private _previous_minutes: number = 0;

  // TODO: remove pipe from ngModels,
  // track hours/mins as separate number and string values for better change detection

  writeValue(value: any){
    super.writeValue(value);
    if (CoreUtilService.numberIsValid(value)){
      const hours_mins = TimeUtilService.hoursDecimalAsHoursAndMinutes(value);
      this.hours = hours_mins[0];
      this.minutes = hours_mins[1];
    }
    else {
      this.hours = 0;
      this.minutes = 0;
    }
  }

  hoursUpdated(hours: any) {
    hours = parseFloat(hours);

    if (CoreUtilService.numberIsValid(hours) && hours <= 24 && hours >= 0) {
      this.hours = hours;
      this._previous_hours = hours;

      if (this.hours === 24){
        this.minutesUpdated(0);
      }
      else {
        this.updateDuration();
      }
    }
    else {
      this.hours = this._previous_hours;
    }
  }

  minutesUpdated(minutes: any) {
    minutes = parseFloat(minutes);

    if (CoreUtilService.numberIsValid(minutes)){
      if (this.hours === 24){
        if (minutes === 0){
          this.minutes = minutes;
          this._previous_minutes = minutes;
          this.updateDuration();
        }
        else {
          this.minutes = this._previous_minutes;
        }
      }
      else {
        if (minutes <= 59 && minutes >= 0){
          this.minutes = minutes;
          this._previous_minutes = minutes;
          this.updateDuration();
        }
        else {
          this.minutes = this._previous_minutes;
        }
      }
    }
  }

  updateDuration(){
    this.value = this.hours + Duration.fromObject({ minutes: this.minutes }).as('hours');
  }

}
