import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { TaskBillRateModifier, TaskPayRateModifier } from '../../lib.types';
import { InvTaskOvertime } from '../inv-task-overtime/inv-task-overtime';
import { InvTaskOncost } from '../inv-task-oncost/inv-task-oncost';

export class InvTask implements PostableObject<InvTask> {

  allow_expenses: boolean;
  account_code: string;
  archived_flag: boolean;
  pay_code: string;
  bill_type: TaskBillRateModifier;
  pay_type: TaskPayRateModifier;
  task_colour: string;
  common_flag: boolean;
  created_date: Date;
  updated_date: Date;
  default_break_duration: number;
  include_oncosts: boolean;
  notes: string;
  on_cost_rate: number;
  payhero_project_key: number;
  task_display_name: string;
  task_key: number;
  task_name: string;
  task_rate: number;
  tax_rate: number;
  tax_type: string;

  private _unit_flag: boolean;
  private _unit_type: string;

  monday_hours: number;
  tuesday_hours: number;
  wednesday_hours: number;
  thursday_hours: number;
  friday_hours: number;
  saturday_hours: number;
  sunday_hours: number;

  bill_rate: number = null;
  pay_rate: number = null;

  overtime: InvTaskOvertime[];
  on_costs: InvTaskOncost[];

  constructor(
    allow_expenses: boolean,
    account_code: string = null,
    archived_flag: boolean = false,
    pay_code: string = null,
    bill_type: TaskBillRateModifier = 'Multiplier',
    pay_type: TaskPayRateModifier = 'Multiplier',
    task_colour: string = '#F9A825',
    common_flag: boolean = false,
    created_date: Date = null,
    updated_date: Date = null,
    default_break_duration: number = null,
    include_oncosts: boolean = false,
    notes: string = '',
    on_cost_rate: number = null,
    payhero_project_key: number = null,
    task_display_name: string = '',
    task_key: number = null,
    task_name: string = '',
    task_rate: number = 0,
    tax_rate: number = null,
    tax_type: string = null,
    unit_flag: boolean = false,
    unit_type: string = null,
    monday_hours: number = 0,
    tuesday_hours: number = 0,
    wednesday_hours: number = 0,
    thursday_hours: number = 0,
    friday_hours: number = 0,
    saturday_hours: number = 0,
    sunday_hours: number = 0,
    pay_add: number = null,
    pay_fixed: number = null,
    pay_multiplier: number = null,
    bill_add: number = null,
    bill_fixed: number = null,
    billable_multiplier: number = null,
    bill_margin: number = null,
    overtime: InvTaskOvertime[] = [],
    on_costs: InvTaskOncost[] = [],
  ) {
    this.allow_expenses = allow_expenses;
    this.account_code = account_code;
    this.archived_flag = archived_flag;
    this.pay_code = pay_code;
    this.bill_type = bill_type;
    this.pay_type = pay_type;
    this.task_colour = task_colour;
    this.common_flag = common_flag;
    this.created_date = created_date;
    this.updated_date = updated_date;
    this.default_break_duration = default_break_duration;
    this.include_oncosts = include_oncosts;
    this.notes = notes;
    this.on_cost_rate = on_cost_rate;
    this.payhero_project_key = payhero_project_key;
    this.task_display_name = task_display_name;
    this.task_key = task_key;
    this.task_name = task_name;
    this.task_rate = task_rate;
    this.tax_rate = tax_rate;
    this.tax_type = tax_type;
    this._unit_flag = unit_flag;
    this._unit_type = unit_type;
    this.monday_hours = monday_hours;
    this.tuesday_hours = tuesday_hours;
    this.wednesday_hours = wednesday_hours;
    this.thursday_hours = thursday_hours;
    this.friday_hours = friday_hours;
    this.saturday_hours = saturday_hours;
    this.sunday_hours = sunday_hours;
    this.overtime = overtime;
    this.on_costs = on_costs;

    this._initBillRate(
      bill_add,
      bill_fixed,
      bill_margin,
      billable_multiplier
    );
    this._initPayRate(
      pay_add,
      pay_fixed,
      pay_multiplier
    );
  }

  get unit_flag(): boolean {
    return this._unit_flag;
  }

  get unit_type(): string {
    return this._unit_type;
  }
  set unit_type(value: string) {
    if (value?.toLowerCase() === 'expense') {
      value = 'expense';
    }

    this._unit_flag = value !== null;
    this._unit_type = value;

    if (this._unit_flag) {
      if (this._unit_type === 'expense') {
        this.pay_type = 'Multiplier';
        this.bill_type = 'Multiplier';
      }
      else {
        this.pay_type = 'Fixed';
        this.bill_type = 'Fixed';
      }
    }
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (!this.task_name) {
      errors['task_name'] = CoreUtilService.task_label.capitalised + ' name required';
    }
    if (!this.task_display_name) {
      errors['task_display_name'] = CoreUtilService.task_label.capitalised + ' display name required';
    }
    if (!CoreUtilService.numberIsValid(this.pay_rate) || this.pay_rate === 0) {
      errors['pay_rate'] = 'Pay rate modifier required';
    }
    if (!CoreUtilService.numberIsValid(this.bill_rate) || this.bill_rate === 0) {
      errors['bill_rate'] = 'Bill rate modifier required';
    }
    if (this._unit_flag && !this._unit_type) {
      errors['unit_type'] = 'Unit type required';
    }

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  formatForPosting(to_archive: boolean = false, work_pattern_enabled: boolean = false): any {
    if (!this.getErrors().has_errors) {

      // 0 Results in clearing out field in DB
      if (!!this._unit_type || !this.default_break_duration) {
        this.default_break_duration = 0;
      }
      if (!work_pattern_enabled) {
        this.monday_hours = 0;
        this.tuesday_hours = 0;
        this.wednesday_hours = 0;
        this.thursday_hours = 0;
        this.friday_hours = 0;
        this.saturday_hours = 0;
        this.sunday_hours = 0;
      }

      return {
        account_code: this.account_code,
        archived_flag: to_archive,
        pay_code: this.pay_code,
        bill_type: this.bill_type,
        pay_type: this.pay_type,
        colour: this.task_colour,
        common_flag: this.common_flag,
        default_break_duration: this.default_break_duration,
        include_oncosts: this.include_oncosts,
        notes: this.notes,
        on_cost_rate: this.on_cost_rate,
        payhero_project_key: this.payhero_project_key,
        task_display_name: this.task_display_name,
        task_key: this.task_key,
        task_name: this.task_name,
        task_rate: this.task_rate,
        tax_rate: this.tax_rate,
        tax_type: this.tax_type,
        unit_flag: this._unit_flag,
        unit_type: this._unit_type,
        monday_hours: this.monday_hours,
        tuesday_hours: this.tuesday_hours,
        wednesday_hours: this.wednesday_hours,
        thursday_hours: this.thursday_hours,
        friday_hours: this.friday_hours,
        saturday_hours: this.saturday_hours,
        sunday_hours: this.sunday_hours,
        pay_add: this._getPayRateValue('Add'),
        pay_fixed: this._getPayRateValue('Fixed'),
        pay_multiplier: this._getPayRateValue('Multiplier'),
        bill_add: this._getBillRateValue('Add'),
        bill_fixed: this._getBillRateValue('Fixed'),
        billable_multiplier: this._getBillRateValue('Multiplier'),
        bill_margin: this._getBillRateValue('Margin')
      };
    }
    return null;
  }

  private _getPayRateValue(pay_type: TaskPayRateModifier) {
    return this.pay_type === pay_type ? this.pay_rate : null;
  }

  private _getBillRateValue(bill_type: TaskBillRateModifier) {
    this.bill_type === bill_type ? this.bill_rate : null;
  }

  private _initBillRate(
    bill_add: number,
    bill_fixed: number,
    bill_margin: number,
    billable_multiplier: number
  ) {
    switch (this.bill_type) {
      case 'Add':
        this.bill_rate = bill_add;
        break;
      case 'Fixed':
        this.bill_rate = bill_fixed;
        break;
      case 'Margin':
        this.bill_rate = bill_margin;
        break;
      case 'Multiplier':
        this.bill_rate = billable_multiplier;
        break;
    }
  }

  private _initPayRate(
    pay_add: number,
    pay_fixed: number,
    pay_multiplier: number
  ) {
    switch (this.pay_type) {
      case 'Add':
        this.pay_rate = pay_add;
        break;
      case 'Fixed':
        this.pay_rate = pay_fixed;
        break;
      case 'Multiplier':
        this.pay_rate = pay_multiplier;
        break;
    }
  }

}