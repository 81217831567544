<div class="np-notification"
     [@animateOnDestroy]
     #notification
     *ngFor="let notification of _notifications; let i = index"
     [ngClass]="type_to_theme_map[notification.notification_type]"
     [ngStyle]="{ 'bottom': notification_bottoms[i] + 'px'}">
  <div class="np-notification-message"
       *ngIf="!notification.is_html">
    {{notification.notification_message}}
  </div>
  <div class="np-notification-message"
       *ngIf="notification.is_html"
       [innerHTML]="notification.notification_message | safeHtml">
  </div>
  <i class="ion ion-md-close app-remove"
     (click)="removeNotification(notification.id)">
  </i>
</div>
