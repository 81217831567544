import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[componentLoader]'
})
export class ComponentLoaderDirective {

  constructor(
    public view_container_ref: ViewContainerRef
  ) { }

}
