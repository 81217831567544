import { Injectable } from '@angular/core';

import {
  ApiService, CoreUtilService, ReportDashBlockConfig
} from 'flexitime-library';

@Injectable({
  providedIn: 'root'
})
export class FtReportService {

  dashboard_config: ReportDashBlockConfig[] = [];

  service_setup = false;

  constructor(
    public apiService: ApiService
  ) { }

  initialiseService(force_reload = false) {
    return new Promise<void>((resolve, reject) => {
      if (this.service_setup && !force_reload) {
        resolve();
      }
      else {
        this.service_setup = false;

        this.loadDashboardConfig()
          .then(() => {
            this.service_setup = true;
            resolve();
          })
          .catch((err) => reject(err));
      }
    });
  }

  clearServiceData() {
    this.dashboard_config = [];
    this.service_setup = false;
  }

  getDashboardConfig() {
    return this.dashboard_config;
  }

  loadDashboardConfig() {
    return new Promise<void>((resolve, reject) => {

      this.apiService.get('FLEXITIME', 'reporting/dashboard_config')
        .then((res) => {
          this.dashboard_config = CoreUtilService.parseJSON(res.dashboard_config) || [];
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  updateDashboardConfig(dashboard_config: any) {
    return new Promise<void>((resolve, reject) => {

      const data = {
        dashboard_config: JSON.stringify(dashboard_config)
      };

      this.apiService.post('FLEXITIME', 'reporting/dashboard_config', data)
        .then(() => {
          this.dashboard_config = dashboard_config;
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

}
