<div class="wdst-dateTotalRow"
     *ngIf="selected_day_index === null">
  <div class="wdst-dateTotal"
       *ngFor="let day of weekly_totals.days; let i = index"
       [ngClass]="{
          '-hasTime': day > 0}">
    <div *ngIf="weekly_totals.unit_type === 'days'">
      {{day | appNumber}}
    </div>
    <div *ngIf="weekly_totals.unit_type === 'hours'">
      {{day | hoursToTimePipe}}
    </div>
  </div>
</div>

<div class="wdst-weekTotalRow"
     *ngIf="selected_day_index !== null">
  <div class="wdst-weekTotal">
    <div class="-label">
      Total {{weekly_totals.unit_type}}:
    </div>
    <div *ngIf="weekly_totals.unit_type === 'days' && selected_day_index === null">
      {{weekly_totals.week | appNumber}}
    </div>
    <div *ngIf="weekly_totals.unit_type === 'days' && selected_day_index !== null">
      {{weekly_totals.days[selected_day_index] | appNumber}}
    </div>

    <div *ngIf="weekly_totals.unit_type === 'hours' && selected_day_index === null">
      {{weekly_totals.week | hoursToTimePipe}}
    </div>
    <div *ngIf="weekly_totals.unit_type === 'hours' && selected_day_index !== null">
      {{weekly_totals.days[selected_day_index] | hoursToTimePipe}}
    </div>
  </div>
</div>
