import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';
import { PostableObject, PostableObjectErrorMap } from './../../lib-interfaces/postable-object.interface';
import { TeamUtilService } from '../../lib-services/team-util/team-util.service';
import { DpTeamPerson } from '../dp-team-person/dp-team-person';
import { DpTeamManager } from '../dp-team-manager/dp-team-manager';
import { DpPerson } from '../dp-person/dp-person';
import { DpCompanyAdmin } from '../dp-company-admin/dp-company-admin';


import * as _ from 'lodash-es';

export class DpTeam implements PostableObject<DpTeam> {

  team_key: number;
  team_name: string;
  default_skill_key: number;

  team_managers: DpTeamManager[];
  team_people: DpTeamPerson[];

  archived_flag: boolean;

  constructor(
    team_key: number = null, team_name: string = '', default_skill_key: number = null,
    team_people: DpTeamPerson[] = [], team_managers: DpTeamManager[] = [], archived_flag: boolean = false
  ) {
    this.team_key = team_key;
    this.team_name = team_name;
    this.default_skill_key = default_skill_key;
    this.team_managers = team_managers;
    this.team_people = team_people;
    this.archived_flag = archived_flag;
  }

  addTeamManager(admin: DpCompanyAdmin) {
    const teamManagerIndex = TeamUtilService.getTeamManagerIndex(this, admin.user_access_company_key);

    if (teamManagerIndex === -1) {
      this.team_managers.push(new DpTeamManager(null, admin));
    }
    else {
      this.team_managers[teamManagerIndex].deleted_flag = false;
    }
    // Ensure change detections are triggered
    this.team_managers = _.clone(this.team_managers);
  }

  removeTeamManager(user_access_company_key: number) {
    const teamManagerIndex = TeamUtilService.getTeamManagerIndex(this, user_access_company_key);

    if (teamManagerIndex !== -1) {
      this.team_managers[teamManagerIndex].deleted_flag = true;
    }
    // Ensure change detections are triggered
    this.team_managers = _.clone(this.team_managers);
  }

  getTeamPerson(person_key: number) {
    const teamPersonIndex = TeamUtilService.getTeamPersonIndex(this, person_key);

    if (teamPersonIndex !== -1) {
      return this.team_people[teamPersonIndex];
    }

    return null;
  }

  addTeamPerson(person: DpPerson) {
    const teamPersonIndex = TeamUtilService.getTeamPersonIndex(this, person.person_key);

    if (teamPersonIndex === -1) {
      this.team_people.push(new DpTeamPerson(null, this.team_key, person));
    }
    else {
      this.team_people[teamPersonIndex].deleted_flag = false;
    }
    // Ensure change detections are triggered
    this.team_people = _.clone(this.team_people);
  }

  removeTeamPerson(person_key: number) {
    const teamPersonIndex = TeamUtilService.getTeamPersonIndex(this, person_key);

    if (teamPersonIndex !== -1) {
      this.team_people[teamPersonIndex].deleted_flag = true;
    }
    // Ensure change detections are triggered
    this.team_people = _.clone(this.team_people);
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    /*
    for (const team_person of this.team_people) {
      const team_person_errors = team_person.getErrors();

      for (const error of team_person_errors) {
        errors.push({
          error_path: 'team_people?team_person_key=' + team_person.team_person_key + '.' + error.error_path,
          error_message: error.error_message
        });
      }
    }

    if (!this.team_name) {
      errors.push({
        error_path: 'team_name',
        error_message: 'Please enter a team name'
      });
    }*/

    return errors;
  }

  formatForPosting(toArchive: boolean = false): any {
    if (this.hasErrors()) {
      const formatted_team_people = [];
      const formatted_team_managers = [];

      for (const team_person of this.team_people) {
        const ftp = team_person.formatForPosting();

        if (ftp !== null) {
          formatted_team_people.push(team_person.formatForPosting());
        }
      }

      for (const team_manager of this.team_managers) {
        const ftm = team_manager.formatForPosting();

        if (ftm !== null) {
          formatted_team_managers.push(team_manager.formatForPosting());
        }
      }

      return {
        team_key: this.team_key,
        team_name: this.team_name,
        default_skill_key: this.default_skill_key,
        team_people: formatted_team_people,
        team_managers: formatted_team_managers,
        archived_flag: toArchive
      };
    }
    return null;
  }
}
