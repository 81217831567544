import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ReportService } from '../../../../public-api';

import { ReportTableColumn } from '../../../lib.types';

@Component({
  selector: 'report-table-column',
  templateUrl: './report-table-column.component.html',
  styleUrls: ['./report-table-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportTableColumnComponent implements OnChanges {

  @ViewChild('headerRowName') header_row_name: ElementRef;

  @Input() column: ReportTableColumn;
  @Input() row_sort: { column_key: string, reverse: boolean };
  @Input() visible_table_rows: any[] = [];

  @Output() sort_rows = new EventEmitter<string>();

  formatted_column_values: string[] = [];

  constructor(
    public reportService: ReportService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.visible_table_rows) {
      this._formatColumnValues();
    }
  }

  private _formatColumnValues() {
    const formatted_column_values: any[] = [];

    for (const row of this.visible_table_rows) {
      formatted_column_values.push(
        this.reportService.formatFieldValue(
          row[this.column.column_key],
          this.column.field_config,
          true
        )
      );
    }

    this.formatted_column_values = formatted_column_values;
  }

}
