import { Component, Input, HostListener, HostBinding } from '@angular/core';

import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'app-radio-button',
  templateUrl: './app-radio-button.component.html',
  styleUrls: ['./app-radio-button.component.scss'],
  providers: [MakeProvider(AppRadioButtonComponent)]
})
export class AppRadioButtonComponent extends AbstractValueAccessor {
  _value: boolean;

  get value(): any {
    if (this.invertModel) {
      return !this._value;
    }
    else {
      return this._value;
    }
  }
  set value(v: any) {
    if (this.invertModel) {
      super.value = !v;
    }
    else {
      super.value = v;
    }
  }

  @HostBinding('class.-disabled') @Input() disabled: boolean = false;

  @Input() disableInternalToggle: boolean = false;
  @Input() radioButtonDisabled: boolean = false;
  @Input() tooltip: string = null;
  @Input() label: string = null;
  @Input() labelLeft: boolean = false;
  @Input() invertModel: boolean = false;

  @HostListener('click', ['$event'])
  handleClick() {
    if (!this.disableInternalToggle && !this.radioButtonDisabled) {
      this.value = true;
    }
  }

}
