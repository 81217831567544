<ng-container *ngIf="is_item">

  <tooltip-popover *ngIf="!!popover_content"
                   [tooltip_content]="popover_content">
  </tooltip-popover>

  <div class="ldd-itemInner">
    <div class="ldd-itemLeft">

      <user-icon class="-user-icon"
                 *ngIf="!!list_show_user_icons"
                 [display_image]="user_img_url"
                 [display_name]="label"
                 [placeholder_color]="'var(--app-color-grey-light)'"
                 [text_color]="'var(--app-color-grey-dark)'">
      </user-icon>

      <item-bullet *ngIf="!!bullet_colour"
                   [bullet_colour]="bullet_colour"
                   [bullet_grayscale]="disabled"
                   [bullet_type]="bullet_type || list_bullet_type">
      </item-bullet>

      <product-icon *ngIf="!!product"
                    [product]="product">
      </product-icon>

      <i class="ion itemIcon"
         *ngIf="icon_class && !list_align_icon_right"
         [style.color]="icon_colour || text_colour"
         [ngClass]="icon_class">
      </i>
      <div class="ldd-itemLeftInner">
        <div class="itemName"
             [style.color]="text_colour || ''"
             [style.font-size]="font_size ? font_size + 'px' : ''">

          <span>{{label}}</span>
        </div>
        <div class="itemDescription"
             *ngIf="!!description">
          <item-bullet class="itemDescription-itemDescriptionBullet"
                       *ngIf="!!description_bullet_colour"
                       [bullet_colour]="description_bullet_colour"
                       [bullet_grayscale]="disabled"
                       [bullet_type]="description_bullet_type || list_bullet_type">
          </item-bullet>
          {{description}}
        </div>
      </div>
    </div>

    <div class="ldd-itemRight">
      <app-toggle *ngIf="toggleable"
                  [(ngModel)]="toggle_value"
                  [disableInternalToggle]="true">
      </app-toggle>
      <app-checkbox *ngIf="checkable"
                    [(ngModel)]="checked_value"
                    [disableInternalToggle]="true">
      </app-checkbox>
      <i class="ion ion-ios-arrow-forward"
         *ngIf="!disabled && !!child_node_items_length">
      </i>
      <i class="ion itemIcon"
         *ngIf="icon_class && list_align_icon_right"
         [style.color]="icon_colour || text_colour"
         [ngClass]="icon_class">
      </i>
      <!-- <i class="ion itemIcon ion-ios-checkmark-circle -multiSelectToggledIcon"
         *ngIf="multi_select_toggled">
      </i> -->
    </div>
  </div>

</ng-container>

<ng-container *ngIf="is_divider && item_index !== visible_items_length - 1">

  <item-bullet *ngIf="divider_bullet_colour"
               [bullet_colour]="divider_bullet_colour">
  </item-bullet>
  <span *ngIf="is_custom_divider && !!divider_label">
    {{divider_label}}
  </span>

</ng-container>
