<banner *ngIf="!!interactive_demo"
        [bannerConfig]="banner_config">
</banner>

<ng-container *ngIf="side_nav">
  <div class="app-navWrapper"
       [ngClass]="{ '-menuClosed': !!menu_closed }">
    <button class="-type-icon -color-default-clear app-navMenu-mobileMenuIcon"
            *ngIf="is_mobile && menu_closed && active_path_is_stickieable"
            (click)="toggleMenu()">
      <i class="ion ion-md-menu"></i>
    </button>

    <div class="menu app-navMenu"
         [ngStyle]="{ 'transition': initialised ? 'all 0.25s ease' : '' }"
         *ngIf="!active_path_is_non_menu">

      <div class="app-navMenu-header">

        <div class="app-navMenu-headerRow">

          <div class="app-navMenu-headerImg">
            <div class="-headerImgIcon-container">
              <img class="-headerImgIcon"
                   [src]="logo_icon">
            </div>
            <img class="-headerImgText"
                 [src]="logo_text">
          </div>

          <button class="-type-icon -color-default-clear app-navMenu-closeButton"
                  [ngClass]="menu_toggle_color_class"
                  (click)="closeMenu()">
            <i class="bi-arrow-bar-left"></i>
          </button>

        </div>

        <div class="app-navMenu-headerRow">
          <button class="-type-icon -color-default-clear  app-navMenu-openButton"
                  [ngClass]="menu_toggle_color_class"
                  (click)="openMenu()">
            <i class="bi-arrow-bar-right"></i>
          </button>
        </div>

      </div>

      <ng-container *ngFor="let item of nav_menu; let i = index">
        <div class="app-navMenu-item"
             *ngIf="item !== null"
             [title]="item?.label || ''"
             [ngClass]="{
           '-highlightedNavItem': item.path === highlighted_nav_menu_item?.path && !highlighted_nav_menu_item_child_visible && !item.children_always_expanded,
           '-alwaysExpanded': !!item.children_always_expanded
         }"
             (click)="itemSelected(item)">

          <div class="app-navMenu-item-img">
            <i *ngIf="item.icon_class"
               [ngClass]="item.icon_class">
            </i>
          </div>
          <div class="app-navMenu-item-name">
            {{item.label}}
          </div>
          <i class="app-navMenu-item-arrow ion"
             *ngIf="item.children?.length > 1 && !item.children_always_expanded && !item.children_always_collapsed"
             [ngClass]="selected_nav_menu_item?.path === item.path ? 'ion-ios-arrow-down' : 'ion-ios-arrow-forward'">
          </i>
          <div class="app-navMenu-item-activeHighlighter">
          </div>

        </div>

        <ng-container *ngIf="!!item && item.children.length > 1 && !item.children_always_collapsed">
          <div class="app-navMenu-itemChildren"
               [ngClass]="{ '-alwaysExpanded': !!item.children_always_expanded }"
               [ngStyle]="{ 'height': selected_nav_menu_item?.path === item.path || item.children_always_expanded ? ('calc(var(--theme-navMenu-item-height) * ' + item.children.length + ')') : '' }">

            <div class="app-navMenu-item"
                 *ngFor="let child_item of item.children"
                 [ngClass]="{ '-highlightedNavItem': item.path === highlighted_nav_menu_item?.path && child_item.path === highlighted_nav_menu_item_child?.path }"
                 (click)="itemChildSelected(item, child_item); $event.stopPropagation()">

              <div class="app-navMenu-item-img">
                <i *ngIf="child_item.icon_class"
                   [ngClass]="child_item.icon_class">
                </i>
              </div>
              <div class="app-navMenu-item-name">
                {{child_item.label}}
              </div>
              <div class="app-navMenu-item-activeHighlighter">
              </div>

            </div>

          </div>
        </ng-container>

        <div class="app-navMenu-itemSpacer"
             *ngIf="item === null">
        </div>
      </ng-container>

      <div class="app-navMenu-item"
           *ngFor="let item of external_nav_menu; let i = index"
           [title]="item.label"
           (click)="externalItemSelected(item)">

        <div class="app-navMenu-itemHeader">
          <div class="app-navMenu-itemHeader-img">
            <i *ngIf="item.icon_class"
               [ngClass]="item.icon_class">
            </i>
          </div>
          <div class="app-navMenu-itemHeader-name">
            {{item.label}}
          </div>
        </div>
      </div>

    </div>

    <ng-container *ngTemplateOutlet="navMenuContent"></ng-container>

  </div>
</ng-container>
<ng-container *ngIf="bottom_nav">
  <div class="app-navWrapper -mobileFooterNav">
    <ng-container *ngTemplateOutlet="navMenuContent"></ng-container>
    <div class="app-navMenu-mobileFooterNav">
      <div class="app-navMenu-mobileFooterNav-highlight -position-{{highlighted_nav_menu_item?.path}}"></div>
      <ng-container *ngFor="let item of nav_menu; let i = index">
        <div class="app-navMenu-mobileFooterNav-item"
             [ngClass]="{
                '-highlightedNavItem':item.path === highlighted_nav_menu_item?.path && !highlighted_nav_menu_item_child_visible && !item.children_always_expanded
              }"
             (click)="itemSelected(item)">
          <div class="mobile-footer-alert"
               *ngIf="unseen_count !== 0 && item.label === 'Notifications'">
          </div>
          <i class="app-navMenu-mobileFooterNav-img"
             *ngIf="item.icon_class"
             [ngClass]="item.icon_class">
          </i>
        </div>
      </ng-container>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="no_nav">
  <div class="app-navWrapper -noNav">
    <ng-container *ngTemplateOutlet="navMenuContent"></ng-container>
  </div>
</ng-container>

<ng-template #navMenuContent>

  <div class="content app-navMenu-content"
       [ngClass]="{ '-nonResponsive': !responsive_ui }"
       [ngStyle]="{ 'margin-left': active_path_is_non_menu ? 'auto' : '',
                'margin-right': active_path_is_non_menu ? 'auto' : '',
                'width': active_path_is_non_menu ? '100%' : ''  }">

    <app-backdrop *ngIf="is_mobile && !menu_closed"
                  (click)="closeMenu()">
    </app-backdrop>
    <router-outlet></router-outlet>

    <clock-footer *ngIf="product === 'KARMLY' && is_mobile">
    </clock-footer>
  </div>

</ng-template>
