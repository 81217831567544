import { SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { DateTime } from 'luxon';

type KmNotificationType = ('CREATE' | 'EDIT' | 'ANNOUNCEMENT' | 'TEST' | 'PROJECT_NEW' | 'INVOXY_PROJECT_NEW' | 'INVOICE_XERO' | 'INVOICE_NEW' | 'COWORK INVITE' | 'COWORK ACCEPT' | 'COWORK TASK INVITE' | 'ACTIVITY_FEED' | 'COWORKER INVOICE PAID' | 'RECURRING TASK CREATED');


export type KmNotificationAdditionalData = {
  url?: string,
  url_params?: any,
  route_path?: string,
  route_query_params?: any
}


export class KmNotification implements PostableObject<KmNotification> {

  notification_key: number;
  identity_key: number;
  notification_date: Date;
  notification_type: KmNotificationType;
  notification_title: string;
  description: SafeHtml;
  seen_flag: boolean;
  deleted_flag: boolean = false;
  read_only : boolean = false;


  additional_data: KmNotificationAdditionalData = null;
  notification_date_label: string = null;
  img_url: SafeResourceUrl = null;

  constructor(
    notification_key: number,
    identity_key: number,
    notification_date: Date,
    notification_type: KmNotificationType,
    notification_title: string,
    additional_data: any,
    description: SafeHtml,
    seen_flag: boolean,
    deleted_flag: boolean = false
  ) {
    this.notification_key = notification_key;
    this.identity_key = identity_key;
    this.notification_date = notification_date;
    this.notification_type = notification_type;
    this.notification_title = notification_title;
    this.description = description;
    this.seen_flag = seen_flag;
    this.deleted_flag = deleted_flag;

    this.additional_data = this.initAdditionalData(additional_data);
    this.read_only = !this.additional_data.route_path && !this.additional_data.url;

    this._initNotificationDateLabel();
  }

  initAdditionalData(additional_data: any): KmNotificationAdditionalData {

    switch (this.notification_type) {
      // identity_key -> project_key
      case 'PROJECT_NEW':
      case 'INVOXY_PROJECT_NEW':
        return {
          route_path: !!this.identity_key ? 'project/edit' : 'project/all',
          route_query_params: !!this.identity_key ? { project_key: this.identity_key, tab: 'DETAIL' } : null
        };
      // identity_key -> batch_key
      case 'INVOICE_NEW':
        return {
          route_path: 'invoice/all',
          route_query_params: null
        };
      case 'COWORK INVITE':
      case 'COWORK ACCEPT':
        return {
          route_path: 'coworker/all',
          route_query_params: null
        };
      case 'COWORK TASK INVITE':
      case 'ACTIVITY_FEED':
      case 'RECURRING TASK CREATED':
        return {
          route_path: 'task/all',
          route_query_params: !!this.identity_key ? { project_task_key: this.identity_key } : null,
        };
      case 'COWORKER INVOICE PAID':
        return {
          route_path: 'invoice/edit',
          route_query_params: this.identity_key ? { invoice_key: this.identity_key } : null,
        };
      default: {
        return {
          route_path: additional_data?.route_path || null,
          route_query_params: additional_data?.route_query_params || null,
          url: additional_data?.url || null,
          url_params: additional_data?.url_params || null
        };
      }
    }
  }

  formatForPosting(to_delete: boolean = false): any {
    return {
      notification_key: this.notification_key,
      seen_flag: this.seen_flag,
      deleted_flag: to_delete
    };
  }

  private _initNotificationDateLabel() {
    const today = DateTime.now();
    const notification_date = DateTime.fromJSDate(this.notification_date);

    if (notification_date > today.minus({ minutes: 1 })) {
      this.notification_date_label = 'Less than a minute ago';
    }
    else if (notification_date.hasSame(today, 'day')) {
      this.notification_date_label = DateTime.fromJSDate(this.notification_date).toRelative();
    }
    else {
      this.notification_date_label = notification_date.toFormat('d LLL, hh:mm a');
    }
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};
    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

}
