<div class="app-modal-container">
  <div class="app-navHeader">

    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>Edit Insight</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>

    <div class="app-navRow -tabRow">
      <app-tabs [tabs]="tabs"
                [(selectedTab)]="selected_tab">
      </app-tabs>
    </div>

  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <report-table-setting *ngIf="selected_tab.value === 'TABLE'"
                            [report]="$any(report)">
      </report-table-setting>
      <report-chart-setting *ngIf="selected_tab.value === 'CHART'"
                            [report]="$any(report)">
      </report-chart-setting>
      <report-numeric-setting *ngIf="selected_tab.value === 'NUMERIC'"
                              [report]="$any(report)">
      </report-numeric-setting>
      <report-filter-setting *ngIf="selected_tab.value === 'FILTER'"
                             [report]="report">
      </report-filter-setting>

    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterLeft"
         *ngIf="selected_tab.value === 'FILTER'">
      <button class="-color-primary"
              (click)="addNewFilter()">
        Add Filter
      </button>
    </div>
    <div class="app-modal-btnFooterRight">
      <button class="-color-success"
              (click)="ok()">
        Update
      </button>
    </div>
  </div>

</div>
