import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DateTime } from 'luxon';

import { StateDataService } from '../../lib-services/state-data/state-data.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';


@Component({
  selector: 'calendar-month-grid',
  templateUrl: './calendar-month-grid.component.html',
  styleUrls: ['./calendar-month-grid.component.scss']
})
export class CalendarMonthGridComponent implements OnInit {

  @Input() month: Date = null;
  @Input() update_state_data: boolean = true;

  @Output() month_selected = new EventEmitter<Date>();

  current_year: Date = null;
  months: { label: string, value: Date }[] = [];
  selected_month_index: number = null;

  constructor(
    public stateDataService: StateDataService
  ) { }

  ngOnInit() {
    // default to start of todays month & year
    const date = new Date();
    if (!this.month) this.month = new Date(date.getFullYear(), date.getMonth(), 1);

    this.current_year = this.update_state_data ? TimeUtilService.updateMonth(this.stateDataService.selected_month_start, 0) : TimeUtilService.updateMonth(this.month, 0);
    this.months = TimeUtilService.generateYearOfMonths(this.current_year.getFullYear());
    this.updateSelectedMonthIndex();
  }

  updateSelectedMonthIndex() {
    const l_selected_month = DateTime.fromJSDate(this.update_state_data ? this.stateDataService.selected_month_start : this.month);
    this.selected_month_index = null;

    for (let i = 0; i < this.months.length; i++) {
      if (DateTime.fromJSDate(this.months[i].value).hasSame(l_selected_month, 'month')) {
        this.selected_month_index = i;
      }
    }
  }

  selectMonth(month: Date) {
    if (this.update_state_data) this.stateDataService.selected_month_start = month;
    else this.month = month;

    this.updateSelectedMonthIndex();
    this.month_selected.emit(this.update_state_data ? this.stateDataService.selected_month_start : this.month);
  }

  backYear() {
    this.current_year = TimeUtilService.incrementYear(this.current_year, -1);
    this.months = TimeUtilService.generateYearOfMonths(this.current_year.getFullYear());
    this.updateSelectedMonthIndex();
  }
  forwardYear() {
    this.current_year = TimeUtilService.incrementYear(this.current_year, 1);
    this.months = TimeUtilService.generateYearOfMonths(this.current_year.getFullYear());
    this.updateSelectedMonthIndex();
  }
}
