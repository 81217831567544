import { Pipe, PipeTransform } from '@angular/core';

import { AppCurrencyPipe } from '../app-currency/app-currency.pipe';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

@Pipe({
  name: 'accountingDollar'
})
export class AccountingDollarPipe implements PipeTransform {

  constructor(
    public appCurrencyPipe: AppCurrencyPipe
  ) { }

  transform(num: number, hide_brackets: boolean = false): string {
    if (num === 0) return '0';

    num = CoreUtilService.roundNumber(num, 2);
    const num_string = this.appCurrencyPipe.transform(Math.abs(num));

    return (num < 0 && !hide_brackets) ? '(' + num_string + ')' : num_string;
  }

}
