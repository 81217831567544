import { InvProjectStub } from '../inv-project-stub/inv-project-stub';

export class InvClientStub {
  
  projects: InvProjectStub[];
  integrations: any[];

  client_key: number;
  client_name: string;

  constructor(
    projects: InvProjectStub[],
    integrations: any[],
    client_key: number,
    client_name: string,
  ) {
    this.projects = projects;
    this.integrations = integrations;
    this.client_key = client_key;
    this.client_name = client_name;
  }

}
