<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>{{modal_header}}</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <div class="app-formItem-container">

        <file-uploader [file_url]="file_url"
                       [file_type]="'image'"
                       [custom_label]="modal_header"
                       (file_changed)="updateImage($event.file)"
                       (file_cleared)="clearImage()"
                       (file_error)="imageError($event.error)">
        </file-uploader>

      </div>

    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterLeft">
    </div>
    <div class="app-modal-btnFooterRight">
      <button class="-color-success"
              (click)="save()">
        Save
      </button>
      <button class="-color-white-outline -desktop-only"
              (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
