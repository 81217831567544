import { DateTime } from 'luxon';
import _ from 'lodash-es';

import { PostableObject, PostableObjectErrorMap, PostableObjectLockedFields, PostableObjectUtilService } from '../../lib-interfaces/postable-object.interface';
import { InvProjectRateType, Label, KmProjectType, ReportDashBlockConfig } from '../../lib.types';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { ProjectUtilService } from '../../lib-services/project-util/project-util.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';

export type KmProjectSummaryData = {
  billable_hours_total: number,
  billable_total: number,
  billed_total: number,
  hours_last_month: number,
  hours_this_month: number,
  paid_invoice_total: number,
  project_budget: number,
  project_start_date: Date,
  project_end_date: Date,
  project_key: number,
  total_hours: number,
  unpaid_invoice_total: number
}

export class KmProject implements PostableObject<KmProject> {

  project_key: number;
  project_name: string;
  project_budget: number;
  project_rate: number;
  project_fee: number;
  rate_type: InvProjectRateType;
  project_type: KmProjectType;
  project_colour: string;
  project_date: Date;
  start_date: Date;
  end_date: Date;
  client_key: number;
  client_name: string;
  labels: Label[];
  notes: string;
  reference: string;
  purchase_order_number: string;
  has_time_flag: boolean;
  completed_flag: boolean;
  project_dashboard_config: ReportDashBlockConfig[];
  locked_flag: boolean;
  coworker_locked_flag: boolean;
  locked_description: string;
  invoxy_task_keys: number[];
  has_coworker_time_flag: boolean;

  constructor(
    project_key: number = null,
    project_name: string = null,
    project_budget: number = null,
    project_rate: number = null,
    project_fee: number = null,
    rate_type: InvProjectRateType = 'hours',
    project_type: KmProjectType = 'Contractor',
    project_colour: string = '#459915',
    project_date: Date = null,
    start_date: Date = null,
    end_date: Date = null,
    client_key: number = null,
    client_name: string = null,
    labels: Label[] = null,
    notes: string = null,
    reference: string = null,
    purchase_order_number: string = null,
    has_time_flag: boolean = false,
    completed_flag: boolean = false,
    project_dashboard_config: ReportDashBlockConfig[] = [],
    invoxy_locked_flag: boolean = false,
    coworker_locked_flag: boolean = false,
    invoxy_task_keys: number[] = [],
    has_coworker_time_flag: boolean = false
  ) {
    this.project_key = project_key;
    this.project_name = project_name;
    this.project_budget = project_budget;
    this.project_rate = project_rate;
    this.project_fee = project_fee;
    this.rate_type = rate_type || 'hours';
    this.project_type = project_type || 'Contractor';
    this.project_colour = project_colour;
    this.project_date = project_date;
    this.start_date = TimeUtilService.getCleanDate(start_date);
    this.end_date = end_date ? TimeUtilService.getCleanDate(end_date) : null;
    this.client_key = client_key;
    this.client_name = client_name;
    this.labels = labels;
    this.notes = notes;
    this.reference = reference;
    this.purchase_order_number = purchase_order_number;
    this.has_time_flag = has_time_flag;
    this.completed_flag = completed_flag;
    this.project_dashboard_config = project_dashboard_config;
    this.invoxy_task_keys = invoxy_task_keys;
    this.has_coworker_time_flag = has_coworker_time_flag;

    this.locked_flag = invoxy_locked_flag || coworker_locked_flag;
    this.coworker_locked_flag = coworker_locked_flag;
    this.locked_description = CoreUtilService.set_locked_description(invoxy_locked_flag, coworker_locked_flag, 'Project');
  }

  isCurrent(date: Date = new Date()): boolean {
    if (this.completed_flag) {
      return false;
    }
    else {
      const d = DateTime.fromJSDate(date).startOf('day');
      const start = DateTime.fromJSDate(this.start_date).startOf('day');
      const end = this.end_date ? DateTime.fromJSDate(this.end_date).startOf('day') : null;

      return d >= start && (!end || d <= end);
    }
  }

  getEditingDisabled(): boolean {
    return this.completed_flag;
  }

  getTaskLocked(): boolean {
    return !!this.coworker_locked_flag;
  }

  getLockedFields(): PostableObjectLockedFields<KmProject> {
    const editing_disabled = this.getEditingDisabled();
    let fields: PostableObjectLockedFields<KmProject> = {};

    if (editing_disabled || this.locked_flag) {
      fields = PostableObjectUtilService.lockAllFields(this);
    }

    if (!editing_disabled && this.locked_flag) {
      delete fields.project_colour;
      delete fields.notes;
    }

    if (this.has_time_flag || (this.project_type === 'Contractor' ? fields.rate_type : fields.project_fee)) {
      fields.project_type = true;
    }

    return fields;
  }

  getErrors(to_complete: boolean = false): PostableObjectErrorMap {
    const errors = {};
    if (to_complete) return errors;

    if (!this.project_name) {
      errors['project_name'] = CoreUtilService.project_label.capitalised + ' name required';
    }

    const rate_key = this.project_type === 'Fixed Fee' ? 'project_fee' : 'project_rate';
    const rate_required = !!this.client_key || this.project_type === 'Fixed Fee';

    if (!CoreUtilService.numberIsValid(this[rate_key])) {
      if (rate_required) {
        errors[rate_key] = 'Rate required';
      }
    }
    else if (this[rate_key] < 0) {
      errors[rate_key] = 'Valid rate required';
    }


    if (!TimeUtilService.dateIsValid(this.start_date)) {
      errors['start_date'] = 'Start date required';
    }
    if (!!this.end_date) {
      if (!TimeUtilService.dateIsValid(this.end_date)) {
        errors['end_date'] = 'Invalid end date';
      }
      else if (DateTime.fromJSDate(this.end_date).startOf('day') < DateTime.fromJSDate(this.start_date).startOf('day')) {
        errors['end_date'] = 'End date cannot be before start date';
      }
    }

    return errors;
  }

  hasErrors(): boolean {
    return PostableObjectUtilService.hasErrors(this);
  }

  formatForPosting(to_delete: boolean = false, toggle_complete: boolean = false): any {
    if (toggle_complete) {
      return {
        project_key: this.project_key,
        completed_flag: !this.completed_flag
      };
    }

    if (!this.getErrors().has_errors) {
      const start_date = ProjectUtilService.isPeriodicProject(this) ? TimeUtilService.dateToDateString(this.start_date) : null;
      const end_date = ProjectUtilService.isPeriodicProject(this) ? this.end_date !== null ? TimeUtilService.dateToDateString(this.end_date) : '' : null;
      const project_date = ProjectUtilService.isPeriodicProject(this) ? null : TimeUtilService.dateToDateString(this.project_date);

      let project_rate = 0;
      let project_fee = -1;
      if (this.project_type === 'Contractor' || this.project_type === 'Agency') {
        project_rate = this.project_rate || project_rate;
      }
      else if (this.project_type === 'Fixed Fee') {
        project_fee = this.project_fee || project_fee;
      }
      const project_budget = this.project_type === 'Fixed Fee' ? project_fee : this.project_budget;

      return {
        project_key: this.project_key,
        project_name: this.project_name,
        project_budget,
        project_rate,
        project_fee,
        rate_type: this.rate_type,
        project_type: this.project_type,
        project_colour: this.project_colour,
        project_date,
        start_date,
        end_date,
        client_key: this.client_key === null ? 0 : this.client_key,
        labels: !!this.labels ? JSON.stringify(this.labels) : null,
        notes: this.notes,
        reference: this.reference,
        purchase_order_number: this.purchase_order_number,
        deleted_flag: to_delete,
        project_dashboard_config: !!this.project_dashboard_config ? JSON.stringify(this.project_dashboard_config) : null,
      };

    }
    return null;
  }

  formatProjectDashboardConfigForPosting(): { project_key: number, project_dashboard_config: string } {
    return {
      project_key: this.project_key,
      project_dashboard_config: !!this.project_dashboard_config ? JSON.stringify(this.project_dashboard_config) : null,
    };
  }

}
