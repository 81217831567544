import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'locked-icon',
  templateUrl: './locked-icon.component.html',
  styleUrls: ['./locked-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LockedIconComponent implements OnInit {

  @Input() lock_description: string = null;

  ngOnInit(): void {
  }

}
