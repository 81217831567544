import _ from 'lodash-es';
import { DateTime, Interval } from 'luxon';

import { PostableObject, PostableObjectErrorMap, PostableObjectUtilService } from '../../lib-interfaces/postable-object.interface';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';

export abstract class CalendarEvent implements PostableObject<CalendarEvent> {

  readonly calendar_event_id: string;

  event_title: string = '';
  event_description: string = '';
  event_html_link: string = '';

  start_time: Date = null;
  end_time: Date = null;

  foreground_color: string = null;
  background_color: string = null;
  text_color: string = null;

  is_greater_than_24_hours: boolean = false;
  is_multi_day_event: boolean = false;

  constructor(
    calendar_event_id: string,
    event_title: string,
    event_description: string,
    start_time: Date,
    end_time: Date,
    event_html_link: string,
    foreground_color: string,
    background_color: string,
    text_color: string
  ) {
    this.calendar_event_id = calendar_event_id;
    this.event_title = event_title;
    this.event_description = event_description;
    this.start_time = TimeUtilService.getCleanTime(start_time);
    this.end_time = !!end_time ? TimeUtilService.getCleanTime(end_time) : null;
    this.event_html_link = event_html_link;
    this.foreground_color = foreground_color || null;
    this.background_color = background_color || null;
    this.text_color = text_color || null;

    this.is_greater_than_24_hours = this._greaterThan24Hours();
    this.is_multi_day_event = this._multiDayEvent();
  }

  abstract formatForPosting(): any;
  abstract get is_locked(): boolean;

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    return errors;
  }

  hasErrors(): boolean {
    return PostableObjectUtilService.hasErrors(this);
  }

  overlapsPeriodOfDays(_period_start: Date, _period_end: Date) {
    const period_start = DateTime.fromJSDate(_period_start);
    const period_end = DateTime.fromJSDate(_period_end);

    const period = Interval.fromDateTimes(period_start, period_end);

    const start_time = DateTime.fromJSDate(this.start_time).startOf('day');

    if (!!this.start_time && !!this.end_time) {
      const end_time = DateTime.fromJSDate(this.end_time).endOf('day');
      const segment_period = Interval.fromDateTimes(start_time, end_time);

      return period.overlaps(segment_period);
    }
    // Start time only
    else {
      return period.contains(start_time);
    }
  }

  private _multiDayEvent() {
    if (!this.end_time) {
      return false;
    }
    const start = DateTime.fromJSDate(this.start_time);
    const end = DateTime.fromJSDate(this.end_time);

    // Doing this rather than DateTime.hasSame('day') ensures that we
    // count events that start at midnight and end exactly 24 hours later
    // as a single day event
    return end.diff(start.startOf('day')).as('days') > 1;
  }

  private _greaterThan24Hours() {
    return !!this.end_time &&
      TimeUtilService.totalMinutesBetweenTwoDates(this.start_time, this.end_time) >= (24 * 60);
  }

}
