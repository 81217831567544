import { AfterViewInit, Component, HostBinding, HostListener, Inject, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import _ from 'lodash-es';

import { InvoiceServiceInterface } from '../../lib-interfaces/invoice-service.interface';
import { ProjectServiceInterface } from '../../lib-interfaces/project-service.interface';
import { KmInvoice, KmInvoiceLine } from '../../lib-models/km-invoice/km-invoice';
import { InvoiceUtilService } from '../../lib-services/invoice-util/invoice-util.service';
import { DropdownItem, EditorPlaceholder } from '../../lib.types';
import { RedactorService } from '../../lib-services/redactor/redactor.service';
import { InvoiceEditorLineComponent } from './invoice-editor-line/invoice-editor-line.component';
import { DomService, LibModalService } from '../../../public-api';
import { KmProjectStub } from '../../lib-models/km-project-stub/km-project-stub';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'invoice-editor',
  templateUrl: './invoice-editor.component.html',
  styleUrls: ['./invoice-editor.component.scss']
})
export class InvoiceEditorComponent implements OnInit, OnChanges, AfterViewInit {

  @HostBinding('class') classes = 'app-invoice-container';

  @ViewChildren(InvoiceEditorLineComponent) invoice_editor_line_components: QueryList<InvoiceEditorLineComponent>;

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.is_mobile !== DomService.is_mobile) {
      this.is_mobile = DomService.is_mobile;
      setTimeout(() => this._initEditors());
    }
  }

  @Input() invoice: KmInvoice;
  @Input() editor_placeholders: EditorPlaceholder[];

  projects: KmProjectStub[] = [];

  header_element: any = null;
  sub_header_element: any = null;
  footer_element: any = null;
  line_editors: Record<string, any> = {};

  new_line_dropdown: DropdownItem[] = [];

  constructor(
    @Inject('invoiceService') public invoiceService: InvoiceServiceInterface,
    @Inject('projectService') public projectService: ProjectServiceInterface,
    @Inject('env') public env: any,
    public libModalService: LibModalService,
  ) { }

  ngOnInit(): void {
    this.projects = this.projectService.getProjectsForClient(this.invoice.client.client_key);

    InvoiceUtilService.recalculateLineIndices(this.invoice);

    this.recalculateLineAmounts();
    this.updateNewLineDropdown();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !!changes.invoice &&
      !changes.invoice.firstChange
    ) {
      InvoiceUtilService.recalculateLineIndices(this.invoice);
      this.recalculateLineAmounts();
      this._initEditors();
    }
  }

  ngAfterViewInit(): void {
    this._initEditors();
  }

  invoiceLineMoved(event: CdkDragDrop<KmInvoiceLine>) {
    moveItemInArray(this.invoice.lines, event.previousIndex, event.currentIndex);
    InvoiceUtilService.recalculateLineIndices(this.invoice);
  }

  updateNewLineDropdown() {
    this.new_line_dropdown = [
      {
        label: 'Text Line',
        value: 'TEXT'
      },
      null
    ];

    for (const project of this.projects) {
      this.new_line_dropdown.push({
        label: project.project_name,
        bullet_colour: project.project_colour,
        value: project
      });
    }
  }

  newLine(item: DropdownItem) {
    let line: KmInvoiceLine = null;

    if (item.value === 'TEXT') {
      line = this.invoiceService.initNewInvoiceLine(
        this.invoice.invoice_key,
        null,
        'Text',
        ''
      );
    }
    else {
      line = this.invoiceService.initNewInvoiceLine(
        this.invoice.invoice_key,
        item.value.project_key,
        item.value.project_type === 'Fixed Fee' ? 'Project' : 'Time',
        item.value.project_name
      );
    }

    this.invoice.lines.push(line);
    this.updateNewLineDropdown();

    InvoiceUtilService.recalculateLineIndices(this.invoice);
    this.recalculateLineAmounts();

    if (this.is_mobile) {
      this.editLine(line);
    }
    else {
      setTimeout(() => this.invoice_editor_line_components.last.lineDescriptionFocus());
    }
  }

  updateLineErrors() {
    this.invoice_editor_line_components?.forEach((line) => line.updateLineErrors());
  }

  recalculateLineAmounts() {
    InvoiceUtilService.recalculateLineAmounts(this.invoice);
  }

  editLine(line: KmInvoiceLine) {
    if (this.is_mobile && !this.invoice.posted_flag) {
      this.libModalService.invoiceLineEditorModal(
        this.invoice,
        line.line_index
      )
        .then((res) => {
          if (res.deleted_flag) {
            this.removeLine(line);
          }
          else {
            line = res.line;

            for (let i = 0; i < this.invoice.lines.length; i++) {
              if (
                !this.invoice.lines[i].deleted_flag &&
                this.invoice.lines[i].line_index === line.line_index
              ) {

                this.invoice.lines[i] = line;
                InvoiceUtilService.recalculateLineAmounts(this.invoice);
                break;
              }
            }
          }
        })
        .catch(() => { });
    }
  }

  removeLine(line: KmInvoiceLine) {
    line.deleted_flag = true;

    InvoiceUtilService.recalculateLineIndices(this.invoice);
    this.recalculateLineAmounts();
  }

  private _initEditors() {
    this.header_element = RedactorService.createEditor(
      '#invoiceEditor-header',
      'invoice-body',
      this.invoice.header_left_text,
      null,
      this.invoice.posted_flag,
      { blur: () => this.invoice.header_left_text = this.header_element.editor.getContent() }
    );
    this.sub_header_element = RedactorService.createEditor(
      '#invoiceEditor-subHeader',
      'invoice-body',
      this.invoice.header_bottom_text,
      null,
      this.invoice.posted_flag,
      { blur: () => this.invoice.header_bottom_text = this.sub_header_element.editor.getContent() }
    );
    this.footer_element = RedactorService.createEditor(
      '#invoiceEditor-footer',
      'invoice-body',
      this.invoice.footer_text,
      null,
      this.invoice.posted_flag,
      { blur: () => this.invoice.footer_text = this.footer_element.editor.getContent() }
    );
  }

  updateModelValuesFromRedactorEditors() {
    if (!!this.header_element) {
      this.invoice.header_left_text = this.header_element.editor.getContent();
    }
    if (!!this.sub_header_element) {
      this.invoice.header_bottom_text = this.sub_header_element.editor.getContent();
    }
    if (!!this.footer_element) {
      this.invoice.footer_text = this.footer_element.editor.getContent();
    }
    this.invoice_editor_line_components?.forEach(component => component.updateModelValuesFromRedactorEditors());
  }

}
