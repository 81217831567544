import { ReportingAccount } from '../reporting-account';

export class PhReportingAccount extends ReportingAccount {

  company_code: string;
  // Key used to associate a PayHero account with a FlexiTime Payroll account
  access_key: string;

  constructor(
    company_reference: string,
    company_name: string,
    company_code: string,
    access_key: string
  ) {
    super(
      company_reference,
      company_name,
    );
    this.company_code = company_code;
    this.access_key = access_key;
    this.product = 'PAYHERO';
  }

}
