import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-form-item-error',
  templateUrl: './app-form-item-error.component.html',
  styleUrls: ['./app-form-item-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('enterLeaveAnimation', [
      transition(':enter', [
        style({ opacity: 0, 'max-height': 0, margin: 0 }),
        animate('200ms', style({ opacity: 1, 'max-height': 'auto', margin: '5px 0' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, 'max-height': 'auto', margin: '5px 0' }),
        animate('200ms', style({ opacity: 0, 'max-height': 0, margin: 0 }))
      ])
    ]
    )
  ]
})
export class AppFormItemErrorComponent {

  @HostBinding('@enterLeaveAnimation') error_label_animation = true;

  @Input() error: string = null;

}
