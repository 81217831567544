import { Injectable } from '@angular/core';
import { ApprovalProject } from '../../lib.types';
import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';
import { KmProject } from '../../lib-models/km-project/km-project';
import { KmProjectStub } from '../../lib-models/km-project-stub/km-project-stub';
import { InvProject } from '../../lib-models/inv-project/inv-project';

import _ from 'lodash-es';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class ProjectUtilService {

  constructor() { }

  static readonly project_types = [
    'Contractor',
    'Labour Hire',
    'Permanent'
  ];

  static readonly consultant_roles = [
    'All',
    'Account Manager',
    'Recruiter'
  ];

  static readonly project_rate_types = [
    'hours',
    'days'
  ];

  static kmProjectActiveInPeriod(
    project: KmProject | KmProjectStub,
    start: Date,
    end: Date = null
  ): boolean {
    end = end || start;

    const project_start = DateTime.fromJSDate(project.start_date).startOf('day');
    const project_end = !!project.end_date ? DateTime.fromJSDate(project.end_date).startOf('day') : null;

    return !project.completed_flag &&
      project_start <= DateTime.fromJSDate(end).startOf('day') &&
      (!project_end || project_end >= DateTime.fromJSDate(start).startOf('day'));
  }

  static getActiveKmProjectsInPeriod(
    projects: KmProject[] | KmProjectStub[],
    start: Date,
    end: Date = null
  ) {
    return _.filter(projects, (project: KmProject | KmProjectStub) => this.kmProjectActiveInPeriod(project, start, end));
  }

  static invProjectIsActive(
    project: any,
    start: Date,
    end: Date = null,
    ignore_tasks: boolean = false
  ) {
    end = end || start;

    const project_start = DateTime.fromJSDate(project.start_date).startOf('day');
    const project_end = !!project.end_date ? DateTime.fromJSDate(project.end_date).startOf('day') : null;

    return (ignore_tasks || !!project.tasks.length) &&
      !project.archived_flag &&
      project_start <= DateTime.fromJSDate(end).startOf('day') &&
      (!project_end || project_end >= DateTime.fromJSDate(start).startOf('day'));
  }

  static invProjectHasUser(
    project: any,
    user_key: number
  ): boolean {
    return _.findIndex(project.users, { user_key }) !== -1;
  }

  static getFirstProjectForUser(projects: any[], user_key: number): any {
    for (const project of projects) {
      if (this.invProjectHasUser(project, user_key)) {
        return project;
      }
    }
    return null;
  }

  static projectHasTask(project: any, task_key: number): boolean {
    for (const task of project.tasks) {
      if (task.task_key === task_key) {
        return true;
      }
    }
    return false;
  }

  static prioritiseFirstNonUnitTask(tasks: any[]): any {
    tasks = SortUtilService.sortList(tasks);
    for (const task of tasks) {
      if (!task.unit_flag) {
        return task;
      }
    }
    // Default to returning first task in sorted list
    return tasks[0];
  }

  static createProjectMap(projects: any[]): Record<number, any> {
    const project_map = {};
    for (const project of projects) {
      project_map[project.project_key] = project;
    }
    return project_map;
  }

  static getDistinctUsersFromProjects(projects: any[], only_resources: boolean = false): any[] {
    const user_map = {};

    for (const project of projects) {
      for (const user of project.users) {
        if (!only_resources || user.resource_flag) {
          user_map[user.user_key] = user;
        }
      }
    }

    const users = [];

    for (const user_key of Object.keys(user_map)) {
      users.push(user_map[user_key]);
    }

    return users;
  }

  // Returns projects and tasks that can be used to record time
  static getTimesheetProjectTasks(projects: any[], expenses_enabled: boolean): any[] {
    projects = _.cloneDeep(projects);
    const filtered_projects = [];

    for (const project of projects) {
      if (['Permanent', 'Service Fee', 'Credit Note'].indexOf(project.project_type) === -1) {
        const filtered_tasks = [];

        for (const task of project.tasks) {
          if (!expenses_enabled || task.unit_type !== 'expense') {
            filtered_tasks.push(task);
          }
        }

        if (!!filtered_tasks.length) {
          project.tasks = filtered_tasks;
          filtered_projects.push(project);
        }
      }
    }

    return filtered_projects;
  }

  // Returns projects and tasks that can be used to record expenses
  static getExpenseProjectTasks(projects: any[]): any[] {
    projects = _.cloneDeep(projects);
    const filtered_projects = [];

    for (const project of projects) {
      if (['Permanent', 'Service Fee', 'Credit Note'].indexOf(project.project_type) === -1) {
        const filtered_tasks = [];

        for (const task of project.tasks) {
          if (task.unit_type === 'expense') {
            filtered_tasks.push(task);
          }
        }

        if (!!filtered_tasks.length) {
          project.tasks = filtered_tasks;
          filtered_projects.push(project);
        }
      }
    }

    return filtered_projects;
  }

  static isPeriodicProject(project: InvProject | KmProject): boolean {
    return ['Contractor', 'Labour Hire', 'Agency', 'Fixed Fee'].indexOf(project.project_type) !== -1;
  }

  static groupApprovalRequestSegments(segments: any[]): ApprovalProject[] {
    const approval_projects: ApprovalProject[] = [];

    for (const segment of segments) {
      let approval_project: ApprovalProject = null;

      for (const ap of approval_projects) {
        if (ap.project_key === segment.project_key) {
          approval_project = ap;
          break;
        }
      }

      if (!approval_project) {
        approval_project = {
          project_name: segment.project_name,
          project_key: segment.project_key,
          client_name: segment.client_name,
          client_key: segment.client_key,
          unit_groups: []
        };

        approval_projects.push(approval_project);
      }

      const unit_type = !segment.unit_flag ? 'hours' : segment.unit_type;

      let group = _.find(approval_project.unit_groups, { unit_type });

      if (!group) {
        group = {
          unit_type,
          segments: [],
          total: 0
        };

        approval_project.unit_groups.push(group);
      }

      group.segments.push(segment);
      group.total += segment.unit_flag ? segment.units : segment.duration;
    }

    for (const ap of approval_projects) {
      for (const ug of ap.unit_groups) {
        SortUtilService.sortList(ug.segments,
          {
            primary_sort_property: 'segment_date',
            secondary_sort_property: 'start_time'
          }
        );
      }
      this._sortUnitTypeGroupsByUnit(ap);
    }
    SortUtilService.sortList(approval_projects,
      {
        primary_sort_property: 'project_name'
      }
    );

    return approval_projects;
  }

  private static _sortUnitTypeGroupsByUnit(approval_project: ApprovalProject) {
    approval_project.unit_groups.sort((a, b) => {
      const a_unit_type = a.unit_type.toUpperCase().replace(' ', '');
      const b_unit_type = b.unit_type.toUpperCase().replace(' ', '');

      if (a_unit_type > b_unit_type) {
        return 1;
      }
      else if (a_unit_type < b_unit_type) {
        return -1;
      }
      return 0;
    });

    for (let i = 0; i < approval_project.unit_groups.length; i++) {
      const group = approval_project.unit_groups[i];

      if (group.unit_type === 'expense') {
        approval_project.unit_groups.splice(i, 1);
        approval_project.unit_groups.unshift(group);
        break;
      }
    }
    for (let i = 0; i < approval_project.unit_groups.length; i++) {
      const group = approval_project.unit_groups[i];

      if (group.unit_type === 'days') {
        approval_project.unit_groups.splice(i, 1);
        approval_project.unit_groups.unshift(group);
        break;
      }
    }
    for (let i = 0; i < approval_project.unit_groups.length; i++) {
      const group = approval_project.unit_groups[i];

      if (group.unit_type === 'hours') {
        approval_project.unit_groups.splice(i, 1);
        approval_project.unit_groups.unshift(group);
        break;
      }
    }
  }

}
