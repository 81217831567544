<div class="banner"
     [ngClass]="{
        '-show' : bannerVisible,
        '-error': bannerConfig.type === 'ERROR',
        '-info': bannerConfig.type === 'INFO',
        '-success': bannerConfig.type === 'SUCCESS',
        '-demo' : bannerConfig.type === 'INTERACTIVE_DEMO'
      }">
  <div class="banner-Message">
    {{bannerConfig.message}}
    <a *ngIf="bannerConfig.type === 'INTERACTIVE_DEMO'"
          [href]="'https://www.karmly.io/signup'">
          Create Account
    </a>
  </div>
  <i class="ion ion-md-close app-remove"
     [hidden]="bannerConfig.hide_disabled"
     (click)="hideBanner()">
  </i>
</div>
