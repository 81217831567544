import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';

import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { AppCurrencyPipe } from './app-currency/app-currency.pipe';
import { AppNumberPipe } from './app-number/app-number.pipe';
import { HoursToTimePipe } from './hours-to-time/hours-to-time.pipe';
import { DateToTimePipe } from './date-to-time/date-to-time.pipe';
import { MinutesToTimePipe } from './minutes-to-time/minutes-to-time.pipe';
import { SecondsToTimePipe } from './seconds-to-time/seconds-to-time.pipe';
import { ReportFieldValuePipe } from './report-field-value/report-field-value.pipe';
import { ReportFilterArgPipe } from './report-filter-arg/report-filter-arg.pipe';
import { DateTimePipe } from './date-time/date-time.pipe';
import { AccountingDollarPipe } from './accounting-dollar/accounting-dollar.pipe';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    AppCurrencyPipe,
    AppNumberPipe,
    HoursToTimePipe,
    MinutesToTimePipe,
    SecondsToTimePipe,
    DateToTimePipe,
    ReportFieldValuePipe,
    ReportFilterArgPipe,
    DateTimePipe,
    AccountingDollarPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafeHtmlPipe,
    AppCurrencyPipe,
    AppNumberPipe,
    HoursToTimePipe,
    MinutesToTimePipe,
    SecondsToTimePipe,
    DateToTimePipe,
    ReportFieldValuePipe,
    ReportFilterArgPipe,
    DateTimePipe,
    AccountingDollarPipe
  ],
  providers: [
    CurrencyPipe,
    DecimalPipe,
    SafeHtmlPipe,
    AppCurrencyPipe,
    AppNumberPipe,
    HoursToTimePipe,
    MinutesToTimePipe,
    SecondsToTimePipe,
    DateToTimePipe,
    ReportFieldValuePipe,
    ReportFilterArgPipe,
    DateTimePipe,
    AccountingDollarPipe
  ]
})
export class LibPipesModule { }
