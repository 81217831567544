import { DateTime } from 'luxon';
import _ from 'lodash-es';

import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { CoreUtilService } from '../../../lib-services/core-util/core-util.service';
import { StateDataService } from '../../../lib-services/state-data/state-data.service';
import { CalendarEvent } from '../../../lib-models/calendar-event/calendar-event';
import { Segment } from '../../../lib-models/segment/segment';

export type TimeUserMonthCalendarDay = {
  date: DateTime,
  segments: Segment[],
  calendar_events: CalendarEvent[],
  total_hours: number,
  is_current_month: boolean,
  is_today: boolean
};

export type TimeUserMonthCalendarNewSegmentEvent = {
  segment?: Segment,
  start_time?: Date,
  end_time?: Date,
  origin_component: string
};

export abstract class TimeUserMonthCalendar {

  readonly DATE_KEY_FORMAT = TimeUtilService.DEFAULT_DATE_KEY_FORMAT;

  readonly project_label = CoreUtilService.project_label;
  readonly project_task_label = CoreUtilService.project_task_label;
  readonly task_label = CoreUtilService.task_label;
  readonly currency_code: string = CoreUtilService.currency_code;
  readonly currency_symbol: string = CoreUtilService.currency_symbol;
  readonly week_days = TimeUtilService.week_day_shorts;

  // Inputs
  abstract segments: Segment[];
  abstract calendar_events: CalendarEvent[];
  abstract show_calendar_events: boolean;

  abstract loading: boolean;

  segments_in_month: Segment[] = [];
  // segment_key => Segment
  segment_map: Record<string, Segment> = {};
  // DATE_KEY_FORMAT => segment_key
  segment_date_map: Record<string, number[]> = {};

  calendar_events_in_month: CalendarEvent[] = [];
  // calendar_event_id => CalendarEvent
  calendar_event_map: Record<string, CalendarEvent> = {};
  // DATE_KEY_FORMAT => calendar_event_id
  calendar_event_date_map: Record<string, string[]> = {};

  calendar_month: TimeUserMonthCalendarDay[][] = [];

  constructor(
    public stateDataService: StateDataService
  ) { }

  onInit() {

  }

  abstract showDaysSchedule(day: TimeUserMonthCalendarDay): void;
  abstract editSegment(segment: Segment): void;

  refreshComponent() {
    this._updateSegmentsAndCalendarEventsInMonth();
    this._updateSegmentAndCalendarEventMaps();
    this._updateSegmentAndCalendarEventDateMaps();

    this.generateCalendarMonth();
  }

  goToCalendarEvent(calendar_event: CalendarEvent) {
    if (!!calendar_event.event_html_link) {
      window.open(calendar_event.event_html_link, '_blank');
    }
  }

  generateCalendarMonth() {
    const month_start = this.stateDataService.selected_month_start;

    const first_day = TimeUtilService.getMonday(month_start);

    const today = DateTime.now();
    const current_month = DateTime.fromJSDate(month_start);
    let current_date = DateTime.fromJSDate(first_day);
    let current_date_key = current_date.toFormat(this.DATE_KEY_FORMAT);

    this.calendar_month = [];

    // Iterate weeks
    for (let i = 0; i < 6; i++) {
      const calendar_week: TimeUserMonthCalendarDay[] = [];

      // Iterate days
      for (let j = 0; j < 7; j++) {
        const segments = (this.segment_date_map[current_date_key] || [])
          .map(segment_key => this.segment_map[segment_key]);

        const calendar_events = (this.calendar_event_date_map[current_date_key] || [])
          .map(calendar_event_id => this.calendar_event_map[calendar_event_id]);

        calendar_week.push({
          date: current_date,
          segments,
          calendar_events,
          total_hours: this._calculateTotalHours(segments),
          is_current_month: current_date.hasSame(current_month, 'month'),
          is_today: current_date.hasSame(today, 'day')
        });

        current_date = current_date.plus({ days: 1 });
        current_date_key = current_date.toFormat(this.DATE_KEY_FORMAT);
      }
      this.calendar_month.push(calendar_week);
    }
  }

  private _updateSegmentsAndCalendarEventsInMonth() {
    const month_start = this.stateDataService.selected_month_start;
    const month_end = this.stateDataService.selected_month_end;

    this.segments_in_month = _.filter(
      this.segments,
      (s) => s.overlapsPeriodOfDays(month_start, month_end)
    );

    this.calendar_events_in_month = _.filter(
      this.calendar_events,
      (ce) => ce.overlapsPeriodOfDays(month_start, month_end)
    );
  }

  private _updateSegmentAndCalendarEventMaps() {
    this.segment_map = {};
    for (const segment of this.segments_in_month) {
      this.segment_map[segment.segment_key] = segment;
    }

    this.calendar_event_map = {};
    for (const calendar_event of this.calendar_events_in_month) {
      this.calendar_event_map[calendar_event.calendar_event_id] = calendar_event;
    }
  }

  private _updateSegmentAndCalendarEventDateMaps() {
    const month_start = this.stateDataService.selected_month_start;
    const month_end = this.stateDataService.selected_month_end;

    this.segment_date_map = {};
    for (const segment of this.segments_in_month) {
      const date_key = TimeUtilService.getDateKey(segment.segment_date);

      if (!this.segment_date_map[date_key]) {
        this.segment_date_map[date_key] = [];
      }
      this.segment_date_map[date_key].push(segment.segment_key);
    }

    this.calendar_event_date_map = {};
    for (const calendar_event of this.calendar_events_in_month) {
      const date_keys = TimeUtilService.getDateKeys(
        calendar_event.start_time,
        calendar_event.end_time || calendar_event.start_time,
        month_start,
        month_end
      );

      for (const date_key of date_keys) {
        if (!this.calendar_event_date_map[date_key]) {
          this.calendar_event_date_map[date_key] = [];
        }
        this.calendar_event_date_map[date_key].push(calendar_event.calendar_event_id);
      }
    }
  }

  private _calculateTotalHours(segments: Segment[]) {
    let total_hours = 0;
    for (const segment of segments) {
      if (!segment.unit_flag) {
        total_hours += segment.duration;
      }
      else if (segment.unit_type === 'hours') {
        total_hours += segment.units;
      }
    }
    return total_hours;
  }

}
