import { Injectable, Inject } from '@angular/core';
import { BlockBlobUploadResponse, BlobServiceClient, BlobDeleteIfExistsResponse } from '@azure/storage-blob';

import { ApiService } from '../api/api.service';
import { CoreUtilService } from '../core-util/core-util.service';
import { BlobUpdateData, BlobDeleteData } from '../../lib.types';
import { ImageUtilService } from '../image-util/image-util.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  readonly BLOB_CONTAINER_KEY_INVOXY: string = 'wbsgc4xdxsqe';

  readonly BLOB_CONTAINER_KEY_DOCUMENTS_KARMLY: string = 'karmly-documents';

  readonly BLOB_CONTAINER_KEY_THUMBNAILS_KARMLY: string = 'karmly-thumbnails';

  readonly _valid_image_formats = [
    'png', 'jpg', 'jpeg', 'gif', 'bmp'
  ]

  blob_sas_keys: Record<string, string> = {};

  service_setup: boolean = false;

  constructor(
    @Inject('env') public env: any,
    public apiService: ApiService
  ) { }

  initialiseService(force_reload = false) {
    return new Promise<void>((resolve, reject) => {
      if (this.service_setup && !force_reload) {
        resolve();
      }
      else {
        this.service_setup = false;
        this.blob_sas_keys = {};
        const promises = [];

        promises.push(this.loadBlobSasKey(this.BLOB_CONTAINER_KEY_INVOXY));
        promises.push(this.loadBlobSasKey(this.BLOB_CONTAINER_KEY_DOCUMENTS_KARMLY));
        promises.push(this.loadBlobSasKey(this.BLOB_CONTAINER_KEY_THUMBNAILS_KARMLY));

        Promise.all(promises)
          .then(() => {
            this.service_setup = true;
            resolve();
          })
          .catch((err) => reject(err));
      }
    });
  }

  clearServiceData() {
    this.blob_sas_keys = null;
    this.service_setup = false;
  }

  getBlobSasKey(container: string = 'wbsgc4xdxsqe') {
    return this.blob_sas_keys[container];
  }

  formatImageUrl(
    image_url: string,
    karmly_flag: boolean = false
  ): string {
    if (!!karmly_flag) {
      // if image_url is a full blob link, just use that
      if (image_url.includes(ApiService.invoxy_blob_url) || image_url.includes('pictures')) return image_url;
      // otherwise, build up link with container & sas
      return ApiService.invoxy_blob_url + this.BLOB_CONTAINER_KEY_DOCUMENTS_KARMLY + '/' + image_url + '?' + this.getBlobSasKey(this.BLOB_CONTAINER_KEY_DOCUMENTS_KARMLY);
    }
    return ApiService.invoxy_blob_url + image_url + '?' + this.getBlobSasKey();
  }

  formatThumbnailUrl(image_url: string): string {
    // if image_url is a full blob link, just use that
    if (image_url.includes(ApiService.invoxy_blob_url)) return image_url;

    return ApiService.invoxy_blob_url + this.BLOB_CONTAINER_KEY_THUMBNAILS_KARMLY + '/' + image_url + '?' + this.getBlobSasKey(this.BLOB_CONTAINER_KEY_THUMBNAILS_KARMLY);
  }


  formatProfilePicture(partial_url : string): string {
    return (partial_url.startsWith('/') ? ApiService.subscription_blob_url.slice(0, -1) : ApiService.subscription_blob_url) + partial_url;
  }

  loadBlobSasKey(container: string = 'wbsgc4xdxsqe') {
    return new Promise<void>((resolve, reject) => {
      const params = {
        container
      };
      this.apiService.get('INVOXY', 'document/sas', params)
        .then((blob_sas_key) => {
          this.blob_sas_keys[container] = blob_sas_key;
          if (container === 'wbsgc4xdxsqe') {
            CoreUtilService.blob_sas_key = blob_sas_key || '';
          }
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  uploadImageKarmly(
    file_name: string,
    file: File,
    is_image: boolean = true,
    generate_thumbnail: boolean = false,
    min_dimension: number = 0,
    max_dimension: number = 0,
    max_file_size: number = 0
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!file) return resolve(null);

      const container = this.BLOB_CONTAINER_KEY_DOCUMENTS_KARMLY;

      const params = {
        file_name,
        container,
        generate_thumbnail
      };

      const file_size = file.size / 1024 / 1024;
      if (!!max_file_size && file_size > max_file_size) {
        return reject('The file provided is larger than the allowed file size of ' + max_file_size + 'MB');
      }

      this.apiService.get('INVOXY', 'karmly/document/sas', params)
        .then((res) => {

          if (!!generate_thumbnail) {
            ImageUtilService.scaleImageToMinSize(file, 150)
              .then((res1) => {
                const thumbnail_file = res1 as File;
                const data = {
                  image_url: res.thumb_url,
                  sas_key: res.thumb_sas_key
                };
                this.postBlob(data, thumbnail_file, this.BLOB_CONTAINER_KEY_THUMBNAILS_KARMLY)
                  .catch((err) => reject(err));
              });
          }

          const data = {
            image_url: res.image_url,
            sas_key: res.sas_key
          };

          if (is_image && !!min_dimension) {
            ImageUtilService.scaleImageToMinSize(file, min_dimension)
              .then((res1) => {
                const resized_file = res1 as File;
                this.postBlob(data, resized_file, this.BLOB_CONTAINER_KEY_DOCUMENTS_KARMLY)
                  .then(() => resolve(res.image_url))
                  .catch((err) => reject(err));
              });
          }
          else if (is_image && !!max_dimension) {
            ImageUtilService.scaleDownImageToMaxSize(file, max_dimension)
              .then((res2) => {
                const resized_file = res2 as File;
                this.postBlob(data, resized_file, this.BLOB_CONTAINER_KEY_DOCUMENTS_KARMLY)
                  .then(() => resolve(res.image_url))
                  .catch((err) => reject(err));
              });
          }
          else {
            this.postBlob(data, file, this.BLOB_CONTAINER_KEY_DOCUMENTS_KARMLY)
              .then(() => resolve(res.image_url))
              .catch((err) => reject(err));
          }
        });
    });
  }

  // If data.image_url is provided, save a new blob to Azure blob storage
  // If data.existing_image_url is provided delete the existing blob from Azure blob storage
  postBlob(data: BlobUpdateData, file: File = null, container: string = 'wbsgc4xdxsqe'): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const promises = [];

      if (!!file && !!data.image_url) {
        promises.push(this._saveDocumentBlob(data, file, container));
      }
      if (!!data.existing_image_url) {
        promises.push(this._deleteDocumentBlob({
          sas_delete_key: data.sas_delete_key,
          existing_image_url: data.existing_image_url
        }));
      }

      Promise.all(promises)
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  private _saveDocumentBlob(data: BlobUpdateData, file: File, container: string): Promise<BlockBlobUploadResponse> {
    const blob_service_client = new BlobServiceClient(ApiService.invoxy_blob_url + '?' + data.sas_key);
    const container_client = blob_service_client.getContainerClient(container);
    const blob_client = container_client.getBlockBlobClient(data.image_url);

    const blobOptions = { blobHTTPHeaders: { blobContentType: file.type, blobContentDisposition: 'attachment; filename=' + file.name } };

    return blob_client.upload(file, file.size, blobOptions);
  }

  private _deleteDocumentBlob(data: BlobDeleteData): Promise<BlobDeleteIfExistsResponse> {
    const blob_service_client = new BlobServiceClient(ApiService.invoxy_blob_url + '?' + data.sas_delete_key);
    const container_client = blob_service_client.getContainerClient(this.BLOB_CONTAINER_KEY_INVOXY);
    const blob_client = container_client.getBlockBlobClient(data.existing_image_url.split('/')[1]);

    return blob_client.deleteIfExists();
  }

}
