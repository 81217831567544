import { OnDestroy, Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgbDate, NgbDatepicker, NgbDatepickerNavigateEvent, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DateTime } from 'luxon';
import _ from 'lodash-es';

import { DomService } from '../../lib-services/dom/dom.service';
import { DateRange } from '../../lib.types';
import { BackdropComponent } from '../backdrop/backdrop.component';

type DrpDayConfig = {
  date: DateTime,
  range_start: boolean,
  range_end: boolean,
  in_range: boolean,
  first_day_of_month: boolean,
  last_day_of_month: boolean
};

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnDestroy {

  readonly num_months: number = 2;

  @ViewChild('ngb_dropdown') ngb_dropdown: NgbDropdown;
  @ViewChild('ngb_datepicker') ngb_datepicker: NgbDatepicker;

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (DomService.is_mobile !== this.is_mobile) {
      this.is_mobile = DomService.is_mobile;
    }
  }

  @Input() start_date: Date;
  @Input() end_date: Date;
  @Input() disabled: boolean = false;

  calendar_start_date: DateTime = null;
  calendar_end_date: DateTime = null;

  range_start: DateTime = null;
  range_end: DateTime = null;
  hover_date: DateTime = null;

  day_config: Record<string, DrpDayConfig> = {};

  backdrop_ref: BackdropComponent = null;

  dayTemplateData = (date: NgbDate, current?: { year: number, month: number }) => {
    return {
      date_key: this._getDateKey(this.toDateTime(date))
    };
  }

  @Output() date_range_updated = new EventEmitter<DateRange>();

  constructor(
    public domService: DomService
  ) { }

  ngOnDestroy() {
    this.backdrop_ref?.close();
  }

  calendarMonthsChanged(event: NgbDatepickerNavigateEvent) {
    this.calendar_start_date = DateTime.fromObject({
      year: event.next.year,
      month: event.next.month,
      day: 1
    });

    this.calendar_end_date = this.calendar_start_date
      .plus({ months: this.num_months - 1 })
      .endOf('month')
      .startOf('day');

    this._reloadDayConfig();
  }

  private _reloadCalendarOnOpen() {
    this.range_start = !!this.start_date ? DateTime.fromJSDate(this.start_date) : null;
    this.range_end = !!this.start_date && !!this.end_date ? DateTime.fromJSDate(this.end_date) : null;
    this.hover_date = null;

    const calendar_start = !!this.range_start ?
      {
        year: this.range_start.year,
        month: this.range_start.month,
        day: 1
      } :
      {
        year: this.ngb_datepicker.model.firstDate.year,
        month: this.ngb_datepicker.model.firstDate.month,
        day: 1
      };
    this.ngb_datepicker.navigateTo(calendar_start);
    this._reloadDayConfig();
  }

  onDateSelection(ngb_date: NgbDate) {
    const date = this.toDateTime(ngb_date);

    if (!this.range_start && !this.range_end) {
      this.range_start = date;
    }
    else if (
      !!this.range_start &&
      !this.range_end &&
      date > this.range_start
    ) {
      this.range_end = date;
      this._dateRangeUpdated();
    }
    else {
      this.range_start = date;
      this.range_end = null;
    }

    this._updateDayConfigRange();
  }

  mouseMouse(hover_date: NgbDate) {
    this.hover_date = this.toDateTime(hover_date);
    this._updateDayConfigRange();
  }

  dropdownToggleClicked() {
    this.openDropdown();
  }

  openDropdown() {
    this.ngb_dropdown?.open();
  }

  closeDropdown() {
    this.backdrop_ref?.close();
    this.ngb_dropdown?.close();
  }

  dropdownToggled(isOpen: boolean) {
    if (isOpen) {
      this._reloadCalendarOnOpen();
      this.backdrop_ref = this.domService.openBackdrop(null, { force_dark_backdrop: true });
    }
    else {
      this.backdrop_ref?.close();
    }
  }

  toDateTime(date: NgbDate): DateTime {
    if (!date) return null;
    return DateTime.fromObject({ year: date.year, month: date.month, day: date.day });
  }

  fromDateTime(date: DateTime): NgbDate {
    if (!date) return null;
    return new NgbDate(
      date.year,
      date.month,
      date.day
    );
  }

  private _dateRangeUpdated() {
    this.date_range_updated.emit({
      start_date: this.range_start.toJSDate(),
      end_date: this.range_end.toJSDate()
    });
  }

  private _getDateKey(date: DateTime): string {
    return date.toFormat('yyyyMMdd');
  }

  private _reloadDayConfig() {
    this.day_config = {};
    let date = _.cloneDeep(this.calendar_start_date);

    while (date <= this.calendar_end_date) {
      const date_key: string = this._getDateKey(date);

      this.day_config[date_key] = {
        date,
        range_start: this._isRangeStart(date),
        range_end: this._isRangeEnd(date),
        in_range: this._isInRange(date),
        first_day_of_month: this._firstDayOfMonth(date),
        last_day_of_month: this._lastDayOfMonth(date)
      };

      date = date.plus({ days: 1 });
    }
  }

  private _updateDayConfigRange() {
    for (const date_key of Object.keys(this.day_config)) {
      const config = this.day_config[date_key];

      config.range_start = this._isRangeStart(config.date);
      config.range_end = this._isRangeEnd(config.date);
      config.in_range = this._isInRange(config.date);
    }
  }

  private _isInRange(date: DateTime): boolean {
    if (!date) return false;
    const range_end = this.range_end || this.hover_date;

    return !!this.range_start &&
      date >= this.range_start &&
      date <= range_end;
  }

  private _isRangeStart(date: DateTime): boolean {
    if (!date) return false;
    return !!this.range_start && date.hasSame(this.range_start, 'day');
  }

  private _isRangeEnd(date: DateTime): boolean {
    if (!date) return false;
    const range_end = this.range_end || this.hover_date;

    return !!range_end && date.hasSame(range_end, 'day');
  }

  private _firstDayOfMonth(date: DateTime): boolean {
    if (!date) return false;
    return date.day === 1;
  }

  private _lastDayOfMonth(date: DateTime): boolean {
    if (!date) return false;
    const next_day = date.plus({ days: 1 });
    return !date.hasSame(next_day, 'month');
  }

}
