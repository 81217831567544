import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Report } from '../../lib-models/report/report';
import { TableReport } from '../../lib-models/report/table-report/table-report';
import { ChartReport } from '../../lib-models/report/chart-report/chart-report';
import { NumericReport } from '../../lib-models/report/numeric-report/numeric-report';
import { ReportService } from '../../lib-services/report/report.service';

@Component({
  selector: 'report-thumbnail',
  templateUrl: './report-thumbnail.component.html',
  styleUrls: ['./report-thumbnail.component.scss']
})
export class ReportThumbnailComponent implements OnInit, OnChanges {

  @Input() report: Report;

  icon_class: string = null;
  icon_prefix_class: ('ion' | 'bi') = null;

  constructor(
    public reportService: ReportService
  ) { }

  ngOnInit(): void {
    this._updateIconClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.report) {
      this._updateIconClasses();
    }
  }

  private _updateIconClasses() {
    if (!!this.report) {
      if (this.report instanceof TableReport) {
        this.icon_class = this.reportService.report_thumbnails.table;
      }
      else if (this.report instanceof ChartReport) {
        this.icon_class = this.reportService.report_thumbnails[this.report.graph_type];
      }
      else if (this.report instanceof NumericReport) {
        this.icon_class = this.reportService.report_thumbnails.numeric;
      }

      this.icon_prefix_class = this.icon_class.indexOf('bi') === 0 ? 'bi' : 'ion';
    }
    else {
      this.icon_class = null;
      this.icon_prefix_class = null;
    }
  }

}
