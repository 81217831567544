<div class="colourPicker-dropdownContainer"
     ngbDropdown
     #colourPicker
     [container]="disable_body_append ? null : 'body'"
     [autoClose]="'outside'">
  <div class="colourPicker-button"
       [ngStyle]="{
          'background-color': value,
          'border-color': value
       }"
       [ngClass]="{ '-disabled': disabled }"
       ngbDropdownToggle>
  </div>

  <div class="colourPicker-list"
       ngbDropdownMenu>
    <div class="colourPicker-listInner">
      <div class="colourPicker-row"
           *ngFor="let row of palette">
        <div class="colourPicker-cell"
             *ngFor="let cell of row"
             [ngStyle]="{ 'background-color': cell }"
             (click)="$event.stopPropagation(); value = cell">
        </div>
      </div>
    </div>
  </div>

</div>
