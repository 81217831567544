import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './app-spinner.component.html',
  styleUrls: ['./app-spinner.component.scss']
})
export class AppSpinnerComponent implements OnInit {

  @HostBinding('class.-hidden') hidden: boolean = true;

  ngOnInit(): void {
    // Avoid briefly showing the spinner for very short loading periods
    setTimeout(() => {
      this.hidden = false;
    }, 500);
  }

}
