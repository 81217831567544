import { KmNote } from '../km-note/km-note';
import { KmProjectTask } from '../km-project-task/km-project-task';

export type ClockGroup = {
  group_clock_key: number,
  project_task: KmProjectTask,
  active_clock: KmClock,
  on_hold_clocks: KmClock[],
  on_hold_clock_seconds: number
}

export class KmClock {

  project_task: KmProjectTask;
  clock_key: number;
  clock_in_time: Date;
  clock_out_time: Date;
  segment_key: number;
  on_hold_flag: boolean;
  group_clock_key: number;
  deleted_flag: boolean;
  notes: KmNote[];

  constructor(
    project_task: KmProjectTask,
    clock_key: number,
    clock_in_time: Date,
    clock_out_time: Date,
    segment_key: number,
    on_hold_flag: boolean,
    group_clock_key: number,
    notes: KmNote[] = null,
    deleted_flag: boolean = false
  ) {
    this.project_task = project_task;
    this.clock_key = clock_key;
    this.clock_in_time = clock_in_time;
    this.clock_out_time = clock_out_time;
    this.segment_key = segment_key;
    this.on_hold_flag = on_hold_flag;
    this.group_clock_key = group_clock_key || clock_key;
    this.deleted_flag = deleted_flag;
    this.notes = notes || [];
  }

}
