import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  AuthService,
  StateDataService,
  _NavMenuItem,
  NavMenuExternalItem,
  DomService,
  LibModalService,
  NavMenuServiceAbstract,
  NavMenuItemChild
} from 'flexitime-library';

import { StateAccessService } from '../state-access/state-access.service';
import { RoutePaths } from '../../app.route-types';

export type NavMenuItemPath = (
  'report'
);

export type NavMenuItem = _NavMenuItem & { path: NavMenuItemPath, children: (NavMenuItemChild & { path: RoutePaths, associated_paths?: RoutePaths[] })[] };

@Injectable({
  providedIn: 'root'
})
export class NavMenuService extends NavMenuServiceAbstract {

  readonly non_menu_states: string[] = [];

  readonly nav_menu_config: NavMenuItem[] = [];

  readonly popup_menu_config: Record<string, NavMenuExternalItem> = {};

  readonly custom_nav_menu_config_rules: Record<string, () => boolean> = {};

  constructor(
    public authService: AuthService,
    public router: Router,
    public stateDataService: StateDataService,
    public stateAccessService: StateAccessService,
    public domService: DomService,
    public libModalService: LibModalService
  ) {
    super(
      stateAccessService,
      stateDataService
    );
  }

  generateExternalNavMenu(): NavMenuExternalItem[] {
    return [];
  }

  generatePopupMenu(): NavMenuExternalItem[] {
    return [];
  }

}
