import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { InvProjectRateType, Label, InvProjectType } from '../../lib.types';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { ProjectUtilService } from '../../lib-services/project-util/project-util.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';

import _ from 'lodash-es';

export type InvProjectUser = {
  display_name: string;
  user_key: number;
  resource_flag: boolean;
  default_start_time: Date;
};

export type InvProjectTask = {
  task_name: string;
  task_display_name: string;
  task_key: number;
  admin_only_flag: boolean;
  archived_flag: boolean;
  unit_flag: boolean;
  has_time: boolean;
  unit_type: string;
  is_custom_unit: boolean;
  task_colour: string;
};

export class InvProject implements PostableObject<InvProject> {

  tasks: InvProjectTask[];
  users: InvProjectUser[];

  project_key: number;
  project_name: string;
  project_rate: number;
  project_type: InvProjectType;
  rate_type: InvProjectRateType;
  client_key: number;
  client_name: string;
  start_date: Date;
  end_date: Date;
  project_date: Date;
  labels: Label[];
  reference: string;

  additional_fields: any;
  billing_name: string;
  created_date: Date;
  has_time: boolean;
  notes: string;
  purchase_order_number: string;
  archived_flag: boolean;

  monday_hours: number;
  tuesday_hours: number;
  wednesday_hours: number;
  thursday_hours: number;
  friday_hours: number;
  saturday_hours: number;
  sunday_hours: number;

  constructor(
    tasks: InvProjectTask[] = [],
    users: InvProjectUser[] = [],
    project_key: number = null,
    project_name: string = '',
    project_rate: number = null,
    project_type: InvProjectType = 'Contractor',
    rate_type: InvProjectRateType = 'hours',
    client_key: number = null,
    client_name: string = null,
    start_date: Date = null,
    end_date: Date = null,
    project_date: Date = null,
    labels: Label[] = [],
    reference: string = null,
    additional_fields: any = null,
    billing_name: string = '',
    created_date: Date = null,
    has_time: boolean = false,
    notes: string = '',
    purchase_order_number: string = '',
    archived_flag: boolean = false,
    monday_hours: number = null,
    tuesday_hours: number = null,
    wednesday_hours: number = null,
    thursday_hours: number = null,
    friday_hours: number = null,
    saturday_hours: number = null,
    sunday_hours: number = null
  ) {
    this.tasks = tasks;
    this.users = users;
    this.project_key = project_key;
    this.project_name = project_name;
    this.project_rate = project_rate;
    this.project_type = project_type;
    this.rate_type = rate_type;
    this.client_key = client_key;
    this.client_name = client_name;
    this.start_date = start_date;
    this.project_date = project_date;
    this.end_date = end_date;
    this.labels = labels;
    this.reference = reference;

    this.additional_fields = additional_fields;
    this.billing_name = billing_name;
    this.created_date = created_date;
    this.has_time = has_time;
    this.notes = notes;
    this.purchase_order_number = purchase_order_number;
    this.archived_flag = archived_flag;

    this.monday_hours = monday_hours;
    this.tuesday_hours = tuesday_hours;
    this.wednesday_hours = wednesday_hours;
    this.thursday_hours = thursday_hours;
    this.friday_hours = friday_hours;
    this.saturday_hours = saturday_hours;
    this.sunday_hours = sunday_hours;
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (!this.project_name) {
      errors['project_name'] = CoreUtilService.project_label.capitalised + ' name required';
    }
    if (!this.client_key) {
      errors['client_key'] = 'Client required';
    }
    if (!CoreUtilService.numberIsValid(this.project_rate) && this.project_rate >= 0) {
      errors['project_rate'] = 'Billing rate required';
    }
    if (ProjectUtilService.isPeriodicProject(this)) {
      if (!TimeUtilService.dateIsValid(this.start_date)) {
        errors['start_date'] = 'Start date required';
      }
      if (!TimeUtilService.dateIsValid(this.end_date)) {
        errors['end_date'] = 'End date required';
      }
    }
    else {
      if (!TimeUtilService.dateIsValid(this.project_date)) {
        errors['project_date'] = CoreUtilService.project_label.capitalised + ' date required';
      }
    }

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  formatForPosting(to_archive: boolean = false): any {
    if (!this.getErrors().has_errors) {

      const start_date = ProjectUtilService.isPeriodicProject(this) ? TimeUtilService.dateToDateString(this.start_date) : null;
      const end_date = ProjectUtilService.isPeriodicProject(this) ? TimeUtilService.dateToDateString(this.end_date) : null;
      const project_date = ProjectUtilService.isPeriodicProject(this) ? null : TimeUtilService.dateToDateString(this.project_date);

      const project_rate = this.project_type === 'Credit Note' ? (Math.abs(this.project_rate) * -1) : this.project_rate;

      return {
        project_key: this.project_key,
        project_name: this.project_name,
        project_rate,
        project_type: this.project_type,
        rate_type: this.rate_type,
        client_key: this.client_key,
        start_date,
        end_date,
        project_date,
        labels: !!this.labels ? JSON.stringify(this.labels) : null,
        reference: this.reference,
        additional_fields: this.additional_fields,
        billing_name: this.billing_name,
        archived_flag: to_archive,
        notes: this.notes,
        purchase_order_number: this.purchase_order_number,
        monday_hours: this.monday_hours,
        tuesday_hours: this.tuesday_hours,
        wednesday_hours: this.wednesday_hours,
        thursday_hours: this.thursday_hours,
        friday_hours: this.friday_hours,
        saturday_hours: this.saturday_hours,
        sunday_hours: this.sunday_hours
      };
    }
    return null;
  }

  formatTasksForPosting() {
    const tasks: any[] = _.cloneDeep(this.tasks);
    for (const task of tasks) {
      task.project_key = this.project_key;
    }
    return tasks;
  }

  formatUsersForPosting() {
    const users: any[] = _.cloneDeep(this.users);
    for (const user of users) {
      user.project_key = this.project_key;
    }
    return users;
  }

}
