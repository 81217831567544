import { Label } from '../../lib.types';

export type InvUserForm = {
  approved_date: Date;
  form_data: any;
  form_type: string;
  user_key: number;
}

export type InvUserIntegration = {
  additional_data: any;
  integration_type: string;
  reference_id: string;
  updated_date: Date;
}

export type InvUserProject = {
  client_key: number;
  client_name: string;
  deleted_flag: boolean;
  end_date: Date;
  has_time: boolean;
  labels: Label[];
  owner_percentage: number;
  project_approver_flag: boolean;
  project_billing_recipient: boolean;
  project_key: number;
  project_name: string;
  project_type: string;
  rate_type: string;
  resource_rate: number;
  sales_group: null
  start_date: Date;
}

export type InvUserIrdDetails = {
  tax_code: string,
  kiwisaver_percentage: string
}

export class InvUser {

  access_deactivated: boolean;
  access_pending_flag: boolean;
  additional_fields: any;
  admin_flag: boolean;
  approver_flag: boolean;
  auth_value: number;
  bank_account: string;
  bcti_flag: boolean;
  billing_recipient: boolean;
  business_flag: boolean;
  client_contact_flag: boolean;
  commission_plans: any[];
  commission_user: boolean;
  company_key: number;
  company_number: string;
  consultant_flag: boolean;
  country_key: string;
  created_date: Date;
  dashboard_config: any;
  default_start_time: Date;
  display_name: string;
  documents: any[];
  email_address: string;
  first_name: string;
  has_time: boolean;
  highest_auth: string;
  integrations: InvUserIntegration[];
  invite_expired: boolean;
  ird_details: InvUserIrdDetails;
  last_name: string;
  manage_time_flag: boolean;
  manage_time_role: string;
  merge_user_key: number;
  mute_approvals_flag: boolean;
  onboards: any[];
  owner_flag: boolean;
  phone_mobile: string;
  phone_office: string;
  projects: InvUserProject[];
  reference: string;
  registered_email: string;
  registered_flag: boolean;
  resource_flag: boolean;
  salary: number;
  sales_target: number;
  show_start_end_times_flag: boolean;
  show_timesheets: boolean;
  tax_number: string;
  tfnd_details: any;
  trading_name: string;
  updated_date: Date;
  user_access_company_key: number;
  user_access_flag: boolean;
  user_address: string;
  user_dob: Date;
  user_forms: InvUserForm[];
  user_key: number;
  user_role: string;
  withholding_tax: number;
  website_link: string;
  availability: string;
  about_me: string;
  display_image: string;
  cover_image: string;
  profile_image: string;

  constructor(
    access_deactivated: boolean,
    access_pending_flag: boolean,
    additional_fields: any,
    admin_flag: boolean,
    approver_flag: boolean,
    auth_value: number,
    bank_account: string,
    bcti_flag: boolean,
    billing_recipient: boolean,
    business_flag: boolean,
    client_contact_flag: boolean,
    commission_plans: any[],
    commission_user: boolean,
    company_key: number,
    company_number: string,
    consultant_flag: boolean,
    country_key: string,
    created_date: Date,
    dashboard_config: any,
    default_start_time: Date,
    display_name: string,
    documents: any[],
    email_address: string,
    first_name: string,
    has_time: boolean,
    highest_auth: string,
    integrations: InvUserIntegration[],
    invite_expired: boolean,
    ird_details: InvUserIrdDetails,
    last_name: string,
    manage_time_flag: boolean,
    manage_time_role: string,
    merge_user_key: number,
    mute_approvals_flag: boolean,
    onboards: any[],
    owner_flag: boolean,
    phone_mobile: string,
    phone_office: string,
    projects: InvUserProject[],
    reference: string,
    registered_email: string,
    registered_flag: boolean,
    resource_flag: boolean,
    salary: number,
    sales_target: number,
    show_start_end_times_flag: boolean,
    show_timesheets: boolean,
    tax_number: string,
    tfnd_details: any,
    trading_name: string,
    updated_date: Date,
    user_access_company_key: number,
    user_access_flag: boolean,
    user_address: string,
    user_dob: Date,
    user_forms: InvUserForm[],
    user_key: number,
    user_role: string,
    withholding_tax: number,
    website_link: string,
    availability: string,
    about_me: string,
    display_image: string,
    cover_image: string,
  ) {
    this.access_deactivated = access_deactivated;
    this.access_pending_flag = access_pending_flag;
    this.additional_fields = additional_fields;
    this.admin_flag = admin_flag;
    this.approver_flag = approver_flag;
    this.auth_value = auth_value;
    this.bank_account = bank_account;
    this.bcti_flag = bcti_flag;
    this.billing_recipient = billing_recipient;
    this.business_flag = business_flag;
    this.client_contact_flag = client_contact_flag;
    this.commission_plans = commission_plans;
    this.commission_user = commission_user;
    this.company_key = company_key;
    this.company_number = company_number;
    this.consultant_flag = consultant_flag;
    this.country_key = country_key;
    this.created_date = created_date;
    this.dashboard_config = dashboard_config;
    this.default_start_time = default_start_time;
    this.display_name = display_name;
    this.documents = documents;
    this.email_address = email_address;
    this.first_name = first_name;
    this.has_time = has_time;
    this.highest_auth = highest_auth;
    this.integrations = integrations;
    this.invite_expired = invite_expired;
    this.ird_details = ird_details;
    this.last_name = last_name;
    this.manage_time_flag = manage_time_flag;
    this.manage_time_role = manage_time_role;
    this.merge_user_key = merge_user_key;
    this.mute_approvals_flag = mute_approvals_flag;
    this.onboards = onboards;
    this.owner_flag = owner_flag;
    this.phone_mobile = phone_mobile;
    this.phone_office = phone_office;
    this.projects = projects;
    this.reference = reference;
    this.registered_email = registered_email;
    this.registered_flag = registered_flag;
    this.resource_flag = resource_flag;
    this.salary = salary;
    this.sales_target = sales_target;
    this.show_start_end_times_flag = show_start_end_times_flag;
    this.show_timesheets = show_timesheets;
    this.tax_number = tax_number;
    this.tfnd_details = tfnd_details;
    this.trading_name = trading_name;
    this.updated_date = updated_date;
    this.user_access_company_key = user_access_company_key;
    this.user_access_flag = user_access_flag;
    this.user_address = user_address;
    this.user_dob = user_dob;
    this.user_forms = user_forms;
    this.user_key = user_key;
    this.user_role = user_role;
    this.withholding_tax = withholding_tax;
    this.website_link = website_link;
    this.availability = availability;
    this.about_me = about_me;
    this.display_image = display_image;
    this.cover_image = cover_image;


  }

}
