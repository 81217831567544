import { Component, Input, OnInit, ViewChild, Output, EventEmitter, HostBinding, TemplateRef, HostListener } from '@angular/core';

import { AppModalInterface, ComponentLoaderDirective } from '../../../public-api';

export type ExpandingCanvasOptions = {
  z_index?: number,
  position?: ('top' | 'bottom'),
  backdrop_class?: string,
  close_on_click?: boolean
};

@Component({
  selector: 'lib-expanding-canvas-wrapper',
  templateUrl: './expanding-canvas-wrapper.component.html',
  styleUrls: ['./expanding-canvas-wrapper.component.scss']
})
export class ExpandingCanvasWrapperComponent implements OnInit {

  readonly DELAYS = {
    BACKGROUND: 300,
    CONTENT: 300
  };

  @HostListener('click', ['$event'])
  handleClick() {
    if (this.options.close_on_click) {
      this.triggerClose(null);
    }
  }

  @HostBinding('class.-showBackground') show_background: boolean = false;
  @HostBinding('class.-showContent') show_content: boolean = false;
  @HostBinding('class.-top') position_top: boolean = false;
  @HostBinding('style.z-index') z_index: number = null;

  @ViewChild(ComponentLoaderDirective, { static: true }) component_loader: ComponentLoaderDirective;

  // Component must be either a TemplateRef or a component that implements AppModalInterface
  @Input() component: TemplateRef<any> | any;
  @Input() component_properties: Record<string, any> = {};

  @Input() options: ExpandingCanvasOptions = null;

  @Output() close = new EventEmitter<any>();
  @Output() dismiss = new EventEmitter<void>();

  component_is_template: boolean = false;
  component_instance: AppModalInterface = null;

  constructor() { }

  ngOnInit(): void {
    this._initOptions();

    this.component_is_template = this.component instanceof TemplateRef;
    if (!this.component_is_template) {
      this.loadComponent();
    }
    else {
      setTimeout(() => this._openAnimation());
    }
  }

  loadComponent() {
    const view_container_ref = this.component_loader.view_container_ref;
    view_container_ref.clear();

    this.component_instance = view_container_ref.createComponent(this.component).instance as AppModalInterface;

    for (const key of Object.keys(this.component_properties)) {
      this.component_instance[key] = this.component_properties[key];
    }

    this.component_instance.close.subscribe((result) => this.triggerClose(result));
    this.component_instance.dismiss.subscribe(() => this.triggerDismiss());

    // If the component implements this event emitter, 
    // it contains asynchronous logic that must be completed before its content can be loaded
    if (!!this.component_instance.modal_initialised) {
      this._delayedOpenAnimation();
    }
    else {
      this._openAnimation();
    }
  }

  triggerClose(result: any) {
    this._closeAnimation()
      .then(() => this.close.emit(result));
  }

  triggerDismiss() {
    this._closeAnimation()
      .then(() => this.dismiss.emit());
  }

  private _openAnimation() {
    setTimeout(() => {
      this.show_background = true;

      setTimeout(() => {
        this.show_content = true;
      }, this.DELAYS.BACKGROUND);
    });
  }

  private _delayedOpenAnimation() {
    setTimeout(() => {
      this.show_background = true;

      this.component_instance.modal_initialised.subscribe(() => {
        setTimeout(() => {
          this.show_content = true;
        });
      });
    });
  }

  private _closeAnimation() {
    return new Promise<void>((resolve) => {
      this.show_content = false;

      setTimeout(() => {
        this.show_background = false;
      }, this.DELAYS.CONTENT);

      setTimeout(() => {
        resolve();
      }, this.DELAYS.CONTENT + this.DELAYS.BACKGROUND);
    });
  }

  private _initOptions() {
    if (!this.options) {
      this.options = {
        z_index: null,
        position: 'bottom',
        close_on_click: false
      };
    }

    this.position_top = this.options.position === 'top';
    this.z_index = this.options.z_index;
  }
}
