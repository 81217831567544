<div class="ecw-componentContainer">
  <ng-template componentLoader></ng-template>
  
  <ng-container *ngIf="component_is_template">
    <ng-container *ngTemplateOutlet="component"></ng-container>
  </ng-container>
</div>
<div class="ecw-componentPlaceholder">
  <app-spinner *ngIf="!!component_instance.modal_initialised && !show_content"></app-spinner>
</div>
