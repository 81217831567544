import { Component, Input, OnInit, ViewChild, Output, HostListener, EventEmitter, OnDestroy } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { DomService } from '../../lib-services/dom/dom.service';
import { StateDataService } from '../../lib-services/state-data/state-data.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { BackdropComponent } from '../backdrop/backdrop.component';
@Component({
  selector: 'day-selector',
  templateUrl: './day-selector.component.html',
  styleUrls: ['./day-selector.component.scss']
})
export class DaySelectorComponent implements OnInit, OnDestroy {

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  @Input() disabled: boolean = false;

  is_mobile: boolean = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.is_mobile = DomService.is_mobile;
  }

  private _selected_day: Date;
  get selected_day(): Date {
    return this._selected_day;
  }
  set selected_day(selected_day: Date) {
    this._selected_day = selected_day;

    this.stateDataService.selected_day = this._selected_day;
    this.day_changed.emit();
    this.closeDropdown();
  }

  @Output() day_changed = new EventEmitter();
  
  backdrop_ref: BackdropComponent = null;

  constructor(
    public stateDataService: StateDataService,
    private domService: DomService
  ) { }

  ngOnInit() {
    this.selected_day = this.stateDataService.selected_day;
  }

  ngOnDestroy() {
    this.backdrop_ref?.close();
  }

  dropdownToggled(isOpen: boolean) {
    if (isOpen) {
      this.backdrop_ref = this.domService.openBackdrop(null, { force_dark_backdrop: true });
    }
    else {
      this.backdrop_ref?.close();
    }
  }

  closeDropdown() {
    this.backdrop_ref?.close();
    this.dropdown?.close();
  }

  forwardDay() {
    this.selected_day = TimeUtilService.incrementDate(this.selected_day, 1);
  }

  backDay() {
    this.selected_day = TimeUtilService.incrementDate(this.selected_day, -1);
  }

}
