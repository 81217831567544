export type KarmlyIntercomSettings = {
  app_id: string,
  hide_default_launcher: boolean,
  email: string,
  name: string,
  user_id: string,
  user_hash: string,
  alignment: string,
  company_key: number,
  number_of_projects: number,
  number_of_clients: number,
  google_calendar_integrated: string,
  xero_integrated: string,
  invoxy_integrated: string,
  from_invoxy: string
};


export type IntercomSettings = {
  email: string,
  hide_default_launcher: boolean,
  name: string,
  user_id: string,
  companies: {
    company_id: string,
    name: string
  }[],
  user_hash: string
};

export class InvUac {

  admin_flag: boolean;
  approver_flag: boolean;
  auth_value: number;
  business_flag: boolean;
  client_contact_flag: boolean;
  commission_user: boolean;
  company_key: number;
  consultant_flag: boolean;
  dashboard_config: any;
  disable_feedback: boolean;
  display_name: string;
  external_user_access_key: number;
  full_name: string;
  intercom_settings: IntercomSettings;
  karmly_intercom_settings: KarmlyIntercomSettings;
  manage_time_flag: boolean;
  owner_flag: boolean;
  partner_flag: boolean;
  pending_owner_flag: boolean;
  resource_flag: boolean;
  sample_user: boolean;
  show_timesheets: boolean;
  user_access_company_key: number;
  user_actions: any[];
  user_key: number;
  payhero_integrated_flag: boolean;
  profile_image: string;
  profile_image_thumbnail: string;

  constructor(
    admin_flag: boolean,
    approver_flag: boolean,
    auth_value: number,
    business_flag: boolean,
    client_contact_flag: boolean,
    commission_user: boolean,
    company_key: number,
    consultant_flag: boolean,
    dashboard_config: any,
    disable_feedback: boolean,
    display_name: string,
    external_user_access_key: number,
    full_name: string,
    intercom_settings: IntercomSettings,
    karmly_intercom_settings: KarmlyIntercomSettings,
    manage_time_flag: boolean,
    owner_flag: boolean,
    partner_flag: boolean,
    pending_owner_flag: boolean,
    resource_flag: boolean,
    sample_user: boolean,
    show_timesheets: boolean,
    user_access_company_key: number,
    user_actions: any[],
    user_key: number,
    payhero_integrated_flag: boolean,
    profile_image: string,
    profile_image_thumbnail: string
  ) {
    this.admin_flag = admin_flag;
    this.approver_flag = approver_flag;
    this.auth_value = auth_value;
    this.business_flag = business_flag;
    this.client_contact_flag = client_contact_flag;
    this.commission_user = commission_user;
    this.company_key = company_key;
    this.consultant_flag = consultant_flag;
    this.dashboard_config = dashboard_config;
    this.display_name = display_name;
    this.disable_feedback = disable_feedback;
    this.external_user_access_key = external_user_access_key;
    this.full_name = full_name;
    this.intercom_settings = intercom_settings;
    this.karmly_intercom_settings = karmly_intercom_settings;
    this.manage_time_flag = manage_time_flag;
    this.owner_flag = owner_flag;
    this.partner_flag = partner_flag;
    this.pending_owner_flag = pending_owner_flag;
    this.resource_flag = resource_flag;
    this.sample_user = sample_user;
    this.show_timesheets = show_timesheets;
    this.user_access_company_key = user_access_company_key;
    this.user_actions = user_actions;
    this.user_key = user_key;
    this.payhero_integrated_flag = payhero_integrated_flag;
    this.profile_image = profile_image;
    this.profile_image_thumbnail = profile_image_thumbnail;
  }
}
