<button class="-type-icon"
        [ngClass]="buttonConfig.button_color_class"
        [disabled]="buttonConfig.button_disabled"
        (click)="clickButton()">
  <i class="ion"
     *ngIf="buttonConfig.button_icon_class"
     [ngClass]="buttonConfig.button_icon_class">
  </i>
  <span [hidden]="!buttonOpen">
    {{buttonConfig.button_label}}
  </span>
</button>
