import { Component, Input, OnInit } from '@angular/core';

import {
  ReportConfigTable,
  ReportConfigMeasure,
  ReportConfigDimension,
  ReportChartType,
  ReportMeasurePermission
} from '../../lib.types';

import { ChartReport } from '../../lib-models/report/chart-report/chart-report';
import { ReportService } from '../../lib-services/report/report.service';

import _ from 'lodash-es';

@Component({
  selector: 'report-chart-setting',
  templateUrl: './report-chart-setting.component.html',
  styleUrls: ['./report-chart-setting.component.scss']
})
export class ReportChartSettingComponent implements OnInit {

  readonly config = this.reportService.getConfig();
  readonly graph_types = this.reportService.getChartTypes();

  @Input() report: ChartReport;

  selected_graph_type: { id: ReportChartType, label: string } = null;

  available_facts: ReportConfigTable[] = [];
  available_series: ReportConfigDimension[] = [];

  no_series: ReportConfigDimension = {
    id: null,
    label: 'No Series',
    datatype: null
  };

  fieldIsVisible = (field: ReportConfigMeasure | ReportConfigDimension) => {
    return !field.system_only_flag;
  }

  constructor(
    public reportService: ReportService
  ) { }

  ngOnInit(): void {
    this._initAvailableFacts();
    this._initSelectedValues();
  }

  selectGraphType(graph_type: { id: ReportChartType, label: string }) {
    this.selected_graph_type = graph_type;
    this.report.graph_type = this.selected_graph_type.id;
  }

  selectFact(fact: ReportConfigTable) {
    if (fact.id !== this.report.table_config.id) {
      this.report.table_config = fact;

      this.selectMeasure(this._getFirstVisibleMeasure());
      this.selectDimension(this._getFirstVisibleDimension());
      this.selectSeries(this.no_series);
    }
  }

  selectMeasure(measure: ReportConfigMeasure) {
    this.report.measure = measure;
  }

  selectFunction(graph_function: ReportMeasurePermission) {
    this.report.graph_function = graph_function;
  }

  selectDimension(dimension: ReportConfigDimension) {
    this.report.dimension = dimension;

    this.updateAvailableSeries();
    if (this.report.series?.dimension.id === this.report.dimension.id) {
      this.selectSeries(this.no_series);
    }
  }

  selectSeries(dimension: ReportConfigDimension) {
    if (dimension.id !== null) {
      this.report.series = {
        dimension,
        show_legend: this.report.series?.show_legend || false,
        stack: this.report.series?.stack || true,
      };
    }
    else {
      this.report.series = null;
    }
    this.updateAvailableSeries();
  }

  updateAvailableSeries() {
    const available_series = [];

    for (const dimension of this.report.table_config.dimensions) {
      if (
        dimension.id !== this.report.dimension.id &&
        this.fieldIsVisible(dimension)
      ) {
        available_series.push(dimension);
      }
    }

    this.available_series = available_series;
  }

  private _initSelectedValues() {
    this.selected_graph_type = _.find(this.graph_types, {
      id: this.report.graph_type
    }) || this.graph_types[0];

    if (!!this.report.table_config) {
      this.report.measure = this.report.measure || this._getFirstVisibleMeasure();
      this.report.dimension = this.report.dimension || this._getFirstVisibleDimension();

      this.updateAvailableSeries();
    }
  }

  private _getFirstVisibleMeasure(): ReportConfigMeasure {
    for (const measure of this.report.table_config.measures) {
      if (this.fieldIsVisible(measure)) {
        return measure;
      }
    }
    return null;
  }

  private _getFirstVisibleDimension(): ReportConfigDimension {
    for (const dimension of this.report.table_config.dimensions) {
      if (this.fieldIsVisible(dimension)) {
        return dimension;
      }
    }
    return null;
  }

  private _initAvailableFacts() {
    const available_facts = [];

    for (const fact of this.config.facts) {
      if (!!fact.measures.length && !!fact.dimensions.length) {
        available_facts.push(fact);
      }
    }

    this.available_facts = available_facts;
  }

}
