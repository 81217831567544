import { Component, OnInit, HostListener, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { StateDataService } from '../../lib-services/state-data/state-data.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';

import { DomService } from '../../lib-services/dom/dom.service';
import { BackdropComponent } from '../backdrop/backdrop.component';
import { DateTime } from 'luxon';

@Component({
  selector: 'month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss']
})
export class MonthPickerComponent implements OnInit {

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  @Input() selected_date: Date = null;
  @Input() min_date: Date = null;

  @Output() update_date = new EventEmitter<Date>();
  @Output() date_error = new EventEmitter<string>();

  backdrop_ref: BackdropComponent = null;
  is_open: boolean = false;

  is_mobile: boolean = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.is_mobile = DomService.is_mobile;
  }

  private _selected_month: Date;
  get selected_month(): Date {
    return this._selected_month;
  }
  set selected_month(monthStart: Date) {
    this._selected_month = monthStart;
  }

  private _selected_year: Date;
  get selected_year(): Date {
    return this._selected_year;
  }
  set selected_year(year: Date) {
    this._selected_year = year;
  }

  month_index: number = null;

  constructor(
    public domService: DomService,
    public stateDataService: StateDataService
  ) { }

  ngOnInit(): void {
    this.selected_month = this.selected_date;
    this.selected_year = this.selected_date;
    this.month_index = this.selected_date?.getMonth();
  }

  dropdownToggled(is_open: boolean) {
    this.is_open = is_open;
    if (this.is_open) {
      this.backdrop_ref = this.domService.openBackdrop(null, { force_dark_backdrop: true });
    }
    else {
      this.backdrop_ref?.close();
    }
  }

  closeDropdown() {
    this.dropdown.close();
    this.backdrop_ref?.close();
  }

  // month selected from calendar view
  monthSelected(date: Date) {
    this.selected_date = date;
    this.month_index = this.selected_date.getMonth();
    this.selected_year = date;
    this.validateDates();

    this.closeDropdown();
  }

  updateMonth() {
    if (this.month_index !== null) {
      const date = new Date();
      if (!this.selected_month) this.selected_month = new Date(date.getFullYear(), date.getMonth(), 1);
      this.selected_month = TimeUtilService.updateMonth(this.selected_month, this.month_index);
    } else {
      this.selected_month = null;
    }

    if (!!this.selected_year && !!this.selected_month) {
      this.selected_date = TimeUtilService.updateYear(this.selected_month, this.selected_year.getFullYear());
    }
    this.validateDates();
  }

  parseYearFromView(value: number) {
    const date = new Date();
    if (!!value && !this.selected_year) this.selected_year = new Date(date.getFullYear(), 0, 1);
    //TODO: validate year ?
    if (!!value) {
      this.selected_year = TimeUtilService.updateYear(this.selected_year, value);
    } else {
      this.selected_year = null;
    }

    if (!!this.selected_year && !!this.selected_month) {
      this.selected_date = TimeUtilService.updateMonth(this.selected_year, this.selected_month.getMonth());
    } else {
      this.selected_date = null;
    }
    this.validateDates();
  }

  validateDates() {
    if (
      !!this.min_date &&
      !!this.selected_date &&
      DateTime.fromJSDate(this.selected_date) < DateTime.fromJSDate(this.min_date)
    ) {
      this.date_error.emit('End date cannot be before start date');
    }
    this.update_date.emit(this.selected_date);
  }

}
