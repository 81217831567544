import { AfterContentInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { AppNumberPipe } from '../../lib-pipes/app-number/app-number.pipe';
import { AppCurrencyPipe } from '../../lib-pipes/app-currency/app-currency.pipe';
import { DateTime } from 'luxon';
import { EnvService } from '../../lib-services/env/env.service';

@Component({
  selector: 'half-doughnut-chart',
  templateUrl: './half-doughnut-chart.component.html',
  styleUrls: ['./half-doughnut-chart.component.scss']
})
export class HalfDoughnutChartComponent implements OnInit, /* AfterContentInit,  */OnChanges {

  @ViewChild('chart') chart_element: ElementRef;

  @Input() min_value: number | Date = null;
  @Input() max_value: number | Date = null;
  @Input() value: number | Date = null;
  @Input() value_type: ('NUMBER' | 'CURRENCY' | 'DATE') = 'NUMBER';
  @Input() title: string = null;

  @Input() max_value_exceeded_text: string = null;
  @Input() date_format: string = 'd MMM, yy';
  @Input() no_current_value_text: string = 'None Set';
  @Input() animation_fps: number = 60;
  @Input() animation_duration = 1000;

  readonly currency_symbol = CoreUtilService.currency_symbol;
  readonly currency_code = CoreUtilService.currency_code;

  readonly canvas_width = 600;
  readonly canvas_height = 300;

  private readonly _arc_center_x = this.canvas_width / 2;
  private readonly _arc_center_y = (this.canvas_height / 3) * 2;
  private readonly _arc_radius = (Math.min(this.canvas_width, this.canvas_height) / 2) - 20;
  private readonly _arc_offset = -180;

  private _renderer: any = null;
  private _context: any = null;

  max_value_exceeded = false;

  constructor(
    public appNumberPipe: AppNumberPipe,
    public appCurrencyPipe: AppCurrencyPipe,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.max_value_exceeded = this._formatMaxValue() < this._formatValue();
  }

  ngOnDestroy() {
    if (!!this._renderer) {
      clearInterval(this._renderer);
    }
  }

  // ngAfterContentInit() {
  //   setTimeout(() => {
  //     this.renderReport();
  //   });
  // }

  ngOnChanges(changes: SimpleChanges): void {
    // Checking a value not expected to change so multiple overlapping renders aren't triggered
    if (!changes.value_type && (!!changes.max_value || !!changes.min_value || !!changes.value)) {
      setTimeout(() => {
        this.renderReport();
      });
    }
  }

  renderReport() {
    // console.log(this.min_value, this.max_value, this.value, this.value_type)
    this._renderer = this._render(this.chart_element.nativeElement);
  }

  private _render(canvas: any) {
    this._context = canvas.getContext('2d');
    this._context.lineWidth = 15;
    this._context.lineCap = 'round';
    this._context.textAlign = 'center';

    const min_value = this._formatMinValue();
    const max_value = this._formatMaxValue();
    const value = this._formatValue();

    const value_fraction = this._getValueFraction(
      min_value,
      max_value,
      value
    );

    const start_angle = 0;
    const end_angle = value_fraction * 180;

    const frame_time = 1000 / this.animation_fps;
    const frame_count = Math.round(this.animation_duration / frame_time);

    let current_arc_angle = null;
    let current_value = null;
    let current_frame = 0;

    const renderer = setInterval(() => {
      this._context.clearRect(0, 0, this.canvas_width, this.canvas_height);
      current_frame++;

      if (this.value !== null) {
        if (current_frame === frame_count) {
          current_arc_angle = end_angle;
          current_value = value;
        }
        else {
          current_arc_angle = this._getArcAngle(current_frame, start_angle, end_angle, frame_count);
          current_value = Math.ceil((max_value - min_value) * (current_frame / frame_count));
        }
      }

      this._renderArc(current_arc_angle);
      this._renderCenterText(current_value);
      this._renderTitleText();
      this._renderMinMaxValueText(min_value, max_value);

      if (current_frame >= frame_count) {
        clearInterval(renderer);
      }
    }, frame_time);

    return renderer;
  }

  private _renderArc(current_arc_angle: number) {
    // Grey arc
    this._context.strokeStyle = '#efefef';

    this._context.beginPath();
    this._context.arc(
      this._arc_center_x,
      this._arc_center_y,
      this._arc_radius,
      CoreUtilService.degreesToRadians(0 + this._arc_offset),
      CoreUtilService.degreesToRadians(180 + this._arc_offset),
      false
    );
    this._context.stroke();

    // Green arc
    if (this.value !== null) {
      this._context.strokeStyle = this.max_value_exceeded ? '#c03a36' : '#5eb22e';

      this._context.beginPath();
      this._context.arc(
        this._arc_center_x,
        this._arc_center_y,
        this._arc_radius,
        CoreUtilService.degreesToRadians(0 + this._arc_offset),
        CoreUtilService.degreesToRadians(current_arc_angle + this._arc_offset),
        false
      );
      this._context.stroke();
    }
  }

  private _renderCenterText(current_value: number) {
    if (this.value !== null) {
      this._context.fillStyle = '#333';
      this._context.font = '500 30px ' + EnvService.font_family;

      let text = this._parseValue(current_value);
      if (this.max_value_exceeded && !!this.max_value_exceeded_text) {
        text = this.max_value_exceeded_text;
      }

      this._context.fillText(
        text,
        this._arc_center_x,
        this._arc_center_y - 20
      );
    }
    else {
      this._context.fillStyle = '#999';
      this._context.font = '400 30px ' + EnvService.font_family;

      this._context.fillText(
        this.no_current_value_text,
        this._arc_center_x,
        this._arc_center_y - 20
      );
    }
  }

  private _renderTitleText() {
    if (this.title !== null) {
      this._context.fillStyle = '#999';
      this._context.font = '400 22px ' + EnvService.font_family;

      this._context.fillText(
        this.title,
        this._arc_center_x,
        40
      );
    }
  }

  private _renderMinMaxValueText(min_value: number, max_value: number) {
    this._context.fillStyle = '#999';
    this._context.font = '400 22px ' + EnvService.font_family;

    if (this.min_value !== null) {
      this._context.fillText(
        this._parseValue(min_value),
        this._arc_center_x - this._arc_radius,
        this._arc_center_y + 40
      );
    }

    if (this.max_value !== null) {
      this._context.fillText(
        this._parseValue(max_value),
        this._arc_center_x + this._arc_radius,
        this._arc_center_y + 40
      );
    }
  }

  private _getArcAngle(
    current_frame: number,
    start_angle: number,
    end_angle: number,
    frame_count: number
  ) {
    const power = 3;
    current_frame /= frame_count / 2;

    if (current_frame < 1) {
      return (end_angle / 2) * (Math.pow(current_frame, power)) + start_angle;
    }
    current_frame -= 2;
    return end_angle / 2 * (Math.pow(current_frame, power) + 2) + start_angle;
  }

  private _getValueFraction(
    min_value: number,
    max_value: number,
    current_value: number
  ): number {
    if (current_value > max_value) {
      return 1;
    }
    else {
      return current_value / (max_value - min_value);
    }
  }

  private _formatMinValue(): number {
    if (this.value_type === 'DATE') {
      return 0;
    }
    return this.min_value as number;
  }

  private _formatMaxValue(): number {
    if (this.value_type === 'DATE') {
      const max = DateTime.fromJSDate(this.max_value as Date);
      const min = DateTime.fromJSDate(this.min_value as Date);
      return max.diff(min, 'days').as('days');
    }
    return this.max_value as number;
  }

  private _formatValue(): number {
    if (this.value_type === 'DATE') {
      const value = DateTime.fromJSDate(this.value as Date);
      const min = DateTime.fromJSDate(this.min_value as Date);
      return value.diff(min, 'days').as('days');
    }
    return this.value as number;
  }

  private _parseValue(value: number) {
    if (this.value_type === 'NUMBER') {
      return this.appNumberPipe.transform(value, 0);
    }
    else if (this.value_type === 'CURRENCY') {
      return this.appCurrencyPipe.transform(value, 0);
    }
    else if (this.value_type === 'DATE') {
      const min = DateTime.fromJSDate(this.min_value as Date);
      const date = min.plus({ days: value }).toJSDate();
      return this.datePipe.transform(date, this.date_format);
    }
    return value;
  }

}
