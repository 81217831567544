import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash-es';
import { lastValueFrom } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { ApiConfig, ProductValue } from '../../lib.types';

import { DomService } from '../dom/dom.service';
import { TokenLoginService } from '../token-login/token-login.service';

type ApiErrorType = ProductValue | 'BLOB';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public static subscription_api_url: string = '';
  public static subscription_blob_url: string = '';
  public static invoxy_api_url: string = '';
  public static invoxy_blob_url: string = '';
  public static droppah_api_url: string = '';

  private _delayed_api_queue: any[] = [];

  constructor(
    @Inject('env') public env: any,
    public http: HttpClient,
    public authService: AuthService,
    public domService: DomService,
    public tokenLoginService: TokenLoginService
  ) {
    this._initUrls();
  }

  get(
    product: ProductValue,
    url: string,
    params: any = null,
    config: ApiConfig = null
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      product = product === 'KARMLY' ? 'INVOXY' : product;

      const api_call = (force_reject: boolean = false) => {
        if (force_reject) {
          return reject();
        }

        this.authService.updateTimeout();

        const product_url = this._getProductApiUrl(product);
        const options: any = {
          headers: this._getHeader(product, config),
          params
        };

        if (config?.no_auth) {
          delete options.headers;
        }
        if (!!config?.response_type) {
          options.responseType = config.response_type;
        }

        lastValueFrom(
          this.http.get(product_url + url, options)
        )
          .then((res: any) => {
            if (product === 'INVOXY') {
              if (res?.success || config?.response_type === 'text') {
                resolve((config?.get_full_response || config?.response_type === 'text') ? res : res.result);
              }
              else {
                reject(this._processAPIError(res, 'INVOXY', config?.hide_error));
              }
            }
            else {
              resolve(res);
            }
          })
          .catch((err) => {
            if (err.status === 401) {
              this._handleUnauthorisedError(
                err, product, config, api_call, reject
              );
            }
            else {
              reject(this._processAPIError(err, product, config?.hide_error));
            }
          });
      };

      if (this.tokenLoginService.token_login_in_progress) {
        this._delayed_api_queue.push(api_call);
      }
      else {
        api_call();
      }
    });
  }

  post(
    product: ProductValue,
    url: string,
    data: any = null,
    config: ApiConfig = null
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      product = product === 'KARMLY' ? 'INVOXY' : product;

      const api_call = (force_reject: boolean = false) => {
        if (force_reject) {
          return reject();
        }

        this.authService.updateTimeout();

        const product_url = this._getProductApiUrl(product);
        const options = {
          headers: this._getHeader(product, config)
        };

        lastValueFrom(
          this.http.post(product_url + url, data, options)
        )
          .then((res: any) => {
            if (product === 'INVOXY') {
              if (res?.success) {
                resolve(config?.get_full_response ? res : res.result);
              }
              else {
                reject(this._processAPIError(res, 'INVOXY', config?.hide_error));
              }
            }
            else {
              resolve(res);
            }
          })
          .catch((err) => {
            if (err.status === 401) {
              this._handleUnauthorisedError(
                err, product, config, api_call, reject
              );
            }
            else {
              reject(this._processAPIError(err, product, config?.hide_error));
            }
          });
      };

      if (this.tokenLoginService.token_login_in_progress) {
        this._delayed_api_queue.push(api_call);
      }
      else {
        api_call();
      }
    });
  }

  blobGet(
    product: ProductValue,
    url: string,
    params: any = null,
    config: ApiConfig = null
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.authService.updateTimeout();

      const product_url = this._getProductApiUrl(product);
      const options: any = {
        headers: this._getHeader(product, config),
        params,
        responseType: 'arraybuffer'
      };

      lastValueFrom(
        this.http.get(product_url + url, options)
      )
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(this._processAPIError(err, 'BLOB', config?.hide_error));
        });
    });
  }

  private _initUrls() {
    switch (this.env.product) {
      case 'KARMLY':
      case 'DROPPAH':
      case 'INVOXY':
        ApiService.subscription_api_url = this.env.subscription_api_url;
        ApiService.subscription_blob_url = this.env.subscription_blob_url;
        ApiService.invoxy_api_url = this.env.node_url;
        ApiService.invoxy_blob_url = this.env.blob_url;
        ApiService.droppah_api_url = this.env.api_url;
        break;
      case 'FLEXITIME':
        ApiService.subscription_api_url = this.env.subscription.api_url;
        ApiService.subscription_blob_url = this.env.subscription.blob_url;
        break;
    }
  }

  private _getProductApiUrl(product: ProductValue): string {
    switch (product) {
      case 'INVOXY':
        return ApiService.invoxy_api_url;
      case 'DROPPAH':
        return ApiService.droppah_api_url;
      case 'FLEXITIME':
        return ApiService.subscription_api_url;
    }
    return '';
  }

  private _getHeader(product: ProductValue, config: ApiConfig) {
    switch (product) {
      case 'FLEXITIME':
        return this.authService.getSubscriptionHttpHeader();
      default:
        return this.authService.getHttpHeader(
          config?.session_only || false,
          config?.include_sub_session || false
        );
    }
  }

  private _handleUnauthorisedError(
    error: any,
    error_type: ApiErrorType,
    config: ApiConfig = null,
    doCall: (force_reject: boolean) => void,
    reject: (reason: any) => void
  ) {
    // Already in the process of reauthenticating using a login token
    if (this.tokenLoginService.token_login_in_progress) {
      // Queue this api call to be called after token authentication finished
      this._delayed_api_queue.push(doCall);
    }
    // Start the process of reauthenticating using a login token
    else {
      // Queue this api call to be called after token authentication finished
      this._delayed_api_queue = [doCall];

      this.tokenLoginService.tokenLogin()
        .then(() => {
          // Reauthorised
          // Execute any pending api calls sitting in the queue
          for (const api_call of this._delayed_api_queue) {
            api_call();
          }
          this._delayed_api_queue = [];
        })
        .catch(() => {
          // Token authentication failed
          // Reject the promises of any pending api calls sitting in the queue
          for (const api_call of this._delayed_api_queue) {
            api_call(true);
          }
          this._delayed_api_queue = [];
          reject(this._processAPIError(error, error_type, config?.hide_error));
        });
    }
  }

  private _processAPIError(
    err: any,
    error_type: ApiErrorType,
    hide_error: boolean = false
  ) {
    hide_error = hide_error || false;

    if (error_type === 'INVOXY' && err && err.status === 503) {
      this.authService.logout('Sorry, Invoxy is currently down for maintenance. We should be back shortly.');
    }
    else {
      const res = {
        message: null,
        data: err
      };

      let message = null;

      switch (error_type) {
        case 'INVOXY':
          message = err && err.error && err.error.message ? err.error.message : null;
          break;
        case 'FLEXITIME':
          message = err && err.error ? err.error : null;
          break;
        case 'BLOB':
          message = err && err.result && err.result.error ? err.result.error : null;
          break;
        case 'DROPPAH':
          message = err && err.error && err.error.message ? err.error.message : null;
          break;
      }

      if (!!message && typeof message === 'string') {
        res.message = message;
      }
      else {
        res.message = 'Something went wrong. Please try again later or get in touch if this issue persists.';
      }

      if (!hide_error && res.message) {
        this.showError(res.message);
      }

      return res;
    }
  }

  async showError(
    message: string
  ): Promise<void> {
    this.domService.openNotificationPopover(message, 'ERROR');
  }


}
