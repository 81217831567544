import { IntegrationOption, IntegrationRecord, IntegrationType } from '../../../lib.types';
import { LibModalService } from '../../../lib-services/lib-modal/lib-modal.service';
import { Inject } from '@angular/core';
import { TaskServiceInterface } from '../../../lib-interfaces/task-service.interface';
import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { IntegrationServiceAbstract } from '../../abstract-services/integration-service/integration-service.abstract';
import { Subscription } from 'rxjs';
import { TrackingService } from '../../../lib-services/tracking/tracking.service';
import { DomService } from '../../../lib-services/dom/dom.service';


export abstract class ManageIntegration {

  abstract option_map: Partial<Record<IntegrationType, IntegrationOption>>;
  abstract options: IntegrationOption[];
  abstract loading_html: string;

  integrations: Partial<Record<IntegrationType, IntegrationRecord>> = null;

  integration_events: Record<string, Subscription> = {};

  loading: boolean = false;

  abstract setSettingValues(option: IntegrationOption, integration: IntegrationRecord): void;

  constructor(
    public integrationService: IntegrationServiceAbstract,
    public libModalService: LibModalService,
    public trackingService: TrackingService,
    public domService: DomService,
    @Inject('taskService') public taskService: TaskServiceInterface,
  ) { }

  initEventListeners() {
    this.integration_events = {};

    this.integration_events.INTEGRATION_CONNECTED = this.integrationService.subscribeToEvent(
      'INTEGRATION_CONNECTED',
      (event_data) => {
        this.getIntegrations();
        this.domService.openNotificationPopover(
          'Your account has successfully been connected to ' + event_data.integration_name,
          'SUCCESS'
        );

        if (event_data.integration_name === 'Xero') {
          const tracking_data = {
            integration_type: 'Xero',
            action: 'Connected',
            integration_date: TimeUtilService.dateToDateString(new Date(Date.now()))
          };
          this.trackingService.trackEvent('Integration', 'Karmly', tracking_data);
        }
      });

    this.integration_events.INTEGRATION_CONNECTION_ERROR = this.integrationService.subscribeToEvent(
      'INTEGRATION_CONNECTION_ERROR',
      (event_data) => {
        this.getIntegrations();
        const message = 'There was a problem connecting to <span class="-bold">' + event_data.integration_name + '.</span>' +
          (event_data.error ? '<br>' + event_data.error : '');

        this.domService.openNotificationPopover(
          message, 'ERROR', true, null, true
        );
      });

    this.integration_events.INTEGRATION_DISCONNECTED = this.integrationService.subscribeToEvent(
      'INTEGRATION_DISCONNECTED',
      (event_data) => {
        this.getIntegrations();
        this.domService.openNotificationPopover(
          'Successfully disconnected from ' + event_data.integration_name,
          'SUCCESS'
        );

        if (event_data.integration_name === 'Xero') {
          const tracking_data = {
            integration_type: 'Xero',
            action: 'Disconnected'
          };
          this.trackingService.trackEvent('Integration', 'Karmly', tracking_data);
        }
        this.clearConnection(event_data.integration_type);
      });
  }

  initialiseConnections() {
    for (const integration of Object.values(this.integrations)) {
      for (const key of Object.keys(this.option_map)) {
        if (integration.integration_type === key) {
          const option = this.option_map[key];
          option.connected = true;
          option.active = integration.active_flag;
          option.updated_date = TimeUtilService.dateToDateTimeString(integration.updated_date, 'dd MMM, yyyy - hh:mm a.') || '';
          this.setSettingValues(option, integration);
        }
      }
    }
  }

  getIntegrations() {
    this.integrations = this.integrationService.getIntegrations();

    this.initialiseConnections();
    this.loading = false;
  }

  connectXero(karmly_flag: boolean = false) {
    const tab = window.open(null, '', 'height=650,left=500,top=110,width=550');
    tab.document.write(this.loading_html);

    this.integrationService.getXeroAuthURL(karmly_flag)
      .then((URL) => {
        if (!URL) return;
        tab.location.href = URL;

      });
  }

  disconnectXero() {
    this.loading = true;
    this.integrationService.disconnectXero()
      .then((result) => {
        this.libModalService.successModal(result);
        this.clearConnection('XERO');

        this.taskService.getXeroAccountCodes(true)
          .then(() => {
            this.taskService.getXeroTaxRates(true);
          });
      })
      .catch(() => {
        this.loading = false;
      });

  }

  clearConnection(integration_type: IntegrationType) {
    const option = this.option_map[integration_type];

    option.connected = false;
    option.details = [];
    option.syncing = false;

    for (const setting of option.settings) {
      setting.value = '';
    }
  }

  clearEventListeners() {
    for (const event_type of Object.keys(this.integration_events)) {
      this.integration_events[event_type].unsubscribe();
    }
  }

}
