import _ from 'lodash-es';

import { PostableObjectErrorMap } from '../../../lib-interfaces/postable-object.interface';
import { ReportConfigTable, Label, ReportMeasurePermission, ReportConfigMeasure, ReportConfigDimension } from '../../../lib.types';
import { Report, ReportFilter, ReportQuery } from '../report';

export type TableReportMeasure = { measure: ReportConfigMeasure, func: ReportMeasurePermission };

export class TableReport extends Report {

  report_type_label: string = 'Table';
  measures: TableReportMeasure[];
  dimensions: ReportConfigDimension[];

  constructor(
    table_config: ReportConfigTable,
    dimensions: ReportConfigDimension[] = [],
    measures: TableReportMeasure[] = [],
    report_key: number = null,
    report_title: string = '',
    labels: Label[] = [],
    filters: ReportFilter[] = []
  ) {
    super(
      'TABLE',
      table_config,
      report_key,
      report_title,
      labels,
      filters
    );

    this.dimensions = dimensions;
    this.measures = measures;
  }

  generateReportQuery(): ReportQuery {
    const valid_measures = this._validateMeasures();
    const valid_dimensions = this._validateDimensions();

    const measures = valid_measures.map((tm) => {
      return {
        field: tm.measure.id,
        func: tm.func
      };
    });
    const dimensions = valid_dimensions.map(dimension => dimension.id);

    return {
      report_is_table: true,
      table: this.table_config.id,
      fields: dimensions,
      groups: dimensions,
      measures,
      filters: this.formatFiltersForPosting()
    };
  }

  getErrors(report_title_required: boolean = true): PostableObjectErrorMap {
    const errors = super.getErrors(report_title_required);

    if (this.dimensions.length === 0) {
      errors['dimensions'] = 'At least one category is required';
    }

    return errors;
  }

  formatForPosting(to_delete: boolean = false): any {
    const data = super.formatForPosting(to_delete);

    const measures = this.measures.map(tm => {
      return {
        func: tm.func,
        id: tm.measure.id
      };
    });
    const dimensions = this.dimensions.map(dimension => dimension.id);

    data.measures = JSON.stringify(measures);
    data.dimensions = JSON.stringify(dimensions);

    return data;
  }

  private _validateMeasures(): TableReportMeasure[] {
    const valid_measures = [];

    for (const tm of this.measures) {
      if (_.findIndex(this.table_config.measures, { id: tm.measure.id }) !== -1) {
        valid_measures.push(tm);
      }
    }

    return valid_measures;
  }

  private _validateDimensions(): ReportConfigDimension[] {
    const valid_dimensions = [];

    for (const dimension of this.dimensions) {
      if (_.findIndex(this.table_config.dimensions, { id: dimension.id }) !== -1) {
        valid_dimensions.push(dimension);
      }
    }

    return valid_dimensions;
  }

}