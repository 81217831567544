import { Directive, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';

export type DroppahGraphic = (
  'balance' | 'beer' | 'burger' | 'drag_and_drop' | 'fries' | 
  'goals' | 'hospo' | 'juggle' | 'rest' | 'upskill' | 'time'
);

@Directive({
  selector: '[productGraphic]'
})
export class ProductGraphicDirective implements OnChanges {

  @Input() graphic_name: DroppahGraphic = null;

  graphic_url: string = null;

  constructor(
    public elementRef: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.graphic_name) {
      this._updateGraphicUrl();
    }
  }

  private _updateGraphicUrl() {
    if (!!this.graphic_name) {
      this.graphic_url = 'assets/product-imgs/droppah/graphics/' + this.graphic_name + '.svg';
    }
    else {
      this.graphic_url = '';
    }

    this.elementRef.nativeElement.src = this.graphic_url;
  }

}
