import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Label } from '../../lib.types';
import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';

import _ from 'lodash-es';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

@Component({
  selector: 'label-panel',
  templateUrl: './label-panel.component.html',
  styleUrls: ['./label-panel.component.scss']
})
export class LabelPanelComponent {

  @ViewChildren('label') label_components: QueryList<ElementRef> = null;

  hidden_labels: Label[] = [];
  label_map: Record<string, Label> = {};

  @Input() enable_remove: boolean = false;

  private _labels: Label[] = [];
  @Input()
  get labels(): Label[] {
    return this._labels;
  }
  set labels(value: Label[]) {
    this._labels = SortUtilService.sortList(value, { primary_sort_property: 'title' });
    setTimeout(() => this.reloadVisibleLabels());
  }

  @Output() remove_label = new EventEmitter<Label>();

  constructor(
    public elementRef: ElementRef
  ) { }

  reloadVisibleLabels() {
    setTimeout(() => {
      this._updateLabelMap();
      this._updateHiddenLabels();
    });
  }

  dropdownToggled(is_open: boolean) {
    if (is_open) {
      this._updateHiddenLabels();
    }
  }

  removeLabel(label: Label) {
    if (this.enable_remove) {
      this.remove_label.emit(label);
      CoreUtilService.removeLabelFromList(label, this.labels);
      this.reloadVisibleLabels();
    }
  }

  addLabel(label: Label) {
    this.labels.push(label);
  }

  private _updateLabelMap() {
    this.label_map = {};
    for (const label of this.labels) {
      this.label_map['label_' + label.title + '_' + label.color] = label;
    }
  }

  private _updateHiddenLabels() {
    this.hidden_labels = [];
    const label_elems = this.label_components.toArray();
    // TODO: hide first element if its width < label-panel width

    for (const elem of label_elems) {
      if (elem.nativeElement.offsetTop !== 0) {
        const label = this.label_map[elem.nativeElement.id];
        this.hidden_labels.push(label);
      }
    }
  }

}
