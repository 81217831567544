<div class="table-borderBottom"></div>
<div class="table-borderLeft"></div>
<div class="table-borderRight"></div>

<div id="table-outerContainer"
     infinite-scroll
     [infiniteScrollContainer]="'#table-outerContainer'"
     [infiniteScrollDistance]="1"
     [fromRoot]="true"
     (scrolled)="loadMoreVisibleRows()">

  <div class="table-colHeaderRowBackground"></div>

  <div class="table-innerContainer">

    <div class="table-colContainer"
         *ngFor="let column of col_list"
         [ngStyle]="{
                 'width': column.width + 'px',
                 'text-align': column.column_function ? 'right' : 'left'}">

      <report-table-column [column]="column"
                           [row_sort]="row_sort"
                           [visible_table_rows]="visible_table_rows"
                           (sort_rows)="sortRowsByCol($event)">
      </report-table-column>

      <div class="table-colResize"
           reportTableResizer
           [col]="column">
      </div>

    </div>


  </div>
</div>
