<img class="app-splash-logo"
     src="assets/product-imgs/flexitime/FlexiTime_Full.svg">

<app-spinner class="-splashSpinner"></app-spinner>

<footer class="app-footer">
  <div class="app-footer-fromFlexitime">
    <img src="assets/product-imgs/flexitime/FlexiTime_WFM_Full.svg" />
  </div>
</footer>
