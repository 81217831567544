import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

@Pipe({
  name: 'hoursToTimePipe',
})
export class HoursToTimePipe implements PipeTransform {

  /**
   * Takes hours in decimal and returns a formatted time.
   */
  transform(value: number, allow_null: boolean = false, trim: boolean = false) {
    if (allow_null && value === null){
      return null;
    }

    const total_minutes = Math.round(Duration.fromObject({hours: value}).as('minutes'));
    const date_time = Duration.fromObject({ minutes: total_minutes }).normalize();
    return date_time.toFormat(trim ? 'h:mm' : 'hh:mm');
  }
}
