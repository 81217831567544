<ng-container *ngIf="!is_mobile">

  <div class="iel-leftContainer">
    <div class="iel-icon -reorder"
         *ngIf="!invoice.posted_flag">
      <i class="ion ion-md-code"></i>
    </div>
    <ng-content select="[slot=drag-handle]">
    </ng-content>
  </div>

  <div class="iel-rowContainer">
    <div class="iel-row">

      <div class="iel-col -icon -icon-generated"
           *ngIf="line.generated_flag">
        <i class="bi bi-lightning-charge-fill"></i>
        <tooltip-popover [tooltip_content]="'This line was generated automatically'">
        </tooltip-popover>
      </div>

      <div class="iel-col -description">
        <div class="app-formItem-inputContainer -word-break">
          <textarea placeholder="Add Description"
                    [id]="'line-editor-' + line.line_index"
                    [disabled]="invoice.posted_flag">
          </textarea>

          <div class="app-formItem-inputContainer-border"
               *ngIf="!invoice.posted_flag">
          </div>
        </div>
      </div>

      <div class="iel-col -quantity"
           *ngIf="show_quantity">
        <div class="app-formItem-inputContainer"
             *ngIf="!invoice.posted_flag">

          <div class="input-group">
            <input type="number"
                   class="form-control"
                   placeholder="0.00"
                   [disabled]="invoice.template_type === 'AOG'"
                   [ngModelOptions]="{ 'updateOn': 'blur' }"
                   [(ngModel)]="line.quantity"
                   (ngModelChange)="quantity_changed.emit({ line: line })">
          </div>

          <div class="app-formItem-inputContainer-border"
               *ngIf="!invoice.posted_flag">
          </div>
        </div>

        <div *ngIf="invoice.posted_flag">
          {{line.quantity | appNumber}}
        </div>
      </div>

      <div class="iel-col -rate"
           *ngIf="show_rate">
        <div class="app-formItem-inputContainer"
             *ngIf="!invoice.posted_flag">

          <div class="input-group">
            <div class="input-group-addon">
              {{currency_symbol}}
            </div>
            <input type="number"
                   class="form-control"
                   placeholder="0.00"
                   [disabled]="invoice.template_type === 'AOG'"
                   [ngModelOptions]="{ 'updateOn': 'blur' }"
                   [(ngModel)]="line.rate"
                   (ngModelChange)="rate_changed.emit({ line: line })">
          </div>

          <div class="app-formItem-inputContainer-border"
               *ngIf="!invoice.posted_flag">
          </div>
        </div>

        <div *ngIf="invoice.posted_flag">
          {{line.rate | appCurrency}}
        </div>
      </div>

      <div class="iel-col -total"
           *ngIf="show_total">
        <div>{{line.net_amount | appCurrency}}</div>
      </div>

      <div class="iel-col -icon -icon-delete"
           *ngIf="!invoice.posted_flag">
        <i class="ion ion-md-close app-remove"
           (click)="remove_invoice_line.emit({ line: line })">
        </i>
      </div>

    </div>
    <div class="iel-row"
         *ngIf="!line.generated_flag && !!project">

      <div class="iel-col -project">
        <div class="iel-project">
          <item-bullet [bullet_colour]="project.project_colour"></item-bullet>
          <span>{{project.project_name}}</span>
        </div>
      </div>

    </div>
  </div>

</ng-container>

<ng-container *ngIf="is_mobile">

  <div class="app-list-item -invoiceLine"
       [ngClass]="{ '-itemSelectDisabled': invoice.posted_flag }">
    <div class="app-list-itemCell">
      <div class="app-list-itemDetail">

        <div class="app-list-itemDetail-value -description">
          <i class="bi bi-lightning-charge-fill"
             *ngIf="line.generated_flag">
          </i>
          <span [innerHtml]="line.line_description | safeHtml"></span>
        </div>

        <div class="app-list-itemDetail-label -project"
             *ngIf="!line.generated_flag && !!project">
          <item-bullet [bullet_colour]="project.project_colour"></item-bullet>
          <span>{{project.project_name}}</span>
        </div>

        <div class="app-list-itemDetail-value"
             *ngIf="show_quantity || show_rate ||show_total">
          <span>
            <span *ngIf="show_quantity">
              {{line.quantity | appNumber}}
            </span>
            <span *ngIf="show_quantity && show_rate">
              @
            </span>
            <span *ngIf="show_rate">
              {{line.rate | appCurrency}}
            </span>
          </span>
          <span *ngIf="show_total">
            {{line.net_amount | appCurrency}}
          </span>
        </div>

        <div class="app-formItem-errorLabel -errorVisible"
             *ngFor="let line_error of line_errors">
          {{line_error}}
        </div>

      </div>
    </div>
  </div>

</ng-container>
