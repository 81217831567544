<list-drop-down [button_color_class]="'-color-white-outline'"
                [button_type_class]="'-type-dropdown'"
                [button_title]="button_title"
                [list_fill_width]="true"
                [show_search]="true"
                [multi_select_config]="selected_account_dropdown_config"
                [multi_select_selected_items]="selected_accounts"
                [item_config]="account_dropdown_config"
                [items]="all_accounts"
                (multi_select_items_selected)="accountsSelected($event.items)">
</list-drop-down>
