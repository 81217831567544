import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { CompanyEventType, CompanyServiceInterface, WeekDayShort } from 'flexitime-library';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements CompanyServiceInterface {

  getUac(): any {
    return null;
  }

  getCompany(): any {
    return null;
  }

  getWeekStart(): WeekDayShort {
    return 'mon';
  }

  subscribeToEvent(event_type: CompanyEventType, callback: (event_data: any) => void): Subscription {
    return null;
  }

  isInteractiveDemo(): boolean {
    return false;
  }

  getProfileImage(): string {
    return null;
  }


}
