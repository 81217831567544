import { Component, OnInit, Input, Output, EventEmitter, ComponentRef, OnDestroy, HostListener, ViewChild, Inject } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { CompanyServiceInterface } from '../../lib-interfaces/company-service.interface';

import { DomService } from '../../lib-services/dom/dom.service';
import { StateDataService } from '../../lib-services/state-data/state-data.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { ProductValue } from '../../lib.types';
import { BackdropComponent } from '../backdrop/backdrop.component';

@Component({
  selector: 'week-selector',
  templateUrl: './week-selector.component.html',
  styleUrls: ['./week-selector.component.scss']
})
export class WeekSelectorComponent implements OnInit, OnDestroy {

  readonly product: ProductValue = this.env.product;

  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  @Input() disabled: boolean = false;
  @Input() min_date: Date = null;
  @Input() max_date: Date = null;
  @Input() show_week_relation_text: boolean = false;
  @Input() show_week_relation_buttons: boolean = false;
  @Input() select_day: boolean = false; // update the specific day picked

  week_relation_string: string = null;

  show_next_button = true;
  show_prev_button = true;

  is_mobile: boolean = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.is_mobile = DomService.is_mobile;
  }

  week_end: Date;

  private _date_picked: Date;
  get date_picked(): Date {
    return this._date_picked;
  }

  set date_picked(date_picked: Date) {
    this._date_picked = date_picked;

    if (this.select_day) {
      this.stateDataService.selected_day = date_picked;
    }

    this.week_start = date_picked;
  }

  private _week_start: Date;
  get week_start(): Date {
    return this._week_start;
  }
  set week_start(week_start: Date) {
    const week_start_day = this.companyService.getWeekStart();

    if (!!week_start_day) {
      week_start = TimeUtilService.getWeekStartForDate(week_start, week_start_day);
    }

    this._week_start = week_start;
    this.stateDataService.selected_week_start = this._week_start;
    this.week_end = this.stateDataService.selected_week_end;

    this.refreshWeekRelationText();
    this.week_changed.emit();
    this.closeDropdown();
  }

  backdrop_ref: BackdropComponent = null;

  @Output() week_changed = new EventEmitter();

  constructor(
    public stateDataService: StateDataService,
    private domService: DomService,
    @Inject('companyService') public companyService: CompanyServiceInterface,
    @Inject('env') public env: any
  ) { }

  ngOnInit() {
    this._date_picked = this.stateDataService.selected_day;
    this._week_start = this.stateDataService.selected_week_start;
    this.week_end = this.stateDataService.selected_week_end;
    this.refreshWeekRelationText();
  }

  ngOnDestroy() {
    this.backdrop_ref?.close();
  }

  dropdownToggled(isOpen: boolean) {
    if (isOpen) {
      this.backdrop_ref = this.domService.openBackdrop(null, { force_dark_backdrop: true });
    }
    else {
      this.backdrop_ref?.close();
    }
  }

  closeDropdown() {
    this.backdrop_ref?.close();
    this.dropdown?.close();
  }

  weekRelationButton(relation: number) {
    const week_start_day = this.companyService.getWeekStart();
    const currentWeekStart = TimeUtilService.getWeekStartForDate(new Date(), week_start_day);
    this.week_start = TimeUtilService.incrementDate(currentWeekStart, 7 * relation);
  }

  refreshWeekRelationText() {
    if (this.show_week_relation_text) {
      const week_start_day = this.companyService.getWeekStart();
      const currentWeekStart = TimeUtilService.getWeekStartForDate(new Date(), week_start_day);

      this.week_relation_string = null;
      for (let i = -1; i <= 1; i++) {
        if (TimeUtilService.datesAreEqual(TimeUtilService.incrementDate(currentWeekStart, i * 7), this._week_start)) {
          switch (i) {
            case -1:
              this.week_relation_string = 'Last Week';
              break;
            case 0:
              this.week_relation_string = 'This Week';
              break;
            case 1:
              this.week_relation_string = 'Next Week';
              break;
          }
        }
      }
    }
  }

  forwardWeek() {
    const incrementAmount = 7;
    const dateToIncrementTo = TimeUtilService.incrementDate(this.week_start, incrementAmount);
    if (!this.max_date || dateToIncrementTo < this.max_date) {
      this.week_start = TimeUtilService.incrementDate(this.week_start, incrementAmount);
    }
  }

  backWeek() {
    const incrementAmount = -7;
    const dateToIncrementTo = TimeUtilService.incrementDate(this.week_start, incrementAmount);
    if (!this.min_date || dateToIncrementTo > this.min_date) {
      this.week_start = TimeUtilService.incrementDate(this.week_start, incrementAmount);
    }
  }
}
