<input class="form-control"
       highlight-on-click
       [ngStyle]="{ 'font-weight': _value > 0 && bold_non_zeros ? 'var(--app-font-weight-bold)' : '' }"
       [disabled]="input_disabled"
       [ngModel]="hours | number: '2.0'"
       [ngModelOptions]="{ 'updateOn': 'blur'}"
       (ngModelChange)="hoursUpdated($event)">

<div class="dsi-spacer">:</div>

<input class="form-control"
       highlight-on-click
       [ngStyle]="{ 'font-weight': _value > 0 && bold_non_zeros ? 'var(--app-font-weight-bold)' : '' }"
       [disabled]="input_disabled"
       [ngModel]="minutes | number: '2.0'"
       [ngModelOptions]="{ 'updateOn': 'blur'}"
       (ngModelChange)="minutesUpdated($event)">
