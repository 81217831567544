import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';

import { StateDataService } from './../state-data/state-data.service';
import { DomService } from '../dom/dom.service';
import { Auth, AuthLoginToken } from '../../lib.types';


declare const Base64: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static subscription_app_url: string = '';

  private _timeout = {
    period: 1000 * 60 * 60,
    timer: null
  };

  private _auth: Auth = {
    session_key: null,
    company_key: null,
    company_code: null,
    return_login_token: null,
    return_session_key: null,
    user_access_company_key: null,
    product_refresh_token: null
  };

  private _trace = null;

  private _disable_timeout: boolean = false;

  constructor(
    @Inject('env') public env: any,
    public stateDataService: StateDataService,
    public router: Router,
    public http: HttpClient
  ) {
    this._initUrls();
    this._auth = this.stateDataService.auth || this._auth;
    this._trace = this._generateUUID();

    this._initEventListeners();
  }


  private _initEventListeners() {
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateTimeout();
      });
  }

  get token_login_enabled(): boolean {
    switch (this.env.product) {
      case 'DROPPAH':
        return true;
      case 'KARMLY':
        return DomService.is_mobile;
      case 'INVOXY':
        return false;
      case 'FLEXITIME':
        return true;
    }
    return false;
  }

  get return_login_token(): AuthLoginToken {
    return this._auth.return_login_token || null;
  }
  set return_login_token(value: AuthLoginToken) {
    this._auth.return_login_token = value;
    this.stateDataService.auth = this._auth;
  }

  set return_session_key(value: string) {
    this._auth.return_session_key = value;
    this.stateDataService.auth = this._auth;
  }

  get product_refresh_token(): string {
    return this._auth.product_refresh_token || null;
  }
  set product_refresh_token(product_refresh_token: string) {
    this._auth.product_refresh_token = product_refresh_token;
    this.stateDataService.auth = this._auth;
  }

  hasSession() {
    if (this.env.product === 'FLEXITIME') {
      return !!this._auth?.session_key;
    }
    else {
      return !!this._auth && !!this._auth.session_key && !!this._auth.company_key;
    }
  }

  productRefreshLogin(
    session_key: string,
    product_refresh_token: string,
    return_session_key: string = null,
    company_code: string = null,
    user_access_company_key: number = null
  ) {
    this._auth.session_key = session_key;
    this._auth.product_refresh_token = product_refresh_token;
    this._auth.return_session_key = return_session_key;
    this._auth.company_code = company_code;
    this._auth.user_access_company_key = user_access_company_key;

    this.stateDataService.auth = this._auth;
  }

  externalLogin(
    session_key: string,
    company_key: number,
    user_access_company_key: number,
    return_session_key: string = null
  ) {
    this._auth.session_key = session_key;
    this._auth.company_key = company_key;
    this._auth.user_access_company_key = user_access_company_key;
    this._auth.return_session_key = return_session_key || this._auth.return_session_key;

    this.stateDataService.auth = this._auth;
  }

  logout(error_message: string = null, domain: string = null) {
    this._auth = {
      session_key: null,
      company_key: null,
      company_code: null,
      return_login_token: null,
      return_session_key: null,
      user_access_company_key: null,
      product_refresh_token: null,
      external_user_access_key: null
    };
    this.stateDataService.auth = null;

    const url_params = {
      error_message: error_message || null,
      domain: domain || null,
      product: this.env.product
    };

    this.goApplet('login', url_params);
  }

  goApplet(state: string, params: any = null, new_tab: boolean = false) {
    let url = AuthService.subscription_app_url;

    if (state === 'login') {
      url += 'login?destination=' + this.env.product + '_APP';

      if (params) {
        url += '&encoded_params=' + Base64.encode(JSON.stringify(params));
      }
    }
    else {
      url += 'loginExternal?product=' + this.env.product + '&urlParams=' + Base64.encode(
        JSON.stringify({
          session_key: this._auth.return_session_key,
          state,
          params
        })
      );
    }

    if (!new_tab) {
      window.location.href = url;
    }
    else {
      window.open(url, '_blank');
    }
  }

  updateTimeout() {
    if (!!this._auth.session_key && !this.token_login_enabled) {

      if (this._timeout.timer) {
        clearTimeout(this._timeout.timer);
      }
      if (!this._disable_timeout) {
        this._timeout.timer = setTimeout(() => {
          this.logout('You\'ve been logged out due to inactivity');
        }, this._timeout.period);
      }
    }
  }

  disableTimeout(set_timeout: boolean) {
    this._disable_timeout = set_timeout;
    this.updateTimeout();
  }

  getHttpHeader(session_only: boolean = false, include_sub_session: boolean = false) {
    const httpHeader: any = {
      trace: this._trace
    };

    const header_auth = session_only ?
      this._auth.session_key :
      (this._auth.company_key + ':' + this._auth.session_key);

    httpHeader.Authorization = 'Basic ' + Base64.encode(header_auth);

    // Add out custom product header for use in the API
    if (this.env.product !== 'DROPPAH') {
      httpHeader.product = this.env.product;
    }

    if (include_sub_session) {
      httpHeader.sub_session_key = Base64.encode(this._auth.return_session_key);
    }

    return httpHeader;
  }

  getSubscriptionHttpHeader() {
    if (this.env.product === 'FLEXITIME' && !!this._auth.session_key) {
      return {
        trace: this._trace,
        Authorization: 'Basic ' + Base64.encode(this._auth.session_key)
      };
    }
    else if (this.env.product !== 'FLEXITIME' && !!this._auth.return_session_key) {
      return {
        trace: this._trace,
        Authorization: 'Basic ' + Base64.encode(this._auth.return_session_key)
      };
    }
    else {
      return null;
    }
  }

  getReturnSessionKey() {
    return this._auth.return_session_key;
  }

  private _generateUUID() {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  // this function can be removed when the 'looking for old invoxy' menu option is removed
  getUrlParams() {
    return Base64.encode(
      JSON.stringify({
        session_key: this._auth.session_key,
        company_key: this._auth.company_key,
        user_access_company_key: this._auth.user_access_company_key,
        return_session_key: this._auth.return_session_key
      }));
  }

  private _initUrls() {
    switch (this.env.product) {
      case 'KARMLY':
      case 'DROPPAH':
      case 'INVOXY':
        AuthService.subscription_app_url = this.env.subscription_app_url;
        break;
      case 'FLEXITIME':
        AuthService.subscription_app_url = this.env.subscription.app_url;
        break;
    }
  }

}
