import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EnvService, StateChangeService } from 'flexitime-library';

import { GridsterModule } from 'angular-gridster2';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { env, environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginModule } from './pages/login/login.module';
import { CoreModule } from './pages/core/core.module';
import { StateAccessService } from './services/state-access/state-access.service';
import { NavMenuService } from './services/nav-menu/nav-menu.service';
import { ModalService } from './services/modal/modal.service';
import { CompanyService } from './services/company/company.service';
import { NotificationService } from './services/notification/notification.service';
import { AccountService } from './services/account/account.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule.forRoot(
      [],
      {
        useHash: true,
        enableTracing: false, // <-- debugging purposes only
        relativeLinkResolution: 'legacy'
      }
    ),
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    GridsterModule,
    NgbModule,
    HttpClientModule,
    LoginModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [
    DatePipe,
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
    { provide: 'env', useValue: env },
    { provide: 'stateAccessService', useExisting: StateAccessService },
    { provide: 'navMenuService', useExisting: NavMenuService },
    { provide: 'modalService', useExisting: ModalService },
    { provide: 'companyService', useExisting: CompanyService },
    { provide: 'notificationService', useExisting: NotificationService },
    { provide: 'accountService', useExisting: AccountService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private stateChangeService: StateChangeService,
    private envService: EnvService
  ) {
    this.stateChangeService.initTransitionListeners();
    this.envService.init();
  }
}
