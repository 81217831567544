import { Component, HostBinding, HostListener, Input, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import {
  KmProjectTask,
  CoreUtilService,
  KmClock,
  ClockGroup,
  LibModalService
} from '../../../public-api';

import _ from 'lodash-es';
import { ClockServiceInterface } from '../../../lib/lib-interfaces/clock-service.interface';
import { ProjectTaskServiceInterface } from '../../../lib/lib-interfaces/project-task-service.interface';

@Component({
  selector: 'project-task-clock',
  templateUrl: './project-task-clock.component.html',
  styleUrls: ['./project-task-clock.component.scss']
})
export class ProjectTaskClockComponent implements OnInit, OnDestroy, OnChanges {

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent) {
    event.stopPropagation();
  }
  @HostBinding('class.-active-clock') is_active_clock: boolean = false;
  @HostBinding('class.-active-project-task') is_active_project_task: boolean = false;
  @HostBinding('class.-on-hold') is_on_hold: boolean = false;

  readonly project_label = CoreUtilService.project_label;

  active_clock = this.clockService.getActiveClock();

  @Input() project_task: KmProjectTask = null;
  @Input() group_clock_key: number = null;
  @Input() hide_timer: boolean = false;
  @Input() hide_button_tooltips: boolean = false;
  @Input() clockOutOverrideFunction: () => void;
  @Input() deleteActiveClockOverrideFunction: () => void = null;

  @Input() active_clock_editing_enabled: boolean = false;
  @Input() active_clock_stop_enabled: boolean = true;
  @Input() active_clock_delete_enabled: boolean = false;

  @Output() clock_modal_opened = new EventEmitter<void>();

  project_not_current: boolean = false;
  unit_based_project_task: boolean = false;

  clock_group: ClockGroup = null;

  project_tasks: KmProjectTask[] = [];

  event_subscriptions: Subscription[] = [];

  loading: boolean = false;

  constructor(
    @Inject('clockService') public clockService: ClockServiceInterface,
    @Inject('projectTaskService') public projectTaskService: ProjectTaskServiceInterface,
    public libModalService: LibModalService
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
    this._updateClockGroup();
    this._updateActiveOnHoldClockClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.project_task) {
      this.project_not_current = !!this.project_task && !this.project_task.project.isCurrent();

      this._updateClockGroup();
      this._updateActiveOnHoldClockClasses();
      this._checkForUnitBasedProjectTask();
    }
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  clockIn() {
    this.clockService.clockIn(this.project_task.project_task_key, null);
  }

  clockOut(event: MouseEvent) {
    event.stopPropagation();

    this.clockService.pauseTimer();

    if (!!this.clockOutOverrideFunction) {
      // TODO: Update this to expect a promise to be returned
      // means that we can restart the timer if clocking out fails
      this.clockOutOverrideFunction();
    }
    else if (!!this.active_clock.project_task) {
      // TODO: restart the timer if clocking out fails
      this.clockService.clockOut();
    }
    else {
      this.clock_modal_opened.emit();

      this.libModalService.clockModal()
        .catch(() => {
          this.clockService.restartTimer();
        });
    }
  }

  clockEdit(event: MouseEvent) {
    event.stopPropagation();

    if (!!this.active_clock) {
      this.clock_modal_opened.emit();

      this.libModalService.clockModal()
        .catch(() => { });
    }
  }

  deleteClock(event: MouseEvent) {
    event.stopPropagation();
    if (!!this.deleteActiveClockOverrideFunction) {
      this.deleteActiveClockOverrideFunction();
    }
    else {
      if (!!this.active_clock) {
        this.clockService.deleteActiveClock()
          .catch(() => { });
      }
    }
  }

  private _checkForUnitBasedProjectTask() {
    if (!!this.project_task) {
      this.unit_based_project_task = this.project_task.getUnitFlag();
    }
    else {
      this.unit_based_project_task = false;
    }
  }

  private _updateClockGroup() {
    this.clock_group = this.clockService.getClockGroup(this.group_clock_key);
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.clockService.getActiveClockUpdatedEvent().subscribe(() => {
        this.active_clock = this.clockService.getActiveClock();
        this._updateClockGroup();
        this._updateActiveOnHoldClockClasses();
      })
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private _updateActiveOnHoldClockClasses() {
    if (!!this.clock_group) {
      this.is_active_clock = !!this.clock_group.active_clock;
      this.is_on_hold = !this.clock_group.active_clock;
    }
    else {
      this.is_active_clock = false;
      this.is_on_hold = false;
    }
    this.is_active_project_task = this.project_task?.project_task_key === this.active_clock?.project_task?.project_task_key;

    if (!!this.project_task && !this.is_active_project_task) {
      const on_hold_project_task_keys = _.map(this.clockService.getOnHoldClocks(), (clock) => {
        return clock?.project_task?.project_task_key;
      });
      this.is_on_hold = on_hold_project_task_keys.indexOf(this.project_task.project_task_key) !== -1;
    }

  }

}
