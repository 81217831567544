import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';

import { IntegrationServiceAbstract } from '../../lib-classes/abstract-services/integration-service/integration-service.abstract';

@Component({
  selector: 'invoxy-integration-icon',
  templateUrl: './invoxy-integration-icon.component.html',
  styleUrls: ['./invoxy-integration-icon.component.scss']
})
export class InvoxyIntegrationIconComponent implements OnInit, OnDestroy {

  invoxy_integrated: boolean = false;
  tooltip_content: string = null;

  integration_events: Record<string, Subscription> = {};

  constructor(
    @Inject ('integrationService') public integrationService: IntegrationServiceAbstract
  ) { }

  ngOnInit(): void {
    this._refreshIntegrationStatus();
    this._initEventListener();
  }

  ngOnDestroy(): void {
    this.clearEventListeners();
  }

  private _refreshIntegrationStatus() {
    this.invoxy_integrated = this.integrationService.isIntegrated('INVOXY');
    this.tooltip_content = 'Imported from ' + (this.invoxy_integrated ? 'an' : 'a disconnected') + ' Invoxy Integration';
  }

  private _initEventListener() {
    this.integration_events.INTEGRATION_CHANGED = this.integrationService.subscribeToEvent('INTEGRATION_CHANGED', () => {
      this._refreshIntegrationStatus();
    });
  }

  clearEventListeners() {
    for (const event_type of Object.keys(this.integration_events)) {
      this.integration_events[event_type].unsubscribe();
    }
  }

}
