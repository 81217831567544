import { WeekDayShort, PriorityChoice } from '../../lib.types';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { DpCountry } from '../dp-country/dp-country';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';

export class DpCompany implements PostableObject<DpCompany> {

  company_key: number;
  company_name: string;
  company_product_key: number;
  admin_flag: boolean;
  sample_company: boolean;
  country: DpCountry;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  week_starts_on: WeekDayShort;
  max_stint: number;
  min_stint_break: number;
  stint_gap_tolerance: number;
  priority: PriorityChoice;
  hide_team_roster_flag: boolean;
  xero_payroll_calendar_id: string;

  requiresManagerApproval: boolean = false;
  roster_segment_cover_enabled: boolean = true;

  readonly days: { name: WeekDayShort, index: number }[] = [
    { name: 'sun', index: 1 },
    { name: 'mon', index: 2 },
    { name: 'tue', index: 3 },
    { name: 'wed', index: 4 },
    { name: 'thu', index: 5 },
    { name: 'fri', index: 6 },
    { name: 'sat', index: 7 }
  ];

  readonly priorities: { name: string, value: PriorityChoice }[] = [
    { name: 'None', value: 'NONE' },
    { name: 'Experience', value: 'EXPERIENCE' },
    { name: 'Cost', value: 'COST' },
    { name: 'Rating', value: 'RATING' }
  ]

  constructor(
    company_key: number,
    company_name: string,
    company_product_key: number,
    admin_flag: boolean,
    sample_company: boolean,
    country: DpCountry,
    contact_name: string,
    contact_email: string,
    contact_phone: string,
    week_starts_on: WeekDayShort,
    max_stint: number,
    min_stint_break: number,
    stint_gap_tolerance: number,
    priority: PriorityChoice,
    hide_team_roster_flag: boolean,
    xero_payroll_calendar_id: string,
    roster_segment_cover_enabled: boolean,
    requiresManagerApproval: boolean
  ) {

    this.company_key = company_key;
    this.company_name = company_name;
    this.company_product_key = company_product_key,
    this.admin_flag = admin_flag;
    this.sample_company = sample_company;
    this.country = country;
    this.contact_name = contact_name;
    this.contact_email = contact_email;
    this.contact_phone = contact_phone;
    this.week_starts_on = week_starts_on;
    this.max_stint = max_stint;
    this.min_stint_break = min_stint_break;
    this.stint_gap_tolerance = stint_gap_tolerance;
    this.priority = priority;
    this.hide_team_roster_flag = hide_team_roster_flag;
    this.roster_segment_cover_enabled = roster_segment_cover_enabled;
    this.requiresManagerApproval = requiresManagerApproval;
    this.xero_payroll_calendar_id = xero_payroll_calendar_id;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    // if (!this.company_name) {
    //   errors.push({
    //     error_path: 'company_name',
    //     error_message: 'Please enter a company name'
    //   });
    // }
    // if (!CoreUtilService.numberIsValid(this.max_stint) || this.max_stint < 0) {
    //   errors.push({
    //     error_path: 'max_stint',
    //     error_message: 'Please enter a valid maximum stint duration'
    //   });
    // }
    // if (!CoreUtilService.numberIsValid(this.min_stint_break) || this.min_stint_break < 0) {
    //   errors.push({
    //     error_path: 'min_stint_break',
    //     error_message: 'Please enter a valid minimum stint break duration'
    //   });
    // }
    // if (!CoreUtilService.numberIsValid(this.stint_gap_tolerance) || this.stint_gap_tolerance < 0) {
    //   errors.push({
    //     error_path: 'stint_gap_tolerance',
    //     error_message: 'Please enter a valid stint gap tolerance'
    //   });
    // }

    return errors;
  }

  formatForPosting(): any {
    if (!this.hasErrors()) {
      return {
        company_name: this.company_name,
        country_key: this.country.country_key,
        contact_name: this.contact_name,
        contact_email: this.contact_email,
        contact_phone: this.contact_phone,
        week_starts_on: this.week_starts_on,
        max_stint: this.max_stint,
        min_stint_break: this.min_stint_break,
        stint_gap_tolerance: this.stint_gap_tolerance,
        priority: this.priority,
        xero_payroll_calendar_id: this.xero_payroll_calendar_id
      };
    }
    return null;
  }
}
