import { DpPerson } from '../dp-person/dp-person';
import { PostableObject, PostableObjectErrorMap } from './../../lib-interfaces/postable-object.interface';

export class DpTeamPerson implements PostableObject<DpTeamPerson> {

  team_person_key: number;
  team_key: number;
  person: DpPerson;
  deleted_flag: boolean = false;

  constructor(
    team_person_key: number = null, team_key: number = null, person: DpPerson
  ) {
    this.team_person_key = team_person_key;
    this.team_key = team_key;
    this.person = person;
  }


  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    return errors;
  }

  formatForPosting(): any {
    if (this.hasErrors()) {
      // No need to post a new but deleted team person
      if (this.team_person_key === null && this.deleted_flag) {
        return null;
      }
      else {
        return {
          team_person_key: this.team_person_key,
          team_key: this.team_key,
          person_key: this.person.person_key,
          deleted_flag: this.deleted_flag
        };
      }
    }
    return null;
  }
}
