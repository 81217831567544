import { ClientPaymentTermRule } from '../../lib.types';
import { PostableObject, PostableObjectLockedFields, PostableObjectUtilService } from '../../lib-interfaces/postable-object.interface';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { KmProjectStub } from '../km-project-stub/km-project-stub';

export type KmClientContact = {
  user_key: number;
  display_name: string;
}

export class KmClient implements PostableObject<KmClient> {

  invoice_template_key: number;
  client_key: number;
  client_name: string;
  address: string;
  contact_phone: string;
  billing_email: string;
  billing_name: string;
  company_business_number: string;
  company_number: string;
  approval_message_template_key: number;
  payment_term_rule: ClientPaymentTermRule;
  payment_term_days: number;
  locked_flag: boolean;
  locked_description: string;
  contacts: KmClientContact[];
  projects: KmProjectStub[];

  constructor(
    invoice_template_key: number = null,
    client_key: number = null,
    client_name: string = '',
    address: string = '',
    contact_phone: string = '',
    billing_email: string = '',
    billing_name: string = '',
    company_business_number: string = '',
    company_number: string = '',
    approval_message_template_key: number = null,
    payment_term_rule: ClientPaymentTermRule = 'Invoice Due Date',
    payment_term_days: number = null,
    invoxy_locked_flag: boolean = false,
    coworker_locked_flag: boolean = false,
    contacts: KmClientContact[] = [],
    projects: KmProjectStub[] = []
  ) {
    this.invoice_template_key = invoice_template_key;
    this.client_key = client_key;
    this.client_name = client_name;
    this.address = address;
    this.contact_phone = contact_phone;
    this.billing_email = billing_email;
    this.billing_name = billing_name;
    this.company_business_number = company_business_number;
    this.company_number = company_number;
    this.approval_message_template_key = approval_message_template_key;
    this.payment_term_rule = payment_term_rule;
    this.payment_term_days = payment_term_days;
    this.projects = projects;

    this.locked_flag = invoxy_locked_flag || coworker_locked_flag;


    this.locked_description = CoreUtilService.set_locked_description(invoxy_locked_flag,coworker_locked_flag,'Client');



  }

  getEditingDisabled(): boolean {
    return this.locked_flag;
  }

  getLockedFields(): PostableObjectLockedFields<KmClient> {
    const editing_disabled = this.getEditingDisabled();
    let fields: PostableObjectLockedFields<KmClient> = {};

    if (editing_disabled) {
      fields = PostableObjectUtilService.lockAllFields(this);
    }

    return fields;
  }

  getErrors(): Record<string, string> {
    const errors = {};

    if (this.locked_flag) {
      errors['locked_flag'] = this.locked_description;
    }
    if (!this.client_name) {
      errors['client_name'] = 'Client name required';
    }
    if (!!this.billing_email && !CoreUtilService.emailIsValid(this.billing_email)) {
      errors['billing_email'] = 'Invalid email address';
    }

    if (!!this.contact_phone && this.contact_phone.length >= 20) {
      errors['contact_phone'] = 'Invalid contact phone';
    }

    if (!this.payment_term_rule) {
      errors['payment_term_rule'] = 'Payment Term required';
    }
    else if (this.payment_term_rule !== 'Invoice Due Date') {
      if (this.payment_term_rule === 'Day of current month' || this.payment_term_rule === 'Day of next month') {
        if (
          !CoreUtilService.numberIsValid(this.payment_term_days) ||
          this.payment_term_days < 1 ||
          this.payment_term_days > 31
        ) {
          errors['payment_term_rule'] = 'Valid day of month required (1 - 31)';
        }
      }
      else {
        if (!CoreUtilService.numberIsValid(this.payment_term_days)) {
          errors['payment_term_rule'] = 'Number of days required';
        }
      }
    }

    return errors;
  }

  hasErrors(): boolean {
    return PostableObjectUtilService.hasErrors(this);
  }

  formatForPosting(to_delete: boolean = false): any {
    if (!this.getErrors().has_errors) {

      return {
        client_key: this.client_key,
        client_name: this.client_name,
        address: this.address,
        contact_phone: this.contact_phone,
        billing_email: this.billing_email,
        billing_name: this.billing_name,
        company_business_number: this.company_business_number,
        company_number: this.company_number,
        invoice_template_key: this.invoice_template_key,
        approval_message_template_key: this.approval_message_template_key,
        payment_term_rule: this.payment_term_rule === 'Invoice Due Date' ? '' : this.payment_term_rule,
        payment_term_days: this.payment_term_rule === 'Invoice Due Date' ? null : this.payment_term_days,
        deleted_flag: to_delete
      };
    }
    return null;
  }

}
