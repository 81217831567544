import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomService } from '../../../public-api';

import { BannerComponent } from '../../lib-components/banner/banner.component';
import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';

@Component({
  selector: 'lib-file-uploader-modal',
  templateUrl: './file-uploader-modal.component.html',
  styleUrls: ['./file-uploader-modal.component.scss']
})
export class FileUploaderModalComponent implements AppModalInterface {

  @ViewChild(BannerComponent) banner: BannerComponent;

  @Input() file_url: string;
  @Input() file_type: string = 'document';
  @Input() modal_header: string = 'Add Document';

  @Output() close = new EventEmitter<{ file: File }>();
  @Output() dismiss = new EventEmitter<void>();

  new_file: File = null;

  constructor(
    public domService: DomService
  ){}

  cancel() {
    this.dismiss.emit();
  }

  updateImage(new_image: File) {
    this.new_file = new_image;
  }

  clearImage() {
    this.new_file = null;
  }

  imageError(error: string) {
    this.domService.openNotificationPopover('error', 'ERROR', true);
    this.banner.showBanner(error);
  }

  save() {
    this.close.emit({ file: this.new_file });
  }

}
