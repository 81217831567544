import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';

import {
  FeedbackService,
  ApiService,
  CompanyServiceInterface,
  AppModalInterface,
  NavMenuServiceAbstract
} from '../../../public-api';

@Component({
  selector: 'lib-feedback-provider-modal',
  templateUrl: './feedback-provider-modal.component.html',
  styleUrls: ['./feedback-provider-modal.component.scss']
})
export class FeedbackProviderModalComponent implements AppModalInterface {

  @Output() close = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<void>();

  feedback_comment: any;
  join_karmly_slack_link: string = this.navMenuService.join_karmly_slack_link;

  submitted: boolean = false;

  processed_state_change: boolean = false

  constructor(
    public feedbackService: FeedbackService,
    public apiService: ApiService,
    public router: Router,
    @Inject('companyService') public companyService: CompanyServiceInterface,
    @Inject('navMenuService') public navMenuService: NavMenuServiceAbstract
  ) { }

  cancel() {
    this.dismiss.emit();
  }

  submit() {
    const company = this.companyService.getCompany();
    const data = {
      company_product_key: company.sub_company_product_key,
      company_name: company.company_name,
      feedback_comment: this.feedback_comment,
      app_location: this.router.url
    };
    this.apiService.post('FLEXITIME', 'feedback/karmly', data);
    this.submitted = true;
  }

}
