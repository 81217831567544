
export type InviteStatus = ('PENDING' | 'ACCEPTED' | 'EXPIRED');

export class KmCoworker {

  coworker_key: number;
  coworker_name: string;
  email_address: string;
  user_image: string;
  current_time: Date;
  resume_link: string;
  invite_status: InviteStatus;
  recipient_flag: boolean;

  constructor(
    coworker_key: number = null,
    coworker_name: string = null,
    email_address: string = null,
    user_image: string = null,
    current_time: Date = null,
    resume_link: string = null,
    invite_status: InviteStatus = null,
    recipient_flag: boolean = false
  ) {
    this.coworker_key = coworker_key;
    this.coworker_name = coworker_name;
    this.email_address = email_address;
    this.user_image = user_image;
    this.current_time = current_time;
    this.resume_link = resume_link;
    this.invite_status = invite_status;
    this.recipient_flag = recipient_flag;
  }
}
