<div class="app-formItem-inputContainer">
  <div class="input-group">
    <div class="input-group-addon"
         *ngIf="input_prefix !== null">
      {{input_prefix}}
    </div>
    <input class="form-control"
           type="number"
           highlight-on-click
           [ngStyle]="{ 'font-weight': _value !== 0 && bold_non_zeros ? 'var(--app-font-weight-bold)' : '' }"
           [disabled]="disabled"
           [readonly]="readonly"
           [ngModel]="value"
           [ngModelOptions]="{ 'updateOn': 'blur'}"
           (ngModelChange)="parseModelFromView($event)">
    <div class="input-group-addon"
         *ngIf="input_suffix !== null">
      {{input_suffix}}
    </div>

    <tooltip-popover *ngIf="tooltip_content"
                     [tooltip_content]="tooltip_content">
    </tooltip-popover>
  </div>
  <div class="app-formItem-inputContainer-border">
  </div>
</div>
