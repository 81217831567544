import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { AuthService } from '../../lib-services/auth/auth.service';
import { StateAccessServiceInterface } from '../../lib-interfaces/state-access-service.interface';
import { StateChangeService } from '../../lib-services/state-change/state-change.service';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    @Inject('stateAccessService') public stateAccessService: StateAccessServiceInterface,
    public authService: AuthService,
    public stateChangeService: StateChangeService,
    public router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this._doCheck(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this._doCheck(childRoute, state);
  }

  private _doCheck(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.authService.hasSession()) {

        if (!this.stateAccessService.state_access_setup) {
          this._goToSplash(
            state.url,
            route.queryParams
          );
          resolve(false);
        }
        else {
          this.stateAccessService.ensureServicesInitialised()
            .then(() => resolve(true))
            .catch(() => {
              this._goToLoginExternal(
                state.url,
                route.queryParams
              );
              resolve(false);
            });
        }
      }
      else {
        this._goToLoginExternal(
          state.url,
          route.queryParams
        );
        resolve(false);
      }
    });
  }

  private _goToSplash(url: string, route_params: any) {
    route_params = !!route_params && Object.keys(route_params).length ? JSON.stringify(route_params) : null;

    this.router.navigate(['splash'], {
      queryParams: {
        route_path: this.stateChangeService.getUrlWithoutParams(url),
        route_params
      }
    });
  }

  private _goToLoginExternal(url: string, route_params: any) {
    route_params = !!route_params && Object.keys(route_params).length ? JSON.stringify(route_params) : null;

    this.router.navigate(['loginExternal'], {
      queryParams: {
        route_path: this.stateChangeService.getUrlWithoutParams(url),
        route_params
      }
    });
  }

}
