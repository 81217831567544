import { Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

import { StateDataService } from '../../lib-services/state-data/state-data.service';
import { NavMenuServiceAbstract } from '../../lib-classes/abstract-services/nav-menu-service/nav-menu-service.abstract';
import { animate, style, transition, trigger } from '@angular/animations';
import { CoreUtilService } from '../../../public-api';

export type NotificationPopoverType = (
  'INFO' | 'ERROR' | 'SUCCESS'
);
type NotificationPopoverTheme = (
  '-theme-info' | '-theme-error' | '-theme-success'
);
type Notification = {
  id: string,
  notification_message: string,
  notification_type: NotificationPopoverType,
  is_html: boolean,
  timeout: number
};

@Component({
  selector: 'notification-popover',
  templateUrl: './notification-popover.component.html',
  styleUrls: ['./notification-popover.component.scss'],
  animations: [
    trigger('animateOnDestroy', [
      transition(':leave', [
        animate('200ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class NotificationPopoverComponent implements OnInit, OnDestroy {

  @HostBinding('class.-navClosed') nav_menu_closed = false;

  @ViewChildren('notification') notification_elemements: QueryList<ElementRef>;

  readonly type_to_theme_map: Record<NotificationPopoverType, NotificationPopoverTheme> = {
    INFO: '-theme-info',
    ERROR: '-theme-error',
    SUCCESS: '-theme-success'
  };
  readonly max_notifications: number = 5;

  private _event_subscriptions: Subscription[] = [];

  protected _notifications: Notification[] = [];
  notification_bottoms: number[] = [];

  constructor(
    @Inject('navMenuService') public navMenuService: NavMenuServiceAbstract,
    public stateDataService: StateDataService,
    public domSanitizer: DomSanitizer,
    public elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.nav_menu_closed = this.stateDataService.getCachedComponentSessionData('AppNavMenuComponent', 'menu_closed');
    this.initEventListeners();
  }

  initEventListeners() {
    this._event_subscriptions.push(
      this.navMenuService.subscribeToEvent(
        'NAV_MENU_TOGGLED',
        () => this.nav_menu_closed = this.stateDataService.getCachedComponentSessionData('AppNavMenuComponent', 'menu_closed')
      )
    );
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  addNotification(
    notification_message: string,
    notification_type: NotificationPopoverType = 'INFO',
    is_html: boolean = false,
    timeout: number = null
  ) {
    notification_type = notification_type || 'INFO';

    if (!!notification_message && !!notification_type) {
      const notification = {
        id: CoreUtilService.generateUUID(),
        notification_message: is_html ? this.domSanitizer.sanitize(1, notification_message) : notification_message,
        notification_type,
        is_html,
        timeout
      };
      this._notifications.unshift(notification);
      this.notification_bottoms.unshift(window.innerHeight + 10);

      if (!!timeout) {
        setTimeout(() => this.removeNotification(notification.id), timeout);
      }

      if (this._notifications.length > this.max_notifications) {
        this.removeExcessNotifications();
      }
    }

    this._updateNotificationBottoms();
  }

  private _updateNotificationBottoms() {
    setTimeout(() => {
      this.notification_bottoms = [];
      let bottom = window.innerHeight;

      if (!!this.notification_elemements) {
        for (const notification_element of this.notification_elemements.toArray()) {
          bottom -= (10 + notification_element.nativeElement.offsetHeight);
          this.notification_bottoms.push(bottom);
        }
      }
    });
  }

  removeNotification(id: string){
    for (let i = this._notifications.length - 1; i >= 0; i--) {
      if (this._notifications[i].id === id){
        this._notifications.splice(i, 1);
      }
    }

    this._updateNotificationBottoms();
  }

  removeNotificationByIndex(index: number) {
    if (!!this._notifications.length){
      const id = this._notifications[this._notifications.length - 1].id;
      this.removeNotification(id);
    }
  }

  removeOldestNotification() {
    this.removeNotificationByIndex(this._notifications.length - 1);
  }

  removeExcessNotifications() {
    while (this._notifications.length > this.max_notifications) {
      this.removeOldestNotification();
    }
  }

  private _clearEventListeners() {
    this._event_subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
