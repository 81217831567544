<div class="cmg-header">
  <button class="-type-icon -color-white-outline"
          (click)="backYear()">
    <i class="ion ion-ios-arrow-back"></i>
  </button>
  <div>
    {{current_year | date : 'yyyy'}}
  </div>
  <button class="-type-icon -color-white-outline"
          (click)="forwardYear()">
    <i class="ion ion-ios-arrow-forward"></i>
  </button>
</div>

<div class="cmg-monthContainer">
  <div class="cmg-month"
       *ngFor="let month of months; let month_index = index"
       [ngClass]="{ '-selected': month_index === selected_month_index }"
       (click)="selectMonth(month.value)">

    <div class="-month-select"
         [ngClass]="{ '-selected': month_index === selected_month_index }">
      {{month.label}}
    </div>

  </div>
</div>
