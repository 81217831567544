import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';
import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';

@Component({
  selector: 'app-sent-time-error-modal',
  templateUrl: './sent-time-error-modal.component.html',
  styleUrls: ['./sent-time-error-modal.component.scss']
})
export class SentTimeErrorModalComponent implements OnInit, AppModalInterface {

  @Input() integration_name: string;
  @Input() segment_type: string;
  @Input() segments: any[];

  @Output() close = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<void>();

  successful_count: number = 0;
  failed_count: number = 0;

  error_users: {
    display_name: string,
    user_key: number,
    segments: any[]
  }[] = [];


  loading: boolean = true;

  constructor() { }

  ngOnInit(): void {
    if (!!this.segments.length) {
      this.setUpSegments();

      console.log(this.error_users);
    }
    else {
      this.cancel();
    }
  }

  setUpSegments() {
    const errorSegmentsMap = {};

    for (const segment of this.segments) {
      if (!!segment.error) {
        this.failed_count++;

        if (!!errorSegmentsMap[segment.user_key]) {
          errorSegmentsMap[segment.user_key].segments.push(segment);
        }
        else {
          errorSegmentsMap[segment.user_key] = {
            display_name: segment.display_name,
            user_key: segment.user_key,
            segments: [segment]
          };
        }
      }
    }

    this.successful_count = this.segments.length - this.failed_count;

    for (const item_key in errorSegmentsMap) {
      SortUtilService.sortList(errorSegmentsMap[item_key].segments,
        {
          primary_sort_property: 'segment_date',
          forward_order: false
        }
      );

      this.error_users.push({
        display_name: errorSegmentsMap[item_key].display_name,
        user_key: errorSegmentsMap[item_key].user_key,
        segments: errorSegmentsMap[item_key].segments
      });
    }

    this.loading = false;
  }

  cancel() {
    this.dismiss.emit();
  }

}
