<div class="table-headerRow"
     (click)="sort_rows.emit(column.column_key)">
  <i class="ion"
     [hidden]="row_sort.column_key !== column.column_key"
     [ngClass]="row_sort.reverse === true ? 'ion-ios-arrow-up':'ion-ios-arrow-down'">
  </i>

  <div class="table-headerRowName"
       #headerRowName>
    <span>{{column.field_config.label}}</span>
    <span *ngIf="column.column_function">
      ({{column.column_function}})
    </span>
  </div>
</div>

<div class="table-row"
     *ngFor="let value of formatted_column_values">
  {{value}}
</div>
