import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { StateDataService } from '../../lib-services/state-data/state-data.service';
import { DomService } from '../../lib-services/dom/dom.service';
import { WeekDayFull, WeekDayShort } from '../../lib.types';
import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';
import { BackdropComponent } from '../backdrop/backdrop.component';

type Day = { label: WeekDayShort, label_full: WeekDayFull, date_index: number };

@Component({
  selector: 'day-dropdown',
  templateUrl: './day-dropdown.component.html',
  styleUrls: ['./day-dropdown.component.scss'],
  providers: [MakeProvider(DayDropdownComponent)]
})
export class DayDropdownComponent extends AbstractValueAccessor implements OnInit {

  @HostBinding('class.-fullWidth') @Input() full_name: boolean = false;

  @ViewChild(NgbDropdown) ngb_dropdown: NgbDropdown;
  @ViewChild('dd_content') dd_content: ElementRef;

  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (DomService.is_mobile !== this.is_mobile) {
      this.is_mobile = DomService.is_mobile;
    }
  }

  backdrop_ref: BackdropComponent = null;

  days: Day[] = [
    { label: 'mon', label_full: 'Monday', date_index: 1 },
    { label: 'tue', label_full: 'Tuesday', date_index: 2 },
    { label: 'wed', label_full: 'Wednesday', date_index: 3 },
    { label: 'thu', label_full: 'Thursday', date_index: 4 },
    { label: 'fri', label_full: 'Friday', date_index: 5 },
    { label: 'sat', label_full: 'Saturday', date_index: 6 },
    { label: 'sun', label_full: 'Sunday', date_index: 0 }
  ];

  selected_day: Day = this.days[0];

  constructor(
    public stateDataService: StateDataService,
    public domService: DomService
  ) {
    super();
  }

  ngOnInit() {
    const week_start_index = this._getIndexOfDayInList(null, this.stateDataService.selected_week_start_day);
    this.days = this.days.slice(week_start_index).concat(this.days.slice(0, week_start_index));
  }

  writeValue(value: any) {
    if (TimeUtilService.dateIsValid(value)) {
      this._selectDayByDateIndex(value.getDay());
      super.writeValue(value);
    }
    else {
      super.writeValue(null);
    }
  }

  ngOnDestroy() {
    this.domService.closeOffCanvas();
    this.backdrop_ref?.close();
  }

  dropdownToggleClicked() {
    if (!this.disabled && !this.readonly) {
      if (this.is_mobile) {
        this.openMobileDropdown();
      }
      else {
        this.openDropdown();
      }
    }
  }

  openDropdown() {
    this.ngb_dropdown?.open();
  }

  openMobileDropdown() {
    this.domService.openMobileDropdown(this.dd_content, 'top');
  }

  dropdownToggled(is_open: boolean) {
    if (is_open && !this.is_mobile) {
      this.backdrop_ref = this.domService.openBackdrop();
    }
    else {
      this.backdrop_ref?.close();
    }
  }

  daySelected(day: Day) {
    if (!!this.selected_day) {
      const current_index = this._getIndexOfDayInList(this.selected_day.date_index);
      const new_index = this._getIndexOfDayInList(day.date_index);

      this.selected_day = day;
      this.value = TimeUtilService.incrementDate(this.value, new_index - current_index);
    }

    this.ngb_dropdown?.close();
    this.backdrop_ref?.close();
    this.domService.closeOffCanvas();
  }

  private _selectDayByDateIndex(date_index: number) {
    for (const day of this.days) {
      if (day.date_index === date_index) {
        this.selected_day = day;
      }
    }
  }

  private _getIndexOfDayInList(date_index: number = null, label: WeekDayShort = null) {
    for (let i = 0; i < this.days.length; i++) {
      if (
        this.days[i].date_index === date_index ||
        this.days[i].label === label
      ) {
        return i;
      }
    }
  }

}
