import { Injectable } from '@angular/core';

import { DpPerson } from '../../lib-models/dp-person/dp-person';
import { DpSkill } from '../../lib-models/dp-skill/dp-skill';
import { DpPersonAvailability } from '../../lib-models/dp-person-availability/dp-person-availability';

export type SkillPerson = {
  person: DpPerson,
  person_skill_key: number,
  deleted_flag: boolean
};

@Injectable({
  providedIn: 'root'
})
export class PersonUtilService {

  static personHasSkill(person: DpPerson, skill_key: number): boolean {
    for (const person_skill of person.skills) {
      if (person_skill.skill.skill_key === skill_key) {
        return true;
      }
    }

    return false;
  }

  static getPersonSkillIndex(person: DpPerson, skill_key: number): number {
    for (let i = 0; i < person.skills.length; i++) {
      if (person.skills[i].skill.skill_key === skill_key) {
        return i;
      }
    }

    return -1;
  }

  static personHasAnActiveSkill(person: DpPerson): boolean {
    for (const person_skill of person.skills) {
      if (!person_skill.skill.archived_flag) {
        return true;
      }
    }

    return false;
  }

  static getUnusedSkillsForPerson(person: DpPerson, allSkills: DpSkill[]): DpSkill[] {
    const unusedSkills = [];
    const usedSkills = {};

    for (const person_skill of person.skills) {
      if (!person_skill.deleted_flag) {
        usedSkills[person_skill.skill.skill_key] = true;
      }
    }

    for (const skill of allSkills) {
      if (!usedSkills[skill.skill_key]) {
        unusedSkills.push(skill);
      }
    }

    return unusedSkills;
  }

  static getUnusedPeopleForSkill(skill_key: number, allPeople: DpPerson[]): DpPerson[] {
    const unusedPeople = [];
    const usedPeople = {};

    for (const person of allPeople) {
      for (const person_skill of person.skills) {
        if (person_skill.skill.skill_key === skill_key) {
          usedPeople[person.person_key] = true;
          break;
        }
      }
    }

    for (const person of allPeople) {
      if (!usedPeople[person.person_key]) {
        unusedPeople.push(person);
      }
    }

    return unusedPeople;
  }

  static setupPersonAvailability(person_availability: any[]): DpPersonAvailability[] {
    const availability_map = {};

    if (person_availability) {
      for (const u of person_availability) {
        availability_map[u.weekday] = u;
      }
    }

    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const availability = [];

    for (const day of weekdays) {
      const u = availability_map[day] || null;

      if (u !== null) {
        availability.push(new DpPersonAvailability(
          u.weekday,
          u.person_availability_key,
          u.available_flag,
          u.start_time,
          u.end_time
        ));
      }
      else {
        availability.push(new DpPersonAvailability(day));
      }
    }

    return availability;
  }

  static getPeopleWithSkill(people: DpPerson[], skill_key: number): SkillPerson[] {
    const skill_people: SkillPerson[] = [];

    for (const person of people) {
      for (const person_skill of person.skills) {
        if (person_skill.skill.skill_key === skill_key) {
          skill_people.push({
            person,
            person_skill_key: person_skill.person_skill_key,
            deleted_flag: false
          });
          break;
        }
      }
    }

    return skill_people;
  }


}
