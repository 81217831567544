import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

@Pipe({
  name: 'minutesToTimePipe'
})
export class MinutesToTimePipe implements PipeTransform {

  transform(minutes: number) {
    return Duration.fromObject({ minutes }).toFormat('hh:mm');
  }

}
