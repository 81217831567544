import { Component, Inject, OnInit } from '@angular/core';

import {
  CoreUtilService
} from '../../lib-services/core-util/core-util.service';

@Component({
  selector: 'task-icon',
  templateUrl: './task-icon.component.html',
  styleUrls: ['./task-icon.component.scss']
})
export class TaskIconComponent implements OnInit {

  icon_classes: string = null;
  tooltip_content: string = null;

  constructor(
    @Inject('env') public env: any
  ) { }

  ngOnInit(): void {
    switch (this.env.product) {
      case 'KARMLY':
        this.icon_classes = 'bi-file-earmark-text';
        this.tooltip_content = CoreUtilService.task_label.capitalised;
        return;
      case 'INVOXY':
        this.icon_classes = 'bi-file-earmark-text';
        this.tooltip_content = CoreUtilService.task_label.capitalised;
        return;
    }
  }

}
