import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Player from '@vimeo/player';

import { DomService } from '../../lib-services/dom/dom.service';

@Component({
  selector: 'app-vimeo-player',
  templateUrl: './app-vimeo-player.component.html',
  styleUrls: ['./app-vimeo-player.component.scss']
})
export class AppVimeoPlayerComponent implements OnInit {

  @Input() video_url: string;

  @Output() video_played = new EventEmitter<void>();

  vimeo_player: Player;

  constructor(
    public elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    DomService.ensureVimeoApiLoaded();

    setTimeout(() => {
      this.vimeo_player = new Player(this.elementRef.nativeElement, {
        url: this.video_url
      });
      this.vimeo_player.on('play', () => {
        this.video_played.emit();
      });
    });
  }

}
