import _ from 'lodash-es';

import { TimeUtilService } from '../../../lib/lib-services/time-util/time-util.service';
import { CoreUtilService } from '../../../public-api';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { InvoiceLineTypeValue } from '../../lib.types';
import { KmClientStub } from '../km-client-stub/km-client-stub';
import { KmExpenseStub } from '../km-expense-stub/km-expense-stub';
import { KmSegmentStub } from '../km-segment-stub/km-segment-stub';
import { KmCoworker } from '../km-coworker/km-coworker';

export type KmInvoiceLine = {
  account_code: string;
  additional_data: any;
  deleted_flag: boolean;
  gross_amount: number;
  invoice_key: number;
  invoice_line_key: number;
  line_description: string;
  line_index: number;
  net_amount: number;
  quantity: number;
  rate: number;
  tax_amount: number;
  tax_rate: number;
  project_key: number;
  generated_flag: boolean;
  line_type: InvoiceLineTypeValue
};

export type KmInvoiceHistoryEvent = {
  created_date: Date,
  history_description: string,
  history_type: string,
  invoice_history_key: number
};

export class KmInvoice implements PostableObject<KmInvoice> {

  additional_data: any;
  address: string;
  billing_email: string;
  billing_name: string;
  client: KmClientStub;
  company_key: number;
  created_date: Date;
  currency_code: string;
  due_date: Date;
  recipient_emails: string[];
  excludes_unapproved_time: boolean;
  external_url: string;
  footer_text: string;
  from_date: Date;
  gross_amount: number;
  header_bottom_text: string;
  header_left_text: string;
  include_timesheets: boolean;
  invoice_batch_key: number;
  invoice_date: Date;
  invoice_key: number;
  invoice_number: string
  invoice_template_key: number;
  locked_for_financing: boolean;
  net_amount: number;
  notes: string;
  paid_date: Date;
  paid_flag: boolean;
  posted_flag: boolean;
  posted_date: Date;
  reference: string;
  sent_to_storecove: boolean;
  tax_amount: number;
  tax_type: string;
  template_type: string;
  to_date: Date;
  deleted_flag: boolean;
  coworker_locked_flag: boolean;
  payable_flag: boolean;
  coworker: KmCoworker;

  lines: KmInvoiceLine[];
  segments: KmSegmentStub[];
  expenses: KmExpenseStub[];
  history: KmInvoiceHistoryEvent[];

  constructor(
    additional_data: any = {},
    address: string,
    billing_email: string,
    billing_name: string,
    client: KmClientStub,
    company_key: number,
    created_date: Date,
    currency_code: string,
    due_date: Date,
    recipient_emails: string[],
    excludes_unapproved_time: boolean,
    external_url: string,
    footer_text: string,
    from_date: Date,
    gross_amount: number,
    header_bottom_text: string,
    header_left_text: string,
    include_timesheets: boolean,
    invoice_batch_key: number,
    invoice_date: Date,
    invoice_key: number,
    invoice_number: string,
    invoice_template_key: number,
    locked_for_financing: boolean,
    net_amount: number,
    notes: string,
    paid_date: Date,
    paid_flag: boolean,
    posted_flag: boolean,
    posted_date: Date,
    reference: string,
    sent_to_storecove: boolean,
    tax_amount: number,
    tax_type: string,
    template_type: string,
    to_date: Date,
    lines: KmInvoiceLine[],
    segments: KmSegmentStub[],
    expenses: KmExpenseStub[],
    history: KmInvoiceHistoryEvent[],
    coworker_locked_flag: boolean = false,
    payable_flag: boolean = false,
    deleted_flag: boolean = false,
    coworker: KmCoworker = null
  ) {
    this.additional_data = additional_data;
    this.address = address;
    this.billing_email = billing_email;
    this.billing_name = billing_name;
    this.client = client;
    this.company_key = company_key;
    this.created_date = created_date;
    this.currency_code = currency_code;
    this.due_date = due_date;
    this.recipient_emails = recipient_emails;
    this.excludes_unapproved_time = excludes_unapproved_time;
    this.external_url = external_url;
    this.footer_text = footer_text;
    this.from_date = from_date;
    this.gross_amount = gross_amount;
    this.header_bottom_text = header_bottom_text;
    this.header_left_text = header_left_text;
    this.include_timesheets = include_timesheets;
    this.invoice_batch_key = invoice_batch_key;
    this.invoice_date = invoice_date;
    this.invoice_key = invoice_key;
    this.invoice_number = invoice_number;
    this.invoice_template_key = invoice_template_key;
    this.locked_for_financing = locked_for_financing;
    this.net_amount = net_amount;
    this.notes = notes;
    this.paid_date = paid_date;
    this.paid_flag = paid_flag;
    this.posted_flag = posted_flag;
    this.posted_date = posted_date;
    this.reference = reference;
    this.sent_to_storecove = sent_to_storecove;
    this.tax_amount = tax_amount;
    this.tax_type = tax_type;
    this.template_type = template_type;
    this.to_date = to_date;
    this.lines = lines;
    this.segments = segments;
    this.expenses = expenses;
    this.history = history;
    this.deleted_flag = deleted_flag;
    this.coworker_locked_flag = coworker_locked_flag;
    this.payable_flag = payable_flag;
    this.coworker = coworker;
  }


  getErrors(email_required: boolean = true): PostableObjectErrorMap {
    const errors = {};

    if (!this.invoice_number) {
      errors['invoice_number'] = 'Invoice number required';
    }
    if (!this.invoice_date || !TimeUtilService.dateIsValid(this.invoice_date)) {
      errors['invoice_date'] = 'Valid invoice date required';
    }
    if (email_required && !this.recipient_emails.length) {
      errors['recipient_emails'] = 'Recipient email required';
    }

    for (const line of this.lines) {
      if (this.lineHasErrors(line)) {
        const line_errors = this.getLineErrors(line);

        for (const error_key of Object.keys(line_errors)) {
          errors['lines?line_index=' + line.line_index + '.' + error_key] = line_errors[error_key];
        }
      }
    }

    return errors;
  }

  hasErrors(email_required: boolean = true): boolean {
    return Object.keys(this.getErrors(email_required)).length > 0;
  }

  formatForPosting(to_delete: boolean, email_required: boolean = true): any {
    if (!this.hasErrors(email_required)) {
      const email_address = !email_required && this.recipient_emails.length === 0 ? '' : this.recipient_emails.join(';');
      const lines = this.formatLinesForPosting();
      const client_key = this.client.client_key;

      return {
        email_address,
        lines,
        client_key,
        deleted_flag: to_delete,
        additional_data: this.additional_data,
        address: this.address,
        billing_email: this.billing_email,
        billing_name: this.billing_name,
        due_date: TimeUtilService.dateToDateString(this.due_date),
        external_url: this.external_url,
        footer_text: this.footer_text,
        header_bottom_text: this.header_bottom_text,
        header_left_text: this.header_left_text,
        include_timesheets: this.include_timesheets,
        invoice_date: TimeUtilService.dateToDateString(this.invoice_date),
        invoice_key: this.invoice_key,
        invoice_number: this.invoice_number,
        invoice_template_key: this.invoice_template_key,
        posted_flag: this.posted_flag,
        paid_flag: this.paid_flag,
        notes: this.notes,
        reference: this.reference,
        net_amount: this.net_amount,
        tax_amount: this.tax_amount,
        gross_amount: this.gross_amount
      };
    }
    return;
  }

  formatLinesForPosting(): any[] {
    const formatted_lines = [];

    for (const line of this.lines) {
      formatted_lines.push({
        account_code: line.account_code,
        additional_data: line.additional_data,
        deleted_flag: line.deleted_flag,
        gross_amount: line.gross_amount,
        invoice_key: line.invoice_key,
        invoice_line_key: line.invoice_line_key,
        line_description: line.line_description,
        line_index: line.line_index + '',
        line_number: line.line_index + 1,
        net_amount: line.net_amount,
        quantity: line.quantity,
        rate: line.rate,
        tax_amount: line.tax_amount,
        tax_rate: line.tax_rate,
        project_key: line.project_key,
        line_type: line.line_type
      });
    }

    return formatted_lines;
  }

  getLineErrors(line: KmInvoiceLine): PostableObjectErrorMap {
    const errors = {};

    if (!line.deleted_flag) {
      if (!CoreUtilService.numberIsValid(line.quantity)) {
        errors['quantity'] = 'Quantity required';
      }
      if (!CoreUtilService.numberIsValid(line.rate)) {
        errors['rate'] = 'Rate required';
      }
    }

    return errors;
  }

  lineHasErrors(line: KmInvoiceLine): boolean {
    return Object.keys(this.getLineErrors(line)).length > 0;
  }


}
