import { Pipe, PipeTransform } from '@angular/core';

import { ReportService, ReportConfigDimension, ReportConfigMeasure } from '../../../public-api';

@Pipe({
  name: 'reportFieldValue'
})
export class ReportFieldValuePipe implements PipeTransform {

  constructor(
    public reportService: ReportService
  ) { }

  transform(
    value: any,
    field_config: ReportConfigDimension | ReportConfigMeasure,
    format_to_string: boolean = true,
    num_decimal_places: number = 2
  ): any {
    return this.reportService.formatFieldValue(value, field_config, format_to_string, num_decimal_places);
  }

}
