import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'tooltip-popover',
  templateUrl: './tooltip-popover.component.html',
  styleUrls: ['./tooltip-popover.component.scss']
})
export class TooltipPopoverComponent implements OnInit {

  @ViewChild('tooltip') tooltip: NgbTooltip;

  @Input() tooltip_content: string;
  //placement necessary only for verbose content on mobile
  @Input() placement: string = null;
  @Input() light_theme_flag: boolean = false;
  @Input() manual_trigger_flag: boolean = false;
  @Input() show_button_flag: boolean = false;
  @Input() workload_tooltips: boolean = false;
  @Input() animation_disabled: boolean = false;
  // Only used when manual_trigger_flag = true
  @Input() autoclose_delay: number = 8000;

  constructor() { }

  ngOnInit() {

  }

  open() {
    this.tooltip?.open();
  }

  close() {
    this.tooltip?.close();
  }

  tooltipOpened() {
    if (this.manual_trigger_flag && !this.workload_tooltips && !!this.autoclose_delay) {
      setTimeout(() => this.close(), this.autoclose_delay);
    }
  }

}
