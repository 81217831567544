import { AfterContentInit, Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash-es';

import { DashGrid } from '../../lib-classes/abstract/dash-grid/dash-grid';

import {
  LibModalService,
  ReportService,
  StateChangeService,
  ReportType,
  ReportDashBlockConfig,
  DomService,
  NavMenuServiceAbstract,
  DashGridBlock,
  CoreUtilService,
  ReportingAccount,
} from '../../../public-api';

import { GridsterComponent } from 'angular-gridster2';
import { Subscription } from 'rxjs';
import { ReportDashBlock } from './report-dash-block';
import { ReportDashBlockComponent } from './report-dash-block/report-dash-block.component';
import { EnvService } from '../../lib-services/env/env.service';

@Component({
  selector: 'report-dash',
  templateUrl: './report-dash.component.html',
  styleUrls: ['./report-dash.component.scss']
})
export class ReportDashComponent extends DashGrid implements OnInit, OnDestroy, AfterContentInit {

  readonly report_label = CoreUtilService.report_label;

  readonly columns: number = EnvService.PRODUCT === 'FLEXITIME' ? 18 : 12;

  readonly default_blocks: ReportDashBlockConfig[] = [];
  readonly block_size_defaults: Record<(ReportType), { rows: number, cols: number }> = {
    'TABLE': {
      rows: 5,
      cols: this.columns
    },
    'CHART': {
      rows: 5,
      cols: this.columns
    },
    'NUMERIC': {
      rows: 3,
      cols: 3
    }
  }

  @ViewChild(GridsterComponent) gridster_component: GridsterComponent;
  @ViewChildren('block_component') block_components: QueryList<ReportDashBlockComponent>;

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (DomService.is_mobile !== this.is_mobile) {
      this.is_mobile = DomService.is_mobile;
      this._fillEmptySpaces();
    }
  }

  @Input() block_config: ReportDashBlockConfig[] = [];
  @Input() query_params: any = null;
  @Input() project_key: number = null;
  @Input() selected_accounts: ReportingAccount[] = [];

  @Output() update_project_dashboard_config = new EventEmitter<ReportDashBlockConfig[]>();
  @Output() update_report_visability = new EventEmitter<number>();


  // Not used
  save_block_config = null;

  editing_enabled = true;
  empty_block_only_at_bottom_of_grid = false;

  blocks: ReportDashBlock[] = [];
  backup_block: ReportDashBlock = null;

  event_subscriptions: Subscription[] = [];

  constructor(
    @Inject('env') public env: any,
    @Inject('navMenuService') public navMenuService: NavMenuServiceAbstract,
    public libModalService: LibModalService,
    public router: Router,
    public stateChangeService: StateChangeService,
    public reportService: ReportService,
    public elementRef: ElementRef,
    public route: ActivatedRoute
  ) {
    super(elementRef);
  }

  ngOnInit(): void {
    this._initEventListeners();
    this._initDashGrid();
    this._initCustomGridOptions();
  }

  ngAfterContentInit(): void {
    this._addNewReportOnInit();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  ngDoCheck() {
    this.doCheck();
  }

  generateQueries() {
    this.block_components?.forEach((block) => {
      block.generateQuery();
    });
  }

  private _initCustomGridOptions() {
  }

  saveConfig() {
    super.saveConfig();
    const project_dashboard_config = this.filterBlocksForSaving() as ReportDashBlockConfig[];
    this.update_project_dashboard_config.emit(project_dashboard_config);
  }

  removeBlock(block: DashGridBlock) {
    super.removeBlock(block);
    this.update_report_visability.emit(block.report_key);
  }

  private _addNewReportOnInit() {
    // If query_params.new_report_key exists then we've likely come from the report-edit page
    // after creating a new report with the intention of adding it to this project summary
    if (!!this.query_params?.new_report_key) {
      const existing_report_block = _.find(this.blocks, { report_key: this.query_params.new_report_key }) || null;

      if (!existing_report_block) {
        const empty_block = _.find(this.blocks, {
          x: this.query_params.empty_block_x,
          y: this.query_params.empty_block_y,
          block_type: null
        }) || this.getLowestEmptyBlock();

        if (!!empty_block) {
          setTimeout(() => this._addReportBlock(empty_block, this.query_params.new_report_key));
          const query_params = new Set(['new_report_key', 'empty_block_x', 'empty_block_y']);
          this.stateChangeService.clearParamsFromCurrentRoute(query_params);
        }
      }
    }
  }

  setupDashBlocks() {
    const block_config = this.block_config || this.default_blocks;
    const dash_blocks = [];

    for (const bc of block_config) {
      if (this.reportService.getReport(bc.report_key)) {
        const block = new ReportDashBlock(
          bc.rows,
          bc.cols,
          bc.x,
          bc.y,
          bc.block_type,
          bc.report_key
        );

        dash_blocks.push(block);
      }
    }
    this.blocks = dash_blocks;
  }

  addBlock(empty_block: ReportDashBlock) {
    const existing_report_keys = this._getExistingReportKeys();

    this.libModalService.reportSelectorModal(!!this.project_key, existing_report_keys)
      .then((res) => {
        if (!!res.report) {
          this._addReportBlock(empty_block, res.report.report_key);
          this.update_report_visability.emit(res.report.report_key);
        }
        else {
          this.newReport(res.report_type, empty_block);
        }
      })
      .catch(() => { });
  }

  private _addReportBlock(empty_block: ReportDashBlock, report_key: number) {
    const report = this.reportService.getReport(report_key);

    if (!!report) {
      const block_size_defaults = this.block_size_defaults[report.report_type];
      let rows = empty_block.rows;
      let cols = empty_block.cols;

      const closest_below = this._getClosestBelowBlock(empty_block, this.blocks);

      if (
        block_size_defaults.cols < empty_block.cols
      ) {
        cols = block_size_defaults.cols;
      }
      if (
        block_size_defaults.rows < empty_block.rows ||
        !closest_below ||
        empty_block.y + block_size_defaults.rows < closest_below.y
      ) {
        rows = block_size_defaults.rows;
      }

      this.blocks.push(new ReportDashBlock(
        rows,
        cols,
        empty_block.x,
        empty_block.y,
        report.report_type,
        report_key
      ));

      this.saveConfig();
      this.updateMinRows();
      this._fillEmptySpaces();
    }
  }

  private _getExistingReportKeys(): Set<number> {
    const existing_report_keys: Set<number> = new Set();

    for (const block of this.blocks) {
      if (!!block.report_key) {
        existing_report_keys.add(block.report_key);
      }
    }

    return existing_report_keys;
  }

  newReport(report_type: ReportType, empty_block: ReportDashBlock) {
    const query_params: any = _.cloneDeep(this.route.snapshot.queryParams);
    query_params.empty_block_x = empty_block.x;
    query_params.empty_block_y = empty_block.y;

    const route = this.env === 'KARMLY' ? 'insight/edit' : 'report/edit';

    this.stateChangeService.go(route, {
      report_key: null,
      report_type,
      previous_route: this.router.url,
      previous_route_query_params: query_params
    });
  }

  addEmptyBlock(x: number, y: number) {
    const empty_block = new ReportDashBlock(
      2,
      2,
      x,
      y,
      null,
      null
    );
    this._updateBlockDimensionsAndPositionForNewBlock(empty_block);
    this.blocks.push(empty_block);
  }

  allBlocksActive() {
    return false;
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.navMenuService.subscribeToEvent(
        'NAV_MENU_TOGGLED',
        () => this.grid_options.api.resize()
      )
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}
