<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>Save Changes</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner -contentCentered">
      Do you want to save your changes?
    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterLeft">
      <button class="-color-success -mobile-only"
              (click)="saveChanges()">
        Yes
      </button>
    </div>
    <div class="app-modal-btnFooterRight">
      <button class="-color-success -desktop-only"
              (click)="saveChanges()">
        Yes
      </button>
      <button class="-color-white-outline"
              (click)="discardChanges()">
        No
      </button>
      <button class="-color-white-outline -desktop-only"
              (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
