import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  @Input() file: any = null;
  // Can be used to provide the url of an existing file
  @Input() file_url: string = null;

  @Input() file_type: string = 'document';
  @Input() disabled = false;
  @Input() custom_label = null;

  @Output() file_changed = new EventEmitter<{ file: File }>();
  @Output() file_cleared = new EventEmitter<void>();
  @Output() file_error = new EventEmitter<{ error: string }>();

  accept_input_types: string = '';
  file_type_starts_with_vowel: boolean;
  show_link: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (this.file_type === 'image') {
      this.accept_input_types = 'image/*';
    }
    else if (this.file_type === 'receipt') {
      this.accept_input_types = 'image/*,application/pdf';
      this.show_link = !!this.file_url;
    }
    this.file_type_starts_with_vowel = CoreUtilService.startsWithVowel(this.file_type);
  }

  fileChanged(file: File) {
    this.file = file;

    if (this.file_type === 'image') {
      CoreUtilService.generateUrlFromFile(file)
        .then((res) => this.file_url = res)
        .catch(() => this.file_url = null);
    }
    else if (this.file_type === 'receipt') {
      this.show_link = !!this.file_url;
    }

    this.file_changed.emit({ file: file });
  }

  fileCleared() {
    this.file = null;
    this.file_url = null;
    this.show_link = false;
    this.file_cleared.emit();
  }

}
