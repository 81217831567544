import _ from 'lodash-es';

import { CalendarEvent } from '../calendar-event';
import { PostableObject, PostableObjectErrorMap } from '../../../lib-interfaces/postable-object.interface';
import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';

export class KmCalendarEvent extends CalendarEvent implements PostableObject<KmCalendarEvent> {


  constructor(
    calendar_event_id: string,
    event_title: string,
    event_description: string,
    start_time: Date,
    end_time: Date,
    event_html_link: string,
    foreground_color: string = null,
    background_color: string = null,
    text_color: string = null
  ) {
    super(
      calendar_event_id,
      event_title,
      event_description,
      start_time,
      end_time,
      event_html_link,
      foreground_color,
      background_color,
      text_color
    );

  }

  get is_locked() {
    return true;
  }

  getErrors(): PostableObjectErrorMap {
    const errors = super.getErrors();

    if (!this.start_time) {
      errors['start_time'] = 'Start time required';
    }
    if (!this.end_time) {
      errors['end_time'] = 'End time required';
    }

    return errors;
  }

  formatForPosting(): any {
    if (!this.getErrors().has_errors) {
      const note_content = this.event_title + (this.event_description ? ' - ' + this.event_description : '');

      const data: any = {
        start_time: TimeUtilService.dateToDateTimeString(this.start_time),
        end_time: TimeUtilService.dateToDateTimeString(this.end_time),
        note_content: note_content
      };
      return data;
    }
    return null;
  }

}
