import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { PostableObject, PostableObjectErrorMap, PostableObjectLockedFields, PostableObjectUtilService } from '../../lib-interfaces/postable-object.interface';
import { TaskUnitOptionValue } from '../../lib.types';

export class KmTask implements PostableObject<KmTask> {

  task_key: number
  task_name: string;
  task_display_name: string;
  tax_rate: number;
  tax_type: string;
  has_time_flag: boolean;
  billable_flag: boolean;
  archived_flag: boolean = false;
  account_code: string;
  locked_flag: boolean;
  locked_description: string;
  deductible_percentage: number;
  nondeductible_flag: boolean;
  nondeductible_percentage: number; // if null = not selected
  nondeductible_account_code: string;
  mixed_use_flag: boolean;
  mixed_use_percentage: number;
  private _unit_flag: boolean;

  constructor(
    task_key: number = null,
    task_name: string = '',
    task_display_name: string = '',
    tax_rate: number = 0,
    tax_type: string = '',
    has_time_flag: boolean = false,
    billable_flag: boolean = false,
    archived_flag: boolean = false,
    account_code: string = null,
    invoxy_locked_flag: boolean = false,
    coworker_locked_flag: boolean = false,
    deductible_percentage: number = 100,
    nondeductible_percentage: number = 0,
    nondeductible_account_code: string = null,
    unit_flag: boolean = false,
    mixed_use_percentage: number = 0
  ) {
    this.task_key = task_key;
    this.task_name = task_name;
    this.task_display_name = task_display_name;
    this.tax_rate = tax_rate;
    this.tax_type = tax_type;
    this.has_time_flag = has_time_flag;
    this.billable_flag = billable_flag;
    this.archived_flag = archived_flag;
    this.account_code = account_code;
    this.deductible_percentage = deductible_percentage;
    this.nondeductible_percentage = nondeductible_percentage;
    this.nondeductible_account_code = nondeductible_account_code;
    this.unit_flag = unit_flag;
    this.mixed_use_percentage = mixed_use_percentage;

    this.nondeductible_flag = this.nondeductible_percentage > 0;
    this.mixed_use_flag = this.mixed_use_percentage > 0;

    this.locked_flag = invoxy_locked_flag || coworker_locked_flag;

    this.locked_description = CoreUtilService.set_locked_description(invoxy_locked_flag, coworker_locked_flag, 'Category');
  }

  set unit_flag(unit_flag: boolean) {
    this._unit_flag = unit_flag;
  }
  get unit_flag(): boolean {
    return this._unit_flag;
  }

  get unit_type(): ('expense') {
    return this._unit_flag ? 'expense' : null;
  }

  get task_type(): TaskUnitOptionValue {
    if (this._unit_flag) {
      return 'EXPENSE';
    }
    else if (this.billable_flag) {
      return 'INCOME';
    }
    else {
      return 'REPORTING';
    }
  }
  set task_type(type: TaskUnitOptionValue) {
    if (type === 'EXPENSE') {
      this.unit_flag = true;
    }
    else if (type === 'INCOME') {
      this.unit_flag = false;
      this.billable_flag = true;
    }
    else if (type === 'REPORTING') {
      this.unit_flag = false;
      this.billable_flag = false;
    }
  }

  getEditingDisabled(): boolean {
    return this.archived_flag || this.locked_flag;
  }

  getLockedFields(): PostableObjectLockedFields<KmTask> {
    const editing_disabled = this.getEditingDisabled();
    let fields: PostableObjectLockedFields<KmTask> = {};

    if (editing_disabled) {
      fields = PostableObjectUtilService.lockAllFields(this);
    }

    return fields;
  }

  getErrors(to_archive: boolean = false): PostableObjectErrorMap {
    const errors = {};

    if (this.locked_flag && !to_archive && !this.archived_flag) {
      errors['locked_flag'] = this.locked_description;
    }

    if (!this.task_name) {
      errors['task_name'] = CoreUtilService.task_label.capitalised + ' name required';
    }
    if (!this.task_display_name) {
      errors['task_display_name'] = CoreUtilService.task_label.capitalised + ' display name required';
    }

    if (this.task_type === 'EXPENSE') {
      if (
        this.billable_flag &&
        (!CoreUtilService.numberIsValid(this.tax_rate) || this.tax_rate < 0)
      ) {
        errors['tax_rate'] = 'Tax rate required';
      }

      if (
        this.mixed_use_flag &&
        (!CoreUtilService.numberIsValid(this.mixed_use_percentage) || this.mixed_use_percentage < 0 || this.mixed_use_percentage > 100)
      ) {
        errors['mixed_use_percentage'] = 'Percentage must be a valid number between 0 and 100';
      }

      if (
        this.nondeductible_flag &&
        (!CoreUtilService.numberIsValid(this.nondeductible_percentage) || this.nondeductible_percentage < 0 || this.nondeductible_percentage > 100)
      ) {
        errors['nondeductible_percentage'] = 'Percentage must be a valid number between 0 and 100';
      }
    }

    return errors;
  }

  hasErrors(): boolean {
    return PostableObjectUtilService.hasErrors(this);
  }

  formatForPosting(to_archive: boolean = false): any {
    if (this.task_type !== 'EXPENSE') {
      this.mixed_use_percentage = 0;
      this.nondeductible_percentage = 0;
      this.nondeductible_account_code = null;
    }

    if (!this.getErrors().has_errors) {
      return {
        task_key: this.task_key,
        task_name: this.task_name,
        task_display_name: this.task_display_name,
        tax_rate: this.tax_rate,
        tax_type: this.tax_type || '',
        billable_flag: this.billable_flag,
        archived_flag: to_archive,
        account_code: this.account_code,
        deductible_percentage: 100 - this.nondeductible_percentage,
        nondeductible_percentage: this.nondeductible_percentage,
        nondeductible_account_code: this.nondeductible_account_code,
        unit_type: this.unit_type,
        unit_flag: this.unit_flag,
        business_use_percentage: this.mixed_use_percentage
      };
    }
    return null;
  }
}
