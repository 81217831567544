import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LibModalService } from '../../lib-services/lib-modal/lib-modal.service';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';

export type UnsavedChangesDataPair = {
  currentData: () => any,
  originalData: () => any,
  props_to_ignore?: string[],
  data_sort_key?: string
}

export type UnsavedChangesData = {
  data_pairs?: UnsavedChangesDataPair[],
  function_checks?: (() => boolean)[],
  guard_disabled?: boolean,
  preComparisonProcessingFunction?: () => void,
  saveFunction: () => Promise<any>
}

export interface GuardedUnsavedChangesComponent {
  unsaved_changes_data: UnsavedChangesData;
}

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<GuardedUnsavedChangesComponent> {

  constructor(
    public libModalService: LibModalService
  ) { }

  canDeactivate(
    component: GuardedUnsavedChangesComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (component.unsaved_changes_data.guard_disabled) {
        return resolve(true);
      }
      else {
        // Set to true when debugging issues with unsaved changes
        const debug = false;

        let data_not_equal = false;

        // Useful for ensuring things like redactor editor
        // content is bound to model fields before change comparison
        if (!!component.unsaved_changes_data.preComparisonProcessingFunction) {
          component.unsaved_changes_data.preComparisonProcessingFunction();
        }

        const data_pairs = component.unsaved_changes_data.data_pairs || [];

        for (const pair of data_pairs) {
          const original_data = pair.originalData();
          const current_data = pair.currentData();

          // Data is an array so needs to be sorted first
          if (!!pair.data_sort_key) {
            if (!CoreUtilService.isLooselyEqual(
              SortUtilService.sortList(original_data, { primary_sort_property: pair.data_sort_key }),
              SortUtilService.sortList(current_data, { primary_sort_property: pair.data_sort_key }),
              pair.props_to_ignore || [],
              debug)
            ) {
              if (debug) {
                console.log(original_data);
                console.log(current_data);
              }
              data_not_equal = true;
              break;
            }
          }
          else {
            if (!CoreUtilService.isLooselyEqual(
              original_data,
              current_data,
              pair.props_to_ignore || [],
              debug)
            ) {
              if (debug) {
                console.log(original_data);
                console.log(current_data);
              }
              data_not_equal = true;
              break;
            }
          }
        }

        if (!data_not_equal) {
          const function_checks = component.unsaved_changes_data.function_checks || [];

          for (const func of function_checks) {
            if (!func()) {
              data_not_equal = true;
              break;
            }
          }
        }

        if (data_not_equal) {
          return this.libModalService.unsavedChangesModal()
            .then((result) => {
              switch (result) {
                case 'SAVE':

                  return component.unsaved_changes_data.saveFunction()
                    .then(() => {
                      resolve(true);
                    })
                    .catch(() => {
                      resolve(false);
                    });

                case 'DISCARD':
                  resolve(true);
                  break;

                case 'CANCEL':
                  resolve(false);
              }
            });
        }
        else {
          resolve(true);
        }
      }
    });
  }

}
