import { PostableObject, PostableObjectErrorMap, PostableObjectUtilService, PostableObjectLockedFields } from '../../lib-interfaces/postable-object.interface';
import { KmProjectStub } from '../km-project-stub/km-project-stub';
import { KmTaskStub } from '../km-task-stub/km-task-stub';
import { Label, ProjectTaskBillRateType, ProjectTaskRepeatFrequency, WeekDayFull } from '../../lib.types';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { DateTime } from 'luxon';
import { KmCoworker } from '../km-coworker/km-coworker';

import _ from 'lodash-es';

export type KmProjectTaskPostableObjectField = (
  'apply_to_future_tasks' |
  'deleted_flag' |
  'project_key' |
  'task_key' |
  'project_task_key' |
  'project_task_name' |
  'project_task_description' |
  'estimated_duration' |
  'total_duration' |
  'completed_date' |
  'labels' |
  'coworker_keys' |
  'bill_type' |
  'bill_rate' |
  'fixed_fee_invoiced_flag' |
  'repeat_frequency' |
  'recurrence_rule_key' |
  'day_of_week' |
  'day_of_month' |
  'month_number'
);

export type ProjectTaskCoworker = {
  project_task_coworker_key: number,
  coworker_key: number,
  coworker: KmCoworker
}

export type ProjectTaskCoworkerSibling = {
  display_name: string,
  email_address: string,
  display_image: string
}

type ProjectTaskRepeatFrequencyData = {
  day_of_week: WeekDayFull,
  day_of_month: number,
  month_number: number
};

export class KmProjectTask implements PostableObject<KmProjectTask> {

  private _project: KmProjectStub;
  private _task: KmTaskStub;
  project_task_key: number;
  project_task_name: string;
  project_task_description: string;
  estimated_duration: number;
  total_duration: number;
  completed_date: Date;
  labels: Label[];
  deleted_flag: boolean;
  coworker_locked_flag: boolean;
  locked_description: string;
  project_task_coworkers: ProjectTaskCoworker[];
  project_task_coworker_siblings: ProjectTaskCoworkerSibling[];
  has_coworker_time_flag: boolean;
  feed_viewed_date: Date;
  coworker_active_task_flag: boolean;
  bill_type: ProjectTaskBillRateType;
  private _bill_rate: number;
  fixed_fee_invoiced_flag: boolean;
  private _repeat_frequency: ProjectTaskRepeatFrequency;
  recurrence_rule_key: number;
  recurring_template_flag: boolean;

  private _next_repeat_date: Date = null;

  private _ordinal_repeat_flag: boolean;

  completed_date_label: string;

  constructor(
    project: KmProjectStub,
    task: KmTaskStub = null,
    project_task_key: number = null,
    project_task_name: string = '',
    project_task_description: string = '',
    estimated_duration: number = 0,
    total_duration: number = 0,
    completed_date: Date = null,
    labels: Label[] = [],
    deleted_flag: boolean = false,
    invoxy_locked_flag: boolean = false,
    coworker_locked_flag: boolean = false,
    project_task_coworkers: ProjectTaskCoworker[] = [],
    project_task_coworker_siblings: ProjectTaskCoworkerSibling[] = [],
    has_coworker_time_flag: boolean = false,
    feed_viewed_date: Date = null,
    coworker_active_task_flag: boolean = false,
    bill_type: ProjectTaskBillRateType = 'PER_HOUR',
    bill_rate: number = null,
    fixed_fee_invoiced_flag: boolean = null,
    repeat_frequency: ProjectTaskRepeatFrequency = null,
    day_of_week: WeekDayFull = null,
    day_of_month: number = null,
    month_number: number = null,
    recurrence_rule_key: number = null,
    recurring_template_flag: boolean = false
  ) {
    this._project = project;
    this._task = task;
    this.project_task_key = project_task_key;
    this.project_task_name = project_task_name;
    this.project_task_description = project_task_description;
    this.estimated_duration = estimated_duration;
    this.total_duration = total_duration;
    this.completed_date = completed_date;
    this.labels = labels;
    this.deleted_flag = deleted_flag;
    this.project_task_coworkers = project_task_coworkers;
    this.project_task_coworker_siblings = project_task_coworker_siblings;
    this.has_coworker_time_flag = has_coworker_time_flag;
    this.feed_viewed_date = feed_viewed_date;
    this.coworker_active_task_flag = coworker_active_task_flag;
    this.bill_type = bill_type;
    this._bill_rate = !!project_task_key ? bill_rate : (project?.project_rate || 0);
    this.fixed_fee_invoiced_flag = fixed_fee_invoiced_flag;

    this._repeat_frequency = repeat_frequency;
    this.recurrence_rule_key = recurrence_rule_key;
    this.recurring_template_flag = recurring_template_flag;

    this.coworker_locked_flag = false || coworker_locked_flag;

    this.locked_description = CoreUtilService.set_locked_description(false, coworker_locked_flag, CoreUtilService.project_task_label.capitalised);

    this._ordinal_repeat_flag = !this.project_task_key ? true : this.repeat_frequency === 'MONTHLY' && day_of_week !== null;

    this._initNextRepeatDate(day_of_week, day_of_month, month_number);
    this.updateCompletedDateLabel();
  }

  get task(): KmTaskStub {
    return this._task;
  }
  set task(task: KmTaskStub) {
    this._task = task;

    if (this._task.task_type.value === 'REPORTING') {
      this.bill_rate = 0;
      this.repeat_frequency = null;
    }
  }

  get project(): KmProjectStub {
    return this._project;
  }
  set project(project: KmProjectStub) {
    this._project = project;

    if (!this._task || this._task?.task_type.value === 'INCOME') {
      this.bill_rate = project?.project_rate || 0;
    }
  }

  get bill_rate(): number {
    return this._bill_rate;
  }
  set bill_rate(value: number) {
    if (CoreUtilService.numberIsValid(value) && value >= 0) {
      this._bill_rate = value;
    }
  }

  get repeat_frequency(): ProjectTaskRepeatFrequency {
    return this._repeat_frequency;
  }
  set repeat_frequency(repeat_frequency: ProjectTaskRepeatFrequency) {
    if (repeat_frequency !== this._repeat_frequency) {
      this._repeat_frequency = repeat_frequency;
      this._repeatFrequencyUpdated();
    }
  }

  private _initNextRepeatDate(day_of_week: WeekDayFull, day_of_month: number, month_number: number) {
    const today = DateTime.now().startOf('day');
    let next_repeat_date: DateTime = null;

    switch (this._repeat_frequency) {
      case 'DAILY':
        next_repeat_date = null;
        break;
      case 'WEEKLY':
        next_repeat_date = this.getNextDateForDay(day_of_week);
        break;
      case 'MONTHLY':
        if (this.ordinal_repeat_flag) {
          next_repeat_date = this.getNextDateForDay(day_of_week);
        }
        else {
          next_repeat_date = today
            .plus({ months: 1 })
            .startOf('month')
            .set({ day: day_of_month });
        }
        break;
      case 'YEARLY':
        next_repeat_date = today
          .set({ day: day_of_month })
          .set({ month: month_number });

        if (next_repeat_date <= today) {
          next_repeat_date = next_repeat_date.plus({ years: 1 });
        }
        break;
      default:
        next_repeat_date = null;
    }

    this.next_repeat_date = next_repeat_date?.toJSDate() || null;
  }

  private _repeatFrequencyUpdated() {
    const today = DateTime.now().startOf('day');
    let next_repeat_date: DateTime = null;

    switch (this._repeat_frequency) {
      case 'DAILY':
        next_repeat_date = null;
        break;
      case 'WEEKLY':
        next_repeat_date = this.getNextDateForDay(today.toFormat('EEEE') as WeekDayFull);
        break;
      case 'MONTHLY':
        if (this.ordinal_repeat_flag) {
          next_repeat_date = this.getNextDateForDay(today.toFormat('EEEE') as WeekDayFull);
        }
        else {
          next_repeat_date = today
            .plus({ months: 1 })
            .startOf('month')
            .set({ day: today.get('day') });
        }
        break;
      case 'YEARLY':
        next_repeat_date = today.plus({ years: 1 });
        break;
      default:
        next_repeat_date = null;
    }

    this.next_repeat_date = next_repeat_date?.toJSDate() || null;
  }

  getNextDateForDay(day: WeekDayFull): DateTime {
    let date = DateTime.now().startOf('day');

    if (this.repeat_frequency === 'WEEKLY') {
      // Tomorrow
      date = date.plus({ days: 1 });
    }
    else if (this.repeat_frequency === 'MONTHLY') {
      // First day of next month
      date = date.plus({ months: 1 }).startOf('month');
    }

    for (let i = 0; i < 7; i++) {
      if (date.toFormat('EEEE') === day) {
        break;
      }
      date = date.plus({ days: 1 });
    }
    return date;
  }

  get next_repeat_date(): Date {
    return this._next_repeat_date;
  }
  set next_repeat_date(next_repeat_date: Date) {
    if (
      this._next_repeat_date !== next_repeat_date &&
      (next_repeat_date === null || CoreUtilService.dateIsValid(next_repeat_date))
    ) {
      this._next_repeat_date = next_repeat_date;
    }
  }

  get ordinal_repeat_flag(): boolean {
    return this._ordinal_repeat_flag;
  }
  set ordinal_repeat_flag(input: boolean) {
    this._ordinal_repeat_flag = input;
    this._repeatFrequencyUpdated();
  }

  addCoworker(project_task_coworker: ProjectTaskCoworker): void {
    if (
      !!project_task_coworker &&
      !_.find(this.project_task_coworkers, (cw) => cw.coworker_key === project_task_coworker.coworker_key)
    ) {
      this.project_task_coworkers.push(
        project_task_coworker);
    }
  }

  removeCoworker(coworker_key: number): void {
    _.remove(this.project_task_coworkers, (ptcw) => ptcw.coworker_key === coworker_key);
  }

  updateCompletedDateLabel() {
    if (!!this.completed_date) {
      if (TimeUtilService.isToday(this.completed_date)) {
        this.completed_date_label = DateTime.fromJSDate(this.completed_date).toFormat('h:mm a,') + ' Today';
      }
      else {
        this.completed_date_label = DateTime.fromJSDate(this.completed_date).toFormat('d MMM yyyy, h:mm a');
      }
    }
    else {
      this.completed_date_label = null;
    }
  }

  getEditingDisabled(): boolean {
    return !!this.completed_date;
  }

  getLockedFields(): PostableObjectLockedFields<KmProjectTask> {
    const editing_disabled = this.getEditingDisabled();
    let fields: PostableObjectLockedFields<KmProjectTask> = {};

    if (editing_disabled) {
      fields = PostableObjectUtilService.lockAllFields(this);
    }
    else if (this.coworker_locked_flag) {
      fields = PostableObjectUtilService.lockFields(['estimated_duration', 'project', 'project_task_name', 'task']);
    }

    return fields;
  }

  getUnitType(): string {
    return this.project.rate_type.toLowerCase();
  }

  getUnitFlag(): boolean {
    return this.project.rate_type === 'days';
  }

  projectIsCurrent(date: Date = new Date()): boolean {
    return this.project.isCurrent(date);
  }

  private _calculateRepeatFrequencyDataForPosting(): ProjectTaskRepeatFrequencyData {
    const data: ProjectTaskRepeatFrequencyData = {
      day_of_week: null,
      day_of_month: null,
      month_number: null
    };

    const next_repeat_date = DateTime.fromJSDate(this.next_repeat_date);

    switch (this._repeat_frequency) {
      case 'DAILY':
        break;
      case 'WEEKLY':
        data.day_of_week = next_repeat_date.toFormat('EEEE') as WeekDayFull;
        break;
      case 'MONTHLY':
        if (this.ordinal_repeat_flag) {
          data.day_of_week = next_repeat_date.toFormat('EEEE') as WeekDayFull;
        }
        else {
          data.day_of_month = next_repeat_date.get('day');
        }
        break;
      case 'YEARLY':
        data.day_of_month = next_repeat_date.get('day');
        data.month_number = next_repeat_date.get('month');
        break;
    }

    return data;
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (!this.project_task_name) {
      errors['project_task_name'] = CoreUtilService.project_task_label.capitalised + ' Name required';
    }
    if (!this.project) {
      errors['project'] = CoreUtilService.project_label.capitalised + ' required';
    }
    if (!this.task) {
      errors['task'] = CoreUtilService.task_label.capitalised + ' required';
    }
    if (!!this.estimated_duration && (!CoreUtilService.numberIsValid(this.estimated_duration) || !CoreUtilService.numberIsInt(this.estimated_duration))) {
      errors['duration'] = 'Effort must be a whole number';
    }
    else if (!!this.estimated_duration && this.estimated_duration < 0) {
      errors['duration'] = 'Effort must be a positive number';
    }
    if (this.bill_type === 'PER_HOUR' && (!CoreUtilService.numberIsValid(this.bill_rate) || this.bill_rate < 0)) {
      errors['bill_rate'] = 'Valid Bill Rate required';
    }

    if (this.repeat_frequency === 'MONTHLY' && !this.ordinal_repeat_flag && !this.next_repeat_date) {
      errors['repeat_date'] = 'Date required for Monthly frequency';
    }
    else if (this.repeat_frequency === 'WEEKLY' && !this.next_repeat_date) {
      errors['repeat_every'] = 'Day required for Weekly frequency';
    }

    return errors;
  }

  hasErrors(): boolean {
    return PostableObjectUtilService.hasErrors(this);
  }

  formatFieldsForPosting(fields: KmProjectTaskPostableObjectField[], apply_to_future_tasks: boolean = false) {
    const postable_data = this.formatForPosting(apply_to_future_tasks);

    if (!!postable_data) {
      for (const key of Object.keys(postable_data) as KmProjectTaskPostableObjectField[]) {
        // Delete keys that aren't included in fields argument
        if (
          key !== 'project_task_key' &&
          key !== 'apply_to_future_tasks' &&
          fields.indexOf(key) === -1
        ) {
          delete postable_data[key];
        }
      }

      return postable_data;
    }
    return null;
  }

  formatForPosting(apply_to_future_tasks: boolean = false): Record<KmProjectTaskPostableObjectField, any> {
    if ((this.deleted_flag && !this.project_task_key) || this.hasErrors()) {
      return null;
    }
    else {
      const frequency_data = this._calculateRepeatFrequencyDataForPosting();

      return {
        apply_to_future_tasks: apply_to_future_tasks || false,
        deleted_flag: this.deleted_flag,
        project_key: this.project.project_key,
        task_key: this.task?.task_key || null,
        project_task_key: this.project_task_key,
        project_task_name: this.project_task_name,
        project_task_description: this.project_task_description,
        estimated_duration: this?.estimated_duration || 0,
        total_duration: this?.total_duration || 0,
        completed_date: !!this.completed_date ? TimeUtilService.dateToDateTimeString(this.completed_date) : '',
        labels: JSON.stringify(this.labels || []),
        coworker_keys: this.project_task_coworkers.map((cw) => cw.coworker_key),
        bill_type: this.bill_type,
        bill_rate: this.task.task_type.value === 'INCOME' ? this.bill_rate : 0,
        fixed_fee_invoiced_flag: this.fixed_fee_invoiced_flag,
        repeat_frequency: this.repeat_frequency === null ? '' : this.repeat_frequency,
        recurrence_rule_key: this.recurrence_rule_key || null,
        day_of_week: frequency_data.day_of_week === null ? '' : frequency_data.day_of_week,
        day_of_month: frequency_data.day_of_month === null ? -1 : frequency_data.day_of_month,
        month_number: frequency_data.month_number === null ? -1 : frequency_data.month_number
      };
    }
  }

}
