<div class="input-group">
  <div class="input-group-addon"
       *ngIf="!hide_search_icon">
    <i class="ion ion-ios-search"></i>
  </div>

  <input type="search"
         autocomplete="off"
         #search_input
         [maxLength]="max_length"
         [placeholder]="placeholder"
         [ngModel]="_search"
         (ngModelChange)="updateSearch($event)"
         (keyup)="keyup($event)">

  <div class="input-group-addon -clearSearch"
       [class.-hidden]="!_search">
    <i class="ion ion-md-close app-remove"
       (click)="clearSearch($event)">
    </i>
  </div>
</div>

<div class="app-formItem-inputContainer-border"
     *ngIf="show_input_container">
</div>
