import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef, NgbOffcanvas, NgbOffcanvasOptions, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import _ from 'lodash-es';

import { DashGridBlockThumbnail, ReportType } from '../../lib.types';
import { Report, ReportFilter } from '../../lib-models/report/report';
import { ModalWrapperComponent, OffcanvasWrapperComponent, KmInvoice, KmInvoiceLine } from '../../../public-api';
import { DomService } from '../dom/dom.service';
import { DashGridBlock } from '../../lib-classes/abstract/dash-grid/dash-grid-block';
import { ExpandingCanvasWrapperComponent, ExpandingCanvasOptions } from '../../../public-api';

import { ErrorModalComponent } from '../../lib-modals/error-modal/error-modal.component';
import { UnsavedChangesModalComponent } from '../../lib-modals/unsaved-changes-modal/unsaved-changes-modal.component';
import { DashGridBlockModalComponent } from '../../lib-modals/dash-grid-block-modal/dash-grid-block-modal.component';
import { ConfirmModalComponent } from '../../lib-modals/confirm-modal/confirm-modal.component';
import { SuccessModalComponent } from '../../lib-modals/success-modal/success-modal.component';
import { ReportFilterModalComponent } from '../../lib-modals/report-filter-modal/report-filter-modal.component';
import { ArchiveProjectModalComponent } from '../../lib-modals/archive-project-modal/archive-project-modal.component';
import { UpdateSegmentRateModalComponent } from '../../lib-modals/update-segment-rate-modal/update-segment-rate-modal.component';
import { ReportSelectorModalComponent } from '../../lib-modals/report-selector-modal/report-selector-modal.component';
import { FileUploaderModalComponent, KmClock, KmSegment } from '../../../public-api';
import { KmClockModalComponent } from '../../lib-modals/km-clock-modal/km-clock-modal.component';
import { ReportSettingModalComponent } from '../../../public-api';
import { FeedbackProviderModalComponent } from '../../lib-modals/feedback-provider-modal/feedback-provider-modal.component';
import { InvoiceLineEditorModalComponent } from '../../lib-modals/invoice-line-editor-modal/invoice-line-editor-modal.component';
import { SentTimeErrorModalComponent } from '../../lib-modals/sent-time-error-modal/sent-time-error-modal.component';

export type ModalOptions = {
  size?: 'sm' | 'lg' | 'xl' | string,
  position?: 'start' | 'end' | 'top' | 'bottom',
  centered?: boolean,
  component_class?: string,
  backdrop_class?: string,
  dismiss_open_modals?: boolean
};

@Injectable({
  providedIn: 'root'
})
export class LibModalService {

  readonly DEFAULT_NGB_MODAL_Z_INDEX = 1051;
  current_ngb_modal_z_index = this.DEFAULT_NGB_MODAL_Z_INDEX;

  active_modal_instances: NgbModalRef[] = [];

  constructor(
    @Inject('env') public env: any,
    public ngb_modal: NgbModal,
    public ngb_offcanvas: NgbOffcanvas,
    public datePipe: DatePipe,
    public router: Router,
    public domService: DomService
  ) {
    this._ensureOnlySingleErrorModalOpen();
    this._closeModalsOnRouteChange();

    this.ngb_modal.activeInstances.subscribe((instances) => {
      this.active_modal_instances = instances;
    });
  }

  invoiceLineEditorModal(
    invoice: KmInvoice,
    line_index: number
  ): Promise<{ line: KmInvoiceLine, deleted_flag: boolean }> {
    return new Promise((resolve, reject) => {

      const component_properties = {
        invoice: _.cloneDeep(invoice),
        line_index
      };

      this._open(
        InvoiceLineEditorModalComponent,
        component_properties,
        { size: 'sm' }
      )
        .then((res) => resolve(res))
        .catch(() => reject());
    });
  }

  reportSettingModal(
    report: Report
  ): Promise<void> {
    return new Promise((resolve, reject) => {

      const component_properties = {
        report
      };

      this._open(
        ReportSettingModalComponent,
        component_properties,
        { size: 'sm', component_class: '-fullHeight' }
      )
        .then(() => resolve())
        .catch(() => reject());
    });
  }

  fileUploaderModal(
    file_url: string,
    file_type: string = 'document',
    modal_header: string = 'Add Document'
  ): Promise<{ file: File }> {
    return new Promise((resolve, reject) => {

      const component_properties = {
        file_url,
        file_type,
        modal_header
      };

      this._open(
        FileUploaderModalComponent,
        component_properties
      )
        .then((res) => !!res ? resolve(res) : reject())
        .catch(() => reject());
    });
  }

  reportSelectorModal(
    project_report_filter_flag: boolean = false,
    excluded_report_keys: Set<number> = new Set()
  ): Promise<{ report: Report, report_type: ReportType }> {
    return new Promise((resolve, reject) => {

      const component_properties = {
        project_report_filter_flag,
        excluded_report_keys
      };

      this._open(
        ReportSelectorModalComponent,
        component_properties,
        { size: 'xl' }
      )
        .then((res) => !!res ? resolve(res) : reject())
        .catch(() => reject());
    });
  }

  archiveProjectModal(
    project_name: string
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const component_properties = {
        project_name
      };

      this._open(
        ArchiveProjectModalComponent,
        component_properties,
        { size: 'lg' }
      )
        .then((res) => !!res ? resolve(res) : reject())
        .catch(() => reject());
    });
  }

  reportFilterModal(
    report: Report,
    filter: ReportFilter = null
  ): Promise<ReportFilter> {
    return new Promise((resolve, reject) => {

      const component_properties = {
        report,
        filter: _.cloneDeep(filter)
      };

      this._open(
        ReportFilterModalComponent,
        component_properties,
        { size: 'sm', component_class: '-fullHeight' }
      )
        .then((res) => !!res ? resolve(res) : reject())
        .catch(() => reject());
    });
  }

  confirmModal(
    title: string,
    message: string,
    button_title: string = 'Confirm',
    button_colour_class: string = '-color-green',
    message_is_html: boolean = false
  ): Promise<void> {
    return new Promise((resolve, reject) => {

      const component_properties = {
        title,
        message,
        button_title,
        button_colour_class,
        message_is_html
      };

      this._open(
        ConfirmModalComponent,
        component_properties
      )
        .then((confirm) => !!confirm ? resolve() : reject())
        .catch(() => reject());
    });
  }

  confirmPublicHolidayModal(
    date: Date,
    holiday_name: string
  ): Promise<void> {
    const title = 'Confirm Public Holiday Time Entry';
    const message =
      '<p><b>' + this.datePipe.transform(date, 'EEEE') + '</b> is a public holiday.</p>' +
      '<p>Are you sure you want to record time on <b>' + holiday_name + '?</b></p>';

    return this.confirmModal(
      title, message, 'Confirm', '-color-green', true
    );
  }

  confirmDeleteModal(
    object_name: string,
    message: string = null,
    message_is_html: boolean = false
  ): Promise<void> {
    const title = 'Delete ' + object_name;
    message = message || 'Are you sure you want to delete ' + object_name + '?';
    const button_title = 'Delete';
    const button_colour_class = '-color-red';

    return this.confirmModal(title, message, button_title, button_colour_class, message_is_html);
  }

  confirmArchiveModal(
    object_name: string,
    message: string = null,
    message_is_html: boolean = false
  ): Promise<void> {
    const title = 'Archive ' + object_name;
    message = message || 'Are you sure you want to archive ' + object_name + '?';
    const button_title = 'Archive';
    const button_colour_class = '-color-red';

    return this.confirmModal(title, message, button_title, button_colour_class, message_is_html);
  }

  confirmInviteUacModal(
    user: any
  ): Promise<void> {
    const title = 'Invite ' + user.display_name;
    const message =
      '<div>Would you like to invite <b>' + user.display_name + '</b> to use Invoxy?</div>' +
      '<div>An invitiation email will be sent to <b>' + user.email_address + '</b>.</div>';
    const button_title = 'Invite';

    return this.confirmModal(title, message, button_title, '-color-green', true);
  }

  errorModal(
    message: string,
    messages: string[] = null,
    message_is_html: boolean = false
  ): Promise<void> {
    return new Promise((resolve) => {

      const component_properties = {
        message,
        messages,
        message_is_html
      };

      this._open(
        ErrorModalComponent,
        component_properties
      )
        .then(() => resolve())
        .catch(() => resolve());
    });
  }

  unsavedChangesModal(): Promise<string> {
    return new Promise((resolve) => {

      this._open(
        UnsavedChangesModalComponent
      )
        .then((save_changes) => save_changes ? resolve('SAVE') : resolve('DISCARD'))
        .catch(() => resolve('CANCEL'));
    });
  }

  dashGridBlockModal(
    available_widget_blocks: DashGridBlockThumbnail[] = [],
    active_blocks: DashGridBlock[] = []
  ): Promise<{ block_type: string, report_key?: number }> {
    return new Promise((resolve, reject) => {

      const component_properties = {
        available_widget_blocks,
        active_blocks
      };

      this._open(
        DashGridBlockModalComponent,
        component_properties
      )
        .then((res) => !!res ? resolve(res) : reject())
        .catch(() => reject());
    });
  }

  updateSegmentRateModal(
    project: any,
    task: any = null,
    resources: any[] = []
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      resources = _.filter(resources, (resource) => !resource.deleted_flag);

      const component_properties = {
        project,
        task,
        resources
      };

      this._open(
        UpdateSegmentRateModalComponent,
        component_properties,
        { size: 'lg' }
      )
        .then((res) => !!res ? resolve(res) : reject())
        .catch(() => reject());
    });
  }

  clockModal(
    init_new_clock: boolean = false,
    new_clock_note: string = null
  ): Promise<void> {
    return new Promise((resolve, reject) => {

      const component_properties = {
        init_new_clock,
        new_clock_note: init_new_clock ? new_clock_note : null
      };

      this._open(
        KmClockModalComponent,
        component_properties,
        { size: 'sm' }
      )
        .then(() => resolve())
        .catch(() => reject());
    });
  }

  feedbackProviderModal(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._open(
        FeedbackProviderModalComponent,
        {},
        { size: 'sm' }
      )
        .then((res) => !!res ? resolve(res) : reject())
        .catch(() => reject());
    });
  }

  successModal(
    message: string
  ): Promise<void> {
    return new Promise((resolve) => {

      const component_properties = {
        message
      };

      this._open(
        SuccessModalComponent,
        component_properties
      )
        .then(() => resolve(null))
        .catch(() => resolve(null));
    });
  }


  sentTimeErrorModal(
    segments: any[],
    integration_name: string,
    segment_type: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {

      const component_properties = {
        integration_name,
        segment_type,
        segments
      };

      this._open(
        SentTimeErrorModalComponent,
        component_properties
      )
        .then(() => resolve())
        .catch(() => reject());
    });
  }

  ////////////////////////////////////////////////////////////////////////////

  _open(
    component: any,
    component_properties: Record<string, any> = {},
    options: ModalOptions = {}
  ): Promise<any> {
    // Ensures an ExpressionChangedAfterItHasBeenCheckedError error isn't thrown if
    // an element with an ngModel binding (and { updateOn: 'blur' }) is active/in focus
    // at the point of opening the model
    (document.activeElement as any).blur();

    options = options || {};

    if (this.env.product === 'DROPPAH') {
      const expandingcanvas_options: ExpandingCanvasOptions = LibModalService.formatOptions('EXPANDING_CANVAS', options);

      return this._openExpandingCanvas(
        component,
        component_properties,
        expandingcanvas_options
      );
    }
    else if (DomService.is_mobile) {
      const offcanvas_options: NgbOffcanvasOptions = LibModalService.formatOptions('OFFCANVAS', options);

      if (!!options?.dismiss_open_modals && this.ngb_offcanvas.hasOpenOffcanvas()) {
        this.ngb_offcanvas.dismiss();
      }

      return this._openOffcanvas(
        component,
        component_properties,
        offcanvas_options
      );
    }
    else {
      const modal_options: NgbModalOptions = LibModalService.formatOptions('MODAL', options);

      if (!!options?.dismiss_open_modals && this.ngb_modal.hasOpenModals()) {
        this.ngb_modal.dismissAll();
      }

      return this._openModal(
        component,
        component_properties,
        modal_options
      );
    }
  }

  private _openExpandingCanvas(
    component: any,
    component_properties: Record<string, any> = {},
    options: ExpandingCanvasOptions = {}
  ): Promise<any> {
    return this.domService.openExpandingCanvas(
      component,
      component_properties,
      options
    );
  }

  private _openOffcanvas(
    component: any,
    component_properties: Record<string, any> = {},
    options: NgbOffcanvasOptions
  ): Promise<any> {
    const component_ref = this.ngb_offcanvas.open(OffcanvasWrapperComponent, options);

    component_ref.componentInstance.component = component;
    component_ref.componentInstance.component_properties = component_properties;

    return component_ref.result;
  }

  private _openModal(
    component: any,
    component_properties: Record<string, any> = {},
    options: NgbModalOptions
  ): Promise<any> {
    options = options || {};
    options.backdropClass = (options.backdropClass || '') + ' -app-zindex-' + (this.current_ngb_modal_z_index - 1);
    options.windowClass = (options.windowClass || '') + ' -app-zindex-' + (this.current_ngb_modal_z_index);

    const component_ref = this.ngb_modal.open(ModalWrapperComponent, options);
    try {
      document.getElementsByClassName('-app-zindex-' + (this.current_ngb_modal_z_index - 1))[0].setAttribute('style', 'z-index: ' + (this.current_ngb_modal_z_index - 1) + ' !important');
      document.getElementsByClassName('-app-zindex-' + this.current_ngb_modal_z_index)[0].setAttribute('style', 'z-index: ' + this.current_ngb_modal_z_index + ' !important');
    }
    catch (err) { }

    component_ref.componentInstance.component = component;
    component_ref.componentInstance.component_properties = component_properties;

    this._incrementModalZIndex();

    component_ref.closed.subscribe(() => {
      this._decrementModalZIndex(component_ref.componentInstance.modal_wrapper_id);

    });
    component_ref.dismissed.subscribe((res) => {
      this._decrementModalZIndex(component_ref.componentInstance.modal_wrapper_id);
    });

    return component_ref.result;
  }

  private _decrementModalZIndex(closed_modal_wrapper_id: string) {
    if (!!this.active_modal_instances.length) {
      // Only one modal was open
      if (this.active_modal_instances.length === 1) {
        this.current_ngb_modal_z_index = this.DEFAULT_NGB_MODAL_Z_INDEX;
      }
      // Multiple modals open
      // Only decrement z_index if top modal was closed
      else {
        const top_modal_wrapper_id = this.active_modal_instances[this.active_modal_instances.length - 1].componentInstance.modal_wrapper_id;

        if (closed_modal_wrapper_id === top_modal_wrapper_id) {
          this.current_ngb_modal_z_index = Math.max(this.current_ngb_modal_z_index - 2, this.DEFAULT_NGB_MODAL_Z_INDEX);
        }
      }
    }
  }

  private _incrementModalZIndex() {
    this.current_ngb_modal_z_index = this.current_ngb_modal_z_index + 2;
  }

  private _ensureOnlySingleErrorModalOpen() {
    this.ngb_modal.activeInstances.subscribe((modals: NgbModalRef[]) => {
      let index_of_last_error_modal = null;

      for (let i = 0; i < modals.length; i++) {
        if (modals[i].componentInstance instanceof ErrorModalComponent) {
          index_of_last_error_modal = i;
        }
      }

      if (index_of_last_error_modal !== null) {

        for (let i = index_of_last_error_modal - 1; i >= 0; i--) {
          if (modals[i].componentInstance instanceof ErrorModalComponent) {
            modals[i].close();
          }
        }
      }
    });
  }

  private _closeModalsOnRouteChange() {
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.ngb_modal.hasOpenModals()) {
          this.ngb_modal.dismissAll();
        }
        if (this.ngb_offcanvas.hasOpenOffcanvas()) {
          this.ngb_offcanvas.dismiss();
        }
      });
  }

  static formatOptions(
    component_type: 'MODAL' | 'OFFCANVAS' | 'EXPANDING_CANVAS',
    options: ModalOptions
  ): any {
    if (component_type === 'MODAL') {
      return {
        size: options?.size || null,
        centered: options?.centered || false
      };
    }
    else if (component_type === 'OFFCANVAS') {
      return {
        panelClass: '-modal ' + (options?.component_class || ''),
        backdropClass: '-modalBackdrop ' + (options?.component_class || ''),
        position: options?.position || 'bottom',
        keyboard: true
      };
    }
    else if (component_type === 'EXPANDING_CANVAS') {
      let position = 'bottom';

      if (options?.position === 'top') {
        position = 'top';
      }

      return {
        position,
        backdrop_class: '-modalBackdrop',
        close_on_click: false
      };
    }
    return {};
  }

}
