import { Inject, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import _ from 'lodash-es';

import {
  Label,
  ReportConfig,
  ReportConfigTable,
  ReportConfigDimension,
  ReportConfigMeasure,
  ReportChartType,
  ReportMeasurePermission,
  SocketEventType,
  ReportDatatype,
  ReportDataSubtype,
  AppProduct,
  ReportDimensionPermission,
  ReportDynamicPermission
} from '../../lib.types';

import { SocketService } from '../socket/socket.service';
import { ApiService } from '../api/api.service';
import { CoreUtilService } from '../core-util/core-util.service';
import { SortUtilService } from '../sort-util/sort-util.service';
import { Report, ReportFilter, } from '../../lib-models/report/report';
import { TableReport, TableReportMeasure } from '../../lib-models/report/table-report/table-report';
import { ChartReport, ReportSeries } from '../../lib-models/report/chart-report/chart-report';
import { NumericReport } from '../../lib-models/report/numeric-report/numeric-report';
import { TimeUtilService } from '../time-util/time-util.service';
import { AppNumberPipe } from '../../lib-pipes/app-number/app-number.pipe';
import { AppCurrencyPipe } from '../../lib-pipes/app-currency/app-currency.pipe';
import { ReportingAccount } from '../../lib-models/reporting-account/reporting-account';
import { HoursToTimePipe, ReportType, DomService, PhReportingAccount } from '../../../public-api';

type TableColumnCsvHeaderData = {
  column_key: string,
  column_title: string,
  column_field: ReportConfigDimension | ReportConfigMeasure,
};

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  readonly PRODUCT: AppProduct = this.env.product;

  readonly chart_type_labels = ChartReport.chart_type_labels;

  // Available graph types
  readonly chart_types: { id: ReportChartType, label: string }[] = [
    { id: 'bar', label: this.chart_type_labels.bar },
    { id: 'horizontal_bar', label: this.chart_type_labels.horizontal_bar },
    { id: 'line', label: this.chart_type_labels.line },
    { id: 'pie', label: this.chart_type_labels.pie }
  ];

  public readonly report_thumbnails: Record<(ReportChartType | 'table' | 'numeric'), string> = {
    bar: 'ion-md-stats',
    horizontal_bar: 'ion-md-list',
    line: 'bi-graph-up',
    pie: 'ion-md-pie',
    table: 'bi-table',
    numeric: 'bi-123'
  };

  reports: Report[] = [];
  reports_map: Record<number, Report> = {};
  config: ReportConfig = null;

  socket_subscriptions: Partial<Record<SocketEventType, Subscription>> = {};

  service_setup = false;

  constructor(
    @Inject('env') public env: any,
    public apiService: ApiService,
    public socketService: SocketService,
    public datePipe: DatePipe,
    public appCurrencyPipe: AppCurrencyPipe,
    public appNumberPipe: AppNumberPipe,
    public hoursToTimePipe: HoursToTimePipe,
    public domService: DomService
  ) { }

  initialiseService(force_reload = false) {
    return new Promise<void>((resolve, reject) => {
      if (this.service_setup && !force_reload) {
        resolve();
      }
      else {
        this.service_setup = false;

        this.loadConfig()
          .then(() => {
            this.loadReports()
              .then(() => {
                this._initEventListeners();
                this.service_setup = true;
                resolve();
              })
              .catch((err) => reject(err));
          })
          .catch(err => reject(err));
      }
    });
  }

  clearServiceData() {
    this.reports = [];
    this.reports_map = {};
    this.config = null;
    this.service_setup = false;

    this._clearEventListeners();
  }

  getChartTypes() {
    return this.chart_types;
  }

  getChartTypeLabels() {
    return this.chart_type_labels;
  }

  private _initEventListeners() {
    this.socket_subscriptions = {};

    if (this.PRODUCT === 'INVOXY' || this.PRODUCT === 'KARMLY') {
      this.socket_subscriptions.report_updated = this.socketService.subscribeToEvent('report_updated', (event_data) => {
        if (event_data.report_key) {
          this.reloadReport(event_data.report_key);
        }
      });

      this.socket_subscriptions.report_deleted = this.socketService.subscribeToEvent('report_deleted', (event_data) => {
        if (event_data.report_key) {
          this._reportDeleted(event_data.report_key);
        }
      });
    }
  }

  private _clearEventListeners() {
    for (const event_type of Object.keys(this.socket_subscriptions)) {
      this.socket_subscriptions[event_type].unsubscribe();
    }
  }

  getAllLabels(): Label[] {
    const labels = [];
    for (const report of this.reports) {
      for (const label of report.labels) {
        if (!CoreUtilService.labelAlreadyExists(label, labels)) {
          labels.push(label);
        }
      }
    }
    return labels;
  }

  getReport(report_key: number, get_reference: boolean = false): Report {
    return (!!get_reference ? this.reports_map[report_key] : _.cloneDeep(this.reports_map[report_key])) || null;
  }

  getAllReports(): Report[] {
    return this.reports;
  }

  getReportsMap(): Record<number, Report> {
    return this.reports_map;
  }

  getConfig(): ReportConfig {
    return _.cloneDeep(this.config);
  }

  getConfigFact(fact_id: string): ReportConfigTable {
    for (const fact of this.config.facts) {
      if (fact.id === fact_id) {
        return fact;
      }
    }
    return null;
  }

  getConfigFactField(fact: ReportConfigTable, id: string): ReportConfigDimension | ReportConfigMeasure {
    for (const measure of fact.measures) {
      if (measure.id === id) {
        return measure;
      }
    }
    for (const dimension of fact.dimensions) {
      if (dimension.id === id) {
        return dimension;
      }
    }
    return null;
  }

  getConfigFactMeasure(fact: ReportConfigTable, id: string): ReportConfigMeasure {
    for (const measure of fact.measures) {
      if (measure.id === id) {
        return measure;
      }
    }
    return null;
  }

  getConfigFactDimension(fact: ReportConfigTable, id: string): ReportConfigDimension {
    for (const dimension of fact.dimensions) {
      if (dimension.id === id) {
        return dimension;
      }
    }
    return null;
  }

  loadConfig(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.apiService.get(this.env.product, 'reporting/get_config')
        .then((res) => {
          this.config = this.setupConfig(res);
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  loadReports(): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.apiService.get(this.env.product, 'reporting')
        .then((res) => {
          this.reports = this.setupReports(res);
          this.reports_map = {};

          for (const report of this.reports) {
            this.reports_map[report.report_key] = report;
          }
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  reloadReport(report_key: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      this.apiService.get(this.env.product, 'reporting', { report_key })
        .then((res) => {
          const report = this.setupReport(res[0]);
          this._reportUpdated(report);
          resolve();
        })
        .catch(() => reject());
    });
  }

  saveReport(report: Report): Promise<Report> {
    return new Promise((resolve, reject) => {

      const data = report.formatForPosting();

      this.apiService.post(this.env.product, 'reporting', data)
        .then((res) => {
          const report = this.setupReport(res);
          this._reportUpdated(report);
          resolve(report);
        })
        .catch(() => reject());
    });
  }

  deleteReport(report: Report): Promise<void> {
    return new Promise<void>((resolve, reject) => {

      const data = report.formatForPosting(true);

      this.apiService.post(this.env.product, 'reporting', data)
        .then(() => {
          this._reportDeleted(report.report_key);
          resolve();
        })
        .catch(() => reject());
    });
  }

  private _reportUpdated(report: Report): void {
    if (!!report) {
      let reportFound = false;

      for (let i = 0; i < this.reports.length; i++) {
        if (report.report_key === this.reports[i].report_key) {
          this.reports[i] = report;
          reportFound = true;
        }
      }

      if (!reportFound) {
        this.reports.push(report);
      }

      this.reports_map[report.report_key] = report;
    }
  }

  private _reportDeleted(report_key: number): void {
    if (!!report_key) {
      for (let i = this.reports.length - 1; i >= 0; i--) {
        if (report_key === this.reports[i].report_key) {
          this.reports.splice(i, 1);
        }
      }
      delete this.reports_map[report_key];
    }
  }

  generateReportData(report: Report): Promise<any> {
    return new Promise((resolve, reject) => {

      const data = {
        report: JSON.stringify(report.generateReportQuery())
      };

      this.apiService.post(this.env.product, 'reporting/generate_report', data)
        .then((res) => resolve(res))
        .catch(() => reject());
    });
  }

  getDistinctValues(table_id: string, field_id: string): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      const params = {
        table: table_id,
        field: field_id
      };

      this.apiService.get(this.env.product, 'reporting/get_distinct', params)
        .then((res) => resolve(res))
        .catch(() => reject());
    });
  }

  generateReportCsv(rows: any[], report: Report): string {
    try {
      const column_headers: TableColumnCsvHeaderData[] = [];

      for (const column_key of Object.keys(rows[0])) {
        let column_field = null;
        let column_title = null;

        // Measure
        if (column_key.indexOf('__') !== -1) {
          const id = column_key.slice(0, column_key.indexOf('__'));
          const func = column_key.slice(column_key.indexOf('__') + 2, column_key.length);

          column_field = this.getConfigFactField(report.table_config, id);
          column_title = column_field.label + ' (' + func + ')';
        }
        // Dimension
        else {
          column_field = this.getConfigFactField(report.table_config, column_key);
          column_title = column_field.label;
        }

        column_headers.push({
          column_key,
          column_title,
          column_field
        });
      }

      let csv_content = (column_headers.map(cd => cd.column_title).join(',') + '\n');

      for (const row of rows) {
        let row_string = '';

        for (let i = 0; i < column_headers.length; i++) {
          const column_header = column_headers[i];
          const cell_value = row[column_header.column_key];

          const cell_value_formatted = this.formatFieldValue(
            cell_value,
            column_header.column_field,
            true
          ) || '';

          row_string += '"' + cell_value_formatted + '"';
          row_string += i < column_headers.length - 1 ? ',' : '\n';
        }

        csv_content += row_string;
      }

      return csv_content;
    }
    catch (err) {
      console.log(err);
      this.domService.openNotificationPopover('There was an issue downloading your report. Please try again later or get in touch if this issue persists', 'ERROR', true);
      return null;
    }
  }

  formatFieldValue(
    value: any,
    field_config: ReportConfigDimension | ReportConfigMeasure,
    format_to_string: boolean,
    num_decimal_places: number = 2
  ): any {
    if (value === null) return null;

    switch (field_config.datatype) {
      case 'BOOLEAN': {
        const formatted_value = CoreUtilService.parseBoolean(value);
        if (format_to_string) {
          return formatted_value === true ? 'True' : 'False';
        }

        return formatted_value;
      }
      case 'DATE': {
        const formatted_value = (TimeUtilService.dateIsValid(value) ?
          DateTime.fromJSDate(value) :
          DateTime.fromISO(value)
        ).startOf('day');

        return format_to_string ? formatted_value.toFormat('dd LLL yy') : formatted_value.toJSDate();
      }
      case 'TIME': {
        const today = DateTime.now();
        const formatted_value = (TimeUtilService.dateIsValid(value) ?
          DateTime.fromJSDate(value) :
          DateTime.fromJSDate(TimeUtilService.dateTimeStringToDate(value))
        ).set({ year: today.year, month: today.month, day: today.day });

        return format_to_string ? formatted_value.toFormat('hh:mm a') : formatted_value.toJSDate();
      }
      case 'NUMBER': {
        switch (field_config.data_subtype) {
          case 'DAYS':
            return format_to_string ? this.appNumberPipe.transform(value, num_decimal_places) : value;
          case 'HOURS':
            return format_to_string ? this.hoursToTimePipe.transform(value) : value;
          case 'CURRENCY': {
            return format_to_string ? this.appCurrencyPipe.transform(value, num_decimal_places) : value;
          }
          default:
            return format_to_string ? this.appNumberPipe.transform(value, num_decimal_places) : value;
        }
      }
      case 'MONTH':
      case 'WEEK':
      case 'WEEKDAY':
      case 'YEAR':
      case 'STRING': {
        return format_to_string ? value + '' : value;
      }
    }
  }

  validateFieldValue(
    value: any,
    field_config: ReportConfigDimension | ReportConfigMeasure
  ): boolean {
    switch (field_config.datatype) {
      case 'BOOLEAN': {
        return CoreUtilService.booleanIsValid(value);
      }
      case 'NUMBER': {
        return CoreUtilService.numberIsValid(value);
      }
      case 'TIME':
      case 'DATE': {
        return CoreUtilService.dateIsValid(value);
      }
      case 'WEEK': {
        return CoreUtilService.numberIsValid(value) && value >= 1 && value <= 52;
      }
      case 'YEAR': {
        return CoreUtilService.numberIsValid(value) && value >= 1900 && value <= 2100;
      }
      case 'MONTH': {
        const months = TimeUtilService.months;
        return CoreUtilService.stringIsValid(value) && months.indexOf(value) !== -1;
      }
      case 'WEEKDAY': {
        const week_days = TimeUtilService.week_days;
        return CoreUtilService.stringIsValid(value) && week_days.indexOf(value) !== -1;
      }
      case 'STRING': {
        return CoreUtilService.stringIsValid(value);
      }
    }
  }

  getDefaultFieldValue(
    field_config: ReportConfigDimension | ReportConfigMeasure
  ): any {
    switch (field_config.datatype) {
      case 'BOOLEAN': {
        return true;
      }
      case 'NUMBER': {
        return 0;
      }
      case 'TIME':
      case 'DATE': {
        return DateTime.now().startOf('day');
      }
      case 'WEEK': {
        return 1;
      }
      case 'YEAR': {
        return new Date().getFullYear();
      }
      case 'MONTH': {
        return TimeUtilService.months[0];
      }
      case 'WEEKDAY': {
        return TimeUtilService.week_days[0];
      }
      case 'STRING': {
        return '';
      }
    }
  }

  parseReportDataRowFieldId(row_field_key: string): string {
    const function_separator_index = row_field_key.indexOf('__');
    // row_field_key is a combination of a measure id and function
    // eg Duration__SUM
    if (function_separator_index !== -1) {
      return row_field_key.substring(0, function_separator_index);
    }
    return row_field_key;
  }

  parseReportDataRowFieldFunction(row_field_key: string): ReportMeasurePermission {
    const function_separator_index = row_field_key.indexOf('__');
    // row_field_key is a combination of a measure id and function
    // eg Duration__SUM
    if (function_separator_index !== -1) {
      return row_field_key.slice(function_separator_index + 2) as ReportMeasurePermission;
    }
    return null;
  }

  generateQueryForProject(report: Report, project_key: number): Promise<any> {
    report = _.cloneDeep(report);

    const dimension = this.getConfigFactDimension(report.table_config, 'Project_Key');

    report.filters.push({
      args: ['' + project_key + ''],
      field: dimension,
      func: 'IN',
      inverse: false
    });

    return this.generateQuery(report);
  }

  generateQueryForAccounts(report: Report, accounts: ReportingAccount[]): Promise<any> {
    report = _.cloneDeep(report);

    let dimension = this.getConfigFactDimension(report.table_config, 'Company_Reference');

    if (!!dimension) {
      report.filters.push({
        args: _.uniq(accounts.map(account => account.company_reference + '')),
        field: dimension,
        func: 'IN',
        inverse: false
      });
    }
    else {
      dimension = this.getConfigFactDimension(report.table_config, 'Access_Key');

      report.filters.push({
        args: _.uniq(accounts.map(account => (account as PhReportingAccount).access_key + '')),
        field: dimension,
        func: 'IN',
        inverse: false
      });
    }

    return this.generateQuery(report);
  }

  generateQuery(report: Report): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!report) {
        return reject();
      }

      if (!report.hasErrors(false)) {
        this.generateReportData(report)
          .then((report_data: any[]) => {

            if (report instanceof NumericReport) {
              if (!!report_data.length) {
                const data_field = report.measure.id + '__' + report.graph_function;
                if (!report_data[0][data_field]) {
                  report_data[0][data_field] = 0;
                }
                resolve(report_data[0]);
              }
            }
            else if (report instanceof TableReport) {
              if (!!report_data.length) {
                const field_config_map: Record<string, (ReportConfigDimension | ReportConfigMeasure)> = {};

                // Initialise field configs
                for (const field_key of Object.keys(report_data[0])) {
                  const field_id = this.parseReportDataRowFieldId(field_key);
                  field_config_map[field_key] = this.getConfigFactField(report.table_config, field_id);
                }

                for (const row of report_data) {
                  for (const field_key of Object.keys(row)) {

                    row[field_key] = this.formatFieldValue(
                      row[field_key],
                      field_config_map[field_key],
                      false
                    );
                  }
                }
              }

              resolve(report_data);
            }
            else if (report instanceof ChartReport) {
              const dimension_id = report.dimension.id;
              const measure_id = report.measure.id;
              const series_id = report.series?.dimension.id || null;

              const dimension_config = this.getConfigFactField(report.table_config, dimension_id);
              const measure_config = this.getConfigFactField(report.table_config, measure_id);
              const series_config = !!series_id ? this.getConfigFactField(report.table_config, series_id) : null;

              const measure_func_id = measure_id + '__' + report.graph_function;

              let chart_data = {
                measures: [],
                dimensions: [],
                series: []
              };

              switch (report.graph_type) {

                case 'bar':
                case 'line':
                case 'horizontal_bar': {
                  // TODO: sort rows
                  const chart_dimension_values = _.uniq(report_data.map((d) => d[dimension_id]));

                  let chart_series_values = [];
                  if (!!series_id) {
                    chart_series_values = _.uniq(report_data.map((d) => d[series_id]));
                  }

                  // Initialise chart_measure_values as an array of 0s
                  let chart_measure_values = CoreUtilService.createArrayOfItems(chart_dimension_values.length, 0);
                  // Convert to 2D array if series is set
                  if (!!series_id) {
                    chart_measure_values = CoreUtilService.createArrayOfItems(chart_series_values.length, chart_measure_values);
                  }

                  for (const row of report_data) {
                    // Treat nulls as 0s for measures
                    const measure_value = row[measure_func_id] || 0;

                    const dimension_value = row[dimension_id];
                    const chart_dimensions_values_index = chart_dimension_values.indexOf(dimension_value);

                    let series_value = null;
                    let chart_series_values_index = null;

                    // Insert measure value into chart_measure_values
                    if (!!series_id) {
                      series_value = row[series_id];
                      chart_series_values_index = chart_series_values.indexOf(series_value);

                      chart_measure_values[chart_series_values_index][chart_dimensions_values_index] = this.formatFieldValue(measure_value, measure_config, false);
                    }
                    else {
                      chart_measure_values[chart_dimensions_values_index] = this.formatFieldValue(measure_value, measure_config, false);
                    }
                  }

                  chart_data = {
                    measures: chart_measure_values,
                    dimensions: chart_dimension_values.map((dimension_value) => this.formatFieldValue(dimension_value, dimension_config, false)),
                    series: chart_series_values.map((series_value) => this.formatFieldValue(series_value, series_config, false)),
                  };
                  break;
                }
                case 'pie': {
                  // TODO: sort rows
                  for (const row of report_data) {
                    const chart_measure_value = row[measure_func_id] || 0;
                    const chart_dimension_value = row[dimension_id];
                    const chart_series_value = !!series_id ? row[series_id] : null;

                    // Formatted to string
                    let label = this.formatFieldValue(chart_dimension_value, dimension_config, true);
                    if (!!series_id) {
                      label += (' - ' + this.formatFieldValue(chart_series_value, series_config, true));
                    }

                    // Formatted without stringifying
                    const value = this.formatFieldValue(chart_measure_value, measure_config, false);

                    chart_data.dimensions.push(label);
                    chart_data.measures.push(value);
                  }
                  break;
                }
              }

              resolve(chart_data);
            }
          })
          .catch(() => {
            reject();
          });
      }
      else {
        const errors = report.getErrors(false);
        for (const error of Object.values(errors)) {
          this.domService.openNotificationPopover(error, 'ERROR');
        }
        reject();
      }
    });
  }

  setupConfig(config: any): ReportConfig {
    for (const fact of config.facts) {
      fact.label = this._parseConfigLabel(fact.label);

      fact.dimensions = fact.dimensions.map((d: any) => {
        const dimension: ReportConfigDimension = {
          id: d.id,
          label: this._parseConfigLabel(d.label),
          datatype: this._parseFieldDataType(d.datatype),
          data_subtype: this._parseFieldSubDataType(d.data_subtype),
          dynamic: CoreUtilService.parseBoolean(d.dynamic),
          system_only_flag: CoreUtilService.parseBoolean(d.system_only_flag),
        };
        return dimension;
      });
      fact.measures = fact.measures.map((m: any) => {
        const measure: ReportConfigMeasure = {
          id: m.id,
          label: this._parseConfigLabel(m.label),
          datatype: this._parseFieldDataType(m.datatype),
          data_subtype: this._parseFieldSubDataType(m.data_subtype),
          system_only_flag: CoreUtilService.parseBoolean(m.system_only_flag)
        };
        return measure;
      });
    }

    return config;
  }

  private _parseFieldDataType(datatype: string): ReportDatatype {
    return (datatype?.toUpperCase() || 'STRING') as ReportDatatype;
  }

  private _parseFieldSubDataType(datatype: string): ReportDataSubtype {
    return (datatype?.toUpperCase() || null) as ReportDataSubtype;
  }

  private _parseConfigLabel(label: string) {
    const placeholder_start_index = label.indexOf('[');

    if (placeholder_start_index !== -1) {
      const placeholder_end_index = label.indexOf(']');

      const placeholder = label.slice(placeholder_start_index + 1, placeholder_end_index);
      let placeholder_value = null;

      switch (placeholder) {
        case 'PROJECT_NAME':
          placeholder_value = CoreUtilService.project_label.capitalised;
          break;
        case 'TASK_NAME':
          placeholder_value = CoreUtilService.task_label.capitalised;
          break;
        case 'PROJECT_TASK_NAME':
          placeholder_value = CoreUtilService.project_task_label.capitalised;
          break;
      }

      if (placeholder_value !== null) {
        label = label.replace('[' + placeholder + ']', placeholder_value);
      }

    }
    return label;
  }

  setupReports(data: any[]): Report[] {
    const reports: Report[] = [];
    for (const d of data) {
      const report = this.setupReport(d);

      if (!!report) {
        reports.push(report);
      }
    }
    return SortUtilService.sortList(reports, { primary_sort_property: 'report_title' });
  }

  setupReport(r: any): Report {
    try {
      const graph_type = r.graph_type === 'horizontalBar' ? 'horizontal_bar' : r.graph_type;
      const table_config = this.getConfigFact(r.data_table);
      const series = this._setupReportSeries(table_config, r.series);
      const filters = this._setupReportFilters(table_config, r.filters);

      if (r.report_is_table) {
        const dimensions: ReportConfigDimension[] = [];
        for (const d of r.dimensions) {
          const dimension = this.getConfigFactDimension(table_config, d);

          if (!!dimension) {
            dimensions.push(dimension);
          }
        }

        const measures: TableReportMeasure[] = [];
        for (const m of r.measures) {
          const measure = this.getConfigFactMeasure(table_config, m.id);

          if (!!measure) {
            measures.push({
              measure,
              func: m.func
            });
          }
        }

        return new TableReport(
          table_config,
          dimensions,
          measures,
          r.report_key,
          r.report_title,
          r.labels,
          filters
        );
      }
      else if (r.graph_type === 'numeric') {
        return new NumericReport(
          table_config,
          r.graph_function,
          this.getConfigFactMeasure(table_config, r.measures[0]) || null,
          r.report_key,
          r.report_title,
          r.labels,
          filters
        );
      }
      else {
        return new ChartReport(
          table_config,
          r.graph_function,
          graph_type,
          this.getConfigFactDimension(table_config, r.dimensions[0]) || null,
          this.getConfigFactMeasure(table_config, r.measures[0]) || null,
          r.report_key,
          r.report_title,
          r.labels,
          filters,
          series
        );
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  private _setupReportSeries(table_config: ReportConfigTable, rs: any): ReportSeries {
    if (!rs) return null;

    const dimension = this.getConfigFactDimension(table_config, rs.id);

    return {
      dimension,
      show_legend: (rs.show_legend || rs.showLegend),
      stack: rs.stack
    };
  }

  private _setupReportFilters(table_config: ReportConfigTable, data: any[]): ReportFilter[] {
    const filters: ReportFilter[] = [];

    for (const d of data) {
      const field = this.getConfigFactField(table_config, d.field);

      if (!!field) {
        filters.push({
          args: this._formatFilterArgs(d.args, d.func, field),
          field,
          func: d.func,
          inverse: d.inverse,
        });
      }
    }

    return filters;
  }

  private _formatFilterArgs(
    args: any[],
    filter_func: ReportDimensionPermission | ReportDynamicPermission,
    filter_field: ReportConfigDimension | ReportConfigMeasure
  ): any[] {
    if (filter_func === 'CUSTOM') {
      return args;
    }
    else {
      return args.map((arg) => this.formatFieldValue(arg, filter_field, false));
    }
  }

  initNewReport(
    report_type: ReportType,
    table_config: ReportConfigTable = null,
    graph_function: ReportMeasurePermission = null
  ): Report {
    if (this.config.facts.length) {
      table_config = table_config || this.config.facts[0];
      graph_function = graph_function || this.config.permissions.measures[0];

      if (report_type === 'TABLE') {
        return new TableReport(
          table_config
        );
      }
      else if (report_type === 'CHART') {
        const dimension = _.find(table_config.dimensions, (dimension) => !dimension.system_only_flag) || null;
        const measure = _.find(table_config.measures, (measure) => !measure.system_only_flag) || null;

        return new ChartReport(
          table_config,
          graph_function,
          this.chart_types[0].id,
          dimension,
          measure
        );
      }
      else if (report_type === 'NUMERIC') {
        const measure = _.find(table_config.measures, (measure) => !measure.system_only_flag) || null;

        return new NumericReport(
          table_config,
          graph_function,
          measure
        );
      }
    }
    else {
      return null;
    }
  }

}
