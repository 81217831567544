import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import {
  DropdownItem,
  ReportConfigDimension,
  ReportConfigMeasure,
  ReportDimensionPermission,
  ReportDynamicPermission
} from '../../lib.types';

import { Report, ReportFilter } from '../../lib-models/report/report';
import { ReportService } from '../../lib-services/report/report.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { ListDropDownComponent } from '../../lib-components/list-drop-down/list-drop-down.component';
import { AppListComponent } from '../../lib-components/app-list/app-list.component';

import _ from 'lodash-es';
import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';
import { PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';

type FilterType = (
  'VALUE' | 'RANGE' | 'DYNAMIC'
);
type DynamicPrefix = (
  'Last' | 'Next'
);
type DynamicSuffix = (
  'Days' | 'Weeks' | 'Months' | 'Years'
);

type DynamicTypeDropdownItem = {
  label: string,
  value: ReportDynamicPermission
};
type SelectedValues = {
  VALUE: {
    value: any,
    list_values: any[]
  },
  RANGE: {
    from: any,
    to: any
  },
  DYNAMIC: {
    type: DynamicTypeDropdownItem,
    prefix: DynamicPrefix,
    value: number,
    suffix: DynamicSuffix
  }
}

@Component({
  selector: 'app-report-filter-modal',
  templateUrl: './report-filter-modal.component.html',
  styleUrls: ['./report-filter-modal.component.scss']
})
export class ReportFilterModalComponent implements OnInit, AppModalInterface {

  readonly currency_symbol = CoreUtilService.currency_symbol;
  readonly config = this.reportService.getConfig();
  readonly DATE_FORMAT = 'yyyy-MM-DD';

  @ViewChild('fieldDropdown') fieldDropdown: ListDropDownComponent;
  @ViewChild('fieldValueDropdown') fieldValueDropdown: ListDropDownComponent;
  @ViewChild(AppListComponent) appList: AppListComponent;

  @Input() report: Report;
  @Input() filter: ReportFilter = null;

  @Output() close = new EventEmitter<ReportFilter>();
  @Output() dismiss = new EventEmitter<void>();

  new_filter = false;

  filter_type_dropdown_map: Record<FilterType, DropdownItem> = {
    VALUE: {
      label: 'Value',
      value: 'VALUE'
    },
    RANGE: {
      label: 'Range',
      value: 'RANGE'
    },
    DYNAMIC: {
      label: 'Dynamic',
      value: 'DYNAMIC'
    }
  };
  filter_type_dropdown: DropdownItem[] = [
    this.filter_type_dropdown_map.VALUE,
    this.filter_type_dropdown_map.RANGE,
    this.filter_type_dropdown_map.DYNAMIC
  ];
  selected_filter_type: DropdownItem = this.filter_type_dropdown[0];

  fields: (ReportConfigMeasure | ReportConfigDimension)[] = [];
  selected_field_datatype_requires_formatting: boolean = false;
  selected_field_has_distinct_values: boolean = false;

  field_values: any[] = [];
  // Determined by filter.field.label
  empty_field_value: string = null;
  field_values_visible = true;

  dynamic_type_dropdown: DynamicTypeDropdownItem[] = [];
  dynamic_field_available = false;
  dynamic_filter_disabled = false;

  readonly dynamic_prefixes: DynamicPrefix[] = [
    'Last', 'Next'
  ];
  readonly dynamic_suffixes: DynamicSuffix[] = [
    'Days', 'Weeks', 'Months', 'Years'
  ];

  selected_values: SelectedValues = {
    VALUE: {
      value: null,
      list_values: []
    },
    RANGE: {
      from: null,
      to: null
    },
    DYNAMIC: {
      type: null,
      prefix: this.dynamic_prefixes[0],
      value: 0,
      suffix: this.dynamic_suffixes[0]
    }
  };

  filter_errors: PostableObjectErrorMap = {};

  fieldIsVisible = (field: ReportConfigMeasure | ReportConfigDimension) => {
    if (field.system_only_flag) {
      return false;
    }
    if (!this._fieldAlreadyInUse(field)) {
      switch (this.selected_filter_type.value) {
        case 'DYNAMIC':
          return this._fieldCanBeDynamic(field);
        case 'RANGE':
          return this._fieldCanBeRange(field);
        case 'VALUE':
          return true;
      }
    }
    return false;
  }

  fieldValueIsVisible = (field_value: string | number) => {
    return !this._fieldValueAlreadyInUse(field_value);
  }

  constructor(
    public reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.new_filter = !this.filter;

    this._initFields();

    if (!this.filter) {
      this._initNewFilter();
    }

    this._initFilterType();
    this._initSelectedField();
    this._initSelectedFieldValues();
    this._initDynamicTypeDropdown();
    this._initSelectedDynamicType();
    this._initDynamicFieldAvailable();

    this.loadFieldValues();

    this.dynamic_filter_disabled = !this.dynamic_field_available && this.selected_filter_type.value !== 'DYNAMIC';
  }

  get save_filter_disabled() {
    switch (this.selected_filter_type.value) {
      case 'VALUE':
        return this.filter.field.datatype !== 'BOOLEAN' && !this.selected_values.VALUE.list_values.length;
      case 'RANGE':
        return this.selected_values.RANGE.from === this.selected_values.RANGE.to;
      case 'DYNAMIC':
        return false;
    }
  }

  selectFilterType(filter_type: DropdownItem) {
    this.selected_filter_type = filter_type;
    this.filter.func = this._getPermissionForFilterType();

    this._checkSelectedFieldValidForFilterType();
    this._reloadChildComponentLists();
  }

  private _checkSelectedFieldValidForFilterType() {
    if (!this.fieldIsVisible(this.filter.field)) {
      for (const field of this.fields) {
        if (this.fieldIsVisible(field)) {
          this.selectField(field);
          return;
        }
      }
    }
  }

  validateValue(value: any): boolean {
    return this.reportService.validateFieldValue(value, this.filter.field);
  }

  addFilter() {
    this.filter_errors = {};
    let args = [];

    switch (this.selected_filter_type.value) {
      case 'VALUE': {
        if (this.filter.field.datatype === 'BOOLEAN') {
          args.push(this.selected_values.VALUE.value);
        }
        else {
          args = this.selected_values.VALUE.list_values;
        }
        break;
      }
      case 'RANGE': {
        if (this.selected_values.RANGE.to === this.selected_values.RANGE.from) {
          this.filter_errors.RANGE_TO = 'Values must be different';
        }
        else if (!this.validateValue(this.selected_values.RANGE.to)) {
          this.filter_errors.RANGE_TO = 'Invalid value';
        }
        else if (!this.validateValue(this.selected_values.RANGE.from)) {
          this.filter_errors.RANGE_FROM = 'Invalid value';
        }
        else {
          args = [this.selected_values.RANGE.from, this.selected_values.RANGE.to];
        }
        break;
      }
      case 'DYNAMIC': {
        if (this.selected_values.DYNAMIC.type.value === 'CUSTOM') {
          if (!this.selected_values.DYNAMIC.value && this.selected_values.DYNAMIC.value !== 0) {
            this.filter_errors.DYNAMIC_CUSTOM = 'Invalid value';
          }
          else {
            args = [
              this.selected_values.DYNAMIC.prefix,
              this.selected_values.DYNAMIC.value,
              this.selected_values.DYNAMIC.suffix
            ];
          }
        }
        this.filter.inverse = false;
        break;
      }
    }

    if (Object.keys(this.filter_errors).length === 0) {
      this.filter.args = args;
      this.close.emit(this.filter);
    }
  }

  cancel() {
    this.dismiss.emit();
  }

  selectField(field: ReportConfigMeasure | ReportConfigDimension) {
    if (field.id !== this.filter.field.id) {

      this.filter.field = field;
      this._updateSelectedFieldDatatypeRequiresFormatting();
      this._updateSelectedFieldHasDistinctValues();

      this.selected_values.VALUE.list_values = [];
      this.filter.args = [];

      this.loadFieldValues();
    }
  }

  private _updateSelectedFieldDatatypeRequiresFormatting() {
    this.selected_field_datatype_requires_formatting = [
      'NUMBER', 'CURRENCY', 'DATE', 'TIME'
    ].indexOf(this.filter.field.datatype) !== -1;
  }

  private _updateSelectedFieldHasDistinctValues() {
    this.selected_field_has_distinct_values = [
      'STRING', 'MONTH', 'WEEKDAY'
    ].indexOf(this.filter.field.datatype) !== -1;
  }

  selectFieldValue(field_value: any) {
    if (field_value === this.empty_field_value) {
      this.selected_values.VALUE.value = null;
    }
    else {
      this.selected_values.VALUE.value = field_value;
    }
  }

  selectFieldFrom(field_from: any) {
    this.selected_values.RANGE.from = field_from;
  }

  selectFieldTo(field_to: any) {
    this.selected_values.RANGE.to = field_to;
  }

  selectDynamicType(dynamic_type: DynamicTypeDropdownItem) {
    this.selected_values.DYNAMIC.type = dynamic_type;
    this.filter.func = dynamic_type.value;
  }

  selectCustomDynamicTypePrefix(prefix: DynamicPrefix) {
    this.selected_values.DYNAMIC.prefix = prefix;
  }

  selectCustomDynamicTypeSuffix(suffix: DynamicSuffix) {
    this.selected_values.DYNAMIC.suffix = suffix;
  }

  addFieldValue(event: MouseEvent = null) {
    event?.stopPropagation();
    const value = this.selected_values.VALUE.value;

    if (value === null || this.validateValue(value)) {
      this.filter_errors = {};
      if (this.selected_values.VALUE.list_values.indexOf(value) === -1) {
        this.selected_values.VALUE.list_values.push(value);
        this.selected_values.VALUE.value = this._getDefaultFieldValue();
      }

      this._updateEmptyFieldValue();
      this._reloadChildComponentLists();
    }
    else {
      this.filter_errors = { VALUE: 'Invalid value' };
    }
  }

  removeFieldValue(field_value: string | number) {
    _.remove(this.selected_values.VALUE.list_values, (value) => value === field_value);

    if (this.selected_values.VALUE.value === null) {
      this.selected_values.VALUE.value = this._getDefaultFieldValue();
    }

    this._updateEmptyFieldValue();
    this._reloadChildComponentLists();
  }

  private _reloadChildComponentLists() {
    setTimeout(() => {
      this.fieldDropdown?.reloadVisibleItems();
      this.fieldValueDropdown?.reloadVisibleItems();
      this.appList?.reloadVisibleItems();

      this.field_values_visible = this.selected_field_has_distinct_values && !!this.fieldValueDropdown?.visible_items.length;
    });
  }

  /**
   * Loads the distinct values for a field
   */
  loadFieldValues() {
    return new Promise<void>((resolve) => {

      if (this.filter.field.datatype === 'STRING') {
        this.reportService.getDistinctValues(this.report.table_config.id, this.filter.field.id)
          .then((res) => this._setupFieldValues(res))
          .catch(() => this._setupFieldValues([]))
          .finally(() => resolve());
      }
      else {
        if (this.filter.field.datatype === 'WEEKDAY') {
          this._setupFieldValues(TimeUtilService.week_days);
        }
        else if (this.filter.field.datatype === 'MONTH') {
          this._setupFieldValues(TimeUtilService.months);
        }
        else {
          this._setupFieldValues([]);
        }
        resolve();
      }
    });
  }

  private _setupFieldValues(field_values: string[]) {
    this.field_values = field_values;

    this._updateEmptyFieldValue();
    this._validateSelectedValues();
    this._reloadChildComponentLists();
  }

  private _updateEmptyFieldValue() {
    if (
      this.field_values.indexOf(null) !== -1 &&
      this.selected_values.VALUE.list_values.indexOf(null) === -1
    ) {
      this.empty_field_value = 'No ' + this.filter.field.label;
    }
    // Disable empty_field_value by setting it to null
    else {
      this.empty_field_value = null;
    }
  }

  private _getDefaultFieldValue(): any {
    switch (this.filter.field.datatype) {
      case 'STRING':
      case 'WEEKDAY':
      case 'MONTH': {
        const visible_field_values = _.filter(this.field_values, (field_value) => this.fieldValueIsVisible(field_value));
        return visible_field_values?.length ? visible_field_values[0] : null;
      }
      default: {
        return this.reportService.getDefaultFieldValue(this.filter.field);
      }
    }
  }

  private _validateSelectedValues() {
    const value_valid = this.reportService.validateFieldValue(this.selected_values.VALUE.value, this.filter.field);
    const from_valid = this.reportService.validateFieldValue(this.selected_values.RANGE.from, this.filter.field);
    const to_valid = this.reportService.validateFieldValue(this.selected_values.RANGE.to, this.filter.field);

    const default_value = this._getDefaultFieldValue();

    if (!value_valid) {
      this.selected_values.VALUE.value = default_value;
    }
    if (!from_valid || !to_valid) {
      this.selected_values.RANGE.from = default_value;
      this.selected_values.RANGE.to = default_value;
    }
  }

  private _initNewFilter() {
    this.filter = {
      args: [],
      field: this.fields[0],
      func: 'IN',
      inverse: false
    };
  }

  private _initFilterType() {
    switch (this.filter.func) {
      case 'LIKE':
      case 'IN':
        this.selected_filter_type = this.filter_type_dropdown_map.VALUE;
        break;
      case 'BETWEEN':
        this.selected_filter_type = this.filter_type_dropdown_map.RANGE;
        break;
      default:
        this.selected_filter_type = this.filter_type_dropdown_map.DYNAMIC;
        break;
    }
  }

  private _getPermissionForFilterType(): (ReportDimensionPermission | ReportDynamicPermission) {
    switch (this.selected_filter_type.value) {
      case 'VALUE':
        return 'IN';
      case 'RANGE':
        return 'BETWEEN';
      case 'DYNAMIC':
        return this.selected_values.DYNAMIC.type.value;
    }
  }

  private _initFields() {
    const existing_filter_fields = new Set(this.report.filters.map((filter) => filter.field.id));
    // Exclude current filter field from existing_filter_fields
    if (!!this.filter) {
      existing_filter_fields.delete(this.filter.field.id);
    }

    const all_fields = this.report.table_config.dimensions.concat(this.report.table_config.measures);

    // Filter out fields that're already used by existing report filters or fields that aren't for use by the user
    this.fields = _.filter(all_fields, (field) => {
      return !existing_filter_fields.has(field.id) && !field.system_only_flag;
    });
  }

  private _initSelectedDynamicType() {
    let selected_type: DynamicTypeDropdownItem = null;

    for (const dynamic_type of this.dynamic_type_dropdown) {
      if (dynamic_type.value === this.filter.func) {
        selected_type = dynamic_type;
      }
    }

    if (!selected_type) {
      selected_type = this.dynamic_type_dropdown[0];
    }
    this.selected_values.DYNAMIC.type = selected_type;
  }

  private _initSelectedField() {
    this._updateSelectedFieldDatatypeRequiresFormatting();
    this._updateSelectedFieldHasDistinctValues();
  }

  private _initSelectedFieldValues() {
    switch (this.selected_filter_type.value) {
      case 'VALUE': {
        this.selected_values.VALUE.list_values = this.filter.args || [];
        break;
      }
      case 'RANGE': {
        this.selected_values.RANGE.from = this.filter.args[0];
        this.selected_values.RANGE.to = this.filter.args[1];
        break;
      }
      case 'DYNAMIC': {
        if (this.filter.func === 'CUSTOM') {
          this.selected_values.DYNAMIC.prefix = this.filter.args[0];
          this.selected_values.DYNAMIC.value = this.filter.args[1];
          this.selected_values.DYNAMIC.suffix = this.filter.args[2];
        }
      }
    }
  }

  private _formatDynamicType(dynamic_type: ReportDynamicPermission): string {
    return CoreUtilService.toTitleCase(dynamic_type.replace(/_/g, ' '));
  }

  private _initDynamicTypeDropdown() {
    const dynamic_type_dropdown: DropdownItem[] = [];

    for (const dt of this.config.permissions.dynamic) {
      dynamic_type_dropdown.push({
        label: this._formatDynamicType(dt),
        value: dt
      });
    }
    this.dynamic_type_dropdown = dynamic_type_dropdown;
  }

  private _initDynamicFieldAvailable() {
    this.dynamic_field_available = false;

    for (const field of this.fields) {
      if (
        this._fieldCanBeDynamic(field) &&
        !this._fieldAlreadyInUse(field)
      ) {
        this.dynamic_field_available = true;
        return;
      }
    }
  }

  private _fieldAlreadyInUse(field: ReportConfigMeasure | ReportConfigDimension) {
    for (const filter of this.report.filters) {
      if (filter.field.id === field.id) {
        return true;
      }
    }
    return false;
  }

  private _fieldValueAlreadyInUse(field_value: any) {
    return this.selected_values.VALUE.list_values.indexOf(field_value) !== -1;
  }

  private _fieldCanBeDynamic(field: ReportConfigMeasure | ReportConfigDimension) {
    return !!(field as ReportConfigDimension).dynamic;
  }

  private _fieldCanBeRange(field: ReportConfigMeasure | ReportConfigDimension) {
    return ['BOOLEAN', 'WEEKDAY', 'MONTH'].indexOf(field.datatype) === -1;
  }

}
