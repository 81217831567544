import { ElementRef, EventEmitter } from '@angular/core';

import { BannerComponent } from '../../../lib-components/banner/banner.component';
import { BannerConfig, PublicHoliday } from '../../../lib.types';
import { CoreUtilService } from '../../../lib-services/core-util/core-util.service';
import { Segment } from '../../../lib-models/segment/segment';
import { PostableObjectErrorMap } from '../../../lib-interfaces/postable-object.interface';

export abstract class SegmentCreditModal {

  readonly currency_code = CoreUtilService.currency_code;
  readonly currency_symbol = CoreUtilService.currency_symbol;
  readonly project_label = CoreUtilService.project_label;
  readonly task_label = CoreUtilService.task_label;

  abstract banner: BannerComponent;
  abstract notes_textarea: ElementRef;

  abstract close: EventEmitter<Segment>;
  abstract dismiss: EventEmitter<void>;

  banner_config: BannerConfig = {
    type: 'ERROR',
    message: ''
  };

  title: string = null;
  current_public_holiday: PublicHoliday = null;
  notes_open: boolean = false;
  editing_disabled: boolean = false;

  error_message: string = null;
  segment_errors: PostableObjectErrorMap = {};

  // Inputs
  abstract segment: Segment;
  abstract projects: any[];
  abstract tasks: any[];

  projectIsVisible = (project: any) => true;
  taskIsVisible = (task: any) => true;

  onInit() {
    if (!this.segment) {
      this.dismiss.emit();
    }
    this.notes_open = true;
  }

  abstract _initTitle(): void;
  abstract projectSelected(project: any): void;
  abstract taskSelected(task: any): void;

  get units(): number {
    return Math.abs(this.segment.units);
  }
  set units(units: number) {
    this.segment.units = 0 - units;
  }

  validateForm() {
    this.banner.hideBanner();

    this.segment_errors = this.segment.getErrors();

    if (
      !this.banner_config.message &&
      Object.keys(this.segment_errors).length === 0
    ) {
      this._saveSegment();
    }
  }

  deleteSegment() {
    if (!!this.segment.segment_key) {
      this._saveSegment(true);
    }
  }

  cancel() {
    this.dismiss.emit();
  }

  toggleNotes() {
    this.notes_open = !this.notes_open;
    if (this.notes_open) {
      setTimeout(() => {
        this.notes_textarea?.nativeElement.focus();
      });
    }
  }

  _saveSegment(to_delete: boolean = false) {
    this.segment.deleted_flag = to_delete;
    this.close.emit(this.segment);
  }


}
