import { SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { DateTime } from 'luxon';

export type InvNotificationButtonConfig = {
  title: string,
  route_path: string,
  route_query_params: any
}

export class InvNotification {

  created_date: Date;
  notification_key: number;
  notification_type: string;
  description: SafeHtml;

  img_url: SafeResourceUrl = null;
  button: InvNotificationButtonConfig = null;

  time_from_now: string;
  is_new: boolean;

  constructor(
    created_date: Date,
    notification_key: number,
    notification_type: string,
    description: SafeHtml,
    img_url: SafeResourceUrl = null,
    button: InvNotificationButtonConfig = null
  ) {
    this.created_date = created_date;
    this.notification_key = notification_key;
    this.notification_type = notification_type;
    this.description = description;
    this.img_url = img_url;
    this.button = button;

    const l_created_date = DateTime.fromJSDate(this.created_date);

    this.time_from_now = l_created_date.toRelative();
    this.is_new = l_created_date.hasSame(DateTime.now(), 'day');
  }

}
