import { ElementRef, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash-es';
import { DateTime } from 'luxon';

import { TimeUserNewSegmentEvent, SegmentWeeklyTotals } from '../../../lib.types';
import { LibModalService } from '../../../lib-services/lib-modal/lib-modal.service';
import { CoreUtilService } from '../../../lib-services/core-util/core-util.service';
import { SortUtilService } from '../../../lib-services/sort-util/sort-util.service';
import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { InvSegment } from '../../../lib-models/segment/inv-segment/inv-segment';
import { KmSegment } from '../../../lib-models/segment/km-segment/km-segment';
import { StateDataService } from '../../../lib-services/state-data/state-data.service';

export type TimeUserTableRow = {
  row_key: number | string,
  row_name: string,
  row_description?: string,
  row_colour: string,
  unit_type: string,
  is_custom_unit_type: boolean,
  project_archived_flag: boolean,
  task_archived_flag: boolean,
  total_units: number,
  start_index: number,
  end_index: number,
  days: TimeUserTableRowDay[],
  removeable: boolean,
  locked: boolean
};

export type TimeUserTableRowDay = {
  segments: (InvSegment | KmSegment)[],
  units: number,
  date: Date
};

export abstract class TimeUserTable {

  readonly query_params = CoreUtilService.parseQueryParams(this.route.snapshot.queryParams);
  readonly project_label = CoreUtilService.project_label;
  readonly task_label = CoreUtilService.task_label;
  readonly project_task_label = CoreUtilService.project_task_label;
  readonly currency_code: string = CoreUtilService.currency_code;
  readonly currency_symbol: string = CoreUtilService.currency_symbol;

  readonly MULTI_SEGMENT_MESSAGE = 'This day has multiple records for this work. Please edit the individual time records manually.';
  readonly NEGATIVE_SEGMENT_MESSAGE = 'This day contains a work credit. Please edit the individual time records manually.';

  selected_week = this.stateDataService.selected_week;

  abstract selected_day_index: number;
  abstract selected_day_segments: (InvSegment | KmSegment)[];

  table_rows: TimeUserTableRow[] = [];
  weekly_totals: SegmentWeeklyTotals = {
    days: [0, 0, 0, 0, 0, 0, 0],
    week: 0,
    unit_type: 'hours'
  };

  // Inputs
  abstract segments: (InvSegment | KmSegment)[];

  abstract loading: boolean;

  projectIsActive = (project: any) => true;

  // Outputs
  abstract new_segment: EventEmitter<TimeUserNewSegmentEvent>;
  abstract update_segment: EventEmitter<{ segment: InvSegment | KmSegment }>;
  abstract edit_segment: EventEmitter<{ segment: InvSegment | KmSegment }>;

  constructor(
    public stateDataService: StateDataService,
    public element: ElementRef,
    public libModalService: LibModalService,
    public route: ActivatedRoute
  ) { }

  abstract setupDefaultTableRows(): void;
  abstract setupTableRowForSegment(segment: any): TimeUserTableRow;
  abstract segmentMatchesTableRow(table_row: TimeUserTableRow, segment: any): boolean;
  abstract createTableRowDaySegment(table_row: TimeUserTableRow, day_index: number): void;
  abstract sortTableRows(): void;

  segmentCreated(segment: InvSegment | KmSegment) {
    this._addSegmentToTableRows(segment);
    this._setupWeeklyTotals();
    this.setupSelectedDaySegments();
  }

  segmentUpdated(segment: InvSegment | KmSegment) {
    this._updateSegmentInTableRows(segment);
    this._setupWeeklyTotals();
    this.setupSelectedDaySegments();
  }

  segmentDeleted(segment_key: number) {
    this._deleteSegmentInTableRows(segment_key);
    this._setupWeeklyTotals();
    this.setupSelectedDaySegments();
  }

  editSegment(segment: InvSegment | KmSegment) {
    this.edit_segment.emit({ segment });
  }

  updateTableRowDay(table_row: TimeUserTableRow, day_index: number) {
    const day = table_row.days[day_index];

    if (
      !CoreUtilService.numberIsValid(day.units) ||
      day.units < 0 ||
      day.segments.length > 1 ||
      this._tableRowDayHasCreditSegment(day)
    ) {
      day.units = TimeUtilService.calculateTotalDurationOrUnitsOfSegments(day.segments);
    }
    else {
      if (day.units === 0) {
        this._deleteTableRowDaySegment(day);
      }
      else {
        if (!!day.segments.length) {
          this._editTableRowDaySegment(day);
        }
        else {
          this.createTableRowDaySegment(table_row, day_index);
        }
      }
    }
  }

  private _editTableRowDaySegment(day: TimeUserTableRowDay) {
    if (day.segments.length === 1) {
      const segment = day.segments[0];

      if (segment.unit_flag) {
        segment.units = day.units;
      }
      else {
        segment.duration = day.units;

        if (segment.duration + segment.break_duration > 24) {
          segment.duration = 24 - segment.break_duration;
        }

        const l_end_time = DateTime.fromJSDate(segment.start_time).plus({ hours: segment.duration + segment.break_duration });
        segment.end_time = l_end_time.toJSDate();
      }

      this.update_segment.emit({ segment });
    }
  }

  private _deleteTableRowDaySegment(day: TimeUserTableRowDay) {
    if (day.segments.length === 1) {
      const segment = day.segments[0];
      segment.deleted_flag = true;

      this.update_segment.emit({ segment });
    }
  }

  setupSelectedDaySegments() {
    if (this.selected_day_index !== null) {
      const segments = [];
      const l_date = DateTime.fromJSDate(this.stateDataService.selected_week[this.selected_day_index]);

      for (const segment of this.segments) {
        if (l_date.hasSame(DateTime.fromJSDate(segment.segment_date), 'day')) {
          segments.push(segment);
        }
      }

      this.selected_day_segments = SortUtilService.sortList(
        segments,
        {
          primary_sort_property: 'start_time',
          forward_order: false
        }
      );
    }
    else {
      this.selected_day_segments = [];
    }
  }

  removeEmptyTableRow(row_index: number): void {
    if (this.table_rows[row_index].removeable) {
      this.table_rows.splice(row_index, 1);
    }
  }

  setupWeek() {
    this.selected_week = this.stateDataService.selected_week;
    this.table_rows = [];
    for (const segment of this.segments) {
      if (this._getDayIndex(segment.segment_date) !== null)
        this._addSegmentToTableRows(segment);
    }
    this.setupDefaultTableRows();
    this.sortTableRows();
    this._setupWeeklyTotals();
  }

  private _setupWeeklyTotals() {
    this.weekly_totals = TimeUtilService.calculateWeeklySegmentTotals(
      this.segments,
      this.stateDataService.selected_week_start
    );
  }

  private _addSegmentToTableRows(segment: InvSegment | KmSegment): void {
    let table_row = null;

    for (const row of this.table_rows) {
      if (this.segmentMatchesTableRow(row, segment)) {
        table_row = row;
        break;
      }
    }

    if (!table_row) {
      table_row = this.setupTableRowForSegment(segment);
      if (!!table_row) {
        this.table_rows.push(table_row);
        this.sortTableRows();
      }
    }

    if (!!table_row) {
      this._addSegmentToDayInRow(segment, table_row);
    }
  }

  private _updateSegmentInTableRows(segment: InvSegment | KmSegment) {
    this._clearSegmentFromTable(segment.segment_key);
    this._addSegmentToTableRows(segment);
  }

  private _clearSegmentFromTable(segment_key: number): void {
    for (const table_row of this.table_rows) {
      for (const day of table_row.days) {

        for (let i = day.segments.length - 1; i >= 0; i--) {
          if (day.segments[i].segment_key === segment_key) {
            day.segments.splice(i, 1);

            this._updateTableRowDayUnits(day);
            this._updateTableRowTotalUnits(table_row);
          }
        }
      }
    }
  }

  private _deleteSegmentInTableRows(segment_key: number) {
    for (const table_row of this.table_rows) {
      for (const day of table_row.days) {

        for (let i = 0; i < day.segments.length; i++) {
          if (day.segments[i].segment_key === segment_key) {
            day.segments.splice(i, 1);

            this._updateTableRowDayUnits(day);
            this._updateTableRowTotalUnits(table_row);
            return;
          }
        }
      }
    }
  }

  private _updateTableRowDayUnits(day: TimeUserTableRowDay) {
    day.units = 0;
    for (const segment of day.segments) {
      day.units += (segment.unit_flag ? segment.units : segment.duration);
    }
  }

  private _updateTableRowTotalUnits(table_row: TimeUserTableRow) {
    table_row.total_units = 0;
    for (const day of table_row.days) {
      table_row.total_units += day.units;
    }
  }

  private _tableRowDayHasCreditSegment(day: TimeUserTableRowDay) {
    for (const segment of day.segments) {
      if ((segment.unit_flag ? segment.units : segment.duration) < 0) {
        return true;
      }
    }
    return false;
  }

  isCustomUnitType(unit_type: string): boolean {
    const p_unit_type = this.parseUnitType(unit_type);
    return ['hours', 'days', 'expense', '$'].indexOf(p_unit_type) === -1;
  }

  parseUnitType(unit_type: string): string {
    if (unit_type === '$') {
      return unit_type;
    }
    else {
      const u_unit_type = unit_type.toLowerCase();

      if (['hours', 'days', 'expense'].indexOf(u_unit_type) !== -1) {
        return u_unit_type;
      }
      else {
        return unit_type;
      }
    }
  }

  private _addSegmentToDayInRow(segment: InvSegment | KmSegment, table_row: TimeUserTableRow) {
    for (const day of table_row.days) {
      const segment_date = DateTime.fromJSDate(segment.segment_date);

      if (DateTime.fromJSDate(day.date).hasSame(segment_date, 'day')) {
        day.segments.push(segment);
        table_row.removeable = false;

        this._updateTableRowDayUnits(day);
        this._updateTableRowTotalUnits(table_row);
      }
    }
  }

  private _getDayIndex(date: Date) {
    const l_date = DateTime.fromJSDate(date);
    const selected_week = this.stateDataService.selected_week;

    for (let i = 0; i < selected_week.length; i++) {
      if (DateTime.fromJSDate(selected_week[i]).hasSame(l_date, 'day')) {
        return i;
      }
    }
    return null;
  }

  initTableRowDays(): TimeUserTableRowDay[] {
    const days: TimeUserTableRowDay[] = [];
    for (let i = 0; i < 7; i++) {
      days.push({
        segments: [],
        units: 0,
        date: this.stateDataService.selected_week[i]
      });
    }
    return days;
  }

  // Normally project start and end dates
  getRowStartEndIndices(start_date: Date, end_date: Date) {
    let start_index: number = null;
    let end_index: number = null;

    if (start_date > this.stateDataService.selected_week_end) {
      start_index = 7;
      end_index = 7;
    }
    else if (end_date && end_date < this.stateDataService.selected_week_start) {
      start_index = -1;
      end_index = -1;
    }
    else {
      start_index = this._getDayIndex(start_date) || -1;
      end_index = this._getDayIndex(end_date) || 7;
    }

    return {
      start: start_index,
      end: end_index
    };
  }

}
