<div class="megaSearch-container"
     ngbDropdown
     *ngIf="!is_mobile"
     [placement]="alignLeft ? 'bottom-left' : 'bottom-right'"
     [container]="'body'"
     [autoClose]="'outside'"
     (openChange)="dropdownToggled($event)">

  <div class="app-formItem-inputContainer megaSearch-inputContainer"
       ngbDropdownAnchor
       [ngClass]="{ '-disabled': disabled }"
       (mousedown)="determineDropAlignment($event)"
       (click)="dropdownToggleClicked()">

    <ng-container *ngTemplateOutlet="ms_inputContainer_content">
    </ng-container>
  </div>

  <div ngbDropdownMenu
       [ngStyle]="{ 'width': listWidth }"
       [hidden]="instantSearch">

    <ng-container *ngTemplateOutlet="ms_dropdown_content">
    </ng-container>
  </div>

</div>

<div class="megaSearch-container"
     *ngIf="is_mobile">

  <div class="app-formItem-inputContainer megaSearch-inputContainer"
       [ngClass]="{ '-disabled': disabled }"
       (click)="dropdownToggleClicked()">

    <ng-container *ngTemplateOutlet="ms_inputContainer_content">
    </ng-container>
  </div>
</div>

<ng-template #ms_inputContainer_content>
  <app-search [show_input_container]="false"
              [placeholder]="inputPlaceholder"
              [disabled]="disabled"
              [search]="searchInput"
              (searchChange)="updateSearchInput($event)"
              (keydown)="keyPressed($event)">
  </app-search>

  <div class="megaSearch-selectedSearchPropertyCount"
       [hidden]="searchInput.length !== 0 || selectedSearchProperties.length === 0">
    <span>{{selectedSearchProperties.length}}</span>
  </div>

  <div class="app-formItem-inputContainer-border">
  </div>
</ng-template>

<ng-template #ms_dropdown_content>
  <div class="megaSearch-dropdown">
    <div class="app-formItem-inputContainer -mockFocus megaSearch-inputContainer -mobile-only"
         *ngIf="!instantSearch">
      <ng-container *ngTemplateOutlet="ms_inputContainer_content">
      </ng-container>
    </div>

    <div class="megaSearch-dropdown-contents">

      <div class="megaSearch-dropdown-properties -filtered"
           *ngIf="filteredSearchProperties.length">
        <div class="megaSearch-dropdown-property"
             *ngFor="let filtered of filteredSearchProperties; let i = index"
             [ngClass]="{ '-highlighted': i === highlightedFilteredSearchPropertyIndex }"
             (click)="selectFilteredSearchProperty(filtered)">
          <div class="-itemValue">
            {{allItemPropValueToUnformattedItemPropValueMap[filtered.item_prop_value]}}
          </div>
          <div class="-propertyLabel">
            <span>{{searchPropertyMap[filtered.search_prop_key].label}}</span>
          </div>
        </div>
      </div>

      <div class="megaSearch-dropdown-empty"
           *ngIf="searchInput.length !== 0 && filteredSearchProperties.length === 0">
        <span>No results for search</span>
      </div>

      <div class="megaSearch-dropdown-spacer"
           *ngIf="searchInput.length !== 0 && selectedSearchProperties.length">
      </div>

      <div class="megaSearch-dropdown-header"
           *ngIf="selectedSearchProperties.length">
        <span>Current Filters:</span>
        <button class="-type-text -color-link-clear"
                (click)="clearAllSelectedSearchProperties()">
          Clear All
        </button>
      </div>

      <div class="megaSearch-dropdown-properties -selected"
           *ngIf="selectedSearchProperties.length">
        <div class="megaSearch-dropdown-property"
             *ngFor="let selected of selectedSearchProperties">
          <div class="-itemValue"
               *ngIf="selected.search_prop_key !== null">
            {{allItemPropValueToUnformattedItemPropValueMap[selected.item_prop_value]}}
          </div>
          <div class="-itemValue"
               *ngIf="selected.search_prop_key === null">
            {{globalSearchValueToUnformattedSearchValueMap[selected.item_prop_value]}}
          </div>
          <div class="-propertyLabel"
               *ngIf="selected.search_prop_key !== null">
            <span>{{searchPropertyMap[selected.search_prop_key].label}}</span>
          </div>
          <i class="ion ion-md-close app-remove"
             (click)="removeSelectedSearchProperty(selected)">
          </i>
        </div>
      </div>
    </div>
  </div>
</ng-template>
