<div class="app-formItem-inputContainer"
     [ngClass]="{ '-readonly': readonly, '-disabled': disabled, '-mockFocus': mock_focus }"
     [ngStyle]="{ 'margin-top': !!label ? 'var(--theme-formItem-label-height)' : '' }"
     (click)="clearErrorOnClick()">
  <ng-content></ng-content>
  <div class="app-formItem-inputContainer-border">
  </div>
</div>
<div class="app-formItem-label"
     *ngIf="!!label"
     [ngClass]="{ '-inputHasValue': input_has_value}">
  <div>{{label}}</div>
  <whats-this *ngIf="!!whats_this"
              [help_type]="'HOVER'"
              [hover_text]="whats_this"
              [hover_text_placement]="label.length > 17 ? 'top' : 'top-left'">
  </whats-this>
  <div class="-alignRight"
       *ngIf="label_right">
    <span>{{label_right}}</span>
  </div>
  <div class="-alignRight"
       *ngIf="label_right_content">
    <ng-content select="[appFormItem-labelRight]"></ng-content>
  </div>
</div>

<ng-container *ngFor="let error_key of error_key_list">
  <div class="app-formItem-errorLabel"
       [ngClass]="{ '-errorVisible': !!error_key && form_errors[error_key] }">
    {{form_errors[error_key]}}
  </div>
</ng-container>
