<div class="app-modal-container">

  <div class="app-navHeader">

    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>{{new_filter ? 'New' : 'Edit'}} Filter</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <app-checkbox [(ngModel)]="filter.inverse"
                      [label]="'Inverse Filter'">
        </app-checkbox>

        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>

  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <div class="app-formItem-container">

        <app-form-item [label]="'Filter Type'">
          <list-drop-down [item_label_prop]="'label'"
                          [button_title]="selected_filter_type.label"
                          [list_fill_width]="true"
                          [sort_items]="false"
                          [items]="filter_type_dropdown"
                          (item_selected)="selectFilterType($event.item)">
          </list-drop-down>
        </app-form-item>

        <app-form-item [label]="'Field'">
          <list-drop-down #fieldDropdown
                          [item_label_prop]="'label'"
                          [button_title]="filter.field.label"
                          [itemIsVisible]="fieldIsVisible"
                          [list_fill_width]="true"
                          [show_search]="true"
                          [items]="fields"
                          (item_selected)="selectField($event.item)">
          </list-drop-down>
        </app-form-item>

        <app-form-item *ngIf="selected_filter_type.value === 'VALUE'"
                       [label]="'Value'"
                       [ngClass]="{ '-notBoolean': filter.field.datatype !== 'BOOLEAN' }"
                       [form_errors]="filter_errors"
                       [error_keys]="'VALUE'"
                       [hide_border]="filter.field.datatype === 'BOOLEAN'">
          <div class="rfModal-booleanValueOptions"
               *ngIf="filter.field.datatype === 'BOOLEAN'">
            <button [ngClass]="selected_values.VALUE.value ? '-color-success' : '-color-white-outline'"
                    (click)="selected_values.VALUE.value = true">
              True
            </button>
            <button [ngClass]="selected_values.VALUE.value ? '-color-white-outline' : '-color-success'"
                    (click)="selected_values.VALUE.value = false">
              False
            </button>
          </div>

          <list-drop-down *ngIf="selected_field_has_distinct_values"
                          #fieldValueDropdown
                          [button_title]="selected_values.VALUE.value"
                          [itemIsVisible]="fieldValueIsVisible"
                          [list_fill_width]="true"
                          [disabled]="!field_values_visible"
                          [sort_items]="false"
                          [default_item]="empty_field_value"
                          [items]="field_values"
                          (item_selected)="selectFieldValue($event.item)">
          </list-drop-down>

          <div class="input-group"
               *ngIf="filter.field.datatype === 'NUMBER' || filter.field.datatype === 'WEEK'">
            <div class="input-group-addon"
                 *ngIf="filter.field.data_subtype === 'CURRENCY'">
              {{currency_symbol}}
            </div>
            <input class="form-control"
                   type="number"
                   placeholder="value"
                   [(ngModel)]="selected_values.VALUE.value">
            <div class="input-group-addon"
                 *ngIf="filter.field.datatype === 'WEEK'">
              (1 - 52)
            </div>
            <div class="input-group-addon"
                 *ngIf="filter.field.data_subtype === 'HOURS'">
              Hours
            </div>
            <div class="input-group-addon"
                 *ngIf="filter.field.data_subtype === 'DAYS'">
              Days
            </div>
          </div>

          <date-picker *ngIf="filter.field.datatype === 'DATE'"
                       [(ngModel)]="selected_values.VALUE.value">
          </date-picker>

          <time-picker *ngIf="filter.field.datatype === 'TIME'"
                       [(ngModel)]="selected_values.VALUE.value">
          </time-picker>

          <button class="-color-success -type-icon"
                  *ngIf="selected_filter_type.value === 'VALUE' && filter.field.datatype !== 'BOOLEAN'"
                  [disabled]="selected_field_has_distinct_values && !field_values_visible"
                  (click)="addFieldValue($event)">
            <i class="ion ion-ios-add"></i>
          </button>

        </app-form-item>

        <app-form-item *ngIf="selected_filter_type.value === 'VALUE' && filter.field.datatype !== 'BOOLEAN'"
                       [label]="(filter.inverse ? 'Excluded' : 'Included') + ' Values'"
                       [hide_border]="true">

          <app-list class="rfModal-selectedValues"
                    [itemHeight]="40"
                    [scrollPositionCachingDisabled]="true"
                    [itemSelectDisabled]="true"
                    [canDeleteItems]="true"
                    [listEmptyHeader]="''"
                    [listEmptyDescription]="'No values added'"
                    [items]="selected_values.VALUE.list_values"
                    (itemDeleted)="removeFieldValue($event.item)">
            <ng-template appListItem
                         let-item>
              <div class="app-list-itemCell">
                <div *ngIf="!!item">
                  {{item | reportFieldValue : filter.field}}
                </div>
                <div class="-label"
                     *ngIf="!item">
                  No {{filter.field.label}}
                </div>
              </div>
            </ng-template>
          </app-list>

        </app-form-item>

        <app-form-item *ngIf="selected_filter_type.value === 'RANGE'"
                       [label]="'From'"
                       [form_errors]="filter_errors"
                       [error_keys]="'RANGE_FROM'">
          <list-drop-down *ngIf="selected_field_has_distinct_values"
                          #fieldValueDropdown
                          [button_title]="selected_values.RANGE.from"
                          [list_fill_width]="true"
                          [disabled]="!field_values_visible"
                          [sort_items]="false"
                          [items]="field_values"
                          (item_selected)="selectFieldFrom($event.item)">
          </list-drop-down>

          <div class="input-group"
               *ngIf="filter.field.datatype === 'NUMBER'">
            <div class="input-group-addon"
                 *ngIf="filter.field.data_subtype === 'CURRENCY'">
              {{currency_symbol}}
            </div>
            <input class="form-control"
                   type="number"
                   placeholder="from"
                   [(ngModel)]="selected_values.RANGE.from">
            <div class="input-group-addon"
                 *ngIf="filter.field.data_subtype === 'HOURS'">
              Hours
            </div>
            <div class="input-group-addon"
                 *ngIf="filter.field.data_subtype === 'DAYS'">
              Days
            </div>
          </div>

          <date-picker *ngIf="filter.field.datatype === 'DATE'"
                       [(ngModel)]="selected_values.RANGE.from">
          </date-picker>

          <time-picker *ngIf="filter.field.datatype === 'TIME'"
                       [(ngModel)]="selected_values.RANGE.from">
          </time-picker>

        </app-form-item>


        <app-form-item *ngIf="selected_filter_type.value === 'RANGE'"
                       [label]="'To'"
                       [form_errors]="filter_errors"
                       [error_keys]="'RANGE_TO'">
          <list-drop-down *ngIf="selected_field_has_distinct_values"
                          #fieldValueDropdown
                          [button_title]="selected_values.RANGE.to"
                          [list_fill_width]="true"
                          [disabled]="!field_values_visible"
                          [sort_items]="false"
                          [items]="field_values"
                          (item_selected)="selectFieldTo($event.item)">
          </list-drop-down>

          <div class="input-group"
               *ngIf="filter.field.datatype === 'NUMBER'">
            <div class="input-group-addon"
                 *ngIf="filter.field.data_subtype === 'CURRENCY'">
              {{currency_symbol}}
            </div>
            <input class="form-control"
                   type="number"
                   placeholder="to"
                   [(ngModel)]="selected_values.RANGE.to">
            <div class="input-group-addon"
                 *ngIf="filter.field.data_subtype === 'HOURS'">
              Hours
            </div>
            <div class="input-group-addon"
                 *ngIf="filter.field.data_subtype === 'DAYS'">
              Days
            </div>
          </div>

          <date-picker *ngIf="filter.field.datatype === 'DATE'"
                       [(ngModel)]="selected_values.RANGE.to">
          </date-picker>

          <time-picker *ngIf="filter.field.datatype === 'TIME'"
                       [(ngModel)]="selected_values.RANGE.to">
          </time-picker>
        </app-form-item>


        <app-form-item *ngIf="selected_filter_type.value === 'DYNAMIC'"
                       [label]="'Value'">
          <list-drop-down [item_label_prop]="'label'"
                          [button_title]="selected_values.DYNAMIC.type.label"
                          [list_fill_width]="true"
                          [items]="dynamic_type_dropdown"
                          (item_selected)="selectDynamicType($event.item)">
          </list-drop-down>
        </app-form-item>


        <app-form-item *ngIf="selected_filter_type.value === 'DYNAMIC' && selected_values.DYNAMIC.type.value === 'CUSTOM'"
                       [form_errors]="filter_errors"
                       [error_keys]="'DYNAMIC_CUSTOM'">
          <div class="input-group">
            <list-drop-down [button_title]="selected_values.DYNAMIC.prefix"
                            [items]="dynamic_prefixes"
                            (item_selected)="selectCustomDynamicTypePrefix($event.item)">
            </list-drop-down>

            <input class="form-control"
                   type="number"
                   placeholder="value"
                   [(ngModel)]="selected_values.DYNAMIC.value">

            <list-drop-down [button_title]="selected_values.DYNAMIC.suffix"
                            [items]="dynamic_suffixes"
                            (item_selected)="selectCustomDynamicTypeSuffix($event.item)">
            </list-drop-down>
          </div>
        </app-form-item>

      </div>

    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterLeft">
    </div>
    <div class="app-modal-btnFooterRight">
      <button class="-color-success"
              [disabled]="save_filter_disabled"
              (click)="addFilter()">
        Save Filter
      </button>
      <button class="-color-white-outline -desktop-only"
              (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
