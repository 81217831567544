import { Component, Inject, OnInit, ComponentRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { DomService } from '../../lib-services/dom/dom.service';
import { NotificationServiceInterface } from '../../lib-interfaces/notification-service.interface';
import { BackdropComponent } from '../backdrop/backdrop.component';
import { KmNotification } from '../../lib-models/km-notification/km-notification';
import { NotificationDropdownContentComponent } from './notification-dropdown-content/notification-dropdown-content.component';
import _ from 'lodash-es';

@Component({
  selector: 'notification-dropdown',
  templateUrl: './notification-dropdown.component.html',
  styleUrls: ['./notification-dropdown.component.scss']
})
export class NotificationDropdownComponent implements OnInit, OnDestroy {

  unseen_count: number = this.notificationService.getUnseenNotificationCount();

  notification_dropdown_content_ref: ComponentRef<NotificationDropdownContentComponent> = null;

  event_subscriptions: Subscription[] = [];

  constructor(
    @Inject('notificationService') public notificationService: NotificationServiceInterface,
    public domService: DomService
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  openDropdown() {
    this.domService.openMobileDropdown(NotificationDropdownContentComponent, 'end');
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.notificationService.getNotificationUpdatedEvent().subscribe(() => {
        this.unseen_count = this.notificationService.getUnseenNotificationCount();
      })
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

}
