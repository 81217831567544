import { Component, HostBinding, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';

@Component({
  selector: 'app-form-item',
  templateUrl: './app-form-item.component.html',
  styleUrls: ['./app-form-item.component.scss']
})
export class AppFormItemComponent implements OnChanges {

  @HostBinding('class') classes = 'app-formItem';
  @HostBinding('class.-floatingLabel') @Input() floating_label: boolean = false;
  @HostBinding('class.-disabled') @Input() disabled: boolean = false;
  @HostBinding('class.-readonly') @Input() readonly: boolean = false;
  @HostBinding('class.-noBorder') @Input() hide_border: boolean = false;
  @HostBinding('class.-noBorderWithoutFocus') @Input() hide_border_without_focus: boolean = false;
  @ViewChild('labelAnchor') label_anchor: any;

  @Input() label: string = '';
  @Input() label_right: string = null;
  @Input() label_right_content: boolean = false;
  @Input() whats_this: string = null;
  @Input() form_errors: PostableObjectErrorMap = {};
  @Input() input_has_value: boolean = false;
  @Input() mock_focus: boolean = false;

  // Comma seperated set of object properties
  // eg. 'project_rate,project_type'
  @Input() error_keys: string = null;

  error_key_list: string[] = [];

  clearErrorOnClick() {
    for (const error_key of this.error_key_list) {
      delete this.form_errors[error_key];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.error_keys) {
      this._updateErrorKeyList();
    }
  }

  private _updateErrorKeyList() {
    const error_keys = !!this.error_keys ? this.error_keys.replace(/\s/g, '') : null;
    this.error_key_list = error_keys?.split(',') || [];
  }

}
