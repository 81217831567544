<div ngbDropdown
     #ngb_dropdown="ngbDropdown"
     [container]="'body'"
     [display]="'static'"
     [autoClose]="'outside'"
     [dropdownClass]="'dropdown'"
     (openChange)="dropdownToggled($event)">
  <button class="-color-white-outline drp-button"
          ngbDropdownAnchor
          [class.-type-icon]="!start_date || !end_date"
          [disabled]="disabled"
          (click)="dropdownToggleClicked()">
    <ng-container *ngTemplateOutlet="button_content">
    </ng-container>
  </button>

  <div class="app-datepicker-dropdown drp-dropdown-content"
       ngbDropdownMenu>
    <ng-container *ngTemplateOutlet="dropdown_content"></ng-container>
  </div>
</div>

<ng-template #button_content>
  <i class="ion ion-md-calendar"></i>
  <ng-container *ngIf="!!start_date && !!end_date">
    <span>{{start_date | date : 'd MMM'}}</span>
    <span>-</span>
    <span>{{end_date | date : 'd MMM, yyyy'}}</span>
  </ng-container>
</ng-template>

<ng-template #dropdown_content>
  <ngb-datepicker #ngb_datepicker
                  [dayTemplate]="dropdown_day"
                  [dayTemplateData]="dayTemplateData"
                  [outsideDays]="'hidden'"
                  [displayMonths]="num_months"
                  (dateSelect)="onDateSelection($event)"
                  (navigate)="calendarMonthsChanged($event)">
  </ngb-datepicker>
</ng-template>

<ng-template #dropdown_day
             let-date
             let-data="data"
             let-today="today">
  <div class="drp-day-outer"
       [class.-inRange]="day_config[data.date_key].in_range"
       [class.-rangeStart]="day_config[data.date_key].range_start"
       [class.-rangeEnd]="day_config[data.date_key].range_end"
       [class.-firstDayOfMonth]="day_config[data.date_key].first_day_of_month"
       [class.-lastDayOfMonth]="day_config[data.date_key].last_day_of_month"
       [class.-today]="today"
       (mouseenter)="mouseMouse(date)">
    <div class="drp-day-mid">
      <div class="drp-day-inner">
        {{date.day}}
      </div>
    </div>
  </div>
</ng-template>
