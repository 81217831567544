import { Component, Input, OnInit } from '@angular/core';

import { ReportConfigTable, ReportConfigDimension, ReportConfigMeasure, ReportMeasurePermission } from '../../lib.types';
import { TableReport, TableReportMeasure } from '../../lib-models/report/table-report/table-report';
import { SortUtilService } from '../../lib-services/sort-util/sort-util.service';
import { ReportService } from '../../lib-services/report/report.service';

import _ from 'lodash-es';

@Component({
  selector: 'report-table-setting',
  templateUrl: './report-table-setting.component.html',
  styleUrls: ['./report-table-setting.component.scss']
})
export class ReportTableSettingComponent implements OnInit {

  readonly config = this.reportService.getConfig();

  @Input() report: TableReport;

  search: string = '';

  dimension_map: Record<string, ReportConfigDimension> = null;
  measure_map: Record<string, ReportConfigDimension> = null;

  report_dimension_map: Record<string, boolean> = null;
  report_measure_map: Record<string, Partial<Record<ReportMeasurePermission, boolean>>> = null;

  available_dimensions: ReportConfigDimension[] = [];
  available_measures: ReportConfigMeasure[] = [];

  visible_dimensions: ReportConfigDimension[] = [];
  visible_measures: ReportConfigMeasure[] = [];

  available_measure_function_map: Record<string, ReportMeasurePermission[]> = null;

  constructor(
    public reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.updateReportSets();
    this.updateAvilableMeasuresAndDimensions();
    this.reloadVisibleColumns();
  }

  reloadVisibleColumns() {
    const search = (this.search || '').toUpperCase();

    this.visible_dimensions = this.available_dimensions.filter((d) => {
      return !search || d.label.toUpperCase().indexOf(search) !== -1;
    });
    this.visible_measures = this.available_measures.filter((m) => {
      return !search || m.label.toUpperCase().indexOf(search) !== -1;
    });
  }

  addMeasure(measure: ReportConfigMeasure) {
    let func: ReportMeasurePermission = null;
    // Only measures already added to the report are added to available_measure_function_map
    if (!this.available_measure_function_map[measure.id]) {
      func = this.config.permissions.measures[0];
    }
    else if (!!this.available_measure_function_map[measure.id]?.length) {
      func = this.available_measure_function_map[measure.id][0];
    }

    if (func !== null) {
      this.report.measures.push({
        measure,
        func
      });

      this.updateReportSets();
      this.updateAvilableMeasuresAndDimensions();
    }
  }

  addDimension(dimension: ReportConfigDimension) {
    this.report.dimensions.push(dimension);

    this.updateReportSets();
    this.updateAvilableMeasuresAndDimensions();
  }

  removeMeasure(measure: TableReportMeasure) {
    _.remove(this.report.measures, (trm) =>
      trm.measure.id === measure.measure.id &&
      trm.func === measure.func
    );

    this.updateReportSets();
    this.updateAvilableMeasuresAndDimensions();
  }

  removeDimension(dimension: ReportConfigDimension) {
    _.remove(this.report.dimensions, (dim) => dim.id === dimension.id);

    this.updateReportSets();
    this.updateAvilableMeasuresAndDimensions();
  }

  selectFact(fact: ReportConfigTable) {
    this.report.table_config = fact;
    this.report.dimensions = [];
    this.report.measures = [];

    this.updateReportSets();
    this.updateAvilableMeasuresAndDimensions();
  }

  selectMeasureFunction(
    measure: TableReportMeasure,
    func: ReportMeasurePermission
  ) {
    measure.func = func;
    this.updateAvilableMeasuresAndDimensions();
  }

  updateAvilableMeasuresAndDimensions() {
    const available_dimensions: ReportConfigDimension[] = [];
    const available_measures: ReportConfigMeasure[] = [];

    if (!!this.report.table_config) {
      for (const dimension of this.report.table_config.dimensions) {
        if (!this.report_dimension_map[dimension.id]) {
          available_dimensions.push(dimension);
        }
      }
      for (const measure of this.report.table_config.measures) {
        if (!this._allFunctionsUsedForMeasure(measure.id)) {
          available_measures.push(measure);
        }
      }
    }

    this.available_dimensions = SortUtilService.sortList(available_dimensions, { primary_sort_property: 'label' });
    this.available_measures = SortUtilService.sortList(available_measures, { primary_sort_property: 'label' });

    this.updateAvailableMeasureFunctionMap();
    this.reloadVisibleColumns();
  }

  updateAvailableMeasureFunctionMap() {
    const available_measure_function_map = {};

    for (const tm of this.report.measures) {
      if (!available_measure_function_map[tm.measure.id]) {
        available_measure_function_map[tm.measure.id] = _.clone(this.config.permissions.measures);
      }

      _.remove(available_measure_function_map[tm.measure.id], (func) => func === tm.func);
    }

    this.available_measure_function_map = available_measure_function_map;
  }

  updateReportSets() {
    const report_dimension_map: Record<string, boolean> = {};
    const report_measure_map: Record<string, Partial<Record<ReportMeasurePermission, boolean>>> = {};

    for (const dimension of this.report.dimensions) {
      report_dimension_map[dimension.id] = true;
    }
    for (const tm of this.report.measures) {
      if (!report_measure_map[tm.measure.id]) {
        report_measure_map[tm.measure.id] = {};
      }
      report_measure_map[tm.measure.id][tm.func] = true;
    }

    this.report_dimension_map = report_dimension_map;
    this.report_measure_map = report_measure_map;
  }

  private _allFunctionsUsedForMeasure(measure_id: string): boolean {
    if (!!this.report_measure_map[measure_id]) {

      for (const func of this.config.permissions.measures) {
        if (!this.report_measure_map[measure_id][func]) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

}
