import _ from 'lodash-es';

import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';

import {
  ReportType,
  ReportConfigTable,
  Label,
  ReportDimensionPermission,
  ReportMeasurePermission,
  ReportDynamicPermission,
  ReportConfigDimension,
  ReportConfigMeasure
} from '../../lib.types';

export type ReportFilter = {
  args: any[]
  field: ReportConfigDimension | ReportConfigMeasure
  func: ReportDimensionPermission | ReportDynamicPermission
  inverse: boolean
}

export type ReportQuery = {
  fields: string[],
  filters: ReportFilter[],
  groups: string[],
  measures: { field: string, func: ReportMeasurePermission }[],
  report_is_table: boolean,
  table: string
};

export abstract class Report implements PostableObject<Report> {

  report_type: ReportType;
  table_config: ReportConfigTable;
  report_key: number;
  report_title: string;
  labels: Label[];
  filters: ReportFilter[];

  constructor(
    report_type: ReportType,
    table_config: ReportConfigTable,
    report_key: number,
    report_title: string,
    labels: Label[],
    filters: ReportFilter[]
  ) {
    this.report_type = report_type;
    this.table_config = table_config;
    this.report_key = report_key;
    this.report_title = report_title;
    this.labels = labels;
    this.filters = filters;
  }

  abstract report_type_label: string;

  abstract generateReportQuery(): ReportQuery;

  getErrors(report_title_required: boolean = true): PostableObjectErrorMap {
    const errors = {};

    if (!this.table_config) {
      errors['table_config'] = 'A table must be selected';
    }

    if (report_title_required) {
      if (!this.report_title) {
        errors['report_title'] = 'Report name required';
      }
      else if (this.report_title.length > 100) {
        errors['report_title'] = 'Report names have a 100 character limit';
      }
    }

    return errors;
  }

  hasErrors(report_title_required: boolean = true): boolean {
    return Object.keys(this.getErrors(report_title_required)).length > 0;
  }

  formatForPosting(to_delete: boolean = false): any {
    const filters = JSON.stringify(this.formatFiltersForPosting());
    const labels = JSON.stringify(this.labels);

    return {
      report_key: this.report_key,
      report_title: this.report_title,
      data_table: this.table_config.id,
      graph_function: null,
      graph_type: null,
      report_is_table: this.report_type === 'TABLE',
      labels,
      filters,
      series: null,
      deleted_flag: to_delete
    };
  }

  formatFiltersForPosting(): any[] {
    return this.filters.map(filter => {
      return {
        field: filter.field.id,
        args: filter.args,
        func: filter.func,
        inverse: filter.inverse
      };
    });
  }

}