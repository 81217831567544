import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

import { ItemBulletType } from '../../lib.types';

@Component({
  selector: 'item-bullet',
  templateUrl: './item-bullet.component.html',
  styleUrls: ['./item-bullet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemBulletComponent implements OnInit, OnChanges {

  @Input() bullet_type: ItemBulletType = null;
  @Input() tooltip_content: string = null;

  @HostBinding('style.background-color') @Input() bullet_colour: string = null;
  @HostBinding('class.-grayscale') @Input() bullet_grayscale = false;
  @HostBinding('class.-bordered') bordered = false

  @HostBinding('class.-type-line') bullet_type_line = true;
  @HostBinding('class.-type-dot') bullet_type_dot = false;
  @HostBinding('class.-type-square') bullet_type_square = false;

  ngOnInit(): void {
    if (!this.bullet_type) {
      this.bullet_type = this._getDefaultItemBulletType();
    }

    this.bullet_type_line = this.bullet_type === 'line';
    this.bullet_type_dot = this.bullet_type === 'dot';
    this.bullet_type_square = this.bullet_type === 'square';

    this.bordered = this.bullet_colour === null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.bullet_colour) {
      this.bordered = this.bullet_colour === null;
    }
    if (!!changes.bullet_type && !this.bullet_type) {
      this.bullet_type = this._getDefaultItemBulletType();
    }
  }

  private _getDefaultItemBulletType(): ItemBulletType {
    switch (CoreUtilService.app_theme) {
      case '-theme-invoxy':
        return 'line';
      case '-theme-karmly-dark':
      case '-theme-karmly-light':
        return 'dot';
    }
  }

}
