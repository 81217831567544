<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <i class="app-nav-icon bi bi-chat-dots"></i>
        <div class="app-nav-title">
          <div>Feedback</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <div class="app-formItem-container">
        <app-form-item class="app-formItem-textarea"
                       *ngIf="!submitted"
                       [label]="'Add your feedback here'"
                       [input_has_value]="!!feedback_comment"
                       [hide_border]="true">
          <textarea class="form-control"
                    [(ngModel)]="feedback_comment"> </textarea>
        </app-form-item>
      </div>

      <div class="app-animatedTick"
           *ngIf="!!submitted">
        <img src="assets/icons/invoxyTickAnimated.svg" />
      </div>
      <div *ngIf="!!submitted">Thanks for your feedback, we really appreciate you taking the time to tell us what you think.
            If you are looking for some help you can join our
            <a [href]="join_karmly_slack_link" target="_blank">
              Slack Community.
            </a>
      </div>
    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterLeft">

    </div>
    <div class="app-modal-btnFooterRight">
      <button class="-color-success"
              *ngIf="!submitted"
              (click)="submit()">
        Submit
      </button>
      <button class="-color-white-outline -desktop-only"
              (click)="cancel()">
        {{!submitted ? 'Cancel' : 'Close'}}
      </button>
    </div>
  </div>
</div>
