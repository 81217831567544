<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>Warning</div>
          <div class="app-nav-titleDescription">
            We encountered issues while sending some {{segment_type}} to {{integration_name}}
          </div>
          <div class="app-nav-titleDescription -error">
            {{failed_count}} / {{segments.length}} {{segment_type}} failed to send
          </div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <!-- <app-spinner *ngIf="loading"></app-spinner> -->
    <div class="app-approvalProject"
         *ngFor="let user of error_users">
      <div class="app-approvalProject-header">
        <div class="app-approvalProject-projectName">
          {{user.display_name}}
        </div>
      </div>
      <div class="app-approvalProject-unitGroups">
        <div class="app-approvalProject-unitGroup">
          <div class="app-approvalProject-unitGroup-segments">
            <div class="app-approvalProject-unitGroup-segment"
                 *ngFor="let segment of user.segments">

              <div class="-segment-date">
                {{segment.segment_date | date : 'EEE, d MMM'}}
              </div>
              <div class="-segment-details">
                <item-bullet [bullet_colour]="segment.task_colour"></item-bullet>
                <div class="-segment-detailsInner">
                  <div>
                    <div>{{segment.task_name}}</div>
                    <i class="ion ion-md-create"
                       *ngIf="!!segment.notes">
                      <tooltip-popover [tooltip_content]="segment.notes"></tooltip-popover>
                    </i>
                  </div>
                  <div *ngIf="!!segment.start_time || !!segment.break_duration">
                    <div *ngIf="!!segment.start_time">
                      {{segment.start_time | dateToTimePipe}} - {{segment.end_time | dateToTimePipe}}
                    </div>
                    <div class="-label"
                         *ngIf="!!segment.break_duration">
                      ({{(60 * segment.break_duration).toFixed(0)}} mins break)
                    </div>
                  </div>
                  <div class="-label"
                       *ngIf="segment.unit_flag && segment.units < 0">
                    CREDIT
                  </div>
                  <div class="-segment-error">{{segment.error}}</div>
                </div>
              </div>
              <div class="-segment-units">
                <segment-value [hide_hours_unit_type]="false"
                               [segment]="segment">
                </segment-value>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterRight">
      <button class="-color-white-outline"
              (click)="cancel()">
        Close
      </button>
    </div>
  </div>
</div>
