<div class="app-nav">
  <div class="app-navHeader">

    <div class="app-navRow">
      <div class="app-navRow-left"
           *ngIf="!!report">
        <div class="app-nav-title">
          <app-form-item>
            <input class="app-nav-titleInput"
                   placeholder="New report..."
                   [(ngModel)]="report.report_title">
          </app-form-item>
        </div>
      </div>
      <div class="app-navRow-right">
        <clock-dropdown *ngIf="PRODUCT === 'KARMLY' && !is_mobile"></clock-dropdown>
        <notification-dropdown *ngIf="PRODUCT === 'KARMLY'"></notification-dropdown>
        <uac-dropdown *ngIf="PRODUCT === 'KARMLY' || PRODUCT === 'INVOXY'"></uac-dropdown>
        <img *ngIf="PRODUCT === 'FLEXITIME'"
             class="app-navRow-logo"
             src="assets/product-imgs/flexitime/FlexiTime_WFM_Full.svg">
      </div>
    </div>

  </div>

  <div class="app-navToolbar">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <back-button></back-button>

        <ng-container *ngIf="!is_mobile && labels_enabled">
          <label-dropdown [labels]="all_labels"
                          [labelIsVisible]="labelIsVisible"
                          (add_label)="addLabel($event)">
          </label-dropdown>

          <label-panel [labels]="report.labels"
                       [enable_remove]="true"
                       (remove_label)="removeLabel($event)">
          </label-panel>
        </ng-container>
      </div>
      <div class="app-navRow-right">

        <app-spinner class="-flexSpinner"
                     *ngIf="!is_mobile && generating_report || loading">
        </app-spinner>

        <account-select-dropdown *ngIf="PRODUCT === 'FLEXITIME'"
                                 (accounts_selected)="accountsSelected()">
        </account-select-dropdown>

        <ng-container *ngIf="!is_mobile">
          <button class="-color-success"
                  [disabled]="loading"
                  (click)="validateReport()">
            Save
          </button>

          <button class="-type-icon"
                  [ngClass]="PRODUCT === 'KARMLY' ? '-color-primary-outline' : '-color-accent-outline'"
                  [disabled]="loading || generating_report"
                  (click)="generateReport()">
            <i class="ion ion-md-refresh"></i>
            <tooltip-popover [tooltip_content]="'Refresh Report'"></tooltip-popover>
          </button>
        </ng-container>

        <button class="-color-primary -type-icon-round -mobile-only -floatLeftOnMobile"
                (click)="openReportSettingModal()">
          <i class="ion ion-md-create"></i>
        </button>

        <list-drop-down [float_on_mobile]="true"
                        [button_type_class]="'-type-icon'"
                        [button_icon_class]="is_mobile ? 'ion-ios-add' : 'ion-ios-more'"
                        [button_color_class]="is_mobile ? '-color-success' : '-color-default-hoverFill'"
                        [item_label_prop]="'label'"
                        [item_text_colour_prop]="'text_colour'"
                        [item_toggleable_prop]="'toggleable'"
                        [item_toggle_value_prop]="'toggle_value'"
                        [disabled]="menu_dropdown.length === 0"
                        [sort_items]="false"
                        [items]="menu_dropdown"
                        (item_selected)="dropdownItemSelected($event.item)">
        </list-drop-down>
      </div>
    </div>

  </div>

  <div class="app-navWindow">
    <div class="app-navContent">

      <div class="reportEdit-sideMenu"
           *ngIf="!is_mobile"
           [ngClass]="{ '-menuClosed': !menu_dropdown_map.TOGGLE_MENU.toggle_value }">

        <app-tabs [tabs]="tabs"
                  [(selectedTab)]="selected_tab">
        </app-tabs>

        <div class="reportEdit-sideMenu-content">
          <report-table-setting *ngIf="selected_tab.value === 'TABLE'"
                                [report]="$any(report)">
          </report-table-setting>
          <report-chart-setting *ngIf="selected_tab.value === 'CHART'"
                                [report]="$any(report)">
          </report-chart-setting>
          <report-numeric-setting *ngIf="selected_tab.value === 'NUMERIC'"
                                  [report]="$any(report)">
          </report-numeric-setting>
          <report-filter-setting *ngIf="selected_tab.value === 'FILTER'"
                                 [report]="report"
                                 (filter_updated)="generateReport()"
                                 (filter_deleted)="generateReport()">
          </report-filter-setting>

          <div class="app-buttonRow"
               *ngIf="selected_tab.value === 'FILTER'">
            <div class="app-buttonRow-right">
              <button class="-color-success"
                      (click)="addNewFilter()">
                Add Filter
              </button>
            </div>
          </div>

        </div>

      </div>

      <div class="reportEdit-reportPanel">

        <report-chart *ngIf="!!report && report.report_type === 'CHART'"
                      [chart_type]="$any(report).graph_type"
                      [stack_series]="$any(report).series?.stack || false"
                      [show_legend]="$any(report).series?.show_legend || false"
                      [dimension_data]="query_data?.dimensions || []"
                      [measure_data]="query_data?.measures || []"
                      [series_data]="query_data?.series || []"
                      [report]="$any(report)">
        </report-chart>

        <report-table *ngIf="!!report && report.report_type === 'TABLE'"
                      [table_rows]="query_data"
                      [report]="$any(report)">
        </report-table>

        <report-numeric *ngIf="!!report && report.report_type === 'NUMERIC'"
                        [report_data]="query_data"
                        [report]="$any(report)">
        </report-numeric>

      </div>

    </div>
  </div>

</div>
