import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { StateDataService } from '../../../public-api';

import { Segment } from '../../lib-models/segment/segment';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { SegmentWeeklyTotals } from '../../lib.types';

@Component({
  selector: 'week-day-segment-totals',
  templateUrl: './week-day-segment-totals.component.html',
  styleUrls: ['./week-day-segment-totals.component.scss']
})
export class WeekDaySegmentTotalsComponent implements OnInit, OnChanges, OnDestroy {

  private _selected_week_update_subscription: Subscription = null;

  @Input() segments: Segment[] = [];
  @Input() selected_day_index: number = null;

  weekly_totals: SegmentWeeklyTotals = {
    days: [0, 0, 0, 0, 0, 0, 0],
    week: 0,
    unit_type: 'hours'
  };

  constructor(
    public stateDataService: StateDataService
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.segments) {
      this.refreshComponent();
    }
  }

  refreshComponent() {
    this.weekly_totals = TimeUtilService.calculateWeeklySegmentTotals(this.segments, this.stateDataService.selected_week_start);
  }

  private _initEventListeners() {
    this._selected_week_update_subscription = this.stateDataService.getSelectedWeekStartUpdatedEvent().subscribe(() => {
      this.refreshComponent();
    });
  }

  private _clearEventListeners() {
    this._selected_week_update_subscription?.unsubscribe();
  }

}
