import { ElementRef, EventEmitter } from '@angular/core';

import { PublicHoliday } from '../../../lib.types';
import { CoreUtilService } from '../../../lib-services/core-util/core-util.service';
import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { Segment } from '../../../lib-models/segment/segment';
import { PostableObjectErrorMap } from '../../../lib-interfaces/postable-object.interface';
import { AppModalInterface } from '../../../lib-interfaces/app-modal.interface';
import { DomService } from '../../../../public-api';

export abstract class SegmentModal implements AppModalInterface {

  readonly currency_code = CoreUtilService.currency_code;
  readonly currency_symbol = CoreUtilService.currency_symbol;
  readonly project_label = CoreUtilService.project_label;
  readonly task_label = CoreUtilService.task_label;

  readonly error_messages = {
    no_overlap: 'This time record can\'t overlap another record'
  };

  abstract notes_textarea: ElementRef;

  abstract close: EventEmitter<Segment>;
  abstract dismiss: EventEmitter<void>;

  title: string = null;
  editing_disabled: boolean = true;
  current_public_holiday: PublicHoliday = null;
  notes_open: boolean = false;

  segment_errors: PostableObjectErrorMap = {};

  // Inputs
  abstract segment: Segment;
  abstract segments: Segment[];
  abstract projects: any[];

  projectIsVisible = (project: any) => true;
  taskIsVisible = (task: any) => true;

  abstract _initTitle(): void;
  abstract projectSelected(project: any): void;

  constructor(
    public domService: DomService
  ) { }

  validateForm() {
    if (!!this.segment) {
      if (TimeUtilService.segmentOverlapsAnotherInList(this.segment, this.segments)) {
        this.domService.openNotificationPopover(this.error_messages.no_overlap, 'ERROR');
      }
      else {
        this.segment_errors = this.segment.getErrors();

        if (!this.segment.hasErrors()) {
          this._saveSegment();
        }
      }
    }
  }

  deleteSegment() {
    if (!!this.segment?.segment_key) {
      this._saveSegment(true);
    }
  }

  cancel() {
    this.dismiss.emit();
  }

  toggleNotes() {
    if (!!this.segment) {
      this.notes_open = !this.notes_open;
      if (this.notes_open) {
        setTimeout(() => {
          this.notes_textarea?.nativeElement.focus();
        });
      }
    }
  }

  _saveSegment(to_delete: boolean = false) {
    if (!!this.segment) {
      this.segment.deleted_flag = to_delete;
      this.close.emit(this.segment);
    }
  }

}
