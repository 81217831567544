import { Subject, Subscription } from 'rxjs';
import _ from 'lodash';

import { IntegrationRecord, IntegrationType, IntegrationTypeName, SocketEventType } from '../../../lib.types';
import { ApiService } from '../../../lib-services/api/api.service';
import { TimeUtilService } from '../../../lib-services/time-util/time-util.service';
import { SocketService } from '../../../lib-services/socket/socket.service';

export type IntegrationEventType = (
  'INTEGRATION_CONNECTED' |
  'INTEGRATION_CONNECTION_ERROR' |
  'INTEGRATION_DISCONNECTED' |
  'INTEGRATION_CHANGED'
);

export abstract class IntegrationServiceAbstract {

  abstract initialiseService(force_reload: boolean): Promise<void>;
  abstract clearServiceData(): void;
  abstract loadIntegrations(): Promise<void>;

  readonly integration_type_names: Record<IntegrationType, IntegrationTypeName> = {
    'STORECOVE': 'Storecove',
    'KEYPAY': 'Employment Hero',
    'XERO PAYROLL': 'Xero Payroll',
    'PAYHERO': 'PayHero',
    'BULLHORN': 'Bullhorn',
    'JOBADDER': 'JobAdder',
    'XERO': 'Xero',
    'POWER BI': 'Power BI',
    'APOSITIVE': 'APositive',
    'INVOXY': 'Invoxy',
    'ATO': 'ATO',
    'GOOGLE_CALENDAR': 'Google Calendar'
  };

  current_integrations: Partial<Record<IntegrationType, IntegrationRecord>> = {};
  socket_subscriptions: Partial<Record<SocketEventType, Subscription>> = {};

  integration_events: Record<IntegrationEventType, Subject<any>> = {
    INTEGRATION_CONNECTED: new Subject(),
    INTEGRATION_CONNECTION_ERROR: new Subject(),
    INTEGRATION_DISCONNECTED: new Subject(),
    INTEGRATION_CHANGED: new Subject()
  };

  service_setup: boolean = false;

  constructor(
    public apiService: ApiService,
    public socketService: SocketService
  ) { }

  initEventListeners() {
    this.socket_subscriptions = {};

    this.socket_subscriptions.INTEGRATION_CONNECTED = this.socketService.subscribeToEvent(
      'INTEGRATION_CONNECTED',
      (event_data) => {
        this.handleConnectEventData(event_data);
      });

    this.socket_subscriptions.INTEGRATION_CONNECTION_ERROR = this.socketService.subscribeToEvent(
      'INTEGRATION_CONNECTION_ERROR',
      (event_data) => {
        this.loadIntegrations()
          .finally(() => {
            this.integration_events.INTEGRATION_CONNECTION_ERROR.next(event_data);
            this.integration_events.INTEGRATION_CHANGED.next(event_data);
          });
      });

    this.socket_subscriptions.INTEGRATION_DISCONNECTED = this.socketService.subscribeToEvent(
      'INTEGRATION_DISCONNECTED',
      (event_data) => {
        this.handleDisconnectEventData(event_data);
      });
  }

  handleConnectEventData(event_data: any) {
    this.loadIntegrations()
      .finally(() => {
        this.integration_events.INTEGRATION_CONNECTED.next(event_data);
        this.integration_events.INTEGRATION_CHANGED.next(event_data);
      });
  }

  handleDisconnectEventData(event_data: any) {
    this.loadIntegrations()
      .finally(() => {
        this.integration_events.INTEGRATION_DISCONNECTED.next(event_data);
        this.integration_events.INTEGRATION_CHANGED.next(event_data);
      });
  }

  subscribeToEvent(
    event_type: IntegrationEventType,
    callback: (event_data: any) => void
  ): Subscription {
    if (!!this.integration_events[event_type]) {

      return this.integration_events[event_type]
        .asObservable()
        .subscribe((event_data) => callback(event_data));
    }
  }

  getIntegrations() {
    return _.clone(this.current_integrations);
  }

  isIntegrated(integration_type: IntegrationType): boolean {
    const integration = this.getIntegration(integration_type);
    return integration?.active_flag || false;
  }

  getIntegration(integration_type: IntegrationType): IntegrationRecord {
    return this.current_integrations[integration_type] || null;
  }

  getXeroAuthURL(karmly_flag: boolean = false): Promise<string> {
    return new Promise((resolve, reject) => {

      this.apiService.get('INVOXY', 'xero/auth', { karmly_flag })
        .then((res) => resolve(res))
        .catch(() => reject());
    });
  }

  // Expects an array of invoice keys
  // Sends invoice to xero only
  sendInvoiceToXero(invoice_keys: number[]) {
    return new Promise((resolve, reject) => {

      this.apiService.post('INVOXY', 'invoice/send/xero', { invoice_keys })
        .then((res) => resolve(res))
        .catch(() => reject());
    });
  }

  // Expects an array of invoice keys
  // Sends invoice to recipients only
  sendInvoiceToRecipients(invoice_keys: number[]) {
    return new Promise((resolve, reject) => {

      this.apiService.post('INVOXY', 'invoice/send/client', { invoice_keys })
        .then((res) => resolve(res))
        .catch(() => reject());
    });
  }

  disconnectXero(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.apiService.post('INVOXY', 'xero/disconnect')
        .then((res) => resolve(res))
        .catch(() => reject());
    });
  }

  setupIntegrations(data: any[]): Partial<Record<IntegrationType, IntegrationRecord>> {
    const integrations = {};

    for (const d of data) {
      integrations[d.integration_type] = {
        active_flag: d.active_flag,
        integration_type: d.integration_type,
        reference_id: d.reference_id,
        refresh_token: d.refresh_token,
        updated_date: TimeUtilService.utcDateTimeStringToDate(d.updated_date),
        integration_type_name: this.integration_type_names[d.integration_type],
        token_data: d.token_data
      };
    }

    return integrations;
  }

  clearEventListeners() {
    for (const event_type of Object.keys(this.socket_subscriptions)) {
      this.socket_subscriptions[event_type].unsubscribe();
    }
  }

}
