import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
export class DpCompanyAdmin implements PostableObject<DpCompanyAdmin> {

  user_access_company_key: number;
  person_key: number;
  registered_email: string;
  full_name: string;
  invite_expired: boolean;
  access_pending_flag: boolean;
  access_deactivated: boolean;
  owner_flag: boolean;
  pending_owner_flag: boolean;
  admin_flag: boolean;
  team_manager_flag: boolean;
  pay_rates_visible: boolean;

  access_string: string;
  role: string;
  resending_invite: boolean;
  resend_invite_message: string;

  constructor(
    user_access_company_key: number = null, person_key: number = null, registered_email: string = null,
    full_name: string = null, invite_expired: boolean = false, access_pending_flag: boolean = false,
    access_deactivated: boolean = false, owner_flag: boolean = false, pending_owner_flag: boolean = false,
    admin_flag: boolean = false, team_manager_flag: boolean = false, pay_rates_visible: boolean = false
  ) {
    this.user_access_company_key = user_access_company_key;
    this.person_key = person_key;
    this.registered_email = registered_email;
    this.full_name = full_name;
    this.invite_expired = invite_expired;
    this.access_pending_flag = access_pending_flag;
    this.access_deactivated = access_deactivated;
    this.owner_flag = owner_flag;
    this.pending_owner_flag = pending_owner_flag;
    this.admin_flag = admin_flag;
    this.team_manager_flag = team_manager_flag;
    this.pay_rates_visible = pay_rates_visible;

    this.access_string = this.getAccessString();
    this.role = this.getRole();
    this.resending_invite = false;
    this.resend_invite_message = 'Resend Invite';
  }

  getAccessString(): string {
    let accessString = '';

    // Role
    if (this.admin_flag) {
      accessString += 'Admin ';
    }
    else if (this.team_manager_flag) {
      accessString += 'Team Manager ';
    }

    // Status
    if (this.invite_expired) {
      accessString += 'Invite Expired';
    }
    else if (this.access_pending_flag) {
      accessString += 'Invited';
    }
    else if (this.access_deactivated) {
      accessString += 'Access Disabled';
    }
    else {
      accessString += 'Access Active';
    }

    return accessString;
  }

  getRole(): string {
    if (this.owner_flag) {
      return 'Owner';
    }

    if (this.admin_flag) {
      return 'Admin';
    }

    if (this.team_manager_flag) {
      return 'Team Manager';
    }
  }

  inviteSentMessage() {
    this.resend_invite_message = 'Invite Sent!';
    setTimeout(() => {
      this.resend_invite_message = 'Resend Invite';
    }, 2000);
  }

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    return errors;
  }
  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }


  formatForPosting(): any {
    if (this.hasErrors()) {
      return {
        user_access_company_key: this.user_access_company_key,
        pay_rates_visible: this.pay_rates_visible
      };
    }
    return null;
  }

}
