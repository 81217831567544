<div class="-value"
     *ngIf="!reverse_display">
  {{display_value}}&nbsp;
</div>
<div class="-unit"
     *ngIf="!hide_hours_unit_type || (display_unit !== 'hours' && display_unit !== 'hour')">
  {{display_unit}}
</div>
<div class="-value"
     *ngIf="reverse_display">
  &nbsp;{{display_value}}
</div>
