import { Component, OnInit, Input, ElementRef, HostBinding, Output, EventEmitter } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';

import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

@Component({
  selector: 'colour-picker',
  templateUrl: './colour-picker.component.html',
  styleUrls: ['./colour-picker.component.scss'],
  providers: [MakeProvider(ColourPickerComponent)]
})
export class ColourPickerComponent extends AbstractValueAccessor {

  _value: boolean;

  readonly palette: string[][] = CoreUtilService.colour_palette;

  @Input() disabled: boolean = false;
  @Input() disable_body_append: boolean = false;

}
