import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Report, ReportFilter } from '../../lib-models/report/report';
import { ReportService } from '../../lib-services/report/report.service';
import { LibModalService } from '../../lib-services/lib-modal/lib-modal.service';

@Component({
  selector: 'report-filter-setting',
  templateUrl: './report-filter-setting.component.html',
  styleUrls: ['./report-filter-setting.component.scss']
})
export class ReportFilterSettingComponent implements OnInit {

  @Input() report: Report;
  @Input() show_add_filter_button: boolean = true;

  @Output() filter_updated = new EventEmitter<ReportFilter>();
  @Output() filter_deleted = new EventEmitter<ReportFilter>();

  constructor(
    public reportService: ReportService,
    public libModalService: LibModalService
  ) { }

  ngOnInit(): void {
  }

  editFilter(filter_index: number) {
    this.libModalService.reportFilterModal(this.report, this.report.filters[filter_index])
      .then((filter) => {
        this.report.filters[filter_index] = filter;
        this.filter_updated.emit(filter);
      })
      .catch(() => { });
  }

  deleteFilter(filter_index: number) {
    const filter = this.report.filters[filter_index];
    this.report.filters.splice(filter_index, 1);
    this.filter_deleted.emit(filter);
  }

  formatDynamicFunctionName(name: string): string {
    return name.replace(/_/g, ' ').toLowerCase();
  }

}
