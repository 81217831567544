import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { ReportService } from '../../lib-services/report/report.service';
import { NumericReport } from '../../lib-models/report/numeric-report/numeric-report';
import { EnvService } from '../../lib-services/env/env.service';

@Component({
  selector: 'report-numeric',
  templateUrl: './report-numeric.component.html',
  styleUrls: ['./report-numeric.component.scss']
})
export class ReportNumericComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('report') canvas: ElementRef;

  @Input() report: NumericReport = null;
  @Input() report_data: any[] = [];

  @Input() animation_fps: number = 60;
  @Input() animation_duration = 500;

  readonly canvas_height = 1000;
  readonly canvas_width = 1500;
  private readonly _canvas_center_y = this.canvas_height / 2;
  private readonly _canvas_center_x = this.canvas_width / 2;

  readonly font_specs = {
    value: {
      size: this.canvas_width / 7,
      weight: 500,
      color: '#333'
    },
    label: {
      size: this.canvas_width / 14,
      weight: 400,
      color: '#999'
    },
    empty_value: {
      size: this.canvas_width / 20,
      weight: 400,
      color: '#999'
    }
  };

  private _renderer: any = null;
  private _context: any = null;

  report_measure_value: number = null;

  constructor(
    public reportService: ReportService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.report || !!changes.report_data) {
      const report_value_key = this.report.measure.id + '__' + this.report.graph_function;
      this.report_measure_value = this.report_data ? this.report_data[report_value_key] : null;

      this.renderReport();
    }
  }

  ngOnDestroy() {
    if (!!this._renderer) {
      clearInterval(this._renderer);
    }
  }

  renderReport() {
    if (!!this.canvas?.nativeElement) {
      this._renderer = this._render(this.canvas.nativeElement);
    }
  }

  private _render(canvas: any) {
    this._context = canvas.getContext('2d');
    this._context.lineWidth = 15;
    this._context.lineCap = 'round';
    this._context.textAlign = 'center';
    this._context.textBaseline = 'middle';

    const max_value = this.report_measure_value;
    const min_value = 0;

    const frame_time = 1000 / this.animation_fps;
    const frame_count = Math.round(this.animation_duration / frame_time);

    let current_value = null;
    let current_frame = 0;

    const renderer = setInterval(() => {
      this._context.clearRect(0, 0, this.canvas_width, this.canvas_height);
      current_frame++;

      if (this.report_measure_value !== null) {
        if (current_frame === frame_count) {
          current_value = this.report_measure_value;
        }
        else {
          current_value = Math.ceil((max_value - min_value) * (current_frame / frame_count));
        }
      }

      this._renderMeasureValue(current_value);
      this._renderMeasureLabel();
      this._renderMeasureUnitType();

      if (current_frame >= frame_count) {
        clearInterval(renderer);
      }
    }, frame_time);

    return renderer;
  }

  private _renderMeasureValue(current_value: number) {
    let text = 'No report data to show';

    const font_specs = this.report_measure_value === null ? this.font_specs.empty_value : this.font_specs.value;
    this._context.fillStyle = font_specs.color;
    this._context.font = font_specs.weight + ' ' + font_specs.size + 'px ' + EnvService.font_family;

    text = this.reportService.formatFieldValue(
      current_value,
      this.report.measure,
      true
    );

    this._context.fillText(
      text,
      this._canvas_center_x,
      this._canvas_center_y
    );
  }

  private _renderMeasureLabel() {
    if (this.report_measure_value !== null) {
      const font_specs = this.font_specs.label;
      this._context.fillStyle = font_specs.color;
      this._context.font = font_specs.weight + ' ' + font_specs.size + 'px ' + EnvService.font_family;

      const text = this.report.measure.label;

      this._context.fillText(
        text,
        this._canvas_center_x,
        this._canvas_center_y - (this.font_specs.value.size * 0.9)
      );
    }
  }

  private _renderMeasureUnitType() {
    if (this.report_measure_value !== null) {
      // Currently only used for days
      if (['DAYS', 'HOURS'].includes(this.report.measure.data_subtype)) {
        const font_specs = this.font_specs.label;
        this._context.fillStyle = font_specs.color;
        this._context.font = font_specs.weight + ' ' + font_specs.size + 'px ' + EnvService.font_family;

        const text = this.report.measure.data_subtype.toLowerCase();

        this._context.fillText(
          text,
          this._canvas_center_x,
          this._canvas_center_y + (this.font_specs.value.size * 0.8)
        );
      }
    }
  }

}
