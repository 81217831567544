import { CoreUtilService, BankValidatorService } from '../../../public-api';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { WeekDayFull, WeekDayShort } from '../../lib.types';

export type KmCompanyAvailability = {
  user_availability_key: number,
  weekday: WeekDayFull,
  weekday_short: WeekDayShort,
  hours: number
}

export type KmCompanySetting = {
  company_key: number,
  company_settings_key: KmCompanySettingKey,
  setting_key: string,
  setting_value: any
}

export type KmCompanySettings = Partial<Record<KmCompanySettingKey, KmCompanySetting>>;

export type KmCompanySettingKey = (
  'Next Invoice Number' |
  'Invoice Prefix' |
  'Income Tax Rate' |
  'Tax Rate' |
  'Tax Type' |
  'Company Logo' |
  'Week Start' |
  'Resume Link' |
  'Clock Limit' |
  'Fiscal Year Start'
)

export class KmCompany implements PostableObject<KmCompany> {

  company_settings: KmCompanySettings;
  availability: KmCompanyAvailability[];
  company_address: string;
  company_business_number: string;
  company_domain: string;
  company_key: number;
  company_logo: string;
  company_name: string;
  company_tax_number: string;
  contact_email: string;
  contact_name: string;
  country_key: string;
  created_on: Date;
  currency_code: string;
  currency_symbol: string;
  in_tutorial: boolean;
  sub_company_product_key: number;
  bank_account: string;

  constructor(
    company_settings: KmCompanySettings,
    availability: KmCompanyAvailability[],
    company_address: string,
    company_business_number: string,
    company_domain: string,
    company_key: number,
    company_logo: string,
    company_name: string,
    company_tax_number: string,
    contact_email: string,
    contact_name: string,
    country_key: string,
    created_on: Date,
    currency_code: string = 'USD',
    currency_symbol: string = '$',
    in_tutorial: boolean,
    sub_company_product_key: number,
    bank_account: string
  ) {
    this.company_settings = company_settings;
    this.availability = availability;
    this.company_address = company_address;
    this.company_business_number = company_business_number;
    this.company_domain = company_domain;
    this.company_key = company_key;
    this.company_logo = company_logo;
    this.company_name = company_name;
    this.company_tax_number = company_tax_number;
    this.contact_email = contact_email;
    this.contact_name = contact_name;
    this.country_key = country_key;
    this.created_on = created_on;
    this.currency_code = currency_code;
    this.currency_symbol = currency_symbol;
    this.in_tutorial = in_tutorial;
    this.sub_company_product_key = sub_company_product_key;
    this.bank_account = bank_account;
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (!this.company_name) {
      errors['company_name'] = 'Account name required';
    }
    if (!!this.contact_email && !CoreUtilService.emailIsValid(this.contact_email)) {
      errors['contact_email'] = 'Invalid contact email';
    }
    for (const day of this.availability) {
      if (!day.hours) {
        day.hours = 0;
      }
      if (day.hours < 0 || day.hours > 24) {
        errors['availability'] = 'Capacity must be between 0 and 24 hours';
      }
    }
    if (
      !!this.bank_account &&
      this.country_key === 'NZ' &&
      !BankValidatorService.nzBankAccountIsValid(this.bank_account)
    ) {
      errors['bank_account'] = 'Invalid bank account';
    }
    if (!this.company_settings['Resume Link'].setting_value) {
      errors['resume_link'] = 'Résumé link required';
    } else if (this.company_settings['Resume Link'].setting_value !== encodeURIComponent(this.company_settings['Resume Link'].setting_value)) {
      errors['resume_link'] = 'Résumé link cannot contain spaces or special characters';
    }

    if (!!this.company_tax_number && !CoreUtilService.irdNumberIsValid(this.company_tax_number, this.country_key))
      errors['company_number'] = 'Invalid GST Number';

    // Clock limit as a user setting has currently been shelved, but may be brought back

    // const clock_limit = this.company_settings['Clock Limit'].setting_value;

    // if (!!clock_limit && (!CoreUtilService.numberIsInt(clock_limit) || clock_limit < 1 || clock_limit > 24)) {
    //   errors['clock_limit'] = 'Whole number between 1 and 24 required';
    // }

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  formatForPosting(): any {
    return {
      karmly_flag: true,
      company_settings: this.formatCompanySettingsForPosting(),
      availability: this.availability,
      company_address: this.company_address,
      company_business_number: this.company_business_number,
      company_domain: this.company_domain,
      company_key: this.company_key,
      company_logo: this.company_logo,
      company_name: this.company_name,
      company_tax_number: this.company_tax_number,
      contact_email: this.contact_email,
      contact_name: this.contact_name,
      country_key: this.country_key,
      bank_account: this.bank_account
    };
  }

  formatCompanySettingsForPosting(): any[] {
    const company_settings = [];

    for (const setting_key of Object.keys(this.company_settings)) {
      const setting = this.company_settings[setting_key];

      company_settings.push({
        company_key: this.company_key,
        setting_key,
        company_settings_key: setting.company_settings_key,
        setting_value: setting.setting_value
      });
    }
    return company_settings;
  }

}
