<div class="app-list-item -header"
     #list_header
     *ngIf="visible_items.length !== 0 && tableHeaders?.length"
     [ngClass]="showHeaderOnMobile ? '' : '-desktop-only'">
  <div class="app-list-itemCell -noSort -rowToggle"
       *ngIf="itemToggleVisible && !!itemToggleIdProperty">
    <app-checkbox [(ngModel)]="all_items_toggled"
                  (ngModelChange)="toggleAllItems()">
    </app-checkbox>
  </div>

  <ng-container *ngFor="let header of tableHeaders; let i = index">
    <div class="app-list-itemCell"
         *ngIf="!header.hidden"
         [ngClass]="[
          (header.css_class || ''),
          (header.desktop_only ? '-desktop-only' : ''),
          (!header.property || sortDisabled ? '-noSort' : '')
         ]"
         (click)="tableHeaderClicked(header.property)">

      <!-- <tooltip-popover *ngIf="!!header?.label_tooltip"
                       [tooltip_content]="header.label_tooltip">
      </tooltip-popover> -->

      <div>{{header.label}}</div>
      <div class="-label"
           *ngIf="!!header.label_description">
        {{header.label_description}}
      </div>
      <whats-this *ngIf="!!header.whats_this"
                  [help_type]="'HOVER'"
                  [hover_text]="header.whats_this">
      </whats-this>
      <i class="ion"
         *ngIf="!sortDisabled && !!header.property && primarySortProperty === header.property"
         [ngClass]="{
          'ion-md-arrow-dropdown': forwardOrder,
          'ion-md-arrow-dropup': !forwardOrder }">
      </i>
    </div>
  </ng-container>

  <div class="app-list-itemCell -rowDelete"
       *ngIf="canDeleteItems">
  </div>
</div>

<cdk-virtual-scroll-viewport class="app-list-scrollOuter"
                             *ngIf="renderingStrategy === 'VIRTUAL' && visible_items.length !== 0"
                             #scroll_outer_viewport
                             [itemSize]="itemHeight"
                             [minBufferPx]="itemHeight * 3"
                             [maxBufferPx]="itemHeight * 6"
                             [ngStyle]="{ 'height': 2 + (itemHeight * visible_items.length) + 'px' }"
                             [id]="'app-list-scrollOuter_' + list_id"
                             infinite-scroll
                             [infiniteScrollContainer]="'#app-list-scrollOuter_' + list_id"
                             [infiniteScrollDistance]="infinite_scroll_distance"
                             [infiniteScrollUpDistance]="infinite_scroll_distance"
                             [fromRoot]="true"
                             (scrolled)="scrolledToBottom()"
                             (scrolledUp)="scrolledToTop()">
  <div class="app-list-item"
       *cdkVirtualFor="let item of visible_items; let i = index"
       [ngStyle]="{
          'min-height' : itemHeight + 'px',
          'opacity': virtual_scroll_loading ? 0 : ''
       }"
       [ngClass]="{
          '-itemSelectDisabled': itemSelectDisabled || (!!itemSelectEnabledProperty && !item[itemSelectEnabledProperty]) || (!!itemSelectDisabledProperty && !!item[itemSelectDisabledProperty])
       }"
       (click)="selectItem(item)">

    <ng-container *ngTemplateOutlet="itemContent; context: { item: item, index: i }">
    </ng-container>
  </div>
</cdk-virtual-scroll-viewport>

<div class="app-list-scrollOuter"
     *ngIf="renderingStrategy === 'INFINITE' && visible_items.length !== 0"
     #scroll_outer_elem
     [hidden]="visible_items.length === 0"
     [id]="'app-list-scrollOuter_' + list_id"
     infinite-scroll
     [infiniteScrollContainer]="'#app-list-scrollOuter_' + list_id"
     [infiniteScrollDistance]="infinite_scroll_distance"
     [infiniteScrollUpDistance]="infinite_scroll_distance"
     [fromRoot]="true"
     (scrolled)="scrolledToBottom()"
     (scrolledUp)="scrolledToTop()">
  <div class="app-list-scrollInner">

    <div class="app-list-bottomSpinnerContainer"
         *ngIf="show_infinite_loading_spinner && loading && reverseScroll">
      <app-spinner></app-spinner>
    </div>

    <div class="app-list-item"
         *ngFor="let item of visible_items; let i = index"
         [ngClass]="{
           '-itemSelectDisabled': itemSelectDisabled || (itemSelectEnabledProperty && !item[itemSelectEnabledProperty]) || (!!itemSelectDisabledProperty && !!item[itemSelectDisabledProperty])
         }"
         [ngStyle]="{ 'min-height' : !!itemHeight ? (itemHeight + 'px') : '' }"
         (click)="selectItem(item)">

      <ng-container *ngTemplateOutlet="itemContent; context: { item: item, index: i }">
      </ng-container>
    </div>

    <div class="app-list-bottomSpinnerContainer"
         *ngIf="show_infinite_loading_spinner && loading && !reverseScroll">
      <app-spinner></app-spinner>
    </div>

    <ng-container *ngIf="!!listEndTemplate">
      <ng-container *ngTemplateOutlet="listEndTemplate"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #itemContent
             let-item=item
             let-index=index>
  <div class="app-list-itemCell -rowToggle"
       *ngIf="itemToggleVisible && !!itemToggleIdProperty"
       [ngClass]="{ '-disabled': itemToggleDisabled }">
    <app-checkbox [disableInternalToggle]="true"
                  [ngModel]="toggled_item_map[item[itemToggleIdProperty]]"
                  (click)="toggleItem($event, item)">
    </app-checkbox>
  </div>
  <ng-container *ngTemplateOutlet="appListItemTemplate; context:{ $implicit: item, index: index }">
  </ng-container>
  <div class="app-list-itemCell -rowDelete"
       *ngIf="canDeleteItems"
       [ngClass]="{ '-disabled': itemDeleteDisabled }"
       (click)="deleteItem($event, item)">
    <i class="ion ion-md-close app-remove"
       [ngStyle]="{ 'display': itemDeleteHiddenProperty && item[itemDeleteHiddenProperty] ? 'none': '' }">
    </i>
  </div>
  <div class="app-list-itemCell -rowAdd"
       *ngIf="canAddItems"
       [ngClass]="{ '-disabled': itemAddDisabled }"
       (click)="addItem($event, item)">
    <i class="ion ion-md-add"></i>
  </div>
</ng-template>

<div class="app-list-empty"
     *ngIf="!loading && visible_items.length === 0">
  <div class="app-list-emptyHeader"
       *ngIf="!!list_empty_config.header">
    {{list_empty_config.header}}
  </div>
  <div class="app-list-emptyDescription"
       *ngIf="!!list_empty_config.description">
    {{list_empty_config.description}}
  </div>
  <button class="-color-success -type-icon app-list-emptyButton"
          *ngIf="!!list_empty_config.button_content"
          (click)="listEmptyButtonClicked.emit()">
    <i class="ion ion-ios-add"></i>
  </button>
  <!-- <button class="-color-success app-list-emptyButton"
          *ngIf="!!list_empty_config.button_content"
          (click)="listEmptyButtonClicked.emit()">
    {{list_empty_config.button_content}}
  </button> -->
</div>
