<div class="app-modal-container">
  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>Notifications</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <button class="-type-icon -color-default-clear -desktop-only"
                (click)="closeDropdown()">
          <i class="bi bi-arrow-bar-right"></i>
        </button>
        <i class="ion ion-md-close app-remove -mobile-only"
           (click)="closeDropdown()">
        </i>
      </div>
    </div>
  </div>
  <div class="app-modal-content">
    <div class="app-modal-contentInner -fullSize">
      <app-list class="-noBorder"
                #notification_list
                [renderingStrategy]="'INFINITE'"
                [itemLoadStrategy]="'ASYNCRONOUS'"
                [listEmptyDescription]="'You\'re all up to date'"
                [itemType]="'notification'"
                [itemTypePlural]="'notifications'"
                [itemSelectDisabledProperty]="'read_only'"
                [items]="notifications"
                (loadMoreItemsAsync)="loadMoreNotifications()"
                (itemSelected)="notificationButtonClicked($event.item)">
        <ng-template appListItem
                     let-notification>
          <div class="nd-notification"
               *ngIf="!notification.deleted_flag">
            <div class="nd-notification-header">
              <div class="nd-notification-title">
                <div class="nd-notification-unseenIcon"
                     *ngIf="!notification.seen_flag"></div>
                <div [ngStyle]="{
                    color: !notification.read_only? 'var(--theme-color-success)' : ''   }">
                  <div> {{ notification.notification_title }} </div>
                </div>
              </div>
              <div class="nd-notification-date"> {{ notification.notification_date_label }} </div>
            </div>
            <div class="nd-notification-description"
                 [innerHTML]="notification.description"></div>
          </div>
          <div class="nd-notification-clearContainer">
            <i class="ion ion-md-close app-remove"
               (click)="deleteNotification($event, notification)">
            </i>
          </div>
        </ng-template>
      </app-list>
    </div>
  </div>
</div>
