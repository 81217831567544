import _ from 'lodash-es';
import { DateTime, Interval } from 'luxon';

import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { CoreUtilService } from '../../../public-api';

export type SegmentTimeValues = {
  start_time: Date,
  end_time: Date,
  break_duration: number
  units: number
};

export abstract class Segment implements PostableObject<Segment> {

  readonly segment_key: number;

  deleted_flag: boolean;
  row_version: string;

  readonly credit_flag: boolean;
  unit_flag: boolean;

  private _segment_date: Date = null;
  private _start_time: Date = null;
  private _end_time: Date = null;
  private _duration: number = null;
  private _break_duration: number = null;
  private _units: number = null;

  _unit_type: string;
  _is_custom_unit: boolean;

  readonly is_ph_segment: boolean;
  readonly is_inv_segment: boolean;
  readonly is_dp_segment: boolean;

  constructor(
    is_ph_segment: boolean,
    is_inv_segment: boolean,
    is_dp_segment: boolean,
    segment_key: number,
    deleted_flag: boolean,
    row_version: string,
    unit_flag: boolean,
    credit_flag: boolean,
    segment_date: Date,
    start_time: Date,
    end_time: Date,
    break_duration: number,
    units: number
  ) {
    this.is_ph_segment = is_ph_segment;
    this.is_inv_segment = is_inv_segment;
    this.is_dp_segment = is_dp_segment;

    this.segment_key = segment_key;
    this.deleted_flag = deleted_flag;
    this.row_version = row_version;

    this.unit_flag = unit_flag;
    this.credit_flag = credit_flag;

    this._segment_date = TimeUtilService.getCleanDate(segment_date);

    if (this.unit_flag) {
      this._start_time = null;
      this._end_time = null;
      this._duration = null;
      this._break_duration = null;

      this._units = units || 0;
    }
    else {
      this._start_time = TimeUtilService.getCleanTime(start_time || new Date());
      this._end_time = TimeUtilService.getCleanTime(end_time || new Date());

      if (!!this._start_time && !!this._end_time) {
        this._duration = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time) - break_duration;
      }
      this._break_duration = break_duration;

      this._units = null;

      if (this.duration > 24) {
        throw new Error('Segment duration can\'t be greater than 24 hours');
      }
    }
  }

  abstract get colour(): string;
  abstract get work_name(): string;
  abstract get is_locked(): boolean;

  abstract _updateUnitType(): void;
  abstract formatForPosting(): any;

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (!this.unit_flag) {
      if (!TimeUtilService.dateIsValid(this.start_time)) {
        errors['start_time'] = 'Start time required';
      }
      if (!TimeUtilService.dateIsValid(this.end_time)) {
        errors['end_time'] = 'End time required';
      }
      if (!this.duration) {
        errors['duration'] = 'Duration required';
      }
    }
    else {
      if (!this.units) {
        const unit_type = this.unit_type === 'expense' ? CoreUtilService.currency_symbol : this.unit_type;
        errors['units'] = unit_type + ' required';
      }
    }

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  //////////////////////////////////////////////////////////////

  get unit_type(): string {
    return this._unit_type;
  }
  get is_custom_unit(): boolean {
    return this._is_custom_unit;
  }

  get segment_date(): Date {
    return this._segment_date;
  }
  set segment_date(segment_date: Date) {
    if (TimeUtilService.dateIsValid(segment_date)) {
      segment_date = TimeUtilService.getCleanDate(segment_date);

      this._segment_date = segment_date;

      this._updateTimesOnDateChange();
    }
  }

  get start_time(): Date {
    return this._start_time;
  }
  set start_time(start_time: Date) {
    if (!this.unit_flag && TimeUtilService.dateIsValid(start_time)) {
      start_time = TimeUtilService.getCleanTime(start_time);

      this._start_time = TimeUtilService.updateDatePortionOfDateTime(start_time, this.segment_date);
      this._end_time = TimeUtilService.updateDatePortionOfDateTime(this._end_time, this._start_time);
      this._checkEndTimeValidIfMultiDaySegment();

      this._updateDurationOnTimeChange();
    }
  }

  get end_time(): Date {
    return this._end_time;
  }
  set end_time(end_time: Date) {
    if (!this.unit_flag && TimeUtilService.dateIsValid(end_time)) {
      end_time = TimeUtilService.getCleanTime(end_time);

      this._end_time = TimeUtilService.updateDatePortionOfDateTime(end_time, this.segment_date);
      this._checkEndTimeValidIfMultiDaySegment();

      this._updateDurationOnTimeChange();
    }
  }

  get duration(): number {
    return this._duration;
  }
  set duration(duration: number) {
    if (!this.unit_flag && TimeUtilService.numberIsValid(duration)) {
      if (duration > 24) {
        duration = 24;
      }
      else if (duration < 0) {
        duration = 0;
      }

      this._duration = duration;

      if (this._start_time && this._end_time) {
        // If sum of duration and break are more than 24 hours,
        // reduce duration so that the sum is exactly 24 hours
        if (this._duration + this._break_duration > 24) {
          this._duration = 24 - this._break_duration;
        }

        // Update end time to match new duration values
        const totalHoursMins = TimeUtilService.hoursDecimalAsHoursAndMinutes(this._duration + this._break_duration);
        const totalHours = totalHoursMins[0];
        const totalMins = totalHoursMins[1];

        let newEnd = _.cloneDeep(this._start_time);
        newEnd = TimeUtilService.incrementHours(newEnd, totalHours);
        newEnd = TimeUtilService.incrementMinutes(newEnd, totalMins);

        this._end_time = newEnd;
      }
    }
  }

  get break_duration(): number {
    return this._break_duration;
  }
  set break_duration(break_duration: number) {
    if (!this.unit_flag && TimeUtilService.numberIsValid(break_duration)) {
      const totalDurationDec = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time);
      // Ensure break duration isn't greater than the total segment duration
      if (break_duration > totalDurationDec) {
        break_duration = totalDurationDec;
      }

      this._break_duration = break_duration;
      this._duration = totalDurationDec - this._break_duration;
    }
  }

  get units(): number {
    return this._units;
  }
  set units(units: number) {
    if (this.unit_flag && TimeUtilService.numberIsValid(units)) {
      if (
        (this.credit_flag && units > 0) ||
        (!this.credit_flag && units < 0)
      ) {
        units = 0;
      }
      this._units = units;
    }
  }

  private _updateDurationOnTimeChange() {
    const totalDurationDec = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time);

    // If new total duration is less than break duration,
    // we need to reset break duration
    if (totalDurationDec < this._break_duration) {
      this._break_duration = 0;
    }

    this._duration = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time) - (this._break_duration);
  }

  overlapsPeriodOfDays(_period_start: Date, _period_end: Date) {
    const period_start = DateTime.fromJSDate(_period_start).startOf('day');
    const period_end = DateTime.fromJSDate(_period_end).endOf('day');

    if (this.unit_flag) {
      const segment_date = DateTime.fromJSDate(this.segment_date).startOf('day');
      return segment_date >= period_start && segment_date <= period_end;
    }
    else {
      const period = Interval.fromDateTimes(period_start, period_end);

      const start_time = DateTime.fromJSDate(this.start_time);
      const end_time = DateTime.fromJSDate(this.end_time);
      const segment_period = Interval.fromDateTimes(start_time, end_time);

      return period.overlaps(segment_period);
    }
  }

  private _updateTimesOnDateChange() {
    if (!this.unit_flag) {
      this._start_time = TimeUtilService.updateDatePortionOfDateTime(this._start_time, this.segment_date);
      this._end_time = TimeUtilService.updateDatePortionOfDateTime(this._end_time, this.segment_date);
      this._checkEndTimeValidIfMultiDaySegment();
    }
  }

  private _checkEndTimeValidIfMultiDaySegment() {
    if (!this.unit_flag) {
      if (TimeUtilService.endTimeLessThanStartTime(this._start_time, this._end_time)) {
        const next_day = TimeUtilService.incrementDate(this._segment_date, 1);
        this._end_time = TimeUtilService.updateDatePortionOfDateTime(this._end_time, next_day);
      }
    }
  }

  // Only to be used by Segment classes that extend this base class
  // Can be used as a way to reinitialise values without triggering validation
  reinitSegmentTimeValues(values: SegmentTimeValues): void {
    this._start_time = values.start_time;
    this._end_time = values.end_time;
    this._break_duration = values.break_duration;
    this._units = values.units;

    if (!!this._start_time && !!this._end_time) {
      this._duration = TimeUtilService.differenceBetweenTwoDatesAsHoursDecimal(this._start_time, this._end_time) - this._break_duration;
    }
    else {
      this._duration = null;
    }
  }

}
