import { Component, Inject, Input, OnInit } from '@angular/core';
import { CoreUtilService } from '../../../public-api';

export type ThumbnailGraphicType = (
  'PROJECT' | 'PROJECT_TASK' | 'REPORT' | 'CLIENT' | 'TASK' | 'TIME' | 'EXPENSE' | 'INVOICE' | 'RESUME'
);

@Component({
  selector: 'thumbnail-graphic',
  templateUrl: './thumbnail-graphic.component.html',
  styleUrls: ['./thumbnail-graphic.component.scss']
})
export class ThumbnailGraphicComponent implements OnInit {

  @Input() thumbnail_type: ThumbnailGraphicType = null;

  graphic_url: string = null;

  constructor(
    @Inject('env') public env: any
  ) { }

  ngOnInit(): void {
    this._updateThumbnailGraphic();
  }

  private _updateThumbnailGraphic() {
    let graphic_url: string = null;

    switch (this.env.product) {
      case 'KARMLY': {
        graphic_url = 'assets/product-imgs/karmly/graphics/thumbnails/';

        switch (this.thumbnail_type) {
          case 'CLIENT':
            graphic_url += 'Karmly_Thumbnail_01.svg';
            break;
          case 'EXPENSE':
            graphic_url += 'Karmly_Thumbnail_02.svg';
            break;
          case 'PROJECT':
            graphic_url += 'Karmly_Thumbnail_03.svg';
            break;
          case 'REPORT':
            graphic_url += 'Karmly_Thumbnail_04.svg';
            break;
          case 'TASK':
            graphic_url += 'Karmly_Thumbnail_05.svg';
            break;
          case 'TIME':
            graphic_url += 'Karmly_Thumbnail_06.svg';
            break;
          case 'INVOICE':
            graphic_url += 'Karmly_Thumbnail_07.svg';
            break;
          case 'RESUME':
            graphic_url += 'Karmly_Thumbnail_08.svg';
            break;
          case 'PROJECT_TASK':
            graphic_url += 'Karmly_Thumbnail_09.svg';
            break;
          default:
            graphic_url = CoreUtilService.getRandomKarmlyThumbnail();
        }
      }
    }

    this.graphic_url = graphic_url;
  }

}
