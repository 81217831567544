import { ChangeDetectionStrategy, Component, Inject, Input, Output, EventEmitter, HostListener, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import _ from 'lodash-es';

import { DomService, InvoiceTemplateServiceInterface, RedactorService } from '../../../../public-api';

import { KmInvoice, KmInvoiceLine } from '../../../lib-models/km-invoice/km-invoice';
import { CoreUtilService } from '../../../lib-services/core-util/core-util.service';
import { KmProjectStub } from '../../../lib-models/km-project-stub/km-project-stub';
import { ProjectServiceInterface } from '../../../lib-interfaces/project-service.interface';
import { ListDropDownComponent } from '../../../lib-components/list-drop-down/list-drop-down.component';

@Component({
  selector: 'invoice-editor-line',
  templateUrl: './invoice-editor-line.component.html',
  styleUrls: ['./invoice-editor-line.component.scss']
})
export class InvoiceEditorLineComponent {

  readonly currency_symbol = CoreUtilService.currency_symbol;

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.is_mobile !== DomService.is_mobile) {
      this.is_mobile = DomService.is_mobile;
      setTimeout(() => this._initLineDescriptionEditor());
    }
  }

  @ViewChild('line_project_dropdown') line_project_dropdown: ListDropDownComponent;
  @ViewChild('line_description_col') line_description_col: ElementRef;

  @Input() invoice: KmInvoice;
  @Input() line: KmInvoiceLine;

  @Output() quantity_changed = new EventEmitter<{ line: KmInvoiceLine }>();
  @Output() rate_changed = new EventEmitter<{ line: KmInvoiceLine }>();
  @Output() remove_invoice_line = new EventEmitter<{ line: KmInvoiceLine }>();

  project: KmProjectStub = null;

  show_quantity: boolean = true;
  show_rate: boolean = true;
  show_total: boolean = true;

  line_description_editor: any = null;
  line_errors: string[] = [];

  constructor(
    @Inject('invoiceTemplateService') public invoiceTemplateService: InvoiceTemplateServiceInterface,
    @Inject('projectService') public projectService: ProjectServiceInterface
  ) { }

  ngOnInit() {
    this._initLineConfig();
  }

  ngAfterViewInit() {
    this._initLineDescriptionEditor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.line && !changes.line.firstChange) {
      this._initLineDescriptionEditor();
    }
  }

  private _initLineConfig() {
    if (!!this.line.project_key) {
      this.project = this.projectService.getProjectStub(this.line.project_key);
    }

    this.show_quantity = true;
    this.show_rate = true;
    this.show_total = true;

    switch (this.line.line_type) {
      case 'Text':
        this.show_quantity = false;
        this.show_rate = false;
        this.show_total = false;
        break;
      case 'Project':
        this.show_quantity = false;
        break;
      case 'Project_Task':
        this.show_quantity = false;
        break;
    }
  }

  updateLineErrors() {
    this.line_errors = Object.values(this.invoice.getLineErrors(this.line));
  }

  lineDescriptionFocus() {
    this.line_description_editor.editor.setFocus('end');
  }

  private _initLineDescriptionEditor() {
    if (!this.is_mobile) {
      this.line_description_editor = RedactorService.createEditor(
        '#line-editor-' + this.line.line_index,
        'invoice-line',
        this.line.line_description,
        null,
        this.invoice.posted_flag,
        { blur: () => this._lineDescriptionUpdated() }
      );
    }
  }

  private _lineDescriptionUpdated() {
    this.updateModelValuesFromRedactorEditors();
  }

  updateModelValuesFromRedactorEditors() {
    if (!!this.line_description_editor) {
      this.line.line_description = this.line_description_editor?.editor.getContent();
    }
  }

  updateRedactorEditorsFromModelValues() {
    if (!!this.line_description_editor) {
      this.line_description_editor.editor.setContent({ html: this.line.line_description });
    }
  }

}
