import { SafeResourceUrl } from '@angular/platform-browser';

import { DpPersonAvailability } from '../dp-person-availability/dp-person-availability';
import { PostableObject, PostableObjectErrorMap } from './../../lib-interfaces/postable-object.interface';
import { TimeUtilService } from './../../lib-services/time-util/time-util.service';
import { PersonUtilService } from '../../lib-services/person-util/person-util.service';
import { DpPersonSkill } from '../dp-person-skill/dp-person-skill';
import { DpSkill } from '../dp-skill/dp-skill';

import * as _ from 'lodash-es';
//import { ThrowStmt } from '@angular/compiler';
export class DpPerson implements PostableObject<DpPerson> {

  person_key: number;
  display_name: string;
  first_name: string;
  last_name: string;
  initials: string = null;
  email: string;
  phone: string;
  address: string;
  employment_start_date: Date;
  max_hours_per_week: number;
  pay_rate: number;
  roster_emails_disabled: boolean = false;

  prefer_mon: number;
  prefer_tue: number;
  prefer_wed: number;
  prefer_thu: number;
  prefer_fri: number;
  prefer_sat: number;
  prefer_sun: number;

  img_url: SafeResourceUrl;

  payhero_connected: boolean;

  xero_employee_id: string;
  export_id: string;

  skills: DpPersonSkill[];
  person_availability: DpPersonAvailability[];

  archived_flag: boolean;

  profile_image: string;

  user_access_flag: boolean = false;

  constructor(
    person_key: number = null, display_name: string = '', first_name: string = '', last_name: string = '',
    email: string = '', phone: string = '', address: string = '', employment_start_date: string = null,
    max_hours_per_week: number = 40, pay_rate: number = 0, prefer_mon: number = 3, prefer_tue: number = 3,
    prefer_wed: number = 3, prefer_thu: number = 3, prefer_fri: number = 3, prefer_sat: number = 3,
    prefer_sun: number = 3, img_url: any = null, payhero_connected: boolean = false, xero_employee_id: string = '',
    export_id: string = null, skills: DpPersonSkill[] = [], person_availability: DpPersonAvailability[] = null,
    archived_flag: boolean = false, profile_image: string = null, roster_emails_disabled: boolean = false, user_access_flag: boolean = false
  ) {

    this.person_key = person_key;
    this.display_name = display_name;
    this.first_name = first_name;
    this.last_name = last_name;
    this.initials = this.display_name.toUpperCase().split(' ').map((n)=>n[0]).join('');
    this.email = email;
    this.phone = phone;
    this.address = address;
    this.employment_start_date = !!employment_start_date ? TimeUtilService.dateStringToDate(employment_start_date) : new Date();
    this.max_hours_per_week = max_hours_per_week;
    this.pay_rate = pay_rate;
    this.prefer_mon = prefer_mon;
    this.prefer_tue = prefer_tue;
    this.prefer_wed = prefer_wed;
    this.prefer_thu = prefer_thu;
    this.prefer_fri = prefer_fri;
    this.prefer_sat = prefer_sat;
    this.prefer_sun = prefer_sun;
    this.img_url = img_url;
    this.payhero_connected = payhero_connected;
    this.xero_employee_id = xero_employee_id;
    this.export_id = export_id;
    this.skills = skills;
    this.person_availability = this._initPersonAvailability(person_availability);
    this.archived_flag = archived_flag;
    this.profile_image = profile_image;
    this.roster_emails_disabled = roster_emails_disabled;
    this.user_access_flag = user_access_flag;
  }


  getPersonSkill(skill_key: number) {
    const personSkillIndex = PersonUtilService.getPersonSkillIndex(this, skill_key);

    if (personSkillIndex !== -1) {
      return this.skills[personSkillIndex];
    }

    return null;
  }

  addPersonSkill(skill: DpSkill) {
    const personSkillIndex = PersonUtilService.getPersonSkillIndex(this, skill.skill_key);

    if (personSkillIndex === -1) {
      this.skills.push(new DpPersonSkill(null, this.person_key, skill));
    }
    else {
      this.skills[personSkillIndex].deleted_flag = false;
    }
    // Ensure change detections are triggered
    this.skills = _.clone(this.skills);
  }

  removePersonSkill(skill_key: number) {
    const personSkillIndex = PersonUtilService.getPersonSkillIndex(this, skill_key);

    if (personSkillIndex !== -1) {
      this.skills[personSkillIndex].deleted_flag = true;
    }
    // Ensure change detections are triggered
    this.skills = _.clone(this.skills);
  }

  hasSkill(skill: DpSkill): boolean {
    let hasSkill = false;
    this.skills.forEach(ps => {
      if (ps.skill.skill_key === skill.skill_key) {
        return hasSkill = true;
      }
    });
    return hasSkill;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    /*
    for (const person_skill of this.skills){
      const person_skill_errors = person_skill.getErrors();

      for (const error of person_skill_errors){
        errors.push({
          error_path: 'skills?person_skill_key=' + person_skill.person_skill_key + '.' + error.error_path,
          error_message: error.error_message
        });
      }
    }
    

    for (const person_availability of this.person_availability){
      const person_availability_errors = person_availability.getErrors();

      for (const error of person_availability_errors){
        errors.push({
          error_path: 'person_availability?person_availability_key=' + person_availability.person_availability_key + '.' + error.error_path,
          error_message: error.error_message
        });
      }
    }

    if (!this.display_name) {
      errors.push({
        error_path: 'display_name',
        error_message: 'Please enter a display name'
      });
    }
    if (!this.first_name) {
      errors.push({
        error_path: 'first_name',
        error_message: 'Please enter a first name'
      });
    }
    if (!this.last_name) {
      errors.push({
        error_path: 'last_name',
        error_message: 'Please enter a last name'
      });
    }
    if (!TimeUtilService.dateIsValid(this.employment_start_date)) {
      errors.push({
        error_path: 'employment_start_date',
        error_message: 'Please enter a valid employment start date'
      });
    }
    if (!TimeUtilService.numberIsValid(this.pay_rate) || this.pay_rate < 0) {
      errors.push({
        error_path: 'pay_rate',
        error_message: 'Please enter a valid cost'
      });
    }
    if (!TimeUtilService.numberIsValid(this.max_hours_per_week) || this.max_hours_per_week < 0) {
      errors.push({
        error_path: 'max_hours_per_week',
        error_message: 'Please enter a valid max hours per week'
      });
    }*/

    return errors;
  }
  

  formatForPosting(toArchive: boolean = false): any {
    if (this.hasErrors()) {
      const formated_skills = [];

      for (const person_skill of this.skills) {
        const fps = person_skill.formatForPosting();

        if (fps !== null) {
          formated_skills.push(person_skill.formatForPosting());
        }
      }

      const formatted_person_availability = [];

      for (const person_availability of this.person_availability) {
        const fpa = person_availability.formatForPosting();

        if (fpa !== null) {
          formatted_person_availability.push(fpa);
        }
        // All person_availability records must be valid
        else {
          return null;
        }
      }

      return {
        person_key: this.person_key,
        display_name: this.display_name,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone: this.phone,
        address: this.address,
        employment_start_date: this.employment_start_date,//TimeUtilService.formatDateForPosting(this.employment_start_date),
        max_hours_per_week: this.max_hours_per_week,
        pay_rate: this.pay_rate,
        prefer_mon: this.prefer_mon,
        prefer_tue: this.prefer_tue,
        prefer_wed: this.prefer_wed,
        prefer_thu: this.prefer_thu,
        prefer_fri: this.prefer_fri,
        prefer_sat: this.prefer_sat,
        prefer_sun: this.prefer_sun,
        export_id: this.export_id,
        xero_employee_id: this.xero_employee_id,
        person_availability: formatted_person_availability,
        archived_flag: toArchive
      };
    }
    return null;
  }


  formatRestrictedForPosting(toArchive: boolean = false): any {
    return {
      person_key: this.person_key,
      email: this.email,
      phone: this.phone,
      roster_emails_disabled: this.roster_emails_disabled,
      archived_flag: toArchive
    };
  }

  private _initPersonAvailability(person_availability: DpPersonAvailability[] = null): DpPersonAvailability[] {
    if (person_availability === null) {
      person_availability = [];
      const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

      for (let i = 0; i < 7; i++) {
        person_availability.push(new DpPersonAvailability(days[i]));
      }
    }
    return person_availability;
  }

}
