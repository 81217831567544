<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-left">
        <div class="app-nav-title">
          <div>Timer</div>
        </div>
      </div>
      <div class="app-navRow-right">
        <i class="ion ion-md-close app-remove"
           (click)="cancel()">
        </i>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <div class="app-formItem-container"
           *ngIf="!!clock">

        <app-form-item [label]="'I\'m working on...'"
                       [hide_border]="true">
          <textarea class="app-modal-textarea -notesOpen"
                    type="text"
                    [(ngModel)]="clock.notes[0].note_content"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (ngModelChange)="updateActiveClockNote()">
          </textarea>
        </app-form-item>

        <app-form-item [label]="project_task_label.capitalised"
                       [floating_label]="true"
                       [input_has_value]="!!clock.project_task"
                       [form_errors]="clock_out_errors"
                       [error_keys]="'project_task'"
                       [disabled]="!projects?.length">

          <list-drop-down #project_task_dropdown
                          [button_title]="clock.project_task?.project_task_name"
                          [disabled]="!projects?.length"
                          [list_fill_width]="true"
                          [show_search]="true"
                          [dynamic_creation_enabled]="true"
                          [dynamic_creation_child_items]="!!selected_project ? tasks : projects"
                          [dynamic_creation_child_item_config]="!!selected_project ? task_dropdown_item_config : pt_creation_project_dropdown_item_config"
                          [sort_items]="false"
                          [items]="project_task_dropdown_items"
                          [item_config]="project_task_dropdown_item_config"
                          (item_selected)="projectTaskSelected($event.item)"
                          (dynamic_creation_item_selected)="createNewProjectTask($event.item_name, $event.child_items[0], $event.child_items[1])">
          </list-drop-down>
        </app-form-item>

        <app-form-item [form_errors]="clock_out_errors"
                       [floating_label]="true"
                       [input_has_value]="!!selected_project"
                       [label]="project_label.capitalised"
                       [error_keys]="'project'">

          <list-drop-down #project_dropdown
                          ngbAutofocus
                          [button_title]="selected_project?.project_name"
                          [button_bullet_colour]="selected_project?.project_colour"
                          [button_show_completed_icon]="selected_project?.completed_flag"
                          [list_fill_width]="true"
                          [show_search]="true"
                          [dynamic_creation_enabled]="true"
                          [items]="projects"
                          [item_config]="project_dropdown_item_config"
                          (item_selected)="projectSelected($event.item)"
                          (dynamic_creation_item_selected)="createNewProject($event.item_name)">
          </list-drop-down>
        </app-form-item>

      </div>

      <active-clock-list [active_clock_editing_enabled]="false"
                         [active_clock_stop_enabled]="true"
                         [active_clock_delete_enabled]="true"
                         [active_clock_hide_details]="true"
                         [clockOutOverrideFunction]="stopActiveClockWrapper"
                         [deleteActiveClockOverrideFunction]="deleteActiveClockWrapper">
      </active-clock-list>

    </div>
  </div>

  <button *ngIf="!!clock.clock_key"
          [disabled]="clockService.timer_active_project_task_seconds < interruption_disabled_delay_seconds"
          class="-color-green-hoverFill kmcm-interruption"
          (click)="startInterruption()">
    <i class="ion ion-ios-play"></i>
    <span>Interruption</span>
  </button>

  <div class="app-modal-btnFooter"
       *ngIf="!!clock && !clock.clock_key">
    <div class="app-modal-btnFooterRight">
      <button class="-color-green-translucent kmcm-startTimer"
              *ngIf="!clock.clock_key"
              (click)="startClock()">
        <i class="ion ion-ios-play"></i>
        <span>Start Timer</span>
      </button>
    </div>
  </div>
</div>
