export class KmNote {
  note_key: number;
  segment_key: number;
  clock_key: number;
  note_content: string;

  deleted_flag: boolean = false;

  constructor(
    note_key: number = null,
    segment_key: number = null,
    clock_key: number = null,
    note_content: string = '',
    deleted_flag: boolean = false
  ) {
    this.note_key = note_key;
    this.segment_key = segment_key;
    this.clock_key = clock_key;
    this.note_content = note_content;
    this.deleted_flag = deleted_flag;
  }


  formatForPosting(): any {
    if (!!this.deleted_flag && !this.note_key) {
      return null;
    }
    else {
      return {
        note_key: this.note_key,
        segment_key: this.segment_key,
        clock_key: this.clock_key,
        note_content: this.note_content,
        deleted_flag: this.deleted_flag
      };
    }
  }
}
