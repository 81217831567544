import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { StateDataService } from '../../lib-services/state-data/state-data.service';

import { PublicHoliday } from '../../lib.types';

@Component({
  selector: 'week-day-tabs',
  templateUrl: './week-day-tabs.component.html',
  styleUrls: ['./week-day-tabs.component.scss']
})
export class WeekDayTabsComponent implements OnInit, OnDestroy {

  @HostBinding('class.-selectionDisabled') @Input() date_selection_disabled: boolean = false;
  @HostBinding('class.-highlightDisabled') @Input() tab_highlight_disabled: boolean = false;
  @Input() highlightBackground: boolean = false;

  selected_week = this.stateDataService.selected_week;

  private _selected_week_update_subscription: Subscription = null;

  @Input() selectedDayIndex: number = null;
  @Output() selectedDayIndexChange = new EventEmitter<number>();

  @Input() public_holidays: PublicHoliday[] = [];

  constructor(
    public stateDataService: StateDataService
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  selectDay(day_index: number) {
    if ((!this.date_selection_disabled && day_index !== this.selectedDayIndex) || this.tab_highlight_disabled) {
      this.selectedDayIndex = day_index;
      this.selectedDayIndexChange.emit(this.selectedDayIndex);
    }
  }

  private _initEventListeners() {
    this._selected_week_update_subscription = this.stateDataService.getSelectedWeekStartUpdatedEvent().subscribe(() => {
      this.selected_week = this.stateDataService.selected_week;
    });
  }

  private _clearEventListeners() {
    this._selected_week_update_subscription?.unsubscribe();
  }

}
