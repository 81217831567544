import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { DpPerson } from '../dp-person/dp-person';
import { PostableObject, PostableObjectErrorMap } from './../../lib-interfaces/postable-object.interface';

import * as _ from 'lodash-es';

export class DpPersonLeave implements PostableObject<DpPersonLeave> {

  person_leave_key: number;
  person: DpPerson;

  private _start_time: Date;
  private _end_time: Date;
  private _leave_date: Date;

  private _start_is_part_day: boolean;
  private _end_is_part_day: boolean;
  private _is_single_day_leave: boolean;

  constructor(
    person: DpPerson, start_time: string | Date, end_time: string | Date, leave_key: number = null
  ) {
    this.person = person;
    this.person_leave_key = leave_key;

    this._initTimesAndDates(start_time, end_time);
  }

  get start_is_part_day(): boolean {
    return this._start_is_part_day;
  }
  set start_is_part_day(is_part_day: boolean) {
    this._start_is_part_day = is_part_day;

    if (!this._start_is_part_day) {
      this._start_time = TimeUtilService.getCleanDate(this._start_time);
    }
  }

  get end_is_part_day(): boolean {
    return this._end_is_part_day;
  }
  set end_is_part_day(is_part_day: boolean) {
    this._end_is_part_day = is_part_day;

    if (!this._end_is_part_day) {
      this._end_time = TimeUtilService.updateTime(this._end_time, 23, 59);
    }
  }

  get is_single_day_leave(): boolean {
    return this._is_single_day_leave;
  }

  get leave_date(): Date {
    return this._leave_date;
  }
  set leave_date(leave_date: Date) {
    if (TimeUtilService.dateIsValid(leave_date)) {
      TimeUtilService.cleanDate(leave_date);

      this._leave_date = leave_date;

      this._updateTimesOnDateChange();
    }
  }

  get start_time(): Date {
    return this._start_time;
  }
  set start_time(start_time: Date) {
    if (TimeUtilService.dateIsValid(start_time)) {
      if (!this._start_is_part_day) {
        TimeUtilService.cleanTime(start_time);
      }

      this._start_time = start_time;
      this._leave_date = TimeUtilService.getCleanDate(start_time);
      //this._ensureEndTimeAfterStartTime();
      //this._updateIsSingleDayLeave();
    }
  }

  get end_time(): Date {
    return this._end_time;
  }
  set end_time(end_time: Date) {
    if (TimeUtilService.dateIsValid(end_time)) {
      if (!this._end_is_part_day) {
        end_time.setHours(23, 59, 0, 0);
      }

      this._end_time = end_time;
      //this._ensureEndTimeAfterStartTime();
      //this._updateIsSingleDayLeave();
    }
  }


  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    return errors;
  }

  formatForPosting(toDelete: boolean = false): any {
    if (this.hasErrors()) {
      return {
        person_leave_key: this.person_leave_key,
        person_key: this.person.person_key,
        start_time: TimeUtilService.dateToDateTimeString(this.start_time),
        end_time: TimeUtilService.dateToDateTimeString(this.end_time),
        deleted_flag: toDelete
      };
    }
    return null;
  }

  private _initTimesAndDates(start_time: string | Date, end_time: string | Date): void {
    this._start_time = typeof start_time === 'string' ? TimeUtilService.dateTimeStringToDate(start_time) : start_time;
    this._end_time = typeof end_time === 'string' ? TimeUtilService.dateTimeStringToDate(end_time) : end_time;
    this._leave_date = _.cloneDeep(this._start_time);

    TimeUtilService.cleanTime(this._start_time);
    TimeUtilService.cleanTime(this._end_time);
    TimeUtilService.cleanDate(this._leave_date);

    this._updateStartIsPartDay();
    this._updateEndIsPartDay();
    //this._updateIsSingleDayLeave();
  }

  private _updateTimesOnDateChange() {
    this._start_time = TimeUtilService.updateDatePortionOfDateTime(this._start_time, this._leave_date);
    //this._ensureEndTimeAfterStartTime();
  }
  /*
  private _updateIsSingleDayLeave() {
    this._is_single_day_leave = moment(this._start_time).isSame(moment(this._end_time), 'date');
  }*/
  private _updateStartIsPartDay() {
    this._start_is_part_day = this._start_time.getHours() !== 0 || this._start_time.getMinutes() !== 0;
  }
  private _updateEndIsPartDay() {
    this._end_is_part_day = this._end_time.getHours() !== 23 || this._end_time.getMinutes() !== 59;
  }
  /*
  private _ensureEndTimeAfterStartTime() {
    if (TimeUtilService.endDateTimeLessThanStartDateTime(this._start_time, this._end_time)) {
      TimeUtilService.updateDatePortionOfDateTime(this._end_time, this._leave_date);
      this._end_time = TimeUtilService.updateDate(this._end_time, this._start_time.getDate() + 1);
    }
  }*/

}
