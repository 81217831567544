import { Injectable } from '@angular/core';
import { NotificationServiceInterface } from 'flexitime-library';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements NotificationServiceInterface {

  private _notification_updated_event = new Subject<void>();

  constructor() { }

  getNotifications(): any[] {
    return [];
  }
  getUnseenNotificationCount(): number {
    return 0;
  }

  notificationButtonClicked(notification: any): void {
  }

  getNotificationUpdatedEvent(): Observable<void> {
    return this._notification_updated_event.asObservable();
  }

  markAllNotificationsAsSeen(): Promise<void> {
    return Promise.resolve();
  }

  deleteFauxNotification(notification: any): void {
  }

  deleteNotifications(notifications: any[]): Promise<void> {
    return Promise.resolve();
  }

  loadMoreNotifications(reload_list: boolean): Promise<any[]> {
    return Promise.resolve([]);
  }

}
