<div class="app-modal-container">

  <div class="app-navHeader">
    <div class="app-navRow">
      <div class="app-navRow-center">
        <i class="ion ion-md-alert app-modal-headerIcon"></i>
      </div>
    </div>
  </div>

  <div class="app-modal-content">
    <div class="app-modal-contentInner">

      <div class="app-modal-contentInfo">
        <div *ngIf="!!message && !message_is_html">
          {{message}}
        </div>
        <div class="app-modal-contentInfo"
           *ngIf="!!message && !!message_is_html">
          <div [innerHTML]="message | safeHtml"></div>
        </div>
        <div *ngFor="let message of messages">
          {{message}}
        </div>
      </div>

    </div>
  </div>

  <div class="app-modal-btnFooter">
    <div class="app-modal-btnFooterRight">
      <button class="-color-white-outline"
              (click)="cancel()">
        Ok
      </button>
    </div>
  </div>
</div>
