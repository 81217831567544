import { AfterViewInit, Component, HostBinding, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import _ from 'lodash-es';

import { AppTheme, NavMenuItemChild, NavMenuExternalItem, _NavMenuItem, BannerConfig } from '../../lib.types';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { DomService } from '../../lib-services/dom/dom.service';
import { StateChangeService } from '../../lib-services/state-change/state-change.service';
import { StateDataService } from '../../lib-services/state-data/state-data.service';
import { CompanyServiceInterface } from '../../lib-interfaces/company-service.interface';
import { NotificationServiceInterface } from '../../lib-interfaces/notification-service.interface';
import { StateAccessServiceInterface } from '../../lib-interfaces/state-access-service.interface';
import { AuthService } from '../../lib-services/auth/auth.service';
import { ProductService, NavMenuServiceAbstract, BannerComponent } from '../../../public-api';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './app-nav-menu.component.html',
  styleUrls: ['./app-nav-menu.component.scss']
})
export class AppNavMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly product = this.env.product;
  readonly responsive_ui = this.product === 'KARMLY' || this.product === 'DROPPAH' || this.product === 'FLEXITIME';

  readonly bottom_nav = this.product === 'DROPPAH';
  readonly side_nav = this.product === 'KARMLY' || this.product === 'INVOXY';
  readonly no_nav = this.product === 'FLEXITIME';

  readonly logo_icon = this.productService.getProductLogo('ICON', false);
  readonly logo_text = this.productService.getProductLogo('TEXT', false, true);

  @ViewChild(BannerComponent) banner: BannerComponent;

  is_mobile = DomService.is_mobile;

  @HostListener('window:resize', ['$event'])
  onResize() {
    // Desktop -> mobile
    if (!this.is_mobile && DomService.is_mobile) {
      this.closeMenu();
    }
    // Mobile -> desktop
    else if (this.is_mobile && !DomService.is_mobile) {
      this.openMenu();
    }
    if (this.is_mobile !== DomService.is_mobile){
      this.is_mobile = DomService.is_mobile;
      this.clock_footer_height = this._getClockFooterHeight();
    }
  }

  @HostBinding('style.--app-navMenu-footerHeight') clock_footer_height: string = this._getClockFooterHeight();

  private _menu_closed: boolean = this.is_mobile || this.stateDataService.getCachedComponentSessionData('AppNavMenuComponent', 'menu_closed') || false;
  get menu_closed(): boolean {
    return this._menu_closed;
  }
  set menu_closed(val: boolean) {
    this._menu_closed = val;
    this.stateDataService.cacheComponentSessionData('AppNavMenuComponent', 'menu_closed', this._menu_closed);
    this.navMenuService.menuToggled(this._menu_closed);
    this._updateHighlightedNavMenuItem();
  }

  app_theme: AppTheme = CoreUtilService.app_theme;
  list_drop_down_color_class: string = this._getListDropDownColorClass();
  menu_toggle_color_class: string = this._getMenuToggleColorClass();

  version_number: string;

  nav_menu: _NavMenuItem[] = [];
  external_nav_menu: NavMenuExternalItem[] = [];

  active_item_path: string;
  active_item_child_path: string;
  active_path_is_stickieable: boolean = false;
  active_path_is_non_menu: boolean = false;

  selected_nav_menu_item: _NavMenuItem = null;

  highlighted_nav_menu_item: _NavMenuItem = null;
  highlighted_nav_menu_item_child: NavMenuItemChild = null;
  highlighted_nav_menu_item_child_visible: boolean = true;

  banner_config: BannerConfig = {
    type: 'INTERACTIVE_DEMO',
    message: 'Thanks for trying our Karmly demo! Take it for a spin to see how it all works. ',
    hide_disabled: true
  };

  interactive_demo = this.companyService.isInteractiveDemo();

  event_subscriptions: Subscription[] = [];

  initialised: boolean = false;

  unseen_count: number = this.notificationService.getUnseenNotificationCount();

  constructor(
    public meta: Meta,
    public route: ActivatedRoute,
    public router: Router,
    @Inject('env') public env: any,
    @Inject('navMenuService') public navMenuService: NavMenuServiceAbstract,
    @Inject('companyService') public companyService: CompanyServiceInterface,
    @Inject('stateAccessService') public stateAccessService: StateAccessServiceInterface,
    @Inject('notificationService') public notificationService: NotificationServiceInterface,
    public stateDataService: StateDataService,
    public stateChangeService: StateChangeService,
    public authService: AuthService,
    public productService: ProductService,
    public domService: DomService
  ) { }

  ngOnInit(): void {
    this._initEventListeners();
    this.stateAccessReloaded();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (!!this.interactive_demo) {
        this.banner.showBanner();
      }
      this.initialised = true;
    });
  }

  ngOnDestroy(): void {
    this._clearEventListeners();
  }

  private _getClockFooterHeight() {
    return this.is_mobile && this.product === 'KARMLY' ? '70px' : '0px';
  }

  private _updateSelectedNavMenuItem() {
    let nav_menu_item = this.selected_nav_menu_item;

    if (!!this.selected_nav_menu_item) {
      let selected_item_valid = false;

      for (const item of this.nav_menu) {
        if (!!item && item.path === this.active_item_path) {
          selected_item_valid = true;
        }
      }

      if (!selected_item_valid) {
        nav_menu_item = null;
      }
    }

    if (!nav_menu_item) {
      for (const item of this.nav_menu) {
        if (!!item && item.path === this.active_item_path) {
          this.selected_nav_menu_item = item;
        }
      }
    }
  }
  back() {
    this.stateChangeService.back();
  }

  toggleMenu() {
    this.menu_closed = !this.menu_closed;
  }

  openMenu() {
    this.menu_closed = false;
  }

  closeMenu() {
    this.menu_closed = true;
  }

  externalItemSelected(item: NavMenuExternalItem) {
    if (!!item.itemSelected) {
      item.itemSelected();
    }
  }

  itemSelected(item: _NavMenuItem) {
    if (!item) {
      return;
    }
    if (item.children.length === 1 || item.children_always_collapsed) {
      const child = this._getStickedMenuItemChild(item.path) || item.children[0];
      this.goToState(item.path + '/' + child.path);

      if (this.is_mobile) {
        this.closeMenu();
      }
    }
    else if (this.menu_closed) {
      this.goToState(item.path);

      if (this.is_mobile) {
        this.closeMenu();
      }
    }

    this.selected_nav_menu_item = item;
    this._updateHighlightedNavMenuItem();
  }

  itemChildSelected(item: _NavMenuItem, item_child: NavMenuItemChild) {
    this.stateDataService.setStickiedMenuPath(item.path, item_child.path);

    this.goToState(item.path + '/' + item_child.path);
    if (this.is_mobile) {
      this.closeMenu();
    }
  }

  checkNonMenuPath() {
    this.active_path_is_non_menu = this.navMenuService.checkNonMenuPath(this.active_item_path);
  }

  goToState(path: string) {
    this.router.navigate([path]);
  }

  stateAccessReloaded() {
    this.version_number = this.env.app_version;
    this.nav_menu = this.navMenuService.generateNavMenu();
    this.external_nav_menu = this.navMenuService.generateExternalNavMenu();

    this._updateActivePath();
    this._updateStickiedPath();
  }

  manualLogout() {
    this.authService.logout();
  }

  private _updateActivePath() {
    const url_segment_paths = this.stateChangeService.getUrlSegmentPaths();

    this.active_item_path = url_segment_paths[0];
    this.active_item_child_path = url_segment_paths.length > 1 ? url_segment_paths[1] : null;

    this.checkNonMenuPath();
    this._updateSelectedNavMenuItem();
    this._updateHighlightedNavMenuItem();
  }

  private _updateStickiedPath() {
    this.active_path_is_stickieable = false;

    if (!!this.active_item_path && !!this.active_item_child_path) {

      for (const menu_item of this.nav_menu) {
        if (!!menu_item && menu_item.path === this.active_item_path) {

          for (const child of menu_item.children) {
            if (child.path === this.active_item_child_path) {

              this.stateDataService.setStickiedMenuPath(
                this.active_item_path,
                this.active_item_child_path
              );
              this.active_path_is_stickieable = true;
            }
          }
        }
      }
    }
  }

  private _getStickedMenuItemChild(item_path: string): NavMenuItemChild {
    const item = _.find(this.nav_menu, { path: item_path });

    if (!!item && !!item.children) {
      const child_path = this.stateDataService.getStickiedMenuPath(item.path);
      const item_child = _.find(item.children, { path: child_path });

      return item_child || null;
    }
    return null;
  }

  private _initEventListeners() {
    this.event_subscriptions.push(
      this.router.events
        .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
        .subscribe((event) => {
          this._updateActivePath();
          this._updateStickiedPath();
        })
    );

    this.event_subscriptions.push(
      this.stateAccessService.getStateAccessReloadedEvent().subscribe(() => {
        setTimeout(() => this.stateAccessReloaded());
      })
    );

    if (this.is_mobile) {
      this.event_subscriptions.push(
        this.notificationService.getNotificationUpdatedEvent().subscribe(() => {
          this.unseen_count = this.notificationService.getUnseenNotificationCount();
        })
      );
    }

    this.event_subscriptions.push(
      CoreUtilService.getAppThemeUpdatedEvent().subscribe(() => {
        setTimeout(() => {
          this._appThemeUpdated();
        });
      })
    );

    this.event_subscriptions.push(
      this.navMenuService.subscribeToEvent(
        'TOGGLE_NAV_MENU',
        () => this.toggleMenu()
      )
    );
  }

  private _clearEventListeners() {
    this.event_subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private _appThemeUpdated() {
    this.app_theme = CoreUtilService.app_theme;
    this.list_drop_down_color_class = this._getListDropDownColorClass();
    this.menu_toggle_color_class = this._getMenuToggleColorClass();
  }

  private _getListDropDownColorClass() {
    switch (this.app_theme) {
      case '-theme-invoxy':
      case '-theme-karmly-dark':
        return '-color-white-clear';
      case '-theme-karmly-light':
        return '-color-default-clear';
    }
  }

  private _getMenuToggleColorClass() {
    switch (this.app_theme) {
      case '-theme-invoxy':
      case '-theme-karmly-dark':
        return '-color-white-clear';
      case '-theme-karmly-light':
        return '-color-grey-clear';
    }
  }

  private _updateHighlightedNavMenuItem() {
    this.highlighted_nav_menu_item = null;
    this.highlighted_nav_menu_item_child = null;

    for (const item of this.nav_menu) {
      if (!!item && item.path === this.active_item_path) {
        this.highlighted_nav_menu_item = item;

        if (!!item.children) {
          for (const item_child of item.children) {
            // Matches child's primary path
            if (item_child.path === this.active_item_child_path) {
              this.highlighted_nav_menu_item_child = item_child;
              break;
            }
          }

          // Couldn't find a matching primary path,
          // so check for matching associated paths
          if (!this.highlighted_nav_menu_item_child) {
            // Need to account for the fact that multiple children may share an associated path
            const children_with_associated_path = [];

            for (const item_child of item.children) {
              if (
                !!item_child.associated_paths &&
                item_child.associated_paths.indexOf(this.active_item_child_path) !== -1
              ) {
                children_with_associated_path.push(item_child);
              }
            }

            if (children_with_associated_path.length === 1) {
              this.highlighted_nav_menu_item_child = children_with_associated_path[0];
            }
            if (children_with_associated_path.length > 1) {
              const previous_route_path_index = this.stateChangeService.getNextBackRoutePathIndex();

              // Try and match the highlighted nav item child to the previous route in route_back_stack
              if (previous_route_path_index !== null) {
                const previous_route_path = this.stateDataService.route_back_stack[previous_route_path_index];
                const path_segments = this.stateChangeService.getUrlSegmentPaths(previous_route_path);

                if (path_segments[0] === this.active_item_path) {
                  for (const child of children_with_associated_path) {
                    if (child.path === path_segments[1]) {
                      this.highlighted_nav_menu_item_child = child;
                    }
                  }
                }
              }
              // Fallback to first child item
              if (!this.highlighted_nav_menu_item_child) {
                this.highlighted_nav_menu_item_child = children_with_associated_path[0];
              }
            }
          }
        }
      }
    }

    this.highlighted_nav_menu_item_child_visible = true;
    if (!!this.selected_nav_menu_item &&
      !!this.highlighted_nav_menu_item) {
      if (
        this.selected_nav_menu_item.children_always_collapsed ||
        this.selected_nav_menu_item.path !== this.highlighted_nav_menu_item.path ||
        this.highlighted_nav_menu_item?.children.length <= 1 ||
        this.menu_closed
      ) {
        this.highlighted_nav_menu_item_child_visible = false;
      }
    }

  }

  oldInvoxy() {
    let url = 'http://invoxy-development.azurewebsites.net/#!/loginExternal?urlParams=';
    url = url + this.authService.getUrlParams();
    window.open(url, '_blank');
  }
}
