import { Component, HostListener, Input } from '@angular/core';
import { StateChangeService, DomService } from '../../../public-api';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.is_mobile = DomService.is_mobile;
  }

  @Input() routes_to_skip: string[] = [];
  @Input() query_params: any = {};

  constructor(
    public stateChangeService: StateChangeService
  ) { }

  back() {
    this.stateChangeService.back(this.routes_to_skip, this.query_params);
  }

}
