import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

@Pipe({
  name: 'secondsToTimePipe',
})
export class SecondsToTimePipe implements PipeTransform {

  transform(seconds: number) {
    return Duration.fromObject({ seconds }).toFormat('hh:mm');
  }
}
