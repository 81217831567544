import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LibPipesModule } from '../lib-pipes/lib-pipes.module';
import { LibComponentsModule } from '../lib-components/lib-components.module';
import { LibDirectivesModule } from '../lib-directives/lib-directives.module';

import { ErrorModalComponent } from './error-modal/error-modal.component';
import { UnsavedChangesModalComponent } from './unsaved-changes-modal/unsaved-changes-modal.component';
import { DashGridBlockModalComponent } from './dash-grid-block-modal/dash-grid-block-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { ReportFilterModalComponent } from './report-filter-modal/report-filter-modal.component';
import { ArchiveProjectModalComponent } from './archive-project-modal/archive-project-modal.component';
import { UpdateSegmentRateModalComponent } from './update-segment-rate-modal/update-segment-rate-modal.component';
import { ReportSelectorModalComponent } from './report-selector-modal/report-selector-modal.component';
import { FileUploaderModalComponent } from './file-uploader-modal/file-uploader-modal.component';
import { KmClockModalComponent } from './km-clock-modal/km-clock-modal.component';
import { ModalWrapperComponent } from './modal-wrapper/modal-wrapper.component';
import { OffcanvasWrapperComponent } from './offcanvas-wrapper/offcanvas-wrapper.component';
import { ReportSettingModalComponent } from './report-setting-modal/report-setting-modal.component';
import { FeedbackProviderModalComponent } from './feedback-provider-modal/feedback-provider-modal.component';
import { InvoiceLineEditorModalComponent } from './invoice-line-editor-modal/invoice-line-editor-modal.component';
import { SentTimeErrorModalComponent } from './sent-time-error-modal/sent-time-error-modal.component';
import { ExpandingCanvasWrapperComponent } from './expanding-canvas-wrapper/expanding-canvas-wrapper.component';

@NgModule({
  declarations: [
    ModalWrapperComponent,
    OffcanvasWrapperComponent,
    ExpandingCanvasWrapperComponent,
    ErrorModalComponent,
    UnsavedChangesModalComponent,
    DashGridBlockModalComponent,
    ConfirmModalComponent,
    SuccessModalComponent,
    ReportFilterModalComponent,
    ArchiveProjectModalComponent,
    UpdateSegmentRateModalComponent,
    ReportSelectorModalComponent,
    FileUploaderModalComponent,
    KmClockModalComponent,
    ReportSettingModalComponent,
    FeedbackProviderModalComponent,
    InvoiceLineEditorModalComponent,
    SentTimeErrorModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LibPipesModule,
    LibComponentsModule,
    LibDirectivesModule
  ],
  exports: [
    ModalWrapperComponent,
    OffcanvasWrapperComponent,
    ExpandingCanvasWrapperComponent,
    ErrorModalComponent,
    UnsavedChangesModalComponent,
    DashGridBlockModalComponent,
    ConfirmModalComponent,
    SuccessModalComponent,
    ReportFilterModalComponent,
    ArchiveProjectModalComponent,
    FileUploaderModalComponent,
    KmClockModalComponent,
    ReportSettingModalComponent,
    InvoiceLineEditorModalComponent
  ]
})
export class LibModalsModule { }
