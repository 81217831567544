<div class="app-formItem-container">

  <app-form-item [label]="'Table'">
    <list-drop-down [item_label_prop]="'label'"
                    [button_title]="report.table_config?.label"
                    [list_fill_width]="true"
                    [items]="available_facts"
                    (item_selected)="selectFact($event.item)">
    </list-drop-down>
  </app-form-item>

  <app-form-item [label]="'Measure'">
    <list-drop-down [item_label_prop]="'label'"
                    [button_title]="report.measure?.label"
                    [list_fill_width]="true"
                    [show_search]="true"
                    [itemIsVisible]="fieldIsVisible"
                    [items]="report.table_config?.measures || []"
                    (item_selected)="selectMeasure($event.item)">
    </list-drop-down>
  </app-form-item>

  <app-form-item [label]="'Aggregate'">
    <list-drop-down [button_title]="report.graph_function"
                    [list_fill_width]="true"
                    [items]="config.permissions.measures"
                    (item_selected)="selectFunction($event.item)">
    </list-drop-down>
  </app-form-item>

</div>
