import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';

export type RadioBoxItem = {
  value: string | number,
  label: String,
  tooltip?: string
};

@Component({
  selector: 'radio-box',
  templateUrl: './radio-box.component.html',
  styleUrls: ['./radio-box.component.scss'],
  providers: [MakeProvider(RadioBoxComponent)]
})
export class RadioBoxComponent extends AbstractValueAccessor {

  _value: RadioBoxItem;

  @Input() radio_items: RadioBoxItem[];
  @HostBinding('class.-readonly') @Input() readonly: boolean = false;

  radioItemSelected(item: RadioBoxItem) {
    if (!this.readonly) {
      this.value = item;
    }
  }

}
