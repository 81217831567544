import { Component, ViewChild, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { DomService } from './../../lib-services/dom/dom.service';
import { BackdropComponent } from './../backdrop/backdrop.component';

export type MobilePopupMenuItem = {
  item_label: string,
  item_value: string,
  item_hidden?: boolean,
  item_disabled?: boolean,
  item_text_colour?: string,
  item_icon_class?: string,
  item_toggleable?: boolean,
  item_toggle_value?: boolean
};

@Component({
  selector: 'mobile-popup-menu',
  templateUrl: './mobile-popup-menu.component.html',
  styleUrls: ['./mobile-popup-menu.component.scss']
})
export class MobilePopupMenuComponent implements OnDestroy {

  @ViewChild(NgbDropdown) ngbDropDown: NgbDropdown;

  private _items: MobilePopupMenuItem[] | MobilePopupMenuItem[][] = [];
  @Input()
  set items(items: any[]) {
    this._items = items || [];
    this.isDoubleArray = this._items.length && this.items[0] instanceof Array;
  }
  get items(): any[] {
    return this._items;
  }

  @Input() listAutoClose: boolean = true;
  @Input() listDisabled: boolean = false;
  @Input() buttonIconClass: string = 'ion-md-add';

  @Output() itemSelected = new EventEmitter();

  menuOpen: boolean = false;
  isDoubleArray: boolean = false;

  backdrop_ref: BackdropComponent = null;

  constructor(
    public domService: DomService
  ) { }

  ngOnDestroy() {
    this.backdrop_ref?.close();
  }

  dropdownToggled(isOpen: boolean) {
    this.menuOpen = isOpen;

    if (this.menuOpen) {
      this.backdrop_ref = this.domService.openBackdrop();
    }
    else {
      this.backdrop_ref?.close();
    }
  }

  close() {
    this.ngbDropDown.close();
  }

  selectItem(item: MobilePopupMenuItem) {
    if (!item.item_hidden && !item.item_disabled) {
      this.itemSelected.emit({ item_value: item.item_value });
    }
  }

}
