import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomService, DropdownItem, Report, ReportType, MegaSearchConfig, ReportService, ReportDashBlockConfig, AppTab, OverrideProperties, CoreUtilService, ReportingAccount, ListDropdownItemConfig, ReportDashComponent } from 'flexitime-library';
import _ from 'lodash-es';
import { AccountService } from '../../../services/account/account.service';
import { FtReportService } from '../../../services/ft-report/ft-report.service';

type ReportTabValue = (
  'DASH' | 'REPORTS'
);
type ReportTab = OverrideProperties<AppTab, { value: ReportTabValue }>

@Component({
  selector: 'app-report-all',
  templateUrl: './report-all.component.html',
  styleUrls: ['./report-all.component.scss']
})
export class ReportAllComponent implements OnInit {
  query_params = CoreUtilService.parseQueryParams(this.route.snapshot.queryParams);

  @ViewChild(ReportDashComponent) report_dash_component: ReportDashComponent;

  readonly report_label = CoreUtilService.report_label;

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.is_mobile !== DomService.is_mobile) {
      this.is_mobile = DomService.is_mobile;
    }
  }

  megaSearchId: string = 'ReportAllComponent';
  megaSearchConfigMap: Record<string, MegaSearchConfig> = {
    reports: {
      search_properties: [
        { label: this.report_label.capitalised, key: 'report_title', paths: ['report_title'] },
        { label: 'Table', key: 'table_config_label', paths: ['table_config.label'] },
        { label: this.report_label.capitalised + ' Type', key: 'report_type_label', paths: ['report_type_label'] }
      ],
      item_key_property: 'report_key',
      filtered_keys: new Set(),
      filter_active: false
    }
  };

  new_report_dropdown: DropdownItem[] = [
    {
      label: 'Table',
      value: 'TABLE',
      description: 'Include data fields and measures for a spreadsheet table'
    },
    {
      label: 'Chart',
      value: 'CHART',
      description: 'Create a bar, line or pie graph'
    },
    {
      label: 'Numeric',
      value: 'NUMERIC',
      description: 'Produce a numeric figure based on measures'
    }
  ];
  new_report_dropdown_config: ListDropdownItemConfig = {
    label: 'label',
    description: 'description'
  };

  tab_map: Record<ReportTabValue, ReportTab> = {
    DASH: {
      label: 'Dash',
      value: 'DASH',
      disabled: false
    },
    REPORTS: {
      label: this.report_label.capitalised_plural,
      value: 'REPORTS',
      disabled: false
    }
  };

  all_accounts: ReportingAccount[] = this.accountService.getAllAccounts();
  selected_accounts: ReportingAccount[] = this.accountService.selected_accounts;

  reports: Report[] = this.reportService.getAllReports();
  visible_reports: Report[] = [];

  dashboard_config: ReportDashBlockConfig[] = []
  initialised: boolean = false;

  tabs: ReportTab[] = [];
  selected_tab: ReportTab = null;

  reportIsVisible = (report: Report) => {
    return this.megaSearchConfigMap.reports.filtered_keys.has(report.report_key);
  }

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public reportService: ReportService,
    public ftReportService: FtReportService,
    public accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.initialised = true;
    this.dashboard_config = this.ftReportService.getDashboardConfig();

    this.tabs = [
      this.tab_map.DASH,
      this.tab_map.REPORTS
    ];
  }

  dropdownItemSelected(item: DropdownItem) {
    this.newReport(item.value);
  }

  accountsSelected() {
    this.selected_accounts = this.accountService.selected_accounts;
    setTimeout(() => this.report_dash_component?.generateQueries());
  }

  editReport(report: Report = null, report_type: ReportType = null) {
    this.router.navigate(['report/edit'], {
      queryParams: {
        report_key: report?.report_key || null,
        report_type: report?.report_type || report_type
      }
    });
  }

  newReport(report_type: ReportType) {
    this.editReport(null, report_type);
  }

  updateVisibleReports() {
    this.visible_reports = _.filter(this.reports, (report) => this.reportIsVisible(report));
  }

  updateDashboardConfig(config: ReportDashBlockConfig[]) {
    this.dashboard_config = config;
    this.ftReportService.updateDashboardConfig(this.dashboard_config)
      .catch(() => { });
  }

}
