import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { OnChargeType } from '../../lib.types';

export class InvTaskOncost implements PostableObject<InvTaskOncost> {

  task_key: number;
  account_code: string;
  billable_flag: boolean;
  description: string;
  exclude_margin_flag: boolean;
  on_charge_flag: boolean;
  on_charge_type: OnChargeType;
  on_cost_key: number;
  on_cost_rate: number;

  constructor(
    task_key: number,
    account_code: string = null,
    billable_flag: boolean = false,
    description: string = '',
    exclude_margin_flag: boolean = false,
    on_charge_flag: boolean = false,
    on_charge_type: OnChargeType = on_charge_flag ? 'Bill' : null,
    on_cost_key: number = null,
    on_cost_rate: number = 0
  ) {
    this.task_key = task_key;
    this.account_code = account_code;
    this.billable_flag = billable_flag;
    this.description = description;
    this.exclude_margin_flag = exclude_margin_flag;
    this.on_charge_flag = on_charge_flag;
    this.on_charge_type = on_charge_type;
    this.on_cost_key = on_cost_key;
    this.on_cost_rate = on_cost_rate;
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (!this.description) {
      errors['description'] = 'Description required';
    }
    if (!CoreUtilService.numberIsValid(this.on_cost_rate)) {
      errors['on_cost_rate'] = (this.on_charge_flag ? 'On-Charge' : 'On-Cost') + ' rate required';
    }

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  formatForPosting(to_delete: boolean = false): any {
    if (!this.getErrors().has_errors) {

      return {
        deleted_flag: to_delete,
        task_key: this.task_key,
        account_code: this.account_code,
        billable_flag: this.billable_flag,
        description: this.description,
        exclude_margin_flag: this.exclude_margin_flag,
        on_charge_flag: this.on_charge_flag,
        on_charge_type: this.on_charge_type,
        on_cost_key: this.on_cost_key,
        on_cost_rate: this.on_cost_rate,
      };
    }
    return null;
  }

}
