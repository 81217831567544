import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageUtilService {

  /**
    * Scales a blob image to the resolution specificed by xPx & xPx
    */
  static scaleImage(originalImage, xPx, yPx, compressionQuality = 1) {
    return new Promise((resolve) => {
      this.checkCanvasToBlobCompatibility();

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      context.canvas.width = xPx;
      context.canvas.height = yPx || xPx;

      const img = new Image();
      img.src = URL.createObjectURL(originalImage);
      img.onload = () => {
        context.drawImage(
          img, 0, 0, context.canvas.width, context.canvas.height
        );

        canvas.toBlob(
          (scaledImage) => resolve(scaledImage),
          'image/png',
          compressionQuality
        );
      };
    });
  }

  /**
   * Scales a blob image to ensure that the pixel values of both its
   * dimensions are no greater than maxSizePx.
   * Keeps the image ratio the same
   */
  static scaleDownImageToMaxSize(originalImage, maxSizePx, compressionQuality = 1) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(originalImage);

      img.onload = () => {
        if (img.height > maxSizePx || img.width > maxSizePx) {

          let scaledWidth;
          let scaledHeight;
          let imageRatio;

          // Portrait
          if (img.height > img.width) {
            imageRatio = img.width / img.height;
            scaledHeight = maxSizePx;
            scaledWidth = scaledHeight * imageRatio;
            if (scaledWidth < 200) {
              imageRatio = (200 - scaledWidth) / scaledWidth + 1;
              scaledWidth = 200;
              scaledHeight = scaledHeight * imageRatio;
            }
          }
          // Landscape
          else {
            imageRatio = img.height / img.width;
            scaledWidth = maxSizePx;
            scaledHeight = scaledWidth * imageRatio;
            if (scaledHeight < 200) {
              imageRatio = (200 - scaledHeight) / scaledHeight + 1;
              scaledHeight = 200;
              scaledWidth = scaledWidth * imageRatio;
            }
          }
          this.scaleImage(originalImage, scaledWidth, scaledHeight, compressionQuality)
            .then((scaledImage) => resolve(scaledImage));

        }
        else {
          resolve(originalImage);
        }
      };
    });
  }
  /**
   * Scales a blob image to ensure that the pixel values of both its
   * dimensions are no smaller than minSizePx.
   * Keeps the image ratio the same
   */
  static scaleImageToMinSize(originalImage, minSizePx, compressionQuality = 1) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(originalImage);

      img.onload = () => {

        let scaledWidth;
        let scaledHeight;
        let imageRatio;

        // Portrait
        if (img.height < img.width) {
          imageRatio = img.width / img.height;
          scaledHeight = minSizePx;
          scaledWidth = scaledHeight * imageRatio;
        }
        // Landscape
        else {
          imageRatio = img.height / img.width;
          scaledWidth = minSizePx;
          scaledHeight = scaledWidth * imageRatio;
        }
        this.scaleImage(originalImage, scaledWidth, scaledHeight, compressionQuality)
          .then((scaledImage) => resolve(scaledImage));
      };
    });
  }

  /**
   * Checks whether browser has a toBlob method for the canvas object.
   * If it doesn't then it will create its own method to read in uploaded images.
   */
  static checkCanvasToBlobCompatibility() {
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value(callback, type, quality) {
          const canvas = this;

          setTimeout(() => {

            const binString = atob(canvas.toDataURL(type, quality).split(',')[1]);
            const len = binString.length;
            const array = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
              array[i] = binString.charCodeAt(i);
            }

            callback(new Blob([array], { type: type || 'image/jpeg' }));
          });
        }
      });
    }
  }

}
