import { DpTeam } from '../dp-team/dp-team';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { DpPerson } from '../dp-person/dp-person';
import { DpSkill } from '../dp-skill/dp-skill';
import { DpShift } from '../dp-shift/dp-shift';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';

import * as _ from 'lodash-es';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';


export type SegmentStatus = ('default' | 'incoming' | 'pending' | 'no-action')

export class DpPublishedRosterSegment implements PostableObject<DpPublishedRosterSegment> {

  segment_status: SegmentStatus = 'default';

  shift_date: Date;
  start_time: Date;
  end_time: Date;

  roster_segment_key: number;
  published_roster_segment_key: number;

  shift: DpShift = null;
  skill: DpSkill = null;
  person: DpPerson = null;
  team: DpTeam = null;

  primary_color: string = null;
  secondary_color: string = null;

  selectedTeamKey: number = null;

  activeCoverRequest: any = null;

  constructor(
    shift_date: Date, shift: DpShift, skill: DpSkill = null, person: DpPerson = null, team: DpTeam = null,
    start_time: Date = null, end_time: Date = null, roster_segment_key: number = null,
    published_roster_segment_key: number = null, segment_status: SegmentStatus = 'default'
  ) {
    this.shift_date = TimeUtilService.getCleanDate(shift_date);
    this.start_time = start_time;
    this.end_time = end_time;
    this.shift = shift;
    this.skill = skill;
    this.person = person;
    this.team = team;
    this.roster_segment_key = roster_segment_key;
    this.published_roster_segment_key = published_roster_segment_key;
    this.segment_status = segment_status;

    this.primary_color = CoreUtilService.nearestColorInPalette(this.skill.skill_colour);
    this.secondary_color = CoreUtilService.getTextColorForBaseColor(this.primary_color);
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};
    return errors;
  }

  formatForPosting(): any {
    return null;
  }


}
