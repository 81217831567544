<div class="app-tabs"
     [ngClass]="{ '-hover-day': highlightBackground }">
  <div class="app-tabs-tab wdt-tab"
       *ngFor="let date of selected_week; let i = index"
       [ngClass]="{ '-selected': !date_selection_disabled && i === selectedDayIndex && !tab_highlight_disabled}"
       (click)="selectDay(i)">
    <!--
        commented out below public holidays as it wasnt been used by karmly timesheet
        or invoxy at this point and time. Below logic needs to be looked at if added as well
        handling hover background changes if there is a week with a public holiday  -->

    <!-- <div class="tuc-tab-holiday">
      {{public_holidays[i]?.public_holiday_name}}
    </div> -->
    <div class="wdt-tab-label">
      {{date | date : 'EEE, d'}}
    </div>


  </div>
</div>

<div class="wdt-highlight-container">
  <div class="-tabHighlighter"
       *ngFor="let date of selected_week; let i = index"
       [hidden]="tab_highlight_disabled"
       [ngStyle]="{'left': 'calc((100% / 7)*' + i + ')'}"
       [ngClass]="{ '-selected': !date_selection_disabled && i === selectedDayIndex && !tab_highlight_disabled}">
  </div>
</div>
