import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(
    value: DateTime,
    format: string
  ): string {
    if (value?.isValid) {
      return value.toFormat(format);
    }
    return null;
  }

}
