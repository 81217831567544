import { Component, Output, EventEmitter, HostListener, HostBinding, Input, AfterContentInit } from '@angular/core';

export type BackdropOptions = {
  z_index?: number,
  force_dark_backdrop?: boolean,
  backdrop_class?: string
}

@Component({
  selector: 'app-backdrop',
  templateUrl: './backdrop.component.html',
  styleUrls: ['./backdrop.component.scss']
})
export class BackdropComponent implements AfterContentInit {

  @HostBinding('class.-visible') is_visible = false;
  @HostBinding('class') classes = null;
  @HostBinding('class.-darkBackdrop') force_dark_backdrop: boolean = false;
  @HostBinding('style.z-index') z_index: number = null;

  @Input() options: BackdropOptions = null;

  @Output() backdropClicked = new EventEmitter<{ ignore_callback: boolean }>();

  @HostListener('click', ['$event'])
  handleClick() {
    this.backdropClicked.emit();
  }

  ngOnInit() {
    this._initOptions();
  }

  ngAfterContentInit() {
    setTimeout(() => this.is_visible = true);
  }

  close(ignore_callback: boolean = false) {
    this.backdropClicked.emit({ ignore_callback });
  }

  private _initOptions() {
    if (!this.options) {
      this.options = {
        z_index: null,
        force_dark_backdrop: false,
        backdrop_class: null
      };
    }

    this.force_dark_backdrop = this.options.force_dark_backdrop;
    this.classes = this.options.backdrop_class;
    this.z_index = this.options.z_index;
  }

}
