import { Injectable } from '@angular/core';

import {
  ReportRoutePath
} from '../../app.route-types';

import { AuthService, SocketService, StateAccessServiceInterface, ReportService, CoreUtilService } from 'flexitime-library';

import { Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AccountService } from '../account/account.service';
import { FtReportService } from '../ft-report/ft-report.service';

type UnlockedRouteConfig = {
  report: boolean | Record<ReportRoutePath, boolean>
};

type StateAccessServiceEventType = (
  'service_reloaded'
);

@Injectable({
  providedIn: 'root'
})
export class StateAccessService implements StateAccessServiceInterface {

  private _events: Record<StateAccessServiceEventType, Subject<any>> = {
    service_reloaded: new Subject<void>()
  };

  private _unlocked_routes: UnlockedRouteConfig = {
    report: false
  };

  private _state_access_setup: boolean = false;

  private _event_subscriptions: Record<string, Subscription> = {};

  constructor(
    public authService: AuthService,
    public socketService: SocketService,
    public router: Router,
    public reportService: ReportService,
    public ftReportService: FtReportService,
    public accountService: AccountService
  ) {
    CoreUtilService.app_theme = '-theme-flexitime';
    CoreUtilService.report_label = CoreUtilService.report_labels.FLEXITIME;
  }

  get state_access_setup(): boolean {
    return this._state_access_setup;
  }

  canAccessRoute(route_path_segments: string[]): boolean {
    const module_path = route_path_segments[0];
    const module_child_path = route_path_segments.length > 1 ? route_path_segments[1] : null;

    if (!!this._unlocked_routes[module_path]) {
      // Only checking if module is accessable
      if (module_child_path === null) {
        return true;
      }
      // All child routes accessable
      if (this._unlocked_routes[module_path] === true) {
        return true;
      }
      // Only some child routes accessable
      else if (this._unlocked_routes[module_path][module_child_path] === true) {
        return true;
      }
    }
    return false;
  }

  getStateAccessReloadedEvent(): Observable<any> {
    return this._events.service_reloaded.asObservable();
  }

  reloadStateAccess(
    route_path: string = null,
    route_params: any = null
  ) {
    if (!this.authService.hasSession()) {
      this.clearServiceData();
      this.authService.logout();
    }
    else {
      this._unlocked_routes = {
        report: true
      };

      const default_route = 'report/all';
      this._state_access_setup = true;
      this._goToRouteAfterReload(default_route, route_path, route_params);
    }
  }

  private _goToRouteAfterReload(
    default_route_path: string,
    route_path: string = null,
    route_params: any = null
  ) {
    this._events.service_reloaded.next(null);

    if (!!route_path) {
      this.router.navigate([route_path], { queryParams: route_params });
    }
    else {
      this.router.navigate([default_route_path]);
    }
  }

  ensureServicesInitialised(force_reload: boolean = false) {
    return new Promise<void>((resolve, reject) => {
      this._state_access_setup = false;

      if (this.authService.hasSession()) {

        // this.socketService.initWebSocket();
        this._initEventListeners();

        Promise.all([
          this.accountService.initialiseService(force_reload),
          this.ftReportService.initialiseService(force_reload)
        ])
          .then(() => {
            this.reportService.initialiseService(force_reload)
              .then(() => {
                this._state_access_setup = true;
                resolve();
              })
              .catch((err) => reject(err));
          })
          .catch((err) => reject(err));
      }
      else {
        reject('AuthService.hasSession() failed');
      }
    });
  }

  clearServiceData() {
    // this.socketService.closeSocket();

    this.accountService.clearServiceData();
    this.ftReportService.clearServiceData();
    this.reportService.clearServiceData();

    this._clearEventListeners();
  }

  private _initEventListeners() {
    this._clearEventListeners();
  }

  private _clearEventListeners() {
    for (const subscription of Object.values(this._event_subscriptions)) {
      subscription.unsubscribe();
    }
    this._event_subscriptions = {};
  }

}
