import { Directive, DoCheck, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[textareaAutoresize]'
})
export class TextareaAutoresizeDirective implements OnInit, DoCheck {

  private _component_visible: boolean = false;

  @HostListener(':input')
  onInput() {
    this.resize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    setTimeout(() => this.resize());
  }

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    if (this.elementRef.nativeElement.scrollHeight) {
      setTimeout(() => this.resize());
    }
  }

  ngDoCheck(): void {
    // offsetParent is null when component or a parent component is hidden
    const component_visible = !!this.elementRef.nativeElement.offsetParent;
    if (this._component_visible !== component_visible) {
      this._component_visible = component_visible;

      if (this._component_visible) {
        this.resize();
      }
    }
  }

  resize() {
    this.elementRef.nativeElement.style.height = '0';
    this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 'px';
  }

}
