import { Component, Input, Output, EventEmitter, HostBinding, HostListener, ElementRef } from '@angular/core';

export type MobileSlideoutButtonConfig = {
  button_label: string,
  button_color_class: string,
  button_icon_class?: string,
  button_disabled?: boolean
};

@Component({
  selector: 'mobile-slideout-button',
  templateUrl: './mobile-slideout-button.component.html',
  styleUrls: ['./mobile-slideout-button.component.scss']
})
export class MobileSlideoutButtonComponent {

  @HostBinding('class.-buttonOpen') buttonOpen: boolean = false;

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    // Outside click
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.buttonOpen = false;
    }
  }

  @Input() buttonConfig: MobileSlideoutButtonConfig;

  @Output() buttonClicked = new EventEmitter();

  constructor(
    private eRef: ElementRef
  ) { }

  clickButton() {
    if (!this.buttonOpen) {
      this.buttonOpen = true;
    }
    else {
      this.buttonClicked.emit();
      this.buttonOpen = false;
    }
  }

}
