import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';

export class KmMessageTemplate implements PostableObject<KmMessageTemplate> {

  message_template_key: number;
  company_key: number;
  template_name: string;
  template_body: string;
  template_subject: string;
  template_type: string;

  constructor(
    message_template_key: number,
    company_key: number,
    template_name: string,
    template_body: string,
    template_subject: string,
    template_type: string
  ) {
    this.message_template_key = message_template_key;
    this.company_key = company_key;
    this.template_name = template_name;
    this.template_body = template_body;
    this.template_subject = template_subject;
    this.template_type = template_type;
  }

  formatForPosting (): any {
    if (!this.getErrors().has_errors) {
      return {
        message_template_key: this.message_template_key,
        template_name: this.template_name,
        template_body: this.template_body,
        template_subject: this.template_subject,
        template_type: this.template_type,
        default_flag: true
      };
    }
    return null;
  }

  hasErrors(): boolean { 
    return Object.keys(this.getErrors()).length > 0;
  }

  getErrors(): PostableObjectErrorMap { 
    const errors = {};

    if(!this.template_subject) {
      errors['template_subject'] = 'Message subject required';
    }
    if(!this.template_body) {
      errors['template_body'] = 'Message body required';
    }

    return errors; 
  }
}
