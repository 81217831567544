import { TimeUtilService } from '../../lib-services/time-util/time-util.service';

export abstract class Expense {

  // Either PhExpense.expense_key or InvExpense.segment_key
  readonly expense_key: number;

  private _expense_amount: number;
  private _expense_date: Date;
  description: string;

  ph_approval_date: Date;
  ph_declined_date: Date;

  ph_authorised_flag: boolean;
  ph_pending_flag: boolean;
  ph_approved_flag: boolean;
  ph_paid_flag: boolean;
  ph_declined_flag: boolean;

  deleted_flag: boolean = false;

  _ph_response_step: number;

  _unit_type: string;
  _is_custom_unit: boolean;

  readonly is_ph_expense: boolean;
  readonly is_inv_expense: boolean;

  constructor(
    is_ph_expense: boolean, is_inv_expense: boolean,
    expense_key: number, expense_amount: number = 0, expense_date: Date, description: string = '',
    ph_approval_date: Date = null, ph_declined_date: Date = null, ph_paid_flag: boolean = false, ph_authorised_flag: boolean = false
  ) {
    this.is_ph_expense = is_ph_expense;
    this.is_inv_expense = is_inv_expense;

    this.expense_key = expense_key;

    this._expense_amount = expense_amount;
    this._expense_date = expense_date;
    this.description = description;

    this.ph_approval_date = ph_approval_date;
    this.ph_declined_date = ph_declined_date;

    this.ph_authorised_flag = ph_authorised_flag;
    this.ph_approved_flag = this.ph_declined_date === null && this.ph_approval_date !== null;
    this.ph_paid_flag = ph_paid_flag;
    this.ph_declined_flag = this.ph_declined_date !== null;
    this.ph_pending_flag = this.ph_authorised_flag && !this.ph_approval_date && !this.ph_declined_date && !this.ph_paid_flag;

    this._ph_response_step = null;
    this._updatePhResponseStep();
  }

  abstract get colour(): string;
  abstract get is_active_project(): boolean;

  abstract _updateUnitType(): void;
  abstract formatExpenseForPosting(): any;

  //////////////////////////////////////////////////////////////

  get unit_type(): string {
    return this._unit_type;
  }
  get is_custom_unit(): boolean {
    return this._is_custom_unit;
  }

  get expense_date(): Date {
    return this._expense_date;
  }
  set expense_date(expense_date: Date) {
    if (TimeUtilService.dateIsValid(expense_date)) {
      expense_date = TimeUtilService.getCleanDate(expense_date);

      this._expense_date = expense_date;
    }
  }

  get expense_amount(): number {
    return this._expense_amount;
  }
  set expense_amount(expense_amount: number) {
    if (TimeUtilService.numberIsValid(expense_amount)) {
      if (expense_amount < 0) {
        expense_amount = 0;
      }
      this._expense_amount = expense_amount;
    }
  }

  get ph_response_step() {
    return this._ph_response_step;
  }

  private _updatePhResponseStep() {
    if (this.ph_pending_flag) {
      this._ph_response_step = 5;
    }
    if (this.ph_approved_flag) {
      this._ph_response_step = 4;
    }
    if (this.ph_paid_flag) {
      this._ph_response_step = 3;
    }
    if (this.ph_declined_flag) {
      this._ph_response_step = 2;
    }
    if (!this.ph_authorised_flag) {
      this._ph_response_step = 1;
    }
  }

}
