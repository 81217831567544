import { Inject, Injectable } from '@angular/core';

import { ProductLogoType } from '../../lib.types';
import { CoreUtilService } from '../core-util/core-util.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    @Inject('env') public env: any
  ) { }

  getProductLogo(logo_type: ProductLogoType, white_logo: boolean = false, registered_logo: boolean = false) {
    const product_lowercase = this.env.product.toLowerCase();
    const product = CoreUtilService.capitaliseFirstLetter(product_lowercase);
    const logo_type_string = CoreUtilService.capitaliseFirstLetter(logo_type);
    const logo_colour = white_logo ? '_White' : '';
    const registered_logo_string = registered_logo ? '_Registered' : '';

    return 'assets/product-imgs/' + product_lowercase + '/' + product + '_' + logo_type_string + logo_colour + registered_logo_string + '.svg';
  }
}
