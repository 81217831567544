import { Component, OnInit, Input } from '@angular/core';

import {
  ReportConfigTable,
  ReportConfigMeasure,
  ReportMeasurePermission
} from '../../lib.types';

import { NumericReport } from '../../lib-models/report/numeric-report/numeric-report';
import { ReportService } from '../../lib-services/report/report.service';

import _ from 'lodash-es';

@Component({
  selector: 'report-numeric-setting',
  templateUrl: './report-numeric-setting.component.html',
  styleUrls: ['./report-numeric-setting.component.scss']
})
export class ReportNumericSettingComponent implements OnInit {

  readonly config = this.reportService.getConfig();

  @Input() report: NumericReport;

  available_facts: ReportConfigTable[] = [];

  fieldIsVisible = (field: ReportConfigMeasure) => {
    return !field.system_only_flag;
  }

  constructor(
    public reportService: ReportService
  ) { }

  ngOnInit(): void {
    this._initAvailableFacts();
    this._initSelectedValues();
  }

  selectFact(fact: ReportConfigTable) {
    if (fact.id !== this.report.table_config.id) {
      this.report.table_config = fact;

      this.selectMeasure(this._getFirstVisibleMeasure());
    }
  }

  selectMeasure(measure: ReportConfigMeasure) {
    this.report.measure = measure;
  }

  selectFunction(graph_function: ReportMeasurePermission) {
    this.report.graph_function = graph_function;
  }

  private _initSelectedValues() {
    this.report.measure = this.report.measure || this._getFirstVisibleMeasure();
  }

  private _getFirstVisibleMeasure(): ReportConfigMeasure {
    for (const measure of this.report.table_config.measures) {
      if (this.fieldIsVisible(measure)) {
        return measure;
      }
    }
    return null;
  }

  private _initAvailableFacts() {
    const available_facts = [];

    for (const fact of this.config.facts) {
      if (!!fact.measures.length && !!fact.dimensions.length) {
        available_facts.push(fact);
      }
    }

    this.available_facts = available_facts;
  }

}
