<div class="input-group">
  <div class="input-group-append"
       *ngIf="!disabled && !readonly">
    <button class="-type-icon -color-link-hoverFill"
            (click)="openDatePicker()"
            type="button">
      <i class="ion ion-md-calendar"></i>
    </button>
  </div>
  <input class="form-control"
         name="dp"
         ngbDatepicker
         #ngbDatepicker="ngbDatepicker"
         [datepickerClass]="'dropdown'"
         [placeholder]="readonly ? '' : 'dd/mm/yyyy'"
         [container]="'body'"
         [disabled]="disabled"
         [readonly]="readonly"
         [maxDate]="max_date_struct"
         [minDate]="min_date_struct"
         [(ngModel)]="value"
         [ngModelOptions]="{ updateOn: 'blur' }"
         (ngModelChange)="validateDate()">
</div>
