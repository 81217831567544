import { Injectable } from '@angular/core';

import { ReportingAccount, PhReportingAccount, StateDataService, AccountServiceInterface, ApiService, SortUtilService } from 'flexitime-library';

import _ from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class AccountService implements AccountServiceInterface {

  accounts: ReportingAccount[] = [];
  account_map: Record<number, ReportingAccount> = {};

  private _selected_accounts: ReportingAccount[] = [];

  service_setup = false;

  constructor(
    public stateDataService: StateDataService,
    public apiService: ApiService
  ) { }

  initialiseService(force_reload: boolean = false) {
    return new Promise<void>((resolve, reject) => {
      if (this.service_setup && !force_reload) {
        resolve();
      }
      else {
        this.service_setup = false;

        this.loadAccounts()
          .then(() => {
            this._initSelectedAccounts();
            this.service_setup = true;
            resolve();
          })
          .catch((err) => reject(err));
      }
    });
  }

  clearServiceData() {
    this.accounts = [];
    this.account_map = {};
    this.service_setup = false;
  }

  get selected_accounts(): ReportingAccount[] {
    return this._selected_accounts;
  }
  set selected_accounts(selected_accounts: ReportingAccount[]) {
    this._selected_accounts = selected_accounts;

    this.stateDataService.cacheComponentSessionData(
      'AccountService',
      'selected_account_keys',
      this.selected_accounts.map((a) => a.company_reference)
    );
  }

  getAllAccounts(): ReportingAccount[] {
    return _.clone(this.accounts);
  }

  loadAccounts() {
    return new Promise<void>((resolve, reject) => {

      this.apiService.get('FLEXITIME', 'reporting/companies')
        .then((res) => {
          this.accounts = this.setupAccounts(res);
          this.account_map = {};

          for (const account of this.accounts) {
            this.account_map[account.company_reference] = account;
          }
          resolve();
        })
        .catch((err) => reject(err));
    });
  }

  private _initSelectedAccounts() {
    const selected_account_keys = this.stateDataService.getCachedComponentSessionData(
      'AccountService',
      'selected_account_keys'
    ) || [];

    const selected_accounts = selected_account_keys
      .map((company_product_key: number) => this.account_map[company_product_key] || null)
      .filter((account: ReportingAccount) => !!account);

    this.selected_accounts = selected_accounts?.length ? selected_accounts : this.accounts;
  }

  setupAccounts(data: any[]): ReportingAccount[] {
    const accounts: ReportingAccount[] = [];

    for (const d of data) {
      const account = this.setupAccount(d);

      if (!!account) {
        accounts.push(account);
      }
    }
    return SortUtilService.sortList(accounts, { primary_sort_property: 'company_name' });
  }

  setupAccount(a: any): ReportingAccount {
    if (!!a.company_code) {
      return new PhReportingAccount(
        a.company_reference,
        a.company_name,
        a.company_code,
        a.access_key
      );
    }
    return null;
  }

}
