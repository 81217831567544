
import { AbstractValueAccessor, MakeProvider } from '../../lib-classes/abstract/abstract-value-accessor/abstract-value-accessor';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import { DomService } from '../../lib-services/dom/dom.service';

import _ from 'lodash-es';

import { Component, Injectable, Input, ViewChild, AfterViewInit, AfterViewChecked, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { NgbDateStruct, NgbDateAdapter, NgbInputDatepicker, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { BackdropComponent } from '../backdrop/backdrop.component';

@Injectable()
export class DateAdapter extends NgbDateAdapter<Date> {

  fromModel(value: Date | null): NgbDateStruct | null {
    if (!value || !TimeUtilService.dateIsValid(value)) {
      return null;
    }
    return {
      year: value.getFullYear(),
      month: value.getMonth() + 1,
      day: value.getDate()
    };
  }

  toModel(date: NgbDateStruct | null): Date | null {
    if (!date) {
      return null;
    }
    return new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0);
  }
}

@Injectable()
export class DateParserFormatter {
  parse(value: string): NgbDateStruct {
    value = this.formatInput(value);

    const date = TimeUtilService.dateTimeStringToDate(value, 'dd/MM/yyyy');

    if (!value || !TimeUtilService.dateIsValid(date)) return null;

    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    } as NgbDateStruct;

  }
  format(ngbDate: NgbDateStruct): string {
    if (!ngbDate) return null;

    const date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day, 0, 0, 0, 0);
    return TimeUtilService.dateToDateTimeString(date, 'dd/MM/yyyy');
  }

  /**
   * Formats a date string to the dd/MM/yyyy format if possible. Incorrect dates can still be returned
   * (e.g. dd/MM/yyy) but will be invalid when checked, this is formatting to make correct dates
   * consistent.
   * @param value Date user input (possibly malformed) as a string.
   * @returns Date formatted (if possible) to dd/MM/yyyy.
   */
  formatInput(value: string): string {

    // Expecting 1-2 day numbers, 1-2 month numbers, and 2 to 4 year numbers
    // Note - user can input three numbers for a year, but it'll still be invalid in dateIsValid
    const dateRegex = /^(\d?\d(\/|-){1}){2}(\d?\d){2}$/;

    if (!dateRegex.test(value)) {
      return; // Invalid input
    }

    let separator = '/';
    if (value.includes('-')) {
      if (value.includes('/')) {
        return; // Combination of dashes and forward slashes is invalid input
      }
      separator = '-';
    }

    // Slice date portion of string
    const date = parseInt(value.slice(0, value.indexOf(separator)));
    // Ensure date string is formatted to 2 digits
    const dateString = date < 10 ? '0' + date : '' + date;

    value = value.slice(value.indexOf(separator) + 1, value.length);

    // Slice month portion of string
    // Month portion of JS dates start at 0 instead of 1
    const month = parseInt(value.slice(0, value.indexOf(separator)));
    // Ensure month string is formatted to 2 digits
    const monthString = month < 10 ? '0' + month : '' + month;

    value = value.slice(value.indexOf(separator) + 1, value.length);

    // Slice year portion of string
    let year = parseInt(value);
    // If year is < 40, assume a 21st century date, otherwise assume 20th century
    if (year.toString().length <= 2) {
      year = year + (year < 40 ? 2000 : 1900);
    } // Else year should be 4 digits already

    // Ensure year string is formatted to 4 digits
    const yearString = '' + year;

    return dateString + '/' + monthString + '/' + yearString;
  }
}

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: DateAdapter },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatter },
    MakeProvider(DatePickerComponent)
  ]
})
export class DatePickerComponent extends AbstractValueAccessor implements AfterViewInit, AfterViewChecked, OnChanges {

  @ViewChild('ngbDatepicker') ngbDatepicker: NgbInputDatepicker;

  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() max_date: Date = null;
  @Input() min_date: Date = null;
  @Input() allow_null: boolean;

  max_date_struct: NgbDateStruct = null;
  min_date_struct: NgbDateStruct = null;

  backdrop_ref: BackdropComponent = null;

  _value: Date = null;
  _backup_value: Date = null;

  constructor(
    public domService: DomService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.max_date) {
      if (!TimeUtilService.dateIsValid(this.max_date)) {
        this.max_date = null;
      }
      this.max_date_struct = TimeUtilService.getNgbDateStruct(this.max_date);
    }
    if (!!changes.min_date) {
      if (!TimeUtilService.dateIsValid(this.min_date)) {
        this.min_date = null;
      }
      this.min_date_struct = TimeUtilService.getNgbDateStruct(this.min_date);
    }
  }

  ngAfterViewInit() {
    this.ngbDatepicker.closed.subscribe(() => {
      this.datePickerClosed();
    });
  }

  ngAfterViewChecked() {
    if (TimeUtilService.dateIsValid(this._value)) {
      this._backup_value = this._value;
    }
  }

  validateDate() {
    if (
      !TimeUtilService.dateIsValid(this.value) &&
      (!this.allow_null || this.value !== null)
    ) {
      this.changeDetectorRef.detectChanges();
      this.value = this._backup_value;
    }
  }

  openDatePicker() {
    this.backdrop_ref = this.domService.openBackdrop(null, { force_dark_backdrop: true });
    this.ngbDatepicker.open();
  }

  datePickerClosed() {
    this.backdrop_ref?.close();
  }
}
