import { Component, EventEmitter, Output } from '@angular/core';

import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';

@Component({
  selector: 'app-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.scss']
})
export class UnsavedChangesModalComponent implements AppModalInterface {

  @Output() close = new EventEmitter<boolean>();
  @Output() dismiss = new EventEmitter<void>();

  saveChanges() {
    this.close.emit(true);
  }

  discardChanges() {
    this.close.emit(false);
  }

  cancel() {
    this.dismiss.emit();
  }

}
