<div ngbDropdown
     #ngbDropDown
     [placement]="'top-left'"
     [container]="'body'"
     [autoClose]="listAutoClose ? true : 'outside'"
     (openChange)="dropdownToggled($event)">

  <button class="-type-icon"
          [ngClass]="{
            '-menuOpen': menuOpen,
            '-color-white': menuOpen,
            '-color-green': !menuOpen
          }"
          [disabled]="listDisabled"
          ngbDropdownToggle>
    <i class="ion"
       [ngClass]="buttonIconClass">
    </i>
  </button>

  <ul ngbDropdownMenu
      class="mobilePopupMenu-ul">

    <div class="mobilePopupMenu-itemsContainer"
         *ngIf="!isDoubleArray">

      <li class="mobilePopupMenu-item"
          *ngFor="let item of items"
          [hidden]="item.hidden"
          [ngStyle]="{ 'color': item.text_colour || '' }"
          [ngClass]="{ '-disabled': item.disabled }"
          (click)="selectItem(item)">
        <div class="mobilePopupMenu-item-left">
          <div class="mobilePopupMenu-item-name">
            {{item.label}}
          </div>
        </div>
        <div class="mobilePopupMenu-item-right">
          <app-toggle *ngIf="item.toggleable"
                      [(ngModel)]="item.toggle_value"
                      [disableInternalToggle]="true">
          </app-toggle>
          <i class="ion"
             *ngIf="item.icon_class"
             [ngClass]="item.icon_class">
          </i>
        </div>
      </li>

    </div>
    <div class="mobilePopupMenu-itemsContainer"
         *ngIf="isDoubleArray">
      <div class="mobilePopupMenu-itemGroup"
           *ngFor="let itemGroup of items">

        <li class="mobilePopupMenu-item"
            *ngFor="let item of itemGroup"
            [hidden]="item.hidden"
            [ngStyle]="{ 'color': item.text_colour || '' }"
            [ngClass]="{ '-disabled': item.disabled }"
            (click)="selectItem(item)">
          <div class="mobilePopupMenu-item-left">
            <div class="mobilePopupMenu-item-name">
              {{item.label}}
            </div>
          </div>
          <div class="mobilePopupMenu-item-right">
            <app-toggle *ngIf="item.toggleable"
                        [(ngModel)]="item.toggle_value"
                        [disableInternalToggle]="true">
            </app-toggle>
            <i class="ion"
               *ngIf="item.icon_class"
               [ngClass]="item.icon_class">
            </i>
          </div>
        </li>

      </div>
    </div>
  </ul>

</div>
