import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { DomService } from '../../../public-api';

type HelpType = ('ARTICLE' | 'LINK' | 'HOVER');


@Component({
  selector: 'whats-this',
  templateUrl: './whats-this.component.html',
  styleUrls: ['./whats-this.component.scss']
})
export class WhatsThisComponent {

  readonly support_article_URL = {
    INVOXY: 'https://support.invoxy.com/',
    KARMLY: 'https://support.invoxy.com/',
    DROPPAH: 'https://support.droppah.com/',
    PAYHERO: 'https://support.payhero.co.nz/'
  }

  is_mobile = DomService.is_mobile;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.is_mobile = DomService.is_mobile;
  }

  @Input() help_type: HelpType = 'LINK';
  @Input() article: string;
  @Input() hover_text: string;
  @Input() custom_link: string;
  @Input() custom_icon_class: string;
  //For displaying long tooltip content on mobile, e.g. 'top-right'
  @Input() hover_text_placement: string = null;


  icon_class: string;

  constructor(
    @Inject('env') public env: any,
  ) {
  }

  ngOnInit() {
    if (this.help_type === 'HOVER' && !this.hover_text) {
      this.help_type = 'LINK';
    }

    if (this.custom_icon_class) {
      this.icon_class = this.custom_icon_class;
    }
    else if (this.help_type === 'HOVER'){
      this.icon_class = 'ion-md-information-circle';
    }
    else {
      this.icon_class = 'ion-md-help-circle-outline';
    }
  }


  linkClicked() {
    let url: string;

    if (this.help_type === 'ARTICLE' && this.article) {
      url = this.support_article_URL[this.env.product] + 'hc/en-us/articles/' + this.article;
    }
    else if (this.help_type === 'LINK' && this.custom_link) {
      url = this.custom_link;
    }
    else {
      url = this.support_article_URL[this.env.product];
    }

    window.open(url, '_blank');
  }
}
