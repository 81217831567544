import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, HostListener, Input, OnDestroy } from '@angular/core';

import { CameraService } from '../../lib-services/camera/camera.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit, OnDestroy {

  @Input() squareImage: boolean = false;
  @Input() selfieCamera: boolean = true;

  @Output() cameraCancelled = new EventEmitter();
  @Output() cameraConfirmed = new EventEmitter();

  @ViewChild('cameraInput') cameraInput: ElementRef;
  @ViewChild('cameraImage') cameraImage: ElementRef;
  @ViewChild('cameraVideo') cameraVideo: ElementRef;
  @ViewChild('cameraContents') cameraContents: ElementRef;

  photoTaken: boolean = false;
  takingPhoto: boolean = false;
  cameraSupported: boolean = false;
  pageInitialised: boolean = false;
  uploadingPhoto: boolean = false;

  constraints = {
    video: {
      facingMode: this.selfieCamera ? 'user' : 'environment'
    },
    audio: false
  };

  imageBlob: Blob = null;
  videoStream: MediaStream;

  pageIsPortrait: boolean = false;
  videoIsPortrait: boolean = false;

  pageHeight: number;
  pageWidth: number;

  nativeVideoHeight: number = null;
  nativeVideoWidth: number = null;

  videoHeight: number;
  videoWidth: number;

  cameraWidth: string = null;
  cameraHeight: string = null;
  cameraBorderSize: string = null;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setupVideoDimensions();
  }

  constructor(
    public cameraService: CameraService
  ) { }

  ngOnInit(): void {
    // CameraService maintains whether or not we've previously tried checking for camera support
    const cameraSupported = this.cameraService.cameraSupported;

    // Camera is supported or camera support hasn't yet been checked and navigator.mediaDevices is accessible
    if ((cameraSupported || cameraSupported === null) &&
      !!navigator && !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia) {

      navigator.mediaDevices.getUserMedia(this.constraints)
        .then((stream) => {
          // Set camera supported value in utilService for easy future reference
          this.cameraSupported = true;
          this.cameraService.cameraSupported = this.cameraSupported;

          this.cameraVideo.nativeElement.srcObject = stream;
          this.videoStream = stream;

          // Watch for stream to be loaded into the video element so we can calculate
          // video element dimensions from video source dimensions/aspect ratio
          this.cameraVideo.nativeElement.onloadedmetadata = () => {
            this.setupVideoDimensions();
            this.pageInitialised = true;
          };
        })
        .catch((err) => {
          this.fallbackToImageUpload();
        });
    }
    else {
      this.fallbackToImageUpload();
    }
  }

  ngOnDestroy() {
    if (!!this.videoStream) {
      this.videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  swapCamera() {
    this.selfieCamera = !this.selfieCamera;
    this.constraints.video.facingMode = this.selfieCamera ? 'user' : 'environment';

    navigator.mediaDevices.getUserMedia(this.constraints)
      .then((stream) => {
        this.cameraVideo.nativeElement.srcObject = stream;

        // Watch for stream to be loaded into the video element so we can calculate
        // video element dimensions from video source dimensions/aspect ratio
        this.cameraVideo.nativeElement.onloadedmetadata = () => {
          this.setupVideoDimensions();
          this.pageInitialised = true;
        };
      })
      .catch((err) => {
        this.fallbackToImageUpload();
      });
  }

  fallbackToImageUpload() {
    this.pageInitialised = true;
    this.cameraSupported = false;
    this.cameraService.cameraSupported = this.cameraSupported;
    setTimeout(() => {
      this.cameraInput.nativeElement.click();
    });
  }

  setupVideoDimensions() {
    // Need to have this wrapped in a try/catch to ensure that if the user goes back before
    // the camera has finished initialising, an error isn't thrown
    try {
      this.pageHeight = this.cameraContents.nativeElement.offsetHeight;
      this.pageWidth = this.cameraContents.nativeElement.offsetWidth;

      this.videoHeight = this.cameraVideo.nativeElement.offsetHeight;
      this.videoWidth = this.cameraVideo.nativeElement.offsetWidth;

      if (this.nativeVideoHeight === null && this.nativeVideoWidth === null) {
        this.nativeVideoHeight = this.videoHeight;
        this.nativeVideoWidth = this.videoWidth;
      }

      this.pageIsPortrait = this.pageHeight > this.pageWidth;
      this.videoIsPortrait = this.videoHeight > this.videoWidth;
    }
    catch (err) { }
  }

  imageUploaded() {
    this.uploadingPhoto = true;

    if (this.cameraInput.nativeElement.files.length) {
      const file = this.cameraInput.nativeElement.files[0];

      CameraService.orientAndCompressBlobImage(file)
        .then((blob) => {
          CameraService.scaleDownImageToMaxSize(blob, 500)
            .then((scaledBlob) => {
              this._cropImage(scaledBlob)
                .then((squaredBlob) => {
                  this.imageBlob = squaredBlob;
                  this.cameraImage.nativeElement.src = URL.createObjectURL(squaredBlob);

                  this.photoTaken = true;
                  this.takingPhoto = false;
                  this.uploadingPhoto = false;

                  this.confirm();
                });
            });
        });
    }
    else {
      this.uploadingPhoto = false;
    }
  }

  private _cropImage(blob: Blob): Promise<Blob> {
    return new Promise<Blob>((resolve) => {
      if (this.squareImage) {
        CameraService.cropImageToSquare(blob)
          .then((squaredBlob) => resolve(squaredBlob))
          .catch(() => resolve(blob));
      }
      else {
        resolve(blob);
      }
    });

  }

  takePhoto() {
    this.takingPhoto = true;

    CameraService.generateBlobImageFromVideo(this.cameraVideo.nativeElement, 0.5, this.squareImage, this.selfieCamera)
      .then((blob) => {
        CameraService.scaleDownImageToMaxSize(blob, 500)
          .then((scaledBlob) => {
            this.imageBlob = scaledBlob;
            this.cameraImage.nativeElement.src = URL.createObjectURL(scaledBlob);

            this.photoTaken = true;
            this.takingPhoto = false;
          });
      });
  }

  newPhoto() {
    this.photoTaken = false;
    this.imageBlob = null;
    // Empty src
    this.cameraImage.nativeElement.src = '';
    this.cameraInput.nativeElement.value = null;
  }

  cancel() {
    this.cameraCancelled.emit();
  }

  confirm() {
    this.cameraConfirmed.emit(this.imageBlob);
  }

}
