import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import _ from 'lodash-es';

import { MegaSearchConfig } from '../../lib-components/mega-search/mega-search.component';
import { DropdownItem, ReportType } from '../../lib.types';
import { ReportService } from '../../lib-services/report/report.service';
import { Report } from '../../lib-models/report/report';
import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';
import { CoreUtilService } from '../../../public-api';

@Component({
  selector: 'lib-report-selector-modal',
  templateUrl: './report-selector-modal.component.html',
  styleUrls: ['./report-selector-modal.component.scss']
})
export class ReportSelectorModalComponent implements OnInit, AppModalInterface {

  readonly report_label = CoreUtilService.report_label;
  readonly chart_type_labels = this.reportService.getChartTypeLabels();

  @Input() project_report_filter_flag: boolean = false;
  @Input() excluded_report_keys: Set<number> = new Set();

  @Output() close = new EventEmitter<{ report: Report, report_type: ReportType }>();
  @Output() dismiss = new EventEmitter<void>();

  megaSearchId: string = 'ReportSelectorModalComponent';
  megaSearchConfigMap: Record<string, MegaSearchConfig> = {
    reports: {
      search_properties: [
        { label: 'Report Title', key: 'report_title', paths: ['report_title'] },
        { label: 'Table', key: 'data_table', paths: ['data_table'] },
        { label: 'Label', key: 'label', paths: ['labels.title'] },
        { label: 'Graph Type', key: 'graph_type', paths: ['graph_type'] }
      ],
      item_key_property: 'report_key',
      filtered_keys: new Set(),
      filter_active: false
    }
  };

  new_report_dropdown: DropdownItem[] = [
    {
      label: 'Table',
      value: 'TABLE'
    },
    {
      label: 'Chart',
      value: 'CHART'
    },
    {
      label: 'Numeric',
      value: 'NUMERIC'
    },
  ];

  reports: Report[] = this.reportService.getAllReports();
  visible_reports: Report[] = [];

  reportIsVisible = (report: Report) => {
    const in_excluded_report_keys = this.excluded_report_keys.has(report.report_key);

    if (in_excluded_report_keys) {
      return false;
    }
    if (!!this.project_report_filter_flag) {
      // Filter out reports that don't allow project_key as a possible dimension
      if (_.findIndex(report.table_config.dimensions, (dimension) => dimension.id === 'Project_Key') === -1) {
        return false;
      }
    }
    return true;
  }

  constructor(
    public reportService: ReportService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.visible_reports = _.filter(this.reports, (report) => this.reportIsVisible(report));
  }

  updateVisibleReports() {
    // Timeout avoids ExpressionChangedAfterItHasBeenCheckedError errors
    setTimeout(() => {
      this.visible_reports = _.filter(this.reports, (report) => this.reportIsVisible(report));
    });
  }

  dropdownItemSelected(item: DropdownItem) {
    this.newReport(item.value);
  }

  selectReport(report: Report) {
    this.close.emit({
      report,
      report_type: null
    });
  }

  newReport(report_type: ReportType) {
    this.close.emit({
      report: null,
      report_type
    });
  }

  cancel() {
    this.dismiss.emit();
  }

}
