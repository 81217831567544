import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { KmProjectStub } from '../km-project-stub/km-project-stub';
import { KmTaskStub } from '../km-task-stub/km-task-stub';
import { CoreUtilService } from '../../lib-services/core-util/core-util.service';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';
import _ from 'lodash-es';

export class KmExpense implements PostableObject<KmExpense> {

  private _receipt_total: number;
  private _expense_date: Date;
  private _task: KmTaskStub;

  project: KmProjectStub;
  gst_amount: number;
  approval_date: Date;
  attachment_url: string;
  supplier: string;
  includes_gst: boolean;
  declined_date: Date;
  expense_key: number;
  tax_type: string;
  description: string;
  invoiced_flag: boolean;

  constructor(
    task: KmTaskStub = null,
    expense_date: Date,
    description: string = '',
    gst_amount: number = null,
    project: KmProjectStub = null,
    tax_type: string = null,
    expense_key: number = null,
    includes_gst: boolean = false,
    approval_date: Date = null,
    declined_date: Date = null,
    attachment_url: string = null,
    supplier: string = null,
    invoiced_flag: boolean = false,
    receipt_total: number = null
  ) {
    this._task = task;
    this._expense_date = expense_date;
    this.project = project;
    this.tax_type = tax_type;
    this.expense_key = expense_key;
    this.description = description;
    this.gst_amount = gst_amount;
    this.includes_gst = includes_gst;
    this.approval_date = approval_date;
    this.declined_date = declined_date;
    this.attachment_url = attachment_url || '';
    this.supplier = supplier;
    this.invoiced_flag = invoiced_flag;
    this.receipt_total = receipt_total;
  }

  get is_locked(): boolean {
    return this.task.archived_flag ||
      !!this.project.completed_flag;
  }

  get expense_date(): Date {
    return this._expense_date;
  }
  set expense_date(expense_date: Date) {
    if (TimeUtilService.dateIsValid(expense_date)) {
      this._expense_date = expense_date;
    }
  }

  get task(): KmTaskStub {
    return this._task;
  }
  set task(task: KmTaskStub) {
      this._task = task;
  }


  get receipt_total(): number {
    return this._receipt_total;
  }
  set receipt_total(expense_amount: number) {
    if (CoreUtilService.numberIsValid(expense_amount)) {
      if (expense_amount < 0) {
        expense_amount = 0;
      }
      this._receipt_total = expense_amount;
    }
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};

    if (!this.description) {
      errors['description'] = 'Description is required';
    }
    if (!this.receipt_total) {
      errors['total'] = 'Total is required';
    }
    if (!this.task) {
      errors['task'] = 'An active ' + CoreUtilService.task_label.lowercase + ' must be set';
    }
    else if (this.task.billable_flag) {
      if (!this.project) {
        errors['project'] = CoreUtilService.project_label.lowercase + ' required when the '
        + CoreUtilService.task_label.lowercase + ' is billable';
      }
      else if (!this.project.client_key) {
        errors['project'] = 'The selected ' + CoreUtilService.project_label.lowercase +
          ' must be associated with a client when the '
          + CoreUtilService.task_label.lowercase + ' is billable';
      }
    }

    if (this.project?.completed_flag) {
      errors['project'] = 'The ' + CoreUtilService.project_label.lowercase + ' for this expense is complete';
    }

    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

  formatForPosting(to_delete: boolean = false): any {
    if (!this.hasErrors()) {
      return {
        expense_key: this.expense_key,
        expense_date: TimeUtilService.dateToDateString(this.expense_date),
        description: this.description,
        approval_date: TimeUtilService.dateToDateString(this.approval_date),
        client_key: this.task.billable_flag? this.project.client_key : null,
        project_key: this.task.billable_flag ? this.project.project_key : null,
        task_key: this.task.task_key,
        attachment_url: !!this.attachment_url ? this.attachment_url : '',
        supplier: this.supplier,
        includes_gst: this.includes_gst,
        deleted_flag: to_delete,
        declined_date: TimeUtilService.dateToDateString(this.declined_date),
        receipt_total: this.receipt_total // save receipt total as expense total -> backend will then apply any business use deductions
      };
    }
  }

}
