<div class="app-formItem-inputContainer">
  <div class="input-group">
    <input class="form-control"
           highlight-on-click
           [ngStyle]="{ 'font-weight': _value !== 0 }"
           [placeholder]="'Month'"
           [ngModel]="month_label"
           [ngModelOptions]="{ 'updateOn': 'blur'}"
           (ngModelChange)="parseModelFromView($event)">
  </div>
  <div class="app-formItem-inputContainer-border">
  </div>
</div>
