import _ from 'lodash-es';

import { PostableObjectErrorMap } from '../../../lib-interfaces/postable-object.interface';
import { ReportConfigTable, Label, ReportMeasurePermission, ReportConfigMeasure } from '../../../lib.types';
import { Report, ReportFilter, ReportQuery } from '../report';

export class NumericReport extends Report {

  measure: ReportConfigMeasure;
  graph_function: ReportMeasurePermission;

  report_type_label: string = 'Numeric';

  constructor(
    table_config: ReportConfigTable,
    graph_function: ReportMeasurePermission,
    measure: ReportConfigMeasure,
    report_key: number = null,
    report_title: string = '',
    labels: Label[] = [],
    filters: ReportFilter[] = [],
  ){
    super(
      'NUMERIC',
      table_config,
      report_key,
      report_title,
      labels,
      filters
    );

    this.measure = measure;
    this.graph_function = graph_function;
  }

  generateReportQuery(): ReportQuery {
    if (
      this._validateMeasure()
    ) {
      const measures = [
        {
          field: this.measure.id,
          func: this.graph_function
        }
      ];

      return {
        report_is_table: false,
        table: this.table_config.id,
        fields: [],
        groups: [],
        measures,
        filters: this.formatFiltersForPosting()
      };
    }
    else {
      throw new Error('Invalid measure or dimension');
    }
  }

  getErrors(report_title_required: boolean = true): PostableObjectErrorMap {
    const errors = super.getErrors(report_title_required);

    if (!this.measure) {
      errors['measure'] = 'A measure must be selected';
    }
    if (!this.graph_function) {
      errors['graph_function'] = 'An aggregate must be selected';
    }

    return errors;
  }

  formatForPosting(to_delete: boolean = false): any {
    const data = super.formatForPosting(to_delete);

    data.measures = JSON.stringify([this.measure.id]);
    data.dimensions = JSON.stringify([]);
    data.graph_function = this.graph_function;
    data.graph_type = 'numeric';

    return data;
  }

  private _validateMeasure(): boolean {
    return _.findIndex(this.table_config.measures, { id: this.measure.id }) !== -1;
  }

}
