<div class="camera-header">
  <button class="-color-white-clear -type-icon"
          (click)="cancel()">
    <i class="ion ion-ios-arrow-back"></i>
  </button>
</div>

<div class="camera-contents"
     #cameraContents>

  <app-spinner *ngIf="!pageInitialised || uploadingPhoto"></app-spinner>

  <div class="ph-emptyMessage"
       *ngIf="pageInitialised && !cameraSupported && !photoTaken && !uploadingPhoto">
    <i class="ion ion-ios-camera"></i>
    <div>{{"Tap to take photo"}}</div>
  </div>

  <input class="camera-input"
         #cameraInput
         (change)="imageUploaded()"
         [hidden]="!pageInitialised || cameraSupported || uploadingPhoto"
         type="file"
         accept="image/*"
         capture="user">

  <div class="camera-squareCamera"
       *ngIf="squareImage"
       [ngStyle]="{
          'width': pageIsPortrait ? '100vw' : '100vh',
          'height': pageIsPortrait ? '100vw' : '100vh'
       }">
    <video class="camera-video"
           #cameraVideo
           [ngStyle]="{ 'opacity': photoTaken || !pageInitialised || !cameraSupported ? 0 : 1 }"
           [ngClass]="{ '-flipVideo': selfieCamera }"
           autoplay
           playsinline>
    </video>
  </div>

  <video class="camera-video"
         *ngIf="!squareImage"
         #cameraVideo
         [ngStyle]="{ 'opacity': photoTaken || !pageInitialised || !cameraSupported ? 0 : 1 }"
         [ngClass]="{ '-flipVideo': selfieCamera }"
         autoplay
         playsinline>
  </video>

  <img class="camera-image"
       #cameraImage
       src=""
       [ngStyle]="{ 'opacity': photoTaken ? 1 : 0 }">

</div>

<div class="camera-footer">

  <button class="-color-white-clear -type-icon -swapCamera"
          [hidden]="photoTaken || !pageInitialised || !cameraSupported"
          (click)="swapCamera()">
    <i class="ion ion-md-swap"></i>
  </button>

  <button class="-color-white-clear -type-icon"
          [hidden]="photoTaken || !pageInitialised || !cameraSupported"
          (click)="takePhoto()">
    <i class="ion ion-ios-radio-button-on"></i>
  </button>

  <button class="-color-white-clear -type-icon"
          [hidden]="!photoTaken || !cameraSupported"
          (click)="newPhoto()">
    <i class="ion ion-ios-close-circle"></i>
  </button>

  <button class="-color-white-clear -type-icon"
          [hidden]="!photoTaken || !cameraSupported"
          (click)="confirm()">
    <i class="ion ion-ios-checkmark-circle"></i>
  </button>
</div>
