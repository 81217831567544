import { Component, Output, EventEmitter, Input, HostBinding, OnChanges, SimpleChanges, ChangeDetectionStrategy, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import _ from 'lodash-es';


@Component({
  selector: 'app-search',
  templateUrl: './app-search.component.html',
  styleUrls: ['./app-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppSearchComponent implements OnChanges, AfterContentInit {

  @ViewChild('search_input') search_input: ElementRef;

  @HostBinding('class.app-formItem-inputContainer') @Input() show_input_container = true;
  @HostBinding('class.-disabled') @Input() disabled = false;

  @Input() only_search_on_enter: boolean = false;
  @Input() hide_search_icon: boolean = false;
  @Input() autofocus_on_init: boolean = false;
  @Input() placeholder: string = 'Search...';
  @Input() max_length: number = 50;


  @Input() search: string = '';
  @Output() searchChange = new EventEmitter<string>();

  _search: string = '';


  ngAfterContentInit() {
    // TODO: test this
    if (this.autofocus_on_init) {
      setTimeout(() => {
        this.focus();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.search) {
      this._search = this.search;
    }


  }

  updateSearch(value: string) {
    if (value !== this._search) {
      this._search = value;
      if (!this.only_search_on_enter || this._search === '') {
        this.search = this._search;
        this.searchChange.emit(this.search);
      }
    }
  }

  keyup(event: KeyboardEvent) {
    if (this.only_search_on_enter && event.key === 'Enter') {
      this.search = this._search;
      this.searchChange.emit(this.search);
    }

  }

  clearSearch(event: MouseEvent) {
    event.stopPropagation();
    this._search = '';
    this.search = '';
    this.searchChange.emit(this.search);
  }

  focus() {
    this.search_input?.nativeElement.focus();
  }

}
