import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { TimeUtilService } from '../../lib-services/time-util/time-util.service';


export class DpShift implements PostableObject<DpShift> {

  shift_key: number;
  shift_name: string;
  private _start_time: Date;
  private _end_time: Date;
  shift_period: string;

  open_ended: boolean;
  strict_start: boolean;
  strict_end: boolean;
  round_times: boolean;
  default_break_duration: number;

  archived_flag: boolean;

  constructor(
    shift_key: number = null, shift_name: string = '', start_time: string = '09:00',
    end_time: string = '17:00', open_ended: boolean = false, strict_start: boolean = false,
    strict_end: boolean = false, round_times: boolean = false, default_break_duration: number = 0,
    archived_flag: boolean = false
  ) {

    this.shift_key = shift_key;
    this.shift_name = shift_name;
    this._start_time = TimeUtilService.timeStringToDate(start_time);
    this._end_time = TimeUtilService.timeStringToDate(end_time);
    this.open_ended = open_ended;
    this.strict_start = strict_start;
    this.strict_end = strict_end;
    this.round_times = round_times;
    this.default_break_duration = default_break_duration;
    this.archived_flag = archived_flag;
    this._updateShiftPeriod();
  }

  get start_time(): Date {
    return this._start_time;
  }
  set start_time(start_time: Date) {
    if (TimeUtilService.dateIsValid(start_time)) {
      this._start_time = start_time;
      this._updateShiftPeriod();
    }
  }

  get end_time(): Date {
    return this._end_time;
  }
  set end_time(end_time: Date) {
    if (TimeUtilService.dateIsValid(end_time)) {
      this._end_time = end_time;
      this._updateShiftPeriod();
    }
  }

  private _updateShiftPeriod() {
    //this.shift_period =
    //moment(this._start_time).format('h:mm A') + ' - ' +
    //moment(this._end_time).format('h:mm A');
  }
  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }


  getErrors(): PostableObjectErrorMap {
    const errors: PostableObjectErrorMap = {};

    return errors;
  }


  formatForPosting(toArchive: boolean = false): any {
    if (!this.hasErrors()) {
      return {
        shift_key: this.shift_key,
        shift_name: this.shift_name,
        start_time: TimeUtilService.dateToTimeString(this.start_time),
        end_time: TimeUtilService.dateToTimeString(this.end_time),
        open_ended: this.open_ended,
        strict_start: this.strict_start,
        strict_end: this.strict_end,
        round_times: this.round_times,
        default_break_duration: this.default_break_duration,
        archived_flag: toArchive
      };
    }
    return null;
  }

}
