import { SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { PostableObject, PostableObjectErrorMap } from '../../lib-interfaces/postable-object.interface';
import { DateTime } from 'luxon';

type DpNotificationType = ('ROSTER_UPDATED' | 'COVER_REQUEST_UPDATE');

type DpNotificationData = {
  roster_start_date?: Date,
  roster_segment_cover_request_key?: number,
  roster_segment_key?: number,
  profile_image?: string,
  display_name?: string
};

export type DpNotificationButtonConfig = {
  custom_button_flag: boolean,
  button_colour_class?: string,
  button_type_class?: string,
  button_icon_class?: string,
  button_title?: string,
  url?: string,
  url_params?: any,
  route_path?: string,
  route_query_params?: any,
}

type DpNotificationIcon = {
  icon_string: string,
  icon_colour: string
}


export class DpNotification implements PostableObject<DpNotification> {

  notification_key: number;
  identity_key: number;
  notification_date: Date;
  notification_type: DpNotificationType;
  notification_icon: DpNotificationIcon;
  notification_title: string;
  description: SafeHtml;
  seen_flag: boolean;
  deleted_flag: boolean = false;

  button_config: DpNotificationButtonConfig = null;
  notification_data: DpNotificationData = null;
  notification_date_label: string = null;
  img_url: SafeResourceUrl = null;
  additional_data: any = null;

  constructor(
    notification_key: number,
    identity_key: number,
    notification_date: Date,
    notification_type: DpNotificationType,
    notification_title: string,
    button_config: any,
    notification_data: DpNotificationData,
    description: SafeHtml,
    seen_flag: boolean,
    deleted_flag: boolean = false,
  ) {
    this.notification_key = notification_key;
    this.identity_key = identity_key;
    this.notification_date = notification_date;
    this.notification_type = notification_type;
    this.notification_icon = this.findNotificationIcon(this.notification_type);
    this.notification_title = notification_title;
    this.notification_data = notification_data;
    this.description = description;
    this.seen_flag = seen_flag;
    this.deleted_flag = deleted_flag;
    this.button_config = this.initButtonConfig(button_config);
    this._initNotificationDateLabel();
  }


  initButtonConfig(button_config: any): DpNotificationButtonConfig {
    switch (this.notification_type) {
      case 'ROSTER_UPDATED':
        this.additional_data = this.notification_data?.roster_start_date || null;
        return {
          custom_button_flag: true,
          route_path: 'roster/dash',
          route_query_params: !!this.notification_data?.roster_start_date ? { roster_start_date: this.notification_data.roster_start_date } : null,
        };
      case 'COVER_REQUEST_UPDATE':
        this.additional_data = {
          roster_segment_cover_request_key: this.notification_data?.roster_segment_cover_request_key,
          roster_segment_key: this.notification_data?.roster_segment_key
        };
        return {
          custom_button_flag: true,
        };
    }
  }

  findNotificationIcon(notification_type: DpNotificationType): DpNotificationIcon{
    switch(notification_type) { 
      case 'ROSTER_UPDATED': { 
        return {
          icon_string: 'list',
          icon_colour: '#84CA80'
        };
        break; 
      } 
      case 'COVER_REQUEST_UPDATE': { 
        return {
          icon_string: 'cover',
          icon_colour: '#FFA571'
        };
        break; 
      } 
      default: { 
        return null;
        break; 
      } 
    } 

  }

  formatForPosting(to_delete: boolean = false): any {
    return {
      notification_key: this.notification_key,
      seen_flag: this.seen_flag,
      deleted_flag: to_delete
    };
  }

  private _initNotificationDateLabel() {
    const today = DateTime.now();
    const notification_date = DateTime.fromJSDate(this.notification_date);

    if (notification_date > today.minus({ minutes: 1 })) {
      this.notification_date_label = 'Less than a minute ago';
    }
    else if (notification_date > today.minus({ days: 7 })) {
      this.notification_date_label = DateTime.fromJSDate(this.notification_date).toRelative();
    }
    else {
      this.notification_date_label = notification_date.toFormat('d LLL');
    }
  }

  getErrors(): PostableObjectErrorMap {
    const errors = {};
    return errors;
  }

  hasErrors(): boolean {
    return Object.keys(this.getErrors()).length > 0;
  }

}
