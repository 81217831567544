import { Injectable } from '@angular/core';

import * as EXIF from 'exif-js';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  private _cameraSupported: boolean = null;

  constructor(

  ) { }

  get cameraSupported(): boolean {
    return this._cameraSupported;
  }
  set cameraSupported(cameraSupported: boolean) {
    this._cameraSupported = cameraSupported;
  }

  /**
   * Generates a blob image from an HTML video element
   */
  static generateBlobImageFromVideo(
    video: any, compressionQuality: number = 0.5, squareImage: boolean = false, mirrorImage: boolean = false
  ): Promise<Blob> {
    return new Promise<any>((resolve, reject) => {
      this.checkCanvasToBlobCompatibility();

      // Create a canvas and use it to draw a snapshot of the video feed for creating a BLOB from
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const vidHeight = video.videoHeight;
      const vidWidth = video.videoWidth;

      let vidOffsetX = 0;
      let vidOffsetY = 0;

      if (squareImage) {
        if (vidHeight < vidWidth) {
          context.canvas.height = vidHeight;
          context.canvas.width = vidHeight;
          vidOffsetX = (vidWidth - vidHeight) / 2;
        }
        else {
          context.canvas.height = vidWidth;
          context.canvas.width = vidWidth;
          vidOffsetY = (vidHeight - vidWidth) / 2;
        }
      }
      else {
        context.canvas.height = vidHeight;
        context.canvas.width = vidWidth;
      }

      if (mirrorImage) {
        context.transform(-1, 0, 0, 1, context.canvas.width, 0);
      }

      context.drawImage(
        video,
        vidOffsetX, vidOffsetY,
        context.canvas.width, context.canvas.height,
        0, 0,
        context.canvas.width, context.canvas.height
      );

      canvas.toBlob(
        (blob) => resolve(blob),
        'image/jpeg',
        compressionQuality
      );
    });
  }

  static cropImageToSquare(
    originalImage: Blob
  ): Promise<Blob> {
    return new Promise<Blob>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(originalImage);

      img.onload = () => {
        if (img.height !== img.width) {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          let imgOffsetX = 0;
          let imgOffsetY = 0;

          if (img.height < img.width) {
            context.canvas.height = img.height;
            context.canvas.width = img.height;
            imgOffsetX = (img.width - img.height) / 2;
          }
          else {
            context.canvas.height = img.width;
            context.canvas.width = img.width;
            imgOffsetY = (img.height - img.width) / 2;
          }

          context.drawImage(
            img,
            imgOffsetX, imgOffsetY,
            context.canvas.width, context.canvas.height,
            0, 0,
            context.canvas.width, context.canvas.height
          );

          canvas.toBlob(
            (blob) => resolve(blob),
            'image/jpeg',
            1
          );
        }
        else {
          resolve(originalImage);
        }
      };
    });
  }

  /**
   * Scales a blob image to the resolution specificed by xPx & xPx
   */
  static scaleImage(
    originalImage: Blob, xPx: number, yPx: number = null, compressionQuality: number = 1
  ): Promise<Blob> {
    return new Promise<any>((resolve) => {
      this.checkCanvasToBlobCompatibility();

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      context.canvas.width = xPx;
      context.canvas.height = yPx || xPx;

      const img = new Image();
      img.src = URL.createObjectURL(originalImage);
      img.onload = () => {
        context.drawImage(
          img, 0, 0, context.canvas.width, context.canvas.height
        );

        canvas.toBlob(
          (scaledImage) => resolve(scaledImage),
          'image/jpeg',
          compressionQuality
        );
      };
    });
  }

  /**
   * Scales a blob image to ensure that the pixel values of both its
   * dimensions are no greater than maxSizePx.
   * Keeps the image ratio the same
   */
  static scaleDownImageToMaxSize(
    originalImage: Blob, maxSizePx: number, compressionQuality: number = 1
  ): Promise<Blob> {
    return new Promise<Blob>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(originalImage);

      img.onload = () => {
        if (img.height > maxSizePx || img.width > maxSizePx) {

          let scaledWidth: number;
          let scaledHeight: number;
          let imageRatio: number;

          // Portrait
          if (img.height > img.width) {
            imageRatio = img.width / img.height;
            scaledHeight = maxSizePx;
            scaledWidth = scaledHeight * imageRatio;
          }
          // Landscape
          else {
            imageRatio = img.height / img.width;
            scaledWidth = maxSizePx;
            scaledHeight = scaledWidth * imageRatio;
          }

          this.scaleImage(originalImage, scaledWidth, scaledHeight, compressionQuality)
            .then((scaledImage) => resolve(scaledImage));
        }
        else {
          resolve(originalImage);
        }
      };
    });
  }

  /**
   * Checks whether browser has a toBlob method for the canvas object.
   * If it doesn't then it will create its own method to read in uploaded images.
   */
  static checkCanvasToBlobCompatibility() {
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value(callback, type, quality) {
          const canvas = this;

          setTimeout(() => {

            const binString = atob(canvas.toDataURL(type, quality).split(',')[1]);
            const len = binString.length;
            const array = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
              array[i] = binString.charCodeAt(i);
            }

            callback(new Blob([array], { type: type || 'image/jpeg' }));
          });
        }
      });
    }
  }

  static mirrorBlobImage(originalImage: Blob): Promise<any> {
    return new Promise<any>((resolve) => {
      const image = new Image();

      image.src = URL.createObjectURL(originalImage);

      image.onload = () => {
        this.checkCanvasToBlobCompatibility();

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const imgWidth = image.width;
        const imgHeight = image.height;

        context.canvas.height = imgHeight;
        context.canvas.width = imgWidth;

        context.transform(-1, 0, 0, 1, imgWidth, 0);

        context.drawImage(image, 0, 0, imgWidth, imgHeight);

        canvas.toBlob(
          (blob) => {
            resolve(blob);
          }, 'image/jpeg', 1
        );
      };
    });
  }

  /**
   * Uses image EXIF meta data to check that image orientation is correct.
   * Compresses a blob image from a blob URL
   */
  static orientAndCompressBlobImage(originalImage: Blob, compressionQuality: number = 0.5) {
    return new Promise<any>((resolve, reject) => {
      const image = new Image();

      image.src = URL.createObjectURL(originalImage);

      image.onload = () => {
        this.checkCanvasToBlobCompatibility();

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        let exifOrientation = null;

        const imgWidth = image.width;
        const imgHeight = image.height;

        const blobQuality = compressionQuality;

        // Check orientation in EXIF metadata
        EXIF.getData(image as unknown as string, () => {
          const allMetaData = EXIF.getAllTags(this);
          exifOrientation = allMetaData.Orientation;

          // Set proper canvas dimensions before transform & export
          if ([5, 6, 7, 8].indexOf(exifOrientation) !== -1) {
            context.canvas.height = imgWidth;
            context.canvas.width = imgHeight;
          }
          else {
            context.canvas.height = imgHeight;
            context.canvas.width = imgWidth;
          }

          // transform context before drawing image
          switch (exifOrientation) {
            case 2:
              context.transform(-1, 0, 0, 1, imgWidth, 0);
              break;
            case 3:
              context.transform(-1, 0, 0, -1, imgWidth, imgHeight);
              break;
            case 4:
              context.transform(1, 0, 0, -1, 0, imgHeight);
              break;
            case 5:
              context.transform(0, 1, 1, 0, 0, 0);
              break;
            case 6:
              context.transform(0, 1, -1, 0, imgHeight, 0);
              break;
            case 7:
              context.transform(0, -1, -1, 0, imgHeight, imgWidth);
              break;
            case 8:
              context.transform(0, -1, 1, 0, 0, imgWidth);
              break;
            default:
              context.transform(1, 0, 0, 1, 0, 0);
          }

          context.drawImage(image, 0, 0, imgWidth, imgHeight);

          canvas.toBlob(
            (blob) => {
              resolve(blob);
            }, 'image/jpeg', blobQuality);
        });
      };
    });
  }

}
