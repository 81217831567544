import { Inject, Injectable } from '@angular/core';

import { AuthService } from '../auth/auth.service';
import { StateDataService } from '../state-data/state-data.service';
import { StateAccessServiceInterface } from '../../lib-interfaces/state-access-service.interface';

import _ from 'lodash-es';
import { Router, RouterEvent, NavigationEnd, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CoreUtilService } from '../core-util/core-util.service';

type BackStackRoute = {
  path: string,
  query_params: any
};

@Injectable({
  providedIn: 'root'
})
export class StateChangeService {

  private readonly _back_routes_to_ignore: string[] = [
    '/splash', '/loginExternal'
  ];
  private readonly _max_route_back_stack_size: number = 50;
  private _route_back_stack: string[] = [];

  constructor(
    @Inject('stateAccessService') public stateAccessService: StateAccessServiceInterface,
    public stateDataService: StateDataService,
    public authService: AuthService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    this._route_back_stack = this.stateDataService.route_back_stack || [];
  }

  initTransitionListeners() {
    this._listenForNavigationEnd();
  }

  /**
   * eg. '/project/edit?project_key=123' => ['project', 'edit']
   */
  getUrlSegmentPaths(url: string = null): string[] {
    const tree = this.router.parseUrl(url || this.router.url);
    const tree_segments = tree.root.children[PRIMARY_OUTLET].segments;

    const paths = [];
    for (const segment of tree_segments) {
      paths.push(segment.path);
    }
    return paths;
  }

  // Clears the specificed query params from the current route without reloading the page
  clearParamsFromCurrentRoute(params: Set<string>): void {
    const queryParams: any = {};
    for (const param of params.keys()) {
      queryParams[param] = null;
    }

    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  /**
   * eg. '/project/edit?project_key=123' => 'project/edit'
   */
  getUrlWithoutParams(url: string): string {
    return '/' + this.getUrlSegmentPaths(url).join('/');
  }

  // Go directly to another route
  // Most common type of transition
  go(
    route: string,
    query_params: any = {}
  ): Promise<boolean> {
    query_params = _.cloneDeep(query_params) || {};

    for (const param_key of Object.keys(query_params)) {
      query_params[param_key] = JSON.stringify(query_params[param_key]);
    }

    return this.router.navigate(
      [route],
      { queryParams: query_params }
    );
  }

  // Go back to the last route in the route_back_stack, skipping any routes in route_paths_to_skip
  // Any routes in the route_back_stack after the specificed route are discarded from the route_back_stack
  back(
    route_paths_to_skip: string[] = [],
    new_query_params: any = {}
  ) {
    const back_stack_route_index = this.getNextBackRoutePathIndex(route_paths_to_skip);

    if (back_stack_route_index !== null) {
      const back_stack_path = this.getUrlWithoutParams(this._route_back_stack[back_stack_route_index]);
      const back_stack_query_params = this.router.parseUrl(this._route_back_stack[back_stack_route_index]).queryParams;

      const query_params = StateChangeService.mergeOldAndNewParams(back_stack_query_params, new_query_params);

      this._back(
        back_stack_path,
        query_params,
        back_stack_route_index
      );
    }
  }

  // Go back to last instance of specific route in the route_back_stack
  // Any routes in the route_back_stack after the specificed route are discarded from the route_back_stack
  backToRoute(
    route_path: string,
    new_query_params: any = {}
  ) {
    const back_stack_route_index = this.getBackStackIndexForLastInstanceOfRoute(route_path);

    if (back_stack_route_index !== null) {
      const back_stack_query_params = this.router.parseUrl(this._route_back_stack[back_stack_route_index]).queryParams;

      const query_params = StateChangeService.mergeOldAndNewParams(back_stack_query_params, new_query_params);

      this._back(
        route_path,
        query_params,
        back_stack_route_index
      );
    }
  }

  private _back(
    path: string,
    query_params: any,
    back_stack_route_index: number
  ) {
    setTimeout(() => {
      this.router.navigate(
        [path],
        {
          queryParams: query_params,
          state: { discard_from_back_stack: true }
        }
      )
        .then((navigation_success) => {
          if (!!navigation_success) {
            // Remove previous route from back stack along with any route to skip
            // that were in between the previous route and the new current route
            this._route_back_stack = this._route_back_stack.slice(back_stack_route_index);
          }
        })
        .catch(() => { });
    });
  }

  private _listenForNavigationEnd() {
    this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        const navigation_extras = this.router.getCurrentNavigation().extras.state;

        // This value will be true when navigation is triggered by this.back()
        if (!navigation_extras?.discard_from_back_stack) {
          this._pushToRouteBackStack();
        }
      });
  }

  private _pushToRouteBackStack() {
    const url = this.getUrlWithoutParams(this.router.url);

    if (this._back_routes_to_ignore.indexOf(url) === -1) {
      // No point adding the same route multiple times in a row
      if (
        !this._route_back_stack.length ||
        this._route_back_stack[0] !== url
      ) {

        if (this._route_back_stack.length >= this._max_route_back_stack_size) {
          this._route_back_stack = this._route_back_stack.slice(0, this._max_route_back_stack_size - 1);
        }

        this._route_back_stack.unshift(this.router.url);
        this.stateDataService.route_back_stack = this._route_back_stack;
      }
    }
  }

  getBackStackIndexForLastInstanceOfRoute(route_path: string): number {
    route_path = this.getUrlWithoutParams(route_path);

    if (!!this._route_back_stack.length) {
      for (let i = 0; i < this._route_back_stack.length; i++) {
        const path = this.getUrlWithoutParams(this._route_back_stack[i]);

        if (path === route_path) {
          return i;
        }
      }
    }
    return null;
  }

  getNextBackRoutePathIndex(route_paths_to_skip: string[] = []): number {
    const route_paths_to_skip_set = new Set();

    for (let path of route_paths_to_skip) {
      path = this.getUrlWithoutParams(path);
      route_paths_to_skip_set.add(path);
    }

    if (!!this._route_back_stack.length) {
      const current_path = this.getUrlWithoutParams(this.router.url);

      for (let i = 0; i < this._route_back_stack.length; i++) {
        const path = this.getUrlWithoutParams(this._route_back_stack[i]);

        // Return route path if it is different from the current route and isn't a skip route
        if (
          path !== current_path &&
          !route_paths_to_skip_set[path]
        ) {
          return i;
        }
      }
    }
    return null;
  }

  /**
   * Merges two state param objects. Values in newParams take precedence
   * if both objects contain different values for the same key
   */
  static mergeOldAndNewParams(oldParams: any = {}, newParams: any = {}): any {
    const params = _.cloneDeep(newParams);

    for (const key of Object.keys(oldParams)) {
      if (!params[key]) {
        params[key] = oldParams[key];
      }
    }

    return params;
  }

}
