import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AppModalInterface } from '../../lib-interfaces/app-modal.interface';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements AppModalInterface {

  @Input() message: string = null;
  @Input() messages: string[] = [];
  @Input() message_is_html: boolean = false;

  @Output() close = new EventEmitter<void>();
  @Output() dismiss = new EventEmitter<void>();

  cancel(){
    this.close.emit();
  }

}
