import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { CoreUtilService } from '../../lib-services/core-util/core-util.service';

@Pipe({
  name: 'appCurrency'
})
export class AppCurrencyPipe implements PipeTransform {

  constructor(
    public currencyPipe: CurrencyPipe
  ) { }

  transform(
    value: number | string,
    num_dp: number = 2,
    currency_code: string = CoreUtilService.currency_code,
    symbol: string = 'symbol-narrow'
  ) {
    const digitsInfo = '1.' + num_dp + '-' + num_dp;
    return this.currencyPipe.transform(value, currency_code, symbol, digitsInfo);
  }

}
