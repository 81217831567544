<ng-container *ngIf="!unit_based_project_task">
  <div class="projectTaskClock-timer"
       *ngIf="!hide_timer && is_active_clock">
    <div>{{clockService.timer_active_project_task_seconds | secondsToTimePipe}}</div>
    <div class="-label">:{{clockService.timer_active_project_task_seconds % 60 | number : '2.0-0'}}</div>
  </div>

  <i class="ion ion-ios-pause projectTaskClock-onHoldIcon"
     *ngIf="!hide_timer && is_on_hold">
  </i>

  <div class="projectTaskClock-timer"
       *ngIf="!hide_timer && is_on_hold && !!clock_group">

    <div>{{clock_group?.on_hold_clock_seconds | secondsToTimePipe}}
    </div>
    <div class="-label">
      :{{clock_group?.on_hold_clock_seconds % 60 | number : '2.0-0'}}
    </div>
  </div>

  <div class="projectTaskClock-buttons">

    <button class="-type-icon -color-grey-clear"
            *ngIf="active_clock_delete_enabled && is_active_clock"
            (click)="deleteClock($event)">
      <i class="ion ion-md-trash"></i>
    </button>

    <button class="-type-icon-round -color-white-outline"
            *ngIf="active_clock_editing_enabled && is_active_clock"
            (click)="clockEdit($event)">
      <i class="ion-md-create"></i>
    </button>

    <i class="ion ion-ios-pause projectTaskClock-onHoldIcon -button-style"
       *ngIf="hide_timer && is_on_hold">
      <tooltip-popover *ngIf="!hide_button_tooltips"
                       [tooltip_content]="'On Hold'">
      </tooltip-popover>
    </i>

    <button class="-type-icon-round -color-red-translucent"
            *ngIf="active_clock_stop_enabled && (is_active_clock || (hide_timer && is_active_project_task))"
            (click)="clockOut($event)">
      <i class="ion-ios-square"></i>
      <tooltip-popover *ngIf="!hide_button_tooltips"
                       [tooltip_content]="'Stop Timer'">
      </tooltip-popover>
    </button>
    <button class="-type-icon-round -color-green-translucent"
            *ngIf="!is_on_hold && !is_active_clock && !is_active_project_task"
            [disabled]="(!!active_clock && active_clock?.project_task?.project_task_key === project_task?.project_task_key)"
            (click)="clockIn()">
      <i class="ion-ios-play"></i>
      <!-- could be 'Interupt and start new Timer' if there is existing timer running-->
      <tooltip-popover *ngIf="!hide_button_tooltips"
                       [tooltip_content]="'Start Timer'">
      </tooltip-popover>
    </button>

    <div class="projectTaskClock-onHoldText"
         *ngIf="!hide_timer && is_on_hold">
      On Hold
    </div>
  </div>
</ng-container>
