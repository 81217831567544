import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { throttleTime, tap } from 'rxjs/operators';

@Directive({
  selector: '[throttledScrollListener]'
})
export class ThrottledScrollListenerDirective implements OnInit, OnDestroy {

  scroll_event: Subscription;

  @Input() scroll_throttle: number = 500;

  @Output() throttled_scroll = new EventEmitter<number>();

  constructor(
    public elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.scroll_event = fromEvent(this.elementRef.nativeElement, 'scroll')
      .pipe(
        throttleTime(this.scroll_throttle),
        tap((ev: any) => this.throttled_scroll.emit(ev.target.scrollTop))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.scroll_event.unsubscribe();
  }
}
