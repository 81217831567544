import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';

import { ReportingAccount } from '../../lib-models/reporting-account/reporting-account';
import { ListDropdownItemConfig, ListDropdownMultiSelectConfig } from '../list-drop-down/list-drop-down.component';
import { StateDataService } from '../../lib-services/state-data/state-data.service';
import { AccountServiceInterface } from '../../lib-interfaces/account-service.interface';

@Component({
  selector: 'account-select-dropdown',
  templateUrl: './account-select-dropdown.component.html',
  styleUrls: ['./account-select-dropdown.component.scss']
})
export class AccountSelectDropdownComponent implements OnInit {

  readonly account_dropdown_config: ListDropdownItemConfig = {
    product: 'product',
    type: 'Account',
    type_plural: 'Accounts',
    id: 'company_reference',
    label: 'company_name'
  };
  readonly selected_account_dropdown_config: ListDropdownMultiSelectConfig = {
    enabled: true,
    button_label: 'Update Selected Accounts',
    update_on_close: true,
    one_item_minimum: true
  };
  button_title: string = '';

  all_accounts: ReportingAccount[] = [];

  account_map: Record<number, ReportingAccount> = {};
  selected_accounts: ReportingAccount[] = this.accountService.selected_accounts;

  @Output() accounts_selected = new EventEmitter<ReportingAccount[]>();

  constructor(
    public stateDataService: StateDataService,
    @Inject('accountService') public accountService: AccountServiceInterface
  ) { }

  ngOnInit(): void {
    this.all_accounts = this.accountService.getAllAccounts();
    this._initAccountMap();
    this._updateButtonTitle();
  }

  accountsSelected(accounts: ReportingAccount[]) {
    if (this._accountsChanged(accounts)) {
      this.selected_accounts = accounts;
      this.accountService.selected_accounts = this.selected_accounts;
      this._updateButtonTitle();
      this.accounts_selected.emit(this.selected_accounts);
    }
  }

  private _accountsChanged(accounts: ReportingAccount[]): boolean {
    if (this.selected_accounts.length !== accounts.length) {
      return true;
    }

    const current_company_references = new Set(this.selected_accounts.map(a => a.company_reference));
    const new_company_references = new Set(accounts.map(a => a.company_reference));

    for (const company_reference of current_company_references.keys()) {
      if (!new_company_references.has(company_reference)) {
        return true;
      }
    }
    return false;
  }

  private _initAccountMap() {
    this.account_map = {};
    for (const account of this.all_accounts) {
      this.account_map[account.company_reference] = account;
    }
  }

  private _updateButtonTitle() {
    if (this.selected_accounts.length === 1) {
      this.button_title = this.selected_accounts[0].company_name;
    }
    else if (this.selected_accounts.length === this.all_accounts.length) {
      this.button_title = 'All Accounts';
    }
    else {
      this.button_title = this.selected_accounts.length + ' Accounts';
    }
  }

}
